import { post, get, put } from "../../../config/axios.js";

export const getUsers = async () => {
  return await post("/api/User/ListUser", {});
};

export const getListModules = async () => {
  return await get("/api/applicationmodule/getlist");
};

export const setUserApplicationModules = async (request) => {
  return await post("/api/user/addorupdateuserapplicationmodules", request);
};

export const setEnableDisableUser = async (request) => {
  return await post("/api/user/enabledisableuser", request);
};

export const updateUser = async (request) => {
  return await put("/api/user", request);
}