import { post } from "../../../config/axios.js";

export const getSellers = async () => {
  const request = {
    Filter: "",
    OrderBy: "email",
    OrderAsc: false,
  };
  return await post("/api/Seller/ListSellers", request);
};

export const listSellers = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
  };
  return await post("/api/Seller/ListSellers", request);
};

export const addOrUpdateSeller = async (request) => {
  return await post("/api/Seller/AddOrUpdateSeller", request);
};

export const deleteSeller = async (request) => {
  return await post("/api/Seller/DeleteSellers", request);
};
