import React from "react";

import CloseIcon from "@material-ui/icons/Close";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import CheckIcon from "@material-ui/icons/Check";
import ViewHeadlineIcon from "@material-ui/icons/ViewHeadline";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import HistoryIcon from "@material-ui/icons/History";
import {
  PlayCircleFilledWhiteOutlined,
  AccessTimeOutlined,
  ErrorOutlineOutlined,
  ForumOutlined,
} from "@material-ui/icons";

export default [
  {
    subheader: "Filtros",
    items: {
      0: {
        href: "/gestion/todos",
        icon: <ViewHeadlineIcon style={{ fontSize: 25 }} />,
      },
      1: {
        href: "/gestion/publicada",
        icon: <EventAvailableIcon style={{ fontSize: 25 }} />,
      },
      2: {
        href: "/gestion/cerrada",
        icon: <CloseIcon style={{ fontSize: 25 }} />,
      },
      3: {
        href: "/gestion/adjudicada",
        icon: <CheckIcon style={{ fontSize: 25 }} />,
      },
      4: {
        href: "/gestion/desierta",
        icon: <CheckBoxOutlineBlankIcon style={{ fontSize: 25 }} />,
      },
      5: {
        href: "/gestion/cancelada",
        icon: <CancelPresentationIcon style={{ fontSize: 25 }} />,
      },
      6: {
        href: "/gestion/pendiente",
        icon: <HistoryIcon style={{ fontSize: 25 }} />,
      },
      7: {
        href: "/gestion/apelada",
        icon: <ErrorOutlineOutlined style={{ fontSize: 25 }} />,
      },
      8: {
        href: "/gestion/objetada",
        icon: <ForumOutlined style={{ fontSize: 25 }} />,
      },
      9: {
        href: "/gestion/sin-efecto",
        icon: <AccessTimeOutlined style={{ fontSize: 25 }} />,
      },
      10: {
        href: "/gestion/en-ejecucion",
        icon: <PlayCircleFilledWhiteOutlined style={{ fontSize: 25 }} />,
      },
      99: {
        href: "/gestion/eliminada",
        icon: <RemoveCircleOutlineIcon style={{ fontSize: 25 }} />,
      },
    },
  },
];
