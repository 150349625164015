import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import { FstGrid } from "../../../components/grid/FstGrid";
import { HeaderTable } from "../../legal-document/LegalDocumentHeaderTable";
import { ManageTicket } from "../services/ticketService";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
import LegalDocumentConfig from "./LegalDocumentConfig";
import FstModalConfirm from "../../../components/modal/FstModalConfirm";
import ManageLegalDocuments from "../../legal-document/ManageLegalDocument";
import { PenaltyMode } from "../../../types";
import { useIntl } from "react-intl";
import * as SpinnerActions from "../../../actions/spinnerActions";
import { formatShortDate } from "utils/common";

const LegalDocumentsTender = (props) => {
  moment.locale("es"); //Dani fijate si esto es correcto

  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [setting, setSetting] = useState({ IsCreate: true });
  const [legalDocument, setLegalDocument] = useState(null);
  const [legalToDelete, setLegalToDelete] = useState(null);
  const [message, setMessage] = useState("");
  const intl = useIntl();
  const [allData, setAllData] = useState(
    JSON.parse(JSON.stringify(props.value.legalDocuments)),
  );
  const [initialData, setInitialData] = useState(
    JSON.parse(JSON.stringify(props.value.legalDocuments)),
  );

  const CreateTicket = () => {
    setSetting({ Mode: PenaltyMode.CREATE });
    setOpen(true);
  };

  const handleModalClose = () => {
    setLegalDocument(null);
    setOpen(false);
  };

  const EditTicket = (item) => {
    if (!item) return;
    setSetting({ Mode: PenaltyMode.EDIT });
    setLegalDocument(item);
    setOpen(true);
  };

  const deleteLegalDocument = (item) => {
    if (!item) return;
    setLegalToDelete(item);
    setMessage(
      `¿Confirma que desea eliminar ${intl.formatMessage({
        id: "etq_CartaFianza",
        defaultMessage: "Carta Fianza",
      })} número ${item.number}?`,
    );
    setOpenConfirm(true);
  };

  const executeDelete = () => {
    props.DeleteLegalDocument(legalToDelete);
    setOpenConfirm(false);
  };

  const getLegalDocumentsData = async (pag, filter) => {
    props.SpinnerShow("Cargando...");

    let orderedData = allData.sort((a, b) => {
      if (a[pag.orderBy] < b[pag.orderBy]) return pag.OrderAsc ? -1 : 1;
      if (a[pag.orderBy] > b[pag.orderBy]) return pag.OrderAsc ? 1 : -1;
      return 0;
    });

    setTimeout(() => {
      if (pag.orderBy === null && pag.orderDirection === null)
        setAllData(JSON.parse(JSON.stringify(initialData)));
      else setAllData(orderedData);
      props.SpinnerHide();
    }, 550);
  };

  useEffect(() => {
    setInitialData(JSON.parse(JSON.stringify(props.value.legalDocuments)));
    setAllData(JSON.parse(JSON.stringify(props.value.legalDocuments)));
  }, [props.value]);

  const exportExcel = (pagination, filter) => {
    let formattedData = initialData.map((item) => {
      return {
        ...item,
        endDate: item.endDate ? formatShortDate(item.endDate) : "",
        active: item.active ? "Si" : "No",
      };
    });
    return formattedData;
  };

  const dataGrid = {
    Getdata: getLegalDocumentsData,
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
    },
    allowPaging: false,
    allowSorting: true,
    Header: HeaderTable(intl),
    height: props.height - 180,
    rowDoubleClickAction: EditTicket,
    Add: CreateTicket,
    Edit: EditTicket,
    Delete: deleteLegalDocument,
    ToolBar: {
      Add: true,
      AddTitle: "Agregar",
      Edit: true,
      EditTitle: "Modificar",
      Delete: true,
      DeleteTitle: "Eliminar",
      Export: true,
    },
    ExportFileName: `${intl.formatMessage({
      id: "etq_CartaFianza",
      defaultMessage: "Carta Fianza",
    })} - ${props.value?.tenderNumber}.xlsx`,
    exportFunc: exportExcel,
    idGrid: "LegalDocumentsTerder",
  };

  const handleSave = async (ld) => {
    const rest = await ManageTicket([ld]);
    props.updateLegalDocument(rest.data[0]);
    setOpen(false);
    setLegalDocument(null);
  };

  return (
    <>
      {open && (
        <ManageLegalDocuments
          openModal={open}
          setting={setting}
          Save={handleSave}
          value={props.value}
          legalDocument={legalDocument}
          handleOpenModal={() => handleModalClose()}
        />
      )}

      <FstModalConfirm
        title={
          "Atención: Eliminación de " +
          intl.formatMessage({
            id: "etq_CartaFianza",
            defaultMessage: "Carta Fianza",
          })
        }
        close={() => {
          setOpenConfirm(false);
        }}
        open={openConfirm}
        message={message}
        success={executeDelete}
      />

      <LegalDocumentConfig
        tender={props.value}
        onChange={props.onChangeConfigLegalDocument}
      />

      <FstGrid
        data={dataGrid}
        uniqValue={1}
        result={{
          result: allData,
          count: allData.length,
        }}
        reloadDefault={true}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const { fullName } = user;
  const { spinnerReducer } = state;

  return {
    ...ownProps,
    fullName,
    ...spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  LegalDocumentsTender,
);
