import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { StylePage } from "../../../style/contentStyle";
import AnalysisActions from "../AnalysisActions";
import TenderAnalysisReport from "../report/TenderAnalysisReport";
import { acquireToken } from "../../../utils/activeDirectory";
import { connect } from "react-redux";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";

function AnalysisPurchaseOrder(props) {
  const classes = StylePage();
  const [auth, setAuth] = useState();
  const appInsights = useAppInsightsContext();
  const trackComponentMetric = useTrackMetric(appInsights, "OC: Time on page");
  useEffect(() => {
    trackComponentMetric();
    return () => {
      trackComponentMetric();
    };
  }, []);

  const getAuth = async () => {
    const { token } = await acquireToken();
    setAuth(token?.accessToken);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      getAuth();
    });

    return () => {
      clearTimeout(time);
    };
  }, []);
  return (
    <>
      <Grid container className={classes.content} spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <AnalysisActions AnalysisFilterModuleId={4} />
          {props.loadReport && auth && <TenderAnalysisReport auth={auth} />}
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (reducer) => {
  return reducer.analysisReportReducer;
};
export default connect(mapStateToProps, {})(AnalysisPurchaseOrder);
