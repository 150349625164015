import React, { useEffect, useState } from "react";
import FstModal from "../../../components/modal/FstModal";
import ReportFilter from "./ReportFilter";
import AnalysisTabs from "../params/AnalysisTabs";
import { saveOrUpdate } from "../services/AnalysisService";
import {
  ConvertDateEndToISOLarge,
  formatDate,
  ConvertDateStartToISOLarge,
} from "../../../utils/DateHelper";
import { procesTime, filterDefault } from "./processTime";
import moment from "moment";
import { FIJO, PERSONALIZADO, RangeDefault, MomentRange } from "./processTime";
import RangeCustom from "./rangeCustom";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { getSources } from "views/smart-search/services/smartSearchService";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const message = {
  ENTITY_ALREADY_EXISTS: " ya existe en el sistema.",
  FILTER: "El filtro",
};

const nameDate = ["StartDate", "EndDate"];

const ManageReportFilter = (props) => {
  const [entitySelected, setEntitySelected] = useState([]);
  const [filter, setFilter] = useState(null);
  const [tabsCounts, setTabsCounts] = useState({
    products: 0,
    organisms: 0,
    competitors: 0,
  });
  const [organims, setOrganims] = useState([]);
  const [openRangeCustom, setOpenRangeCustom] = useState(false);
  const [competitors, setCompetitors] = useState([]);
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [fixedDate, setfixedDate] = useState(false);
  const [sources, setSources] = useState([]);

  const TabsENUM = {
    PRODUCTOS: 0,
    ORGANIMS: 1,
    COMPETITORS: 2,
  };

  const onChange = (key, value) => {
    const aux = { ...filter };
    aux[key] = value;
    setFilter(aux);
    setfixedDate(nameDate.indexOf(key) > -1 ? true : false);
  };

  const onChangeError = (key, value) => {
    const aux = { ...filter };
    aux[key] = value;
    setFilter(aux);
  };

  const onChangeProcessTime = (value) => {
    const aux = { ...filter };
    const key = value.key.toString();
    aux["range"] = value;
    if (value && PERSONALIZADO.key === key) {
      setOpenRangeCustom(true);
      setFilter(aux);
      return;
    }
    if (value && FIJO.key !== key) {
      const dates = procesTime[key]();
      aux["StartDate"] = dates.StartDate;
      aux["EndDate"] = dates.EndDate;
    } else {
      aux["StartDate"] = moment().format("L");
      aux["EndDate"] = moment().format("L");
    }
    setFilter(aux);
    setfixedDate(false);
  };

  const setDataFormOrganims = (analysisFilterEntity) => {
    const organimsEntities = analysisFilterEntity.organisms.map((item) => {
      return {
        Id: item.clientUnitId,
        entity: {
          clientUnitId: item.clientUnitId,
          name: item.name,
        },
        selected: item.excludeClientUnit,
      };
    });
    setOrganims(organimsEntities);
  };

  const setDataFormCompetitors = (analysisFilterEntity) => {
    const competitorsEntities = analysisFilterEntity.competitors.map((item) => {
      return {
        Id: item.competitorId,
        entity: {
          competitorId: item.competitorId,
          name: item.name,
        },
        selected: item.excludeCompetitor,
      };
    });
    setCompetitors(competitorsEntities);
  };

  const setCriteriaWords = (words) => {
    if (!words || words.length < 1) return [];
    const list = words.split("@");
    return list
      .filter((item) => item.length > 0)
      .map((item) => {
        return {
          word: "",
          words: item.split("|"),
        };
      });
  };

  const setData = () => {
    const { analysisFilterEntity } = props;
    const analysisFilter = {
      analysisFilterId: analysisFilterEntity.analysisFilterId,
      Name: analysisFilterEntity.analysisFilterName,
      RangeFrom: analysisFilterEntity.priceRangeFrom,
      RangeTo: analysisFilterEntity.priceRangeTo,
      range: {
        key: analysisFilterEntity.dateRangeId,
        value: analysisFilterEntity.dateRangeName,
      },
      StartDate: moment(analysisFilterEntity.startDate).format(
        formatDate.es.SHORT_UPPER,
      ),
      EndDate: moment(analysisFilterEntity.endDate).format(
        formatDate.es.SHORT_UPPER,
      ),
      Types: analysisFilterEntity.types,
      IncludeKey: setCriteriaWords(analysisFilterEntity.includeWords),
      ExcludeKey: setCriteriaWords(analysisFilterEntity.excludeWords),
      dateRangeUnitId: analysisFilterEntity.dateRangeUnitId,
      dateRangeQty: analysisFilterEntity.dateRangeQty,
      allowDatePicker:
        analysisFilterEntity.dateRangeId.toString() === FIJO.key ? false : true,
      Result: analysisFilterEntity.result === 1 ? true : false,
      ApplyToCompetitorDescription:
        analysisFilterEntity.applyToCompetitorDescription,
      SourceIds: analysisFilterEntity.sourceIds,
    };
    setDataFormOrganims(analysisFilterEntity);
    setDataFormCompetitors(analysisFilterEntity);
    setFilter(analysisFilter);
    setInitialTabsCount(analysisFilterEntity, analysisFilter);
    if (analysisFilter.range.key === PERSONALIZADO.key) {
      analysisFilter.dateRangeUnitId = analysisFilter.dateRangeUnitId;
      analysisFilter.dateRangeQty = analysisFilter.dateRangeQty;
    }
  };

  const defineMode = async () => {
    const sources = await getSources();
    // filter PAC
    const sourcesFiltered = sources.data.filter((item) => item.name !== "PAC");
    setSources(sourcesFiltered);

    if (Object.getOwnPropertyNames(props.analysisFilterEntity).length !== 0) {
      setData();
    } else {
      filterDefault.range = RangeDefault;
      const dates = procesTime[RangeDefault.key]();
      filterDefault.StartDate = dates.StartDate;
      filterDefault.EndDate = dates.EndDate;
      filterDefault.IncludeKey = [{ word: "", words: [] }];
      filterDefault.ExcludeKey = [{ word: "", words: [] }];
      setTabsCounts({ products: 0, organisms: 0, competitors: 0 });
      setFilter(filterDefault);
      setOrganims([]);
      setCompetitors([]);
    }
  };
  const setInitialTabsCount = (analysisFilterEntity, analysisFilter) => {
    const tabsCountsCopy = { ...tabsCounts };
    tabsCountsCopy.products =
      analysisFilter.IncludeKey.length + analysisFilter.ExcludeKey.length;
    tabsCountsCopy.organisms = analysisFilterEntity.organisms.length;
    tabsCountsCopy.competitors = analysisFilterEntity.competitors.length;
    setTabsCounts(tabsCountsCopy);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      defineMode();
    });
    return () => {
      clearTimeout(time);
    };
  }, [props.open]);

  const convertASrrayToString = (list) => {
    let str = "";
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      const strJoined = element.words.join("|");
      str = str.length > 0 ? ` ${str}@${strJoined}` : strJoined;
    }
    return str;
  };

  const getOrganimsById = (id) => {
    if (!props.analysisFilterEntity.organisms) return null;
    console.log(props.analysisFilterEntity.organisms);
    return props.analysisFilterEntity.organisms.find(
      (item) => item.clientUnitId === id,
    );
  };

  const getOrganims = (list) => {
    return organims.map((item) => {
      const entity = {
        ClientUnitId: item.entity.clientUnitId,
        ExcludeClientUnit: item.selected ? 1 : 0,
      };
      const organimsSaved = getOrganimsById(item.entity.clientUnitId);
      if (organimsSaved) {
        entity.analysisFilterClientUnitId =
          organimsSaved.analysisFilterClientUnitId;
      }
      return entity;
    });
  };

  const getCompetitorsById = (id) => {
    if (!props.analysisFilterEntity.competitors) return null;
    return props.analysisFilterEntity.competitors.find(
      (item) => item.competitorId === id,
    );
  };

  const getCompetitors = () => {
    return competitors.map((item) => {
      const entity = {
        CompetitorId: item.entity.competitorId,
        ExcludeCompetitor: item.selected ? 1 : 0,
      };
      const competitorsSaved = getCompetitorsById(item.entity.competitorId);
      if (competitorsSaved) {
        entity.analysisFilterCompetitorId =
          competitorsSaved.analysisFilterCompetitorId;
      }
      return entity;
    });
  };

  const getTypes = () => {
    if (!filter.Types || filter.Types.lengyh === 0) return null;
    return filter.Types.map((item) => {
      return {
        EntityTypeId: item.entityTypeId,
        name: item.name,
      };
    });
  };

  const handleSave = async () => {
    const request = {
      AnalysisFilterName: filter.Name,
      StartDate: ConvertDateStartToISOLarge(
        filter.StartDate,
        formatDate.es.SHORT_UPPER,
      ),
      EndDate: ConvertDateEndToISOLarge(
        filter.EndDate,
        formatDate.es.SHORT_UPPER,
      ),
      IncludeWords: convertASrrayToString(filter.IncludeKey),
      ExcludeWords: convertASrrayToString(filter.ExcludeKey),
      IsPreview: 0,
      AnalysisFilterModuleId: props.AnalysisFilterModuleId,
      DateRangeId: filter.range.key,
      Organisms: getOrganims(),
      Competitors: getCompetitors(),
      PriceRangeFrom: filter.RangeFrom,
      PriceRangeTo: filter.RangeTo,
      types: getTypes(),
      Result: filter.Result ? 1 : null,
      ApplyToCompetitorDescription: filter.ApplyToCompetitorDescription,
      SourceIds: filter.SourceIds,
    };

    if (parseInt(filter.range.key) === parseInt(PERSONALIZADO.key)) {
      request.DateRangeUnitId = filter.dateRangeUnitId;
      request.DateRangeQty = filter.dateRangeQty;
    }

    if (filter.analysisFilterId) {
      request.AnalysisFilterId = filter.analysisFilterId;
    }

    saveOrUpdate(request)
      .then((result) => {
        //debugger;
        filter.analysisFilterId = result.data.analysisFilterId;
        props.handleCloseWithSave(filter);
      })
      .catch((error) => {
        console.log(error);
        setOpenToaster(true);
        setOpenToasterMessage(
          message[error.entityKey] + message[error.msgErrorKey],
        );
        setTimeout(() => {
          setOpenToaster(false);
        }, 1500);
      });
    setfixedDate(false);
  };

  const handleClose = (value) => {
    setfixedDate(false);
    props.handleClose(value);
  };

  const changeList = (key, value) => {
    const aux = { ...filter };
    const list = value.length > 0 ? value : [{ word: "", words: [] }];
    aux[key] = list;
    setFilter(aux);
    countProducts(aux);
  };

  const addElement = (key, value) => {
    const aux = { ...filter };
    aux[key].push({ word: "", words: [] });
    setFilter(aux);
  };

  const countProducts = (analysisFilter) => {
    const tabsCountsCopy = { ...tabsCounts };
    const includeKey = analysisFilter.IncludeKey.filter(
      (items) => items.words.length > 0,
    );
    const excludeKey = analysisFilter.ExcludeKey.filter(
      (items) => items.words.length > 0,
    );
    tabsCountsCopy.products = includeKey.length + excludeKey.length;
    setTabsCounts(tabsCountsCopy);
  };

  const handleChangeOrganims = (list) => {
    setOrganims(list);
    setEntitySelected(list);
    countOrganims(list);
  };

  const countOrganims = (list) => {
    const tabsCountsCopy = { ...tabsCounts };
    tabsCountsCopy.organisms = list.length;
    setTabsCounts(tabsCountsCopy);
  };

  const handleChangeCompetitors = (list) => {
    setCompetitors(list);
    setEntitySelected(list);
    countCompetitors(list);
  };

  const countCompetitors = (list) => {
    const tabsCountsCopy = { ...tabsCounts };
    tabsCountsCopy.competitors = list.length;
    setTabsCounts(tabsCountsCopy);
  };

  const changeTabs = (tabSelected) => {
    if (TabsENUM.ORGANIMS === tabSelected) {
      setEntitySelected(organims);
    } else {
      setEntitySelected(competitors);
    }
  };

  const handleCloseRangeCustom = () => {
    setOpenRangeCustom(false);
  };

  const setRangeCustom = (value) => {
    const aux = { ...filter };
    aux["range"] = PERSONALIZADO;
    aux.dateRangeUnitId = value.range;
    aux.dateRangeQty = value.quantity;
    const startDate = moment()
      .subtract(value.quantity, MomentRange[value.range])
      .format("L");
    const endDate = moment().format("L");
    aux["StartDate"] = startDate;
    aux["EndDate"] = endDate;
    setFilter(aux);
    setOpenRangeCustom(false);
  };

  const validateError = () => {
    let error = [];
    if (props.AnalysisFilterModuleId !== 4) {
      error.push(
        tabsCounts.products === 0 &&
          tabsCounts.organisms === 0 &&
          tabsCounts.competitors === 0
          ? true
          : false,
      );
    }
    if (filter !== null) {
      error.push(filter.Name.trim().length === 0 ? true : false);
      error.push(filter.StartDate === null ? true : false);
      if (filter.StartDate !== null) {
        error.push(filter.StartDate.indexOf("_") > -1 ? true : false);
      }
      error.push(filter.EndDate === null ? true : false);
      if (filter.EndDate !== null) {
        error.push(filter.EndDate.indexOf("_") > -1 ? true : false);
      }
      if (filter.errorDate !== null && filter.errorDate !== undefined) {
        error.push(filter.errorDate.indexOf(true) === -1 ? false : true);
      }
    }

    return error.indexOf(true) === -1 ? false : true;
  };

  return (
    <>
      <FstModal
        open={props.open}
        title="Filtros de previsualización de reporte"
        handleClose={() => handleClose(false)}
        handleSave={handleSave}
        widthSize="lg"
        fullWidth={true}
        IsDisabledPrimary={validateError()}
        classContainer={"fst-Modal-Container"}
      >
        <div style={{ height: "560px" }}>
          <ReportFilter
            filter={filter}
            fixedDate={fixedDate}
            onChangeProcessTime={onChangeProcessTime}
            onChange={onChange}
            ModuleId={props.AnalysisFilterModuleId}
            handleError={onChangeError}
            sources={sources}
          />
          <AnalysisTabs
            onChange={onChange}
            onChangeList={(key, value) => changeList(key, value)}
            handleChangeOrganims={handleChangeOrganims}
            handleChangeCompetitors={handleChangeCompetitors}
            changeTabs={changeTabs}
            tabsCounts={tabsCounts}
            addElement={(key) => addElement(key)}
            entitySelected={entitySelected}
            ModuleId={props.AnalysisFilterModuleId}
            filter={filter}
          />
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
              zIndex: 800,
            }}
            open={openToaster}
          >
            <Alert severity="error">{openToasterMessage}</Alert>
          </Snackbar>
        </div>
      </FstModal>
      <RangeCustom
        open={openRangeCustom}
        filter={filter}
        close={handleCloseRangeCustom}
        save={(value) => setRangeCustom(value)}
      />
    </>
  );
};

export default ManageReportFilter;
