import React from "react";
import { Grid, List, ListItem, Divider } from "@material-ui/core";
import { Fragment } from "react";

export const Managed = (props) => {
  const { result } = props;
  return (
    <Grid container spacing={4} style={{ width: "400px" }}>
      <Grid item xs={12} lg={12}>
        <h3 style={{ margin: 0 }}>Resultado</h3>
        <List className="py-0">
          <ListItem className="d-flex justify-content-between align-items-center">
            <Grid container spacing={4}>
              <Grid item xs={12} lg={6}>
                CONVOCATORIA
              </Grid>
              <Grid item xs={12} lg={6}>
                ESTADO
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          {result.map((item) => (
            <Fragment key={item.tenderNumber}>
              <ListItem className="d-flex justify-content-between align-items-center">
                <Grid container spacing={4}>
                  <Grid item xs={12} lg={6}>
                    {item.TenderNumber}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    {item.Result}
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
