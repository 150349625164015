import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootHeader: {
    display: "block",
    padding: 13,
  },
  headerTypography: {
    display: "inline",
    fontSize: "1.10rem",
  },
}));
