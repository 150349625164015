import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Controls } from "../../utils/controls";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Countries, Idiom } from "./CountryObject";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  InputWidth: {
    width: "100%",
  },
}));

const CompanyManagerBody = (props) => {
  const classes = useStyles();
  const { variant } = Controls.TextField;

  const getListDeault = (listApp, listItem) => {
    const listDefault = listItem.filter((items) => listApp.id === items.id);
    return listDefault[0];
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant={variant}
            label="Empresa RUC"
            size="small"
            className={classes.InputWidth}
            value={props.data.ruc}
            onChange={(event) => {
              props.handlerChange("ruc", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant={variant}
            label="Inquilino"
            size="small"
            className={classes.InputWidth}
            value={props.data.tenantId}
            onChange={(event) => {
              props.handlerChange("tenantId", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant={variant}
            label="Cliente"
            size="small"
            className={classes.InputWidth}
            value={props.data.clientId}
            onChange={(event) => {
              props.handlerChange("clientId", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant={variant}
            label="Cliente Secreto"
            size="small"
            className={classes.InputWidth}
            value={props.data.clientSecret}
            onChange={(event) => {
              props.handlerChange("clientSecret", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            options={Countries}
            className={classes.Autocomplete}
            openOnFocus
            getOptionLabel={(option) => option.value}
            defaultValue={getListDeault(props.data.defaultCountry, Countries)}
            onChange={(event, value) => {
              props.handlerChange("countryCode", value.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Paises"
                variant={variant}
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            options={Idiom}
            className={classes.Autocomplete}
            openOnFocus
            getOptionLabel={(option) => option.value}
            defaultValue={getListDeault(props.data.defaultLang, Idiom)}
            onChange={(event, value) => {
              props.handlerChange("language", value.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Idioma"
                variant={variant}
                size="small"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyManagerBody;
