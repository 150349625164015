import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import * as ModalAction from "../../../actions/modalAction";
import FstModal from "../../../components/modal/FstModal";
import { useStoreDataClient } from "../../../utils/hooks/useStoreData";
import ModalData from "./modal-data/ModalData";
import { generateData } from "./modal-data/modalDataGenerator";

const FstModalDetail = (props) => {
  const queryClient = useStoreDataClient(props.idModal);
  const [allData, setAllData] = React.useState({});
  const [dataDetail, setDataDetail] = React.useState("");
  const [queryData, setQueryData] = React.useState([]);
  const intl = useIntl();

  const handleClose = () => {
    props.CloseModal();
  };

  useEffect(() => {
    setQueryData(queryClient.getDataQuery());
  }, [queryClient]);

  useEffect(() => {
    if (!queryData || !queryData.data || queryData.data.length === 0) {
      return;
    }
    let detail = queryData.data[0].detail;
    let data = queryData.data[0];
    setDataDetail(detail);
    let parsedData = generateData(detail, data, intl);
    setAllData(parsedData);
  }, [queryData]);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <FstModal
      open={props.activeModal}
      title={props.titleModal}
      handleClose={handleClose}
      handleSave={handleClose}
      setting={{ BtnNameSave: "Cerrar" }}
      onlyOK={true}
      widthSize="md"
      fullWidth={true}
      classContainer={"fst-Modal-Container"}
    >
      <ModalData data={allData} modalName={dataDetail} />
    </FstModal>
  );
};

const mapStateToProps = (state, props) => {
  const { modalReducer } = state;
  const { idModal, setting, getIsActive } = modalReducer;
  const titleModal = setting ? setting.titleModal : "";
  const activeModal = getIsActive ? getIsActive(props.id) : false;

  return {
    ...state,
    activeModal,
    titleModal,
    idModal,
  };
};

export default connect(mapStateToProps, { ...ModalAction })(FstModalDetail);
