import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import useDownloadLang from "./langDownload";
import langPE from "../lang/format-message/es-PE.json";
import configPE from "../lang/format-form/ObjectFormPE";

const langContext = React.createContext();

const LangProvider = ({ children }) => {
  const lang = useDownloadLang();

  const [mensaje, setMensaje] = useState(langPE);
  const [locale, setLocale] = useState("es-PE");
  const [configForm, setConfigForm] = useState(configPE);

  const setLanguaje = (language) => {
    const langProperty = lang.getLangDetail(language);
    setMensaje(langProperty.mensaje);
    setLocale(langProperty.locale);
    setConfigForm(langProperty.configForm);
  };

  return (
    <langContext.Provider
      value={{ setLanguaje: setLanguaje, configForm: configForm }}
    >
      <IntlProvider locale={locale} messages={mensaje}>
        {children}
      </IntlProvider>
    </langContext.Provider>
  );
};

export { LangProvider, langContext };
