import { useIntl } from "react-intl";

const useIntlColumns = (idGrid, id, completeHeaders) => {
  const intl = useIntl();
  const headersFromIntlJson = intl.formatMessage({ id: id });
  const gridLocalStorageId = "grid" + idGrid;
  let store = JSON.parse(localStorage.getItem(gridLocalStorageId));

  // filter completeHeaders AND set their visible field whether they are in the json headers or not
  // usually completeHeaders includes all columns set in code and consequently getHeader will have only the ones set in json
  const getHeader = completeHeaders.filter((item, index) => {
    const format = { id: item.id, defaultMessage: item.headerText };
    item.visible = false;
    if (headersFromIntlJson.indexOf(item.id) !== -1) {
      item.headerText = intl.formatMessage(format);
      item.visible = true;
      return item;
    }
  });

  if (store !== null) {
    // filter store.columns values if they are not in getHeader (removing a column from json case) (this also fixes an uncatched error, so it's important)
    store.columns = store.columns.filter((existingItem) =>
      getHeader.find((headerItem) => headerItem?.field === existingItem?.field),
    );

    getHeader.map((row, index) => {
      let indexAdd = 0;
      // verifies if the column already exists and went through modifications for better storage in localStorage
      // if it's not in the store, it will be added
      // the field "valided" (typo, its "validated") stablishes if it's been through the said modifications or not.

      // filter any null values inside store.columns
      store.columns = store.columns.filter((item) => item !== null);

      // verify if the column already exists in the store, if it's not then it will be added
      const isColumnValidated = store.columns.some((item, ind) => {
        indexAdd = ind; // here we save the index of the column in the store.columns (its equivalent to findIndex)
        const isValidated = item.hasOwnProperty("valided")
          ? item.valided
          : true;
        return item.field === row.field && isValidated;
      });

      if (isColumnValidated) {
        Object.keys(row).forEach((key) => {
          if (!store.columns[indexAdd].hasOwnProperty(key)) {
            store.columns[indexAdd][key] = row[key];
          }
        });
        store.columns[indexAdd].showInColumnChooser = row.hasOwnProperty(
          "showInColumnChooser",
        )
          ? row.showInColumnChooser
          : true;
        store.columns[indexAdd].width = store.columns[indexAdd].hasOwnProperty(
          "width",
        )
          ? store.columns[indexAdd].width
          : row.width;
        store.columns[indexAdd].allowSorting = row.hasOwnProperty(
          "allowSorting",
        )
          ? row.allowSorting
          : true;
        store.columns[indexAdd].valided = true;
        store.columns[indexAdd].uid = "grid-column" + index;
        store.columns[indexAdd].intlIndex = index;
        store.columns[indexAdd].idLag = row.id; // idLag == intlId: which means it's the intl json key field (eg. cols.Nombre)

        let keysToRemoveIfNotInHeader = [
          "isPrimaryKey",
          "allowEditing",
          "autoFitColumns",
          "commands",
          "displayAsCheckBox",
        ];
        keysToRemoveIfNotInHeader.forEach((keyToRemove) => {
          if (
            store.columns[indexAdd].hasOwnProperty(keyToRemove) &&
            !row.hasOwnProperty(keyToRemove)
          ) {
            delete store.columns[indexAdd][keyToRemove];
          }
        });

        if (store.columns[indexAdd].index !== indexAdd) {
          store.columns[indexAdd].index = indexAdd;
        }
      } else {
        let auxItem = {};
        Object.keys(row).forEach((key) => {
          if (!store.columns[0].hasOwnProperty(key)) {
            auxItem[key] = row[key];
          }
        });
        Object.keys(store.columns[0]).forEach((key) => {
          auxItem[key] = store.columns[0][key];
          if (row.hasOwnProperty(key)) {
            auxItem[key] = row[key];
          } else if (key === "foreignKeyField") {
            auxItem.foreignKeyField = row.field;
          } else if (key === "showInColumnChooser") {
            auxItem[key] = true;
          } else if (key === "allowSorting") {
            auxItem[key] = row[key] ? row[key] : false;
          }
        });
        auxItem["idLag"] = row.id; // idLag == intlId: which means it's the intl json key field (eg. cols.Nombre)
        auxItem["uid"] = "grid-column" + index;
        auxItem["index"] = index;
        auxItem["intlIndex"] = index;
        store.columns.splice(index, 0, auxItem);
      }
    });

    localStorage.setItem(gridLocalStorageId, JSON.stringify(store));
  }

  return {
    getHeader: store === null ? getHeader : store.columns,
  };
};

export default useIntlColumns;
