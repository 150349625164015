import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { FormattedForm } from "../../intl/utils/format-elements/formattedForm";
import { injectIntl } from "react-intl";

class GetSelectComponet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleData() {
    const { item, dataExt } = this.props;
    if (Object.keys(item).some((key) => key === "template")) {
      return item.template(this.props);
    } else if (Object.keys(item).some((key) => key === "idValue")) {
      return dataExt[item.idValue];
    } else {
      return item.label;
    }
  }

  handleDataGrid() {
    const { item, data, dataExt } = this.props;
    if (Object.keys(item).some((key) => key === "template")) {
      return item.template(this.props);
    } else {
      return data;
    }
  }

  render() {
    const { item, data } = this.props;

    switch (item.type) {
      case "Grid":
        return (
          <div style={item.styleParent}>
            <FormattedForm id={item.id_ff} data={this.handleDataGrid()} />
          </div>
        );
      case "EtqH3":
        return (
          <div style={item.styleParent}>
            <h3>{this.handleData()}</h3>
          </div>
        );
      case "EtqH4":
        return (
          <div style={item.styleParent}>
            <h4 style={item.style}>{this.handleData()}</h4>
          </div>
        );
      case "EtqH5":
        return (
          <div style={item.styleParent}>
            <h5 style={item.style}>{this.handleData()}</h5>
          </div>
        );
      case "Span":
        return (
          <div style={item.styleParent}>
            <span style={item.style}>{this.handleData()}</span>
          </div>
        );
    }
  }
}

export default injectIntl(GetSelectComponet);
