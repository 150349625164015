import { makeStyles } from "@material-ui/core/styles";
export const StylePage = makeStyles(() => ({
  content: {
    padding: "24px",
    height: "inherit",
  },
  buttonBack: {
    textAlign: "right",
    paddingRight: "30px",
    paddingTop: "5px",
    width: "100%",
  },
  SubContent: {
    height: "inherit",
  },
  paddingLeft20: {
    paddingLeft: "20px",
  },
}));
