import { DataManager, Query } from "@syncfusion/ej2-data";
import moment from "moment";
import React, { useState } from "react";
import { connect } from "react-redux";
import { formatShortDate } from "utils/common";
import { dateTimeShortFormat } from "utils/common";
import {
  DeletePenalties,
  GetPenaltyList,
  ManagePenalty,
} from "views/penalties/service/penaltyService";
import AdminSnackbars from "views/utils-admin/AdminSnackbars";
import * as SpinnerActions from "../../actions/spinnerActions";
import { FstGrid } from "../../components/grid/FstGrid";
import CustomDatePicker from "./all-details/CustomDatePicker";
import { CommentStyles } from "./style/TicketStyle";

export const SanctionsHeader = (penaltyReasonsList) => [
  {
    id: "agreedDeliveryDate",
    field: "agreedDeliveryDate",
    headerText: "Fecha de entrega pactada",
    width: "100",
    textAlign: "Center",
    template: (props) => (
      <span>
        {moment(props.agreedDeliveryDate).format(dateTimeShortFormat)}
      </span>
    ),
    allowEditing: true,
    editTemplate: (args) => {
      return (
        <CustomDatePicker
          id={"agreedDeliveryDate"}
          args={args}
          defaultValue={args.agreedDeliveryDate}
        />
      );
    },
  },
  {
    id: "actualDeliveryDate",
    field: "actualDeliveryDate",
    headerText: "Fecha entrega efectiva",
    width: "100",
    textAlign: "Center",
    template: (props) => (
      <span>
        {moment(props.actualDeliveryDate).format(dateTimeShortFormat)}
      </span>
    ),
    allowEditing: true,
    editTemplate: (args) => {
      return (
        <CustomDatePicker
          id={"actualDeliveryDate"}
          args={args}
          defaultValue={args.actualDeliveryDate}
        />
      );
    },
  },
  {
    id: "daysLate",
    field: "daysLate",
    headerText: "Días de atraso",
    width: "100",
    textAlign: "Center",
    allowEditing: true,
    editType: "numeric",
  },
  {
    id: "finePercentage",
    field: "finePercentage",
    headerText: "Multa (%)",
    width: "100",
    textAlign: "Center",
    allowEditing: true,
    editType: "numeric",
  },
  {
    id: "others",
    field: "others",
    headerText: "Otros",
    width: "100",
    textAlign: "Center",
    allowEditing: true,
  },
  {
    id: "penaltyReasonId",
    field: "penaltyReasonId",
    headerText: "Resultado",
    width: "120",
    textAlign: "Left",
    allowEditing: true,
    editType: "dropdownedit",
    editParams: {
      params: {
        actionComplete: () => false,
        allowFiltering: true,
        dataSource: new DataManager(penaltyReasonsList),
        fields: { text: "name", value: "id" },
        query: new Query(),
      },
    },
    template: (props) => {
      return <span>{props.penaltyReasonDesc}</span>;
    },
  },
  {
    id: "cols.Acciones",
    headerText: "Acciones",
    hideToExport: true,
    width: "100px",
    commands: [
      {
        type: "Edit",
        buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
      },
      {
        type: "Remove",
        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
      },
      {
        type: "Save",
        buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
      },
      {
        type: "Cancel",
        buttonOption: {
          cssClass: "e-flat",
          iconCss: "e-cancel-icon e-icons",
        },
      },
    ],
  },
];

const Sanctions = (props) => {
  const classes = CommentStyles();
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [allData, setAllData] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [openToasterError, setOpenToasterError] = useState(false);
  const [penaltiesToDelete, setPenaltiesToDelete] = useState([]);
  const [penaltiesToAdd, setPenaltiesToAdd] = useState([]);

  const getData = async (
    pag,
    filter,
    penaltiesDeleted = [],
    penaltiesAdded = [],
    setData = true,
  ) => {
    props.SpinnerShow("Cargando...");
    let response = await GetPenaltyList();
    const data = response.data.map((item) => {
      return { ...item, ...JSON.parse(item.data) };
    });
    // filter items that are just deleted
    const filteredData = data.filter((item) => {
      return !penaltiesDeleted.find(
        (penaltyId) => penaltyId === item.penaltyId,
      );
    });
    // filter items that have TenderId null or are about to be added
    const filteredTenderIds = filteredData.filter((item) => {
      return (
        item.tenderId === props.data.tenderId ||
        penaltiesAdded.find((penaltyId) => penaltyId === item.penaltyId)
      );
    });
    if (setData) setAllData(filteredTenderIds);
    props.SpinnerHide();
    return filteredTenderIds;
  };

  const remove = (btn, item) => {
    if (btn.type === "Remove") {
      setItemToDelete(item);
      setDeleteConfirm(true);
    }
  };

  const actionCustomRow = (btn, item) => {
    remove(btn, item);
  };

  const handleDelete = async (item) => {
    setDeleteConfirm(false);
    props.SpinnerShow("");
    props.onChangePenalties([{ PenaltyId: item.penaltyId, toDelete: true }]);

    let newPenaltiesToDelete = [...penaltiesToDelete, item.penaltyId];
    setPenaltiesToDelete(newPenaltiesToDelete);

    setTimeout(async () => {
      await getData(null, null, newPenaltiesToDelete, penaltiesToAdd);
      props.SpinnerHide();
    }, 250);
  };

  const showToasterError = () => {
    setOpenToasterError(true);
    setTimeout(() => {
      setOpenToasterError(false);
    }, 4500);
  };

  const handleSave = async (item) => {
    if (!item.penaltyReasonId) {
      showToasterError();
      return;
    }
    let data = {
      TenderId: item.tenderId,
      PenaltyReasonId: item.penaltyReasonId,
      PenaltyId: +item.penaltyId,
      Data: JSON.stringify({
        agreedDeliveryDate: moment(item.agreedDeliveryDate, "DD/MM/YYYY")
          .toDate()
          .toISOString(),
        actualDeliveryDate: moment(item.actualDeliveryDate, "DD/MM/YYYY")
          .toDate()
          .toISOString(),
        daysLate: item.daysLate,
        finePercentage: item.finePercentage,
        others: item.others,
      }),
    };
    props.SpinnerShow("");
    let modifiedPenalty = await ManagePenalty([data]);
    data.PenaltyId = modifiedPenalty.data[0].penaltyId;

    props.onChangePenalties([data]);
    let newPenaltiesToAdd = [...penaltiesToAdd, data.PenaltyId];
    setPenaltiesToAdd(newPenaltiesToAdd);
    setTimeout(async () => {
      await getData(null, null, penaltiesToDelete, newPenaltiesToAdd);
      props.SpinnerHide();
    }, 250);
  };

  const exportExcel = async (pag, filter) => {
    let filteredTenderIds = await getData(
      pag,
      filter,
      penaltiesToDelete,
      penaltiesToAdd,
      false,
    );
    let dataToExport = filteredTenderIds.map((item) => {
      return {
        ...item,
        agreedDeliveryDate: item.agreedDeliveryDate
          ? formatShortDate(item.agreedDeliveryDate)
          : "",
        actualDeliveryDate: item.actualDeliveryDate
          ? formatShortDate(item.actualDeliveryDate)
          : "",
        penaltyReasonId: item.penaltyReasonDesc,
      };
    });
    return dataToExport;
  };

  const dataGrid = {
    Getdata: getData,
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      allowEditing: true,
      allowAdding: true,
    },
    Header: SanctionsHeader(props.penaltyReasonsList),
    ActionCustomRow: actionCustomRow,
    height: props.height - 120,
    ToolBar: {
      Add: true,
      AddTitle: "Agregar",
      Export: true,
    },
    ExportFileName: `Sanciones - ${props.data?.tenderNumber}.xlsx`,
    exportFunc: exportExcel,
    allowSorting: false,
    allowPaging: false,
    AddDefault: true,
    Updated: handleSave,
    idGrid: "Sanctions",
  };

  return (
    <>
      <div className={classes.content}>
        <FstGrid
          data={dataGrid}
          uniqValue={uniqValue}
          result={{
            result: allData,
            count: allData.length,
          }}
          reloadDefault={true}
        />
        <AdminSnackbars
          openToaster={false}
          openToasterError={openToasterError}
          errorTitle={"Debe completar el Resultado de la Sanción"}
          modalTitle={`Eliminar sanción`}
          modalClose={() => {
            setItemToDelete(null);
            setDeleteConfirm(false);
          }}
          deleteConfirm={deleteConfirm}
          modalMessage={`¿Está seguro que desea eliminar la sanción?`}
          modalSuccess={() => handleDelete(itemToDelete)}
        />
      </div>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(Sanctions);
