import React from "react";
import LoadingOverlay from "react-loading-overlay";
import { connect } from "react-redux";

const FstSpinner = (props) => {
  return (
    <LoadingOverlay
      active={props.active}
      spinner
      text={props.message}
      styles={{
        overlay: (base) => ({
          ...base,
          background: "rgba(0, 0, 0, 0.2)",
          zIndex: 9999,
        }),
        spinner: (base) => ({
          ...base,
          width: "60px",
          "& svg circle": {
            stroke: "#3f51b5",
          },
        }),
        content: (base) => ({
          ...base,
          color: "black",
        }),
      }}
    >
      {props.children}
    </LoadingOverlay>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.spinnerReducer;
};

export default connect(mapStateToProps, {})(FstSpinner);
