import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getTags } from "../services/tagsService";
import { getInternalStatus } from "../services/internalStatusService";
import { getSellers } from "../../seller/services/sellersService";
import { getCategories } from "../services/categoriesService";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import * as SettingsChangeActions from "actions/settingsChangeActions";

const EditAssignments = (props) => {
  const [tags, setTags] = useState([]);
  const [internalStatus, setInternalStatus] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [categories, setCategories] = useState([]);

  const getCategoryList = async () => {
    const listCategory = await getCategories();
    setCategories(listCategory.data);
  };

  const getInternalStatusList = async () => {
    const listInternalStatus = await getInternalStatus();
    setInternalStatus(listInternalStatus.data);
  };

  const getSellersList = async () => {
    const listSellers = await getSellers();
    setSellers(listSellers.data);
  };

  const getTagList = async () => {
    const listTags = await getTags();
    setTags(listTags.data);
  };

  const fetchAllData = async () => {
    await getCategoryList();
    await getInternalStatusList();
    await getSellersList();
    await getTagList();
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  useEffect(() => {
    if (props.settingsChangeActive) {
      fetchAllData();
      props.SettingsChangeDeactivate();
    }
  }, [props.settingsChangeActive]);

  return (
    <>
      <Grid container spacing={5} id="content-edit-tender">
        <Grid item xs={12} sm={12} md={12}>
          <Autocomplete
            options={internalStatus}
            className="autocomplete-class"
            style={{ marginBottom: 16 }}
            size="medium"
            id="internalStatus"
            openOnFocus
            value={props.assignments.internalStatus}
            getOptionLabel={(option) => option.description}
            onChange={(event, newValue) => {
              props.handleAssignments("internalStatus", newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Situacion" variant="outlined" />
            )}
          />
          <Autocomplete
            multiple
            id="tags"
            className="autocomplete-class"
            style={{ marginTop: 16, marginBottom: 16 }}
            size="medium"
            value={props.assignments.tags}
            options={tags}
            onChange={(event, newValue) => {
              props.handleAssignments("tags", newValue);
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Etiquetas" />
            )}
          />
          <Autocomplete
            multiple
            size="medium"
            className="autocomplete-class"
            style={{ marginTop: 16, marginBottom: 16 }}
            id="sellers"
            value={props.assignments.sellers}
            options={sellers}
            onChange={(event, newValue) => {
              props.handleAssignments("sellers", newValue);
            }}
            getOptionLabel={(option) =>
              `${option.lastName}, ${option.firstName}`
            }
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Ejecutivos" />
            )}
          />

          <Autocomplete
            multiple
            size="medium"
            className="autocomplete-class"
            style={{ marginTop: 16, marginBottom: 16 }}
            id="categories"
            value={props.assignments.categories}
            options={categories}
            onChange={(event, newValue) => {
              props.handleAssignments("categories", newValue);
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Segmento de Mercado"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.settingsChangeReducer,
  };
};

export default connect(mapStateToProps, { ...SettingsChangeActions })(
  EditAssignments,
);
