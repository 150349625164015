import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FstModal from "../../components/modal/FstModal";
import ManagePenaltyForm from "./ManagePenaltyForm";
import moment from "moment";
import * as PenaltyActions from "../../actions/penaltyActions";
import * as SpinnerActions from "../../actions/spinnerActions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ManagePenalty = (props) => {
  const spinnerMessage = "Creando Penalidad...";
  const [isDisableModal, setIsDisabledModal] = useState(false);
  const [selectedDate] = useState(moment());
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);

  const penaltyDefault = {
    CustomerName: "",
    CustomerCode: "",
    SaleOrder: "",
    PurchaseOrderCode: "",
    InvoiceNumber: "",
    InvoiceDate: selectedDate.format("L"),
    InvoiceAmount: "",
    PenaltyAmount: "",
    Responsible: "",
    CaseNumber: "",
    Reason: null,
    isTender: false,
    Observations: "",
  };
  const [Penalty, setPenalty] = useState(penaltyDefault);
  useEffect(() => {
    setIsDisabledModal(false);
  }, []);

  const handleClose = () => {
    setPenalty(penaltyDefault);
    props.handleClose(false);
  };

  const handleSave = async () => {
    const request = {
      clientUnitId: null,
      PenaltyReasonId: Penalty.Reason ? Penalty.Reason.penaltyReasonId : null,
      TenderId: Penalty.isTender ? 1 : 0,
      Data: JSON.stringify({
        customerName: Penalty.CustomerName,
        customerCode: Penalty.CustomerCode,
        saleOrder: Penalty.SaleOrder,
        purchaseOrderCode: Penalty.PurchaseOrderCode,
        invoiceNumber: Penalty.InvoiceNumber,
        invoiceDate: Penalty.InvoiceDate
          ? moment(Penalty.InvoiceDate, "DD/MM/YYYY").toISOString()
          : null,
        invoiceAmount: Penalty.InvoiceAmount,
        penaltyAmount: Penalty.PenaltyAmount,
        responsible: Penalty.Responsible,
        caseNumber: Penalty.CaseNumber,
        description: Penalty.Observations,
      }),
    };

    props.SpinnerShow(spinnerMessage);
    await props.ManagePenaltySave([request]);
    props.SpinnerHide();
    setPenalty(penaltyDefault);
    showToaster();
    props.handleClose(true);
  };

  const showToaster = (value) => {
    setOpenToasterMessage("Penalidad creada exitosamente");
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 3000);
  };

  const handleChange = (key, value) => {
    const aux = { ...Penalty };
    aux[key] = value;
    if (key === "Customer" && value && value.Code) {
      aux["CustomerCode"] = value.Code;
    }
    setPenalty(aux);
  };

  useEffect(() => {
    const isDisabled =
      Penalty.Reason && Penalty.PenaltyAmount && Penalty.InvoiceDate;
    setIsDisabledModal(!isDisabled);
  }, [Penalty]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
      <FstModal
        open={props.open}
        title="Agregar Penalidad"
        handleClose={handleClose}
        handleSave={handleSave}
        widthSize="md"
        IsDisabledPrimary={isDisableModal}
      >
        <ManagePenaltyForm Penalty={Penalty} onChange={handleChange} />
      </FstModal>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.penaltyReducer;
};
export default connect(mapStateToProps, {
  ...PenaltyActions,
  ...SpinnerActions,
})(ManagePenalty);
