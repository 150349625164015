import React, { useState, useEffect } from "react";
import { Controls } from "../../utils/controls";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { FormStyles } from "./style/style";
import LegalDocumentFormHeader from "./LegalDocumentFormHeader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import DateFnsUtils from "@date-io/date-fns";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GetBankList } from "../bank/services/BankService";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Tooltip from "@material-ui/core/Tooltip";
import { formatDate } from "../../utils/common";
import { PenaltyMode } from "../../types";
import * as SettingsChangeActions from "actions/settingsChangeActions";
import { useIntl } from "react-intl";

const LegaDocumentForm = (props) => {
  const classes = FormStyles();
  const [bankList, setBankList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [subject, setSubject] = useState({ id: 0, description: "" });
  const [subjectList, setSubjectList] = useState([]);
  const { variant } = Controls.TextField;
  const intl = useIntl();

  const getBanks = async () => {
    const result = await GetBankList({});
    setBankList(result.data);
  };

  const getDocumentsStatus = () => {
    const list = [];
    const objectArray = Object.entries(props.legalDocumentWTStatus);
    objectArray.forEach(([key, value]) => {
      list.push({ Id: key, Description: value });
    });
    setStatusList(list);
  };

  useEffect(() => {
    const currencyListActives = props.currencyList.filter(
      (item) => item.active,
    );
    setCurrencyList(currencyListActives);
    getBanks();
    getDocumentsStatus();
    getSubject();
  }, [props.setting]);

  useEffect(() => {
    if (props.settingsChangeActive) {
      getBanks();
      props.SettingsChangeDeactivate();
    }
  }, [props.settingsChangeActive]);

  const getSubject = () => {
    let list = props.Ticket.ObjectSubject;
    let selectSubject = props.Ticket.Subject;
    if (props.setting.Mode === "EDIT" || props.setting.Mode === "VIEW") {
      selectSubject = list.filter(
        (item) => item.description === props.Ticket.Subject,
      );
    } else {
      selectSubject = list.filter((item) => item.id === 0);
    }
    setSubjectList(list);
    setSubject(selectSubject[0]);
  };

  return (
    <>
      {props.setting.Mode !== PenaltyMode.CREATE && props.tender && (
        <LegalDocumentFormHeader tender={props.tender} />
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            variant={variant}
            id="number"
            InputProps={{
              readOnly: props.isViewMode,
            }}
            label="Numero"
            autoComplete="off"
            className={classes.InputWidth}
            value={props.Ticket.Number}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("Number", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              inputVariant={variant}
              format="dd/MM/yyyy"
              required
              autoComplete="off"
              id="StartDate"
              label="Emision"
              className={classes.KeyboardDatePicker}
              inputValue={props.Ticket.StartDate}
              onChange={(event, newValue) => {
                props.onChange("StartDate", newValue);
              }}
              InputProps={{
                readOnly: props.isViewMode,
              }}
              readOnly={props.isViewMode}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disableToolbar
              required
              InputProps={{
                readOnly: props.isViewMode,
              }}
              readOnly={props.isViewMode}
              autoOk={true}
              inputVariant={variant}
              format="dd/MM/yyyy"
              autoComplete="off"
              id="EndDate"
              label="Vencimiento"
              className={classes.KeyboardDatePicker}
              inputValue={props.Ticket.EndDate}
              onChange={(event, newValue) => {
                props.onChange("EndDate", newValue);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            options={statusList}
            className={classes.Autocomplete}
            disabled={props.isViewMode}
            id="status"
            openOnFocus
            getOptionLabel={(option) => option.Description}
            value={props.Ticket.Status}
            onChange={(event, newValue) => {
              props.onChange("Status", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Estado"
                required
                variant={variant}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Autocomplete
            options={subjectList}
            required
            id="Subject"
            openOnFocus
            disabled={props.isViewMode}
            autoComplete={false}
            className={classes.Autocomplete}
            getOptionLabel={(option) => option.description}
            value={props.Ticket.Subject}
            onChange={(event, newValue) => {
              props.onChange("Subject", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Motivo"
                variant={variant}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            options={currencyList}
            required
            id="currency"
            openOnFocus
            disabled={props.isViewMode}
            autoComplete={false}
            className={classes.Autocomplete}
            getOptionLabel={(option) => option.description}
            value={props.Ticket.Currency}
            onChange={(event, newValue) => {
              props.onChange("Currency", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Moneda"
                variant={variant}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <CurrencyTextField
            variant={variant}
            label="Monto"
            className={classes.InputWidth}
            currencySymbol={
              props.Ticket.Currency ? props.Ticket.Currency.symbol : ""
            }
            value={props.Ticket.Value}
            autoComplete={false}
            decimalCharacter="."
            required
            InputProps={{
              readOnly: props.isViewMode,
            }}
            digitGroupSeparator=","
            onChange={(e, value) => props.onChange("Value", value)}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            variant={variant}
            id="Reference"
            label="Referencia"
            autoComplete="off"
            InputProps={{
              readOnly: props.isViewMode,
            }}
            className={classes.InputWidth}
            value={props.Ticket.Reference}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("Reference", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Autocomplete
            options={bankList}
            required
            id="bank"
            openOnFocus
            disabled={props.isViewMode}
            autoComplete={false}
            className={classes.Autocomplete}
            getOptionLabel={(option) => option.bankName}
            value={props.Ticket.Bank}
            onChange={(event, newValue) => {
              props.onChange("Bank", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label={intl.formatMessage({
                  id: "title.Banco.ModalGestionGarantia",
                  defaultMessage: "Banco Emisor",
                })}
                variant={variant}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextField
            variant={variant}
            id="fileName"
            label="Adjunto"
            className={classes.InputWidth + " Input-fileLoad"}
            value={props.Ticket.Document.fileName}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <TextField
            variant={variant}
            id="comments"
            label="Nuevo comentario"
            style={{ width: "100%" }}
            value={props.Ticket.Comments}
            autoComplete="off"
            multiline
            InputProps={{
              readOnly: props.isViewMode,
            }}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("Comments", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} className={classes.btnCenter}>
          <input
            accept="image/*,.pdf,.doc,.docx"
            style={{ display: "none" }}
            id="upload-button-file"
            type="file"
            onInput={(e) => {
              props.handleFileUpload(e);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <Tooltip id="upload-button-file" title="Adjuntar">
            <label htmlFor="upload-button-file">
              <IconButton
                color="secondary"
                disabled={props.isViewMode}
                aria-label="upload picture"
                component="span"
              >
                <CloudUploadIcon />
              </IconButton>
            </label>
          </Tooltip>

          <Tooltip
            id="download-button-file"
            className="download-button-file-class"
            title="Descargar"
          >
            <label htmlFor="download-button-file">
              <IconButton
                onClick={props.handleFileDownload}
                color="primary"
                aria-label="download picture"
                component="span"
                disabled={
                  !props.Ticket.DocumentFiles.length > 0 || props.isViewMode
                }
              >
                <CloudDownloadIcon />
              </IconButton>
            </label>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={12} md={9}></Grid>

        <Grid item xs={12} sm={12} md={3} className={classes.btnCenter}>
          <FormControlLabel
            control={
              <Switch
                size="medium"
                disabled={props.isViewMode}
                checked={props.Ticket.Active}
                onChange={(event) => {
                  const { checked } = event.target;
                  props.onChange("Active", checked);
                }}
                name="active"
                color="primary"
              />
            }
            label="Activa"
            labelPlacement="end"
          />
        </Grid>
      </Grid>

      {props.Ticket.DocumentComments.map((item) => {
        return (
          <Grid
            container
            spacing={2}
            className={classes.ListComments}
            key={item.legalDocumentCommentId}
          >
            <Grid item xs={12} sm={12} md={7} key={item.comment}>
              <TextField
                disabled
                style={{ width: "100%" }}
                value={item.comment}
                multiline
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <TextField
                //label="Usuario"
                disabled
                style={{ width: "100%" }}
                value={item.updatedByUserName}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                disabled
                style={{ width: "100%" }}
                value={formatDate(item.lastUpdate)}
              />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { app } = state;
  return {
    ...ownProps,
    legalDocumentWTStatus: app.legalDocumentWTStatus,
    currencyList: app.currencyList,
    ...state.settingsChangeReducer,
  };
};

export default connect(mapStateToProps, { ...SettingsChangeActions })(
  LegaDocumentForm,
);
