export const PenaltyType = {
  ALL_PENALTY: "ALL_PENALTY",
  ALL_PENALTY_ERROR: "ALL_PENALTY_ERROR",
  GET_PENALTY: "GET_PENALTY",
  ALL_PENALTY_DEFAULT: "ALL_PENALTY_DEFAULT",
};

export const PenaltyMode = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
};
