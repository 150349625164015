import { TenderType } from "../types";

const INITIAL_STATE = {
  result: null,
  reloadDefault: false,
  error: null,
  count: 0,
  page: 0,
  pageSize: 0,
  filter: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TenderType.ALL_TENDERS:
      return {
        ...state,
        result: action.payload,
        error: null,
        count: action.count,
        page: action.page,
        pageSize: action.pageSize,
        reloadDefault: action.reloadDefault,
        filter: action.filter,
      };
    case TenderType.ALL_TENDERS_ERROR:
      return {
        ...state,
        result: null,
        error: action.error,
      };
    case TenderType.ALL_TENDERS_DEFAULT:
      return {
        ...state,
        filter: null,
        reloadDefault: action.reloadDefault,
      };
    case TenderType.CLEAR_LIST:
      return {
        ...state,
        result: null,
      };
    default:
      return state;
  }
};
