import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import ClearIcon from "@material-ui/icons/Clear";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  widthWord: {
    width: "70%",
    fontWeight: "600",
  },
  widthExcluir: {
    width: "20%",
    fontWeight: 600,
  },
  row: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  rootList: {
    maxHeight: "340px",
    overflow: "auto",
  },
  body: {
    width: "15%",
    fontWeight: "600 !important",
    textAlign: "right",
  },
}));

export default function EntityList(props) {
  const classes = useStyles();

  return (
    <>
      <List className={classes.root}>
        <ListItem role={undefined}>
          <ListItemText className={classes.widthWord} primary="Nombre" />
          <ListItemText className={classes.body} primary="Excluir" />
          <ListItemText primary="" />
        </ListItem>
        <Divider component="li" />
      </List>
      <List className={classes.rootList}>
        {props.list.map((item, index) => {
          const labelId = `checkbox-list-label-${item.Id}`;

          return (
            <>
              <ListItem key={index} className={classes.row} key={item.uniqueId}>
                <ListItemText id={labelId} primary={item.entity.name} />
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={item.selected}
                    tabIndex={-1}
                    onChange={(ev) => {
                      const value = ev.target.checked;
                      props.handleToggle(value, item);
                    }}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="comments"
                    onClick={() => props.deleteItem(item)}
                  >
                    <ClearIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider key={index} component="li" />
            </>
          );
        })}
      </List>
    </>
  );
}
