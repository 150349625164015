import { post } from "../../../config/axios.js";

export const getInternalStatus = async () => {
  const request = {
    Filter: "",
    OrderBy: "description",
    OrderAsc: false,
  };
  return await post(
    "/api/ManageInternalStatus/ListManageInternalStatuses",
    request
  );
};
