import React, { useState, useEffect } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  Page,
  ExcelExport,
  Selection,
} from "@syncfusion/ej2-react-grids";
import { Edit, Toolbar } from "@syncfusion/ej2-react-grids";
import {
  getOrderAscTableValue,
  translateCurrentPageForServerGridSyncFusion,
} from "../../utils/common";
import FstModalConfirm from "../../components/modal/FstModalConfirm";

const FSTGridCrud = (props) => {
  let grid;
  let take = props.take ? props.take : 10;
  const [queryParams, setQueryParams] = useState(null);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [messageAlertDelete, setMessageAlertDelete] = useState(
    "No hay registros seleccionados ",
  );

  const editOptions = {
    allowEditing: true,
    allowAdding: true,
    allowDeleting: true,
    newRowPosition: "Top",
  };
  const toolbarOptions = [
    "Search",
    { text: "Agregar", tooltipText: "Agregar", prefixIcon: "e-add", id: "Add" },
    {
      text: "Eliminar",
      tooltipText: "Eliminar",
      prefixIcon: "e-delete",
      id: "Delete",
    },
    {
      text: "Exportar",
      tooltipText: "Exportar a Excel",
      prefixIcon: "e-excelexport",
      id: "excelexport",
    },
    ...(props.Import
      ? [
          {
            text: "Importar",
            tooltipText: "Importar desde Excel",
            prefixIcon: "e-import-excel",
            id: "excelimport",
          },
        ]
      : []),
  ];

  const paginationGrid = (state) => {
    let orderBy = null;
    let orderDirection = null;
    let search = "";

    if (state && (state.sorted || []).length) {
      orderBy = state.sorted[0].name;
      orderDirection =
        state.sorted[0].direction === "ascending" ? "asc" : "desc";
    }

    if (state && (state.search || []).length) {
      search = state.search[0].key;
    }
    const PageNumber = translateCurrentPageForServerGridSyncFusion(
      state.skip,
      state.take,
    );
    const OrderAsc = getOrderAscTableValue(orderDirection);
    const request = {
      orderBy: orderBy ? orderBy : orderBy,
      orderDirection,
      search,
      pageSize: state.take,
      PageNumber,
      OrderAsc,
    };
    setQueryParams(request);
    return request;
  };

  const getData = (state) => {
    const pagination = paginationGrid(state);
    props.Getdata(pagination);
  };

  const setupInitialData = () => {
    const state = { skip: 0, take: take };
    getData(state);
  };

  useEffect(() => {
    if (!props.list || props.reloadDefault) {
      setupInitialData();
    }
    return () => {};
  }, [props.uniqValue]);

  const actionBegin = (args) => {
    if (args.requestType === "save") {
      props.handleUpdate(args.data);
    }
  };

  const exportExcel = async () => {
    const aux = { ...queryParams };
    aux.pageSize = props.list.count;
    aux.PageNumber = 1;
    const rest = await props.GetDataExport(aux);

    if (props.dynamicColumns && rest && rest.data) {
      let headerTableCopy = [...props.dynamicColumns];
      rest.data = rest.data.map((item) => {
        if (item.data) {
          let dataParsed = JSON.parse(item.data);
          // add its keys to HeaderTable if they are not already there
          Object.keys(dataParsed).forEach((key) => {
            if (!headerTableCopy.find((x) => x.field === key)) {
              headerTableCopy.push({
                field: key,
                headerText: key,
                width: 150,
                textAlign: "left",
                visible: true,
                clipMode: "EllipsisWithTooltip",
              });
            }
          });
          return { ...item, ...dataParsed };
        } else {
          return { ...item };
        }
      });

      grid.columns = headerTableCopy;
    }

    const excelExportProperties = {
      dataSource: rest.data,
    };
    if (props.ExportFileName) {
      excelExportProperties.fileName = props.ExportFileName;
    }
    grid.excelExport(excelExportProperties);
  };

  const excelExportComplete = () => {
    if (props.dynamicColumns) {
      grid.columns = props.dynamicColumns;
    }
  };

  const clickHandler = (args) => {
    if (grid && args.item.id === "excelexport") {
      exportExcel().then();
      return;
    }

    if (grid && args.item.id === "Add") {
      grid.addRecord();
      return;
    }

    if (grid && args.item.id === "Delete") {
      const selectedrecords =
        props.selectionSettings && props.selectionSettings.type === "Multiple"
          ? grid.getSelectedRecords()
          : grid.getSelectedRecords()[0];
      if (
        selectedrecords &&
        (!props.validateDelete || props.validateDelete(selectedrecords))
      ) {
        props.delete(selectedrecords);
      } else if (!selectedrecords) {
        setOpenAlertDelete(true);
      } else {
        setMessageAlertDelete(props.messageDelete);
        setOpenAlertDelete(true);
      }
      return;
    }

    if (grid && args.item.id === "excelimport") {
      props.Import();
      return;
    }
  };

  const dataSourceChanged = (state) => {
    state.endEdit();
  };

  const handleCloseAlertDelete = () => {
    setOpenAlertDelete(false);
  };

  useEffect(() => {
    if (props.dynamicColumns) {
      grid.columns = props.dynamicColumns;
    }
  }, [props.dynamicColumns]);

  return (
    <>
      <GridComponent
        id={props.id}
        dataSource={props.list}
        editSettings={editOptions}
        toolbar={toolbarOptions}
        dataStateChange={getData}
        toolbarClick={clickHandler}
        height={props.height ?? 300}
        allowPaging={true}
        allowExcelExport={true}
        actionBegin={actionBegin}
        dataSourceChanged={dataSourceChanged}
        ref={(g) => {
          if (!g) return;
          grid = g;
        }}
        excelExportComplete={excelExportComplete}
        selectionSettings={props.selectionSettings}
      >
        <ColumnsDirective>
          {props.rows.map((row, index) => (
            <ColumnDirective
              key={index}
              visible={row.visible}
              isPrimaryKey={row.isPrimaryKey}
              field={row.field}
              headerText={row.headerText}
              width={row.width}
              textAlign={row.textAlign}
              template={row.template}
              editTemplate={row.editTemplate}
            />
          ))}
        </ColumnsDirective>
        <Inject services={[Page, Edit, Toolbar, ExcelExport, Selection]} />
      </GridComponent>

      <FstModalConfirm
        onlyOK={true}
        setting={{ BtnNameSave: "OK" }}
        title="Atención:"
        close={() => {
          handleCloseAlertDelete();
        }}
        open={openAlertDelete}
        message={messageAlertDelete}
        success={() => handleCloseAlertDelete()}
      />
    </>
  );
};

export default FSTGridCrud;
