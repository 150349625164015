import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { langContext } from "../../context/langContext";
import BuildElement from "./buildElement";

const FormattedForm = (props) => {
  const { id, data, children } = props;
  const [withChildren, setwithChildren] = useState();
  const langChange = useContext(langContext);

  const setInputValues = (id, type, format) => {
    let value = props.values[id];
    if (type == "date") {
      value = moment(props.values[id]).format(format);
    }
    return value;
  };

  const setChildren = () => {
    let listChildren = [];
    const config = langChange.configForm[id];
    if (config) {
      const elements = config.elements;
      const content = elements.map((item, i) => {
        let auxChildren = null;
        if (item.children) {
          const auxProps = item.children.props;
          if (auxProps.type === "input") {
            // TOOD: Hardcoded, remove later
            // Replaces 'idvalue' from 'tenderName' to 'tenderDescription if label is '"Objeto de la Contratación"'
            // and if props.values.portal is equal to one of 3 values
            if (id === "TenderInform" && (
              props.values?.portal === "MinisterioSaludPBA" ||
              props.values?.portal === "PAMICentral" ||
              props.values?.portal === "PAMIUgls" ||
              props.values?.portal === "ComprasGarrahan"
            )) {
              if (auxProps.idvalue === "tenderName" && auxProps.label === "Objeto de la Contratación") {
                auxProps.idvalue = "tenderDescription";
              }
            }
            //
            auxProps.value = setInputValues(
              auxProps.idvalue,
              auxProps.typeinput,
              auxProps.format
            );
          }
          auxChildren = BuildElement(
            item.children.type,
            { ...auxProps, key: i },
            null
          );
        }
        listChildren.push(
          BuildElement(
            item.type,
            generarProps({ ...item.props, key: i }),
            auxChildren
          )
        );
      });
      setwithChildren(listChildren);
    } else {
      setwithChildren(children);
    }
  };

  const generarProps = (newProps) => {
    return {
      ...newProps,
      data: data,
    };
  };

  useEffect(() => {
    setChildren();
    return () => {};
  }, []);

  return <>{withChildren}</>;
};

export { FormattedForm };
