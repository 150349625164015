import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import menuConfig from "../../tender-information/TenderMenuObject";
import { TenderPanel } from "../../../types";
import ContentSubCardTender from "../../tender-information/ContentSubCardTender";
import useLangForm from "../../../intl/utils/hooks/useLangForm";
import { isLangCR } from "utils/common";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.boxPadding}>
          <Grid className={classes.contGrid}>{children}</Grid>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  contGrid: {
    fontSize: "1rem",
    lineHeight: "1.5",
    letterSpacing: "0.00938em",
    margin: 0,
    display: "block",
    marginBlockStart: "1em",
    //marginBlockEnd: "1em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
  },
  boxPadding: {
    paddingTop: "24px",
    paddingBottom: "0px",
    paddingLeft: "1em",
    paddingRight: "1em",
  },
}));

const TenderContainer = ({ tender, onChangeTender, getTender }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [menuList, setMenuList] = useState([]);
  const [menu, setMenu] = useState(null);
  const route = "licitaciones";
  const filterForm = useLangForm();

  const getMenu = () => {
    let list = null;
    let menuDefault = null;
    list = menuConfig.filter((item) => item.subheader === TenderPanel.SEACE);
    let menuFilter = JSON.parse(
      JSON.stringify(
        filterForm.FormatFilterFormObject(
          "TenderContainer",
          "Tab",
          list[0].items,
        ),
      ),
    ); // Copy list to prevent filtering and then not being able to restore them
    // filter "Contratos" menu if tender status is not "Contrato"
    // and also "Ordenes de pedido" if modalityType is not "Según demanda", otherwise there wouldn't be data to show
    if (isLangCR() && tender) {
      if (tender.portalStatus !== "Contrato") {
        menuFilter = menuFilter.filter(
          (item) => item.etq_title !== "Contratos",
        );
      }
      if (
        tender.portalStatus !== "Contrato" ||
        tender.modalityType !== "Según demanda"
      ) {
        menuFilter = menuFilter.filter(
          (item) => item.etq_title !== "Ordenes de Pedido",
        );
      }
    }
    setMenuList(menuFilter);
    menuDefault = menuFilter[0];
    setMenu(menuDefault);
  };

  const reloadPage = () => {
    setMenu(null);
  };

  const handleChange = (event, newValue) => {
    setMenu(menuList[newValue]);
    setValue(newValue);
  };

  useEffect(() => {
    getMenu();
    return () => {};
  }, []);

  return (
    <div className={classes.root}>
      {menuList && menu && (
        <>
          <AppBar position="static">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {menuList.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    label={item.etq_title}
                    //style={{width: `${100/(menuList.length)}%`}}
                    style={{ width: `${100 / 5}%` }}
                    {...a11yProps(index, menuList)}
                  />
                );
              })}
            </Tabs>
          </AppBar>
          {menuList.map((item, index) => {
            return (
              <TabPanel key={index} value={value} index={index}>
                <ContentSubCardTender
                  hiddenFooter={true}
                  heightToSubtract="145"
                  menu={menu}
                  tender={tender}
                  route={route}
                  reloadPage={reloadPage}
                  onChangeTender={onChangeTender}
                  getTender={getTender}
                  isManaged={false}
                />
              </TabPanel>
            );
          })}
        </>
      )}
    </div>
  );
};

export default TenderContainer;
