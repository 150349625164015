import { makeStyles } from "@material-ui/core/styles";

export const UseStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //maxWidth: 360,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  rootCheckBox: {
    minWidth: "30px",
    height: "15px",
  },
  headerName: {
    display: "inline",
    padding: "0",
    fontWeight: "500",
    fontSize: "1.1rem",
    color: "rgba(0, 0, 0, 0.87)",
  },
  header: {
    zIndex: 400,
    borderBottom: "1px solid #e0e0e0",
    paddingBottom: "3px",
    paddingTop: "3px",
  },
}));
