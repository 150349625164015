import { post } from "../../../config/axios.js";

export const GetLegalDocumentList = async (request) => {
  return await post("/api/LegalDocument/ListLegalDocuments", request);
};

export const ManageLegalDocument = async (request) => {
  return await post("/api/LegalDocument/AddOrUpdateLegalDocument", request);
};

export const getCurrency = (request) => {
  return [
    { id: 1, Description: "Dolar", Symbol: "u$s" },
    { id: 2, Description: "Soles", Symbol: "$" },
  ];
};

export const LegalDocumentDelete = async (request) => {
  return await post("/api/LegalDocument/DeleteLegalDocuments", request);
};
