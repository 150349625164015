import React, { useState, useEffect } from "react";
import { CardHeader } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowUpSharpIcon from "@material-ui/icons/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@material-ui/icons/KeyboardArrowDownSharp";

const HeaderWords = ({
  addElement,
  label,
  list,
  showExpand,
  expand,
  handleExpand,
}) => {
  const classes = useStyles();
  const [count, setCount] = useState(0);

  useEffect(() => {
    const result = list.filter((item) => item.words.length > 0).length;
    setCount(result);
  }, [list]);

  /*const handleExpandAndCollapse = (value) => {
        if ( typeof handleExpand === 'function' ) {
            handleExpand(value);
        }
    }*/

  return (
    <CardHeader
      className={classes.rootHeader}
      title={
        <div>
          <IconButton
            color="primary"
            onClick={addElement}
            disabled={list.filter((item) => item.words.length === 0).length > 0}
            className={classes.actionBtn}
            aria-label="add to shopping cart"
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <Typography
            gutterBottom
            variant="h6"
            component="h6"
            className={classes.tipograghy}
          >
            {label}
            {count > 0 && <span className={classes.counter}>({count})</span>}
          </Typography>
        </div>
      }
      action={
        <>
          {showExpand && (
            <IconButton
              aria-label="delete"
              className={classes.btnPrdFilteredArrow}
              onClick={() => handleExpand(!expand)}
            >
              {expand ? (
                <KeyboardArrowUpSharpIcon />
              ) : (
                <KeyboardArrowDownSharpIcon />
              )}
            </IconButton>
          )}
        </>
      }
    ></CardHeader>
  );
};

const useStyles = makeStyles(() => ({
  counter: {
    color: "red",
    marginLeft: "5px",
    fontSize: "0.9rem",
  },
  tipograghy: {
    display: "inline",
    fontSize: "1.10rem",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: 400,
  },
  rootHeader: {
    padding: 6,
  },
  actionBtn: {
    top: 0,
    position: "relative",
    padding: "5px",
  },
  btnPrdFilteredArrow: {
    position: "relative",
    top: "4px",
    right: "5px",
  },
}));

export default HeaderWords;
