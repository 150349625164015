import React, { useEffect, useState } from "react";

const UserManagerButton = (props) => {
  const [activeUser, setActiveUser] = useState(props.active);

  const handleActiveUser = (event) => {
    if (!props.isAdmin) {
      const element = event.currentTarget.classList;
      setActiveUser(!element.contains("user-active"));
      props.handleClickActive(!activeUser);
    }
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div
      id="status"
      onClick={handleActiveUser}
      className={
        props.isAdmin
          ? "statustemp-admin user-admin"
          : "statustemp " + (activeUser ? "user-active" : "user-inactive")
      }
    >
      <span>{activeUser ? "Activo" : "Inactivo"}</span>
    </div>
  );
};

export default UserManagerButton;
