import React from "react";
import EntityList from "./EntityList";
import EntityAutocompletePaging from "./EntityAutocompletePaging";
import Grid from "@material-ui/core/Grid";

function EntityContainerPaging(props) {
  const handleToggle = (value, element) => {
    const currentIndex = findElement(element);
    const newList = [...props.list];
    newList[currentIndex].selected = value;
    props.handleChange(newList);
  };

  const deleteItem = (element) => {
    const currentIndex = findElement(element);
    const newList = [...props.list];
    newList.splice(currentIndex, 1);
    props.handleChange(newList);
  };

  const findElement = (element) => {
    return props.list.findIndex((item) => item.Id === element.Id);
  };
  const handleParentEntity = (values) => {
    const res = [...props.list, values];
    const newValue = {
      entity: values,
      selected: false,
      Id: props.list.length + 1,
    };
    const newList = [newValue, ...props.list];
    props.handleChange(newList);
  };

  const disabledOption = (option) => {
    return props.list
      .map((item) => item.entity.uniqueId)
      .includes(option.uniqueId);
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} sm={12} md={12}>
        <EntityAutocompletePaging
          disabledOption={disabledOption}
          handleParentEntity={handleParentEntity}
          loadOptions={props.loadOptionsAutocomplete}
          minCharacters={3}
        />

        <EntityList
          list={props.list}
          deleteItem={deleteItem}
          handleToggle={handleToggle}
        />
      </Grid>
    </Grid>
  );
}

export default EntityContainerPaging;
