import { Card, CardContent, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { MultiSelect } from "@syncfusion/ej2-dropdowns";
import { addOrUpdateAlert } from "actions/alertActions";
import { generalDataAlerts } from "actions/alertActions";
import { listAlerts } from "actions/alertActions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AdminGrid from "views/utils-admin/AdminGrid";
import AdminHeader from "views/utils-admin/AdminHeader";
import AdminSnackbars from "views/utils-admin/AdminSnackbars";
import {
  addOrUpdateNotification,
  deleteNotification,
  getGeneralDataNotifications,
  listNotifications,
} from "../../actions/notificationActions";
import { listRecipients } from "../../actions/recipientActions";
import * as SpinnerActions from "../../actions/spinnerActions";
import AdminAccess from "../../components/AdminAccess";
import { StylePage } from "../../style/contentStyle";
import { strcmpWithoutAccent, dateTimeFormat } from "../../utils/common";
import { getReportCategories } from "views/tender-analisys/services/AnalysisService";
import { getAnalysisFilter } from "views/tender-analisys/services/AnalysisService";

const AnalysisFilterModuleId = 3;
const EXPORT_REPORT_NOTIFICATION_ID = 25;

const reportExportTypes = [
  { id: 1, name: "PDF" },
  { id: 2, name: "Excel" },
];

function NotificationsAdmin(props) {
  const classes = StylePage();
  const [tableData, setTableData] = React.useState({
    data: [],
    page: 0,
    totalCount: 0,
  });
  const [alertsData, setAlertsData] = React.useState({
    data: [],
    page: 0,
    totalCount: 0,
  });

  const btnTypes = { REMOVE: "Remove" };
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterError, setOpenToasterError] = useState(false);
  const [itemToModify, setItemToModify] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [generalData, setGeneralData] = React.useState({ empty: true });
  const [availableRecipients, setAvailableRecipients] = React.useState([]);
  const [notifIdToAlertId, setNotifIdToAlertId] = React.useState({});
  const [availableReports, setAvailableReports] = React.useState([]);
  const [analysisFilters, setAnalysisFilters] = React.useState([]);

  const invertObjectKeyValue = (name, generalData) => {
    const obj = {};
    for (let key in generalData) {
      if (generalData.hasOwnProperty(key)) {
        obj[generalData[key]] = key;
      }
    }
    return obj;
  };

  const getLabelFromId = (name, lookup, id) => {
    let result = "Desconocido";
    for (let key in lookup) {
      if (lookup.hasOwnProperty(key)) {
        if (lookup[key] === id) {
          result = key;
          break;
        }
      }
    }
    return result;
  };

  const getDataForSelect = (data) => {
    return Object.entries(data).map(([key, value]) => {
      return { id: +key, name: value };
    });
  };

  // function that returns an object with keys as the ids in the first array of objects and values as the ids in the second array of objects that match the 'name' key
  // used to match the recipients of a notification with the alerts that they are associated with
  const mapNotifIdtoAlertId = (notifIds, alertIds) => {
    return notifIds.reduce((obj, notifId) => {
      for (let i = 0; i < alertIds.length; i++) {
        if (strcmpWithoutAccent(notifId.name, alertIds[i].name)) {
          obj[notifId.id] = alertIds[i].id;
          break;
        }
      }
      return obj;
    }, {});
  };

  const fetchAllData = async (pageInfo) => {
    props.SpinnerShow("Cargando...");
    const data = await getGeneralDataNotifications();
    const alertsData = await generalDataAlerts();

    const reportCategories = await getReportCategories(AnalysisFilterModuleId);
    const reports = reportCategories.data.map((reportCategory) => {
      return reportCategory.reports.map((report) => {
        return {
          id: report.id,
          name: `${reportCategory.name} - ${report.name}`,
        };
      });
    });
    const reportsFlat = [].concat.apply([], reports);
    setAvailableReports(reportsFlat);

    const analysisFilters = await getAnalysisFilter(AnalysisFilterModuleId);
    setAnalysisFilters(analysisFilters.data);

    if (!!data.error || !!alertsData.error) {
      setGeneralData({
        error: "Ocurrió un error al cargar los datos iniciales.",
      });
      return;
    }
    const recipientsInfo = await listRecipients();
    const activeRecipients = recipientsInfo.data.filter((r) => r.active);
    const mappedRecipients = activeRecipients.map((r) => {
      return { id: r.recipientId, name: `${r.firstName} ${r.lastName}` };
    });
    setAvailableRecipients(mappedRecipients);

    data.processed = {};
    data.mappedToSelect = {};
    let name = "notificationType";
    data.processed[name] = invertObjectKeyValue(name, data[name]);
    data.mappedToSelect[name] = getDataForSelect(data.processed[name]);
    name = "notificationChannel";
    data.processed[name] = invertObjectKeyValue(name, data[name]);
    data.mappedToSelect[name] = getDataForSelect(data.processed[name]);
    name = "sendDay";
    data.processed[name] = invertObjectKeyValue(name, data[name]);
    data.mappedToSelect[name] = getDataForSelect(data.processed[name]);

    alertsData.processed = {};
    alertsData.mappedToSelect = {};
    name = "alertTypes";
    alertsData.processed[name] = invertObjectKeyValue(name, alertsData[name]);
    alertsData.mappedToSelect[name] = getDataForSelect(
      alertsData.processed[name],
    );
    setNotifIdToAlertId(
      mapNotifIdtoAlertId(
        data.mappedToSelect["notificationType"],
        alertsData.mappedToSelect["alertTypes"],
      ),
    );

    setGeneralData(data);

    await fetchTableData(pageInfo?.PageNumber ? pageInfo.PageNumber : 0);
    setTimeout(() => {
      props.SpinnerHide();
    }, 750);
  };

  const fetchTableData = async (page) => {
    return new Promise(async (resolve, reject) => {
      const notifications = await listNotifications();
      const alerts = await listAlerts();
      setAlertsData({
        page: page,
        data: alerts.data,
        totalCount: alerts.data.length,
      });
      notifications.data.map((item) => {
        if (notifIdToAlertId[item.notificationTypeId]) {
          let alertId = notifIdToAlertId[item.notificationTypeId];
          let alert = alerts.data.find((a) => a.alertTypeId === alertId);
          item.alert = alert ? alert : null;
          item.alertActive = alert ? alert.active : false;
        } else {
          item.alert = null;
          item.alertActive = false;
        }
        return item;
      });

      notifications.data = notifications.data.map((item) => {
        if (item.reportParam) {
          let reportParam = JSON.parse(item.reportParam);
          return {
            ...item,
            ReportId: reportParam.ReportId,
            ExportType: reportParam.ExportType,
            AnalysisFilterId: +reportParam.AnalysisFilterId,
          };
        }
        return {
          ...item,
          ReportId: null,
          ExportType: null,
          AnalysisFilterId: null,
        };
      });

      if (!!notifications) {
        const tableData = {
          page: page,
          data: notifications.data,
          totalCount: notifications.data.length,
        };
        setTableData(tableData);
        resolve(tableData);
      } else {
        reject("what?");
      }
    });
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const remove = (btn, item) => {
    if (btn.type === btnTypes.REMOVE) {
      setItemToModify(item);
      setDeleteConfirm(true);
    }
  };

  const actionCustomRow = (btn, item) => {
    remove(btn, item);
  };

  const showToaster = () => {
    props.SpinnerShow("Cargando...");
    setDeleteConfirm(false);
    setItemToModify(null);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
      props.SpinnerHide();
    }, 2000);
  };

  const showToasterError = () => {
    setOpenToasterError(true);
    setTimeout(() => {
      setOpenToasterError(false);
    }, 4500);
  };

  const handleSave = async (item) => {
    if (
      !item.channelId ||
      !item.notificationTypeId ||
      !item.sendDayId ||
      !item.recipientsIds
    ) {
      showToasterError();
      return;
    }
    if (item.notificationTypeId === EXPORT_REPORT_NOTIFICATION_ID) {
      item.reportParam = JSON.stringify({
        ReportId: item.ReportId,
        ExportType: item.ExportType,
        AnalysisFilterId: +item.AnalysisFilterId,
      });
    }
    delete item.ReportId;
    delete item.ExportType;
    delete item.AnalysisFilterId;

    showToaster();
    await addOrUpdateNotification(item, tableData);
    if (item.alert && item.alert.active !== item.alertActive) {
      item.alert.active = item.alertActive;
      await addOrUpdateAlert(item.alert, alertsData);
    } else if (
      !item.alert &&
      notifIdToAlertId[item.notificationTypeId] &&
      item.alertActive
    ) {
      let alertId = notifIdToAlertId[item.notificationTypeId];
      let alert = alertsData.data.find((a) => a.alertTypeId === alertId);
      if (!alert) {
        alert = {
          alertTypeId: alertId,
          active: item.alertActive,
        };
      } else alert.active = item.alertActive;
      await addOrUpdateAlert(alert, alertsData);
    }
    await fetchTableData(tableData.page);
  };

  const handleDelete = async (item) => {
    showToaster();
    await deleteNotification(item, tableData);
    if (item.alert) {
      item.alert.active = false;
      await addOrUpdateAlert(item.alert, alertsData);
    }
    await fetchTableData(tableData.page);
  };

  //////////////////////////////////////////////////////////////////////
  let elem;
  let multiSelectObj;
  const header = [
    {
      id: "cols.Tipo",
      field: "notificationTypeId",
      headerText: "Tipo",
      width: "350",
      textAlign: "Left",
      allowSorting: true,
      showInColumnChooser: false,
      template: (rowData) => (
        <label>
          {getLabelFromId(
            "notificationType",
            generalData["notificationType"],
            rowData["notificationTypeId"],
          )}
        </label>
      ),
      editType: "dropdownedit",
      editParams: {
        params: {
          actionComplete: () => false,
          allowFiltering: true,
          dataSource: new DataManager(
            generalData.mappedToSelect
              ? generalData.mappedToSelect["notificationType"]
              : [],
          ),
          fields: { text: "name", value: "id" },
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      id: "cols.Canal",
      field: "channelId",
      headerText: "Canal",
      width: "120",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>
          {getLabelFromId(
            "notificationChannel",
            generalData["notificationChannel"],
            rowData["channelId"],
          )}
        </label>
      ),
      editType: "dropdownedit",
      editParams: {
        params: {
          actionComplete: () => false,
          allowFiltering: true,
          dataSource: new DataManager(
            generalData.mappedToSelect
              ? generalData.mappedToSelect["notificationChannel"]
              : [],
          ),
          fields: { text: "name", value: "id" },
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      id: "cols.Notificacion",
      field: "active",
      headerText: "Notificación",
      width: "90",
      textAlign: "Center",
      allowSorting: true,
      displayAsCheckBox: true,
      editType: "booleanedit",
      editParams: {
        params: {
          actionComplete: () => false,
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      id: "cols.Alerta",
      field: "alertActive",
      headerText: "Alerta",
      width: "90",
      textAlign: "Center",
      allowSorting: true,
      displayAsCheckBox: true,
      editType: "booleanedit",
      editParams: {
        params: {
          actionComplete: () => false,
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      id: "cols.DiaEnvio",
      field: "sendDayId",
      headerText: "Dia de envío",
      width: "180",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>
          {getLabelFromId(
            "sendDay",
            generalData["sendDay"],
            rowData["sendDayId"],
          )}
        </label>
      ),
      editType: "dropdownedit",
      editParams: {
        params: {
          actionComplete: () => false,
          allowFiltering: true,
          dataSource: new DataManager(
            generalData.mappedToSelect
              ? generalData.mappedToSelect["sendDay"]
              : [],
          ),
          fields: { text: "name", value: "id" },
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      id: "cols.Creacion",
      field: "creation",
      headerText: "Creación",
      width: "150",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>{moment(rowData["creation"]).format(dateTimeFormat)}</label>
      ),
      editTemplate: (rowData) => (
        <label>{moment(rowData["creation"]).format(dateTimeFormat)}</label>
      ),
    },
    {
      id: "cols.UltimaActualizacion",
      field: "lastUpdate",
      headerText: "Ultima actualización",
      width: "150",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>{moment(rowData["lastUpdate"]).format(dateTimeFormat)}</label>
      ),
      editTemplate: (rowData) => (
        <label>{moment(rowData["lastUpdate"]).format(dateTimeFormat)}</label>
      ),
    },
    {
      id: "cols.Destinatarios",
      field: "recipientsIds",
      headerText: "Destinatarios",
      width: "170",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>
          {rowData["recipientsIds"]
            .map(
              (ri) => availableRecipients.filter((ar) => ar.id === ri)[0].name,
            )
            .join(", ")}
        </label>
      ),
      allowEditing: true,
      editParams: {
        create: function () {
          elem = document.createElement("input");
          return elem;
        },
        read: () => {
          return multiSelectObj.value;
        },
        destroy: () => {
          multiSelectObj.destroy();
        },
        write: (args) => {
          var data = args.rowData.recipientsIds;
          multiSelectObj = new MultiSelect({
            value:
              typeof data === "string"
                ? args.rowData.recipientsIds.split()
                : data,
            fields: { text: "name", value: "id" },
            dataSource: availableRecipients,
          });
          multiSelectObj.appendTo(elem);
        },
      },
    },
    {
      id: "ReportId",
      field: "ReportId",
      headerText: "Tipo de reporte",
      width: "220",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>
          {availableReports.find((ar) => ar.id === rowData["ReportId"])?.name}
        </label>
      ),
      editType: "dropdownedit",
      editParams: {
        params: {
          actionComplete: () => false,
          allowFiltering: true,
          dataSource: new DataManager(availableReports ? availableReports : []),
          fields: { text: "name", value: "id" },
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      id: "ExportType",
      field: "ExportType",
      headerText: "Formato del reporte",
      width: "140",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>
          {
            reportExportTypes.find((ar) => ar.id === rowData["ExportType"])
              ?.name
          }
        </label>
      ),
      editType: "dropdownedit",
      editParams: {
        params: {
          actionComplete: () => false,
          allowFiltering: true,
          dataSource: new DataManager(reportExportTypes),
          fields: { text: "name", value: "id" },
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      id: "AnalysisFilterId",
      field: "AnalysisFilterId",
      headerText: "Filtro de análisis del reporte",
      width: "220",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>
          {
            analysisFilters.find(
              (ar) => +ar.uniqueId === +rowData["AnalysisFilterId"],
            )?.name
          }
        </label>
      ),
      editType: "dropdownedit",
      editParams: {
        params: {
          actionComplete: () => false,
          allowFiltering: true,
          dataSource: new DataManager(analysisFilters),
          fields: { text: "name", value: "uniqueId" },
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      id: "cols.Acciones",
      headerText: "Acciones",
      width: "160",
      commands: [
        {
          type: "Edit",
          buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
        },
        {
          type: "Remove",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
        {
          type: "Save",
          buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
    },
  ];

  //////////////////////////////////////////////////////////////////////
  return (
    <div className={classes.content}>
      <AdminAccess>
        {!generalData.empty ? (
          !generalData.error ? (
            <>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <AdminHeader headerTitle="Notificaciones y Alertas" />
                    <Divider />
                    <CardContent>
                      <AdminGrid
                        tableData={tableData}
                        tableDataCount={tableData.totalCount}
                        reloadDefault={true}
                        header={header}
                        idGrid="NotificationAdmin"
                        fetchAllData={fetchAllData}
                        actionCustomRow={actionCustomRow}
                        handleSave={handleSave}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <AdminSnackbars
                openToaster={openToaster}
                openToasterError={openToasterError}
                modalTitle={`Eliminar notificación`}
                modalClose={() => {
                  setItemToModify(null);
                  setDeleteConfirm(false);
                }}
                deleteConfirm={deleteConfirm}
                modalMessage={`¿Está seguro que desea eliminar la notificación de tipo ${getLabelFromId(
                  "notificationType",
                  generalData["notificationType"],
                  itemToModify ? itemToModify["notificationTypeId"] : "",
                )} y que se envía ${getLabelFromId(
                  "sendDay",
                  generalData["sendDay"],
                  itemToModify ? itemToModify["sendDayId"] : "",
                )}?`}
                modalSuccess={() => handleDelete(itemToModify)}
              />
            </>
          ) : (
            `${generalData.error}`
          )
        ) : (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          />
        )}
      </AdminAccess>
    </div>
  );
}

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  NotificationsAdmin,
);
