import React, { useState, useEffect } from "react";
import { FstGrid } from "../../../components/grid/FstGrid";
import { Headers } from "./smartSearchListHeader";
import {
  getSearchManageFilter,
  deleteSmartSearch,
  updateDaysToAllFilters,
} from "../services/smartSearchService";
import moment from "moment";
import ManageSmartSearchModal from "../manage-smart-search/ManageSmartSearchModal";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FstModalConfirm from "../../../components/modal/FstModalConfirm";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  FormLabel,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import * as SpinnerActions from "../../../actions/spinnerActions";
import { connect } from "react-redux";
import FstInputNumeric from "components/forms/input-numeric/FstInputNumeric";
import FstModal from "components/modal/FstModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SmartSearchList = (props) => {
  const classes = useStyles();
  const btnTypes = {
    EDIT: "Edit",
    REMOVE: "Remove",
  };
  const [list, setList] = useState({ rows: [], totalRows: 0 });
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [openManage, setOpenManage] = useState(false);
  const [smartSearch, setSmartSearch] = useState(false);
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [deleteFilter, setDeleteFilter] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [itemSelectedname, setItemSelectedName] = useState("");
  const [actionEdit, setActionEdit] = useState(false);
  const [currentPageRequest, setCurrentPageRequest] = useState(null);

  const getData = async (pag, filter) => {
    props.SpinnerShow("Cargando...");
    setCurrentPageRequest(pag);
    const response = await getSearchManageFilter(pag, filter);
    setList({
      rows: response.data.currentPageItems,
      totalRows: response.data.totalItems,
    });
    props.SpinnerHide();
  };

  const exportList = async (pag, filter) => {
    const response = await getSearchManageFilter(pag, filter);
    if (!!response && !!response.data) {
      return response.data.currentPageItems;
    }
  };

  const add = () => {
    setActionEdit(false);
    setSmartSearch(null);
    setOpenManage(true);
  };

  const edit = (btn, item) => {
    if (btn.type === btnTypes.EDIT) {
      setActionEdit(true);
      setSmartSearch(item);
      setOpenManage(true);
    }
  };

  const remove = (btn, item) => {
    if (btn.type === btnTypes.REMOVE) {
      setItemSelected(item);
      setItemSelectedName(item.name);
      setDeleteFilter(true);
    }
  };

  const executeRemove = async () => {
    const request = [{ SearchFilterId: itemSelected.searchFilterId }];
    props.SpinnerShow();
    await deleteSmartSearch(request);
    setUniqueValue(moment().format());
    showToaster(`El filtro ${itemSelected.name} ha sido borrado exitosamente`);
    setDeleteFilter(false);
    props.SpinnerHide();
    props.handleUpdateList();
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 4000);
  };

  const actionCustomRow = (btn, item) => {
    remove(btn, item);
    edit(btn, item);
  };

  // Advanced options Modal
  const defaultAdvancedOptions = {
    days: 0,
    errorMessage: "",
    isChanged: false,
  };
  const [advancedOptionsModal, setAdvancedOptionsModal] = useState(false);
  const [advancedOptions, setAdvancedOptions] = useState(
    defaultAdvancedOptions,
  );

  const advancedOptionsModalOpen = () => {
    setAdvancedOptionsModal(true);
  };
  const advancedOptionsModalClose = () => {
    setAdvancedOptionsModal(false);
    setAdvancedOptions(defaultAdvancedOptions);
  };
  const handleChangeAdvancedOptions = (name, value) => {
    setAdvancedOptions({
      ...advancedOptions,
      [name]: value,
      isChanged: true,
    });
  };
  const advancedOptionsModalSave = async () => {
    if (!advancedOptions.days) {
      setAdvancedOptions({
        ...advancedOptions,
        errorMessage: "Debe ingresar una cantidad de días",
      });
    } else {
      setAdvancedOptions({ ...advancedOptions, errorMessage: "" });
      setAdvancedOptionsModal(false);
      props.SpinnerShow("Guardando...");
      await updateDaysToAllFilters(+advancedOptions.days);
      setTimeout(async () => {
        await getData(currentPageRequest);
        props.SpinnerHide();
        setAdvancedOptions(defaultAdvancedOptions);
      }, 700);
    }
  };
  ////

  const dataGrid = {
    Getdata: getData,
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
    },
    ActionCustomRow: actionCustomRow,
    allowSorting: true,
    allowPaging: true,
    Add: add,
    take: 17,
    Header: Headers,
    height: props.height - 180,
    exportFunc: exportList,
    ToolBar: {
      Add: true,
      AddTitle: "Agregar",
      Export: true,
      Search: true,
      AdvancedOptions: true,
    },
    AdvancedOptions: advancedOptionsModalOpen,
    ExportFileName: "SmartSearch - Filtros.xlsx",
    idGrid: "FilterAdmin",
  };

  const handleSave = () => {
    setActionEdit(false);
    setOpenManage(false);
    props.handleUpdateList();
    getData(currentPageRequest);
  };

  useEffect(() => {
    setUniqueValue(moment().format());
  }, []);

  return (
    <>
      <Card>
        <CardHeader
          className={classes.rootHeader}
          title={
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              className={classes.tipograghy}
            >
              Administración de filtros
            </Typography>
          }
        ></CardHeader>
        <Divider />
        <CardContent>
          <FstGrid
            data={dataGrid}
            uniqValue={uniqValue}
            result={{
              result: list.rows,
              count: list.totalRows,
            }}
            reloadDefault={true}
          />
          <Grid item xs={12} sm={12} md={12} style={{ textAlign: "right" }}>
            <Button onClick={props.viewFilters}>Volver</Button>
          </Grid>
        </CardContent>
      </Card>

      <FstModalConfirm
        title="Eliminación de filtro"
        close={() => {
          setDeleteFilter(false);
        }}
        open={deleteFilter}
        message={
          <>
            {`¿Esta seguro que desea eliminar el Filtro`}{" "}
            <b>{itemSelectedname}</b>
            {"?"}
          </>
        }
        success={executeRemove}
      />

      <ManageSmartSearchModal
        open={openManage}
        smartSearch={smartSearch}
        handleClose={() => setOpenManage(false)}
        handleSave={() => handleSave()}
        edit={actionEdit}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>

      <FstModal
        open={advancedOptionsModal}
        title={"Opciones Avanzadas"}
        handleClose={advancedOptionsModalClose}
        handleSave={advancedOptionsModalSave}
        widthSize="xs"
        fullWidth={true}
        classContainer={"fst-Modal-Container"}
        IsDisabledPrimary={!advancedOptions.isChanged}
      >
        <List>
          <ListItem>
            <FormLabel component="legend">
              Establecer últimos días para TODOS los filtros creados. Máximo 90
              días.
            </FormLabel>
          </ListItem>
          <ListItem alignItems="center" style={{ marginTop: "3%" }}>
            <FstInputNumeric
              label="Días"
              name="days"
              value={advancedOptions.days}
              handleChange={handleChangeAdvancedOptions}
              minimum="0"
              maximum="90"
              error={advancedOptions.errorMessage ? true : false}
              helperText={advancedOptions.errorMessage}
            />
          </ListItem>
        </List>
      </FstModal>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(SmartSearchList);
