import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FstModal from "../../components/modal/FstModal";
import { Controls } from "../../utils/controls";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  InputWidth: {
    width: "100%",
  },
}));

const filterDefault = {
  login: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confPass: "",
  active: false,
  applicationModules: [],
  isAdmin: false,
  roleId: 2,
};

const initialErrors = {
  emailValidity: false,
  emailErrorMsg: "",
};

const btnTypes = {
  ADD: "add",
  EDIT: "Edit",
  REMOVE: "Remove",
};

const UserManagerModal = (props) => {
  const classes = useStyles();
  const { variant } = Controls.TextField;
  const [filter, setFilter] = useState(filterDefault);
  const [listDefaultApp, setlistDefaultApp] = useState([]);
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    initialModal();
    return () => {};
  }, []);

  const initialModal = async () => {
    if (props.action === btnTypes.EDIT) {
      const { applicationModules } = props.userSelect;
      getListDeault(props.appModules, applicationModules);
      setFilter(props.userSelect);
    }
  };

  const getListDeault = (listApp, listUser) => {
    const listDefault = listApp.filter((items) => {
      if (listUser.some((item) => item.name === items.name)) {
        return items;
      }
    });
    setlistDefaultApp(listDefault);
  };

  const handlerChange = (key, value) => {
    const aux = { ...filter };
    aux[key] = value;
    setFilter(aux);
    if (key === "email") {
      validarField(value);
    }
  };

  const handleChangeModulo = (event, value) => {
    const getValue = value.map((item) => {
      return item.id;
    });
    handlerChange("applicationModules", getValue);
  };

  const validarField = (value) => {
    let emailErrorMsg = "";
    let emailValidity = false;

    if (value.trim()) {
      emailValidity = !/[\w\d\-]+@[\w\d\.-]+\[\w\d\-]+/.test(value);
      emailErrorMsg = !emailValidity ? "" : "Email Invalido";
    }

    setErrors((prevState) => ({
      ...prevState,
      emailValidity,
      emailErrorMsg,
    }));
  };

  const validateError = () => {
    let error = [];

    if (filter !== null) {
      error.push(filter.login.trim().length === 0 ? true : false);
      error.push(filter.firstName.trim().length === 0 ? true : false);
      error.push(filter.lastName.trim().length === 0 ? true : false);
      error.push(filter.email.trim().length === 0 ? true : false);
      error.push(filter.password.trim().length === 0 ? true : false);
      error.push(filter.confPass.trim().length === 0 ? true : false);
      error.push(filter.applicationModules.length > 0 ? true : false);
    }

    return error.indexOf(true) === -1 ? false : true;
  };

  return (
    <FstModal
      open={props.activeModal}
      title={props.titleModal}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      widthSize="sm"
      IsDisabledPrimary={validateError()}
      classContainer={"fst-Modal-Container"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant={variant}
            id="login"
            label="Usuario"
            size="small"
            className={classes.InputWidth}
            value={filter.login}
            onChange={(event) => {
              handlerChange("login", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant={variant}
            id="name"
            label="Nombre"
            size="small"
            className={classes.InputWidth}
            value={filter.firstName}
            onChange={(event) => {
              handlerChange("firstName", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant={variant}
            id="lastName"
            label="Apellido"
            size="small"
            className={classes.InputWidth}
            value={filter.lastName}
            onChange={(event) => {
              handlerChange("lastName", event.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant={variant}
            id="email"
            label="Email"
            size="small"
            className={classes.InputWidth}
            value={filter.email}
            onChange={(event) => {
              handlerChange("email", event.target.value);
            }}
            helperText={errors.emailErrorMsg}
            error={errors.emailValidity}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            required={true}
            variant={variant}
            id="password"
            label="Contraseña"
            size="small"
            className={classes.InputWidth}
            value={filter.password}
            onChange={(event) => {
              handlerChange("password", event.target.value);
            }}
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            required={true}
            variant={variant}
            id="confPass"
            label="Confirmar Contraseña"
            size="small"
            className={classes.InputWidth}
            value={filter.confPass}
            onChange={(event) => {
              handlerChange("confPass", event.target.value);
            }}
            type="password"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <div id="usermodulos" style={{ marginTop: "10px" }}>
            <div className={classes.root + " div-usemanager"}>
              <Autocomplete
                multiple
                limitTags={3}
                id="modulos"
                size="small"
                options={props.appModules}
                defaultValue={listDefaultApp}
                getOptionLabel={(option) => option.name}
                onChange={handleChangeModulo}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Modulos"
                  />
                )}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="active"
                checked={filter.active}
                onChange={(event) => {
                  handlerChange("active", event.target.checked);
                }}
                color="primary"
                size="small"
              />
            }
            label="Estado"
            size="small"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                id="isAdmin"
                checked={filter.roleId === 1}
                onChange={(event) => {
                  handlerChange("isAdmin", event.target.checked);
                }}
                color="primary"
                size="small"
              />
            }
            label="Aministrador"
            size="small"
          />
        </Grid>
      </Grid>
    </FstModal>
  );
};

const mapStateToProps = (state, props) => {
  const { modalReducer } = state;
  const { idModal, setting, getIsActive } = modalReducer;
  const titleModal = setting ? setting.titleModal : "";
  const activeModal = getIsActive ? getIsActive(props.id) : false;

  return {
    ...state,
    activeModal,
    titleModal,
    idModal,
  };
};

export default connect(mapStateToProps)(UserManagerModal);
