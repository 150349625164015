import React, { useState, useEffect } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import PropTypes from "prop-types";

const FstCheckbox = (props) => {
  const { name, label, value } = props;
  const [inputValue, setInputValue] = useState("");
  const handleChangeValue = (value) => {
    if (typeof props.handleChange === "function") {
      props.handleChange(name, value);
    }
  };

  useEffect(() => {
    setInputValue(value);
    return () => {};
  }, [value]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={inputValue === true}
          onChange={(e) => {
            const { checked } = e.target;
            handleChangeValue(checked);
          }}
          name={name}
          color={props.color}
        />
      }
      label={label}
    />
  );
};

FstCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

FstCheckbox.defaultProps = {
  disabled: false,
  readOnly: false,
  color: "primary",
};

export default FstCheckbox;
