import axios from "axios";

export const getAllFilters = async () => {
  const defaultResponse = { totalCount: 0, page: 0, data: [], error: "" };

  try {
    const axiosResult = await axios.post("/api/SearchFilter/ListSearchFilters");

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return {
        data: data,
      };
    } else {
      defaultResponse.error = "Error fetching filters";
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};

export const getFilterById = async (id) => {
  const defaultResponse = { totalCount: 0, page: 0, data: [], error: "" };

  try {
    const payload = { SearchFilterId: id };
    const axiosResult = await axios.post(
      "/api/SearchFilter/ListSearchFiltersById",
      payload
    );

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return {
        data: data,
      };
    } else {
      defaultResponse.error = `Error fetching filter ${id}`;
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};

export const upsertFilter = async (
  id,
  name,
  days,
  include,
  exclude,
  includeNames
) => {
  const defaultResponse = { totalCount: 0, page: 0, data: [], error: "" };
  const payload = {
    Name: name,
    Days: days,
    IncludeWords: include,
    ExcludeWords: exclude,
    IncludeWordsNames: includeNames,
  };
  if (id !== null) {
    payload.SearchFilterId = id;
  }

  try {
    const axiosResult = await axios.post(
      "/api/SearchFilter/AddOrUpdateSearchFilter",
      [payload]
    );

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return {
        data: data,
      };
    } else {
      defaultResponse.error = `Error upserting filter ${payload}`;
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};

export const deleteFilterById = async (id, tableData) => {
  const defaultResponse = { totalCount: 0, page: 0, data: [], error: "" };

  try {
    const payload = { SearchFilterId: id };
    const axiosResult = await axios.post(
      "/api/SearchFilter/DeleteSearchFilter",
      [payload]
    );

    if (!!axiosResult && !!axiosResult.data) {
      //const data = axiosResult.data;
      //const error = data.errorOnDelete;
      const { totalCount, ...rest } = tableData;
      return {
        rest,
        totalCount: tableData.length - 1,
      };
    } else {
      defaultResponse.error = `Error deleting filter ${id}`;
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};
