//@flow
import React from "react";
import { withRouter } from "react-router-dom";
import { materialTable as localization } from "../../config/localization";
import { deleteFilterById, getAllFilters } from "../../actions/filterActions";
import { getMaterialTableCell } from "../../utils/common";
import MaterialTableResponsive from "../../components/MaterialTableResponsive";
import { StylePage } from "../../style/contentStyle";

const Filters = withRouter(({ history }) => {
  const [tableData, setTableData] = React.useState([]);
  const classes = StylePage();
  function getColumns() {
    const columns = [];
    columns.push(getMaterialTableCell({ title: "Nombre", field: "name" }));
    columns.push(
      getMaterialTableCell({ title: "Incluir por item", field: "includeWords" })
    );
    columns.push(
      getMaterialTableCell({ title: "Excluir por item", field: "excludeWords" })
    );
    columns.push(
      getMaterialTableCell({
        title: "Incluir por nombre",
        field: "includeWordsNames",
      })
    );
    return columns;
  }
  return (
    <div className={classes.content}>
      <MaterialTableResponsive
        title="Filtros Smart Search"
        paging={false}
        columns={getColumns()}
        onRowClick={(e, row) => history.push("/filtros/" + row.searchFilterId)}
        actions={[
          {
            icon: "add",
            tooltip: "Agregar filtro",
            isFreeAction: true,
            onClick: () => {
              history.push("/filtros/new");
            },
          },
        ]}
        editable={{
          onRowDelete: (oldData) => {
            return deleteFilterById(oldData.searchFilterId, tableData);
          },
        }}
        data={(query) =>
          new Promise(async (resolve) => {
            const { page } = query;
            const data = await getAllFilters();
            const td = {
              data: data.data,
              page: page,
              totalCount: data.data.length,
            };
            setTableData(td);
            if (!!data) {
              resolve(td);
            }
          })
        }
        localization={localization["es"]}
      />
    </div>
  );
});
export default Filters;
