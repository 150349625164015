import React, { useState } from "react";
import langAR from "../lang/format-message/es-AR.json";
import langPE from "../lang/format-message/es-PE.json";
import langCR from "../lang/format-message/es-CR.json";

import configPE from "../lang/format-form/ObjectFormPE";
import configAR from "../lang/format-form/ObjectFormAR";
import configCR from "../lang/format-form/ObjectFormCR";

const useDownloadLang = () => {
  const getLangDetail = (language) => {
    let mensaje = langPE;
    let locale = "es-PE";
    let configForm = configPE;

    switch (language) {
      case "es-AR":
        mensaje = langAR;
        locale = "es-AR";
        configForm = configAR;
        break;
      case "es-PE":
        mensaje = langPE;
        locale = "es-PE";
        configForm = configPE;
        break;
      case "es-CR":
        mensaje = langCR;
        locale = "es-CR";
        configForm = configCR;
        break;
      default:
        mensaje = langPE;
        locale = "es-PE";
        configForm = configPE;
    }
    return {
      mensaje: mensaje,
      locale: locale,
      configForm: configForm,
    };
  };

  return {
    getLangDetail,
  };
};

export default useDownloadLang;
