import React, { useState, useEffect } from "react";
import { addToManagedTenders } from "../../../actions/tenderActions";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ResponsiveTable from "material-ui-next-responsive-table";
import { useHistory } from "react-router-dom";
import { isLangAR } from "utils/common";

const AddTenderModal = (props) => {
  const useStyles = makeStyles(() => ({
    textFieldProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    centered: {
      textAlign: "center",
    },
  }));

  const [tendersToManage, setTendersToManage] = useState("");
  const [responseArrived, setResponseArrived] = useState(null);
  const [addingManagedTenders, setAddingManagedTenders] = useState(false);
  const NOT_RESULT = "Sin resultado";
  const NOT_FOUND = "No encontrada";

  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseAddTenders = () => {
    setTendersToManage("");
    const isAdded =
      responseArrived &&
      responseArrived[0].Result !== NOT_RESULT &&
      responseArrived[0].Result !== NOT_FOUND;
    props.handleCloseAddTenders(isAdded);
    setResponseArrived(null);
  };

  useEffect(() => {
    if (props.tenderNumber) {
      setTendersToManage(props.tenderNumber);
    }

    return () => {};
  }, []);

  async function handleAdd() {
    setAddingManagedTenders(true);
    const tendersToManageArr = tendersToManage
      .split("\n")
      .filter((e) => e !== "");
    const payload = tendersToManageArr.map((tn) => {
      return { tenderNumber: tn };
    });
    const response = await addToManagedTenders(payload);
    setAddingManagedTenders(false);
    const formattedResponse = response.map((r) => {
      return {
        TenderNumber: r.TenderNumber || "",
        Result: r.Result || NOT_RESULT,
      };
    });
    setResponseArrived(formattedResponse);
  }

  const history = useHistory();
  const handleRedirectToOutsiderTenders = () => {
    handleCloseAddTenders();
    history.push("/licitaciones-externas");
  };

  const addTenderMenu = (
    <>
      <DialogTitle id="responsive-dialog-title">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Agregar Licitaciones
          {isLangAR() && (
            <Button
              onClick={handleRedirectToOutsiderTenders}
              color="primary"
              variant="outlined"
            >
              Agregar Licitaciones Externas
            </Button>
          )}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Ingrese el identificador de convocatoria de licitacion a agregar (uno
          por línea)
        </DialogContentText>
        <TextField
          autoFocus
          id="standard-multiline-static"
          label="Licitaciones"
          multiline
          fullWidth
          value={tendersToManage}
          onChange={(e) => {
            setTendersToManage(e.target.value);
          }}
          rows={7}
          style={{
            margin: theme.spacing(1),
          }}
          margin="normal"
          disabled={addingManagedTenders}
        />
        {addingManagedTenders && (
          <CircularProgress className={classes.textFieldProgress} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseAddTenders}
          color="primary"
          disabled={addingManagedTenders}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleAdd}
          color="primary"
          disabled={addingManagedTenders}
        >
          Agregar
        </Button>
      </DialogActions>
    </>
  );

  const displayAddTendersResult = (
    <>
      <DialogTitle id="responsive-dialog-title">
        Agregar Licitaciones
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Resultado</DialogContentText>
        {!!responseArrived ? (
          <ResponsiveTable
            columns={[
              { key: "TenderNumber", label: "Convocatoria" },
              { key: "Result", label: "Estado" },
            ]}
            data={!!responseArrived ? responseArrived : []}
          />
        ) : (
          <p>Ocurrió un error al leer el estado de las licitaciones.</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCloseAddTenders}
          color="primary"
          disabled={addingManagedTenders}
        >
          Salir
        </Button>
      </DialogActions>
    </>
  );

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.openAddTenders}
        onClose={handleCloseAddTenders}
        aria-labelledby="agregar-licitaciones-popup"
      >
        {responseArrived === null ? addTenderMenu : displayAddTendersResult}
      </Dialog>
    </>
  );
};

export default AddTenderModal;
