import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect } from "react";

export default function CustomDatePicker(props) {
  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    setSelectedDate(props.defaultValue);
  }, []);

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="DD/MM/yyyy"
        margin="normal"
        id={props.id}
        name={props.id}
        value={selectedDate}
        onChange={handleDateChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        autoOk={true}
        style={{ marginTop: "13px" }}
      />
    </MuiPickersUtilsProvider>
  );
}
