import React from "react";
import { Prompt } from "react-router-dom";

export default function UnsavedChangesWarning({ isEditing, children }) {
  const unsavedWarningMsg = `Se perderán los cambios no guardados.\n¿Desea continuar?`;
  React.useEffect(() => {
    function reloadListener(e) {
      if (isEditing) {
        e.preventDefault();
        e.returnValue = unsavedWarningMsg;
      }
    }
    window.addEventListener("beforeunload", reloadListener);
    return () => {
      window.removeEventListener("beforeunload", reloadListener);
    };
  }, [isEditing, unsavedWarningMsg]);

  return (
    <React.Fragment>
      <Prompt when={isEditing} message={() => unsavedWarningMsg} />
      {children}
    </React.Fragment>
  );
}
