import axios from "axios";
import { TenderType } from "../types";
import { translateCurrentPageForClient } from "../utils/common";

export const getManagedTendersList =
  (pagination, filter) => async (dispatch) => {
    try {
      const url =
        filter.statusId !== -1
          ? "/api/Manage/GetManageList"
          : "/api/DeletedTenders/GetDeletedTendersList";
      const status = filter.statusId !== -1 ? filter.statusId : 0;
      const axiosResult = await axios.post(url, {
        OrderBy: pagination.orderBy || "dateCreationManage",
        PageSize: pagination.pageSize,
        Filter: pagination.search,
        PageNumber: pagination.PageNumber,
        OrderAsc: pagination.OrderAsc,
        StatusId: status,
      });

      if (!!axiosResult && !!axiosResult.data) {
        const data = axiosResult.data;

        dispatch({
          type: TenderType.ALL_TENDERS,
          payload: {
            result: data.currentPageItems,
            count: data.totalItems,
          },
          page: translateCurrentPageForClient(data.currentPage),
          pageSize: data.pageSize,
          reloadDefault: false,
          filter: {
            pagination,
            filter,
          },
        });
      } else {
        dispatch({
          type: TenderType.ALL_TENDERS_ERROR,
          error: "Error fetching managed tenders data",
        });
      }
    } catch (error) {
      dispatch({
        type: TenderType.ALL_TENDERS_ERROR,
        error: "Error fetching managed tenders data",
      });
    }
  };

export const getManagedTendersListExport = (pagination, filter) => async () => {
  const url =
    filter.statusId !== -1
      ? "/api/Manage/GetManageList"
      : "/api/DeletedTenders/GetDeletedTendersList";
  const status = filter.statusId !== -1 ? filter.statusId : 0;
  const axiosResult = await axios.post(url, {
    OrderBy: pagination.orderBy || "dateCreationManage",
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
    StatusId: status,
  });

  if (!!axiosResult && !!axiosResult.data) {
    return axiosResult.data.currentPageItems;
  }
  return null;
};

export const getManagedTendersListDefault = () => async (dispatch) => {
  dispatch({
    type: TenderType.ALL_TENDERS_DEFAULT,
    reloadDefault: true,
  });
};

export const clearTendersList = () => (dispatch) => {
  dispatch({
    type: TenderType.CLEAR_LIST,
  });
};

export const removeTenderFromManagedList = (row) => async (dispatch) => {
  try {
    const axiosResult = await axios.post("/api/Manage/DeleteTenders", [
      {
        tenderNumber: row.tenderNumber,
        deleteComment: row.deleteComment,
        deleteReason: row.deleteReason,
      },
    ]);

    if (!!axiosResult && !!axiosResult.data) {
      dispatch({
        type: TenderType.ALL_TENDERS_DEFAULT,
        reloadDefault: true,
      });
    }
  } catch (error) {}
};
