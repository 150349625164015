import { SendTendersType } from "../types";
import { GetSendTendersList } from "../views/tender-information/services/sendTenderMailService";

export const GetSendTenders = () => async (dispatch) => {
  try {
    const axiosResult = await GetSendTendersList();

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      dispatch({
        type: SendTendersType.SEND_TENDER_ALL,
        payload: {
          result: data,
        },
      });
    } else {
      dispatch({
        type: SendTendersType.SEND_TENDER_ERROR,
        error: "Error fetching products data",
      });
    }
  } catch (error) {
    dispatch({
      type: SendTendersType.SEND_TENDER_ERROR,
      error: "Error fetching products data",
    });
  }
};

export const GetSendTendersDefault = () => (dispatch) => {
  dispatch({
    type: SendTendersType.SEND_TENDER_DEFAULT,
    reloadDefault: true,
  });
};
