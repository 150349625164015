import React from "react";
import moment from "moment";
import { dateTimeFormat } from "../../../utils/common";
export const TenderCommentsHeaderTable = [
  {
    field: "comment",
    headerText: "Comentario",
    width: "300",
    textAlign: "Left",
    showInColumnChooser: false,
  },
  {
    field: "updatedByUserName",
    headerText: "Usuario",
    width: "100",
    textAlign: "Left",
  },
  {
    field: "lastUpdate",
    headerText: "Fecha",
    width: "105",
    textAlign: "Center",
    template: (props) => (
      <span>{moment(props.lastUpdate).format(dateTimeFormat)}</span>
    ),
  },
];
