import React, { useState } from "react";
import { connect } from "react-redux";
import FstModal from "../../../components/modal/FstModal";
import { ListTender } from "./ListTender";
import { addToManagedTenders } from "../../../actions/tenderActions";
import * as SpinnerActions from "../../../actions/spinnerActions";
import { Managed } from "./ManagedTender";

const ManageTender = (props) => {
  const spinnerMessage = "Guardando...";
  const { open, handleClose, tenders } = props;
  const [settingModal, setSettingModal] = useState({
    BtnNameSave: "Gestionar",
  });
  const [result, setResult] = useState(null);

  const handleSave = async () => {
    if (result) {
      handleClose();
    } else {
      executeSave();
    }
  };

  const executeSave = async () => {
    props.SpinnerShow(spinnerMessage);
    const tendersToManageArr = [...tenders].map((item) => item.tenderNumber);
    const payload = tendersToManageArr.map((tn) => {
      return { tenderNumber: tn };
    });
    const response = await addToManagedTenders(payload);
    setResult(response);
    setSettingModal({ BtnNameSave: "Salir" });
    props.SpinnerHide();
  };

  return (
    <FstModal
      open={open}
      title="Agregar Licitaciones"
      handleClose={handleClose}
      handleSave={handleSave}
      onlyOK={result}
      setting={settingModal}
      widthSize="lg"
      IsDisabledPrimary={false}
    >
      {!result && <ListTender tenders={tenders} />}

      {result && <Managed result={result} />}
    </FstModal>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(ManageTender);
