import React from "react";
import MaterialTable from "material-table";
import { materialTable as localization } from "../config/localization";
import { Prompt } from "react-router-dom";

export default function MaterialTableResponsive({
  title,
  columns,
  data,
  paging = true,
  editable,
  actions = [],
  tableRef = React.createRef(),
  onEditingStatusChangeCb = null,
  onRowClick = () => {},
  initialRowsPerPage = 20,
  exportable = false,
}) {
  const unsavedWarningMsg = `Se perderán los cambios no guardados.\n¿Desea continuar?`;
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [isEditing, setIsEditing] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    function reloadListener(e) {
      if (isEditing) {
        e.preventDefault();
        e.returnValue = unsavedWarningMsg;
      }
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("beforeunload", reloadListener);

    return () => {
      window.removeEventListener("beforeunload", reloadListener);
      window.removeEventListener("resize", handleResize);
    };
  }, [isEditing, unsavedWarningMsg]);

  return (
    <div>
      <Prompt when={isEditing} message={() => unsavedWarningMsg} />
      <MaterialTable
        tableRef={tableRef}
        title={title}
        columns={columns}
        options={{
          paging: paging,
          maxBodyHeight: dimensions.height - 300 + "px",
          debounceInterval: 400,
          pageSize: rowsPerPage,
          pageSizeOptions: [10, 20, 30],
          exportButton: exportable,
          exportAllData: exportable,
        }}
        onChangeRowsPerPage={(newPageSize) => setRowsPerPage(newPageSize)}
        onEditingStatusChange={(newIsEditing) => {
          let actuallyChanged = newIsEditing !== isEditing;
          if (newIsEditing !== isEditing) {
            setIsEditing(newIsEditing);
          }
          if (!!onEditingStatusChangeCb && actuallyChanged) {
            onEditingStatusChangeCb(newIsEditing);
          }
        }}
        onRowClick={onRowClick}
        data={data}
        localization={localization["es"]}
        editable={editable}
        actions={actions}
      />
    </div>
  );
}
