import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import { EventNote, Label, Shop } from "@material-ui/icons";
import BusinessIcon from "@material-ui/icons/Business";
import GroupIcon from "@material-ui/icons/Group";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PersonIcon from "@material-ui/icons/Person";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import TuneIcon from "@material-ui/icons/Tune";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import CompanySettingModal from "views/company-setting/CompanySettingModal";
import ManageTenderEmailSubjectConfig from "views/company-setting/ManageTenderEmailSubjectConfig";
import * as PendingSaveActions from "../actions/pendingSaveActions";

const COMPANY_SETTING_MODAL = "companySettingModal";
const EMAIL_SUBJECT_CONFIG_MODAL = "emailSubjectConfigModal";

const listItem = [
  {
    primary: "Empresas",
    route: "/empresas",
    icon: <BusinessIcon />,
    showItem: (props) => props.idAdminGeneral,
  },
  {
    primary: "Destinatarios",
    route: "/destinatarios",
    icon: <GroupIcon />,
    showItem: (props) => props.isAdmin,
  },
  {
    primary: "Notificaciones y Alertas",
    route: "/notificaciones",
    icon: <NotificationsIcon />,
    showItem: (props) => props.isAdmin,
  },
  {
    primary: "Usuarios",
    route: "/usuarios",
    icon: <PersonIcon />,
    showItem: (props) => props.isAdmin,
  },
  {
    primary: "Competidores OC",
    route: "/competidores-oc",
    icon: <Shop />,
    showItem: (props) => props.appModules.some((item) => item.name === "OC"),
  },
  {
    primary: "Entidades PAC",
    route: "/entidades-pac",
    icon: <EventNote />,
    showItem: (props) => props.appModules.some((item) => item.name === "PAC"),
  },
  {
    primary: "Configurar Empresa",
    route: COMPANY_SETTING_MODAL,
    icon: <SettingsApplicationsIcon />,
    showItem: (props) => props.isAdmin,
  },
  {
    primary: "Configuración Correo GL",
    route: EMAIL_SUBJECT_CONFIG_MODAL,
    icon: <Label />,
    showItem: (props) => props.isAdmin,
  },
];

const SettingsButton = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState({
    [COMPANY_SETTING_MODAL]: false,
    [EMAIL_SUBJECT_CONFIG_MODAL]: false,
  });
  const arrayCond = {
    isAdmin: props.isAdmin,
    idAdminGeneral: props.idAdminGeneral,
    appModules: props.appModules,
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseModal = (modalName) => {
    setOpenModal({ ...openModal, [modalName]: false });
  };

  const goToRoute = (route) => {
    if (route === COMPANY_SETTING_MODAL) {
      setOpenModal({ ...openModal, [COMPANY_SETTING_MODAL]: true });
    } else if (route === EMAIL_SUBJECT_CONFIG_MODAL) {
      setOpenModal({ ...openModal, [EMAIL_SUBJECT_CONFIG_MODAL]: true });
    } else if (!props.isToRouter) {
      props.history.push(route);
    } else {
      props.OpenAlertPending();
      props.LinkRedirect(route);
    }
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Ajustes">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <TuneIcon style={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {listItem.map(
          (item, index) =>
            item.showItem(arrayCond) && (
              <MenuItem key={index} onClick={() => goToRoute(item.route)}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.primary} />
              </MenuItem>
            ),
        )}
      </Menu>
      <CompanySettingModal
        open={openModal[COMPANY_SETTING_MODAL]}
        handleClose={() => handleCloseModal(COMPANY_SETTING_MODAL)}
      />
      <ManageTenderEmailSubjectConfig
        open={openModal[EMAIL_SUBJECT_CONFIG_MODAL]}
        handleClose={() => handleCloseModal(EMAIL_SUBJECT_CONFIG_MODAL)}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { user, app } = state;
  const { roleId } = user;
  const { applicationModules } = app;
  return {
    ...state,
    isAdmin: roleId === 1 || roleId === 99,
    idAdminGeneral: roleId === 99,
    appModules: applicationModules,
  };
};

export default withRouter(
  connect(mapStateToProps, { ...PendingSaveActions })(SettingsButton),
);
