import React from "react";
import MasterSendTenders from ".//MasterSendTender";

const AddColumnGrid = (getProps) => {
  const ColumnsEmail = (columns) => {
    const column = {
      id: "cols.enviados",
      field: "sendEmail",
      headerText: "E-Mail",
      width: "130",
      allowSorting: false,
      textAlign: "Center",
      template: (props) => (
        <MasterSendTenders
          tenderId={props.tenderId}
          tenderNumber={props.tenderNumber}
          {...getProps}
        />
      ),
    };
    if (!columns.some((item) => item.id === "cols.enviados")) {
      columns.splice(3, 0, column);
    }
    return columns;
  };

  return {
    ColumnsEmail,
  };
};

export default AddColumnGrid;
