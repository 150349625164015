import { applyMiddleware, createStore } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import { loadState } from "./utils/storePersistence";

const middleware = [thunk];

const persistedData = loadState();
export const store = createStore(
  rootReducer,
  persistedData,
  applyMiddleware(...middleware)
);
