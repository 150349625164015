import React from "react";
import { Grid, List, ListItem, Divider } from "@material-ui/core";
import { Fragment } from "react";

export const ListTender = (props) => {
  const { tenders } = props;
  return (
    <Grid container spacing={2} style={{ width: "450px" }}>
      <Grid item xs={12} lg={12}>
        <List className="py-0">
          <ListItem alignItems="center">
            <Grid container spacing={2}>
              <Grid item xs={9} lg={9}>
                Nomenclatura
              </Grid>
              <Grid item xs={3} lg={3}>
                Convocatoria
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
          {tenders.map((item) => (
            <Fragment key={item.tenderNumber}>
              <ListItem alignItems="center">
                <Grid container spacing={2}>
                  <Grid item xs={9} lg={9}>
                    {item.fileNumber}
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    {item.tenderNumber}
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
            </Fragment>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
