export default {
  comboCartaFianzaPE: [
    {
      id: 0,
      description: "Garantía de Fiel Cumplimiento",
    },
  ],
  comboGarantiasAR: [
    {
      id: 0,
      description: "Impugnación al pliego",
    },
    {
      id: 1,
      description: "Impugnación a la preadjudicación",
    },
    {
      id: 2,
      description: "Impugnación a la pre selección",
    },
    {
      id: 3,
      description: "Mantenimiento de oferta",
    },
    {
      id: 4,
      description: "Cumplimiento de contrato",
    },
    {
      id: 5,
      description: "Monedas habilitadas para presentacion de Garantías",
    },
  ],
  comboGarantiasCR: [
    {
      id: 0,
      description: "Garantía de participación",
    },
    {
      id: 1,
      description: "Garantía de cumplimiento",
    },
    {
      id: 2,
      description: "Colateral",
    },
  ],
};
