import { ActivityModal } from "../types";

export const OpenModal = (idModal, setting) => (dispatch) => {
  dispatch({
    type: ActivityModal.MODAL_ACTIVE,
    idModal: idModal,
    setting: setting,
  });
};

export const CloseModal = () => (dispatch) => {
  dispatch({
    type: ActivityModal.MODAL_DEACTIVE,
  });
};
