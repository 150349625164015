import React, { useState, useEffect } from "react";
import FstModal from "../../components/modal/FstModal";
import FSTGridCrud from "../../components/grid/FSTGridCrud";
import { HeaderTable } from "./BankRowsTable";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import { addOrUpdateBank, deleteBank, listBanks } from "./services/BankService";
import { connect } from "react-redux";
import * as SettingsChangeActions from "actions/settingsChangeActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BankModal = (props) => {
  const settingModal = { BtnNameSave: "Cerrar" };
  const [uniqValue, setUniqValue] = useState(moment().format());
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [reloadDefault, setReloadDefault] = useState(false);
  const [result, setResult] = useState({ result: [], count: 0 });
  const messageDelete =
    "El banco no puede ser eliminado porque esta siendo utilizado.";
  const ExportFileName = "Bancos.xlsx";

  const handleClose = () => {
    props.handleOpenModal(false);
  };

  const handleSave = async (data) => {
    await addOrUpdateBank([data]);
    showToaster(`El Banco "${data.bankName}" ha sido almacenado.`);
    setReloadDefault(true);
    setUniqValue(uniqValue + 1);
    props.SettingsChangeActivate();
  };

  const handleDelete = async (item) => {
    const request = [{ bankId: item.bankId }];
    await deleteBank(request);
    showToaster(`El Banco "${item.bankName}" ha sido eliminado.`);
    setReloadDefault(true);
    setUniqValue(uniqValue + 1);
    props.SettingsChangeActivate();
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 4000);
  };

  const getData = async (pagination) => {
    const rest = await listBanks(pagination);
    setResult({
      result: rest.data,
      count: rest.data.length,
    });
    setReloadDefault(false);
  };

  const validateDelete = (item) => {
    return item.disposable;
  };

  useEffect(() => {
    setReloadDefault(true);
  }, []);

  return (
    <>
      <FstModal
        open={props.open}
        title="Bancos"
        handleClose={handleClose}
        handleSave={handleClose}
        onlyOK={true}
        widthSize="sm"
        setting={settingModal}
        IsDisabledPrimary={false}
      >
        <FSTGridCrud
          id="gridBankModal"
          list={result}
          rows={HeaderTable}
          uniqValue={uniqValue}
          Getdata={getData}
          ExportFileName={ExportFileName}
          GetDataExport={listBanks}
          delete={(item) => handleDelete(item)}
          reloadDefault={reloadDefault}
          messageDelete={messageDelete}
          validateDelete={validateDelete}
          handleUpdate={(data) => handleSave(data)}
        />
      </FstModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.settingsChangeReducer,
  };
};

export default connect(mapStateToProps, { ...SettingsChangeActions })(
  BankModal,
);
