import React from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import { GestionStyle } from "../style/gestion";

const MiniDrawer = (props) => {
  const classes = GestionStyle(props);
  const [open] = React.useState(false);

  return (
    <>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        anchor="right"
      >
        <List>
          {props.filters.map((item, index) => (
            <Tooltip title={item.title} key={index} placement="left">
              <ListItem
                button
                onClick={() => props.handleSelected(item)}
                key={index}
                className={
                  item.statusId === props.selectId ? classes.selected : ""
                }
              >
                <ListItemIcon className={classes.items}>
                  <Badge
                    badgeContent={item.qtty}
                    classes={{ badge: classes.badgeCss }}
                  >
                    {item.icon}
                  </Badge>
                </ListItemIcon>
              </ListItem>
            </Tooltip>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>{props.children}</main>
    </>
  );
};

export default MiniDrawer;
