import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { useStyles } from "./style";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ModalManagePac from "./utils/ModalManagePac";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { getSearchManageFilter } from "../smart-search/services/smartSearchService";
import { getFiltersCountPac } from "./services/servicePac";
import FstCheckbox from "../../components/forms/checkbox/FstCheckbox";
import ListIcon from "@material-ui/icons/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Tooltip } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";

const ManagePacFilter = (props) => {
  const headerTable = 45;
  const classes = useStyles();
  const [openManage, setOpenManage] = useState(false);
  const [managePac, setManagePac] = useState(false);
  const [selectAll, setsSelectAll] = useState(true);
  const [actionEdit, setActionEdit] = useState(false);

  const getFilters = async (ss) => {
    const request = {
      orderBy: null,
      pageSize: 999,
      search: "",
      PageNumber: 1,
      OrderAsc: false,
    };
    const response = await getSearchManageFilter(request);
    const ids = response.data.currentPageItems.map(
      (item) => item.searchFilterId
    );
    const counts = await getFiltersCountPac({ FilterIds: ids });
    const auxList = response.data.currentPageItems.map((item) => {
      return {
        ...item,
        checked: true,
        count: counts.data.find((obj) => obj.filterId === item.searchFilterId)
          .count,
        fieldName: `checkbox-list-label-${item.searchFilterId}`,
      };
    });
    props.handleList(auxList, ss);
  };

  const handleChangeCheckox = (value, item) => {
    const aux = [...props.list];
    const index = aux.findIndex(
      (x) => x.searchFilterId === item.searchFilterId
    );
    aux[index].checked = value;
    props.handleList(aux);
  };

  useEffect(() => {
    getFilters(null);
  }, [props.updateList]);

  const handleToggle = (value) => () => {
    const index = props.list.findIndex(
      (item) => item.searchFilterId === value.searchFilterId
    );
    const aux = [...props.list];
    aux[index].checked = !value.checked;
    props.handleList(aux);
  };

  const toFilter = (item) => {
    setActionEdit(item ? true : false);
    setManagePac(item);
    setOpenManage(true);
  };

  const handleSelectAll = (name, value) => {
    setsSelectAll(value);
    const list = [...props.list].map((item) => {
      return {
        ...item,
        checked: value,
      };
    });

    props.handleListAll(list);
  };

  const handleSave = (ss) => {
    setActionEdit(false);
    setOpenManage(false);
    getFilters(ss);
    props.handleUpdateList();
  };

  return (
    <>
      <List
        className={classes.root}
        style={{
          height: props.height + headerTable,
          maxHeight: props.height + headerTable,
          overflow: "auto",
        }}
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            className={classes.header}
          >
            <ListItem role={undefined} dense button>
              <Tooltip title="Todos" placement="left">
                <ListItemIcon className={classes.rootCheckBox}>
                  <FstCheckbox
                    name="selectAll"
                    value={selectAll}
                    handleChange={(name, value) => handleSelectAll(name, value)}
                  />
                </ListItemIcon>
              </Tooltip>
              <Typography variant="h6" className={classes.headerName}>
                Filtros
              </Typography>
              <ListItemSecondaryAction>
                <Tooltip title="Ocultar" placement="left">
                  <IconButton
                    edge="end"
                    className="icon-size-filter"
                    size="small"
                    onClick={props.handlePanelHide}
                    aria-label="comments"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Agregar" placement="bottom">
                  <IconButton
                    edge="end"
                    color="primary"
                    className="icon-size-filter"
                    size="small"
                    onClick={() => toFilter(null)}
                    aria-label="comments"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Administrar" placement="right">
                  <IconButton
                    edge="end"
                    className="icon-size-filter"
                    size="small"
                    onClick={props.handleView}
                    aria-label="comments"
                  >
                    <ListIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          </ListSubheader>
        }
      >
        {props.list.map((item) => {
          return (
            <ListItem
              key={item.searchFilterId}
              role={undefined}
              dense
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon className={classes.rootCheckBox}>
                <FstCheckbox
                  name={item.fieldName}
                  value={item.checked}
                  handleChange={(name, value) =>
                    handleChangeCheckox(value, item)
                  }
                />
              </ListItemIcon>
              <ListItemText
                id={item.searchFilterId}
                primary={
                  <>
                    <span>{item.name}</span> <span>({item.count})</span>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="Editar" placement="right">
                  <IconButton
                    edge="end"
                    className="icon-size"
                    size="small"
                    aria-label="comments"
                    onClick={() => toFilter(item)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <ModalManagePac
        open={openManage}
        smartSearch={managePac}
        handleClose={() => setOpenManage(false)}
        handleSave={(ss) => handleSave(ss)}
        edit={actionEdit}
      ></ModalManagePac>
    </>
  );
};

export default withRouter(ManagePacFilter);
