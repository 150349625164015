import { post } from "../../../config/axios.js";

export const listTags = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
  };
  return await post("/api/Tag/ListTags", request);
};

export const addOrUpdateTag = async (request) => {
  return await post("/api/Tag/AddOrUpdateTag", request);
};

export const deleteTag = async (request) => {
  return await post("/api/Tag/DeleteTags", request);
};
