import React, { useEffect, useState } from "react";
import FstModal from "../../components/modal/FstModal";
import CompanyManagerBody from "./CompanyManagerBody";
import { connect } from "react-redux";

const btnTypes = {
  ADD: "add",
  EDIT: "Edit",
  REMOVE: "Remove",
};

const CompanyManagerModal = (props) => {
  useEffect(() => {
    return () => {};
  }, []);

  const validateError = () => {
    let error = [];
    const data = props.data;

    if (data !== null) {
      error.push(data.ruc.trim().length === 0 ? true : false);
      error.push(data.tenantId.trim().length === 0 ? true : false);
      error.push(data.clientId.trim().length === 0 ? true : false);
      error.push(data.clientSecret.trim().length === 0 ? true : false);
    }

    return error.indexOf(true) === -1 ? false : true;
  };

  return (
    <FstModal
      open={props.activeModal}
      title={props.titleModal}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      widthSize="sm"
      IsDisabledPrimary={validateError()}
      classContainer={"fst-Modal-Container"}
    >
      <CompanyManagerBody
        data={props.data}
        handlerChange={props.handlerChange}
      />
    </FstModal>
  );
};

const mapStateToProps = (state, props) => {
  const { modalReducer } = state;
  const { idModal, setting, getIsActive } = modalReducer;
  const titleModal = setting ? setting.titleModal : "";
  const activeModal = getIsActive ? getIsActive(props.id) : false;

  return {
    ...state,
    activeModal,
    titleModal,
    idModal,
  };
};

export default connect(mapStateToProps)(CompanyManagerModal);
