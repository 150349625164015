const stateKey = "state";

export const getState = () => JSON.parse(localStorage.getItem(stateKey));

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(stateKey);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};

export const persistState = async (state) => {
  try {
    localStorage.setItem(stateKey, JSON.stringify(state));
  } catch (e) {
    console.warn(e);
  }
};

export const purgeState = async () => {
  try {
    localStorage.removeItem(stateKey);
  } catch (e) {
    console.warn(e);
  }
};
