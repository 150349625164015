import React, { useState, useEffect } from "react";
import { Controls } from "../../../utils/controls";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { FormStyles } from "../../legal-document/style/style";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { formatDate, ConvertToDateShortUPPER } from "../../../utils/DateHelper";
import ReactHtmlParser from "react-html-parser";

const AwardedForm = (props) => {
  const classes = FormStyles();
  const { variant } = Controls.TextField;
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [label, setLabel] = useState("");
  useEffect(() => {
    setMinDate(ConvertToDateShortUPPER(props.awared.startDate));
    setMaxDate(ConvertToDateShortUPPER(props.awared.endDate));
    setLabel(
      ReactHtmlParser(
        `<span>Adjudicada por: <b>${props.awared.companyName}</b><span>`
      )
    );
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              inputVariant={variant}
              format={formatDate.es.SHORT_PICKER}
              required
              maxDate={maxDate}
              invalidDateMessage="Fecha Invalida"
              maxDateMessage="La fecha Desde no puede ser mayor a la Hasta"
              autoComplete="off"
              id="from"
              label="Desde"
              className={classes.KeyboardDatePicker}
              inputValue={props.awared.startDate}
              onChange={(event, newValue) => {
                setMinDate(ConvertToDateShortUPPER(newValue));
                props.onChange("startDate", newValue);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disableToolbar
              autoOk={true}
              inputVariant={variant}
              format={formatDate.es.SHORT_PICKER}
              required
              autoComplete="off"
              invalidDateMessage="Fecha Invalida"
              minDateMessage="La fecha Hasta no puede ser menor a la Desde"
              id="to"
              label="Hasta"
              className={classes.KeyboardDatePicker}
              minDate={minDate}
              inputValue={props.awared.endDate}
              onChange={(event, newValue) => {
                setMaxDate(ConvertToDateShortUPPER(newValue));
                props.onChange("endDate", newValue);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.awared.Active}
                onChange={(e) => {
                  props.onChange("won", e.target.checked);
                }}
                name="active"
                color="primary"
              />
            }
            label={label}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AwardedForm;
