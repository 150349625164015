import React, { useState, useEffect } from "react";
import { getTenderInformation } from "../../../actions/tenderInformationActions";
import TenderInfo from "../../tender-information/TenderInfo";
import TenderContainer from "./TenderContainer";
import { connect } from "react-redux";
import FstSpinnerLocal from "components/spinner/FstSpinnerLocal";
import * as PendingSaveActions from "../../../actions/pendingSaveActions";

const Tender = ({ tenderNumber, ...props }) => {
  const [tender, setTender] = useState(null);

  const getTender = async (number) => {
    props.FormShow();
    const axiosResult = await getTenderInformation(number);
    setTender(axiosResult.data);
    props.FormHide();
  };

  const onChangeTender = (key, value) => {
    setTender({
      ...tender,
      [key]: value,
    });
  };

  useEffect(() => {
    async function fetchData() {
      getTender(tenderNumber);
    }
    fetchData().catch(console.error);

    return () => {};
  }, []);

  return (
    <FstSpinnerLocal active={props.formActive} message={"Cargando..."}>
      <div>
        {tender && (
          <>
            <TenderInfo tender={tender} />
            <TenderContainer
              onChangeTender={onChangeTender}
              tender={tender}
              getTender={getTender}
            />
          </>
        )}
      </div>
    </FstSpinnerLocal>
  );
};

const mapStateToProps = (state) => {
  const { alertPendingSaveReducer } = state;
  const { formActive } = alertPendingSaveReducer;

  return {
    formActive,
  };
};

export default connect(mapStateToProps, { ...PendingSaveActions })(Tender);
