import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    //maxWidth: 360,
    border: "1px solid rgba(0, 0, 0, 0.12)",
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  rootCheckBox: {
    minWidth: "30px",
    height: "15px",
  },
  headerName: {
    display: "inline",
    padding: "0",
    fontWeight: "500",
    color: "rgba(0, 0, 0, 0.87)",
  },
  header: {
    zIndex: 400,
    borderBottom: "1px solid #e0e0e0",
    paddingBottom: "3px",
    paddingTop: "3px",
  },
  rootHeader: {
    padding: 10,
  },
  tipograghy: {
    margin: 0,
    fontWeight: 500,
    fontSize: "1.1rem",
    display: "inline-flex",
  },
  button: {
    margin: theme.spacing(1),
  },
  btnGrPrimary: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  btnGrIcon: {
    paddingBottom: 1,
    paddingTop: 4,
  },
  fstButtom: {
    display: "inline-flex",
    float: "right",
    paddingLeft: "10px",
  },
  ctnActializar: {
    height: "34px",
    marginTop: "-17px",
    display: "flex",
    alignItems: "center",
  },
  etqActializar: {
    display: "flex",
    padding: "3px 4px 2px 4px",
    fontSize: "0.75rem",
    backgroundColor: "#8c8c8c",
    borderRadius: "5px",
    color: "#edecec",
    fontWeight: "500",
    height: "19px",
    alignItems: "center",
  },
}));
