import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { FstGrid } from "../../components/grid/FstGrid";
import {
  PenaltiesHeaderTable,
  ColumnsDates,
  ColumnsBooleans,
} from "./PenaltiesHeaderTable";
import ManagePenalty from "./ManagePenalty";
import * as PenaltyActions from "../../actions/penaltyActions";
import * as SpinnerActions from "../../actions/spinnerActions";
import moment from "moment";

const Penalties = (props) => {
  const spinnerMessage = "Cargando...";
  const [open, setOpen] = useState(false);
  const [uniqueValue, setUniqueValue] = useState(1);
  const addPenalty = () => {
    setOpen(true);
  };

  const getPenalties = async (pag, filter) => {
    props.SpinnerShow(spinnerMessage);
    await props.GetPenalties(pag, filter);
    props.SpinnerHide();
    setUniqueValue(moment().format());
  };

  const dataGrid = {
    Getdata: getPenalties,
    EditOptions: { allowEditing: false, allowDeleting: false },
    Header: PenaltiesHeaderTable,
    take: 15,
    height: props.height,
    allowSorting: true,
    allowPaging: true,
    Add: addPenalty,
    exportFunc: props.GetPenaltiesExport,
    ExportFileName: "Penalidades.xlsx",
    ToolBar: {
      Add: true,
      AddTitle: "Agregar",
      Export: true,
      Search: true,
    },
    idGrid: "Penalties",
  };

  const handleClose = (refresehGrid) => {
    setOpen(false);
    if (refresehGrid) {
      setUniqueValue(moment().format());
    }
  };

  const defaultData = async () => {
    await props.GetPenaltiesDefault();
  };

  useEffect(() => {
    setUniqueValue(moment().format());
    defaultData();
  }, []);

  return (
    <>
      <ManagePenalty open={open} handleClose={(value) => handleClose(value)} />
      {dataGrid.height > 0 && (
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <FstGrid
              data={dataGrid}
              uniqValue={uniqueValue}
              ColumnsDate={ColumnsDates}
              ColumnsBooleans={ColumnsBooleans}
              result={{
                result: props.result,
                count: props.count,
              }}
              reloadDefault={props.reloadDefault}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (reducer) => {
  return reducer.penaltyReducer;
};
export default connect(mapStateToProps, {
  ...PenaltyActions,
  ...SpinnerActions,
})(Penalties);
