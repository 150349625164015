import { useQuery, useQueryClient } from "react-query";

export const useStoreData = (key) => {
  return useQuery(key, () => {
    return { data: [] };
  });
};

export const useStoreDataClient = (key, init) => {
  const queryClient = useQueryClient();

  const getDataQuery = () => {
    return queryClient.getQueryData(key);
  };

  const setDataQuery = (data) => {
    const newData = data ? { data: [data] } : { data: [] };
    queryClient.setQueryData(key, () => {
      return newData;
    });
  };

  const updateDataQuery = (data) => {
    queryClient.setQueryData(key, (oldData) => {
      return {
        data: [...oldData.data, data],
      };
    });
  };

  const resetDataQuery = () => {
    queryClient.resetQueries(key, { exact: true });
  };

  return {
    getDataQuery,
    setDataQuery,
    updateDataQuery,
    resetDataQuery,
  };
};
