import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { NavigationGestionStyle } from "./style/gestion";
import { withRouter } from "react-router-dom";
import GetAwardedReportModal from "./awarded/GetAwardedReportModal";
import { ManageTenderPanel } from "../../types";
import ListIcon from "@material-ui/icons/List";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import AccountBalance from "@material-ui/icons/AccountBalance";
import "./style/style.css";
import { useIntl } from "react-intl";
import { isLangPE } from "utils/common";
const ITEM_HEIGHT = 48;

const TenderGestionNavigation = (props) => {
  const actions = [
    {
      id: 1,
      title: "Adjudicaciones",
      action: () => openModalAdjudications(),
    },
  ];

  const classes = NavigationGestionStyle();
  const [value, setValue] = React.useState(0);
  const [openAdjudication, setOpenAdjudication] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElTender, setAnchorElTender] = React.useState(null);
  const open = Boolean(anchorEl);
  const openTener = Boolean(anchorElTender);
  const intl = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickTender = (event) => {
    setAnchorElTender(event.currentTarget);
  };
  const handleCloseTender = () => {
    setAnchorElTender(null);
  };
  const openModalAdjudications = () => {
    setOpenAdjudication(true);
    setAnchorEl(null);
  };

  const selectItem = (item) => {
    props.show(item);
    setAnchorElTender(null);
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        showLabels
        className={classes.root}
      >
        <BottomNavigationAction
          label="Licitaciones"
          aria-controls="long-menu-tender"
          onClick={handleClickTender}
          icon={<ListIcon />}
        />

        <Menu
          id="long-menu-tender"
          anchorEl={anchorElTender}
          keepMounted
          open={openTener}
          onClose={handleCloseTender}
        >
          <MenuItem
            onClick={() => selectItem({ module: ManageTenderPanel.TENDER })}
          >
            Licitaciones
          </MenuItem>
          <MenuItem
            onClick={() => selectItem({ module: ManageTenderPanel.CALENDAR })}
          >
            Calendario
          </MenuItem>
        </Menu>
        {isLangPE() && (
          <BottomNavigationAction
            label="Penalidades"
            onClick={() => selectItem({ module: ManageTenderPanel.PENALTY })}
            icon={<MonetizationOnIcon />}
          />
        )}

        <BottomNavigationAction
          label={intl.formatMessage({
            id: "tab.CartasFianza",
            defaultMessage: "Cartas Fianza",
          })}
          onClick={() =>
            selectItem({ module: ManageTenderPanel.LEGAL_DOCUMENT })
          }
          icon={<AccountBalance />}
        />

        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {actions.map((option) => (
            <MenuItem key={option.id} onClick={option.action}>
              {option.title}
            </MenuItem>
          ))}
        </Menu>
      </BottomNavigation>
      <GetAwardedReportModal
        open={openAdjudication}
        handleOpen={(value) => setOpenAdjudication(value)}
      />
    </>
  );
};

export default withRouter(TenderGestionNavigation);
