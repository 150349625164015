import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FstGrid } from "../../../components/grid/FstGrid";
import { Headers, ColumnsDates } from "./smartSearchHeader";
import {
  getFilteredAttachmentResult,
  getFilteredResult,
  highlightTender,
  viewTender,
} from "../services/smartSearchService";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
  Button,
} from "@material-ui/core";
import * as SpinnerActions from "../../../actions/spinnerActions";
import ManageTender from "../manage-tender/ManageTender";
import TenderInformation from "../tender-information/TenderInformation";
import { FstSplitButton } from "../../../components/forms/buttons/FstSplitButton";
import { downloadReport } from "../services/smartSearchService";
import { addToManagedTenders } from "../../../actions/tenderActions";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import {
  getSearchManageFilter,
  getSources,
} from "../services/smartSearchService";
import { FormattedMessage, useIntl } from "react-intl";
import useIntlColumns from "../../../intl/utils/format-elements/columnsIntl";
import { useStoreDataClient } from "../../../utils/hooks/useStoreData";
import { convertTimezoneToTimezone } from "utils/DateHelper";
import {
  useAppInsightsContext,
  useTrackEvent,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";
import { dateTimeFormat } from "utils/common";
import { isLangAR } from "utils/common";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SmartSearchListApply = (props) => {
  const spinnerMessage = "Cargando...";
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [reloadDefault, setReloadDefault] = useState(false);
  const [stateRequest, setStateRequest] = useState(null);
  const [list, setList] = useState({ rows: [], totalRows: 0 });
  const [openModaltender, setOpenModaltender] = useState(false);
  const [openTenderInformation, setOpenTenderInformation] = useState(false);
  const [tenderNumber, setTenderNumber] = useState(null);
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [tenderNumbers, setTenderNumbers] = useState(false);
  const [timeUpdate, setTimeUpdate] = useState({
    isDate: false,
    dateTime: moment().format("dd/MM/YYYY HH:mm"),
  });
  const queryClient = useStoreDataClient("SELECT_ROWS");

  let selectedRows = null;
  const classes = useStyles();
  const optionsExport = [
    { id: 2, description: "Gestionadas" },
    { id: 3, description: "Destacadas" },
  ];
  const primaryExport = { id: 1, description: "Exportar" };
  const [paginado, setPaginado] = useState(null);
  const { getHeader } = useIntlColumns("SmartSearch", "app.columnsSS", Headers);
  const selectionRows = (value) => {
    selectedRows = [...value];
  };
  const intl = useIntl();
  const appInsights = useAppInsightsContext();

  const actionsRows = [
    {
      text: "Gestionar licitación",
      target: ".e-content",
      id: "manage-tender",
      iconCss: "e-icons e-edit-6",
    },
    {
      text: "Gestionar item(s)",
      target: ".e-content",
      id: "manage-item",
      iconCss: "e-icons e-edit-5",
    },
    {
      text: "Destacar licitación",
      target: ".e-content",
      id: "highlight-all",
      iconCss: "e-icons e-collapse",
    },
    {
      text: "No Destacar licitación",
      target: ".e-content",
      id: "no-highlight-all",
      iconCss: "e-icons e-expand",
    },
    {
      text: "Destacar item(s)",
      target: ".e-content",
      id: "highlight-item",
      iconCss: "e-icons e-plus-square e-icon-custom",
    },
    {
      text: "No Destacar item(s)",
      target: ".e-content",
      id: "no-highlight-item",
      iconCss: "e-icons e-minus-square e-icon-custom",
    },
    {
      text: "Marcar como visto",
      target: ".e-content",
      id: "mark-seen",
      iconCss: "e-icons e-eye e-icon-custom",
    },
    {
      text: "Marcar como no visto",
      target: ".e-content",
      id: "no-mark-seen",
      iconCss: "e-icons e-eye-slash e-icon-custom",
    },
  ].concat(
    JSON.parse(localStorage.getItem("state")).user?.language === "es-AR"
      ? [
          {
            text: "Abrir en el portal",
            target: ".e-content",
            id: "open-link",
            iconCss: "e-icons e-open-link e-icon-custom",
          },
        ]
      : [],
  );

  const [firstRender, setFirstRender] = useState(true);

  const getData = async (pag, filter) => {
    const data = queryClient.getDataQuery();
    if (data.data.length > 0) {
      await viewedItemAll(data, true);
    }
    let list = await props.listFilter
      .filter((item) => item.checked)
      .map((item) => item.searchFilterId);
    if (props.listFilter.length === 0) {
      list = await getFilters();
    }
    const request = {
      TenderId: 0,
      TenderName: "",
      TenderDescription: "",
      Organism: "",
      Filters: list,
      PageNumber: pag.PageNumber,
      PageSize: pag.pageSize,
      SearchFilter: pag.search,
      OrderBy: firstRender && !pag.orderBy ? "publishedDate" : pag.orderBy,
      OrderAsc: firstRender && !pag.orderDirection ? false : pag.OrderAsc,
      IsPaginated: true,
    };
    setPaginado(pag);
    setStateRequest(request);
    executeLoadData(request);
    queryClient.resetDataQuery();
    setFirstRender(false);
  };

  const executeLoadData = async (request) => {
    props.SpinnerShow(spinnerMessage);
    getTimeUpdate();
    let response;
    if (props.attachmentResultView) {
      response = await getFilteredAttachmentResult(request);
    } else {
      response = await getFilteredResult(request);
    }

    setList({
      rows: response.data.currentPageItems,
      totalRows: response.data.totalItems,
    });
    props.SpinnerHide();
  };

  const getTimeUpdate = async () => {
    const getTime = {
      isDate: false,
      dateTime: moment().format("dd/MM/YYYY HH:mm"),
    };
    const resSource = await getSources();
    const portalName = intl.formatMessage({
      id: "portalNameSS",
      defaultMessage: "SEACE",
    });
    const getDateArray = resSource.data.filter(
      (item) => item.name === portalName,
    );
    if (getDateArray.length > 0) {
      if (getDateArray[0].lastScrapedDate) {
        getTime.isDate = true;
        getTime.dateTime = moment(getDateArray[0].lastScrapedDate).format(
          "DD/MM/YYYY HH:mm",
        );
      }
    }
    let language = JSON.parse(localStorage.getItem("state")).user?.language;
    // if language is CR, change timezone from Chile to CR
    if (language === "es-CR") {
      getTime.dateTime = convertTimezoneToTimezone(
        getTime.dateTime,
        "DD/MM/YYYY HH:mm",
        "America/Santiago",
        "America/Costa_Rica",
      );
    }

    getTime.dateTime = `${getTime.dateTime}hs.`;
    setTimeUpdate(getTime);
  };

  const trackExportList = useTrackEvent(appInsights, "SS: Export", {});

  const exportList = async (pag, filter) => {
    setPaginado(pag);
    const request = {
      TenderId: 0,
      TenderName: "",
      TenderDescription: "",
      Organism: "",
      Filters: props.listFilter
        .filter((item) => item.checked)
        .map((item) => item.searchFilterId),
      PageNumber: pag.PageNumber,
      PageSize: pag.pageSize,
      SearchFilter: pag.search,
    };
    const response = await getFilteredResult(request);
    trackExportList({ request: request, response: response });
    if (!!response && !!response.data) {
      return response.data.currentPageItems;
    }
  };

  const executeSave = async (selectedRows) => {
    props.SpinnerShow(spinnerMessage);
    const tendersToManageArr = selectedRows.map((item) => item.tenderNumber);
    const payload = tendersToManageArr.map((tn) => {
      return { tenderNumber: tn };
    });
    await addToManagedTenders(payload);
    setOpenToaster(true);
    setOpenToasterMessage("La licitación fue gestionada.");
    props.SpinnerHide();
    refreshGrid();
  };

  const executeSaveItem = async (selectedRows) => {
    props.SpinnerShow(spinnerMessage);
    let payload = [];
    for (let index = 0; index < selectedRows.length; index++) {
      const element = selectedRows[index];
      const idx = payload.findIndex(
        (item) => item.TenderNumber === element.tenderNumber,
      );
      if (idx >= 0) {
        payload[idx].ItemNumbers.push(element.itemNumber);
      } else {
        payload.push({
          TenderNumber: element.tenderNumber,
          ItemNumbers: [element.itemNumber],
        });
      }
    }
    await addToManagedTenders(payload);
    setOpenToaster(true);
    setOpenToasterMessage("El item fue gestionado.");
    props.SpinnerHide();
    refreshGrid();
  };

  const trackManageTender = useTrackEvent(appInsights, "SS: Manage Tender", {});

  const contextMenuIFnc = async (value) => {
    const {
      rowInfo: { rowData },
      item: { id },
    } = value;
    if (id === "manage-tender") {
      await executeSave(selectedRows);
      trackManageTender({ managedTenders: selectedRows });
    }
    if (id === "manage-item") {
      await executeSaveItem(selectedRows);
      trackManageTender({ managedItems: selectedRows });
    }

    if (id === "highlight-all") {
      await highlightAll(rowData, true);
    }
    if (id === "no-highlight-all") {
      await highlightAll(rowData, false);
    }
    if (id === "highlight-item") {
      await highlightItem(true);
    }
    if (id === "no-highlight-item") {
      await highlightItem(false);
    }
    if (id === "mark-seen") {
      await viewedItem(true);
      setTimeout(async () => {
        let filtered = viewedNotUpdatedRows.filter(
          (item) => item.tenderDetailId !== rowData.tenderDetailId,
        );
        selectedRows = filtered.filter(
          (item) =>
            selectedRows.findIndex(
              (item2) => item2.tenderDetailId === item.tenderDetailId,
            ) === -1,
        );
        await viewedItem(true);
      }, 600);
    }
    if (id === "no-mark-seen") {
      await viewedItem(false);
      setTimeout(async () => {
        let filtered = viewedNotUpdatedRows.filter(
          (item) => item.tenderDetailId !== rowData.tenderDetailId,
        );
        selectedRows = filtered.filter(
          (item) =>
            selectedRows.findIndex(
              (item2) => item2.tenderDetailId === item.tenderDetailId,
            ) === -1,
        );
        await viewedItem(true);
      }, 600);
    }
    if (id === "open-link") {
      if (rowData.link) window.open(rowData.link, "_blank");
    }
  };

  const highlightItem = async (value) => {
    let list = [];
    for (let index = 0; index < selectedRows.length; index++) {
      const element = selectedRows[index];
      const idx = list.findIndex(
        (item) => item.TenderNumber === element.tenderNumber,
      );
      if (idx >= 0) {
        list[idx].ItemNumbers.push(element.itemNumber);
      } else {
        list.push({
          TenderNumber: element.tenderNumber,
          ItemNumbers: [element.itemNumber],
        });
      }
    }
    setReloadDefault(false);
    await highlightTender(list, value);
    executeLoadData(stateRequest);
  };

  const highlightAll = async (item, mustHighlight) => {
    let list = [];
    for (let index = 0; index < selectedRows.length; index++) {
      const element = selectedRows[index];
      list.push({
        TenderNumber: element.tenderNumber,
        ItemNumbers: [0],
      });
    }

    //let mustHighlight = !item.userHighlightName;
    const rest = await highlightTender(list, mustHighlight);
    executeLoadData(stateRequest);
  };

  const trackViewTenderInfo = useTrackEvent(
    appInsights,
    "SS: View/Open Tender info",
    openTenderInformation,
  );

  const view = (row) => {
    if (row === undefined) return;
    setTenderNumber(row.tenderNumber);
    setOpenTenderInformation(true);
    trackViewTenderInfo({ tenderNumber: row.tenderNumber });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      console.log(e.shiftKey);
    }
  };
  var viewedNotUpdatedRows = [];
  const viewedArrowClick = (arg) => {
    if (!arg.row.classList.contains("bg-color-gray")) {
      arg.row.classList.add("bg-color-gray");
      // check if item is in viewedNotUpdatedRows
      const idx = viewedNotUpdatedRows.findIndex(
        (item) => item.tenderDetailId === arg.rowData?.tenderDetailId,
      );
      if (idx < 0 && arg.rowData) {
        viewedNotUpdatedRows.push(JSON.parse(JSON.stringify(arg.rowData)));
      }
    }
  };

  const viewedItem = async (value) => {
    let list = [];
    if (!value) {
      await normalizeData(selectedRows);
    }
    for (let element of selectedRows) {
      const idx = list.findIndex(
        (item) => item.TenderNumber === element.tenderNumber,
      );
      if (idx >= 0) {
        if (!element.itemNumber)
          // If no item number, then add a 0 to view the whole tender
          list[idx].ItemNumbers.push(0);
        else list[idx].ItemNumbers.push(element.itemNumber);
      } else {
        if (!element.itemNumber) {
          list.push({
            TenderNumber: element.tenderNumber,
            ItemNumbers: [0],
          });
        } else {
          list.push({
            TenderNumber: element.tenderNumber,
            ItemNumbers: [element.itemNumber],
          });
        }
      }
    }
    setReloadDefault(false);
    await viewTender(list, value);
    executeLoadData(stateRequest);
  };

  const normalizeData = async (select) => {
    const data = queryClient.getDataQuery();
    const filter = data.data.filter((item) => {
      const stringData = JSON.stringify(select);
      const itemData = JSON.stringify(item);
      if (stringData.indexOf(itemData) === -1) {
        return item;
      }
    });

    queryClient.setDataQuery(...filter);
  };

  const viewedItemAll = async (items, viewed) => {
    let list = [];
    items.data.map((item) => {
      if (!item.itemNumber) {
        list.push({
          TenderNumber: item.tenderNumber,
          ItemNumbers: [0],
        });
      } else {
        list.push({
          TenderNumber: item.tenderNumber,
          ItemNumbers: [item.itemNumber],
        });
      }
    });

    setReloadDefault(false);
    await viewTender(list, viewed);
  };

  const updateChangeSelect = async () => {
    const data = queryClient.getDataQuery();
    if (data.data.length > 0) {
      await viewedItemAll(data, true);
      queryClient.resetDataQuery();
    }
  };

  const dataGrid = {
    allowSorting: true,
    allowPaging: true,
    take: 17,
    Getdata: getData,
    Header: getHeader,
    height: props.height - 183,
    exportFunc: exportList,
    rowDoubleClickAction: view,
    rowClickAction: viewedArrowClick,
    actionsRows: actionsRows,
    contextMenuIFnc: contextMenuIFnc,
    selection: {
      settingSelection: {
        type: "Multiple",
        mode: "Row",
      },
      selectionRows: selectionRows,
    },
    viewedArrow: viewedArrowClick,
    ToolBar: {
      Search: true,
      Export: false,
      Refresh: true,
    },
    ExportFileName: "SmartSearch - Detalles de licitaciones.xlsx",
    idGrid: "SmartSearch",
    styles: props.styles,
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setReloadDefault(true);
      setUniqueValue(moment().format());
    }, 500);

    return () => {
      clearTimeout(timer);
      updateChangeSelect();
    };
  }, [props.listFilter]);

  const rowDataBound = (arg) => {
    if (arg.data.userViewedName) {
      arg.row.classList.add("bg-color-gray");
    }

    if (arg.data.userHighlightName) {
      arg.row.classList.remove("bg-color-gray");
      arg.row.classList.add("bg-color-green");
    }

    if (arg.data.userManage) {
      arg.row.classList.remove("bg-color-gray");
      arg.row.classList.remove("bg-color-green");
      arg.row.classList.add("bg-color-orange");
    }
  };

  const handleClose = () => {
    setOpenModaltender(false);
    setReloadDefault(false);
    executeLoadData(stateRequest);
  };

  const exportFilterPDF = async (value) => {
    let filename = props.attachmentResultView
      ? intl.formatMessage({
          id: "exportarSSDocsFileName",
          defaultMessage: "SmartSearchAttachmentsReport_AR.rdl",
        })
      : intl.formatMessage({
          id: "exportarSSFileName",
          defaultMessage: "SmartSearchReport.rdl",
        });
    const request = {
      IsPaginated: false,
      ReportId: 3,
      TenderId: 0,
      TenderName: "",
      TenderDescription: "",
      Organism: "",
      Filters: props.listFilter
        .filter((item) => item.checked)
        .map((item) => item.searchFilterId),
      SearchFilter: paginado.search,
      ExportContentType: value.id, //1 -> All 2 -> Managed 3 -> Highlighted
      FileName: filename,
    };
    const result = await downloadReport(request);
    exportToExcel(result.data);
  };

  const exportToExcel = (value) => {
    const filename = props.attachmentResultView
      ? "SmartSearch - Documentos - Detalles de licitaciones.xlsx"
      : "SmartSearch - Detalles de licitaciones.xlsx";
    const anchor_href =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      value;
    const exportLinkElement = document.createElement("a");
    exportLinkElement.hidden = true;
    exportLinkElement.download = filename;
    exportLinkElement.href = anchor_href;
    exportLinkElement.text = "downloading...";

    document.body.appendChild(exportLinkElement);
    exportLinkElement.click();
    exportLinkElement.remove();
  };

  const refreshGrid = () => {
    setReloadDefault(false);
    executeLoadData(stateRequest);
    setTimeout(() => {
      setOpenToaster(false);
    }, 1500);
  };

  const handleClick = (value) => {
    exportFilterPDF(value);
  };

  const getFilters = async () => {
    const request = {
      orderBy: null,
      pageSize: 999,
      search: "",
      PageNumber: 1,
      OrderAsc: false,
    };
    const response = await getSearchManageFilter(request);
    const auxList = await response.data.currentPageItems.map((item) => {
      return item.searchFilterId;
    });

    return auxList;
  };

  // -- Filtered Attachment result, only for AR, specific results that need different columns -- //

  const attachmentResultsHeader = [
    {
      id: "cols.Filtro",
      field: "searchFilterName",
      headerText: "Filtro",
      width: "150",
      textAlign: "Left",
      allowSorting: true,
      showInColumnChooser: false,
    },
    {
      id: "cols.OrdenAño",
      field: "orderYear",
      headerText: "Orden / Año",
      width: "150",
      textAlign: "Left",
      allowSorting: false,
    },
    {
      id: "cols.Id",
      field: "tenderNumber",
      headerText: "Id",
      width: "200",
      textAlign: "Center",
      allowSorting: true,
    },
    {
      id: "cols.Numenclatura",
      field: "fileNumber",
      headerText: "Nomenclatura",
      width: "250",
      textAlign: "Left",
      allowSorting: true,
    },
    {
      id: "cols.Nombre",
      field: "tenderName",
      headerText: "Nombre",
      width: "200",
      textAlign: "Left",
      allowSorting: true,
    },
    {
      id: "cols.Extracto",
      field: "excerpt",
      headerText: "Extracto",
      width: "150",
      textAlign: "Left",
      allowSorting: true,
    },

    {
      id: "cols.Portal",
      field: "portal",
      headerText: "Portal",
      width: "100",
      textAlign: "Left",
      allowSorting: true,
      template: (props) => {
        let portalLink;
        if (props.portal === "MinisterioSaludPBA") {
          portalLink =
            "https://sistemas.ms.gba.gov.ar/LicitacionesyContrataciones/web/app.php/publico/licitacion/lista";
        } else if (props.portal === "PAMICentral") {
          portalLink = "https://prestadores.pami.org.ar/result.php?c=7-5&par=1";
        } else if (props.portal === "PAMIUgls") {
          portalLink = "https://prestadores.pami.org.ar/result.php?c=7-5&par=2";
        } else if (props.portal === "ComprasGarrahan") {
          portalLink = "https://compras.garrahan.gov.ar/Licitaciones/Llamado";
        }

        return (
          <span>
            <a
              href={portalLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#007bff" }}
            >
              {props.portal}
            </a>
          </span>
        );
      },
    },
    {
      id: "cols.Entidad",
      field: "clientUnit",
      headerText: "Entidad",
      width: "200",
      textAlign: "Left",
      allowSorting: true,
    },
    {
      id: "cols.Publicacion",
      field: "publishedDate",
      headerText: "Publicación",
      width: "150",
      textAlign: "Center",
      allowSorting: true,
      template: (props) => (
        <span>{moment(props.publishedDate).format(dateTimeFormat)}</span>
      ),
    },
    {
      id: "cols.Cierre",
      field: "endDate",
      headerText: "Cierre",
      width: "150",
      textAlign: "Center",
      allowSorting: true,
      template: (props) =>
        props.endDate ? (
          <span>{moment(props.endDate).format(dateTimeFormat)}</span>
        ) : (
          ""
        ),
    },
    {
      id: "cols.GestionadoPor",
      field: "userManage",
      headerText: "Gestionado por",
      width: "150",
      textAlign: "Center",
      allowSorting: true,
    },
    {
      id: "cols.GestionadoEn",
      field: "dateCreationManage",
      headerText: "Gestionado en ",
      width: "150",
      textAlign: "Center",
      allowSorting: true,
      template: (props) =>
        props.dateCreationManage ? (
          <span>{moment(props.dateCreationManage).format(dateTimeFormat)}</span>
        ) : (
          ""
        ),
    },
    {
      id: "cols.DestacadoPor",
      field: "userHighlightName",
      headerText: "Destacado por",
      width: "150",
      textAlign: "Center",
      allowSorting: true,
    },
    {
      id: "cols.DestacadoEn",
      field: "highlightedDate",
      headerText: "Destacado en",
      width: "150",
      textAlign: "Center",
      allowSorting: true,
      template: (props) =>
        props.highlightedDate ? (
          <span>{moment(props.highlightedDate).format(dateTimeFormat)}</span>
        ) : (
          ""
        ),
    },
    {
      id: "cols.VistoPor",
      field: "userViewedName",
      headerText: "Visto por",
      width: "150",
      textAlign: "Center",
      allowSorting: true,
    },
    {
      id: "cols.VistoEn",
      field: "viewedDate",
      headerText: "Visto en",
      width: "150",
      textAlign: "Center",
      allowSorting: true,
      template: (props) =>
        props.viewedDate ? (
          <span>{moment(props.viewedDate).format(dateTimeFormat)}</span>
        ) : (
          ""
        ),
    },
  ];

  const getAttachmentResHeader = useIntlColumns(
    "SmartSearchAttachmentResults",
    "app.columnsSS",
    attachmentResultsHeader,
  );

  const actionsRowsAttachments = [
    {
      text: "Gestionar licitación",
      target: ".e-content",
      id: "manage-tender",
      iconCss: "e-icons e-edit-6",
    },
    {
      text: "Destacar licitación",
      target: ".e-content",
      id: "highlight-all",
      iconCss: "e-icons e-collapse",
    },
    {
      text: "No Destacar licitación",
      target: ".e-content",
      id: "no-highlight-all",
      iconCss: "e-icons e-expand",
    },
    {
      text: "Marcar como visto",
      target: ".e-content",
      id: "mark-seen",
      iconCss: "e-icons e-eye e-icon-custom",
    },
    {
      text: "Marcar como no visto",
      target: ".e-content",
      id: "no-mark-seen",
      iconCss: "e-icons e-eye-slash e-icon-custom",
    },
    {
      text: "Abrir Documento",
      target: ".e-content",
      id: "open-link",
      iconCss: "e-icons e-open-link e-icon-custom",
    },
  ];

  const dataGridAttachmentResults = {
    allowSorting: true,
    allowPaging: true,
    take: 17,
    Getdata: getData,
    Header: getAttachmentResHeader.getHeader,
    height: props.height - 183,
    exportFunc: exportList,
    rowDoubleClickAction: view,
    rowClickAction: viewedArrowClick,
    actionsRows: actionsRowsAttachments,
    contextMenuIFnc: contextMenuIFnc,
    selection: {
      settingSelection: {
        type: "Multiple",
        mode: "Row",
      },
      selectionRows: selectionRows,
    },
    viewedArrow: viewedArrowClick,
    ToolBar: {
      Search: true,
      Export: false,
      Refresh: true,
    },
    ExportFileName: "SmartSearch - Detalles de licitaciones.xlsx",
    idGrid: "SmartSearchAttachmentResults",
    styles: props.styles,
  };
  // --  -- //

  return (
    <>
      <Card>
        <CardHeader
          className={classes.rootHeader}
          title={
            <>
              <Typography
                gutterBottom
                variant="h6"
                component="h6"
                className={classes.tipograghy}
              >
                <FormattedMessage
                  id="title.Resultados"
                  defaultMessage="Resultados"
                />
              </Typography>
              {isLangAR() && (
                <>
                  <Button
                    variant={
                      props.attachmentResultView ? "outlined" : "contained"
                    }
                    color="primary"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      props.setAttachmentResultView(false);
                    }}
                  >
                    Renglones
                  </Button>
                  <Button
                    variant={
                      props.attachmentResultView ? "contained" : "outlined"
                    }
                    color="primary"
                    size="small"
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      props.setAttachmentResultView(true);
                    }}
                  >
                    Documentos
                  </Button>
                </>
              )}

              <FstSplitButton
                clasNameGroup={classes.fstButtom}
                clasNamePrymary={classes.btnGrPrimary}
                btnGrIcon={classes.btnGrIcon}
                classNameBtn={classes.button}
                options={optionsExport}
                primary={primaryExport}
                label="Exportar"
                handleClick={handleClick}
              />
            </>
          }
        ></CardHeader>
        <Divider />
        <CardContent onKeyUp={handleKeyPress}>
          {timeUpdate.isDate && (
            <div className={classes.ctnActializar}>
              {/*<Tooltip title="Actualizar">*/}
              <div className={classes.etqActializar}>
                <span style={{ display: "flex" }}>
                  Ultima actualización: {timeUpdate.dateTime}
                </span>
              </div>
              {/*</Tooltip>*/}
            </div>
          )}
          {!props.attachmentResultView && (
            <FstGrid
              data={dataGrid}
              uniqValue={uniqValue}
              reloadDefault={reloadDefault}
              rowDataBound={rowDataBound}
              ColumnsDate={ColumnsDates}
              result={{
                result: list.rows,
                count: list.totalRows,
              }}
            />
          )}
          {props.attachmentResultView && isLangAR() && (
            <FstGrid
              data={dataGridAttachmentResults}
              uniqValue={uniqValue}
              reloadDefault={reloadDefault}
              rowDataBound={rowDataBound}
              ColumnsDate={ColumnsDates}
              result={{
                result: list.rows,
                count: list.totalRows,
              }}
            />
          )}
        </CardContent>
      </Card>
      {openModaltender && (
        <ManageTender
          open={openModaltender}
          tenders={[...tenderNumbers]}
          handleClose={() => handleClose()}
        />
      )}

      {openTenderInformation && (
        <TenderInformation
          open={openTenderInformation}
          tenderNumber={tenderNumber}
          handleClose={() => setOpenTenderInformation(false)}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
    </>
  );
};
const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  withRouter(SmartSearchListApply),
);
