import { post, put, get } from "../../../config/axios.js";

export const addOrUpdateSearchFilter = async (request) => {
  return await post("/api/SearchFilter/AddOrUpdateSearchFilter", request);
};

export const getSmartSearchById = async (request) => {
  return await post("/api/SearchFilter/ListSearchFiltersById", request);
};

export const getSearchManageFilter = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
  };
  return await post("/api/SearchFilter/ListSearchFilters", request);
};

export const deleteSmartSearch = async (request) => {
  return await post("/api/SearchFilter/DeleteSearchFilter", request);
};

export const updateDaysToAllFilters = async (request) => {
  return await post(`/api/SearchFilter/UpdateDaysToAllFilters/${request}`, {});
};

export const getFilteredResult = async (request) => {
  return await post("/api/smartSearchFilter/getFilteredResult", request);
};

export const getFilteredAttachmentResult = async (request) => {
  return await post(
    "/api/smartSearchFromFile/getFilteredAttachmentResult",
    request,
  );
};

export const getFilteredAttachmentCount = async (request) => {
  return await post(
    "/api/smartSearchFromFile/getFiltersCount",
    request,
  );
};

export const highlightTender = async (request, Highlighted) => {
  return await post(
    `/api/smartSearchFilter/highlightTender?highlighted=${Highlighted}`,
    request,
  );
};

export const getFiltersCount = async (request) => {
  return await post("/api/searchFilter/getFiltersCount", request);
};

export const downloadReport = async (request) => {
  return await post("/api/reportwriter/exportSmartSearch", request);
};

export const setUpdateUserSetting = async (request) => {
  return await put("/api/user/updateUserClientSettings", request);
};

export const viewTender = async (request, viewed) => {
  return await post(
    `/api/smartSearchFilter/viewTender?viewed=${viewed}`,
    request,
  );
};

export const getSources = async () => {
  return await get("api/smartSearchFilter/getSources");
};
