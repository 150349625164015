import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FstModalConfirm from "components/modal/FstModalConfirm";
import useIntlColumns from "intl/utils/format-elements/columnsIntl";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DownloadExcel from "views/manage-pac/utils/downloadExcel";
import EntityAutocompletePaging from "views/tender-analisys/params/EntityAutocompletePaging";
import * as SpinnerActions from "../../actions/spinnerActions";
import AdminAccess from "../../components/AdminAccess";
import useDataFile from "../../utils/hooks/useDataFile";
import ImportFileIcon from "../../utils/custom-icons/ImportFile";
import ABMGrid from "./ABMGrid";
import ABMHeaderActions from "./ABMHeaderActions";
import { competitorsHeader } from "./competitorsHeader";
import {
  getCompetitors,
  getCompetitorsByUniqueId,
  getCompetitorsIdsByCompany,
  putCompetitors,
} from "./services/abmServices";
import { useStyles } from "./style";
import { Alert } from "@material-ui/lab";
import { TenderParameter } from "views/tender-information/services/tenderParameter";

function ABMCompetitorsOC(props) {
  const classes = useStyles();
  const intl = useIntl();
  const [uniqueValue, setUniqueValue] = useState(moment().format());
  const [isDataModified, setIsDataModified] = useState(false);

  const [paginatedData, setPaginatedData] = useState([]); // Paginated data to show in table
  const [filteredDataLength, setFilteredDataLength] = useState(0);
  const [currentPageRequest, setCurrentPageRequest] = useState(null);

  const [newAddedSelected, setNewAddedSelected] = useState([]);
  const [selectedCompetitorsIds, setSelectedCompetitorsIds] = useState([]);
  const [initialSelectedCompetitorsIds, setInitialSelectedCompetitorsIds] =
    useState([]);
  const [isInit, setIsInit] = useState(true);
  const dataFile = useDataFile();
  const { getHeader } = useIntlColumns(
    "gridCompetitors",
    "app.columnsABMCompetitors",
    competitorsHeader,
  );
  const [isDownload, setIsDownload] = useState(false);
  let timerMessageError = null;
  const [messageErrorFile, setMessageErrorFile] = useState(null);

  /// Height handling
  const [height, setHeight] = useState();
  const handleResize = useCallback(() => {
    const padding = 130;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  ///

  const handleSave = () => {
    props.SpinnerShow("Guardando...");
    setTimeout(() => {
      putCompetitors(selectedCompetitorsIds); // Save data to backend
      setIsDataModified(false);
      props.SpinnerHide();
    }, 750);
  };

  const handleDelete = async (item) => {
    props.SpinnerShow("Guardando...");
    updateAllData(
      paginatedData.filter((x) => +x.id !== +item.id),
      selectedCompetitorsIds.filter((x) => +x !== +item.id),
      newAddedSelected.filter((x) => +x.id !== +item.id),
    );
  };

  const handleDeleteAllSelected = () => {
    props.SpinnerShow("Cargando...");
    setTimeout(() => {
      setSelectedCompetitorsIds([]);
      setNewAddedSelected([]);
      setIsDataModified(true);
      getCompetitorsData({ useCurrent: true }, null, []);
      props.SpinnerHide();
    }, 500);
  };

  const reloadPage = () => {
    props.SpinnerShow("Cargando...");
    setTimeout(() => {
      setSelectedCompetitorsIds(initialSelectedCompetitorsIds);
      setNewAddedSelected([]);
      setIsDataModified(false);
      getCompetitorsData({ useCurrent: true }, null);
      props.SpinnerHide();
    }, 350);
  };

  useEffect(() => {
    setFilteredDataLength(selectedCompetitorsIds.length);
  }, [selectedCompetitorsIds]);

  /////////////////////////////////////////////////////////////////////////

  const setData = async (request, competitors) => {
    if (competitors) {
      setPaginatedData(competitors);
      return;
    }
    let fetchedData = await getCompetitors(request);
    let parsedData = fetchedData.data.currentPageItems.map((x) => {
      return {
        id: +x.competitorId,
        name: x.name,
        uniqueId: x.uniqueId,
      };
    });
    setPaginatedData(parsedData);
  };

  const getCompetitorsData = async (pag, _, specificSelectedCompetitors) => {
    props.SpinnerShow("Cargando...");

    if (pag.useCurrent) pag = currentPageRequest;
    if (isInit) {
      // fetch all competitors for autocomplete
      var dataByCompany = await getCompetitorsIdsByCompany();
      setInitialSelectedCompetitorsIds(
        JSON.parse(JSON.stringify(dataByCompany.data)),
      );
      setSelectedCompetitorsIds(dataByCompany.data);
      //
      setIsInit(false);
    }
    setCurrentPageRequest(pag);
    await setData(
      { ...pag, filter: pag.search, isSelected: true, PageNumber: 1 },
      specificSelectedCompetitors ? specificSelectedCompetitors : null,
    );
    props.SpinnerHide();
    return;
  };

  const btnTypes = { REMOVE: "Remove" };
  const remove = (btn, item) => {
    if (btn.type === btnTypes.REMOVE) handleDelete(item);
  };
  const actionCustomRow = (btn, item) => {
    if (!item) return;
    remove(btn, item);
  };

  const dataGrid = {
    Getdata: getCompetitorsData,
    Header: getHeader,
    allowSorting: true,
    idGrid: "gridCompetitors",
    ToolBar: { Search: true },
    height: height - 220,
    ActionCustomRow: actionCustomRow,
    take: 999999,
  };

  const updateAllData = (
    newPaginatedData,
    newSelectedCompetitorsIds,
    newNewAddedSelected,
  ) => {
    setPaginatedData(newPaginatedData);
    setSelectedCompetitorsIds(newSelectedCompetitorsIds);
    setNewAddedSelected(newNewAddedSelected);
    setIsDataModified(true);
    props.SpinnerHide();
  };

  const handleParentEntity = async (e) => {
    props.SpinnerShow("Cargando...");
    let competitorToAdd = await getCompetitorsByUniqueId([e.uniqueId]);
    if (
      competitorToAdd.data.competitors.length === 0 ||
      competitorToAdd.data.notFound.length > 0
    ) {
      props.SpinnerHide();
      return;
    }
    competitorToAdd = competitorToAdd.data.competitors[0];
    let newItem = {
      id: +competitorToAdd.competitorId,
      name: competitorToAdd.name.replaceAll('"', ""),
      uniqueId: competitorToAdd.uniqueId,
    };
    let newPaginatedData = [...paginatedData, newItem];
    updateAllData(
      newPaginatedData,
      selectedCompetitorsIds.concat(newItem.id),
      newAddedSelected.concat(newItem),
    );
  };

  const disabledOption = (option) => {
    return selectedCompetitorsIds.includes(+option.competitorId);
  };

  const validateFile = (file, data) => {
    if (file && file.size > TenderParameter.MaxFileSize10MB) {
      const message = `El archivo ${
        file ? file.name : ""
      } supera el tamaño maximo permitido de 10 MB!`;
      setMessageErrorFile(message);
      timerMessageError = setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    } else if (!data) {
      const message = `El archivo ${
        file ? file.name : ""
      } es de tipo inválido o no tiene datos!`;
      setMessageErrorFile(message);
      timerMessageError = setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    }
    setMessageErrorFile(null);

    return true;
  };

  const [notFoundUniqueId, setNotFoundUniqueId] = useState(false);
  const [notFoundUniqueIdList, setNotFoundUniqueIdList] = useState([]);

  const importFile = async (event) => {
    props.SpinnerShow("Cargando...");
    const data = await dataFile.getDataFile(event);
    if (!validateFile(event.target?.files[0], data)) {
      props.SpinnerHide();
      return;
    }
    let competitorToAdd = await getCompetitorsByUniqueId(data);

    let filter = [];
    competitorToAdd.data.competitors.map((items) => {
      const newItem = {
        id: +items.competitorId,
        name: items.name.replaceAll('"', ""),
        uniqueId: items.uniqueId,
      };
      filter.push(newItem);
    });
    let newPaginatedData = [...paginatedData, ...filter];

    if (competitorToAdd.data.notFound.length > 0) {
      setNotFoundUniqueIdList(competitorToAdd.data.notFound);
      setNotFoundUniqueId(true);
    }
    updateAllData(
      newPaginatedData,
      selectedCompetitorsIds.concat(...filter.map((x) => x.id)),
      newAddedSelected.concat(...filter),
    );
  };

  const addFiles = () => {
    document.getElementById("import-file-xlsx").click();
  };

  const exportData = async () => {
    setIsDownload(false);
    return paginatedData.slice();
  };

  ///////////////////////////////////////////////////////////////////////////

  const loadOptionsCompetitors = async (
    searchQuery,
    loadedOptions,
    { page },
  ) => {
    const request = {
      idOrder: 0,
      pageNumber: page,
      pageSize: 100,
      filter: searchQuery,
      orderAsc: true,
      orderBy: "",
      isSelected: false,
    };

    const response = await getCompetitors(request);
    const responseJSON = await response.data.currentPageItems;

    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: response.data.currentPage,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
      },
    };
  };

  return (
    <AdminAccess>
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <ABMHeaderActions
                headerTitle="Administrar Competidores"
                isDataModified={isDataModified}
                handleSave={handleSave}
                reloadPage={reloadPage}
              />
              <Divider />
              <CardContent>
                <EntityAutocompletePaging
                  disabledOption={disabledOption}
                  handleParentEntity={handleParentEntity}
                  loadOptions={loadOptionsCompetitors}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", marginBottom: "-35px" }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <div style={{ float: "left" }}>
                      <h3>Competidores seleccionados</h3>
                    </div>
                    <div style={{ float: "right" }}>
                      <Tooltip
                        id="download-excel-button"
                        title="Descargar Excel"
                      >
                        <IconButton
                          className={classes.buttonExcel}
                          onClick={() => setIsDownload(true)}
                          disabled={paginatedData.length === 0}
                        >
                          <InsertDriveFileIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        id="import-button"
                        title="Importar desde archivo"
                      >
                        <IconButton color="primary">
                          <ImportFileIcon
                            handleIconClick={() => {
                              addFiles();
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        id="remove-button"
                        title="Eliminar todos los seleccionados"
                      >
                        <IconButton
                          color="secondary"
                          onClick={handleDeleteAllSelected}
                          disabled={paginatedData.length === 0}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
              </CardContent>
              <CardContent>
                <ABMGrid
                  dataGrid={dataGrid}
                  uniqueValue={uniqueValue}
                  paginatedData={paginatedData}
                  dataLength={filteredDataLength}
                />
              </CardContent>
            </Card>
            <FstModalConfirm
              title={`${intl.formatMessage({
                id: "etq.PopupCompetitors",
                defaultMessage: "RUC",
              })}s no encontrados`}
              open={notFoundUniqueId}
              message={`Algunos ${intl.formatMessage({
                id: "etq.PopupCompetitors",
                defaultMessage: "RUC",
              })}s no pudieron ser encontrados: ${notFoundUniqueIdList.join(
                ", ",
              )}`}
              success={() => {
                setNotFoundUniqueId(false);
                setNotFoundUniqueIdList([]);
              }}
              onlyOK={true}
              setting={{ BtnNameSave: "Continuar" }}
            />
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={messageErrorFile}
            >
              <Alert severity="error">{messageErrorFile}</Alert>
            </Snackbar>
          </Grid>
        </Grid>
        <input
          accept=".xlsx,.xls,.txt"
          style={{ display: "none" }}
          id="import-file-xlsx"
          type="file"
          hidden
          onInput={(e) => {
            importFile(e);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <DownloadExcel
          isDonwload={isDownload}
          fileName={"Competidores Seleccionados OC"}
          getData={exportData}
          columns={getHeader.filter((x) => x.headerText !== "Acciones")}
          paging={{ ...currentPageRequest, totalRows: filteredDataLength }}
          nameSheet={"Competidores"}
        />
      </div>
    </AdminAccess>
  );
}
const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  withRouter(ABMCompetitorsOC),
);
