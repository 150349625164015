import { Spinner } from "../types";

export const SpinnerShow = (message) => (dispatch) => {
  dispatch({
    type: Spinner.ACTIVE,
    message: message,
  });
};

export const SpinnerHide = () => (dispatch) => {
  dispatch({
    type: Spinner.DEACTIVE,
    message: "",
  });
};
