import React, { useState } from "react";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

const TextEditor = (props) => {
  const [text, setText] = useState(EditorState.createEmpty());
  const onChangeText = (value) => {
    /*const blockHtml = htmlToDraft("<p>Prueba de busqueda</p>\n<p>cambio de</p>\n<p></p>\n");
        const { contentBlocks, entityMap } = blockHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);
        setText(editorState)*/
    setText(value);
    const text = draftToHtml(convertToRaw(value.getCurrentContent()));
    props.onChange(text);
  };

  const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      let img = new Image();

      reader.onload = function (e) {
        img.src = this.result;
        console.log(img.src);
        resolve({
          data: {
            link: img.src,
          },
        });
      };
    });
  };

  return (
    <div>
      {
        <Editor
          editorState={text}
          wrapperClassName={props.wrapperClassName}
          editorClassName="text-editor"
          onEditorStateChange={onChangeText}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            image: {
              urlEnabled: true,
              uploadEnabled: true,
              ignmentEnabled: true,
              previewImage: true,
              uploadCallback: uploadCallback,
              alt: { present: false, mandatory: false },
            },
          }}
          localization={{
            locale: "es",
          }}
        />
      }
    </div>
  );
};

export default TextEditor;
