import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardContent, Divider } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import { useStyles } from "./style";
import {
  getUsers,
  setEnableDisableUser,
  updateUser,
} from "../tender-information/services/usersService";
import Grid from "@material-ui/core/Grid";
import UserManagerGrid from "./UserManagerHeader";
import UserManagerModal from "./UserManagerModal";
import { connect } from "react-redux";
import * as SpinnerActions from "../../actions/spinnerActions";
import * as ModalAction from "../../actions/modalAction";
import FstModalConfirm from "../../components/modal/FstModalConfirm";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import EditMailModal from "./EditMailModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const btnTypes = {
  ADD: "add",
  EDIT: "Edit",
  REMOVE: "Remove",
};

const UserManager = (props) => {
  const classes = useStyles();
  const [list, setList] = useState({ rows: [], totalRows: 0 });
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [open, setOpen] = useState(false);
  const [rowSelect, setRowSelect] = useState([]);
  const [action, setAction] = useState(btnTypes.ADD);
  const [deleteUser, setDeleteUser] = useState(false);
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [userRemove, setUserRemove] = useState([]);
  const [titleModal, setTitleModal] = useState("");

  const getDataUser = async () => {
    props.SpinnerShow("Cargando...");
    const response = await getUsers();
    setList({
      rows: response.data,
      totalRows: response.data.length,
    });
    props.SpinnerHide();
  };

  const executeCreate = async (item) => {
    const setting = {
      titleModal: "Agregar Usuario",
      action: btnTypes.ADD,
    };
    props.OpenModal("MODAL_USER", setting);
  };

  const executeDelete = async (item) => {
    if (!item) return;
    setAction(btnTypes.REMOVE);
    setDeleteUser(true);
    setUserRemove(item);
  };

  const handleRemove = () => {
    showToaster(`El usuario ${userRemove.login} ha sido borrado exitosamente`);
    setDeleteUser(false);
  };

  const executeEdit = async (item) => {
    setTitleModal("Editar Usuario");
    setAction(btnTypes.EDIT);
    setRowSelect(item);
    setOpen(true);
  };

  const handleClose = () => {
    props.CloseModal();
  };

  const handleSave = () => {};

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 4000);
  };

  const enableDisableUser = async (request) => {
    await setEnableDisableUser(request);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setUniqueValue(moment().format());
      props.CloseModal();
    });

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // -- Email modal logic -- //
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [emailModalUser, setEmailModalUser] = useState(null);

  const handleSaveEmail = async (user) => {
    await updateUser(user);
    if (emailModalOpen) {
      setEmailModalOpen(false);
    }
    showToaster(
      `El correo del usuario ${user.firstName} ${user.lastName} ha sido actualizado exitosamente`,
    );
    getDataUser();
  };
  // --  -- //

  return (
    <>
      <Grid container className={classes.content}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.paddingLeft20}
        >
          <Card>
            <CardHeader
              className={classes.rootHeader}
              title={
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h6"
                  className={classes.tipograghy}
                >
                  Administrar Usuarios
                </Typography>
              }
            ></CardHeader>
            <Divider />
            <CardContent>
              <UserManagerGrid
                dataUser={getDataUser}
                listData={list}
                uniqValue={uniqValue}
                appModules={props.applicationModules}
                executeCreate={executeCreate}
                executeDelete={executeDelete}
                executeEdit={executeEdit}
                handleActiveUser={enableDisableUser}
                isAdmin={props.isAdmin}
                setEmailModalOpen={setEmailModalOpen}
                setEmailModalUser={setEmailModalUser}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <UserManagerModal
        id={"MODAL_USER"}
        handleClose={handleClose}
        handleSave={handleSave}
        appModules={props.applicationModules}
        userSelect={rowSelect}
        action={action}
        title={titleModal}
      />

      <FstModalConfirm
        title="Eliminar Usuario"
        close={() => {
          setDeleteUser(false);
        }}
        open={deleteUser}
        message={"¿Esta seguro que desea eliminar este Usuario?"}
        success={handleRemove}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>

      {/* EDIT MAIL MODAL - TODO: remove later with new UI */}
      <EditMailModal
        open={emailModalOpen}
        user={emailModalUser}
        onClose={() => setEmailModalOpen(false)}
        onSave={handleSaveEmail}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { user, app, spinnerReducer } = state;
  const { fullName } = user;
  const { applicationModules } = app;
  const { roleId } = user;

  const isAdmin = roleId === 1 || roleId === 99;

  return {
    ...ownProps,
    fullName,
    applicationModules,
    spinnerReducer,
    isAdmin,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions, ...ModalAction })(
  UserManager,
);
