import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { FormTicketContentStyles } from "../style/TicketStyle";
import { useIntl } from "react-intl";

const TicketConfig = (props) => {
  const classes = FormTicketContentStyles();
  const intl = useIntl();
  const options = [
    { text: "No Requerida", id: 0 },
    { text: "Requerida", id: 1 },
  ];
  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} sm={3} md={3} className={classes.root}>
          <Autocomplete
            id="tags"
            className={classes.AutocompletePositionot}
            style={{ marginBottom: 2, marginTop: 3 }}
            size="small"
            openOnFocus
            disabled={props.tender.faithFulObservanceRequired}
            value={props.tender.ConfigLegalDocument}
            options={options}
            onChange={(event, newValue) => {
              props.onChange("ConfigLegalDocument", newValue);
            }}
            getOptionLabel={(option) => option.text}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({
                  id: "etq_CartaFianza",
                  defaultMessage: "Carta Fianza",
                })}
                margin="normal"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TicketConfig;
