//@flow
import React, { useState } from "react";
import type { Node } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  selected: {
    "&$selected": {
      backgroundColor: "#E1E1E1",
      "& .MuiListItemIcon-root": {
        color: "#000",
      },
      "& .MuiListItemText-root .MuiTypography-root": {
        color: "#000",
        fontWeight: "500",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#E1E1E1",
      "& .MuiListItemIcon-root": {
        color: "#000",
      },
      "& .MuiListItemText-root .MuiTypography-root": {
        color: "#000",
        fontWeight: "500",
      },
    },
  },
  selectedHover: {
    "&:hover": {
      backgroundColor: "#E1E1E1",
      "& .MuiListItemIcon-root": {
        color: "#000",
      },
      "& .MuiListItemText-root .MuiTypography-root": {
        color: "#000",
        fontWeight: "500",
      },
    },
  },
}));

export type LeafMenuItem = {
  label: string,
  icon: Node,
  route: string,
};

export type RootMenuItem = {
  label: string,
  icon: Node,
  route: string,
  children: Array<LeafMenuItem>,
};

export const DrawerTreeMenu = withRouter(
  ({
    history,
    menuItems,
    location,
    isToRouter,
    activarAlert,
    toRedirect,
  }: {
    history: Node,
    menuItems: Array<RootMenuItem>,
  }) => {
    const classes = useStyles();
    const { pathname } = location;
    const [selectedId, setSelectedId] = useState(pathname);

    function goToRoute(route: string) {
      if (!isToRouter) {
        setSelectedId(route);
        history.push(route);
      } else {
        activarAlert();
        toRedirect(route);
      }
    }

    const startingState = {};
    menuItems.forEach((mi) => {
      if (hasChildren(mi)) {
        const key = `open${mi.label}`;
        startingState[key] = false;
      }
    });

    const [openTreeBranches, setOpen] = React.useState(startingState);

    function handleExpandMenuItem(key) {
      const state = { ...openTreeBranches };
      state[key] = !state[key];
      setOpen(state);
    }

    function hasChildren(menuItem) {
      return !!menuItem.children && menuItem.children.length > 0;
    }

    /*
    function closeAllItems() {
        const state = {...openTreeBranches};
        menuItems.forEach(mi => {
            const key = `open${mi.label}`;
            state[key] = false;
        });
        setOpen(state);
    }
    */

    return (
      <List>
        {menuItems.map((mi, idx) => (
          <React.Fragment key={idx}>
            <ListItem
              button
              key={mi.label}
              className={
                classes.selectedHover +
                " " +
                mi.class +
                " " +
                (selectedId === mi.route ? classes.selected : "")
              }
              onClick={
                hasChildren(mi)
                  ? () => handleExpandMenuItem(`open${mi.label}`)
                  : () => goToRoute(mi.route)
              }
            >
              <ListItemIcon>{mi.icon}</ListItemIcon>
              <ListItemText primary={mi.label} />
              {hasChildren(mi) ? (
                openTreeBranches[`open${mi.label}`] ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : null}
            </ListItem>
            {hasChildren(mi) ? (
              <Collapse
                in={openTreeBranches[`open${mi.label}`]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {mi.children.map((c, idx) => (
                    <ListItem
                      button
                      className={classes.nested}
                      key={idx}
                      onClick={() => goToRoute(c.route)}
                    >
                      <ListItemIcon>{c.icon}</ListItemIcon>
                      <ListItemText primary={c.label} />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            ) : null}
          </React.Fragment>
        ))}
      </List>
    );
  }
);
