import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AuthConfig from "./components/AuthConfig";
import { Provider } from "react-redux";
import { store } from "./store";
import CssBaseline from "@material-ui/core/CssBaseline";
import { acquireToken, login } from "./utils/activeDirectory";
import TenderAppFrame from "./components/TenderAppFrame";
import { persistState } from "./utils/storePersistence";
import { throttle } from "throttle-debounce";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Loading from "./views/Loading";
import AadCallback from "./views/AadCallback";
import { isCurrentUrlProtocolValid } from "./utils/common";
import { L10n } from "@syncfusion/ej2-base";
import es from "./config/es-ES.json";
import { QueryClient, QueryClientProvider } from "react-query";
import { LangProvider } from "./intl/context/langContext";
import AuthRoute from "./components/AuthRoute";

import ManageTenders from "./views/manage-tenders/ManageTenders";
import SmartSearch from "./views/smart-search/SmartSearch";
import TenderAnalysis from "./views/tender-analisys/tender/TenderAnalysis";
import PurchaseOrder from "./views/tender-analisys/purchase-order/AnalysisPurchaseOrder";
import UserManager from "./views/user-manager/UserManager";
import CompanyManager from "./views/company-manager/CompanyManager";
import ManagePac from "./views/manage-pac/ManagePac";
import ABMCompetitorsOC from "./views/abm-competitors-entities/ABMCompetitorsOC";
import ABMEntitiesOC from "./views/abm-competitors-entities/ABMOrganismsPAC";
import NotificationsAdmin from "./views/notification-admin/NotificationsAdmin";
import AlertsAdmin from "./views/alerts-admin/AlertsAdmin";
import RecipientsAdmin from "./views/recipients-admin/RecipientsAdmin";
import ContentTenderInformation from "./views/tender-information/ContentTenderInformation";
import ListAvailableTenders from "./views/licitaciones/ListAvailableTenders";
import Filters from "./views/filter/Filters";
import FilterDetails from "./views/filter/FilterDetails";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  ReactPlugin,
  withAITracking,
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import ErrorHandlingPage from "views/ErrorHandlingPage";
import OutsiderTenders from "views/outsider-tenders/OutsiderTenders";

store.subscribe(
  throttle(500, () => {
    persistState(store.getState());
  }),
);

L10n.load({ "es-ES": es });

export const quereyClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      refetchOnWindowFocus: false,
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    if (!isCurrentUrlProtocolValid()) {
      this.state = { validUrlProtocol: false };
      window.location.href = window.location.href.replace(
        "http://",
        "https://",
      );
    } else {
      this.state = { validUrlProtocol: true };
    }
  }

  async componentDidMount(): void {
    const { validUrlProtocol } = this.state;
    if (validUrlProtocol) {
      const { error, token } = await acquireToken();
      if (!!error || !token) {
        await login();
      } else if (!error) {
        this.setState({ acquireTokenError: error });
      }
    }
  }

  render() {
    const { validUrlProtocol, acquireTokenError } = this.state;

    const browserHistory = createBrowserHistory({ basename: "" });
    var reactPlugin = new ReactPlugin();
    var appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INST_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    appInsights.loadAppInsights();

    return (
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary
          onError={() => (
            <div style={{ textAlign: "center", marginTop: "10%" }}>
              <h2>
                Ha ocurrido un error. Por favor refresque el sitio. Si el
                problema persiste, contacte a soporte.
              </h2>
            </div>
          )}
          appInsights={reactPlugin}
        >
          <LangProvider>
            <QueryClientProvider client={quereyClient}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Provider store={store}>
                  <BrowserRouter>
                    <Switch>
                      <Route path={"/aad-callback"} component={AadCallback} />
                      {validUrlProtocol && (
                        <Loading msg={acquireTokenError}>
                          <AuthConfig>
                            <CssBaseline />
                            <TenderAppFrame>
                              <AuthRoute>
                                <Route
                                  path={"/gestion"}
                                  component={ManageTenders}
                                  idModule={"GL"}
                                />
                                <Route
                                  path={"/smart-search"}
                                  component={SmartSearch}
                                  idModule={"SS"}
                                />
                                <Route
                                  path={"/analisis"}
                                  component={TenderAnalysis}
                                  idModule={"AL"}
                                />
                                <Route
                                  path={"/purchase-order"}
                                  component={PurchaseOrder}
                                  idModule={"OC"}
                                />
                                <Route
                                  path={"/manage-pac"}
                                  component={ManagePac}
                                  idModule={"PAC"}
                                />
                                <Route
                                  path={"/licitaciones"}
                                  component={ListAvailableTenders}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/destinatarios"}
                                  component={RecipientsAdmin}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/notificaciones"}
                                  component={NotificationsAdmin}
                                  isAdmin={true}
                                />
                                <Route
                                  exact
                                  path={"/filtros"}
                                  component={Filters}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/filtros/:id"}
                                  component={FilterDetails}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/informacion/:tenderNumber"}
                                  component={ContentTenderInformation}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/gestion-informacion/:tenderNumber"}
                                  component={ContentTenderInformation}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/usuarios"}
                                  component={UserManager}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/empresas"}
                                  component={CompanyManager}
                                  isAdminGeneral={true}
                                />
                                <Route
                                  path={"/competidores-oc"}
                                  component={ABMCompetitorsOC}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/entidades-pac"}
                                  component={ABMEntitiesOC}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/alertas"}
                                  component={AlertsAdmin}
                                  isAdmin={true}
                                />
                                <Route
                                  path={"/licitaciones-externas"}
                                  component={OutsiderTenders}
                                  isAdmin={true}
                                />
                              </AuthRoute>
                            </TenderAppFrame>
                          </AuthConfig>
                        </Loading>
                      )}
                    </Switch>
                  </BrowserRouter>
                </Provider>
              </MuiPickersUtilsProvider>
            </QueryClientProvider>
          </LangProvider>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    );
  }
}

export default App;
