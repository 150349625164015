import React, { useEffect, useState } from "react";
import FstModal from "../../components/modal/FstModal";
import LegalDocumentForm from "./LegalDocumentForm";
import { connect } from "react-redux";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work
import { PenaltyMode } from "../../types";
import { downloadBase64File } from "../../utils/FileHandleService";
import { useIntl } from "react-intl";
import useSelectObject from "../../intl/utils/hooks/useTranslatorCommon";
import objectSubject from "./LegalDocumentsObject";
import * as SpinnerActions from "../../actions/spinnerActions";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { TenderParameter } from "views/tender-information/services/tenderParameter";
import { Grid } from "@material-ui/core";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ManageLegalDocuments = (props) => {
  moment.locale("es"); //Dani fijate si esto es correcto
  const [selectedDate] = useState(moment());
  const [isViewMode, setIsViewMode] = useState(false);
  const [settingModal, setSettingModal] = useState(null);
  const [isDisabledPrimaryModal, setIsDisabledPrimaryModal] = useState(true);
  const intl = useIntl();
  const selectObject = useSelectObject();
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  let timerMessageError = null;
  const [messageErrorFile, setMessageErrorFile] = useState(null);

  const legalDocumentDefault = {
    LegalDocumentId: null,
    Status: null,
    StatusId: null,
    StatusDescription: "",
    BankId: null,
    BankName: "",
    Bank: null,
    Reference: "",
    Subject: "", //default
    Value: "",
    StartDate: selectedDate.format("L"),
    EndDate: selectedDate.format("L"),
    DocumentTypeId: 1, //WT - WarrantyTicket
    Number: "",
    Comments: "",
    Active: true,
    DocumentComments: [],
    DocumentFiles: [],
    CurrencyId: null,
    Currency: props.currencyDefault,
    Document: { fileName: "" },
    ObjectSubject: selectObject.ObjectFilter("ValueCmb_Carta", objectSubject),
  };

  const EditLegalDocument = (item) => {
    if (!item) return;
    const parseTicket = {
      LegalDocumentId: item.legalDocumentId,
      StatusId: item.statusId,
      Status: {
        Id: item.statusId,
        Description: item.statusDescription,
      },
      BankId: item.bankId,
      Bank: {
        bankId: item.bankId,
        bankName: item.bankName,
      },
      Reference: item.reference,
      Subject: item.subject
        ? selectObject
            .ObjectFilter("ValueCmb_Carta", objectSubject)
            .find((x) => x.description === item.subject)
        : item.subject, // item comes as string (the description), so we need to find the object in the subject array and get the description
      Value: item.value,
      StartDate: moment(item.startDate).format("L"),
      EndDate: moment(item.endDate).format("L"),
      DocumentTypeId: item.documentTypeId,
      Number: item.number,
      Comments: "",
      Active: item.active,
      DocumentComments: item.documentComments,
      DocumentFiles: item.documentFiles,
      Document:
        item.documentFiles && item.documentFiles.length
          ? item.documentFiles[0]
          : {},
      CurrencyId: item.currencyId,
      Currency: {
        ...item.currency,
      },
      ObjectSubject: selectObject.ObjectFilter("ValueCmb_Carta", objectSubject),
    };
    setLegalDocument(parseTicket);
  };

  const [legalDocument, setLegalDocument] = useState(null);

  const handleClose = () => {
    setLegalDocument({ ...legalDocumentDefault });
    props.handleOpenModal(false);
  };

  const handleSave = async () => {
    const {
      Status,
      Bank,
      Reference,
      Value,
      StartDate,
      EndDate,
      Comments,
      Active,
      LegalDocumentId,
      Number,
      Subject,
      DocumentComments,
      Document,
      Currency,
    } = legalDocument;

    const request = {
      Number,
      Subject: Subject.description,
      StatusId: Status ? Status.Id : null,
      StatusDescription: Status ? Status.Description : "",
      BankId: Bank.bankId,
      BankName: Bank.bankName,
      Reference,
      Value: +Value,
      DocumentTypeId: 1,
      Active,
      StartDate: moment(StartDate, "L")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"), //moment(StartDate, 'L').format('YYYY-MM-DDThh:mm:ss'),
      EndDate: moment(EndDate, "L")
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"), //moment(EndDate, 'L').format('YYYY-MM-DDThh:mm:ss'),
      DocumentComments,
      DocumentFiles: Document.Content ? [Document] : [],
      CurrencyId: Currency.currencyId,
      Currency: {
        currencyId: Currency.currencyId,
        name: Currency.name,
        description: Currency.description,
        code: Currency.code,
        symbol: Currency.symbol,
      },
    };

    if (Comments && Comments.length) {
      const _comment = {
        Comment: Comments,
        lastUpdate: moment().format("YYYY-MM-DDTHH:mm:ss"),
        updatedByUserName: props.fullName,
      };
      request.DocumentComments.push(_comment);
    }

    if (LegalDocumentId) {
      request.LegalDocumentId = LegalDocumentId;
    }
    props.Save(request);
  };

  const onChange = (key, value) => {
    const aux = { ...legalDocument };
    aux[key] = value;
    setLegalDocument(aux);
    const isDisabled = aux.Value && aux.Bank && aux.EndDate;
    setIsDisabledPrimaryModal(!isDisabled);
  };

  const mode = () => {
    if (!props.legalDocument) {
      setLegalDocument({ ...legalDocumentDefault });
    } else {
      const btn =
        props.setting.Mode === PenaltyMode.VIEW
          ? { BtnNameSave: "Cerrar" }
          : null;
      setSettingModal(btn);
      setIsViewMode(props.setting.Mode === PenaltyMode.VIEW);
      EditLegalDocument(props.legalDocument);
    }
  };

  useEffect(() => {
    mode();
  }, []);

  const validateFileSize = (file) => {
    if (file.size > TenderParameter.MaxFileSize10MB) {
      const message = `El file ${file.name} supera el tamaño maximo permitido de 10 MB!`;
      setMessageErrorFile(message);
      timerMessageError = setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    }
    setMessageErrorFile(null);

    return true;
  };

  const handleFileUpload = (event) => {
    if (!validateFileSize(event.target.files[0])) return;
    props.SpinnerShow("Cargando Archivo...");
    const _file = {
      FileName: event.target.files[0].name,
      fileName: event.target.files[0].name,
    };
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      _file.Content = e.target.result.split(",")[1];
      onChange("Document", _file);
    };
    setTimeout(() => {
      props.SpinnerHide("");
      showToaster(`El Archivo se adjunto exitosamente`);
    }, 500);
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 4000);
  };

  const handleFileDownload = (event) => {
    const _file = {
      fileName: legalDocument.DocumentFiles[0].fileName,
      base64Data: legalDocument.DocumentFiles[0].content,
    };
    downloadBase64File(_file.fileName, _file.base64Data);
  };

  return (
    <>
      <FstModal
        open={props.openModal}
        title={intl.formatMessage({
          id: "etq_CartaFianza",
          defaultMessage: "Carta Fianza",
        })}
        handleClose={handleClose}
        handleSave={isViewMode ? handleClose : handleSave}
        onlyOK={false}
        widthSize="md"
        setting={settingModal}
        IsDisabledPrimary={isDisabledPrimaryModal && !isViewMode}
      >
        {legalDocument && (
          <LegalDocumentForm
            Ticket={legalDocument}
            setting={props.setting}
            isViewMode={isViewMode}
            onChange={onChange}
            handleFileUpload={handleFileUpload}
            handleFileDownload={handleFileDownload}
            tender={props.value}
          />
        )}
        {messageErrorFile && (
          <Grid item xs={12} sm={12} md={12}>
            <Alert severity="error">{messageErrorFile}</Alert>
          </Grid>
        )}
      </FstModal>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const { fullName } = user;
  const { currencyDefault } = state.app;

  return {
    ...ownProps,
    fullName,
    currencyDefault,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  ManageLegalDocuments,
);
