import { useState, useLayoutEffect } from "react";

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  function updateSize() {
    const padding = 10;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header - padding);
    setSize([window.innerWidth, rest]);
  }
  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export default useWindowSize;
