export const competitorsHeader = [
  {
    id: "abmC.RUC",
    field: "uniqueId",
    headerText: "RUC",
    textAlign: "left",
    width: "20%",
    allowSorting: true,
    showInColumnChooser: false,
  },
  {
    id: "abmC.Nombre",
    field: "name",
    headerText: "Nombre",
    textAlign: "left",
    width: "80%",
    allowSorting: true,
  },
  {
    id: "abmC.Acciones",
    headerText: "Acciones",
    width: "120px",
    commands: [
      {
        type: "Remove",
        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
      },
    ],
  },
];
