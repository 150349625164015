export const HeaderTable = [
  {
    field: "bankName",
    headerText: "Nombre",
    width: 10,
    textAlign: "Left",
    visible: true,
    isPrimaryKey: false,
  },
];
