import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import FstModalConfirm from "../../components/modal/FstModalConfirm";

/* Props: 
    - openToaster: bool
    - openToasterError: bool
    - modalTitle: str
    - modalClose: func
    - deleteConfirm: bool
    - modalMessage: str
    - modalSuccess: func
*/
export default function AdminSnackbars(props) {
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={props.openToaster}
      >
        <Alert severity="success">Los cambios han sido confirmados</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={props.openToasterError}
      >
        <Alert severity="error">
          {props.errorTitle
            ? props.errorTitle
            : "Datos incorrectos o faltantes"}
        </Alert>
      </Snackbar>
      <FstModalConfirm
        title={props.modalTitle}
        close={props.modalClose}
        open={props.deleteConfirm}
        message={props.modalMessage}
        success={props.modalSuccess}
      />
    </>
  );
}
