import React from "react";
import { dateTimeShortFormat } from "../../utils/common";
import moment from "moment";
import FstYesNO from "../../components/utils/FstYesNo";

export const PenaltiesHeaderTable = [
  {
    field: "tenderId",
    headerText: "Licitación",
    width: "70",
    textAlign: "Center",
    template: (props) => <FstYesNO value={props.tenderId === 1} />,
    showInColumnChooser: false,
  },
  {
    field: "customerCode",
    headerText: "Cod. Cliente",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "customerName",
    headerText: "Cliente",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "saleOrder",
    headerText: "SO",
    width: "150",
    textAlign: "center",
  },
  {
    field: "purchaseOrderCode",
    headerText: "Orden Compra",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "invoiceNumber",
    headerText: "Número Fact.",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "invoiceDate",
    headerText: "Fecha Fact.",
    width: "120",
    textAlign: "Center",
    template: (props) => (
      <span>
        {props.invoiceDate
          ? moment(props.invoiceDate).format(dateTimeShortFormat)
          : null}
      </span>
    ),
  },
  {
    field: "invoiceAmount",
    headerText: "Valor Fact.",
    width: "120",
    textAlign: "Right",
    template: (props) => (
      <span>
        {props.invoiceAmount
          ? props.invoiceAmount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          : null}
      </span>
    ),
  },
  {
    field: "penaltyAmount",
    headerText: "Multa",
    width: "120",
    textAlign: "Right",
    template: (props) => (
      <span>
        {props.penaltyAmount
          ? props.penaltyAmount.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })
          : null}
      </span>
    ),
  },
  {
    field: "penaltyReasonDesc",
    headerText: "Motivo",
    width: "200",
    textAlign: "Center",
  },
  {
    field: "responsible",
    headerText: "Responsable",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "caseNumber",
    headerText: "Case # SF",
    width: "150",
    textAlign: "Center",
  },
  {
    field: "description",
    headerText: "Observaciones",
    width: "300",
    textAlign: "Left",
  },
];

export const ColumnsDates = [
  {
    field: "invoiceDate",
    format: dateTimeShortFormat,
  },
];

export const ColumnsBooleans = [
  {
    field: "tenderId",
    POSITIVE: "Si",
    NEGATIVE: "No",
  },
];
