import React from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";

export const FstButtonGroup = (props) => {
  const {
    primary,
    options,
    handleClick,
    label,
    clasNamePrymary,
    btnGrIcon,
    clasNameGroup,
  } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [idSelect, SetIdSelect] = React.useState(0);

  const handleClickOptions = (value) => {
    handleClick(value);
  };

  const handleMenuItemClick = (item) => {
    handleClick(item);
    SetIdSelect(item.id);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        aria-label="split button"
        className={clasNameGroup}
      >
        <Button
          className={clasNamePrymary}
          onClick={() => handleClickOptions(primary)}
        >
          {options[idSelect].description}
        </Button>
        <Button
          className={btnGrIcon}
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label={label}
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        className={"popperButtom"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option) => (
                    <MenuItem
                      key={option.id}
                      onClick={() => handleMenuItemClick(option)}
                    >
                      {option.description}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
