import { ActivityModal } from "../types";

const INITIAL_STATE = {
  modalActive: false,
  idModal: "",
  setting: null,
  getIsActive: (id) => false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActivityModal.MODAL_ACTIVE:
      return {
        ...state,
        modalActive: true,
        idModal: action.idModal,
        setting: action.setting,
        getIsActive: (id) => action.idModal === id && true,
      };
    case ActivityModal.MODAL_DEACTIVE:
      return {
        ...state,
        modalActive: false,
        idModal: "",
        setting: null,
        getIsActive: (id) => false,
      };
    default:
      return state;
  }
};
