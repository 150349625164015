import { PenaltyType } from "../types";

const INITIAL_STATE = {
  result: null,
  reloadDefault: false,
  error: null,
  count: 0,
  page: 0,
  pageSize: 0,
  penalty: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PenaltyType.ALL_PENALTY:
      return {
        ...state,
        result: action.payload.result,
        error: null,
        count: action.payload.count,
        page: action.page,
        pageSize: action.pageSize,
        reloadDefault: action.reloadDefault,
      };
    case PenaltyType.ALL_PENALTY_ERROR:
      return {
        ...state,
        result: null,
        error: action.error,
      };
    case PenaltyType.ALL_PENALTY_DEFAULT:
      return {
        ...state,
        reloadDefault: action.reloadDefault,
      };
    case PenaltyType.GET_PENALTY:
      return {
        ...state,
        penalty: action.payload,
        error: null,
      };
    default:
      return state;
  }
};
