import React from "react";
import FlagIcon from "@material-ui/icons/Flag";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { getValue } from "@syncfusion/ej2-base";
let data;
const fields = { text: "colorCode", value: "colorCode" };

export const HeaderTable = [
  {
    field: "description",
    headerText: "Descripción",
    width: 100,
    textAlign: "Left",
    visible: true,
    isPrimaryKey: false,
  },
  {
    field: "colorCode",
    headerText: "Imágen",
    width: 100,
    textAlign: "Left",
    visible: true,
    template: (props) => {
      return <FlagIcon style={{ color: props.colorCode }} />;
    },
    editTemplate: (props) => (
      <ColorPickerComponent
        locale="es-ES"
        dataSource={data}
        value={getValue("colorCode", props)}
        fields={fields}
        id="colorCode"
      />
    ),
  },
];
