import { fulfilled, pending, rejected } from "../utils/asyncStatusGenerator";
import { appActionTypes } from "../actions/appActions";

const initialState = {
  appVersion: "",
  tendersStatus: null,
  finishedLoadingInitialData: false,
  errorLoadingInitialState: null,
  loginMessageError: "",
  legalDocumentWTStatus: [],
};

export const appReducer = (state = initialState, action) => {
  const { payload } = action;
  const data = !!payload && payload.data;
  switch (action.type) {
    case pending(appActionTypes.INITIAL_DATA):
      return {
        ...state,
        legalDocumentWTStatus: state.legalDocumentWTStatus,
        finishedLoadingInitialData: false,
        errorLoadingInitialState: null,
      };
    case fulfilled(appActionTypes.INITIAL_DATA):
      return {
        ...state,
        ...data,
        finishedLoadingInitialData: true,
        errorLoadingInitialState: null,
      };
    case fulfilled(appActionTypes.SET_LOADING_MSG):
      return {
        ...state,
        legalDocumentWTStatus: state.legalDocumentWTStatus,
        loginMessageError: payload.msg,
      };
    case rejected(appActionTypes.INITIAL_DATA):
      return {
        ...state,
        finishedLoadingInitialData: true,
        errorLoadingInitialState: payload,
      };

    default:
      return state;
  }
};
