import { Logger, LogLevel, UserAgentApplication } from "msal";
import { IS_DEVELOPMENT } from "../utils/common";
import { setUpdateUserSetting } from "../views/smart-search/services/smartSearchService";

const debugSystemConfig = {
  logger: new Logger(
    (logLevel, message) => {
      //console.log(message);
    },
    {
      level: LogLevel.Verbose,
      piiLoggingEnabled: false,
      correlationId: "1234",
    },
  ),
};
const systemConfig = IS_DEVELOPMENT ? debugSystemConfig : {};
const msalInstance = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    redirectUri: `${window.location.origin}/aad-callback`,
    postLogoutRedirectUri: `${window.location.origin}/`,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: systemConfig,
  navigateToLoginRequestUrl: false,
});

msalInstance.handleRedirectCallback((error, response) => {
  console.log("error", error);
  console.log("response", response);
});

const loginRequest = { scopes: ["user.read"], prompt: "select_account" };

export const login = async (callback) => {
  try {
    await msalInstance.loginRedirect(loginRequest);
    !!callback && callback(null, true);
  } catch (error) {
    console.log("Catching error", { error }, { callback });
    !!callback && callback(error, false);
  }
};

export const selectAccount = async () => {
  await msalInstance.loginRedirect({
    scopes: ["user.read"],
    prompt: "select_account",
  });
};

export const isMsalUserLoggedIn = () => {
  //console.log("isMsalUserLoggedIn()", "ALL ACCOUNTS", msalInstance.getAllAccounts());
  const account = msalInstance.getAccount();
  return !!account;
};

export const acquireToken = async () => {
  try {
    const response = await msalInstance.acquireTokenSilent({
      scopes: [process.env.REACT_APP_MSAL_CLIENT_ID],
    });
    if (!!response) {
      //console.log("acquireToken.response", response);
      return { token: response, error: null };
    }
  } catch (error) {
    console.log("acquireToken.error", error);
    return { token: null, error };
  }
};

export const logout = (callback) => {
  //const result = await updateSettingUser()
  sessionStorage.clear();
  localStorage.clear();
  msalInstance.logout();
  !!callback && callback();
};

export const updateSettingUser = async () => {
  let request = {};
  Object.keys(localStorage).filter((key) => {
    if (key.indexOf("grid") === 0) {
      request[key] = [JSON.parse(localStorage.getItem(key))];
    }
    return key;
  });
  let manageDefaultTab = localStorage.getItem("manageDefaultTab");
  if (!!manageDefaultTab) {
    request["manageDefaultTab"] = [JSON.parse(manageDefaultTab)];
  }
  let selectedFilters = localStorage.getItem("selectedFilters");
  if (!!selectedFilters) {
    request["selectedFilters"] = [JSON.parse(selectedFilters)];
  }

  const saveUserSetting = await setUpdateUserSetting(JSON.stringify(request));
  return saveUserSetting;
};
