import React, { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { UseStyles } from "./style";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import ManageSmartSearchModal from "../manage-smart-search/ManageSmartSearchModal";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  getSearchManageFilter,
  getFiltersCount,
  getFilteredAttachmentCount,
} from "../services/smartSearchService";
import FstCheckbox from "../../../components/forms/checkbox/FstCheckbox";
import ListIcon from "@material-ui/icons/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Tooltip } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import * as SpinnerActions from "../../../actions/spinnerActions";
import { connect } from "react-redux";
import { Save } from "@material-ui/icons";
import { updateSettingUser } from "utils/activeDirectory";

const SmartSearchFilter = (props) => {
  const headerTable = 45;
  const classes = UseStyles();
  const [openManage, setOpenManage] = useState(false);
  const [smartSearch, setSmartSearch] = useState(false);
  const [selectAll, setsSelectAll] = useState(true);
  const [actionEdit, setActionEdit] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState(
    JSON.parse(localStorage.getItem("selectedFilters")),
  );

  const getFilters = async (ss) => {
    const request = {
      orderBy: null,
      pageSize: 999,
      search: "",
      PageNumber: 1,
      OrderAsc: false,
    };
    const response = await getSearchManageFilter(request);
    const ids = response.data.currentPageItems.map(
      (item) => item.searchFilterId,
    );
    let counts;
    if (props.attachmentResultView) {
      counts = await getFilteredAttachmentCount({ FilterIds: ids });
    } else {
      counts = await getFiltersCount({ FilterIds: ids });
    }
    const auxList = response.data.currentPageItems.map((item) => {
      return {
        ...item,
        // item.searchFilterId in selectedFilters (from clientsettings)
        checked: selectedFilters
          ? selectedFilters.includes(item.searchFilterId)
          : true,
        count: counts.data.find((obj) => obj.filterId === item.searchFilterId)
          .count,
        fieldName: `checkbox-list-label-${item.searchFilterId}`,
      };
    });
    props.handleList(auxList, ss);
    props.SpinnerHide();
  };

  const handleChangeCheckox = (value, item) => {
    const aux = [...props.list];
    const index = aux.findIndex(
      (x) => x.searchFilterId === item.searchFilterId,
    );
    aux[index].checked = value;
    props.handleList(aux);
  };

  useEffect(() => {
    getFilters(null);
  }, [props.updateList]);

  useEffect(() => {
    getFilters(null);
  }, [props.attachmentResultView]);

  const handleToggle = (value) => () => {
    const index = props.list.findIndex(
      (item) => item.searchFilterId === value.searchFilterId,
    );
    const aux = [...props.list];
    aux[index].checked = !value.checked;
    props.handleList(aux);
  };

  const toFilter = (item) => {
    setActionEdit(item ? true : false);
    setSmartSearch(item);
    setOpenManage(true);
  };

  const handleSelectAll = (name, value) => {
    setsSelectAll(value);
    const list = [...props.list].map((item) => {
      return {
        ...item,
        checked: value,
      };
    });

    props.handleListAll(list);
  };

  const handleSave = (ss) => {
    setActionEdit(false);
    setOpenManage(false);
    getFilters(ss);
    props.handleUpdateList();
  };

  const handleSaveSelectedFilters = async () => {
    props.SpinnerShow("Guardando...");
    setSelectedFilters(
      props.list
        .filter((item) => item.checked)
        .map((item) => item.searchFilterId),
    );
    localStorage.setItem(
      "selectedFilters",
      JSON.stringify(
        props.list
          .filter((item) => item.checked)
          .map((item) => item.searchFilterId),
      ),
    );
    await updateSettingUser();
    props.SpinnerHide();
  };

  return (
    <>
      <List
        className={classes.root}
        style={{
          height: props.height + headerTable,
          maxHeight: props.height + headerTable,
          overflow: "auto",
        }}
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            className={classes.header}
          >
            <ListItem role={undefined} dense button>
              <Tooltip title="Todos" placement="left">
                <ListItemIcon className={classes.rootCheckBox}>
                  <FstCheckbox
                    name="selectAll"
                    value={selectAll}
                    handleChange={(name, value) => handleSelectAll(name, value)}
                  />
                </ListItemIcon>
              </Tooltip>
              <Typography
                variant="h6"
                className={classes.headerName}
                style={{ marginLeft: "-10px" }}
              >
                Filtros
              </Typography>
              <ListItemSecondaryAction>
                <Tooltip title="Ocultar" placement="left">
                  <IconButton
                    edge="end"
                    className="icon-size-filter"
                    size="small"
                    onClick={props.handlePanelHide}
                    aria-label="comments"
                  >
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title="Guardar filtros seleccionados"
                  placement="bottom"
                >
                  <IconButton
                    edge="end"
                    color="primary"
                    className="icon-size-filter"
                    size="small"
                    onClick={handleSaveSelectedFilters}
                    aria-label="comments"
                    //disabled if props.list has the same ids as initialSelectedFilters regardless of order
                    disabled={
                      selectedFilters !== null &&
                      props.list
                        .filter((item) => item.checked)
                        .map((item) => item.searchFilterId)
                        .sort()
                        .join(",") === selectedFilters.sort().join(",")
                    }
                  >
                    <Save />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Agregar" placement="bottom">
                  <IconButton
                    edge="end"
                    color="primary"
                    className="icon-size-filter"
                    size="small"
                    onClick={() => toFilter(null)}
                    aria-label="comments"
                  >
                    <AddCircleIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Administrar" placement="right">
                  <IconButton
                    edge="end"
                    className="icon-size-filter"
                    size="small"
                    onClick={props.handleView}
                    aria-label="comments"
                  >
                    <ListIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          </ListSubheader>
        }
      >
        {props.list.map((item) => {
          return (
            <ListItem
              key={item.searchFilterId}
              role={undefined}
              dense
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon className={classes.rootCheckBox}>
                <FstCheckbox
                  name={item.fieldName}
                  value={item.checked}
                  handleChange={(name, value) =>
                    handleChangeCheckox(value, item)
                  }
                />
              </ListItemIcon>
              <ListItemText
                id={item.searchFilterId}
                primary={
                  <>
                    <span>{item.name}</span> <span>({item.count})</span>
                  </>
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="Editar" placement="right">
                  <IconButton
                    edge="end"
                    className="icon-size"
                    size="small"
                    aria-label="comments"
                    onClick={() => toFilter(item)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <ManageSmartSearchModal
        open={openManage}
        smartSearch={smartSearch}
        handleClose={() => setOpenManage(false)}
        handleSave={(ss) => handleSave(ss)}
        edit={actionEdit}
      ></ManageSmartSearchModal>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  SmartSearchFilter,
);
