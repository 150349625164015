import React from "react";
import formatJSON from "../json/form-AR.json";
import {
  formatDate,
  compareString,
  formatShortDate,
  getLang,
} from "../../../../../utils/common";
import structureDataJS from "./structure-data/dataStructure-AR.js";
import TenderGridMessage from "../../../tender-comments/TenderGridMessage";
import { Divider, Typography } from "@material-ui/core";

export default {
  ...formatJSON,
  ...structureDataJS,
  TabGeneralInfoFirst: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "tenderDescription", label: "Objeto de Contratación" },
            { key: "callNumber", label: "Nº Convocatoria" },
            { key: "estimatedValue", label: "Valor de la Unidad de Compra" },
            {
              key: "publishedDate",
              label: "Fecha Publicación",
              render: formatDate,
            },
          ],
        },
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "name", label: "Etapa" },
            {
              key: "startDate",
              label: "Fecha y Hora Inicio",
              render: (value, column) => {
                if (
                  column.startDate === column.endDate &&
                  !compareString(column.name, ["PUBLICACION", "APERTURA"])
                )
                  return "";
                return formatDate(value);
              },
            },
            {
              key: "endDate",
              label: "Fecha y Hora Fin",
              render: (value, column) =>
                compareString(column.name, ["PUBLICACION", "APERTURA"])
                  ? ""
                  : formatDate(value),
            },
          ],
        },
      },
    ],
  },

  TabDocumentsFolds: {
    elements: [
      {
        type: "Typography",
        props: {
          component: () => (
            <Typography
              variant="h5"
              style={{
                marginLeft: "0.25em",
                paddingTop: "1%",
                paddingBottom: "1%",
                fontSize: "0.96rem",
                fontWeight: "bold",
              }}
            >
              Pliego de bases y condiciones generales
            </Typography>
          ),
        },
      },
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            {
              key: "document",
              label: "Documento",
              primary: true,
              render: (value, column, row, data) =>
                column.link.includes("comprar") ? column.name : column.document,
            },
            { key: "date", label: "Fecha creación", render: formatShortDate },
            {
              key: "link",
              label: "Acciones",
              render: (value, column, row, data) => (
                <a href={value}>
                  {column.link.includes("comprar")
                    ? column.name
                    : column.document}
                </a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabDocumentsParticularClauses: {
    elements: [
      {
        type: "Typography",
        props: {
          component: () => (
            <Typography
              variant="h5"
              style={{
                marginLeft: "0.25em",
                paddingTop: "3%",
                paddingBottom: "1%",
                fontSize: "0.96rem",
                fontWeight: "bold",
              }}
            >
              Cláusulas Particulares
            </Typography>
          ),
        },
      },
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "document", label: "Documento", primary: true },
            {
              key: "documentNumber",
              label: "Número GDE/GDEBA/SADE",
              primary: true,
            },
            { key: "specialNumber", label: "Número Especial" },
            {
              key: "date",
              label: "Fecha de vinculación",
              render: formatShortDate,
            },
            {
              key: "link",
              label: "Acciones",
              render: (value, column, row, data) => (
                <a href={value}>{column.documentNumber}</a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabDocumentsAnnexes: {
    elements: [
      {
        type: "Typography",
        props: {
          component: () => (
            <Typography
              variant="h5"
              style={{
                marginLeft: "0.25em",
                paddingTop: "3%",
                paddingBottom: "1%",
                fontSize: "0.96rem",
                fontWeight: "bold",
              }}
            >
              Anexos
            </Typography>
          ),
        },
      },
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", label: "Nro.", primary: true },
            { key: "name", label: "Nombre" },
            { key: "type", label: "Tipo", primary: true },
            { key: "description", label: "Descripción" },
            {
              key: "link",
              label: "Acciones",
              render: (value, column, row, data) => (
                <a href={value}>{column.name}</a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabDocumentsTechnicalFolds: {
    elements: [
      {
        type: "Typography",
        props: {
          component: () => (
            <Typography
              variant="h5"
              style={{
                marginLeft: "0.25em",
                paddingTop: "3%",
                paddingBottom: "1%",
                fontSize: "0.96rem",
                fontWeight: "bold",
              }}
            >
              Posee pliego técnico
            </Typography>
          ),
        },
      },
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "document", label: "Documento", primary: true },
            {
              key: "documentNumber",
              label: "Número GDE/GDEBA/SADE",
              primary: true,
            },
            { key: "specialNumber", label: "Número Especial" },
            {
              key: "date",
              label: "Fecha de vinculación",
              render: formatShortDate,
            },
            {
              key: "link",
              label: "Acciones",
              render: (value, column, row, data) => (
                <a href={value}>{column.documentNumber}</a>
              ),
            },
          ],
        },
      },
    ],
  },

  ListCompDocuments: {
    component: [
      {
        type: "Grid",
        id_ff: "TabDocumentsFolds",
        template: (props) => {
          return props.data?.tenderFolds?.map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          });
        },
      },
      {
        type: "Grid",
        id_ff: "TabDocumentsParticularClauses",
        template: (props) => {
          return props.data?.tenderClauses?.map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          });
        },
      },
      {
        type: "Grid",
        id_ff: "TabDocumentsAnnexes",
        template: (props) => {
          return props.data?.tenderDocuments?.map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          });
        },
      },
      {
        type: "Grid",
        id_ff: "TabDocumentsTechnicalFolds",
        template: (props) => {
          return props.data?.tenderTechnicalFolds?.map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          });
        },
      },
    ],
  },

  ValueCmb_Carta: {
    objectName: "comboGarantiasAR",
  },
  TabRequirement: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", label: "NRO." },
            {
              key: "description",
              label: "Descripción",
              render: (value) => (
                <TenderGridMessage title={"Requisito"} menssage={value} />
              ),
            },
            { key: "documentType", label: "Tipo de documento" },
          ],
        },
      },
    ],
  },
  TabWarranty: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            {
              key: "type",
              label: "Tipo de Garantía",
              render: (value) => <b>{value}</b>,
            },
            { key: "indicator", label: "Indicador" },
            { key: "description", label: "Descripción" },
          ],
        },
      },
    ],
  },
  TabItemListLast: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "competitorName", label: "Postor" },
            {
              key: "qty",
              label: "Cantidad",
              render: (value) => (
                // TODO: use formatcurrecntlocale function
                <span>{value.toLocaleString(getLang(), {})}</span>
              ),
            },
            {
              key: "ammount",
              label: "Monto unitario adjudicado/ofertado",
              render: (value) => (
                <span>
                  {value.toLocaleString(getLang(), {
                    minimumFractionDigits: 2,
                  })}
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
  TabItemModalDeliveryDetail: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "Quantity", label: "Cantidad a entregar" },
            { key: "DeliveryTerm", label: "Plazo de entrega" },
            { key: "DeliveryAddress", label: "Lugar de entrega" },
            { key: "Comments", label: "Observaciones" },
            { key: "DeliveryType", label: "Tipo de entrega" },
          ],
        },
      },
    ],
  },
  ListCompItemList: {
    data: {
      section: "0",
    },
    component: [
      {
        type: "EtqH5",
        styleParent: { marginLeft: "0.25em" },
        template: (props) => props.idx + 1 + " - " + props.data.description,
      },
      {
        type: "Grid",
        id_ff: "TabItemListFirst",
        template: (props) =>
          [props.data].map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          }),
      },
      {
        type: "Span",
        styleParent: { marginLeft: "1em", marginTop: "2em", fontWeight: 400 },
        template: (props) => (
          <div style={{ display: "flex", flexDirection: "row", fontSize: 14 }}>
            <span style={{ minWidth: "15em", fontWeight: 500 }}>
              OBSERVACIONES
            </span>
            <span>{props.data.observation}</span>
          </div>
        ),
      },
      {
        type: "Span",
        styleParent: { marginLeft: "1em", marginTop: "1em", fontWeight: 400 },
        template: (props) => (
          <>
            <Divider
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                marginLeft: "-1em",
              }}
            />
            <div
              style={{ display: "flex", flexDirection: "row", fontSize: 14 }}
            >
              <span style={{ minWidth: "15em", fontWeight: 500 }}>
                ESPECIFICACIONES
              </span>
              <span>{props.data.specification}</span>
            </div>
          </>
        ),
      },
      {
        type: "Span",
        styleParent: { marginLeft: "1em", marginTop: "1em" },
        template: (props) => (
          <>
            <Divider
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                marginLeft: "-1em",
              }}
            />
            <div
              style={{ display: "flex", flexDirection: "row", fontSize: 14 }}
            >
              <span style={{ minWidth: "15em", fontWeight: 500 }}>
                ACONDICIONAMIENTO
              </span>
              <span>{props.data.conditioning}</span>
            </div>
            <Divider
              style={{
                marginTop: "1em",
                marginBottom: "1em",
                marginLeft: "-1em",
              }}
            />
          </>
        ),
      },
      {
        type: "EtqH5",
        styleParent: { textAlign: "center" },
        label: "ADJUDICACIONES",
      },
      {
        type: "Grid",
        id_ff: "TabItemListLast",
        template: (props) =>
          props.data.tenderDetailAwardeds.map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          }),
      },
    ],
  },
};
