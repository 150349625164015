import React, { useState, useEffect } from "react";
import { useStyles } from "./style";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {
  saveCompany,
  getCompanyList,
  setEnableDisableCompany,
  syncCompanyUsers,
} from "./service/CompanyService";
import { connect } from "react-redux";
import * as SpinnerActions from "../../actions/spinnerActions";
import * as ModalAction from "../../actions/modalAction";
import CompanyManagerGrid from "./CompanyManagerGrid";
import CompanyManagerModal from "./CompanyManagerModal";
import moment from "moment";
import CompanyManagerUserSyncModal from "./CompanyManagerUserSyncModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const defaultData = {
  ruc: "",
  tenantId: "",
  clientId: "",
  clientSecret: "",
  countryCode: "PE",
  language: "es",
  defaultLang: { id: "es", value: "Español" },
  defaultCountry: { id: "PE", value: "Perú" },
};

const btnTypes = {
  ADD: "add",
  EDIT: "Edit",
  REMOVE: "Remove",
};

const CompanyManager = (props) => {
  const classes = useStyles();
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [openToasterSeverity, setOpenToasterSeverity] = useState(null);
  const [data, setData] = useState(defaultData);
  const [list, setList] = useState({ rows: [], totalRows: 0 });
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [rowSelect, setRowSelect] = useState([]);

  const getDataCompany = async () => {
    props.SpinnerShow("Cargando...");
    const response = await getCompanyList();
    setList({
      rows: response.data,
      totalRows: response.data.length,
    });
    props.SpinnerHide();
  };

  const enableDisableCompany = async (request) => {
    await setEnableDisableCompany(request);
  };

  const executeCreate = async (item) => {
    const setting = {
      titleModal: "Agregar Empresa",
      action: btnTypes.ADD,
    };
    props.OpenModal("MODAL_COMPANY", setting);
  };

  const showToaster = (value, severity = "success") => {
    setOpenToasterMessage(value);
    setOpenToasterSeverity(severity);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
      setOpenToasterMessage(null);
      setOpenToasterSeverity(null);
    }, 5000);
  };

  const handleClose = () => {
    props.CloseModal();
  };

  const handlerChange = (key, value) => {
    const aux = { ...data };
    aux[key] = value;
    setData(aux);
  };

  const handleSave = async () => {
    props.SpinnerShow("Cargando...");
    const request = {
      tenantId: data.tenantId,
      clientId: data.clientId,
      clientSecret: data.clientSecret,
      tenantName: "",
      countryCode: data.countryCode,
      language: data.language,
      ruc: data.ruc,
    };

    const result = await saveCompany(request);
    showToaster("La empresa se guardo correctamente");
    setUniqueValue(moment().format());
    clearData();
    props.CloseModal();
    props.SpinnerHide("");
  };

  const clearData = () => {
    setData(defaultData);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setUniqueValue(moment().format());
      props.CloseModal();
    });

    return () => {
      clearTimeout(timer);
    };
  }, []);

  // User Sync logic
  const [userSyncOpen, setUserSyncOpen] = useState(false);
  const [userSyncCompany, setUserSyncCompany] = useState("");
  const getSyncUsersResponse = (response) => {
    if (response.includes("does not exist in MASTER")) {
      return {
        message: `La empresa ${userSyncCompany} no existe en la base de datos`,
        severity: "error",
      };
    } else if (response.includes("has missing parameters")) {
      return {
        message: `La empresa ${userSyncCompany} no tiene parametros en la base de datos`,
        severity: "error",
      };
    } else if (response.includes("has wrong parameters")) {
      return {
        message: `La empresa ${userSyncCompany} tiene parametros incorrectos en la base de datos`,
        severity: "error",
      };
    } else if (response.includes("Tenant")) {
      return {
        message: `La empresa ${userSyncCompany} no tiene usuarios creados aún`,
        severity: "error",
      };
    } else if (response.includes("does not exist in")) {
      return {
        message: `La empresa ${userSyncCompany} no existe en la base de datos a la que pertenece`,
        severity: "error",
      };
    } else if (response.includes("Company users successfully updated")) {
      return {
        message: `Usuarios de la empresa ${userSyncCompany} actualizados correctamente`,
        severity: "success",
      };
    } else {
      return {
        message: `Error al actualizar usuarios de la empresa ${userSyncCompany}`,
        severity: "error",
      };
    }
  };
  const handleUserSyncSave = async () => {
    props.SpinnerShow("Cargando...");
    const request = {
      tenantName: userSyncCompany,
    };
    syncCompanyUsers(request)
      .then((response) => {
        const toasterResult = getSyncUsersResponse(response.data.message);
        showToaster(toasterResult.message, toasterResult.severity);
        setUserSyncOpen(false);
        setUniqueValue(moment().format());
        setUserSyncCompany("");
        props.SpinnerHide();
      })
      .catch((error) => {
        props.SpinnerHide();
        showToaster("Ocurrió un error al sincronizar la empresa");
      });
  };
  const handleUserSyncChange = (event) => {
    setUserSyncCompany(event.target.value);
  };
  const handleUserSyncClose = () => {
    setUserSyncOpen(false);
    setUserSyncCompany("");
  };

  return (
    <>
      <Grid container className={classes.content}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader
              classes={{
                root: classes.rootHeader,
                action: classes.action,
              }}
              title={
                <Typography
                  gutterBottom
                  variant="h6"
                  component="h6"
                  className={classes.tipograghy}
                >
                  Administrar Empresas
                </Typography>
              }
              action={
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  onClick={() => setUserSyncOpen(true)}
                >
                  Sincronizar Usuarios
                </Button>
              }
            ></CardHeader>
            <Divider />
            <CardContent>
              <CompanyManagerGrid
                dataCompany={getDataCompany}
                listData={list}
                uniqValue={uniqValue}
                appModules={props.applicationModules}
                executeCreate={executeCreate}
                //executeDelete = {executeDelete}
                //executeEdit = {executeEdit}
                handleActiveCompany={enableDisableCompany}
                isAdmin={props.isAdmin}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <CompanyManagerUserSyncModal
        open={userSyncOpen}
        handleClose={handleUserSyncClose}
        title="Sincronizar Usuarios"
        handleSave={handleUserSyncSave}
        fieldValue={userSyncCompany}
        handleChange={handleUserSyncChange}
      />

      <CompanyManagerModal
        id={"MODAL_COMPANY"}
        handleClose={handleClose}
        handleSave={handleSave}
        handlerChange={handlerChange}
        appModules={props.applicationModules}
        companySelect={rowSelect}
        data={data}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity={openToasterSeverity ? openToasterSeverity : "success"}>
          {openToasterMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { user, app, spinnerReducer } = state;
  const { fullName, roleId } = user;
  const { applicationModules } = app;

  const isAdmin = roleId === 1 || roleId === 99;

  return {
    ...ownProps,
    fullName,
    applicationModules,
    spinnerReducer,
    isAdmin,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions, ...ModalAction })(
  CompanyManager
);
