import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import FstModal from "../../components/modal/FstModal";
import FSTGridCrud from "../../components/grid/FSTGridCrud";
import { HeaderTable } from "./productRowsTable";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import moment from "moment";
import * as SettingsChangeActions from "actions/settingsChangeActions";
import * as ProductActions from "../../actions/productActions";
import * as XLSX from "xlsx";
import {
  Card,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  deleteProduct,
  getProducts,
  massivePostProduct,
  postProduct,
  putProduct,
} from "./service/productService";
import * as SpinnerActions from "../../actions/spinnerActions";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProductModal = (props) => {
  const settingModal = { BtnNameSave: "Cerrar" };
  const [uniqValue, setUniqValue] = useState(moment().format());
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [openToasterSeverity, setOpenToasterSeverity] = useState("success");
  const messageDelete =
    "Los productos no pueden ser eliminados porque están siendo utilizados.";
  const ExportFileName = "Productos.xlsx";
  const [headerTable, setHeaderTable] = useState(HeaderTable);
  const [allData, setAllData] = useState([]);
  const take = 12;
  const [pagination, setPagination] = useState({
    search: "",
    OrderAsc: false,
    orderBy: null,
    PageNumber: 1,
    pageSize: take,
  });

  const handleClose = () => {
    props.handleOpenModal(false);
  };

  /// Height handling
  const [height, setHeight] = useState();
  const handleResize = useCallback(() => {
    const padding = 400;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  ///

  const getData = async (pagination) => {
    const request = {
      OrderBy: pagination.orderBy,
      PageSize: pagination.pageSize,
      Filter: pagination.search,
      PageNumber: pagination.PageNumber,
      OrderAsc: pagination.OrderAsc,
    };
    setPagination(pagination);
    let result = await getProducts(request);

    let headerTableCopy = [...headerTable];
    let modifiedHeader = false;
    // flatten with json parse the "data" field in result.result
    // and add the keys of 'data' to the headerTable
    let resultParsed = result.data?.currentPageItems?.map((item) => {
      if (item.data) {
        let dataParsed = JSON.parse(item.data);
        // add its keys to HeaderTable if they are not already there
        Object.keys(dataParsed).forEach((key) => {
          if (!headerTableCopy.find((x) => x.field === key)) {
            headerTableCopy.push({
              field: key,
              headerText: key,
              width: 150,
              textAlign: "left",
              visible: true,
              clipMode: "EllipsisWithTooltip",
            });
            modifiedHeader = true;
          }
        });
        return { ...item, ...dataParsed };
      } else {
        return { ...item };
      }
    });

    setAllData({ result: resultParsed, count: result.data.totalItems });
    if (modifiedHeader) {
      setHeaderTable(headerTableCopy);
    }
  };

  useEffect(() => {
    if (props.open) getData(pagination);
  }, [props.open]);

  const refreshData = () => {
    setTimeout(() => {
      getData(pagination);
    }, 400);
  };

  const showToasterError = (message) => {
    setOpenToasterMessage(message);
    setOpenToasterSeverity("error");
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
      setTimeout(() => {
        setOpenToasterSeverity("success");
      }, 750);
    }, 7000);
  };

  const handleSave = async (data) => {
    if (!data.productCode || !data.productDesc) {
      showToasterError("El código y descripción del producto son requeridos.");
      return;
    }
    // if any data field is in headerTable, add it to data.data
    let dataToSave = { ...data };
    // remove add button from data
    let dataFields = headerTable.filter(
      (x) => x.field !== "addButtonColumnExcel",
    );
    // convert data field to object
    if (!dataToSave.data) dataToSave.data = {};
    else dataToSave.data = JSON.parse(dataToSave.data);

    dataFields.forEach((field) => {
      // if the field is as a standalone field, add it to data.data,
      // but only if it is not null and not from the products fixed fields
      if (
        dataToSave.hasOwnProperty(field.field) &&
        dataToSave[field.field] != null &&
        !["productBarCode", "productCode", "productDesc", "productId"].includes(
          field.field,
        )
      ) {
        dataToSave.data[field.field] = dataToSave[field.field];
        // if the user inputs an empty string, remove the field from data.data so data.data ends up being null
        if (dataToSave[field.field] === "") delete dataToSave.data[field.field];
        delete dataToSave[field.field];
      }
    });

    // if data.data is empty, set it to null so it doesnt get saved as an empty object
    if (Object.keys(dataToSave.data).length > 0)
      dataToSave.data = JSON.stringify(dataToSave.data);
    else dataToSave.data = null;

    // if the product is new, add it
    if (!dataToSave.productId) {
      try {
        await postProduct(dataToSave);
      } catch (error) {
        showToasterError(
          `El producto "${data.productDesc}" no puede ser agregado porque ya existe.`,
        );
        return;
      }
    } else {
      // if the product is not new, update it
      await putProduct(dataToSave);
    }

    showToaster(`El producto "${data.productDesc}" ha sido almacenado.`);
    setUniqValue(uniqValue + 1);
    props.SettingsChangeActivate();
    refreshData();
  };

  const handleDelete = async (items) => {
    for (const item of items) {
      try {
        await deleteProduct(item.productId);
      } catch (error) {
        showToasterError(
          `El producto "${item.productDesc}" no puede ser eliminado porque está siendo utilizado.`,
        );
        return;
      }
    }
    if (items.length > 1) {
      showToaster("Los productos han sido eliminados.");
    } else {
      showToaster(`El producto "${items[0].productDesc}" ha sido eliminado.`);
    }
    setUniqValue(uniqValue + 1);
    props.SettingsChangeActivate();
    refreshData();
  };

  const validateDelete = (items) => {
    return items.every((item) => item.disposable);
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 4000);
  };

  ///////////////////////-- EXCEL IMPORT --//////////////////////////
  const [excelDataOrganizationModal, setExcelDataOrganizationModal] =
    useState(false);

  const excelDataOrgInitial = [
    {
      columnValue: "productCode",
      columnName: "Código",
      columnPosition: 0,
      modifiable: false,
    },
    {
      columnValue: "productBarCode",
      columnName: "Código de barras",
      columnPosition: 1,
      modifiable: false,
    },
    {
      columnValue: "productDesc",
      columnName: "Descripción",
      columnPosition: 2,
      modifiable: false,
    },
  ];
  const excelDataAdd = [
    {
      columnValue: "addButtonColumnExcel",
      columnName: "Agregar columna",
      modifiable: false,
    },
  ];

  const [excelDataOrganization, setExcelDataOrganization] = useState([
    ...excelDataOrgInitial,
    ...excelDataAdd,
  ]);
  const [duplicateExcelPositions, setDuplicateExcelPositions] = useState({
    value: false,
    message: "",
  });

  //function that returns excel columns available from a to z with format { id: 0, name: "A"}
  const getExcelColumns = () => {
    let columns = [];
    for (let i = 0; i < 26; i++)
      columns.push({ id: i, name: String.fromCharCode(65 + i) });
    return columns;
  };

  const [excelDataOrganizationHeader, setExcelDataOrganizationHeader] =
    useState(false);

  const handleImport = () => {
    setExcelDataOrganizationModal(true);
  };

  const handleSaveExcelDataOrganization = () => {
    if (verifyDuplicatePositions(excelDataOrganization)) return;
    if (verifyNullColumnNames(excelDataOrganization)) return;

    document.getElementById("import-file-xlsx").click();
    setExcelDataOrganizationModal(false);
  };

  const handleCloseExcelDataOrganization = () => {
    setExcelDataOrganizationModal(false);
    setExcelDataOrganization([...excelDataOrgInitial, ...excelDataAdd]);
    setExcelDataOrganizationHeader(false);
    setDuplicateExcelPositions({ value: false, message: "" });
  };

  const verifyNullColumnNames = (data) => {
    let nullColumnNames = false;
    data.forEach((item) => {
      if (item.columnName === "" || item.columnValue === "")
        nullColumnNames = true;
    });
    if (nullColumnNames)
      showToasterError("No se puede importar con columnas sin nombre");

    return nullColumnNames;
  };

  const verifyDuplicatePositions = (data) => {
    let seen = new Set();
    var hasDuplicates = data.some(
      (currentObject) =>
        seen.size === seen.add(currentObject.columnPosition).size,
    );
    hasDuplicates |= data.some(
      (currentObject) => seen.size === seen.add(currentObject.columnValue).size,
    );
    if (hasDuplicates) {
      setDuplicateExcelPositions({
        value: true,
        message: "No debe haber columnas repetidas",
      });
    } else {
      setDuplicateExcelPositions({ value: false, message: "" });
    }
    return hasDuplicates;
  };

  const handleChange = (event, item, mustModifyPosition = false) => {
    //find and modify the item in the excelDataOrganization array
    let excelDataOrganizationCopy = [...excelDataOrganization];
    let itemIndex = excelDataOrganizationCopy.findIndex(
      (x) =>
        x.columnPosition === item.columnPosition &&
        x.columnName === item.columnName &&
        x.columnValue === item.columnValue,
    );
    if (mustModifyPosition) {
      // modify the position of the item
      excelDataOrganizationCopy[itemIndex].columnPosition = event.target.value;
    } else {
      // modify the name of the item
      // remove dots from the string
      excelDataOrganizationCopy[itemIndex].columnValue =
        event.target.value.replace(/\./g, "");
      excelDataOrganizationCopy[itemIndex].columnName =
        event.target.value.replace(/\./g, "");
    }
    setExcelDataOrganization(excelDataOrganizationCopy);

    // if two items have the same columnPosition, then warn user
    verifyDuplicatePositions(excelDataOrganizationCopy);
  };

  const handleAddColumn = () => {
    // max columnPosition + 1 (so if the user adds a columns and sets the position to 5, then the next column will be 6, even if there is no position 4)
    let maxColumnPosition = Math.max.apply(
      Math,
      excelDataOrganization.slice(0, -1).map((o) => o.columnPosition),
    );
    // this shouldn't happen, but just in case
    if (maxColumnPosition === -Infinity || maxColumnPosition === "NaN")
      maxColumnPosition = excelDataOrganization.length - 1;

    setExcelDataOrganization([
      ...excelDataOrganization.slice(0, -1),
      {
        columnName: "",
        columnValue: "",
        columnPosition: maxColumnPosition + 1,
        modifiable: true,
      },
      ...excelDataAdd,
    ]);
  };

  const handleRemoveColumn = (event, item) => {
    let excelDataOrganizationCopy = [...excelDataOrganization];
    // to find the item all three properties must match
    let itemIndex = excelDataOrganizationCopy.findIndex(
      (x) =>
        x.columnPosition === item.columnPosition &&
        x.columnName === item.columnName &&
        x.columnValue === item.columnValue,
    );
    excelDataOrganizationCopy.splice(itemIndex, 1);
    setExcelDataOrganization(excelDataOrganizationCopy);

    // if two items have the same columnPosition, then warn user
    verifyDuplicatePositions(excelDataOrganizationCopy);
  };

  const handleChangeHeaderSwitch = (event) => {
    setExcelDataOrganizationHeader(event.target.checked);
  };

  const getDataExcel = (event) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(event.target.files[0]);
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workBook = XLSX.read(data, { type: "array" });
        const sheetName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[sheetName];

        // skip the first row if there is header
        if (excelDataOrganizationHeader) {
          var range = XLSX.utils.decode_range(sheet["!ref"]);
          range.s.r = 1; // <-- zero-indexed, so setting to 1 will skip row 0
          sheet["!ref"] = XLSX.utils.encode_range(range);
        }

        // array of empty strings of the same length as the excel columns
        // this way we can use the excel specified by the user, with the columns in any order
        let header = Array.from({ length: 26 }, () => null);
        excelDataOrganization.forEach((item) => {
          if (item.columnValue !== "addButtonColumnExcel")
            header[item.columnPosition] = item.columnValue;
        });

        // to array of obj
        const dataParse = XLSX.utils.sheet_to_json(sheet, { header: header });

        resolve(dataParse);
      };
    });
  };

  const importFile = async (event) => {
    const _file = { FileName: event.target.files[0].name };
    if (_file.FileName.indexOf("xls") === -1) {
      showToasterError("El archivo no es válido");
      return;
    }
    const data = await getDataExcel(event);
    // verify if there's one with no product code
    const noProductCode = data.find((item) => !item.productCode);
    if (noProductCode) {
      const productIndex = data.indexOf(noProductCode);
      const message = `El producto en la fila ${
        productIndex + 1
      } no posee código.`;
      showToasterError("El código del producto es requerido. " + message);
      return;
    }
    // verify if there's one with no product desc
    const noProductDesc = data.find((item) => !item.productDesc);
    if (noProductDesc) {
      const productIndex = data.indexOf(noProductDesc);
      const message = `El producto en la fila ${
        productIndex + 1
      } no posee descripción.`;
      showToasterError("La descripción del producto es requerida. " + message);
      return;
    }
    // verify if there's one with invalid json data for the Data column
    data.forEach((item, index) => {
      const { productCode, productDesc, productBarCode, ...rest } = item;
      try {
        let test = JSON.parse(JSON.stringify(rest));
      } catch (e) {
        const message = `El producto en la fila ${
          index + 1
        } posee datos extras inválidos.`;
        showToasterError("Datos extras inválidos. " + message);
        return;
      }
    });

    // add all other keys that are not productCode, productDesc or productBarCode to a new key called 'data' with a JSON as value
    const dataWithJSON = data.map((item) => {
      const { productCode, productDesc, productBarCode, ...rest } = item;
      return {
        productCode,
        productDesc,
        productBarCode,
        Data: JSON.stringify(rest),
      };
    });
    props.SpinnerShow("Guardando...");
    try {
      await massivePostProduct(dataWithJSON);
    } catch (e) {
      showToasterError(
        "No se han podido guardar los productos. Intente nuevamente.",
      );
      props.SpinnerHide();
      return;
    }
    props.SpinnerHide();
    showToaster("Los productos han sido importados.");
    setUniqValue(uniqValue + 1);
    props.SettingsChangeActivate();

    handleCloseExcelDataOrganization();

    // close modal then refresh columns and reopen modal, this is needed to properly show new columns
    handleClose();
    props.SpinnerShow("Cargando...");
    setTimeout(() => {
      refreshData(true);
      setTimeout(() => {
        props.handleRefreshModal();
        props.SpinnerHide();
      }, 500);
    }, 1000);
  };

  const getDataExport = async () => {
    props.SpinnerShow("Exportando...");
    const request = {
      PageSize: 99999,
      PageNumber: 1,
      OrderAsc: false,
    };
    const response = await getProducts(request);
    props.SpinnerHide();
    return { data: response.data.currentPageItems };
  };

  return (
    <>
      <FstModal
        open={props.open}
        title="Productos"
        handleClose={handleClose}
        handleSave={handleClose}
        onlyOK={true}
        widthSize="md"
        setting={settingModal}
        IsDisabledPrimary={false}
      >
        <FSTGridCrud
          id="gridProductsModal"
          list={allData}
          rows={HeaderTable}
          dynamicColumns={headerTable}
          uniqValue={uniqValue}
          Getdata={getData}
          ExportFileName={ExportFileName}
          GetDataExport={getDataExport}
          delete={(item) => handleDelete(item)}
          reloadDefault={true}
          messageDelete={messageDelete}
          validateDelete={validateDelete}
          handleUpdate={(data) => handleSave(data)}
          Import={handleImport}
          take={take}
          selectionSettings={{ type: "Multiple", mode: "Row" }}
          height={height}
        />

        <FstModal
          open={excelDataOrganizationModal}
          title="Interpretación del contenido del archivo Excel"
          handleClose={handleCloseExcelDataOrganization}
          handleSave={handleSaveExcelDataOrganization}
          widthSize="md"
          setting={{ BtnNameSave: "Importar" }}
          IsDisabledPrimary={false}
          classContainer={"fst-Modal-Container"}
        >
          <div className="row" style={{ width: 700 }}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                overflowX: "auto",
                overflowY: "hidden",
                alignItems: "stretch",
              }}
            >
              {excelDataOrganization.map((item, index) => (
                <Grid
                  item
                  xs={4}
                  key={index}
                  style={{
                    flexBasis: "25%",
                    flexGrow: 0,
                    flexShrink: 0,
                    alignItems: "center",
                    alignContent: "center",
                  }}
                >
                  <Card style={{ alignItems: "center" }}>
                    {item.modifiable ? (
                      <Grid
                        container
                        style={{
                          alignItems: "center",
                          paddingBottom: "4%",
                          paddingTop: "8%",
                          justifyContent: "center",
                        }}
                        spacing={2}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Nombre"
                          variant="outlined"
                          name="columnName"
                          value={item.columnName}
                          onChange={(event) => handleChange(event, item)}
                          size="small"
                          style={{ width: "75%" }}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        container
                        style={{
                          alignItems: "center",
                          paddingBottom: "10%",
                          paddingTop: "10%",
                          justifyContent: "center",
                        }}
                        spacing={2}
                      >
                        <Typography style={{ fontSize: "medium" }} variant="h6">
                          {item.columnName}
                        </Typography>
                      </Grid>
                    )}

                    {item.columnValue === "addButtonColumnExcel" ? (
                      <Grid
                        container
                        style={{
                          alignItems: "center",
                          paddingBottom: "9%",
                          justifyContent: "center",
                        }}
                        spacing={2}
                      >
                        <IconButton onClick={handleAddColumn}>
                          <AddCircleIcon />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        style={{
                          alignItems: "center",
                          paddingBottom: "10%",
                          paddingTop: "5%",
                          justifyContent: "center",
                        }}
                        spacing={2}
                      >
                        <Typography
                          style={{ fontSize: "small", paddingRight: "5%" }}
                        >
                          Columna:
                        </Typography>
                        <TextField
                          style={{ width: "40%" }}
                          fullWidth
                          value={item.columnPosition}
                          variant="outlined"
                          size="small"
                          name={item.columnValue}
                          onChange={(event) => handleChange(event, item, true)}
                          select
                        >
                          {getExcelColumns().map((excelColumns) => (
                            <MenuItem
                              key={excelColumns.id}
                              value={excelColumns.id}
                            >
                              {excelColumns.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        {item.modifiable && (
                          <IconButton
                            onClick={(event) => handleRemoveColumn(event, item)}
                            style={{ marginBottom: "-2%", marginTop: "2%" }}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        )}
                      </Grid>
                    )}
                  </Card>
                </Grid>
              ))}
            </Grid>
            {duplicateExcelPositions.value && (
              <Typography
                style={{ color: "red", paddingTop: "2%", textAlign: "center" }}
                variant="h6"
              >
                {duplicateExcelPositions.message}
              </Typography>
            )}
            <Card style={{ marginTop: "3%" }}>
              <Grid container spacing={2} justify="center">
                <Grid item xs={4} style={{ alignSelf: "center" }}>
                  <Typography
                    style={{ fontSize: "medium", marginLeft: "4%" }}
                    variant="h6"
                  >
                    Contiene encabezado
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "center" }}>
                  <Switch
                    style={{ whiteSpace: "nowrap", width: "70%" }}
                    color="primary"
                    checked={excelDataOrganizationHeader}
                    name={"hasHeader"}
                    onChange={handleChangeHeaderSwitch}
                    size="medium"
                  />
                </Grid>
              </Grid>
            </Card>
          </div>
        </FstModal>
      </FstModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity={openToasterSeverity}>{openToasterMessage}</Alert>
      </Snackbar>
      <input
        accept=".xlsx,.xls"
        style={{ display: "none" }}
        id="import-file-xlsx"
        type="file"
        hidden
        onInput={(e) => {
          importFile(e);
        }}
        onClick={(event) => {
          event.target.value = null;
        }}
      />
    </>
  );
};

const mapStateToProps = (reducer) => {
  return {
    ...reducer.settingsChangeReducer,
    ...reducer.productReducer,
    ...reducer.spinnerReducer,
  };
};

export default connect(mapStateToProps, {
  ...SettingsChangeActions,
  ...ProductActions,
  ...SpinnerActions,
})(ProductModal);
