import { post, get } from "../../../config/axios.js";

export const getFiltersCountPac = async (request) => {
  return await post("/api/pac/getfilterscount", request);
};

export const getFilteredResultPac = async (request) => {
  return await post("/api/pac/getfilteredresult", request);
};

export const downloadExcelPac = async (request) => {
  return await post("/api/reportwriter/exportpac", request);
};
