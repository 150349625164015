import React, { useState } from "react";
import { useStyles } from "./style";
import CriteriaWordListCard from "../../../components/criteria/CriteriaWordListCard";
import { Grid, Card, CardContent, Divider } from "@material-ui/core";
import HeaderWords from "../../../components/criteria/HeaderWords";

export default function ManageSmartSearchWords(props) {
  const classes = useStyles();
  const { smartSearch, handleWordsList, addElement } = props;
  const [heightContent, setHeightContent] = useState("320px");
  const [expand, setExpand] = useState(false);

  const handleExpand = (value) => {
    setExpand(value);
    setHeightContent(value ? "100px" : "320px");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <HeaderWords
              control="panel1bh-content"
              id="include-words-header"
              label="Incluir por Item"
              list={smartSearch.includeWords}
              addElement={() => addElement("includeWords")}
            />
            <Divider className={classes.divider} />
            <CardContent
              className={classes.cardContent}
              style={{ height: heightContent }}
            >
              <CriteriaWordListCard
                key="includeWords"
                name={"includeWords"}
                list={smartSearch.includeWords}
                handleList={(value) => handleWordsList("includeWords", value)}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <HeaderWords
              control="panel2bh-content"
              id="include-words-name-header"
              label="Incluir por nombre"
              list={smartSearch.includeWordsNames}
              addElement={() => addElement("includeWordsNames")}
            />
            <Divider className={classes.divider} />
            <CardContent
              className={classes.cardContent}
              style={{ height: heightContent }}
            >
              <CriteriaWordListCard
                key="includeWordsNames"
                name={"includeWordsNames"}
                list={smartSearch.includeWordsNames}
                handleList={(value) =>
                  handleWordsList("includeWordsNames", value)
                }
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <HeaderWords
              control="panel3bh-content"
              id="exclude-words-header"
              label="Excluir por item"
              list={smartSearch.excludeWords}
              addElement={() => addElement("excludeWords")}
              showExpand={true}
              expand={expand}
              handleExpand={handleExpand}
            />
            <Divider className={classes.divider} />
            <CardContent
              className={classes.cardContent}
              style={{ height: expand ? "320px" : 0 }}
            >
              {expand && (
                <CriteriaWordListCard
                  key="excludeWords"
                  name={"excludeWords"}
                  list={smartSearch.excludeWords}
                  handleList={(value) => handleWordsList("excludeWords", value)}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <HeaderWords
              control="panel3bh-content"
              id="exclude-name-words-header"
              label="Excluir por nombre"
              list={smartSearch.excludeWordsNames}
              addElement={() => addElement("excludeWordsNames")}
              showExpand={true}
              expand={expand}
              handleExpand={handleExpand}
            />
            <Divider className={classes.divider} />
            <CardContent
              className={classes.cardContent}
              style={{ height: expand ? "320px" : 0 }}
            >
              {expand && (
                <CriteriaWordListCard
                  key="excludeWordsNames"
                  name={"excludeWordsNames"}
                  list={smartSearch.excludeWordsNames}
                  handleList={(value) =>
                    handleWordsList("excludeWordsNames", value)
                  }
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
