import {
  Button,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import FstModal from "components/modal/FstModal";
import React from "react";

export default function BalanceModalData(props) {
  return (
    <FstModal
      open={props.open}
      title={"Resumen del monto adjudicado, pedidos e inyección de presupuesto"}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      setting={{ BtnNameSave: "Cerrar" }}
      onlyOK={true}
      widthSize="md"
      fullWidth={true}
      classContainer={"fst-Modal-Container"}
    >
      <CardContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {props.data.amountAwarded?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell>{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3>{"Información del bien, servicio u obra"}</h3>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.data.awardedOrdersHeaders?.map((row, idx) => (
                  <TableCell key={idx}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.awardedOrdersInfo?.map((row, idx) => (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                  key={idx}
                >
                  {row.map((row2, idx) => (
                    <TableCell key={`${row2}${idx}`}>{row2}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableBody>
                <TableRow key={1}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}
                  >
                    Monto total de órdenes de pedido
                  </TableCell>
                  <TableCell>
                    <>
                      {props.data.CheckBalance?.HistoryTotal}{" "}
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ marginLeft: "50px", height: "50%" }}
                        onClick={props.handleOpenHistory}
                      >
                        {"Ver detalle"}
                      </Button>
                    </>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </CardContent>
    </FstModal>
  );
}
