import React, { useState } from "react";
import { Grid, Card, CardContent, Divider } from "@material-ui/core";
import CriteriaWordListCard from "../../../components/criteria/CriteriaWordListCard";
import { ProductFilteredList } from "./ProductFilteredList";
import { useStyles } from "../../smart-search/manage-smart-search/style";
import HeaderWords from "../../../components/criteria/HeaderWords";

export default function ProductCriteria(props) {
  const classes = useStyles();
  const { listInclude, listExclude, handleList, addElement } = props;
  const [displayProductsFiltered, setDisplayProductsFiltered] = useState(false);
  const [heightContent, setHeightContent] = useState("320px");

  const openProductsFiltered = (value) => {
    setDisplayProductsFiltered(value);
    setHeightContent(value ? "100px" : "340px");
  };

  function valdate() {
    const list1 = listInclude.map((item) =>
      item.words.length > 0 ? true : false
    );
    const list2 = listExclude.map((item) =>
      item.words.length > 0 ? true : false
    );
    const itemValidate =
      (list1 + ", " + list2).indexOf(true) > -1 ? false : true;

    return itemValidate;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Card>
            <HeaderWords
              control="panel1bh-content"
              id="include-header"
              label="Incluir"
              list={listInclude}
              addElement={() => addElement("IncludeKey")}
            />
            <Divider className={classes.divider} />
            <CardContent
              className={classes.cardContent}
              style={{ height: heightContent }}
            >
              <CriteriaWordListCard
                key="IncludeKey"
                list={listInclude}
                handleList={(value) => handleList("IncludeKey", value)}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card>
            <HeaderWords
              control="panel2bh-content"
              id="exclude-header"
              label="Excluir"
              list={listExclude}
              addElement={() => addElement("ExcludeKey")}
            />
            <Divider className={classes.divider} />
            <CardContent
              className={classes.cardContent}
              style={{ height: heightContent }}
            >
              <CriteriaWordListCard
                key="ExcludeKey"
                list={listExclude}
                handleList={(value) => handleList("ExcludeKey", value)}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} lg={12}>
          <ProductFilteredList
            displayProductsFiltered={displayProductsFiltered}
            handleDisplayProductsFiltered={(value) =>
              openProductsFiltered(value)
            }
            listExclude={listExclude}
            listInclude={listInclude}
            btnActIncExt={valdate()}
          />
        </Grid>
      </Grid>
    </>
  );
}
