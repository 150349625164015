import { post, get } from "../../../config/axios.js";

export const saveCompany = async (request) => {
  return await post("/api/masterconfig/SyncCompany", request);
};

export const getCompanyList = async () => {
  return await get("/api/company");
};

export const setEnableDisableCompany = async (request) => {
  return await post("/api/company/enabledisablecompany", request);
};

export const syncCompanyUsers = async (request) => {
  return await post("/api/masterconfig/SyncCompanyUsers", request);
};
