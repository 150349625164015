import axios from "axios";

export const generalDataAlerts = async () => {
  try {
    const axiosResult = await axios.post("/api/Alert/GeneralDataAlerts");

    if (!!axiosResult && !!axiosResult.data) {
      return axiosResult.data;
    } else {
      return { error: "Error fetching general Alert data" };
    }
  } catch (error) {
    console.log({ error });
    return { error: "Error fetching general Alert data", msg: error };
  }
};

export const listAlerts = async () => {
  const defaultResponse = { totalCount: 0, page: 0, data: [], error: "" };

  try {
    const axiosResult = await axios.post("/api/Alert/ListAlerts");

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return { data };
    } else {
      defaultResponse.error = "Error fetching Alerts data";
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};

export const addOrUpdateAlert = async (row, tableData) => {
  try {
    const axiosResult = await axios.post("/api/Alert/AddOrUpdateAlert", [row]);

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      tableData.data.push(data);
      tableData.totalCount += 1;
      return { data };
    } else {
      tableData.error = "Error fetching Alert data";
      return tableData;
    }
  } catch (error) {
    console.log({ error });
    tableData.error = error;
    return tableData;
  }
};

export const deleteAlert = async (row, tableData) => {
  try {
    const axiosResult = await axios.post("/api/Alert/DeleteAlerts", [
      { AlertId: row.alertId },
    ]);

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      const { errorOnDelete } = data;
      if (!!errorOnDelete) {
        console.log({ errorOnDelete });
        return tableData;
      } else {
        const { data: rows, totalCount, ...rest } = tableData;

        const index = rows.indexOf(row);
        rows.splice(index, 1);

        return { ...rest, rows, totalCount: totalCount - 1 };
      }
    } else {
      console.log("Error deleting Alert data");
      return tableData;
    }
  } catch (error) {
    console.log({ error });
    tableData.error = error;
    return tableData;
  }
};
