import { Card, CardContent, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Query } from "@syncfusion/ej2-data";
import React, { useState } from "react";
import { connect } from "react-redux";
import AdminSnackbars from "views/utils-admin/AdminSnackbars";
import {
  addOrUpdateRecipients,
  deleteRecipient,
  listRecipients,
} from "../../actions/recipientActions";
import * as SpinnerActions from "../../actions/spinnerActions";
import AdminAccess from "../../components/AdminAccess";
import { StylePage } from "../../style/contentStyle";
import AdminGrid from "../../views/utils-admin/AdminGrid";
import AdminHeader from "../../views/utils-admin/AdminHeader";

function RecipientsAdmin(props) {
  const classes = StylePage();
  const [tableData, setTableData] = React.useState({
    data: [],
    page: 0,
    totalCount: 0,
  });

  const btnTypes = { REMOVE: "Remove" };
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterError, setOpenToasterError] = useState(false);
  const [itemToModify, setItemToModify] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const fetchAllData = async (pageInfo) => {
    props.SpinnerShow("Cargando...");
    await fetchTableData(pageInfo?.PageNumber ? pageInfo.PageNumber : 0);
    setTimeout(() => {
      props.SpinnerHide();
    }, 750);
  };

  const fetchTableData = async (page) => {
    return new Promise(async (resolve, reject) => {
      const recipients = await listRecipients();

      if (!!recipients) {
        const tableData = {
          page: page,
          data: recipients.data,
          totalCount: recipients.data.length,
        };
        setTableData(tableData);
        resolve(tableData);
      } else {
        reject("what?");
      }
    });
  };

  const remove = (btn, item) => {
    if (btn.type === btnTypes.REMOVE) {
      setItemToModify(item);
      setDeleteConfirm(true);
    }
  };

  const actionCustomRow = (btn, item) => {
    remove(btn, item);
  };

  const showToaster = () => {
    props.SpinnerShow("Cargando...");
    setDeleteConfirm(false);
    setItemToModify(null);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
      props.SpinnerHide();
    }, 2000);
  };

  const showToasterError = () => {
    setOpenToasterError(true);
    setTimeout(() => {
      setOpenToasterError(false);
    }, 4500);
  };

  const handleSave = async (item) => {
    if (!item.firstName || !item.lastName || !item.emails) {
      showToasterError();
      return;
    }
    showToaster();
    await addOrUpdateRecipients(item, tableData);
    await fetchTableData(tableData.page);
  };

  const handleDelete = async (item) => {
    showToaster();
    await deleteRecipient(item, tableData);
    await fetchTableData(tableData.page);
  };

  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  const header = [
    {
      field: "active",
      headerText: "Activo",
      width: "12%",
      textAlign: "Center",
      allowSorting: true,
      displayAsCheckBox: true,
      editType: "booleanedit",
      editParams: {
        params: {
          actionComplete: () => false,
          query: new Query(),
        },
      },
      allowEditing: true,
      defaultValue: true,
    },
    {
      field: "firstName",
      headerText: "Nombre",
      width: "21%",
      textAlign: "Left",
      allowSorting: true,
      showInColumnChooser: false,
      allowEditing: true,
    },
    {
      field: "lastName",
      headerText: "Apellido",
      width: "21%",
      textAlign: "Left",
      allowSorting: true,
      showInColumnChooser: false,
      allowEditing: true,
    },
    {
      field: "emails",
      headerText: "Emails",
      width: "25%",
      textAlign: "Left",
      allowSorting: true,
      showInColumnChooser: true,
      allowEditing: true,
    },
    {
      field: "phones",
      headerText: "Teléfonos",
      width: "21%",
      textAlign: "Left",
      allowSorting: true,
      showInColumnChooser: true,
      allowEditing: true,
    },
    {
      headerText: "Acciones",
      width: "160px",
      commands: [
        {
          type: "Edit",
          buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
        },
        {
          type: "Remove",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
        {
          type: "Save",
          buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
    },
  ];
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////

  return (
    <div className={classes.content}>
      <AdminAccess>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <AdminHeader headerTitle="Destinatarios" />
              <Divider />
              <CardContent>
                <AdminGrid
                  tableData={tableData}
                  tableDataCount={tableData.totalCount}
                  reloadDefault={true}
                  header={header}
                  idGrid="RecipientsAdmin"
                  fetchAllData={fetchAllData}
                  actionCustomRow={actionCustomRow}
                  handleSave={handleSave}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <AdminSnackbars
          openToaster={openToaster}
          openToasterError={openToasterError}
          modalTitle={`Eliminar destinatario`}
          modalClose={() => {
            setItemToModify(null);
            setDeleteConfirm(false);
          }}
          deleteConfirm={deleteConfirm}
          modalMessage={`¿Está seguro que desea eliminar el destinatario ${
            itemToModify?.firstName ? itemToModify?.firstName : ""
          }${itemToModify?.lastName ? ` ${itemToModify?.lastName}` : ""}?`}
          modalSuccess={() => handleDelete(itemToModify)}
        />
      </AdminAccess>
    </div>
  );
}

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(RecipientsAdmin);
