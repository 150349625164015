import React from "react";
import FstModal from "components/modal/FstModal";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";

export default function EditViewModal(props) {
  return (
    <FstModal
      title="Seleccione la vista a mostrar por defecto"
      open={props.openEditView}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      widthSize="lg"
    >
      <div className="row" style={{ width: 450 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="position"
                name="position"
                value={props.statusId}
                onChange={(e) => {
                  props.setStatusId(+e.target.value);
                }}
              >
                {props.statusGroups?.map((item, idx) => (
                  <FormControlLabel
                    value={item.statusId}
                    control={<Radio color="primary" />}
                    label={item.statusDesc}
                    labelPlacement="end"
                    key={idx}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </FstModal>
  );
}
