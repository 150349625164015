import moment from "moment";
import { formatShortDate, isLangCR } from "utils/common";
import {
  flattenSheetDataFromJson,
  sanitizeStringForXML,
} from "utils/excelExportService";

export const fillAllDetailsSheet = (
  workbook,
  intl,
  sheetNumber,
  exportHeader,
  exportData,
  exportItemsData,
  exportTenderData,
) => {
  const sheet1 = workbook.sheet(sheetNumber);
  sheet1.name(
    intl.formatMessage({
      id: "tab.ListaItems",
      defaultMessage: "Lista de Items",
    }),
  );

  // Write tender data
  sheet1.cell("B2").value(exportTenderData);
  sheet1.range(`B2:B${exportTenderData.length + 1}`).style("bold", true); // Bold titles

  // Write items data
  // Removes invalid XML characters as sometimes they are present in the data and make the excel file not openable
  let result = flattenSheetDataFromJson(exportData, exportHeader);
  result = result.map((item) => item.map((cell) => sanitizeStringForXML(cell)));
  sheet1.cell("A10").value(result);

  const totalColumnsItems = exportItemsData[0].length;
  const endColumn = String.fromCharCode(64 + totalColumnsItems);
  // Header bold
  sheet1.row(10).style("bold", true);
  // center text for every row in the range
  sheet1
    .range(`A10:${endColumn}${exportItemsData.length + 10}`)
    .style("horizontalAlignment", "center");
  // center text left for second column after header
  sheet1
    .range(`B11:B${exportItemsData.length + 10}`)
    .style("horizontalAlignment", "left");

  // Set column widths
  sheet1.column("A").width(10);
  sheet1.column("B").width(30);
  sheet1.column("C").width(25);
  sheet1.column("D").width(15);
  sheet1.column("E").width(15);
  sheet1.column("F").width(15);
  sheet1.column("G").width(15);
  sheet1.column("H").width(35);
  sheet1.column("I").width(30);
  sheet1.column("J").width(30);
  sheet1.column("K").width(30);
};

export const getAllDetailsFormattedInitialData = (tenderDetails, tagsList) => {
  return tenderDetails.map((item) => {
    return {
      ...item,
      offered: item.offered ? "Si" : "No",
      isPrimary: item.isPrimary ? "No" : "Si",
      tagIds: item.tagIds
        .map((tag) => tagsList.find((t) => t.tagId === tag).name)
        .join(", "),
      ...(isLangCR()
        ? {
            deliveryDate: item.deliveryDate
              ? formatShortDate(item.deliveryDate)
              : "",
          }
        : {}),
      ...(!item.productCode && !item.productDesc
        ? {}
        : {
            productId: `(${item.productCode}) - ${item.productDesc}`,
          }),
    };
  });
};

export const getAllDetailsData = (intl, tender, header, formattedData) => {
  let exportHeader = header
    .filter((item) => item.headerText && !item.hideToExport)
    .map((item) => item.headerText);
  let exportColumns = header
    .filter((item) => item.headerText && !item.hideToExport)
    .map((item) => item.field);
  let exportData = formattedData.map((item) => {
    let exportItem = {};
    exportColumns.forEach((column) => {
      exportItem[column] = item[column];
    });
    return exportItem;
  });
  let exportItemsData = [[...exportHeader], ...exportData];
  let exportTenderData = [
    [
      intl.formatMessage({
        id: "text.Nomenclatura",
        defaultMessage: "Nomenclatura",
      }),
      tender.fileNumber,
    ],
    [
      intl.formatMessage({
        id: "text.Fecha_Publicacion",
        defaultMessage: "Fecha Publicación",
      }),
      moment(tender.publishedDate).format("DD/MM/YYYY"),
    ],
    [
      intl.formatMessage({
        id: "text.Convocatoria",
        defaultMessage: "Convocatoria",
      }),
      tender.tenderNumber,
    ],
    [
      intl.formatMessage({
        id: "text.Tipo_Compra",
        defaultMessage: "Tipo Compra",
      }),
      tender.tenderTypeDesc,
    ],
    [
      intl.formatMessage({
        id: "text.Entidad",
        defaultMessage: "Entidad",
      }),
      tender.clientUnitName,
    ],
    [
      intl.formatMessage({
        id: "text.Descripcion",
        defaultMessage: "Descripción",
      }),
      tender.tenderName,
    ],
  ];

  return {
    exportHeader,
    exportData,
    exportItemsData,
    exportTenderData,
  };
};
