import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { getTypes, getDateRange } from "../services/AnalysisService";
import { formatDate } from "../../../utils/DateHelper";
import { FIJO } from "./processTime";
import FstInputText from "../../../components/forms/input-text/FstInputText";
import FstAutocomplete from "../../../components/forms/autocomplete/FstAutocomplete";
import FstDatePicker from "../../../components/forms/date-picker/FstDatePicker";
import FstInputNumeric from "../../../components/forms/input-numeric/FstInputNumeric";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpSharpIcon from "@material-ui/icons/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@material-ui/icons/KeyboardArrowDownSharp";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialErrors = {
  dateValidity: false,
  dateErrorMsg: "",
};

const initialErrorsText = {
  required: true,
  error: false,
  message: "",
};

const useStyles = makeStyles((theme) => ({
  content: {
    padding: "0px 7px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  fontSize: {
    "& span:last-child": {
      fontSize: 15,
    },
  },
}));

function ReportFilter(props) {
  const setDataPicker = props.filter?.allowDatePicker;
  const setFixedDate = props.fixedDate;
  const [types, setTypes] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [allowDatePicker, setAllowDatePicker] = useState(setDataPicker);
  const classes = useStyles();
  const [infoTooltip, setInfoTooltip] = useState([]);
  const [sourcesTooltip, setSourcesTooltip] = useState([]);
  const [openSources, setOpenSources] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState(initialErrors);
  const [errorInput, setErrorInput] = useState(initialErrorsText);
  const [isFocus, setIsFocus] = useState(true);

  const getSelectTypes = async () => {
    const list = await getTypes(props.ModuleId);
    setTypes(list.data);
  };

  const sourceIdsToSourceData = (sourceIds) => {
    if (sourceIds && sourceIds.length > 0) {
      const sourcesIds = sourceIds.split(",").map((item) => {
        return props.sources?.find((source) => source.id === parseInt(item));
      });
      return sourcesIds;
    }
    return [];
  };

  const changePeriod = (key, value) => {
    if (!value) return;
    const allow = !(FIJO.key === value.key.toString());
    setAllowDatePicker(allow);
    props.onChangeProcessTime(value);
  };

  const getSelectRange = async () => {
    const result = await getDateRange();
    setRanges(result.data);
  };

  const handleDisplay = () => {
    const rest = showForm;
    setShowForm(!rest);
    if (!rest) {
      props.onChange("RangeFrom", "");
      props.onChange("RangeTo", "");
      props.onChange("Types", props.filter.Types);
    }
  };

  const getDatePickerStart = (key, value) => {
    if (key === "min") {
      return moment(value, "DD/MM/YYYY").subtract(10, "y").format("YYYY-MM-DD");
    } else {
      return moment().add(1, "d").format("YYYY-MM-DD");
    }
  };

  const getDatePickerEnd = (key, value) => {
    if (key === "min") {
      return moment(value, "DD/MM/YYYY").add(1, "d").format("YYYY-MM-DD");
    } else {
      return moment().add(1, "y").format("YYYY-MM-DD");
    }
  };

  useEffect(() => {
    getSelectTypes();
    getSelectRange();
    validadDates();
    return () => {};
  }, []);

  const openTooltip = (value) => {
    setOpen(value);
  };

  const getFixedDate = (date) => {
    if (setFixedDate) {
      return undefined;
    }
    return date;
  };

  const handleSources = (value) => {
    let sourcesIds = value.map((item) => item.id).join(",");
    if (!sourcesIds) {
      sourcesIds = null;
    }
    props.onChange("SourceIds", sourcesIds);
    setSourcesTooltip(value);
  };

  const openSourcesTooltip = (value) => {
    setOpenSources(value);
  };

  const handleTypes = (value) => {
    let auxilter = [];
    if (value.length > 0) {
      const nItem = value.length - 1;
      const lastItem = value[nItem];
      let itemLog = false;
      auxilter = value.filter((item, index) => {
        if (index !== nItem) {
          if (item.entityTypeId === lastItem.entityTypeId) {
            itemLog = true;
          } else {
            return item;
          }
        }
      });

      if (!itemLog) {
        auxilter.push(lastItem);
      }
    }

    props.onChange("Types", auxilter);
    setInfoTooltip(auxilter);
  };

  const handleChecked = (event) => {
    props.onChange("Result", event.target.checked);
  };

  const handleApplyToCompetitorDescription = (event) => {
    props.onChange("ApplyToCompetitorDescription", event.target.checked);
  };

  const validadDates = async (key, value) => {
    let dateErrorMsg = "";
    let dateValidity = false;
    let dateErrorMsgYear = "";
    let dateValidityYear = false;

    let startDate = moment(props.filter.StartDate, "DD/MM/YYYY");
    let endDate = moment(props.filter.EndDate, "DD/MM/YYYY");

    if (key === "StartDate") {
      startDate = moment(value, "DD/MM/YYYY");
    } else if (key === "EndDate") {
      endDate = moment(value, "DD/MM/YYYY");
    }

    const yearDiff = moment().subtract(10, "y").format("yyyy");
    const yearStart = moment(startDate).format("yyyy");
    const dataValidateYear = yearDiff <= yearStart;
    const dateRangeCheck = startDate <= endDate;

    dateErrorMsgYear = dataValidateYear
      ? ""
      : "La fecha está fuera del rango de los diez años";
    dateValidityYear = dataValidateYear ? false : true;
    if (!dateValidityYear) {
      dateErrorMsg = dateRangeCheck
        ? ""
        : "La fecha hasta no puede ser menor a la desde";
      dateValidity = dateRangeCheck ? false : true;
    }

    await setErrors((prevState) => ({
      ...prevState,
      dateValidity,
      dateErrorMsg,
      dateValidityYear,
      dateErrorMsgYear,
    }));

    //props.handleError('errorDate', dateValidity + '_' + dateValidityYear);
  };

  const handleChangeName = (key, value) => {
    props.onChange(key, value);
    validateInput(key, value);
  };

  const validateInput = async (key, value) => {
    let error = false;
    let message = "";

    const defaultError = value.trim() ? true : false;
    error = defaultError ? false : true;

    await setErrorInput((prevState) => ({
      ...prevState,
      error,
      message,
    }));
  };

  const handleChange = (key, value) => {
    const element = document.querySelector(`input[name='${key}']`);
    let newValue = value;
    if (element.value === "") {
      newValue = null;
    }
    props.onChange(key, newValue);
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        className={classes.content}
        style={!showForm ? { marginBottom: "5px" } : {}}
      >
        <Grid
          container
          item
          spacing={2}
          xs={11}
          sm={11}
          md={11}
          style={{ width: "97%", maxWidth: "97%", flexBasis: "97%" }}
        >
          <Grid item xs={12} sm={5} md={5}>
            <FstInputText
              name="Name"
              label="Nombre"
              value={props.filter.Name}
              handleChange={handleChangeName}
              error={errorInput.error}
              required={errorInput.required}
              autoFocus={isFocus}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <FstAutocomplete
              label="Periodo"
              name="range"
              list={ranges}
              getOptions={(option) => (option ? option.value : "")}
              value={props.filter.range}
              handleChange={changePeriod}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <FstDatePicker
              label="Desde"
              name="StartDate"
              format={formatDate.es.SHORT_PICKER}
              inputValue={getFixedDate(props.filter.StartDate)}
              value={props.filter.StartDate}
              handleChange={props.onChange}
              readOnly={allowDatePicker}
              minDate={getDatePickerStart("min", props.filter.StartDate)}
              maxDate={getDatePickerStart("max", props.filter.StartDate)}
              helperText={errors.dateErrorMsgYear}
              error={errors.dateValidityYear}
              handleError={validadDates}
            />
          </Grid>
          <Grid item xs={2} sm={2} md={2}>
            <FstDatePicker
              label="Hasta"
              name="EndDate"
              format={formatDate.es.SHORT_PICKER}
              inputValue={getFixedDate(props.filter.EndDate)}
              value={props.filter.EndDate}
              handleChange={props.onChange}
              readOnly={allowDatePicker}
              minDate={getDatePickerEnd("min", props.filter.StartDate)}
              maxDate={getDatePickerEnd("max", props.filter.StartDate)}
              helperText={errors.dateErrorMsg}
              error={errors.dateValidity}
              handleError={validadDates}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          style={{ width: "3%", maxWidth: "3%", flexBasis: "3%" }}
        >
          <IconButton aria-label="delete" onClick={handleDisplay}>
            {showForm ? (
              <KeyboardArrowUpSharpIcon />
            ) : (
              openExistFilter(props.filter)
            )}
          </IconButton>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={showForm ? {} : { display: "none" }}
        className={classes.content}
      >
        <Grid
          container
          item
          spacing={2}
          xs={11}
          sm={11}
          md={11}
          style={{
            width: "97%",
            maxWidth: "97%",
            flexBasis: "97%",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={props.ModuleId !== 4 ? 2 : 4}
            md={props.ModuleId !== 4 ? 2 : 4}
          >
            <Tooltip
              open={open}
              title={infoTooltip.map((item) => item.name).join(", ")}
              placement="bottom"
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="Types-label">Tipo</InputLabel>
                <Select
                  labelId="Types-label"
                  id="Types"
                  multiple
                  label="Tipo"
                  value={props.filter.Types}
                  defaultValue={props.filter.Types}
                  onChange={(event) => {
                    handleTypes(event.target.value);
                  }}
                  onMouseEnter={() => {
                    openTooltip(true);
                  }}
                  onMouseLeave={() => {
                    openTooltip(false);
                  }}
                  onClick={() => {
                    openTooltip(false);
                  }}
                  renderValue={(selected) =>
                    selected.map((item) => item.name).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {types.map((type) => (
                    <MenuItem key={type.entityTypeId} value={type}>
                      <Checkbox
                        checked={props.filter.Types.some(
                          (elem) =>
                            elem.entityTypeId.toString() ===
                            type.entityTypeId.toString(),
                        )}
                      />
                      <Tooltip title={type.name} placement="right">
                        <ListItemText primary={type.name} />
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid
            item
            xs={12}
            sm={props.ModuleId !== 4 ? 2 : 4}
            md={props.ModuleId !== 4 ? 2 : 4}
          >
            <Tooltip
              open={openSources}
              title={sourcesTooltip.map((item) => item.name).join(", ")}
              placement="bottom"
            >
              <FormControl variant="outlined" style={{ width: "100%" }}>
                <InputLabel id="SourceIds-label">Portal</InputLabel>
                <Select
                  labelId="SourceIds-label"
                  id="SourceIds"
                  multiple
                  label="SourceIds"
                  value={sourceIdsToSourceData(props.filter.SourceIds)}
                  defaultValue={sourceIdsToSourceData(props.filter.SourceIds)}
                  disabled={props.sources?.length <= 1} // If there is only one source (portal), disable the select
                  onChange={(event) => {
                    handleSources(event.target.value);
                  }}
                  onMouseEnter={() => {
                    openSourcesTooltip(true);
                  }}
                  onMouseLeave={() => {
                    openSourcesTooltip(false);
                  }}
                  onClick={() => {
                    openSourcesTooltip(false);
                  }}
                  renderValue={(selected) =>
                    selected.map((item) => item.name).join(", ")
                  }
                  MenuProps={MenuProps}
                >
                  {props.sources?.map((portal) => (
                    <MenuItem key={portal.id} value={portal}>
                      <Checkbox
                        checked={sourceIdsToSourceData(
                          props.filter.SourceIds,
                        ).some((elem) => elem.id === portal.id)}
                      />
                      <Tooltip title={portal.name} placement="right">
                        <ListItemText primary={portal.name} />
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <FstInputNumeric
              name="RangeFrom"
              label="Valor Desde"
              value={props.filter.RangeFrom}
              handleChange={(key, value) => {
                handleChange(key, value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <FstInputNumeric
              name="RangeTo"
              label="Valor Hasta"
              value={props.filter.RangeTo}
              handleChange={(key, value) => {
                handleChange(key, value);
              }}
            />
          </Grid>
          {props.ModuleId !== 4 && (
            <>
              <Grid item xs={12} sm={2} md={2}>
                <FormControlLabel
                  className={classes.fontSize}
                  control={
                    <Switch
                      color="primary"
                      checked={props.filter.Result}
                      onChange={handleChecked}
                      size="small"
                    />
                  }
                  label="Sólo adjudicadas"
                  labelPlacement="top"
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <FormControlLabel
                  className={classes.fontSize}
                  style={{ whiteSpace: "nowrap" }}
                  control={
                    <Switch
                      color="primary"
                      checked={props.filter.ApplyToCompetitorDescription}
                      onChange={handleApplyToCompetitorDescription}
                      size="small"
                    />
                  }
                  label="Aplicar en desc. vendedor"
                  labelPlacement="top"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ReportFilter;

export const openExistFilter = (filter) => {
  if (
    filter.Types.length > 0 ||
    (filter.RangeFrom !== "" && filter.RangeFrom > 0) ||
    (filter.RangeTo !== "" && filter.RangeTo > 0) ||
    (filter.Result !== "" && filter.Result) ||
    (filter.ApplyToCompetitorDescription !== "" &&
      filter.ApplyToCompetitorDescription) ||
    filter.SourceIds !== ""
  ) {
    return (
      <>
        <KeyboardArrowDownSharpIcon />
        <div className="itemExistFilter"></div>
      </>
    );
  } else {
    return <KeyboardArrowDownSharpIcon />;
  }
};
