import { SendTendersType } from "../types";

const INITIAL_STATE = {
  sendTender: null,
  reloadDefault: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SendTendersType.SEND_TENDER_ALL:
      return {
        ...state,
        sendTender: action.payload.result,
        error: null,
      };
    case SendTendersType.SEND_TENDER_ERROR:
      return {
        ...state,
        sendTender: null,
        error: action.error,
      };
    case SendTendersType.SEND_TENDER_DEFAULT:
      return {
        ...state,
        reloadDefault: action.reloadDefault,
      };
    default:
      return state;
  }
};
