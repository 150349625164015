import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FstModal from "../../../components/modal/FstModal";
import ManageSmartSearch from "../../smart-search/manage-smart-search/ManageSmartSearch";
import { addOrUpdateSearchFilter } from "../../smart-search/services/smartSearchService";
import * as SpinnerActions from "../../../actions/spinnerActions";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const message = {
  ENTITY_ALREADY_EXISTS: " ya existe en el sistema.",
  FILTER: "El filtro",
};

const ModalManagePac = (props) => {
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const spinnerMessage = "Guardando...";
  const [smartSearch, setSmartSearch] = useState({
    name: "",
    quantity: 7,
    includeWords: [{ word: "", words: [] }],
    includeWordsNames: [{ word: "", words: [] }],
    excludeWords: [{ word: "", words: [] }],
    excludeWordsNames: [{ word: "", words: [] }],
  });
  const [searchFilterId, setSearchFilterId] = useState(null);
  const [intBtnGuardar, setInitBtnGuardar] = useState(true);

  const handleClose = () => {
    props.handleClose();
  };

  const cleanListEmpty = (list) => {
    return list
      .filter((item) => item.words.length > 0)
      .map((item) => item.words);
  };

  const handleSave = async () => {
    props.SpinnerShow(spinnerMessage);
    const request = {
      name: smartSearch.name,
      days: smartSearch.quantity,
      includeWords: cleanListEmpty(smartSearch.includeWords),
      excludeWords: cleanListEmpty(smartSearch.excludeWords),
      includeWordsNames: cleanListEmpty(smartSearch.includeWordsNames),
      excludeWordsNames: cleanListEmpty(smartSearch.excludeWordsNames),
    };
    if (smartSearch.searchFilterId) {
      request.searchFilterId = smartSearch.searchFilterId;
    }
    setInitBtnGuardar(true);
    addOrUpdateSearchFilter([request])
      .then(() => {
        props.SpinnerHide();
        props.handleSave(request);
      })
      .catch((error) => {
        props.SpinnerHide();
        setOpenToaster(true);
        setOpenToasterMessage(
          message[error.entityKey] + message[error.msgErrorKey]
        );
        setTimeout(() => {
          setOpenToaster(false);
        }, 1500);
      });
  };

  const handleSmartSearch = (value) => {
    setSmartSearch(value);
  };

  useEffect(() => {
    const id = props.smartSearch ? props.smartSearch.searchFilterId : null;
    setSearchFilterId(id);
  }, [props.open]);

  const activeBtnGuardar = (value) => {
    setInitBtnGuardar(value);
  };

  const verifyActiveBtn = (value) => {
    let active = [];
    let auxActive = [];

    active.push(intBtnGuardar);
    active.push(!smartSearch.name || smartSearch.name.length === 0);
    active.push(!smartSearch.quantity || smartSearch.quantity.length === 0);

    if (smartSearch.includeWords)
      auxActive.push(cleanListEmpty(smartSearch.includeWords).length === 0);

    if (smartSearch.excludeWords)
      auxActive.push(cleanListEmpty(smartSearch.excludeWords).length === 0);

    if (smartSearch.includeWordsNames)
      auxActive.push(
        cleanListEmpty(smartSearch.includeWordsNames).length === 0
      );

    if (smartSearch.excludeWordsNames)
      auxActive.push(
        cleanListEmpty(smartSearch.excludeWordsNames).length === 0
      );

    active.push(auxActive.indexOf(false) === -1 ? true : false);

    return active.indexOf(true) === -1 ? false : true;
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity="error">{openToasterMessage}</Alert>
      </Snackbar>
      <FstModal
        open={props.open}
        title="Filtros PAC "
        handleClose={handleClose}
        handleSave={handleSave}
        widthSize="lg"
        fullWidth={true}
        classContainer={"fst-Modal-Container"}
        IsDisabledPrimary={verifyActiveBtn()}
      >
        <div style={{ height: "530px" }}>
          <ManageSmartSearch
            handleSmartSearch={handleSmartSearch}
            handleBtnActive={activeBtnGuardar}
            searchFilterId={searchFilterId}
            quantity={7}
            isShow={false}
            edit={props.edit}
          />
        </div>
      </FstModal>
    </>
  );
};
const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(ModalManagePac);
