import React from "react";
import formatJSON from "../json/form-CR.json";
import structureDataJS from "./structure-data/dataStructure-CR.js";
import {
  formatDate,
  compareString,
  getDataJsonComplex,
  getDataJsonBucle,
  formatCurrencyLocale,
  formatShortDate,
} from "../../../../../utils/common";
import ButtonModalDetail from "../../../utils-info/ButtonModalDetail";
import { Provider } from "react-redux";
import { store } from "../../../../../store";
import { QueryClientProvider } from "react-query";
import { quereyClient } from "../../../../../App";
import { LangProvider } from "../../../../../intl/context/langContext";

export default {
  ...formatJSON,
  ...structureDataJS,
  TabGeneralInfoFirst: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "tenderDescription", label: "Clasificación del objeto" },
            {
              key: "estimatedValue",
              label: "Presupuesto total estimado",
              render: (value, column) =>
                formatCurrencyLocale(value, 2, column.currency),
            },
            {
              key: "extension",
              label: "Presupuesto total estimado USD (Opcional)",
              render: (value, column) =>
                formatCurrencyLocale(
                  getDataJsonBucle(
                    "Info.EstimatedTotalBudgetUSD",
                    value,
                    "J.V",
                  ),
                  2,
                  "USD",
                ),
            },
            {
              key: "publishedDate",
              label: "Fecha/hora de publicación",
              render: formatDate,
            },
          ],
        },
      },
    ],
  },
  TabGeneralInfoSecond: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "portalStatus", label: "Estado del concurso" },
            {
              key: "extension",
              label: "Tipo de modalidad",
              render: (value) =>
                getDataJsonBucle("Info.ModalityType", value, "J.V"),
            },
            {
              key: "extension",
              label: "Excepción de contratación directa",
              render: (value) =>
                getDataJsonBucle(
                  "Info.DirectContractingException",
                  value,
                  "J.V",
                ),
            },
            {
              key: "extension",
              label: "Plazo de Adjudicación",
              render: (value) =>
                getDataJsonBucle("Info.AwardTerm", value, "J.V"),
            },
          ],
        },
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "name", label: "Etapa" },
            { key: "startDate", label: "Fecha/hora", render: formatDate } /*,
                        {key: "endDate", label: "Días hábiles",  
                            render: (value, column) => 
                            compareString(column.tenderStageType, ['PLAZO']) ? value : '' }   */,
          ],
        },
      },
    ],
  },
  TabProcedureDocument: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", label: "Nro.", primary: true },
            { key: "type", label: "Tipo de documento", primary: true },
            {
              key: "link",
              label: "Documento",
              render: (value, column, row, data) =>
                column.link !== "" ? (
                  <a href={value}>{column.name}</a>
                ) : (
                  column.name
                ),
            },
          ],
        },
      },
    ],
  },
  ListCompDocuments: {
    component: [
      {
        type: "Grid",
        id_ff: "TabProcedureDocument",
        template: (props) => {
          return props.data.map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          });
        },
      },
    ],
  },
  TabGeneralProcedureActions: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "number", label: "Nro.", primary: true },
            { key: "name", label: "Acción", primary: true },
            {
              key: "publishedDate",
              label: "Fecha publicación",
              render: formatDate,
            },
            { key: "description", label: "Motivo de la acción" },
          ],
        },
      },
    ],
  },

  TabAwardedContracts: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "contractNumber", label: "Número de contrato" },
            {
              key: "",
              label: "Detalle del contrato",
              render: (value, column, row, data) => (
                <ButtonModalDetail
                  data={row}
                  queryClient={"MODAL_AWARDED"}
                  detail={"Contrato"}
                  result={{ contractNumber: column.contractNumber }}
                  titleModal={"Contratos"}
                  modalName={"MODAL_AWARDED"}
                />
              ),
            },
            {
              key: "date",
              label: "Fecha de contrato",
              render: formatShortDate,
            },
            { key: "contractTerm", label: "Vigencia del contrato" },
            { key: "status", label: "Estado" },
          ],
        },
      },
    ],
  },
  TabAwardedPurchaseOrders: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "OrderNumber", label: "Número de orden de pedido" },
            {
              key: "",
              label: "Orden de pedido",
              render: (value, column, row, data) => (
                <ButtonModalDetail
                  data={column}
                  queryClient={"MODAL_AWARDED"}
                  detail={"Ordenes de Pedido"}
                  result={{}}
                  titleModal={"Ordenes de Pedido"}
                  modalName={"MODAL_AWARDED"}
                />
              ),
            },
            {
              key: "NotificationDate",
              label: "Notificación orden de pedido",
              render: (value) => {
                // Replace '01/01/0001' with null
                if (formatShortDate(value) === "01/01/0001") return "";
                return formatShortDate(value);
              },
            },
          ],
        },
      },
    ],
  },

  TabSupervisingJob: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "Sin oficios",
          columns: [
            { key: "number", label: "Nro.", primary: true },
            {
              key: "publishDate",
              label: "Fecha de publicación",
              render: formatDate,
            },
            { key: "data", label: "Datos del Oficio", primary: true },
            {
              key: "releaseDate",
              label: "Fecha de emisión",
              render: formatDate,
            },
            {
              key: "link",
              label: "Archivo",
              render: (value, column, row, data) => (
                <a href={value}>{column.data}</a>
              ),
            },
          ],
        },
      },
    ],
  },
  TabOffersInfo: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "MaxOfferNumber", label: "Número máximo de ofertas bases" },
            {
              key: "MaxAlternativeOfferNumber",
              label: "Número máximo de ofertas alternativas",
            },
            { key: "OfferValidity", label: "Vigencia" },
          ],
        },
      },
    ],
  },
  TabContractConditions: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "ContractValidity", label: "Vigencia del contrato" },
            { key: "Extension", label: "Prórroga" },
            { key: "Fine", label: "Multa" },
            { key: "PenaltyClause", label: "Cláusula Penal" },
          ],
        },
      },
    ],
  },
  TabDelivery: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "AccordingToDemand", label: "Según demanda" },
            { key: "DeliveryDetail", label: "Detalle de entrega" },
            { key: "DeliveryObservations", label: "Observaciones" },
          ],
        },
      },
    ],
  },

  TabRelatedOfficials: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "Line", label: "Línea" },
            { key: "Role", label: "Rol" },
            { key: "Name", label: "Nombre del encargado" },
            { key: "Dependency", label: "Nombre de la dependencia" },
          ],
        },
      },
    ],
  },
  ValueCmb_Carta: {
    objectName: "comboGarantiasCR",
  },
  ListCompItemList: {
    data: {
      section: "1",
    },
    component: [
      {
        type: "Grid",
        id_ff: "TabItemListFirst",
      },
    ],
  },
  TabItemListFirst: {
    element: {
      type: "FstGrid",
      columns: [
        {
          id: "extensionPartida",
          field: "extensionPartida", // not really a field but it needs to be unique
          headerText: "Partida",
          width: "40",
          template: (props) => (
            <span>
              {getDataJsonComplex("SicopBatch.Batch", props.extension)}
            </span>
          ),
        },
        { field: "itemNumber", headerText: "Línea", width: "40" },
        { field: "code", headerText: "Código", width: "100" },
        { field: "description", headerText: "Descripción", width: "150" },
        { field: "qty", headerText: "Cantidad", width: "60" },
        {
          field: "estimatedValue",
          headerText: "Precio Unitario",
          template: (props) => (
            <span>
              {formatCurrencyLocale(
                props.estimatedValue,
                2,
                props.estimatedValueCurrency,
              )}
            </span>
          ),
        },
        {
          id: "extensionDetallePartida",
          field: "extensionDetallePartida", // not really a field but it needs to be unique
          headerText: "Detalle de Partida",
          width: "100",
          template: (props) => (
            <LangProvider>
              <QueryClientProvider client={quereyClient}>
                <Provider store={store}>
                  <ButtonModalDetail
                    queryClient={"MODAL_DETAIL"}
                    detail={"Partida"}
                    data={getDataJsonComplex(null, props.extension)}
                    result={{}}
                    titleModal={"Detalle de la partida"}
                    modalName={"MODAL_DETAIL"}
                  />
                </Provider>
              </QueryClientProvider>
            </LangProvider>
          ),
          hideToExport: true,
        },
        {
          id: "extensionDetalleLinea",
          field: "extensionDetalleLinea", // not really a field but it needs to be unique
          headerText: "Detalle de Línea",
          textAlign: "center",
          width: "90",
          template: (props) => (
            <LangProvider>
              <QueryClientProvider client={quereyClient}>
                <Provider store={store}>
                  <ButtonModalDetail
                    queryClient={"MODAL_DETAIL"}
                    detail={"Línea"}
                    data={getDataJsonComplex(null, props.extension)}
                    result={{}}
                    titleModal={"Información detallada de línea"}
                    modalName={"MODAL_DETAIL"}
                  />
                </Provider>
              </QueryClientProvider>
            </LangProvider>
          ),
          hideToExport: true,
        },
        {
          id: "extensionDetalleContratacion",
          field: "tenderExtension",
          headerText: "Detalle de Contratación",
          width: "100",
          template: (props) => (
            <LangProvider>
              <QueryClientProvider client={quereyClient}>
                <Provider store={store}>
                  <ButtonModalDetail
                    queryClient={"MODAL_DETAIL"}
                    detail={"Contratación"}
                    data={getDataJsonBucle(
                      "Info.RequestsInformation",
                      props.tenderExtension,
                      "J.V",
                    )}
                    result={{
                      RequestNumber: getDataJsonComplex(
                        "RequestNumber",
                        props.extension,
                      ),
                    }}
                    titleModal={"Solicitud de contratación"}
                    modalName={"MODAL_DETAIL"}
                  />
                </Provider>
              </QueryClientProvider>
            </LangProvider>
          ),
          hideToExport: true,
        },
      ],
    },
  },
};
