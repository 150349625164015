import {
  Card,
  CardContent,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

export default function LineData(props) {
  return (
    <Card>
      <CardContent>
        <h3>{props.title1}</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}
                >
                  {props.allData.requestNumber.name}
                </TableCell>
                <TableCell>{props.allData.requestNumber.data}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <CardContent>
        <h3>{props.title2}</h3>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.delivery.map((row) => (
                  <TableCell key={row.id}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {props.allData.delivery.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  {props.allData.deliveryItems.map((row) => (
                    <TableCell key={row.id}>{row.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.allData.deliveryItemsList.map((row) => (
                  <TableRow
                    key={row.length !== 0 ? row[0] + 100 : 1}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    {row.map((row2) => (
                      <TableCell key={row2}>{row2}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </CardContent>
      <CardContent>
        <h3>{props.title3}</h3>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}
                >
                  {props.allData.guarrantee.name}
                </TableCell>
                <TableCell>{props.allData.guarrantee.data}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3>{props.title4}</h3>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.budgetFirstLine.map((row) => (
                  <TableCell key={row.name}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {props.allData.budgetFirstLine.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
          <Divider />
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.budgetSecondLine.map((row) => (
                  <TableCell key={row.name}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                key={12}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {props.allData.budgetSecondLine.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>

      <CardContent>
        <h3>{props.title5}</h3>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.sampleFirstLine.map((row) => (
                  <TableCell key={row.name}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {props.allData.sampleFirstLine.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
          <Divider />
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                {props.allData.sampleSecondLine.map((row) => (
                  <TableCell key={row.name}>{row.name}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {props.allData.sampleSecondLine.map((row) => (
                  <TableCell key={row.id}>{row.data}</TableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
