import React, { useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { FstGrid } from "../../components/grid/FstGrid";
import * as SpinnerActions from "../../actions/spinnerActions";
import * as LegalDocumentsActions from "../../actions/legalDocumentActions";
import {
  HeaderTable,
  ColumnsDates,
  ColumnsBooleans,
} from "./LegalDocumentHeaderTable";
import Grid from "@material-ui/core/Grid";
import ManageLegalDocuments from "./ManageLegalDocument";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { PenaltyMode } from "../../types";
import FstModalConfirm from "../../components/modal/FstModalConfirm";
import { useIntl } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LegalDocuments = (props) => {
  const spinnerMessage = "Creando...";
  const [uniqueValue, setUniqueValue] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [setting, setSetting] = useState({ IsCreate: true });
  const [legalDocument, setLegalDocument] = useState(null);
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [openDelete, setDeleteConfirm] = useState(false);
  const [legalDocumentToDelete, setLegalDocumentToDelete] = useState(null);
  const intl = useIntl();

  const getLegalDocuments = async (pag, filter) => {
    props.SpinnerShow(spinnerMessage);
    await props.GetLegalDocuments(pag, filter);
    props.SpinnerHide();
    setUniqueValue(moment().format());
  };

  const CreateTicket = () => {
    setLegalDocument(null);
    setOpenModal(true);
    setSetting({ Mode: PenaltyMode.CREATE });
  };

  const viewLegalDocuments = (item) => {
    setLegalDocument(item);
    setSetting({ Mode: PenaltyMode.VIEW });
    setOpenModal(true);
  };

  const deleteLegalDocument = async () => {
    props.SpinnerShow(spinnerMessage);
    const request = [
      {
        LegalDocumentId: legalDocumentToDelete.legalDocumentId,
      },
    ];
    props.SpinnerHide();
    await props.DeleteLegalDocument(request);
    setUniqueValue(moment().format());
    setDeleteConfirm(false);

    showToaster(
      intl.formatMessage({
        id: "etq_CartaFianza",
        defaultMessage: "Carta Fianza",
      }) + " eliminada exitosamente"
    );
  };

  const handleSave = async (request) => {
    props.SpinnerShow(spinnerMessage);
    await props.Save([request]);
    props.SpinnerHide();
    showToaster(
      intl.formatMessage({
        id: "etq_CartaFianza",
        defaultMessage: "Carta Fianza",
      }) + " creada exitosamente"
    );
    setUniqueValue(moment().format());
    setOpenModal(false);
  };

  const openModalComfirmDelete = (item) => {
    if (!item) return;
    setLegalDocumentToDelete(item);
    setDeleteConfirm(true);
  };

  const dataGrid = {
    Getdata: getLegalDocuments,
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
    },
    Header: HeaderTable(intl),
    take: 15,
    height: props.height,
    allowSorting: true,
    allowPaging: true,
    rowDoubleClickAction: viewLegalDocuments,
    Add: CreateTicket,
    exportFunc: props.GetLegalDocumentsExport,
    Delete: openModalComfirmDelete,
    ExportFileName: "Carta Fianza.xlsx",
    ToolBar: {
      Add: true,
      AddTitle: "Agregar",
      Export: true,
      Delete: true,
      DeleteTitle: "Eliminar",
      Search: true,
    },
    idGrid: "LegalDocuments",
  };

  const showToaster = (message) => {
    setOpenToasterMessage(message);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 3000);
  };

  const defaultData = async () => {
    await props.GetLegalDocumentsDefault();
  };

  useEffect(() => {
    setUniqueValue(moment().format());
    defaultData();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <FstGrid
            data={dataGrid}
            uniqValue={uniqueValue}
            ColumnsDate={ColumnsDates}
            ColumnsBooleans={ColumnsBooleans}
            result={{
              result: props.result,
              count: props.count,
            }}
            reloadDefault={props.reloadDefault}
          />
        </Grid>
      </Grid>

      <FstModalConfirm
        title={
          "Eliminación de " +
          intl.formatMessage({
            id: "etq_CartaFianza",
            defaultMessage: "Carta Fianza",
          })
        }
        close={() => {
          setDeleteConfirm(false);
        }}
        open={openDelete}
        message={
          "¿Esta seguro que desea eliminar la " +
          intl.formatMessage({
            id: "etq_CartaFianza",
            defaultMessage: "Carta Fianza",
          }) +
          "?"
        }
        success={deleteLegalDocument}
      />

      {openModal && (
        <ManageLegalDocuments
          openModal={openModal}
          setting={setting}
          Save={handleSave}
          legalDocument={legalDocument}
          handleOpenModal={() => setOpenModal(false)}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (reducer) => {
  return reducer.legalDocumentReducer;
};
export default connect(mapStateToProps, {
  ...LegalDocumentsActions,
  ...SpinnerActions,
})(LegalDocuments);
