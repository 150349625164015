import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as SettingsChangeActions from "actions/settingsChangeActions";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FstModal from "../../components/modal/FstModal";
import {
  addOrUpdateCompanySetting,
  listCompanySettings,
} from "./services/CompanySettingService";
import { getSources } from "views/smart-search/services/smartSearchService";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialData = {
  ruc: "",
  limitAppealSimpleTender: 0,
  limitAppealPublicTender: 0,
  limitWorkingDaysToSign: 0,
  limitWorkingDaysToSendDoc: 0,
  limitWarrantyExpiration: 0,
  faithfulObservanceThreshold: 0,
  faithfulObservanceThresholdCurrency: "",
  sendNotificationToSeller: false,
  getBACOffers: false,
  smartSearchSources: [],
};

const fieldsData = [
  {
    name: "ruc",
    translation: "Identificador",
    type: "text",
  },
  {
    name: "limitAppealSimpleTender",
    translation: "Límite de apelación simple de licitación (días)",
    type: "number",
  },
  {
    name: "limitAppealPublicTender",
    translation: "Límite de apelación pública de licitación (días)",
    type: "number",
  },
  {
    name: "limitWorkingDaysToSign",
    translation: "Límite de días para firmar",
    type: "number",
  },
  {
    name: "limitWorkingDaysToSendDoc",
    translation: "Límite de días para enviar documentación",
    type: "number",
  },
  {
    name: "limitWarrantyExpiration",
    translation: "Límite de vencimiento de garantía (días)",
    type: "number",
  },
  {
    name: "faithfulObservanceThreshold",
    translation: "Umbral para requerir Boleta de Garantia",
    type: "number",
  },
  {
    name: "faithfulObservanceThresholdCurrency",
    translation: "Moneda del Umbral para requerir Boleta de Garantia",
    type: "text",
  },
  {
    name: "sendNotificationToSeller",
    translation: "Enviar notificación a ejecutivo",
    type: "switch",
  },
  {
    name: "getBACOffers",
    translation: "Mostrar ofertas de BAC",
    type: "switch",
  },
  {
    name: "smartSearchSources",
    translation: "Portales para resultados de Smart Search",
    type: "multiselect",
  },
];

const CompanySettingModal = (props) => {
  const settingModal = { BtnNameSave: "Guardar" };
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [data, setData] = useState(initialData);
  const [sources, setSources] = useState([]);
  const [openSources, setOpenSources] = useState(false);

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = async () => {
    await addOrUpdateCompanySetting({
      ...data,
      smartSearchSources: data.smartSearchSources.join(","),
    });
    showToaster(`La configuración ha sido almacenada.`);
    props.handleClose();
  };

  const handleChange = (event) => {
    if (
      fieldsData.find((x) => x.name === event.target.name)?.type === "switch"
    ) {
      setData({ ...data, [event.target.name]: event.target.checked });
    } else {
      setData({ ...data, [event.target.name]: event.target.value });
    }
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 4000);
  };

  const getData = async () => {
    const result = await listCompanySettings();
    const resultData = {
      ...result.data,
      smartSearchSources: result.data.smartSearchSources
        ? result.data.smartSearchSources.split(",").map((x) => +x)
        : [],
    };
    setData(resultData);
    const sources = await getSources();
    setSources(sources.data);
  };

  useEffect(() => {
    if (props.open) getData();
  }, [props.open]);

  return (
    <>
      <FstModal
        open={props.open}
        title="Configuración de Empresa"
        handleClose={handleClose}
        handleSave={handleSave}
        widthSize="md"
        setting={settingModal}
        IsDisabledPrimary={false}
        classContainer={"fst-Modal-Container"}
      >
        <div className="row" style={{}}>
          <Grid container spacing={2}>
            {fieldsData.map((item, idx) => (
              <Grid
                container
                style={{
                  alignItems: "center",
                  paddingBottom: "2%",
                  ...(item.name === "ruc" ? { paddingTop: "1%" } : {}),
                }}
                spacing={2}
                key={idx}
              >
                <Grid item xs={4}>
                  <Typography
                    style={{ fontSize: "medium", marginLeft: "4%" }}
                    variant="h6"
                  >
                    {item.translation}
                  </Typography>
                </Grid>
                <Grid item xs={8} style={{ textAlign: "center" }}>
                  {item.type === "switch" && (
                    <Switch
                      style={{ whiteSpace: "nowrap", width: "70%" }}
                      color="primary"
                      checked={data[item.name]}
                      name={item.name}
                      onChange={handleChange}
                      size="medium"
                    />
                  )}
                  {(item.type === "text" || item.type === "number") && (
                    <TextField
                      style={{ width: "70%" }}
                      fullWidth
                      value={data[item.name]}
                      variant="outlined"
                      size="small"
                      type={item.type}
                      name={item.name}
                      onChange={handleChange}
                    />
                  )}
                  {item.type === "multiselect" && (
                    <Tooltip
                      open={openSources}
                      title={data.smartSearchSources
                        .map((x) => {
                          return sources.find((s) => s.id === x)?.name;
                        })
                        .join(", ")}
                      placement="bottom"
                    >
                      <FormControl
                        size="small"
                        variant="outlined"
                        fullWidth
                        style={{ alignItems: "center" }}
                      >
                        <Select
                          style={{ width: "70%" }}
                          fullWidth
                          value={data[item.name]}
                          variant="outlined"
                          multiple
                          name={item.name}
                          onChange={handleChange}
                          onMouseEnter={() => setOpenSources(true)}
                          onMouseLeave={() => setOpenSources(false)}
                          onClick={() => setOpenSources(false)}
                        >
                          {sources.map((source, idx) => (
                            <MenuItem key={idx} value={source.id}>
                              {source.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      </FstModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.settingsChangeReducer,
  };
};

export default connect(mapStateToProps, { ...SettingsChangeActions })(
  CompanySettingModal,
);
