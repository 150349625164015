import React, { useState, useEffect } from "react";
import "./style.css";
import Grid from "@material-ui/core/Grid";
import { Controls } from "../../utils/controls";
import {
  FormStyles,
  ModalSendTenderEmail,
} from "../tender-information/style/TicketStyle";
import TextField from "@material-ui/core/TextField";
import { getSellers } from "../seller/services/sellersService";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextEditor from "../../components/editor/TextEditorDraft";
import ChipInput from "material-ui-chip-input";
import IconButton from "@material-ui/core/IconButton";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Tooltip from "@material-ui/core/Tooltip";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Chip from "@material-ui/core/Chip";
import { TenderParameter } from "../tender-information/services/tenderParameter";
import Alert from "@material-ui/lab/Alert";
import { connect } from "react-redux";
import * as SettingsChangeActions from "actions/settingsChangeActions";

const SendTenderByEmailForm = (props) => {
  const classes = FormStyles();
  let timerMessageError = null;
  const classesModal = ModalSendTenderEmail();
  const { variant } = Controls.TextField;
  const [sellers, setSellers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [messageErrorFile, setMessageErrorFile] = useState(null);

  const getSellersList = async () => {
    const listSellers = await getSellers();
    setSellers(listSellers.data);
  };

  const handleAddEmail = (element) => {
    if (isEmail(element)) {
      const list = [...emails, element.toLowerCase()];
      setEmails(list);
      props.onChange("NotificationRecipients", list);
      return true;
    } else {
      return false;
    }
  };

  const isEmail = (email) => {
    return Controls.EmailPattern.test(email);
  };

  const handleDeleteEmail = (chip, index) => {
    const arr = [...emails];
    arr.splice(index, 1);
    setEmails(arr);
    props.onChange("NotificationRecipients", arr);
  };

  const validateFileSize = (file) => {
    if (file.size > TenderParameter.MaxFileSize10MB) {
      const message = `El file ${file.name} supera el tamaño maximo permitido de 10 MB!`;
      setMessageErrorFile(message);
      timerMessageError = setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    }
    setMessageErrorFile(null);

    return true;
  };

  const initEmail = () => {
    if (props.SendEmail) {
      if (props.SendEmail.NotificationRecipients.length > 0) {
        setEmails(props.SendEmail.NotificationRecipients);
        const element = document.getElementById("iframeMail");
        if (element !== null) {
          const container = document.getElementById("container-adjunto");
          container.classList.remove("inactive-email");
          element.innerHTML = props.SendEmail.emailAdjunto;
        }
      }
    }
  };

  useEffect(() => {
    getSellersList();
    initEmail();
    return () => {
      clearTimeout(timerMessageError);
    };
  }, []);

  useEffect(() => {
    if (props.settingsChangeActive) {
      getSellersList();
      props.SettingsChangeDeactivate();
    }
  }, [props.settingsChangeActive]);

  const handleFileUpload = (event) => {
    if (!validateFileSize(event.target.files[0])) return;
    const _file = {
      FileName: event.target.files[0].name,
    };
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      _file.FileContent = e.target.result.split(",")[1];
      props.onChange("Files", _file);
    };
  };

  const viewEmailAdjunto = (event) => {
    const element = document.getElementById("iframeMail");
    console.log(element.classList);
    if (!element.classList.contains("ocultar-email")) {
      element.classList.add("ocultar-email");
      element.classList.remove("mostrar-email");
    } else {
      element.classList.remove("ocultar-email");
      element.classList.add("mostrar-email");
    }
  };

  return (
    <>
      <Grid container spacing={2} className={classesModal.content}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            variant={variant}
            id="Subject"
            label="Asunto"
            autoComplete="off"
            className={classes.InputWidth}
            value={props.SendEmail.Subject}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("Subject", value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <Autocomplete
            multiple
            size="medium"
            className="autocomplete-class"
            id="Sellers"
            value={props.SendEmail.Sellers}
            options={sellers}
            onChange={(event, newValue) => {
              props.onChange("Sellers", newValue);
            }}
            getOptionLabel={(option) =>
              `${option.lastName}, ${option.firstName}`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant={variant}
                required={emails.length < 1}
                className={classes.InputWidth}
                label="Ejecutivos"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <ChipInput
            value={emails}
            variant={variant}
            label="Emails"
            required={props.SendEmail.Sellers.length < 1}
            onBeforeAdd={handleAddEmail}
            fullWidth
            onDelete={(chip, index) => {
              handleDeleteEmail(chip, index);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextEditor
            value={props.SendEmail.Message}
            wrapperClassName={classesModal.contentTextEditor}
            onChange={(value) => {
              props.onChange("Message", value);
            }}
          />
        </Grid>

        <Grid
          id="container-adjunto"
          item
          xs={12}
          sm={12}
          md={12}
          className="inactive-email"
        >
          <span className="view-email" onClick={viewEmailAdjunto}>
            ...email adjunto
          </span>
          <div id="iframeMail" className="ocultar-email"></div>
        </Grid>

        <Grid item xs={1} sm={1} md={1}>
          <input
            accept="image/*,.pdf,.doc,.docx"
            style={{ display: "none" }}
            id="upload-button-file-send-email"
            type="file"
            onInput={(e) => {
              handleFileUpload(e);
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <Tooltip id="upload-button-file-send-email" title="Adjuntar">
            <label htmlFor="upload-button-file-send-email">
              <IconButton
                color="secondary"
                disabled={
                  props.SendEmail.Files.length >= TenderParameter.MaxFiles
                }
                aria-label="upload picture"
                component="span"
              >
                <CloudUploadIcon />
              </IconButton>
            </label>
          </Tooltip>
        </Grid>
        <Grid item xs={11} sm={11} md={11}>
          {props.SendEmail.Files.length > 0 && (
            <div className={classesModal.chipRoot}>
              {props.SendEmail.Files.map((value, index) => (
                <Chip
                  key={index}
                  icon={<AttachFileIcon />}
                  label={value.FileName}
                  onDelete={() => props.deleteFile(index)}
                  variant="outlined"
                />
              ))}
            </div>
          )}
        </Grid>
        {messageErrorFile && (
          <Grid item xs={12} sm={12} md={12}>
            <Alert severity="error">{messageErrorFile}</Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.settingsChangeReducer,
  };
};

export default connect(mapStateToProps, { ...SettingsChangeActions })(
  SendTenderByEmailForm,
);
