import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
  },
}));

const TenderGridMessage = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const cutString = (cadena) => {
    let sub = cadena;
    if (cadena.length > 120) {
      const length = 120;
      const subCadenaEnd = cadena.substring(length, cadena.length);
      const nSpace =
        subCadenaEnd.indexOf(" ") > 0 ? subCadenaEnd.indexOf(" ") : 0;
      sub = cadena.substring(0, length + nSpace) + "...";
      return addDialog(sub);
    } else {
      return <span>{sub}</span>;
    }
  };

  const addDialog = (child) => {
    return (
      <>
        <Tooltip title="Haga clic aquí para ver más detalles">
          <span className={classes.root} onClick={handleOpen}>
            {child}
          </span>
        </Tooltip>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.menssage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  };

  return <div>{cutString(props.menssage)}</div>;
};

export default TenderGridMessage;
