import axios from "axios";
import {
  newFulfilledAction,
  newPendingAction,
  newRejectedAction,
} from "../utils/asyncActionGenerator";

export const appActionTypes = {
  INITIAL_DATA: "INITIAL_DATA",
  SET_LOADING_MSG: "SET_LOADING_MSG",
};

export function setupInitialData() {
  return async (dispatch) => {
    dispatch(newPendingAction(appActionTypes.INITIAL_DATA));
    try {
      const axiosResult = await axios.post("/api/Login/InitialData");
      const { status } = axiosResult;
      if (status !== 401) {
        const currencyDefault = axiosResult.data.currencyList.filter(
          (item) => item.default
        );
        axiosResult.data.currencyDefault =
          currencyDefault.length > 0 ? currencyDefault[0] : null;
        return dispatch(
          newFulfilledAction(appActionTypes.INITIAL_DATA, axiosResult)
        );
      } else {
        throw new Error(
          `/api/Login/InitialData returned status code ${status}`
        );
      }
    } catch (error) {
      return dispatch(
        newRejectedAction(
          appActionTypes.INITIAL_DATA,
          `Error getting initial data. ${error}`
        )
      );
    }
  };
}

export function setLoadingMessage(msg) {
  return async (dispatch) => {
    return dispatch(
      newFulfilledAction(appActionTypes.SET_LOADING_MSG, { msg: msg })
    );
  };
}
