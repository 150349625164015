import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddTenderModal from "./AddTenderModal";
import DeleteConfirmation from "./DeleteConfirmation";
import {
  HeaderTableTenders,
  HeaderTableTendersDelete,
} from "./HeaderTableTenders";
import * as TenderAction from "../../../actions/manageTenderActions";
import { withRouter } from "react-router-dom";
import * as SpinnerActions from "../../../actions/spinnerActions";
import * as SendTenders from "../../../actions/sendTenderActions";
import FstModalConfirm from "../../../components/modal/FstModalConfirm";
import { getStatusGroups } from "../../../actions/statusGroupsActions";
import menuConfig from "./TenderFilterList";
import GridTenders from "./GridTander";
import moment from "moment";
import useIntlColumns from "../../../intl/utils/format-elements/columnsIntl";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SendTenderByEmail from "../../tender-information/send-tender/SendTenderByEmail";
import AddColumnGrid from "./tender-send/addColumnsGrid";
import EditViewModal from "./EditViewModal";
import { updateSettingUser } from "utils/activeDirectory";
import { isLangAR } from "utils/common";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Tenders = (props) => {
  const spinnerMessage = "Cargando...";
  const [openAddTenders, setOpenAddTenders] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openAlertDelete, setOpenAlertDelete] = useState(false);
  const [rowDeleteDelete, setRowDeleteDelete] = useState({});
  const [statusGroups, setStatusGroups] = useState(null);
  const [uniqValue, setUniqValue] = useState(moment().format());
  const [statusId, setStatusId] = useState(null);
  const [defaultStatusId, setDefaultStatusId] = useState(
    +localStorage.getItem("manageDefaultTab"),
  );
  const [initialDefaultStatusId, setInitialDefaultStatusId] = useState(
    +localStorage.getItem("manageDefaultTab"),
  );
  const [filter, setFilter] = useState(null);
  const addColumns = AddColumnGrid({ ...props, resendEmail });
  const [columns, setColumns] = useState(
    addColumns.ColumnsEmail(HeaderTableTenders),
  );
  const { getHeader } = useIntlColumns(
    "TenderGestion",
    "app.columnsGest",
    columns,
  );
  const [openSendTendedByEmail, setOpenSendTendedByEmail] = useState(false);
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [tender, setTender] = useState(null);
  const [sendEmail, setSendEmail] = useState({});
  const [editMail, setEditMail] = useState(false);
  const [openEditView, setOpenEditView] = useState(false);

  const MessageToaster = {
    tenderSendByEmail: "La licitacion fue enviada por mail",
    saveManageTender: "Los cambios han sido confirmados",
  };

  const addStatusGroups = async () => {
    const response = await getStatusGroups();
    const groupsItems = response.statusGroups.map((item) => {
      item.href = menuConfig[0].items[item.statusId].href;
      item.icon = menuConfig[0].items[item.statusId].icon;
      item.title = item.statusDesc;
      return item;
    });
    setStatusGroups(groupsItems);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      loadStatus();
      let id;
      // If no statusId is set in storage, set the default statusId
      if (
        localStorage.getItem("manageDefaultTab") == null ||
        localStorage.getItem("manageDefaultTab") == "null"
      ) {
        localStorage.setItem("manageDefaultTab", "0");
        setDefaultStatusId(0);
        setInitialDefaultStatusId(0);
        id = 0; // Default starting GL tab
      } else {
        id = +defaultStatusId;
      }

      if (
        props.location.state &&
        props.location.state.gestion &&
        props.filter
      ) {
        id = props.filter.filter.statusId;
        setFilter(props.filter);
      }
      setStatusId(id);
      defaultData(id);
    });
    return () => {
      props.clearTendersList();
      clearTimeout(time);
    };
  }, []);

  const AddTender = () => {
    setOpenAddTenders(true);
  };

  const deleteTenderFunc = (selectedrecords) => {
    if (selectedrecords === undefined) {
      setOpenAlertDelete(true);
    } else {
      setOpenConfirmDelete(true);
      setRowDeleteDelete(selectedrecords);
    }
  };

  const edit = (selectedrecords) => {
    if (selectedrecords === undefined) {
      setOpenAlertDelete(true);
    } else {
      props.history.push(
        "/gestion-informacion/" +
          encodeURIComponent(selectedrecords.tenderNumber),
      );
    }
  };

  const [firstRender, setFirstRender] = useState(true);

  const func = async (pag, filter) => {
    filter.statusId = statusId;
    props.SpinnerShow(spinnerMessage);
    await props.GetSendTenders();
    pag.orderBy = firstRender && !pag.orderBy ? "publishedDate" : pag.orderBy;
    pag.OrderAsc = firstRender && !pag.orderDirection ? false : pag.OrderAsc;
    await props.getManagedTendersList(pag, filter);
    props.SpinnerHide();
    setFirstRender(false);
  };

  useEffect(() => {
    setFirstRender(true);
  }, [statusId]);

  const contextMenuIFnc = (value) => {
    const {
      rowInfo: { rowData },
      item: { id },
    } = value;
    if (id === "send-mail") {
      setTender(rowData);
      setOpenSendTendedByEmail(true);
    }
    if (id === "open-link") {
      if (rowData.link) window.open(rowData.link, "_blank");
    }
  };

  const sendMail = (selectedrecords) => {
    if (selectedrecords !== undefined) {
      setTender(selectedrecords);
      setOpenSendTendedByEmail(true);
    }
  };

  const getAddColumns = () => {
    console.log(columns);
    return columns;
  };

  const dataGrid = {
    Getdata: func,
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
    },
    allowSorting: true,
    allowPaging: true,
    take: 15,
    height: props.height,
    Filter: {
      statusId: statusId,
    },
    Delete: deleteTenderFunc,
    Edit: edit,
    Add: AddTender,
    Email: sendMail,
    ExportFileName: "Licitaciones.xlsx",
    exportFunc: props.getManagedTendersListExport,
  };

  const dataGridTenders = {
    ...dataGrid,
    Header: getHeader,
    rowDoubleClickAction: edit,
    actionsRows: [
      {
        text: "Enviar licitación",
        target: ".e-content",
        id: "send-mail",
        iconCss: "e-icons e-send-1",
      },
    ].concat(
      isLangAR()
        ? [
            {
              text: "Link al portal / licitación",
              target: ".e-content",
              id: "open-link",
              iconCss: "e-icons e-open-link e-icon-custom",
            },
          ]
        : [],
    ),
    contextMenuIFnc: contextMenuIFnc,
    EditView: () => {
      setOpenEditView(true);
    },
    ToolBar: {
      Add: true,
      AddTitle: "Agregar Licitaciones",
      Delete: true,
      DeleteTitle: "Eliminar",
      Edit: true,
      EditTitle: "Gestionar",
      Export: true,
      Search: true,
      Email: true,
      EmailTitle: "Enviar Licitación",
      Refresh: true,
      EditView: true,
    },
    idGrid: "TenderGestion",
  };

  const dataGridDelete = {
    ...dataGrid,
    idGrid: "TenderGestionEliminadas",
    Header: HeaderTableTendersDelete,
    ToolBar: {
      Export: true,
      Search: true,
    },
  };

  const handleCloseConfirmDelete = (value) => {
    setOpenConfirmDelete(false);
    if (value) {
      loadData();
      setUniqValue(moment().format());
    }
  };

  const closeModalAdd = (value) => {
    setOpenAddTenders(false);
    if (value) {
      props.getManagedTendersListDefault();
      loadData();
      setUniqValue(moment().format());
    }
  };

  const loadData = () => {
    addStatusGroups();
  };

  const handleCloseAlertDelete = () => {
    setOpenAlertDelete(false);
  };

  const loadStatus = () => {
    addStatusGroups();
  };

  const handleSelected = (item) => {
    defaultData(item.statusId);
  };

  const defaultData = async (_statusId) => {
    props.SpinnerShow(spinnerMessage);
    await props.GetSendTenders();
    await props.getManagedTendersListDefault();
    const status = _statusId !== 99 ? _statusId : -1;
    setStatusId(status);
    setUniqValue(moment().format());
    props.SpinnerHide();
  };

  const handCloseSendByEmailTender = (value) => {
    if (value) {
      showToaster(MessageToaster.tenderSendByEmail);
    }
    setOpenSendTendedByEmail(false);
    setEditMail(false);
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    defaultData(statusId);
    setTimeout(() => {
      setOpenToaster(false);
    }, 3000);
  };

  function resendEmail(tender, sendEmail) {
    setEditMail(true);
    setSendEmail(sendEmail);
    setTender(tender);
    setOpenSendTendedByEmail(true);
  }

  const handleSaveEditView = () => {
    setOpenEditView(false);
    let newStatus = statusGroups.find(
      (item) => item.statusId === defaultStatusId,
    );
    handleSelected(newStatus);
    setInitialDefaultStatusId(newStatus.statusId);
    localStorage.setItem("manageDefaultTab", `${+newStatus.statusId}`);
    updateSettingUser();
  };

  const handleCloseEditView = () => {
    setOpenEditView(false);
    setDefaultStatusId(initialDefaultStatusId);
  };

  return (
    <>
      <AddTenderModal
        openAddTenders={openAddTenders}
        handleCloseAddTenders={closeModalAdd}
      />

      <DeleteConfirmation
        openConfirmDelete={openConfirmDelete}
        handleCloseConfirmDelete={handleCloseConfirmDelete}
        rowDataToDelete={rowDeleteDelete}
      />

      <FstModalConfirm
        onlyOK={true}
        setting={{ BtnNameSave: "OK" }}
        title="Atención:"
        close={() => {
          handleCloseAlertDelete();
        }}
        open={openAlertDelete}
        message="No hay registros seleccionados "
        success={() => handleCloseAlertDelete()}
      />

      <EditViewModal
        openEditView={openEditView}
        handleClose={handleCloseEditView}
        handleSave={handleSaveEditView}
        statusId={defaultStatusId}
        statusGroups={statusGroups}
        setStatusId={setDefaultStatusId}
      />

      {statusId >= 0 && (
        <GridTenders
          statusGroups={statusGroups}
          handleSelected={handleSelected}
          dataGrid={dataGridTenders}
          uniqValue={uniqValue}
          selectId={statusId === -1 ? 99 : statusId}
          reloadDefault={props.reloadDefault}
          result={props.result}
          height={props.height}
        />
      )}
      {statusId < 0 && (
        <GridTenders
          statusGroups={statusGroups}
          handleSelected={handleSelected}
          dataGrid={dataGridDelete}
          uniqValue={uniqValue}
          selectId={statusId === -1 ? 99 : statusId}
          reloadDefault={props.reloadDefault}
          result={props.result}
          height={props.height}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
      <SendTenderByEmail
        open={openSendTendedByEmail}
        tender={tender}
        SendEmail={sendEmail}
        edit={editMail}
        handleClose={(value) => {
          handCloseSendByEmailTender(value);
        }}
      />
    </>
  );
};

const mapStateToProps = (reducers) => {
  return reducers.tenderReducer;
};
export default connect(mapStateToProps, {
  ...TenderAction,
  ...SpinnerActions,
  ...SendTenders,
})(withRouter(Tenders));
