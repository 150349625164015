import React, { useState, useEffect } from "react";
import Alert from "@material-ui/lab/Alert";
import Grid from "@material-ui/core/Grid";
import { downloadBase64File } from "../../utils/FileHandleService";
import { makeStyles } from "@material-ui/core/styles";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootHeader: {
    padding: 10,
  },
  tipograghy: {
    margin: 0,
    fontWeight: 400,
    fontSize: "1.1rem",
  },
}));

const AttachFiles = (props) => {
  const classes = useStyles();
  let timerMessageError = null;
  const [messageErrorFile, setMessageErrorFile] = useState(null);
  const [files, setFiles] = useState(null);
  const [dense, setDense] = React.useState(false);

  const downloadFile = (data) => {
    downloadBase64File(data.FileName, data.Content);
  };

  const addFiles = () => {
    document.getElementById("upload-button-file").click();
  };

  const deleteFile = (index) => {
    const aux = files;
    aux.splice(index, 1);
    setFiles(aux);
    props.onChange(aux);
  };

  const validateFileSize = (file) => {
    if (file.size > props.MaxFileSize) {
      const message = `El file ${file.name} supera el tamaño maximo permitido de 10 MB!`;
      setMessageErrorFile(message);
      timerMessageError = setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    }
    setMessageErrorFile(null);

    return true;
  };

  const loadDefautFiles = () => {
    setFiles(props.files);
    props.onChange(props.files);
  };

  useEffect(() => {
    loadDefautFiles();
    return () => {
      clearTimeout(timerMessageError);
    };
  }, []);

  const handleFileUpload = (event) => {
    if (!validateFileSize(event.target.files[0])) return;
    const _file = {
      FileName: event.target.files[0].name,
    };
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      _file.Content = e.target.result.split(",")[1];
      const aux = files;
      aux.push(_file);
      setFiles(aux);
      props.onChange(aux);
    };
  };

  return (
    <>
      <div>
        <input
          accept="image/*,.pdf,.doc,.docx"
          style={{ display: "none" }}
          id="upload-button-file"
          type="file"
          hidden
          onInput={(e) => {
            handleFileUpload(e);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
        />

        {messageErrorFile && (
          <Grid item xs={12} sm={12} md={12}>
            <Alert severity="error">{messageErrorFile}</Alert>
          </Grid>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          {files && (
            <Card>
              <CardHeader
                className={classes.rootHeader}
                title={
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="h6"
                    className={classes.tipograghy}
                  >
                    Listado de Archivos
                  </Typography>
                }
              ></CardHeader>
              <Divider />
              <CardContent>
                <List dense={dense}>
                  {files.length === 0 && (
                    <ListItem>
                      <ListItemText primary="No tiene archivos adjuntos " />
                    </ListItem>
                  )}
                  {files.map((item, index) => (
                    <>
                      <ListItem>
                        <ListItemText primary={item.FileName} />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            size="small"
                            aria-label="download"
                            onClick={() => {
                              downloadFile(item);
                            }}
                          >
                            <GetAppIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            size="small"
                            aria-label="delete"
                            onClick={() => {
                              deleteFile(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider />
                    </>
                  ))}
                </List>
              </CardContent>
              <Divider />
              <CardActions>
                <Button
                  color="primary"
                  variant="text"
                  onClick={() => {
                    addFiles();
                  }}
                  disabled={files.length >= props.MaxFiles}
                >
                  Adjuntar Archivo
                </Button>
                <Typography variant="caption">
                  {`Máximo ${props.MaxFiles} archivos de hasta 10MB cada uno.`}{" "}
                  {/* Remember to change this value when you change the file size */}
                </Typography>
              </CardActions>
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AttachFiles;
