import React, { Component } from "react";
import { CircularProgress, Paper, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { isMsalUserLoggedIn, selectAccount } from "../utils/activeDirectory";
import { setLoadingMessage } from "../actions/appActions";

const mapStateToProps = (state, ownProps) => {
  const { app, user } = state;

  return {
    ...ownProps,
    loginMessageError: app.loginMessageError,
    finishedLoadingInitialData: app.finishedLoadingInitialData,
    finishedUserLogin: user.finishedUserLogin,
  };
};

class Loading extends Component {
  componentDidMount() {
    this.handleLogout();
  }
  componentDidUpdate() {
    this.handleLogout();
  }

  handleLogout() {
    const { loginMessageError, dispatch } = this.props;
    const loginError = !!loginMessageError && loginMessageError.length > 0;
    if (loginError) {
      setTimeout(async () => {
        await dispatch(setLoadingMessage(""));
        selectAccount().then();
      }, 3000);
    }
  }

  render() {
    const errorMsg = this.props.msg;
    const min = 1;
    const max = 10;
    const imgName = `background_${
      Math.floor(Math.random() * (+max - +min)) + +min
    }.jpg`;
    const {
      loginMessageError,
      finishedUserLogin,
      checkFinishedLoading,
      finishedLoadingInitialData,
    } = this.props;
    const loginError = !!loginMessageError && loginMessageError.length > 0;
    const showLoading =
      !isMsalUserLoggedIn() ||
      loginError ||
      (!!checkFinishedLoading &&
        (!finishedUserLogin || !finishedLoadingInitialData));

    if (showLoading) {
      return (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            backgroundImage: `url("assets/${imgName}")`,
            backgroundSize: "100% 100%",
          }}
        >
          <Grid
            container
            justify={"center"}
            alignItems={"center"}
            style={{ minHeight: "100vh" }}
          >
            <Grid item xs={3} style={{ minWidth: "20vw", textAlign: "center" }}>
              <Paper
                style={{
                  display: "inline-block",
                  padding: "0.7em",
                  textAlign: "center",
                  backgroundColor: "rgba(255,255,255,0.8)",
                }}
              >
                <h3>Falconsoft Tenders</h3>
                <CircularProgress size={"3rem"} color={"primary"} />
                <p>{loginError ? loginMessageError : `Cargando...`}</p>
                {!!errorMsg && errorMsg.length > 0 && <p>{errorMsg}</p>}
              </Paper>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default connect(mapStateToProps)(Loading);
