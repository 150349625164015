import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Controls } from "../../utils/controls";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { FormStyles } from "./style/style";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { GetPenaltyReasons } from "./service/penaltyService";

//const filter = createFilterOptions();

const ManagePenaltyForm = (props) => {
  const classes = FormStyles();
  const { variant } = Controls.TextField;
  const [reasonList, setReasonList] = useState([]);

  const getReasonList = async () => {
    const { data } = await GetPenaltyReasons();
    setReasonList(data);
  };

  useEffect(() => {
    getReasonList();
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant={variant}
            id="customerName"
            label="Cliente"
            InputProps={{
              autoComplete: "new-password",
            }}
            className={classes.InputWidth}
            value={props.Penalty.CustomerName}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("CustomerName", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant={variant}
            id="code"
            label="Cod. Cliente"
            InputProps={{
              autoComplete: "new-password",
            }}
            className={classes.InputWidth}
            value={props.Penalty.CustomerCode}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("CustomerCode", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant={variant}
            id="saleOrder"
            label="SO"
            InputProps={{
              autoComplete: "new-password",
            }}
            className={classes.InputWidth}
            value={props.Penalty.SaleOrder}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("SaleOrder", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant={variant}
            id="PurchaseOrderCode"
            label="Orden de Compra"
            InputProps={{
              autoComplete: "new-password",
            }}
            className={classes.InputWidth}
            value={props.Penalty.PurchaseOrderCode}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("PurchaseOrderCode", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant={variant}
            id="invoiceNumber"
            label="Número de Factura"
            InputProps={{
              autoComplete: "new-password",
            }}
            className={classes.InputWidth}
            value={props.Penalty.InvoiceNumber}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("InvoiceNumber", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
              disableToolbar
              required
              autoOk={true}
              inputVariant={variant}
              format="dd/MM/yyyy"
              autoComplete="off"
              id="InvoiceDate"
              label="Fecha de Factura"
              className={classes.KeyboardDatePicker}
              inputValue={props.Penalty.InvoiceDate}
              onChange={(event, newValue) => {
                props.onChange("InvoiceDate", newValue);
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <CurrencyTextField
            variant={variant}
            label="Valor de Factura"
            className={classes.InputWidth}
            currencySymbol="$"
            value={props.Penalty.InvoiceAmount}
            autoComplete="off"
            decimalCharacter="."
            digitGroupSeparator=","
            onChange={(e, value) => props.onChange("InvoiceAmount", value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <CurrencyTextField
            variant={variant}
            label="Valor de Penalidad"
            className={classes.InputWidth}
            currencySymbol="$"
            value={props.Penalty.PenaltyAmount}
            autoComplete="off"
            decimalCharacter="."
            required
            digitGroupSeparator=","
            onChange={(e, value) => props.onChange("PenaltyAmount", value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant={variant}
            id="responsible"
            label="Responsable"
            InputProps={{
              autoComplete: "new-password",
            }}
            className={classes.InputWidth}
            value={props.Penalty.Responsible}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("Responsible", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            variant={variant}
            id="caseNumber"
            label="Case # SF"
            InputProps={{
              autoComplete: "new-password",
            }}
            className={classes.InputWidth}
            value={props.Penalty.Case}
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("CaseNumber", value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Autocomplete
            id="reason"
            options={reasonList}
            openOnFocus
            InputProps={{
              autoComplete: "new-password",
            }}
            getOptionLabel={(option) => option.description}
            value={props.Penalty.Reason}
            onChange={(event, newValue) => {
              props.onChange("Reason", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                required
                label="Motivo"
                variant={variant}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.Penalty.isTender}
                onChange={(e) => {
                  props.onChange("isTender", e.target.checked);
                }}
                name="isTender"
                color="primary"
              />
            }
            label="Licitacion"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            variant={variant}
            id="observations"
            label="Observaciones"
            style={{ width: "100%" }}
            value={props.Penalty.Observations}
            autoComplete="off"
            multiline
            onChange={(event) => {
              const { value } = event.target;
              props.onChange("Observations", value);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ManagePenaltyForm;
