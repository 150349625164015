import React from "react";
import { connect } from "react-redux";
import * as ModalAction from "../../actions/modalAction";
import FstModalDetail from "../../views/tender-information/utils-info/FstModalDetail";

const FstContainerGeneral = (props) => {
  return <FstModalDetail id={props.idModal} />;
};

const mapStateToProps = (state, props) => {
  const { modalReducer } = state;
  const { idModal } = modalReducer;
  return { ...state, idModal };
};

export default connect(mapStateToProps, { ...ModalAction })(
  FstContainerGeneral,
);
