import React, { useContext, useState } from "react";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import SaveSharpIcon from "@material-ui/icons/SaveSharp";
import CloseIcon from "@material-ui/icons/Close";
import SendSharpIcon from "@material-ui/icons/SendSharp";
import GetAppIcon from "@material-ui/icons/GetApp";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import { ManageTenderData } from "./services/tenderService";
import FstModalConfirm from "../../components/modal/FstModalConfirm";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SendTenderByEmail from "./send-tender/SendTenderByEmail";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { DeletePenalties } from "views/penalties/service/penaltyService";
import { ManagePenalty } from "views/penalties/service/penaltyService";
import useHandleDataByLang from "intl/utils/hooks/useHandleDataByLang";
import { langContext } from "intl/context/langContext";
import {
  getNewWorkbook,
  saveNewWorkbook,
  flattenSheetDataFromJson,
} from "utils/excelExportService";
import { AllDetailsHeader } from "./all-details/AllDetailsHeader";
import { listTags } from "views/tag/services/tagService";
import { useIntl } from "react-intl";
import { formatShortDate } from "utils/common";
import {
  fillAllDetailsSheet,
  getAllDetailsData,
  getAllDetailsFormattedInitialData,
} from "./all-details/AllDetailsExcelService";
import { HeaderTable } from "views/legal-document/LegalDocumentHeaderTable";
import { GetPenaltyList } from "views/penalties/service/penaltyService";
import { SanctionsHeader } from "./Sanctions";
import { baseChecklistData, ChecklistHeader } from "./Checklist";
import { GetPenaltyReasons } from "views/penalties/service/penaltyService";
import { TenderPanel } from "types";
import { flattenSheetDataFromJsonFieldBased } from "utils/excelExportService";

const useStyles = makeStyles({
  root: {
    display: "block",
    padding: 13,
  },
  headerName: {
    display: "inline",
    fontSize: "1.10rem",
  },
  positionButtons: {
    float: "right",
    marginTop: -7,
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function HeaderActions({
  menu,
  menuCompleteList,
  tender,
  tenderData,
  responseAction,
  assignments,
  reloadPage,
  iniSave,
  files,
  refreshData,
}) {
  const MessageToaster = {
    tenderSendByEmail: "La licitacion fue enviada por mail",
    saveManageTender: "Los cambios han sido confirmados",
  };
  const classes = useStyles();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const appInsights = useAppInsightsContext();
  const intl = useIntl();

  const [openSendTendedByEmail, setOpenSendTendedByEmail] = useState(false);
  const descartChanges = () => {
    setOpenConfirm(true);
  };

  const executeDescartChanges = () => {
    refreshData();
    setTimeout(() => {
      reloadPage();
    }, 350);
    setOpenConfirm(false);
  };

  const handCloseSendByEmailTender = (value) => {
    if (value) {
      showToaster(MessageToaster.tenderSendByEmail);
    }
    setOpenSendTendedByEmail(false);
  };

  const handleUpdateTenderPenalties = async (tenderId, penalties) => {
    if (!penalties) return;
    // get penalties to delete
    let penaltiesToDelete = penalties.filter((item) => item.toDelete);
    // get penalties to update
    let penaltiesToUpdate = penalties.filter((item) => !item.toDelete);
    // remove "toDelete" key from penaltiesToDelete
    penaltiesToDelete.forEach((item) => {
      delete item.toDelete;
    });
    //set tenderId to all penalties to update
    penaltiesToUpdate.forEach((item) => {
      if (!item.tenderId) item.TenderId = tenderId;
    });

    await ManagePenalty(penaltiesToUpdate);
    await DeletePenalties(penaltiesToDelete);
  };

  const trackSaveManageTender = useTrackEvent(
    appInsights,
    "GL: Save managed modified tender",
    menu,
  );
  const manageTender = async () => {
    iniSave();
    const manageDetails = tender.manageDetails.map((item) => {
      item.estimatedTotal =
        item.estimatedTotal !== null ? +item.estimatedTotal : null;
      return item;
    });
    const documentsIds = tender.legalDocuments.length
      ? tender.legalDocuments.map((item) => item.legalDocumentId)
      : [];
    const request = {
      TenderNumber: tender.tenderNumber,
      ManageFiles: files,
      SellerIds: assignments.sellers.map((item) => item.sellerId),
      TagIds: assignments.tags.map((item) => item.tagId),
      CategoryIds: assignments.categories.map((item) => item.categoryId),
      LegalDocumentIds: documentsIds,
      ManageComments: tender.manageComments,
      ManageDetails: manageDetails.map((item) => {
        const obj = {
          manageId: item.manageId,
          manageDetailId: item.manageDetailId,
          tenderDetailId: item.tenderDetailId,
          itemNumber: item.itemNumber,
          companyId: item.companyId,
          estimatedTotal: item.estimatedTotal,
          offered: item.offered,
          comments: item.comments,
          createdByUserId: item.createdByUserId,
          creationDate: item.creationDate,
          searchFilterResult: item.searchFilterResult,
          productId: item.productId,
          deliveryDate: item.deliveryDate,
          tagIds: item.tagIds,
          isPrimary: item.isPrimary,
        };
        if (!item.manageDetailId) delete obj.manageDetailId;
        return obj;
      }),
      ManageInternalStatusId: assignments.internalStatus
        ? assignments.internalStatus.manageInternalStatusId
        : null,
      ManageExtensions: tender.manageExtensions,
    };
    await ManageTenderData(request);
    await handleUpdateTenderPenalties(tender.tenderId, tender.penalties);
    showToaster(MessageToaster.saveManageTender);
    trackSaveManageTender({
      manageDetails: manageDetails,
      documentsIds: documentsIds,
      request: request,
    });
    responseAction();
    refreshData();
    setTimeout(() => {
      reloadPage();
    }, 350);
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 3000);
  };

  // EXPORT TO EXCEL LOGIC //
  const handleData = useHandleDataByLang("TenderContainer", "Tab", tenderData);
  const langChange = useContext(langContext);

  const parseColumnsData = (columns, item) => {
    let obj = {};
    columns.forEach((column) => {
      if (column.render) {
        if (column.render(item[column.key], item)?.type) {
          obj[column.label] = item[column.key]?.toString();
        } else {
          obj[column.label] = column.render(item[column.key], item)?.toString();
        }
      } else {
        obj[column.label] = item[column.key]?.toString();
      }
    });
    return obj;
  };

  const handleExportCompleteInfo = async () => {
    // Export Portal Info tabs
    let portalInfoMenus = menuCompleteList
      .find((item) => item.subheader === "Info del Portal")
      ?.items.filter((item) => item.showExportAll);
    let workbook = await getNewWorkbook();

    portalInfoMenus.forEach((specificMenu, sheetIndex) => {
      const { columnsExport, exportData } = getExcelData(specificMenu);
      populateSheet(
        workbook,
        sheetIndex,
        specificMenu.title,
        exportData,
        columnsExport,
      );
    });

    // Export Manage tabs
    let manageMenus = menuCompleteList
      .find((item) => item.subheader === "Gestión")
      ?.items.filter((item) => item.showExportAll);
    let manageExportFunctions = {
      [TenderPanel.ITEMS_ALL]: exportAllDetails,
      [TenderPanel.TICKET]: exportLegalDocuments,
      [TenderPanel.SANCTIONS]: exportSanctions,
      [TenderPanel.CHECKLIST]: exportChecklist,
    };
    for (let i = 0; i < manageMenus.length; i++) {
      const specificMenu = manageMenus[i];
      await manageExportFunctions[specificMenu.title](
        workbook,
        portalInfoMenus.length + i,
      );
    }

    // Save the workbook
    await saveExcel(workbook, "Info Gestión");
  };

  const getExcelData = (selectedMenu = menu) => {
    const result = handleData.getDataByCountry(selectedMenu.idLang);
    let columns = [];
    selectedMenu.langForm.forEach((item) => {
      // put all columns in one array, from all elements in elements
      if (!langChange.configForm[item]) return;
      const config = langChange.configForm[item].elements;
      if (!config) return;
      config.forEach((item) => {
        if (item.props.columns) {
          columns = [...columns, ...item.props.columns];
        }
      });
    });

    // Parse columns data to get the value of each column, returns an object with the column label as key and the value as value
    let finalData = [];
    if (Array.isArray(result)) {
      result.forEach((item) => {
        finalData.push(parseColumnsData(columns, item));
      });
    } else {
      finalData.push(parseColumnsData(columns, result));
    }

    // Map columns and data to get the export data
    let columnsExport = columns.map((item) => item.label);
    let exportData = flattenSheetDataFromJson(finalData, columnsExport);

    return { columnsExport, exportData };
  };

  const handleExportIndividualTabInfo = async (
    selectedMenu = menu,
    sheetIndex = 0,
  ) => {
    if (!selectedMenu.showExport) return;
    const { columnsExport, exportData } = getExcelData(selectedMenu);

    let workbook = await getNewWorkbook();
    populateSheet(
      workbook,
      sheetIndex,
      selectedMenu.title,
      exportData,
      columnsExport,
    );

    return workbook;
  };

  const populateSheet = (workbook, sheetNumber, sheetName, data, columns) => {
    // Workbook starts with 1 sheet, so if sheetNumber is > 0, we need to add a new sheet
    if (sheetNumber > 0) {
      workbook.addSheet(sheetName);
    }
    const sheet1 = workbook.sheet(sheetNumber);
    sheet1.name(sheetName);
    // Style header row
    const endColumn = String.fromCharCode(64 + columns.length);
    sheet1.range(`A1:${endColumn}1`).style("bold", true);
    // Write items data
    sheet1.cell("A1").value(data);

    // Set column widths (from list of abecedary letters of columns length)
    const letters = Array.from({ length: columns.length }, (_, i) =>
      String.fromCharCode(65 + i),
    );
    // Set width to each column
    letters.forEach((letter) => sheet1.column(letter).width(25));
  };

  const saveExcel = async (workbook, fileTitle) => {
    const fileName = `${fileTitle} - ${tenderData.tenderNumber}.xlsx`;
    return await saveNewWorkbook(workbook, fileName);
  };

  ////////////////////////////////////////////////////

  const exportAllDetails = async (workbook, sheetNumber) => {
    if (sheetNumber > 0) {
      workbook.addSheet("-");
    }
    let tagsList = await listTags({
      orderBy: "",
      pageSize: 1000,
      search: "",
      PageNumber: 1,
      OrderAsc: false,
    });
    let allDetailsHeader = AllDetailsHeader(
      intl,
      () => {},
      tender.modalityType,
      tagsList.data,
    );
    let dataToExportFormatted = getAllDetailsFormattedInitialData(
      tender.manageDetails,
      tagsList.data,
    );

    const { exportHeader, exportData, exportItemsData, exportTenderData } =
      getAllDetailsData(
        intl,
        tenderData,
        allDetailsHeader,
        dataToExportFormatted,
      );

    fillAllDetailsSheet(
      workbook,
      intl,
      sheetNumber,
      exportHeader,
      exportData,
      exportItemsData,
      exportTenderData,
    );
  };

  const exportLegalDocuments = async (workbook, sheetNumber) => {
    let formattedData = tender.legalDocuments.map((item) => {
      return {
        ...item,
        endDate: item.endDate ? formatShortDate(item.endDate) : "",
        active: item.active ? "Si" : "No",
      };
    });

    let exportHeader = HeaderTable(intl)
      .filter((item) => item.headerText && !item.hideToExport)
      .map((item) => item.headerText);
    let exportData = flattenSheetDataFromJson(formattedData, exportHeader);

    populateSheet(
      workbook,
      sheetNumber,
      intl.formatMessage({
        id: "etq_CartaFianza",
        defaultMessage: "Carta Fianza",
      }),
      exportData,
      exportHeader,
    );
  };

  const exportSanctions = async (workbook, sheetNumber) => {
    let penalties = await GetPenaltyList();
    const data = penalties.data.map((item) => {
      return { ...item, ...JSON.parse(item.data) };
    });
    // filter items that have TenderId null or are about to be added
    const penaltiesFilteredByTenderIds = data.filter((item) => {
      return item.tenderId === tender.tenderId;
    });

    let penaltyReasons = await GetPenaltyReasons();
    let penaltyReasonsList = penaltyReasons.data.map((item) => {
      return { id: item.penaltyReasonId, name: item.description };
    });

    let header = SanctionsHeader(penaltyReasonsList)
      .filter((item) => !item.hideToExport)
      .map((item) => {
        return { field: item.field, headerText: item.headerText };
      });

    // set Description to penaltyReasonId so it can be exported with the display value and not the id
    let formattedData = penaltiesFilteredByTenderIds.map((item) => {
      return {
        ...item,
        agreedDeliveryDate: item.agreedDeliveryDate
          ? formatShortDate(item.agreedDeliveryDate)
          : "",
        actualDeliveryDate: item.actualDeliveryDate
          ? formatShortDate(item.actualDeliveryDate)
          : "",
        penaltyReasonId: item.penaltyReasonDesc,
      };
    });
    let exportData = flattenSheetDataFromJsonFieldBased(formattedData, header);
    populateSheet(
      workbook,
      sheetNumber,
      "Sanciones",
      exportData,
      header.map((item) => item.headerText),
    );
  };

  const exportChecklist = async (workbook, sheetNumber) => {
    let header = ChecklistHeader.filter((item) => !item.hideToExport).map(
      (item) => item.headerText,
    );
    let data = JSON.parse(JSON.parse(tenderData?.extension)?.Info);
    let tenderDetailExtension =
      tenderData.tenderDetails.length > 0
        ? JSON.parse(tenderData.tenderDetails[0].extension)
        : {};

    let manageExtensions = (tender.manageExtensions || []).filter(
      (manageExtension) => {
        return (
          (manageExtension.manageId === tender.manageId &&
            manageExtension.manageExtensionId != null) ||
          (manageExtension.ManageId === tender.manageId &&
            manageExtension.ManageExtensionId != null)
        );
      },
    );
    if (manageExtensions.length > 0) {
      let extensionData = manageExtensions.map((item) => {
        return {
          ...item,
          manageExtensionId: item.manageExtensionId || item.ManageExtensionId,
          manageId: item.manageId || item.ManageId,
          listData: JSON.parse(item.data || item.Data),
        };
      });
      manageExtensions = extensionData[extensionData.length - 1].listData;
    } else {
      manageExtensions = baseChecklistData(data, tenderDetailExtension);
    }
    manageExtensions.forEach((item) => {
      delete item["id"];
    });
    let dataToExport = manageExtensions.map((item) => {
      return {
        ...item,
        status: item.status ? "Si" : "No",
      };
    });

    let exportData = flattenSheetDataFromJson(dataToExport, header);
    populateSheet(workbook, sheetNumber, "Checklist", exportData, header);
  };

  ////////////////////////////////////////////////////
  return (
    <>
      <CardActions disableSpacing className={classes.root}>
        <Typography variant="h6" className={classes.headerName}>
          {menu.titlePanel}
        </Typography>

        {menu.showExportAll && (
          <span className={classes.positionButtons}>
            <Tooltip
              id="exportCompleteInfo"
              title="Exportar información completa"
            >
              <IconButton
                aria-label="export"
                color="primary"
                onClick={handleExportCompleteInfo}
              >
                <SaveAltIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}
        {menu.showExport && (
          <span className={classes.positionButtons}>
            <Tooltip id="export" title="Exportar">
              <IconButton
                aria-label="export"
                color="primary"
                onClick={() =>
                  handleExportIndividualTabInfo().then((workbook) => {
                    saveExcel(workbook, menu.title);
                  })
                }
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}

        {menu.showActionPanel && (
          <span className={classes.positionButtons}>
            <Tooltip id="save-button" title="Guardar cambios">
              <IconButton
                onClick={manageTender}
                color="primary"
                disabled={!tender.isChange && !tender.isChangeMangeList}
              >
                <SaveSharpIcon />
              </IconButton>
            </Tooltip>
            <Tooltip id="close-button" title="Descartar cambios">
              <IconButton
                onClick={descartChanges}
                color="secondary"
                disabled={!tender.isChange && !tender.isChangeMangeList}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <Tooltip id="send-button" color="primary" title="Enviar Licitación">
              <IconButton
                onClick={() => {
                  setOpenSendTendedByEmail(true);
                }}
              >
                <SendSharpIcon />
              </IconButton>
            </Tooltip>
          </span>
        )}
      </CardActions>

      <FstModalConfirm
        title="Descartar Cambios"
        close={() => {
          setOpenConfirm(false);
        }}
        open={openConfirm}
        message={"¿Esta seguro que desea descartar los cambios?"}
        success={executeDescartChanges}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
      <SendTenderByEmail
        open={openSendTendedByEmail}
        tender={tender}
        handleClose={(value) => {
          handCloseSendByEmailTender(value);
        }}
      />
    </>
  );
}

export default HeaderActions;
