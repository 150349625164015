import React from "react";
import { isAwardedContract, formatShortDate, isLangCR } from "utils/common";
import CustomDatePicker from "./CustomDatePicker";
import ProductsAutoComplete from "./ProductsAutoComplete";
import { MultiSelect } from "@syncfusion/ej2-dropdowns";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import { getLang } from "utils/common";
import { isLangPE } from "utils/common";

export const AllDetailsHeader = (
  intl,
  onChangeProduct,
  modalityType,
  availableTags,
) => {
  let elem;
  let multiSelectObj;
  return [
    {
      headerText: "Acciones",
      width: "80px",
      hideToExport: true,
      commands: [
        {
          type: "Edit",
          buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
        },

        {
          type: "Save",
          buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
    },
    {
      field: "manageDetailId",
      headerText: "manageDetailId",
      visible: false,
      isPrimaryKey: true,
      hideToExport: true,
      showInColumnChooser: false,
    },
    {
      field: "itemNumber",
      headerText: "#",
      width: "60",
      textAlign: "Center",
      //validationRules: orderidRules,
    },
    {
      field: "description",
      headerText: "Descripción",
      width: "150",
      allowEditing: false,
      textAlign: "Left",
      clipMode: "EllipsisWithTooltip",
    },
    {
      field: "code",
      headerText: intl.formatMessage({
        id: "tabL.Codigo_Cubso",
        defaultMessage: "Código CUBSO",
      }),
      allowEditing: false,
      width: "170",
      textAlign: "Left",
      clipMode: "EllipsisWithTooltip",
    },
    {
      field: "qty",
      headerText: "Cantidad",
      allowEditing: false,
      width: "100",
      textAlign: "Center",
      template: (props) => {
        return <span>{props.qty?.toLocaleString(getLang(), {})}</span>;
      },
    },
    {
      field: "uom",
      headerText: "Unidad",
      allowEditing: false,
      width: "120",
      textAlign: "Center",
      ClipMode: "EllipsisWithTooltip",
    },
    {
      field: "isPrimary",
      headerText: "Alternativo",
      allowEditing: false,
      width: "100",
      textAlign: "Center",
      template: (props) => {
        return props.isPrimary ? null : (
          <CallSplitIcon
            style={{ color: "green", marginTop: -8, marginBottom: -8 }}
          />
        );
      },
      editTemplate: (args) => {
        return args.isPrimary ? null : (
          <CallSplitIcon
            style={{ color: "green", marginTop: -8, marginBottom: -8 }}
          />
        );
      },
      clipMode: "Clip",
    },
    {
      field: "estimatedTotal",
      headerText: "Estimado",
      // format: "C2",
      allowEditing: true,
      width: "120",
      textAlign: "Right",
      template: (props) => {
        return (
          <span>
            {props.estimatedValueCurrency && `${props.estimatedValueCurrency} `}
            {props.estimatedTotal?.toLocaleString(getLang(), {
              minimumFractionDigits: 2,
            })}
          </span>
        );
      },
    },
    {
      field: "offered",
      headerText: "Cotizado",
      textAlign: "Center",
      displayAsCheckBox: true,
      editType: "booleanEdit",
      allowEditing: true,
      width: "100",
    },
    {
      field: "productId",
      headerText: "Producto",
      width: "250",
      textAlign: "Center",
      clipMode: "EllipsisWithTooltip",
      template: (props) => {
        if (!props.productCode && !props.productDesc) return <></>;
        let renderString = `(${props.productCode}) - ${props.productDesc}${
          props.productData
            ? ` - ${Object.values(JSON.parse(props.productData)).join(" - ")}`
            : ""
        }`;
        return <span>{renderString}</span>;
      },
      editTemplate: (args) => {
        return (
          <ProductsAutoComplete onChangeProduct={onChangeProduct} args={args} />
        );
      },
    },

    {
      field: "tagIds",
      headerText: "Etiquetas",
      width: "200",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => {
        return (
          <label>
            {rowData["tagIds"]
              .map(
                (ti) => availableTags.filter((at) => at.tagId === ti)[0].name,
              )
              .join(", ")}
          </label>
        );
      },
      allowEditing: true,
      editParams: {
        create: function () {
          elem = document.createElement("input");
          return elem;
        },
        read: () => {
          return multiSelectObj.value;
        },
        destroy: () => {
          multiSelectObj.destroy();
        },
        write: (args) => {
          var data = args.rowData.tagIds;
          multiSelectObj = new MultiSelect({
            value:
              typeof data === "string" ? args.rowData.tagIds.split() : data,
            fields: { text: "name", value: "tagId" },
            dataSource: availableTags,
          });
          multiSelectObj.appendTo(elem);
        },
      },
    },
    {
      field: "comments",
      headerText: "Comentario",
      width: "200",
      allowEditing: true,
      textAlign: "Left",
      clipMode: "EllipsisWithTooltip",
    },
    ...(isLangCR() || isLangPE()
      ? [
          {
            field: "deliveryDate",
            headerText: "Fecha de entrega",
            textAlign: "Center",
            width: "200",
            template: (props) => {
              return (
                <span>
                  {props.deliveryDate
                    ? formatShortDate(props.deliveryDate)
                    : props.deliveryDate}
                </span>
              );
            },
            editTemplate: (args) => {
              if (
                isLangPE() ||
                (isLangCR() && isAwardedContract(modalityType))
              ) {
                return (
                  <CustomDatePicker
                    id={"deliveryDate"}
                    args={args}
                    defaultValue={args.deliveryDate}
                  />
                );
              }
            },
          },
        ]
      : []),
  ];
};
