import React, { useState } from "react";
import UserManagerInput from "./UserManagerInput";
import UserManagerButton from "./UserManagerButton";
import { FstGrid } from "../../components/grid/FstGrid";
import { useCallback } from "react";
import { useEffect } from "react";

const UserManagerGrid = (props) => {
  /// Height handling
  const [height, setHeight] = useState();

  const handleResize = useCallback(() => {
    const padding = 130;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  ///

  const getProps = props;
  const [activeUser, setActiveUser] = useState(true);

  const Headers = [
    {
      field: "login",
      headerText: "Usuario",
      width: "10%",
      textAlign: "Left",
      allowSorting: true,
      showInColumnChooser: false,
    },
    {
      field: "firstName",
      headerText: "Nombre",
      width: "15%",
      textAlign: "Left",
      allowSorting: true,
    },
    {
      field: "lastName",
      headerText: "Apellido",
      width: "15%",
      textAlign: "Left",
      allowSorting: true,
    },
    {
      field: "email",
      headerText: "Email",
      width: "23%",
      textAlign: "Left",
      allowSorting: true,
      //template showing the email and when clicked it calls the function to open the modal, doesnt use any existing component in this file
      template: (values) => {
        return (
          <div id="useremail">
            <a
              href="#"
              style={{
                color: "#000",
                textDecoration: "none",
              }}
              onClick={(event) => {
                event.preventDefault();
                props.setEmailModalUser({
                  active: values.active,
                  creation: values.creation,
                  email: values.email,
                  firstName: values.firstName,
                  lastName: values.lastName,
                  lastUpdate: values.lastUpdate,
                  login: values.login,
                  phone: values.phone,
                  roleId: values.roleId,
                  userId: values.userId,
                });
                props.setEmailModalOpen(true);
              }}
            >
              {values.email}
            </a>
          </div>
        );
      },
    },
    {
      field: "active",
      headerText: "Estado",
      width: "7%",
      textAlign: "center",
      type: "boolean",
      allowSorting: true,
      template: (props) => (
        <UserManagerButton
          handleClickActive={(event) => userActive(props.userId, event)}
          active={props.active}
          isAdmin={props.roleId === 1 || props.roleId === 99}
        />
      ),
    },
    {
      field: "",
      headerText: "Modulos",
      width: "30%",
      textAlign: "right",
      allowSorting: true,
      template: (props) => (
        <div id="usermodulos">
          <UserManagerInput
            idUser={props.userId}
            appModules={getProps.appModules}
            appUserModule={props.applicationModules}
          />
        </div>
      ),
    },
  ];

  const addToolBar = () => {
    let toolbar = "";
    if (props.isAdmin) {
      toolbar = {
        Add: props.executeCreate,
        Delete: props.executeDelete,
        //rowDoubleClickAction:  props.executeEdit,
        ToolBar: {
          Add: true,
          AddTitle: "Nuevo",
          Delete: true,
          DeleteTitle: "Eliminar",
        },
      };
    }
    return toolbar;
  };

  const userActive = (userId, active) => {
    const param = {
      userId: userId,
      isEnabled: active,
    };
    props.handleActiveUser(param);
  };

  const dataGrid = {
    Getdata: props.dataUser,
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
    },
    allowSorting: true,
    allowPaging: true,
    take: 17,
    Header: Headers,
    ...addToolBar(),
    idGrid: "UserManager",
    height: height - 180,
  };

  return (
    <FstGrid
      data={dataGrid}
      uniqValue={props.uniqValue}
      result={{
        result: props.listData.rows,
        count: props.listData.totalRows,
      }}
      reloadDefault={true}
    />
  );
};

export default UserManagerGrid;
