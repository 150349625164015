import React from "react";
import {
  Grid,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import * as SettingsChangeActions from "actions/settingsChangeActions";
import FstModal from "components/modal/FstModal";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isLangAR, isLangMX, isLangPE } from "utils/common";
import {
  addOrUpdateCompanySetting,
  listCompanySettings,
} from "./services/CompanySettingService";
import { useIntl } from "react-intl";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ManageTenderEmailSubjectConfig = (props) => {
  const settingModal = { BtnNameSave: "Guardar" };
  const intl = useIntl();
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [companySettings, setCompanySettings] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectItems, setSubjectItems] = useState([]);
  const [availableValues, setAvailableValues] = useState([
    {
      id: "cols.Nombre",
      name: "tenderName",
      subjectName: `{${intl.formatMessage({
        id: "cols.Nombre",
        defaultMessage: "Nombre",
      })}}`,
      translation: intl.formatMessage({
        id: "cols.Nombre",
        defaultMessage: "Nombre",
      }),
      active: false,
    },
    {
      id: "cols.Id",
      name: "tenderNumber",
      subjectName: `{${intl.formatMessage({
        id: "cols.Id",
        defaultMessage: "Id",
      })}}`,
      translation: intl.formatMessage({
        id: "cols.Id",
        defaultMessage: "Id",
      }),
      active: false,
    },
    {
      id: "cols.Cierre",
      name: "endDate",
      subjectName: `{${intl.formatMessage({
        id: "cols.Cierre",
        defaultMessage: "Cierre",
      })}}`,
      translation: intl.formatMessage({
        id: "cols.Cierre",
        defaultMessage: "Cierre",
      }),
      active: false,
    },
    {
      id: "text.Tipo_Compra",
      name: "tenderTypeDesc",
      subjectName: `{${intl.formatMessage({
        id: "text.Tipo_Compra",
        defaultMessage: "Tipo Compra",
      })}}`,
      translation: intl.formatMessage({
        id: "text.Tipo_Compra",
        defaultMessage: "Tipo Compra",
      }),
      active: false,
    },
    {
      id: "cols.Portal",
      name: "portal",
      subjectName: `{${intl.formatMessage({
        id: "cols.Portal",
        defaultMessage: "Portal",
      })}}`,
      translation: intl.formatMessage({
        id: "cols.Portal",
        defaultMessage: "Portal",
      }),
      active: false,
    },
    {
      id: "cols.Entidad",
      name: "clientUnitName",
      subjectName: `{${intl.formatMessage({
        id: "cols.Entidad",
        defaultMessage: "Entidad",
      })}}`,
      translation: intl.formatMessage({
        id: "cols.Entidad",
        defaultMessage: "Entidad",
      }),
      active: false,
    },
    {
      id: "cols.Usuario",
      name: "userManage",
      subjectName: `{${intl.formatMessage({
        id: "cols.Usuario",
        defaultMessage: "Usuario",
      })}}`,
      translation: intl.formatMessage({
        id: "cols.Usuario",
        defaultMessage: "Usuario",
      }),
      active: false,
    },
    ...(isLangAR()
      ? [
          {
            id: "cols.ClientName", // only AR
            name: "clientName",
            subjectName: `{${intl.formatMessage({
              id: "cols.ClientName",
              defaultMessage: "Servicio Administrativo Financiero",
            })}}`,
            translation: intl.formatMessage({
              id: "cols.ClientName",
              defaultMessage: "Servicio Administrativo Financiero",
            }),
            active: false,
          },
        ]
      : []),
    ...(isLangPE() || isLangMX()
      ? [
          {
            id: "cols.Region", //only PE y MX
            name: "regionName",
            subjectName: `{${intl.formatMessage({
              id: "cols.Region",
              defaultMessage: "Región",
            })}}`,
            translation: intl.formatMessage({
              id: "cols.Region",
              defaultMessage: "Región",
            }),
            active: false,
          },
        ]
      : []),
  ]);

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = async () => {
    let subjectItemsToSave = subjectItems.join(",");
    await addOrUpdateCompanySetting({
      ...companySettings,
      subject: subjectItemsToSave,
    });
    showToaster(`La configuración ha sido almacenada.`);
    props.handleClose();
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 4000);
  };

  const getData = async () => {
    const result = await listCompanySettings();
    setCompanySettings(result.data);
    let availableValuesCopy = [...availableValues];
    availableValuesCopy.forEach((item) => {
      item.active = result.data.subject.includes(item.name);
    });
    setAvailableValues(availableValuesCopy); //TODO: use structuredClone ?

    let subjectCopy = "";
    // sort the array by the order of the subject without modifying the original array
    let availableValuesCopyToSort = [...availableValuesCopy];
    availableValuesCopyToSort
      .sort((a, b) => {
        return (
          result.data.subject.indexOf(a.name) -
          result.data.subject.indexOf(b.name)
        );
      })
      .filter((item) => item.active)
      .forEach((item, idx) => {
        if (result.data.subject.includes(item.name)) {
          subjectCopy += `${item.subjectName} | `;
          if (
            idx ===
            availableValuesCopy.filter((item) => item.active).length - 1
          ) {
            subjectCopy = subjectCopy.slice(0, -3);
          }
        }
      });

    setSubject(subjectCopy);
    setSubjectItems(
      availableValuesCopy
        .filter((item) => item.active)
        .map((item) => item.name),
    );
  };

  const handleChange = (event) => {
    let availableValuesCopy = [...availableValues];
    availableValuesCopy.forEach((item) => {
      if (item.name === event.target.name) {
        item.active = event.target.checked;
      }
    });
    setAvailableValues(availableValuesCopy);
    let subjectCopy = "";
    let subjectItemsCopy = [];
    // when the user checks or unchecks a checkbox, it needs to mantain the order of the subject
    if (event.target.checked) {
      subjectItemsCopy = [...subjectItems, event.target.name];
    } else {
      subjectItemsCopy = subjectItems.filter(
        (item) => item !== event.target.name,
      );
    }
    subjectItemsCopy.forEach((item, idx) => {
      let itemToPush = availableValuesCopy.find((i) => i.name === item);
      subjectCopy += `${itemToPush.subjectName} | `;
      if (idx === subjectItemsCopy.length - 1) {
        subjectCopy = subjectCopy.slice(0, -3);
      }
    });
    setSubject(subjectCopy);
    setSubjectItems(subjectItemsCopy);
  };

  useEffect(() => {
    if (props.open) getData();
  }, [props.open]);

  const getAvailableValuesToRender = () => {
    // map to a list of 2 elements in each row
    let availableValuesToRender = [];
    for (let i = 0; i < availableValues.length; i += 2) {
      availableValuesToRender.push(availableValues.slice(i, i + 2));
    }
    if (
      availableValuesToRender[availableValuesToRender.length - 1].length === 1
    )
      // if last row has only one element, add an empty element to render a blank column
      // [ [a], [b], [c] ] => [ [a, b], [c, null] ]
      availableValuesToRender[availableValuesToRender.length - 1].push(null);
    return availableValuesToRender;
  };

  return (
    <>
      <FstModal
        open={props.open}
        title="Configuración Correo GL"
        handleClose={handleClose}
        handleSave={handleSave}
        widthSize="lg"
        setting={settingModal}
        IsDisabledPrimary={false}
        classContainer={"fst-Modal-Container"}
      >
        <div className="row" style={{ width: 800 }}>
          <Grid container spacing={4}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
              }}
            >
              <Typography
                style={{ fontSize: "medium", width: "40%" }}
                variant="h6"
              >
                Plantilla asunto
              </Typography>
              <Tooltip
                title={`${subject ? "[" : ""}${subject}${
                  subject ? "]" : ""
                } Asunto del email`}
                placement="top"
              >
                <TextField
                  style={{ marginTop: "20px" }}
                  fullWidth
                  value={`${subject ? "[" : ""}${subject}${
                    subject ? "]" : ""
                  } Asunto del email`}
                  disabled
                  variant="outlined"
                  size="small"
                  helperText="Los valores entre llaves se reemplazarán por los valores correspondientes."
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontSize: "medium" }} variant="h6">
                Valores Disponibles
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {getAvailableValuesToRender().map((item, idx) => (
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: "2%",
                  }}
                  spacing={2}
                  key={idx}
                  direction="row"
                >
                  {item.map((item2, idx2) => (
                    <Grid item key={idx2} style={{ width: 300 }}>
                      {item2 === null ? (
                        <div style={{ width: 300 }} />
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item style={{ width: 200 }}>
                            <Typography style={{ fontSize: 14 }} variant="h7">
                              {item2.translation}
                            </Typography>
                          </Grid>
                          <Grid item style={{ width: 75 }}>
                            <Switch
                              style={{ whiteSpace: "nowrap" }}
                              color="primary"
                              checked={item2.active}
                              name={item2.name}
                              onChange={handleChange}
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </div>
        {/* <Grid
                  container
                  style={{
                    alignItems: "center",
                    paddingBottom: "2%",
                  }}
                  spacing={2}
                  key={idx}
                >
                  <Grid item xs={3}>
                    <Typography style={{ fontSize: 14 }} variant="h7">
                      {item.translation}
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Switch
                      style={{ whiteSpace: "nowrap" }}
                      color="primary"
                      checked={item.active}
                      name={item.name}
                      onChange={handleChange}
                      size="small"
                    />
                  </Grid>
                </Grid> */}
      </FstModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.settingsChangeReducer,
  };
};

export default connect(mapStateToProps, { ...SettingsChangeActions })(
  ManageTenderEmailSubjectConfig,
);
