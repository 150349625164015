import useHandleDataByLang from "intl/utils/hooks/useHandleDataByLang";
import React, { useEffect, useState } from "react";
import { FormattedForm } from "../../intl/utils/format-elements/formattedForm";

export default function Requirement({ data }) {
  const [getData, setData] = useState([]);
  const [runInit, setRunInit] = useState(false);
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);

  const initData = () => {
    let itemData = [
      {
        description: "I. Requisitos económicos y financieros",
        type: 1,
        data: [],
      },
      {
        description: "II. Requisitos técnicos",
        type: 2,
        data: [],
      },
      {
        description: "III. Requisitos administrativos",
        type: 3,
        data: [],
      },
    ];
    let result = handelData.getDataByCountry("tab.Requisitos");
    result.map((item) => {
      itemData[+item.type - 1].data = [...itemData[+item.type - 1].data, item];
    });
    setData(itemData);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <React.Fragment>
      {runInit &&
        getData.map((d, idx) => (
          <React.Fragment key={idx}>
            <div
              style={{
                marginLeft: "0.25em",
                textTransform: "uppercase",
                paddingTop: "0.5rem",
              }}
            >
              <h5>{d.description}</h5>
            </div>
            <FormattedForm id={"TabRequirement"} data={d.data} />
          </React.Fragment>
        ))}
    </React.Fragment>
  );
}
