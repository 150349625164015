import React, { useState, useEffect } from "react";
import { Controls } from "../../../utils/controls";
import { InputStyles } from "./style";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";

const FstInputText = (props) => {
  const classes = InputStyles();
  const { name, label, value, placeholder } = props;
  const { variant } = Controls.TextField;

  const handleChangeValue = (value) => {
    if (typeof props.handleChange === "function") {
      props.handleChange(name, value);
    }
  };

  const handleKeyPress = (ev) => {
    if (typeof props.handleKeyPress === "function") {
      props.handleKeyPress(ev);
    }
  };

  const handleBlur = (ev) => {
    if (typeof props.handleBlur === "function") {
      props.handleBlur(ev);
    }
  };

  return (
    <TextField
      disabled={props.disabled}
      size={props.size}
      variant={variant}
      id={name}
      label={label}
      placeholder={placeholder}
      autoComplete="off"
      className={classes.InputWidth}
      value={props.value}
      onChange={(event) => {
        const { value } = event.target;
        handleChangeValue(value);
      }}
      onKeyPress={handleKeyPress}
      onBlur={handleBlur}
      InputProps={{
        readOnly: props.readOnly,
        disabled: props.disabled,
      }}
      error={props.error}
      required={props.required}
      helperText={props.helperText}
      autoFocus={props.autoFocus}
    />
  );
};

FstInputText.propTypes = {
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  size: PropTypes.string,
};

FstInputText.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default FstInputText;
