import React, { useState, useEffect } from "react";
import FstModal from "../../../components/modal/FstModal";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { FormStyles } from "../../legal-document/style/style";
import { Controls } from "../../../utils/controls";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { getDateRangeUnits } from "../services/AnalysisService";

const RangeCustom = ({ open, close, save, filter }) => {
  const classes = FormStyles();
  const [range, setRange] = useState({});
  const [list, setList] = useState([]);
  const { variant } = Controls.TextField;

  const handleClose = () => {
    close();
  };

  const handleSaveRange = () => {
    save(range);
  };

  const onChange = (key, value) => {
    setRange({
      ...range,
      [key]: parseInt(value),
    });
  };

  const getList = async () => {
    const result = await getDateRangeUnits();
    setList(result.data);
  };

  useEffect(() => {
    const time = setTimeout(() => {
      if (filter && filter.dateRangeUnitId) {
        setRange({
          quantity: filter.dateRangeQty,
          range: filter.dateRangeUnitId,
        });
      } else {
        setRange({
          quantity: 1,
          range: 1,
        });
      }

      getList();
    });

    return () => {
      clearTimeout(time);
    };
  }, [open]);

  return (
    <FstModal
      open={open}
      title="Seleccione"
      handleClose={handleClose}
      handleSave={handleSaveRange}
      widthSize="md"
      IsDisabledPrimary={false}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            variant={variant}
            id="quantity"
            label="Cantidad"
            autoComplete="off"
            className={classes.InputWidth}
            value={range.quantity}
            onChange={(event) => {
              const { value } = event.target;
              const res = value.length > 0 ? value : 0;
              onChange("quantity", res);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Rango</FormLabel>
            <RadioGroup
              aria-label="gender"
              name="range"
              value={range.range}
              onChange={(event) => {
                const { value } = event.target;
                onChange("range", value);
              }}
            >
              {list.map((item) => {
                return (
                  <FormControlLabel
                    key={item.key}
                    value={item.key}
                    control={<Radio />}
                    label={item.value}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </FstModal>
  );
};

export default RangeCustom;
