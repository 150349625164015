export const HeaderTable = [
  {
    field: "lastName",
    headerText: "Apellido",
    width: 80,
    textAlign: "Left",
    visible: true,
    isPrimaryKey: false,
  },
  {
    field: "firstName",
    headerText: "Nombre",
    width: 80,
    textAlign: "Left",
    visible: true,
    isPrimaryKey: false,
  },
  {
    field: "email",
    headerText: "Email",
    width: 100,
    textAlign: "Left",
    visible: true,
    isPrimaryKey: false,
  },
];
