import React from "react";
import moment from "moment";
import {
  dateTimeFormat,
  getDataJson,
  formatCurrency,
} from "../../utils/common";

export const Headers = [
  {
    id: "cols.Filtro",
    field: "searchFilterName",
    headerText: "Filtro",
    width: "150",
    textAlign: "Left",
    allowSorting: true,
    showInColumnChooser: false,
  },
  {
    id: "cols.NroPac",
    field: "number",
    headerText: "Nro. PAC",
    width: "150",
    textAlign: "Center",
    allowSorting: false,
    showInColumnChooser: false,
  },
  {
    id: "cols.Entidad",
    field: "clientUnitName",
    headerText: "Entidad",
    width: "350",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Ruc",
    field: "clientUnitUniqueId",
    headerText: "RUC",
    width: "150",
    allowSorting: true,
    textAlign: "Center",
  },
  {
    id: "cols.Descripcion",
    field: "description",
    headerText: "Descripción/Objeto",
    width: "250",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.TipoProceso",
    field: "ProcessType",
    headerText: "Tipo Proceso",
    width: "250",
    allowSorting: true,
    textAlign: "Left",
    template: (props) => (
      <span>{getDataJson("ProcessType", props.scrapInfo)}</span>
    ),
    colExcel: (props) => getDataJson("ProcessType", props.scrapInfo),
  },
  {
    id: "cols.CantidadItem",
    field: "ItemCount",
    headerText: "Cantidad de Items",
    width: "150",
    allowSorting: true,
    textAlign: "Center",
    template: (props) => (
      <span>{getDataJson("ItemCount", props.scrapInfo)}</span>
    ),
    colExcel: (props) => getDataJson("ItemCount", props.scrapInfo),
  },
  {
    id: "cols.Mes",
    field: "ScheduledMonth",
    headerText: "Mes Programado",
    width: "150",
    allowSorting: true,
    textAlign: "Left",
    template: (props) => (
      <span>{getDataJson("ScheduledMonth", props.scrapInfo)}</span>
    ),
    colExcel: (props) => getDataJson("ScheduledMonth", props.scrapInfo),
  },
  {
    id: "cols.Fuente",
    field: "FinanceSource",
    headerText: "Fuente de Financiamiento",
    autoFitColumns: true,
    allowSorting: true,
    textAlign: "Left",
    template: (props) => (
      <span>{getDataJson("FinanceSource", props.scrapInfo)}</span>
    ),
    colExcel: (props) => getDataJson("FinanceSource", props.scrapInfo),
  },
  {
    id: "cols.NroItem",
    field: "itemNumber",
    headerText: "Nro. de Item",
    autoFitColumns: true,
    textAlign: "Center",
    allowSorting: false,
  },
  {
    id: "cols.CodigoCusbo",
    field: "code",
    headerText: "Código Cubso",
    width: "150",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.DescripcioItem",
    field: "itemDescription",
    headerText: "Descripción de Item",
    width: "250",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.UnidadMedida",
    field: "UOM",
    headerText: "Unidad de Medida",
    width: "150",
    allowSorting: true,
    textAlign: "Left",
    template: (props) => (
      <span>{getDataJson("UOM", props.scrapDetailInfo)}</span>
    ),
    colExcel: (props) => getDataJson("UOM", props.scrapDetailInfo),
  },
  {
    id: "cols.Cantidad",
    field: "Ammount",
    headerText: "Cantidad",
    width: "130",
    allowSorting: true,
    textAlign: "Right",
    template: (props) => (
      <span>
        {formatCurrency(getDataJson("Ammount", props.scrapDetailInfo), 2)}
      </span>
    ),
    colExcel: (props) => {
      const value = getDataJson("Ammount", props.scrapDetailInfo);
      return value ? value.toFixed(2) : value;
    },
  },
  {
    id: "cols.TipoMoneda",
    field: "Currency",
    headerText: "Tipo de Moneda",
    width: "150",
    allowSorting: true,
    textAlign: "Left",
    template: (props) => (
      <span>{getDataJson("Currency", props.scrapDetailInfo)}</span>
    ),
    colExcel: (props) => getDataJson("Currency", props.scrapDetailInfo),
  },
  {
    id: "cols.ProcesoDesierto",
    field: "Process1",
    headerText: "Proceso desierto del que procede",
    width: "300",
    allowSorting: true,
    textAlign: "Left",
    template: (props) => (
      <span>{getDataJson("Process1", props.scrapDetailInfo)}</span>
    ),
    colExcel: (props) => getDataJson("Process1", props.scrapDetailInfo),
  },
  {
    id: "cols.ProcesoDConvocado",
    field: "process2",
    headerText: "Proceso Convocado",
    width: "250",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Estado",
    field: "statusName",
    headerText: "Estado",
    width: "150",
    allowSorting: true,
    textAlign: "Left",
    template: (props) =>
      props.process2 ? (
        <span>{"Convocadas"}</span>
      ) : (
        <span>{"No convocadas"}</span>
      ),
    colExcel: (props) => (props.process2 ? "Convocadas" : "No convocadas"),
  },
];

export const ColumnsDates = [
  {
    field: "publishedDate",
    format: dateTimeFormat,
  },
  {
    field: "endDate",
    format: dateTimeFormat,
  },
];
