import React, { useState } from "react";
import FstInputText from "../../../components/forms/input-text/FstInputText";
import FstInputNumeric from "../../../components/forms/input-numeric/FstInputNumeric";
import Grid from "@material-ui/core/Grid";

const initialErrorsText = {
  required: true,
  error: false,
  message: "",
  focus: true,
};

const ManageSmartSearchHeader = (props) => {
  const { smarthSearch, isShow, edit } = props;
  const [errorInput, setErrorInput] = useState(initialErrorsText);
  const [activeValided, setActiveValided] = useState(edit);

  const handleChangeHeader = (key, value) => {
    props.handleChange(key, value);
    validateInput(key, value);
  };

  const validateInput = async (key, value) => {
    if (!activeValided) {
      /*Validar elemento*/
      let errorQty = false;
      let errorTxt = false;
      let defaultErrorQty = false;
      let defaultErrorTxt = false;
      if (key === "focus") {
        defaultErrorQty = smarthSearch.quantity !== 0 ? true : false;
        errorQty = defaultErrorQty ? false : true;
        defaultErrorTxt = smarthSearch.name.trim() ? true : false;
        errorTxt = defaultErrorTxt ? false : true;
      } else {
        if (typeof value === "number") {
          defaultErrorQty = value !== 0 ? true : false;
          errorQty = defaultErrorQty ? false : true;
          defaultErrorTxt = smarthSearch.name.trim() ? true : false;
          errorTxt = defaultErrorTxt ? false : true;
        } else {
          defaultErrorTxt = value.trim() ? true : false;
          errorTxt = defaultErrorTxt ? false : true;
        }
      }

      await setErrorInput((prevState) => ({
        ...prevState,
        errorQty,
        errorTxt,
      }));
    } else {
      setActiveValided(false);
    }
  };

  const handleBlur = (event) => {
    validateInput("focus", "");
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={isShow ? 8 : 12} md={isShow ? 8 : 12}>
          <FstInputText
            name="name"
            label="Nombre de Filtro"
            value={smarthSearch.name}
            handleChange={handleChangeHeader}
            autoFocus={errorInput.focus}
            required={errorInput.required}
            error={errorInput.errorTxt}
            handleBlur={handleBlur}
          />
        </Grid>
        {isShow && (
          <Grid item xs={12} sm={4} md={4}>
            <FstInputNumeric
              name="quantity"
              minimum="0"
              maximum="90"
              label="Lic. últimos(Dias)"
              value={smarthSearch.quantity}
              handleChange={handleChangeHeader}
              required={errorInput.required}
              error={errorInput.errorQty}
              handleBlur={handleBlur}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ManageSmartSearchHeader;
