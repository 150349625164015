import { LegalDocumentType } from "../types";
import {
  GetLegalDocumentList,
  ManageLegalDocument,
  LegalDocumentDelete,
} from "../views/legal-document/service/legalDocumentService";
import { translateCurrentPageForClient } from "../utils/common";

export const GetLegalDocuments = (pagination, filter) => async (dispatch) => {
  try {
    const request = {
      OrderBy: pagination.orderBy,
      PageSize: pagination.pageSize,
      Filter: pagination.search,
      PageNumber: pagination.PageNumber,
      OrderAsc: pagination.OrderAsc,
    };
    const axiosResult = await GetLegalDocumentList(request);

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;

      dispatch({
        type: LegalDocumentType.ALL,
        payload: {
          result: data.currentPageItems,
          count: data.totalItems,
        },
        page: translateCurrentPageForClient(data.currentPage),
        pageSize: data.pageSize,
        reloadDefault: false,
      });
    } else {
      dispatch({
        type: LegalDocumentType.ERROR,
        error: "Error fetching penalties data",
      });
    }
  } catch (error) {
    dispatch({
      type: LegalDocumentType.ERROR,
      error: "Error fetching penalties data",
    });
  }
};

export const GetLegalDocumentsExport = (pagination, filter) => async () => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
  };
  const axiosResult = await GetLegalDocumentList(request);

  if (!!axiosResult && !!axiosResult.data) {
    return axiosResult.data.currentPageItems;
  }
  return null;
};

export const GetLegalDocumentsDefault = () => (dispatch) => {
  dispatch({
    type: LegalDocumentType.DEFAULT,
    reloadDefault: true,
  });
};

export const Save = (penalty) => async (dispatch) => {
  try {
    await ManageLegalDocument(penalty);
    dispatch({
      type: LegalDocumentType.DEFAULT,
      reloadDefault: true,
    });
  } catch (error) {
    dispatch({
      type: LegalDocumentType.ERROR,
    });
  }
};

export const DeleteLegalDocument = (request) => async (dispatch) => {
  try {
    await LegalDocumentDelete(request);
    dispatch({
      type: LegalDocumentType.DEFAULT,
      reloadDefault: true,
    });
  } catch (error) {
    dispatch({
      type: LegalDocumentType.ERROR,
    });
  }
};
