export const TenderType = {
  ALL_TENDERS: "ALL_TENDERS",
  ALL_TENDERS_ERROR: "ALL_TENDERS_ERROR",
  ALL_TENDERS_DEFAULT: "ALL_TENDERS_DEFAULT",
  CLEAR_LIST: "CLEAR_LIST",
};

// TODO: translate names
export const TenderPanel = {
  GENERAL: "General",
  SCHEDULER: "Cronograma",
  ITEMS_LIST: "Lista de Items",
  DOCUMENTOS: "Documentos",
  ACCIONES: "Acciones",
  OFICIOS: "Oficios",
  ENTIDADES: "Asignaciones",
  ITEMS_ALL: "Items",
  ITEMS_MANAGED: "Gestionados",
  TICKET: "Cartas Fianza",
  COMMENTS: "Comentarios",
  SEACE: "Info del Portal",
  GESTION: "Gestión",
  REQUISITOS: "Requisitos",
  GARANTIAS: "Garantías",
  OFERTAS: "Ofertas",
  CONDICIONES: "Condiciones Contrato",
  ENTREGA: "Entrega",
  FUNCIONARIOS: "Funcionarios relacionados",
  SANCTIONS: "Sanciones",
  CHECKLIST: "Checklist",
  CONTRATOS: "Contratos",
  ORDENES_PEDIDO: "Ordenes de Pedido",
  PANTALLA_CARGA: "Pantalla de Carga",
};

export const ManageTenderPanel = {
  TENDER: "TENDER",
  PENALTY: "PENALTY",
  LEGAL_DOCUMENT: "LEGAL_DOCUMENT",
  CALENDAR: "CALENDAR",
};
