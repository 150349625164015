import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  counter: {
    color: "red",
    marginLeft: "5px",
    fontSize: "0.9rem",
  },
  tipograghy: {
    display: "inline",
    fontSize: "1.10rem",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: 400,
  },
  rootHeader: {
    padding: 6,
  },
  cardContent: {
    padding: "0px 5px 0 5px !important",
    overflow: "auto",
  },
  cardContentProductList: {
    padding: 0,
    paddingBottom: "0px !important",
  },
  actionBtn: {
    top: 0,
    position: "relative",
    padding: "5px",
  },
  button: {
    marginRight: "10px",
    marginTop: "4px",
  },
  btnPrdFilteredArrow: {
    position: "relative",
    top: "4px",
    right: "5px",
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
  },
  contentList: {
    display: "block",
    textAlign: "center",
    marginTop: "20px",
  },
}));
