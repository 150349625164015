import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootHeader: {
    display: "block",
    padding: 13,
  },
  buttonsPositions: {
    float: "right",
    marginTop: -2.5,
  },
  content: {
    height: "inherit",
    padding: "24px",
  },
  headerTypography: {
    display: "inline",
    fontSize: "1.20rem",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  widthWord: {
    maxWidth: "700px",
    width: "70%",
    fontWeight: "600",
  },
  width2ndWord: {
    maxWidth: "300px",
    width: "30%",
    fontWeight: "600",
  },
  widthExcluir: {
    width: "20%",
    fontWeight: 600,
  },
  row: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  rootList: {
    maxHeight: "340px",
    overflow: "auto",
  },
  body: {
    width: "15%",
    fontWeight: "600 !important",
    textAlign: "right",
  },
  buttonExcel: {
    color: "green",
    "&:disabled": { color: "light-gray" },
  },
}));
