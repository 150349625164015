import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import BatchData from "./BatchModalData";
import ContractData from "./ContractModalData";
import EngagementData from "./EngagementModalData";
import LineData from "./LineModalData";
import {
  CR_BATCH_MODAL,
  CR_CONTRACT_MODAL,
  CR_ENGAGEMENT_MODAL,
  CR_LINE_MODAL,
  CR_PURCHASE_ORDER_MODAL,
} from "./modalDataGenerator";
import PurchaseOrderData from "./PurchaseOrderModalData";

export default function ModalData(props) {
  const intl = useIntl();
  return (
    <>
      {props.modalName === CR_BATCH_MODAL ? (
        <BatchData
          title={intl.formatMessage({
            id: "tabL.P.Titulo",
            defaultMessage: "Otras condiciones",
          })}
          title1={intl.formatMessage({
            id: "tabL.P.TituloPolizas",
            defaultMessage: "Pólizas",
          })}
          title2={intl.formatMessage({
            id: "tabL.P.TituloColegios",
            defaultMessage: "Colegios Profesionales",
          })}
          allData={props.data}
        />
      ) : props.modalName === CR_LINE_MODAL ? (
        <LineData
          title1={intl.formatMessage({
            id: "tabL.L.TituloInformacion",
            defaultMessage: "Información de solicitud de contratación",
          })}
          title2={intl.formatMessage({
            id: "tabL.L.TituloEntrega",
            defaultMessage: "Entrega",
          })}
          title3={intl.formatMessage({
            id: "tabL.L.TituloGarantiaTecnica",
            defaultMessage: "Garantía técnica",
          })}
          title4={intl.formatMessage({
            id: "tabL.L.TituloPresupuesto",
            defaultMessage: "Presupuesto por línea",
          })}
          title5={intl.formatMessage({
            id: "tabL.L.TituloMuestra",
            defaultMessage: "Muestra",
          })}
          allData={props.data}
        />
      ) : props.modalName === CR_ENGAGEMENT_MODAL ? (
        <EngagementData
          title1={intl.formatMessage({
            id: "tabL.C.TituloInfoContratacion",
            defaultMessage: "Información de la contratación",
          })}
          title2={intl.formatMessage({
            id: "tabL.C.TituloInfoUsuarios",
            defaultMessage: "Información de usuarios",
          })}
          title3={intl.formatMessage({
            id: "tabL.C.TituloArchivoAdjunto",
            defaultMessage: "Archivo Adjunto",
          })}
          allData={props.data}
        />
      ) : props.modalName === CR_CONTRACT_MODAL ? (
        <ContractData data={props.data} />
      ) : props.modalName === CR_PURCHASE_ORDER_MODAL ? (
        <PurchaseOrderData data={props.data} />
      ) : null}
    </>
  );
}
