import {
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import FstModal from "components/modal/FstModal";
import React from "react";

export default function BalanceHistoryModalData(props) {
  return (
    <FstModal
      open={props.open}
      title={"Consulta Historial de órdenes de pedido"}
      handleClose={props.handleClose}
      handleSave={props.handleSave}
      setting={{ BtnNameSave: "Cerrar" }}
      onlyOK={true}
      widthSize="md"
      fullWidth={true}
      classContainer={"fst-Modal-Container"}
    >
      <CardContent>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {props.data.purchaseOrderHistory?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontWeight: "bold" }}
                  >
                    {row.name}
                  </TableCell>
                  <TableCell>{row.data}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <CardContent>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  {props.data.purchaseOrderHistoryItemsHeader?.map(
                    (row, idx) => (
                      <TableCell key={idx}>{row}</TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.purchaseOrderHistoryItemsInfo?.map((row, idx) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={idx}
                  >
                    {row.map((row2, idx2) => (
                      <TableCell key={`${row2}${idx2}`}>{row2}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TableContainer component={Paper} style={{ marginTop: "2%" }}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Total</TableCell>
                <TableCell>{props.data.CheckBalance?.HistoryTotal}</TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        </CardContent>
      </CardContent>
    </FstModal>
  );
}
