import React, { useState, useEffect } from "react";
import ManageSmartSearchHeader from "./ManageSmartSearchHeader";
import Grid from "@material-ui/core/Grid";
import ManageSmartSearchWords from "./ManageSmartSearchWords";
import { getSmartSearchById } from "../services/smartSearchService";

const ManageSmartSearch = (props) => {
  const { handleSmartSearch, searchFilterId } = props;

  const [smartSearch, setSmartSearch] = useState({
    name: "",
    quantity: props.quantity ? props.quantity : 0,
    includeWords: [{ word: "", words: [] }],
    includeWordsNames: [{ word: "", words: [] }],
    excludeWords: [{ word: "", words: [] }],
    excludeWordsNames: [{ word: "", words: [] }],
  });

  const handleChange = (key, value) => {
    const aux = { ...smartSearch };
    aux[key] = value;
    setSmartSearch(aux);
    handleSmartSearch(aux);
    props.handleBtnActive(false);
  };

  const addElement = (key) => {
    const aux = { ...smartSearch };
    aux[key].push({ word: "", words: [] });
    setSmartSearch(aux);
  };

  const handleWordsList = (key, value) => {
    const aux = { ...smartSearch };
    aux[key] = value.length > 0 ? value : [{ word: "", words: [] }];
    setSmartSearch(aux);
    handleSmartSearch(aux);
    props.handleBtnActive(false);
  };

  const adaptList = (list) => {
    const aux = [];
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      const word = {
        word: "",
        words: element,
      };
      if (element.length > 0) {
        aux.push(word);
      }
    }
    return aux.length > 0 ? aux : [];
  };

  const getSmartSearch = async () => {
    if (searchFilterId) {
      const result = await getSmartSearchById({
        SearchFilterId: searchFilterId,
      });
      const { data: ss } = result;
      const model = {
        searchFilterId: ss.searchFilterId,
        name: ss.name,
        quantity: ss.days,
        includeWords: adaptList(ss.includeWords),
        excludeWords: adaptList(ss.excludeWords),
        includeWordsNames: adaptList(ss.includeWordsName),
        excludeWordsNames: adaptList(ss.excludeWordsName),
      };
      setSmartSearch(model);
    }
    props.handleBtnActive(true);
  };

  useEffect(() => {
    getSmartSearch();
  }, []);

  return (
    <>
      <ManageSmartSearchHeader
        smarthSearch={smartSearch}
        isShow={props.isShow}
        handleChange={handleChange}
        edit={props.edit}
      />

      {
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <ManageSmartSearchWords
              addElement={(key) => {
                addElement(key);
              }}
              smartSearch={smartSearch}
              handleWordsList={handleWordsList}
            />
          </Grid>
        </Grid>
      }
    </>
  );
};

export default ManageSmartSearch;
