import { combineReducers } from "redux";
import alertPendingSaveReducer from "./alertPendingSaveReducer";
import analysisReportReducer from "./analysisReportReducer";
import { appReducer } from "./appReducer";
import legalDocumentReducer from "./legalDocumentReducer";
import modalReducer from "./modalReducer";
import penaltyReducer from "./penaltyReducer";
import productReducer from "./productReducer";
import sendTenderReducer from "./sendTenderReducer";
import settingsChangeReducer from "./settingsChangeReducer";
import spinnerReducer from "./spinnerReducer";
import tenderReducer from "./tenderReducer";
import { userReducer } from "./userReducer";

const allReducers = combineReducers({
  user: userReducer,
  app: appReducer,
  tenderReducer,
  spinnerReducer,
  penaltyReducer,
  legalDocumentReducer,
  productReducer,
  analysisReportReducer,
  alertPendingSaveReducer,
  sendTenderReducer,
  modalReducer,
  settingsChangeReducer,
});

const rootReducer = (state, action) => {
  return allReducers(state, action);
};

export default rootReducer;
