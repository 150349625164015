export const HeaderTable = [
  {
    field: "productId",
    headerText: "ID",
    width: 60,
    textAlign: "Center",
    visible: false,
    isPrimaryKey: true,
  },
  {
    field: "productCode",
    headerText: "Codigo",
    width: 80,
    textAlign: "Center",
    visible: true,
    isPrimaryKey: false,
    clipMode: "EllipsisWithTooltip",
  },
  {
    field: "productBarCode",
    headerText: "Codigo Bar",
    width: 85,
    textAlign: "Center",
    visible: true,
    isPrimaryKey: false,
    clipMode: "EllipsisWithTooltip",
  },
  {
    field: "productDesc",
    headerText: "Descripcion",
    width: 170,
    textAlign: "Left",
    visible: true,
    isPrimaryKey: false,
    clipMode: "EllipsisWithTooltip",
  },
];
