import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import useOutsiderTenderColumns from "./useOutsiderTenderColumns";
import { useState } from "react";
import { Delete, ExpandMore } from "@material-ui/icons";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import OutsiderTendersFormComponent from "./OutsiderTenderFormComponent";
import moment from "moment";
import * as SpinnerActions from "../../actions/spinnerActions";
import { connect } from "react-redux";
import { manageOutsiderTenders } from "./outsiderTendersService";
import FstModalConfirm from "components/modal/FstModalConfirm";

const OutsiderTendersManualInsert = ({
  height,
  tenderTypes,
  tendersStatus,
  sellers,
  categories,
  tags,
  buildDTOToSave,
  showToasterError,
  showToasterSuccess,
  getSourceId,
  nullifyUndefinedValues,
  ...props
}) => {
  const intl = useIntl();
  const outsiderTenders = useOutsiderTenderColumns();
  const [saveConfirm, setSaveConfirm] = useState(false);
  const { currencies } = props;
  const currencyList = currencies.map((item) => ({
    ...item,
    id: item.currencyId,
    name: item.code,
  }));

  const splitArrayIntoPairs = (array) => {
    // map to a list of 2 elements in each row
    let newArray = [];
    if (!array) return newArray;
    for (let i = 0; i < array.length; i += 2) {
      newArray.push(array.slice(i, i + 2));
    }
    if (newArray[newArray.length - 1].length === 1)
      // if last row has only one element, add an empty element to render a blank column
      // [ [a], [b], [c] ] => [ [a, b], [c, null] ]
      newArray[newArray.length - 1].push(null);
    return newArray;
  };

  const [tenderInputValues, setTenderInputValues] = useState(
    outsiderTenders
      .getTenderValues(tenderTypes, tendersStatus, currencyList)
      .map((item) => ({
        ...item,
        inputValue: "",
      })),
  );

  const [tenderDetailInputValues, setTenderDetailInputValues] = useState([
    {
      detail: outsiderTenders
        .getTenderDetailValues(currencyList)
        .map((item) => ({
          ...item,
          inputValue: "",
        })),
      awarded: [],
      manageDetail: outsiderTenders
        .getTenderManageDetailValues()
        .map((item) => ({
          ...item,
          inputValue: "",
        })),
    },
  ]);

  const [tenderManageInputValues, setTenderManageInputValues] = useState(
    outsiderTenders
      .getTenderManageValues(sellers, tags, categories)
      .map((item) => ({
        ...item,
        inputValue: "",
      })),
  );

  const addTenderDetailInputValue = () => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    let newTenderDetailInputValue = outsiderTenders
      .getTenderDetailValues(currencyList)
      .map((item) => ({
        ...item,
        inputValue: "",
      }));
    newTenderDetailInputValues.push({
      detail: newTenderDetailInputValue,
      awarded: [],
      manageDetail: outsiderTenders
        .getTenderManageDetailValues()
        .map((item) => ({ ...item, inputValue: "" })),
    });
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const addTenderDetailAwardInputValue = (idx) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    newTenderDetailInputValues[idx].awarded.push(
      outsiderTenders.getTenderDetailAwardValues(currencyList).map((item) => ({
        ...item,
        inputValue: "",
      })),
    );
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const deleteTenderDetailInputValue = (idx) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    newTenderDetailInputValues.splice(idx, 1);
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const deleteTenderDetailAwardedInputValue = (idxTD, idx) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    newTenderDetailInputValues[idxTD].awarded.splice(idx, 1);
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const handleTenderInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderInputValues = [...tenderInputValues];
    if (newTenderInputValues[idx].type === "date") {
      newTenderInputValues[idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      ).toISOString();
    } else if (newTenderInputValues[idx].type === "number") {
      newTenderInputValues[idx].inputValue = newValue;
    } else {
      newTenderInputValues[idx].inputValue = e.target.value;
    }
    setTenderInputValues(newTenderInputValues);
  };

  const handleTenderManageInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderManageInputValues = [...tenderManageInputValues];
    if (newTenderManageInputValues[idx].type === "date") {
      newTenderManageInputValues[idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      ).toISOString();
    } else if (newTenderManageInputValues[idx].type === "number") {
      newTenderManageInputValues[idx].inputValue = newValue;
    } else {
      newTenderManageInputValues[idx].inputValue = e.target.value;
    }
    setTenderManageInputValues(newTenderManageInputValues);
  };

  const handleTenderDetailInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    if (newTenderDetailInputValues[idx2].detail[idx].type === "date") {
      newTenderDetailInputValues[idx2].detail[idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      ).toISOString();
    } else if (newTenderDetailInputValues[idx2].detail[idx].type === "number") {
      newTenderDetailInputValues[idx2].detail[idx].inputValue = newValue;
    } else {
      newTenderDetailInputValues[idx2].detail[idx].inputValue = e.target.value;
    }
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const handleTenderDetailAwardInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    if (newTenderDetailInputValues[idx2].awarded[idx3][idx].type === "date") {
      newTenderDetailInputValues[idx2].awarded[idx3][idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      ).toISOString();
    } else if (
      newTenderDetailInputValues[idx2].awarded[idx3][idx].type === "number"
    ) {
      newTenderDetailInputValues[idx2].awarded[idx3][idx].inputValue = newValue;
    } else {
      newTenderDetailInputValues[idx2].awarded[idx3][idx].inputValue =
        e.target.value;
    }
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const handleTenderManageDetailInputValueChange = (
    e,
    idx,
    idx2,
    idx3,
    outsiderValue,
    newValue = null,
  ) => {
    const newTenderDetailInputValues = [...tenderDetailInputValues];
    if (newTenderDetailInputValues[idx2].manageDetail[idx].type === "date") {
      newTenderDetailInputValues[idx2].manageDetail[idx].inputValue = moment(
        newValue,
        "DD/MM/YYYY HH:mm",
      ).toISOString();
    } else if (
      newTenderDetailInputValues[idx2].manageDetail[idx].type === "number"
    ) {
      newTenderDetailInputValues[idx2].manageDetail[idx].inputValue = newValue;
    } else {
      newTenderDetailInputValues[idx2].manageDetail[idx].inputValue =
        e.target.value;
    }
    setTenderDetailInputValues(newTenderDetailInputValues);
  };

  const verifyRequiredFields = () => {
    let error = false;
    // Tender
    tenderInputValues.forEach((tenderInputValue) => {
      if (tenderInputValue.required && tenderInputValue.inputValue === "") {
        error = true;
      }
    });
    // Manage
    tenderManageInputValues.forEach((tenderManageInputValue) => {
      if (
        tenderManageInputValue.required &&
        tenderManageInputValue.inputValue === ""
      ) {
        error = true;
      }
    });
    // Tender Details
    tenderDetailInputValues.forEach((tenderDetailInputValue) => {
      tenderDetailInputValue.detail.forEach((tenderDetailInputValueDetail) => {
        if (
          tenderDetailInputValueDetail.required &&
          tenderDetailInputValueDetail.inputValue === ""
        ) {
          error = true;
        }
      });
      tenderDetailInputValue.manageDetail.forEach(
        (tenderDetailInputValueManageDetail) => {
          if (
            tenderDetailInputValueManageDetail.required &&
            tenderDetailInputValueManageDetail.inputValue === ""
          ) {
            error = true;
          }
        },
      );
      tenderDetailInputValue.awarded.forEach(
        (tenderDetailInputValueAwarded) => {
          tenderDetailInputValueAwarded.forEach(
            (tenderDetailInputValueAwardedDetail) => {
              if (
                tenderDetailInputValueAwardedDetail.required &&
                tenderDetailInputValueAwardedDetail.inputValue === ""
              ) {
                error = true;
              }
            },
          );
        },
      );
    });
    return error;
  };

  const getInputValuesByName = (data) => {
    return data.reduce((acc, curr) => {
      if (curr.type === "select" && curr.inputValue !== "") {
        // get the 'name' of the selected option
        acc[curr.columnValue] = curr.options.find(
          (option) => option.id === curr.inputValue,
        ).name;
      } else {
        acc[curr.columnValue] = curr.inputValue !== "" ? curr.inputValue : null;
      }
      return acc;
    }, {});
  };

  const getInputManageValues = (data) => {
    return data.reduce((acc, curr) => {
      let manageItem = null;
      if (curr.inputValue !== "") {
        manageItem = curr.options.find(
          (option) => option.id === curr.inputValue,
        );
        delete manageItem.id;
      }

      acc[curr.columnValue] = manageItem !== null ? [manageItem] : [];
      return acc;
    }, {});
  };

  const resetForm = () => {
    setTenderInputValues(
      outsiderTenders
        .getTenderValues(tenderTypes, tendersStatus, currencyList)
        .map((item) => ({
          ...item,
          inputValue: "",
        })),
    );
    setTenderDetailInputValues([
      {
        detail: outsiderTenders
          .getTenderDetailValues(currencyList)
          .map((item) => ({
            ...item,
            inputValue: "",
          })),
        awarded: [],
        manageDetail: outsiderTenders
          .getTenderManageDetailValues()
          .map((item) => ({
            ...item,
            inputValue: "",
          })),
      },
    ]);
    setTenderManageInputValues(
      outsiderTenders
        .getTenderManageValues(sellers, tags, categories)
        .map((item) => ({
          ...item,
          inputValue: "",
        })),
    );
  };

  const handleSave = async () => {
    setSaveConfirm(false);
    if (verifyRequiredFields()) {
      showToasterError("Debe completar los campos requeridos.");
      return;
    }
    props.SpinnerShow("Guardando licitación...");
    let tender = {
      ...getInputValuesByName(tenderInputValues),
      ...getInputManageValues(tenderManageInputValues),
    };

    tender.Details = tenderDetailInputValues.map((tenderDetailInputValue) => {
      let tenderDetail = getInputValuesByName(tenderDetailInputValue.detail);
      tenderDetail = {
        ...tenderDetail,
        ...getInputValuesByName(tenderDetailInputValue.manageDetail),
      };

      tenderDetail.Awardeds = tenderDetailInputValue.awarded.map(
        (tenderDetailInputValueAwarded) => {
          return getInputValuesByName(tenderDetailInputValueAwarded);
        },
      );
      return tenderDetail;
    });

    let result = {
      // Tender
      TenderNumber: tender.tenderNumber, // si hay mismo tender number, se agregan los details nomas
      FileNumber: tender.fileNumber,
      PublishedDate: tender.publishedDate,
      TenderName: tender.tenderName,
      TenderDescription: tender.tenderDescription,
      Currency: tender.currency,
      EstimatedValue: tender.estimatedValue,
      Extension: {
        Info: JSON.stringify({ SourceName: tender.sourceName }),
      },
      TenderType: tender.tenderType, // mandan la string (se da en excel opciones) --> despues se mapea (BE)
      Status: tender.tenderStatus, // mandan la string (se da en excel opciones) --> despues se mapea (BE)
      ClientUnit: {
        // BE looks if the clientUnit exists, if not, creates it
        UniqueId: tender.clientUnitUniqueId,
        Name: tender.clientUnitName,
        // Client: null // Maybe we need to add this for Peru or other later
      },
      EndDate: tender.endDate,
      AwardDate: tender.awardDate,
      Link: tender.link,
      BidSecurity: tender.bidSecurity,
      FaithfulObservance: tender.faithfulObservance,
      BidTypeLine: null, // probablemente no se use
      ScrapedDate: new Date().toISOString(), //automatic -> fecha de hoy (poner aca)
      SourceId: getSourceId(), // externo, completado x nosotros (extAR, etc)

      Details: tender.Details.map((tenderDetail) => {
        return {
          ItemNumber: tenderDetail.tenderDetailItemNumber,
          Code: tenderDetail.tenderDetailCode,
          Description: tenderDetail.tenderDetailDescription,
          Qty: tenderDetail.tenderDetailQty,
          UOM: tenderDetail.tenderDetailUom,
          EstimatedValue: tenderDetail.tenderDetailEstimatedValue,
          EstimatedValueCurrency:
            tenderDetail.tenderDetailEstimatedValueCurrency,
          Observations: tenderDetail.tenderDetailObservation,
          Specifications: tenderDetail.tenderDetailSpecification,
          Conditioning: tenderDetail.tenderDetailConditioning,
          Awardeds: tenderDetail.Awardeds.map((tenderDetailAwarded) => {
            return {
              ammount: tenderDetailAwarded.tenderDetailAwardedAmmount,
              qty: tenderDetailAwarded.tenderDetailAwardedQty,
              description:
                tenderDetailAwarded.tenderDetailAwardedCompetitorDescription,
              currrency: tenderDetailAwarded.tenderDetailAwardedCurrency,
              Competitor: {
                // BE looks if the competitor exists, if not, creates it
                UniqueId:
                  tenderDetailAwarded.tenderDetailAwardedCompetitorUniqueId,
                Name: tenderDetailAwarded.tenderDetailAwardedCompetitorName,
              },
            };
          }),
          //Manage detail
          Offered:
            tenderDetail.tenderManageDetailOffered === "Si" ? true : false,
          Comments: tenderDetail.tenderManageDetailComments,
          EstimatedTotal: tenderDetail.tenderManageDetailEstimatedTotal,
          DeliveryDate: tenderDetail.tenderManageDetailDeliveryDate,
          ProductCode: tenderDetail.tenderManageDetailProductCode,
          OfferedDate: tenderDetail.tenderManageDetailOfferedDate,
        };
      }),

      //Manage
      Sellers: tender.tenderManageSeller,
      Categories: tender.tenderManageCategory,
      Tags: tender.tenderManageTag,
    };
    result = nullifyUndefinedValues(result);

    const response = await manageOutsiderTenders([result]);

    if (response) {
      showToasterSuccess("Licitación guardada correctamente.");
      resetForm();
    } else {
      showToasterError(
        "Ocurrió un error al guardar la licitación. Por favor intente nuevamente.",
      );
    }

    props.SpinnerHide();
  };

  return (
    <>
      <Card
        style={{
          padding: "1.5rem",
          marginTop: "1rem",
          height: `calc(${height}px - 14rem)`,
          overflowY: "scroll",
        }}
      >
        <Typography variant="h6" style={{ marginBottom: "1rem" }}>
          Datos de Licitación
        </Typography>
        {/* Tender Data */}
        <Grid container spacing={2} style={{ maxWidth: 1200 }}>
          {splitArrayIntoPairs(tenderInputValues).map((tenderDualValue, idx) =>
            tenderDualValue.map((tenderValue, idx2) => (
              <OutsiderTendersFormComponent
                key={idx2}
                index={2 * idx + idx2}
                outsiderValue={tenderValue}
                onChange={handleTenderInputValueChange}
              />
            )),
          )}
        </Grid>

        {/* Tender Manage */}
        <Typography
          variant="h6"
          style={{ marginBottom: "1rem", marginTop: "2rem" }}
        >
          Datos de Gestión
        </Typography>
        <Grid container spacing={2} style={{ maxWidth: 1200 }}>
          {splitArrayIntoPairs(tenderManageInputValues).map(
            (tenderManageDualValue, idx) =>
              tenderManageDualValue.map((tenderManageValue, idx2) => (
                <OutsiderTendersFormComponent
                  key={idx2}
                  index={2 * idx + idx2}
                  outsiderValue={tenderManageValue}
                  onChange={handleTenderManageInputValueChange}
                />
              )),
          )}
        </Grid>

        {/* Tender Details */}
        {tenderDetailInputValues.map((tenderDetailValue, idxTD) => (
          <Accordion
            style={{ marginTop: "2rem", backgroundColor: "#F1F1EF" }}
            key={idxTD}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ alignItems: "center" }}
            >
              <Typography style={{ fontSize: 16 }} variant="h6">
                {`Datos de ${intl.formatMessage({
                  id: "tab.ListaItems",
                  defaultMessage: "Lista de Items",
                })}: ${idxTD + 1}`}
              </Typography>
              {/* Delete Button */}
              {tenderDetailInputValues.length > 1 && (
                <IconButton
                  style={{ float: "right", marginLeft: "auto" }}
                  onClick={() => deleteTenderDetailInputValue(idxTD)}
                  size="small"
                >
                  <Delete />
                </IconButton>
              )}
            </AccordionSummary>

            <AccordionDetails>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ fontSize: 16, marginBottom: "1rem" }}
                  variant="h6"
                >
                  {`Licitación`}
                </Typography>
                <Grid container spacing={2} style={{ maxWidth: 1200 }}>
                  {splitArrayIntoPairs(tenderDetailValue.detail).map(
                    (tenderDetailDualValue, idx) =>
                      tenderDetailDualValue.map(
                        (tenderDetailSingleItem, idx2) => (
                          <OutsiderTendersFormComponent
                            key={idx2}
                            index={2 * idx + idx2}
                            indexTopElem={idxTD}
                            outsiderValue={tenderDetailSingleItem}
                            onChange={handleTenderDetailInputValueChange} //change
                          />
                        ),
                      ),
                  )}
                </Grid>

                {/* Tender Manage Detail */}
                <Typography
                  style={{
                    fontSize: 16,
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                  variant="h6"
                >
                  {`Gestión`}
                </Typography>
                <Grid container spacing={2} style={{ maxWidth: 1200 }}>
                  {splitArrayIntoPairs(tenderDetailValue.manageDetail).map(
                    (tenderMDDualValue, idx) =>
                      tenderMDDualValue.map((tenderMDSingleItem, idx2) => (
                        <OutsiderTendersFormComponent
                          key={idx2}
                          index={2 * idx + idx2}
                          indexTopElem={idxTD}
                          outsiderValue={tenderMDSingleItem}
                          onChange={handleTenderManageDetailInputValueChange}
                        />
                      )),
                  )}
                </Grid>

                {/* Tender Detail Awarded */}
                {tenderDetailValue.awarded.length > 0 &&
                  tenderDetailValue.awarded.map((TDAwardedValue, idxAward) => (
                    <Accordion
                      style={{ marginTop: "2rem", backgroundColor: "#E5E4E2" }}
                      key={idxAward}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ alignItems: "center" }}
                      >
                        <Typography style={{ fontSize: 16 }} variant="h6">
                          {`Datos de Adjudicación / Oferta: ${idxAward + 1}`}
                        </Typography>
                        {/* Delete Button */}
                        {tenderDetailValue.awarded.length > 0 && (
                          <IconButton
                            style={{ float: "right", marginLeft: "auto" }}
                            onClick={() =>
                              deleteTenderDetailAwardedInputValue(
                                idxTD,
                                idxAward,
                              )
                            }
                            size="small"
                          >
                            <Delete />
                          </IconButton>
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} style={{ maxWidth: 1200 }}>
                          {splitArrayIntoPairs(TDAwardedValue).map(
                            (TDAwardedSingleItem, idx) =>
                              TDAwardedSingleItem.map((awardedItem, idx2) => (
                                <OutsiderTendersFormComponent
                                  key={idx2}
                                  index={2 * idx + idx2}
                                  indexTopElem={idxTD}
                                  indexAux={idxAward}
                                  outsiderValue={awardedItem}
                                  onChange={
                                    handleTenderDetailAwardInputValueChange
                                  }
                                />
                              )),
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                <Button
                  style={{ marginTop: "2rem", width: "fit-content" }}
                  onClick={() => addTenderDetailAwardInputValue(idxTD)}
                  variant="outlined"
                  color="primary"
                >
                  Agregar Adjudicación / Oferta
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}

        <Button
          onClick={addTenderDetailInputValue}
          style={{ marginTop: "2rem" }}
          variant="outlined"
          color="primary"
        >
          Agregar Item
        </Button>
        <div>
          <Button
            onClick={() => setSaveConfirm(true)}
            style={{ marginTop: "3rem" }}
            variant="contained"
            color="primary"
          >
            Guardar licitación
          </Button>
        </div>
      </Card>
      <FstModalConfirm
        title={"Guardar licitación"}
        close={() => {
          setSaveConfirm(false);
        }}
        open={saveConfirm}
        message={
          "¿Esta seguro que desea guardar la licitación? Una vez guardada no podrá ser modificada."
        }
        success={handleSave}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { app, spinnerReducer } = state;
  return {
    currencies: app.currencyList,
    ...spinnerReducer,
    ...ownProps,
  };
};

export default connect(mapStateToProps, {
  ...SpinnerActions,
})(OutsiderTendersManualInsert);
