import React, { useState, useEffect } from "react";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import PropTypes from "prop-types";
import { Controls } from "../../../utils/controls";
import { InputStyles } from "./style";

const FstInputNumeric = (props) => {
  const { variant } = Controls.TextField;
  const classes = InputStyles();
  const [inputValue, setInputValue] = useState("");
  const { name, label, value, handleChange } = props;

  const handleChangeValue = (value) => {
    handleChange(name, value);
  };

  useEffect(() => {
    setInputValue(value);
    return () => {};
  }, [value]);

  return (
    <CurrencyTextField
      variant={variant}
      label={label}
      name={name}
      minimumValue={props.minimum}
      maximumValue={props.maximum}
      className={classes.InputWidth}
      currencySymbol={props.symbol}
      value={inputValue}
      decimalPlaces={props.decimalPlaces}
      autoComplete="off"
      decimalCharacter={props.decimalCharacter}
      InputProps={{
        readOnly: props.readOnly,
        disabled: props.disabled,
      }}
      digitGroupSeparator={props.digitGroupSeparator}
      onChange={(e, value) => handleChangeValue(value)}
      onBlur={(e, value) => handleChangeValue(value)}
      error={props.error}
      required={props.required}
      style={props.style}
      helperText={props.helperText}
      size={props.size}
    />
  );
};

FstInputNumeric.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  decimalCharacter: PropTypes.string,
  symbol: PropTypes.string,
  minimum: PropTypes.string,
  maximum: PropTypes.string,
};

FstInputNumeric.defaultProps = {
  disabled: false,
  readOnly: false,
  symbol: "",
  decimalCharacter: ",",
  digitGroupSeparator: ".",
  decimalPlaces: 0,
  minimum: "0",
  maximum: "99999999999999",
};

export default FstInputNumeric;
