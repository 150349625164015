import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootHeader: {
    padding: 10,
  },
  tipograghy: {
    margin: 0,
    fontWeight: 500,
    fontSize: "1.1rem",
    display: "inline-flex",
  },
  button: {
    margin: theme.spacing(1),
  },
  btnGrPrimary: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  btnGrIcon: {
    paddingBottom: 1,
    paddingTop: 4,
  },
  fstButtom: {
    display: "inline-flex",
    float: "right",
  },
  ctnActializar: {
    height: "34px",
    marginTop: "-17px",
    display: "flex",
    alignItems: "center",
  },
  etqActializar: {
    display: "flex",
    padding: "3px 4px 2px 4px",
    fontSize: "0.75rem",
    backgroundColor: "#8c8c8c",
    borderRadius: "5px",
    color: "#edecec",
    fontWeight: "500",
    height: "19px",
    alignItems: "center",
  },
  separeDiv: {
    borderLeft: "1px solid #edecec",
    height: "16px",
    marginLeft: "5px",
    marginRight: "2px",
  },
}));
