import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as SettingsChangeActions from "actions/settingsChangeActions";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FSTGridCrud from "../../components/grid/FSTGridCrud";
import FstModal from "../../components/modal/FstModal";
import {
  addOrUpdateDeleteReason,
  deleteDeleteReason,
  listDeleteReasons,
} from "./services/deleteReasonService";

const HeaderTable = [
  {
    field: "description",
    headerText: "Descripción",
    textAlign: "Left",
  },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const DeleteReasonModal = (props) => {
  const settingModal = { BtnNameSave: "Cerrar" };
  const [uniqValue, setUniqValue] = useState(moment().format());
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [reloadDefault, setReloadDefault] = useState(false);
  const [result, setResult] = useState({ result: [], count: 0 });
  const messageDelete =
    "El motivo no puede ser eliminado porque está siendo utilizado.";
  const ExportFileName = "Motivos de eliminación.xlsx";

  const handleClose = () => {
    props.handleOpenModal(false);
  };

  const handleSave = async (data) => {
    await addOrUpdateDeleteReason([data]);
    showToaster(
      `El Motivo de Eliminación "${data.description}" ha sido almacenado.`,
    );
    setReloadDefault(true);
    setUniqValue(uniqValue + 1);
    props.SettingsChangeActivate();
  };

  const handleDelete = async (item) => {
    const request = [{ deleteReasonId: item.deleteReasonId }];
    await deleteDeleteReason(request);
    showToaster(
      `El Motivo de Eliminación "${item.description}" ha sido eliminado.`,
    );
    setReloadDefault(true);
    setUniqValue(uniqValue + 1);
    props.SettingsChangeActivate();
  };

  const showToaster = (value) => {
    setOpenToasterMessage(value);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 4000);
  };

  const getData = async (pagination) => {
    const rest = await listDeleteReasons(pagination);
    setResult({
      result: rest.data,
      count: rest.data.length,
    });
    setReloadDefault(false);
  };

  const validateDelete = (item) => {
    return item.disposable;
  };

  useEffect(() => {
    setReloadDefault(true);
  }, []);

  return (
    <>
      <FstModal
        open={props.open}
        title="Motivos de Eliminación"
        handleClose={handleClose}
        handleSave={handleClose}
        onlyOK={true}
        widthSize="sm"
        setting={settingModal}
        IsDisabledPrimary={false}
      >
        <FSTGridCrud
          id="gridDeleteReasonsModal"
          list={result}
          rows={HeaderTable}
          uniqValue={uniqValue}
          Getdata={getData}
          ExportFileName={ExportFileName}
          GetDataExport={listDeleteReasons}
          delete={(item) => handleDelete(item)}
          reloadDefault={reloadDefault}
          messageDelete={messageDelete}
          validateDelete={validateDelete}
          handleUpdate={(data) => handleSave(data)}
        />
      </FstModal>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.settingsChangeReducer,
  };
};

export default connect(mapStateToProps, { ...SettingsChangeActions })(
  DeleteReasonModal,
);
