import React from "react";
import formatJSON from "../json/form-PE.json";
import { formatDate } from "../../../../../utils/common";

export default {
  ...formatJSON,
  TabGeneralInfoFirst: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "tenderDescription", label: "Objeto de Contratación" },
            { key: "callNumber", label: "Nº Convocatoria" },
            { key: "estimatedValue", label: "Valor Referencial" },
            {
              key: "publishedDate",
              label: "Fecha Publicación",
              render: formatDate,
            },
          ],
        },
      },
    ],
  },
  TabSchedule: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "tenderStageType", label: "Etapa" },
            { key: "startDate", label: "Fecha Inicio", render: formatDate },
            { key: "endDate", label: "Fecha Fin", render: formatDate },
          ],
        },
      },
    ],
  },
  TabProcedureDocument: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "No hay información disponible",
          columns: [
            { key: "number", label: "Nro.", primary: true },
            { key: "stageName", label: "Etapa", primary: true },
            { key: "name", label: "Documento" },
            {
              key: "link",
              label: "Archivo",
              render: (value, column, row, data) => (
                <a href={value}>{column.name}</a>
              ),
            },
            {
              key: "publishedDate",
              label: "Fecha publicación",
              render: formatDate,
            },
          ],
        },
      },
    ],
  },
  ListCompDocuments: {
    component: [
      {
        type: "Grid",
        id_ff: "TabProcedureDocument",
        template: (props) => {
          return props.data.map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          });
        },
      },
    ],
  },
  TabGeneralProcedureActions: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          columns: [
            { key: "number", label: "Nro.", primary: true },
            { key: "name", label: "Acción", primary: true },
            {
              key: "publishedDate",
              label: "Fecha publicación",
              render: formatDate,
            },
            { key: "description", label: "Motivo de la acción" },
          ],
        },
      },
    ],
  },
  TabSupervisingJob: {
    elements: [
      {
        type: "ResponsiveTable",
        props: {
          noContentText: "Sin oficios",
          columns: [
            { key: "number", label: "Nro.", primary: true },
            {
              key: "publishDate",
              label: "Fecha de publicación",
              render: formatDate,
            },
            { key: "data", label: "Datos del Oficio", primary: true },
            {
              key: "releaseDate",
              label: "Fecha de emisión",
              render: formatDate,
            },
            {
              key: "link",
              label: "Archivo",
              render: (value, column, row, data) => (
                <a href={value}>{column.data}</a>
              ),
            },
          ],
        },
      },
    ],
  },
  ValueCmb_Carta: {
    objectName: "comboCartaFianzaPE",
  },
  ListCompItemList: {
    data: {
      section: "0",
    },
    component: [
      {
        type: "EtqH5",
        styleParent: { marginLeft: "0.25em" },
        template: (props) => props.idx + 1 + " - " + props.data.description,
      },
      {
        type: "Grid",
        id_ff: "TabItemListFirst",
        template: (props) =>
          [props.data].map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          }),
      },
      {
        type: "EtqH5",
        styleParent: { textAlign: "center" },
        label: "ADJUDICACIONES",
      },
      {
        type: "Grid",
        id_ff: "TabItemListLast",
        template: (props) =>
          props.data.tenderDetailAwardeds.map((row) => {
            const copy = { ...row };
            copy.missing = "Unmapped";
            return copy;
          }),
      },
    ],
  },
};
