import React, { forwardRef, useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  listbox: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

const renderGroup = (params) => [params.children];

const ListboxComponent = forwardRef(function ListboxComponentInner(
  { scrollTop, setIsScrollBottom, ...rest },
  ref
) {
  return <ul ref={ref} {...rest} />;
});

export default function FstAutocompletePaging(props) {
  const {
    name,
    optionsLabel,
    value,
    onChange,
    renderOption,
    disabledOption,
    loadOptions,
  } = props;
  let newPagingDefault = {
    searchQuery: "",
    loadedOptions: [],
    additional: { page: 1 },
  };
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [options, setOptions] = useState({
    hasMore: false,
    options: [],
    additional: { page: 1, totalItems: 0, totalPages: 0 },
  });
  const [paging, setPaging] = useState(newPagingDefault);

  useEffect(() => {
    if (options.additional.totalItems === 0) {
      getLoadOptions(paging);
    } else {
      getLoadNextPage();
    }
  }, [isScrollBottom]);

  const getLoadOptions = async (request) => {
    const result = await loadOptions(
      request.searchQuery,
      request.loadedOptions,
      request.additional
    );
    const aux = {
      ...result,
      options: [...options.options, ...result.options],
    };
    setOptions((prevOptions) => aux);
  };

  const getLoadNextPage = async () => {
    if (isScrollBottom) {
      const aux = paging;
      aux.additional.page = aux.additional.page + 1;
      if (aux.additional.page <= options.additional.totalPages) {
        setPaging(aux);
        await getLoadOptions(paging);
      }
    }
  };

  const onChangeText = (event, value) => {
    if (value.length < 4) return;
    setOptions({
      hasMore: false,
      options: [],
      additional: { page: 1, totalItems: 0, totalPages: 0 },
    });
    setTimeout(() => {
      const aux = newPagingDefault;
      aux.searchQuery = value;
      setPaging(aux);
      getLoadOptions(aux).then();
    }, 500);
  };

  const onChangeAutocomplete = (event, value) => {
    onChange(event, value);
    getLoadOptions(newPagingDefault).then();
  };

  const isDisabledOptions = (option) => {
    if (disabledOption) {
      return disabledOption(option);
    }
    return false;
  };

  const nearBottom = (target = {}) => {
    const diff = Math.round(target.scrollHeight - target.scrollTop);
    return diff - 25 <= target.clientHeight;
  };

  return (
    <>
      <Autocomplete
        id={name}
        name={name}
        value={value}
        options={options.options}
        getOptionLabel={optionsLabel}
        disableListWrap
        onChange={onChangeAutocomplete}
        renderGroup={renderGroup}
        getOptionDisabled={isDisabledOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={props.textFieldVariant ?? "outlined"}
            label={props.label}
            size={props.textFieldSize}
          />
        )}
        style={props.style ? props.style : { width: "100%" }}
        renderOption={renderOption}
        onInputChange={onChangeText}
        ListboxComponent={ListboxComponent}
        ListboxProps={{
          onScroll: ({ target }) => setIsScrollBottom(nearBottom(target)),
        }}
      />
    </>
  );
}
