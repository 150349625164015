import { SettingChange } from "../types";

const INITIAL_STATE = {
  settingsChangeActive: false,
};

export default function settingsChangeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SettingChange.SETTING_CHANGE_ACTIVE:
      return { ...state, settingsChangeActive: true };

    case SettingChange.SETTING_CHANGE_INACTIVE:
      return { ...state, settingsChangeActive: false };

    default:
      return state;
  }
}
