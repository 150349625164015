import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FstModal from "../../components/modal/FstModal";
import { Controls } from "../../utils/controls";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
  InputWidth: {
    width: "100%",
  },
}));

const initialErrors = {
  emailValid: false,
  emailErrorMsg: "",
};

const EditMailModal = ({ open, user, onClose, onSave }) => {
  const classes = useStyles();
  const { variant } = Controls.TextField;
  const [email, setEmail] = useState(user?.email);
  const [errors, setErrors] = useState(initialErrors);

  useEffect(() => {
    setEmail(user?.email);
    validateEmail(user?.email);
  }, [user]);

  const handleChange = (e) => {
    validateEmail(e.target.value);
    setEmail(e.target.value);
  };

  const validateEmail = (value) => {
    let emailErrorMsg = "";
    let emailValid = false;

    if (value?.trim()) {
      emailValid =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value,
        );
      emailErrorMsg = emailValid ? "" : "Email Invalido";
    }
    setErrors({ emailValid, emailErrorMsg });
  };

  const handleSave = async () => {
    if (!errors.emailValid) {
      return;
    }
    if (email.trim()) {
      await onSave({ ...user, email });
      onClose();
    }
  };

  return (
    <FstModal
      open={open}
      title={"Editar Email"}
      handleClose={onClose}
      handleSave={handleSave}
      widthSize="md"
    >
      <Grid container spacing={2} style={{ width: 500 }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required={true}
            variant="outlined"
            id="email"
            label="Email"
            size="small"
            className={classes.InputWidth}
            value={email}
            onChange={handleChange}
            helperText={errors.emailErrorMsg}
            error={!errors.emailValid}
          />
        </Grid>
      </Grid>
    </FstModal>
  );
};

export default EditMailModal;
