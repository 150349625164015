import { PenaltyType } from "../types";
import {
  GetPenaltiesList,
  ManagePenalty,
} from "../views/penalties/service/penaltyService";
import { translateCurrentPageForClient } from "../utils/common";

export const GetPenalties = (pagination, filter) => async (dispatch) => {
  try {
    const request = {
      OrderBy: pagination.orderBy,
      PageSize: pagination.pageSize,
      Filter: pagination.search,
      PageNumber: pagination.PageNumber,
      OrderAsc: pagination.OrderAsc,
    };
    const axiosResult = await GetPenaltiesList(request);

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;

      // flatten with json parse the "data" field in data.currentPageItems
      // (this is done to obtain all the generic fields in the data because it has different intl fields)
      const currentPageItems = data.currentPageItems.map((item) => {
        return { ...item, ...JSON.parse(item.data) };
      });

      dispatch({
        type: PenaltyType.ALL_PENALTY,
        payload: {
          result: currentPageItems,
          count: data.totalItems,
        },
        page: translateCurrentPageForClient(data.currentPage),
        pageSize: data.pageSize,
        reloadDefault: false,
      });
    } else {
      dispatch({
        type: PenaltyType.ALL_PENALTY_ERROR,
        error: "Error fetching penalties data",
      });
    }
  } catch (error) {
    dispatch({
      type: PenaltyType.ALL_PENALTY_ERROR,
      error: "Error fetching penalties data",
    });
  }
};

export const GetPenaltiesExport = (pagination, filter) => async () => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
  };
  const axiosResult = await GetPenaltiesList(request);
  if (!!axiosResult && !!axiosResult.data) {
    // flatten with json parse the "data" field in data.currentPageItems
    const currentPageItems = axiosResult.data.currentPageItems.map((item) => {
      return { ...item, ...JSON.parse(item.data) };
    });
    return currentPageItems;
  }
  return null;
};

export const ManagePenaltySave = (penalty) => async (dispatch) => {
  try {
    await ManagePenalty(penalty);
    dispatch({
      type: PenaltyType.ALL_PENALTY_DEFAULT,
      reloadDefault: true,
    });
  } catch (error) {
    dispatch({
      type: PenaltyType.ALL_PENALTY_ERROR,
    });
  }
};

export const GetPenaltiesDefault = () => (dispatch) => {
  dispatch({
    type: PenaltyType.ALL_PENALTY_DEFAULT,
    reloadDefault: true,
  });
};
