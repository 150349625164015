// @flow
import React, { useEffect, useContext } from "react";
import type { Node } from "react";
import clsx from "clsx";
import { fade, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import withWidth from "@material-ui/core/withWidth";
import FstSpinner from "../spinner/FstSpinner";
import { langContext } from "../../intl/context/langContext";
import logoFalconBlock from "../../assets/img/imgFalcon2.png";
import logoFalconLine from "../../assets/img/imgFalcon3.png";
import FstContainerGeneral from "../utils/FstContainerGeneral";

const AppBarClippedDrawer = ({
  title,
  rightMenu,
  drawerWidth = 240,
  menuItems,
  content,
  width,
  language,
  appVersion,
}: {
  title: string,
  rightMenu?: Node,
  drawerWidth?: number,
  menuItems: Node,
  content?: Node,
}) => {
  let startWithDrawerOpened = false;
  // if(width === "md" || width === "lg" || width === "xl"){
  //     startWithDrawerOpened = true;
  // }
  const [open, setOpen] = React.useState(startWithDrawerOpened);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    body: {
      height: window.innerHeight - 50, //,
      //overflow: 'auto'
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      zIndex: 600,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
      zIndex: 600,
    },
    spacer: {
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      width: "150px",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: 120,
        "&:focus": {
          width: 200,
        },
      },
    },
    contentImg: {
      height: "100%",
      position: "relative",
    },
    contentInfo: {
      position: "absolute",
      bottom: "10px",
      width: "100%",
      textAlign: "center",
    },
    imagFalconLine: {
      maxWidth: "120px",
      position: "relative",
      marginBottom: "5px",
      display: "block",
      marginLeft: "25%",
    },
    imagFalconBlock: {
      maxWidth: "35px",
      position: "relative",
      display: "block",
      marginLeft: "20%",
    },
    spanVersion: {
      position: "relative",
      fontSize: "0.8rem",
      fontStyle: "italic",
      fontWeight: "500",
      color: "rgb(46 46 46 / 87%);",
      marginLeft: "18px",
    },
  }));
  const classes = useStyles();
  const langChange = useContext(langContext);

  useEffect(() => {
    langChange.setLanguaje(language);
  }, []);

  function toggleDrawerOpen() {
    setOpen(!open);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} id="main-header">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawerOpen}
            edge="start"
            className={classes.menuButton}
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>

          <Typography variant="h6" noWrap className={classes.title}>
            {title}
          </Typography>

          {!!rightMenu && rightMenu}
        </Toolbar>
      </AppBar>

      {
        /*width === "md" ||*/ width === "lg" || width === "xl" ? (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              }),
            }}
            open={open}
          >
            <div className={classes.spacer} />
            {menuItems}
            <React.Fragment>
              <div className={classes.contentImg}>
                <div className={classes.contentInfo}>
                  {open ? (
                    <>
                      <img
                        src={logoFalconLine}
                        alt="photo_falcon"
                        className={classes.imagFalconLine}
                      />
                      <span
                        className={classes.spanVersion}
                      >{`Versión: ${appVersion}`}</span>
                    </>
                  ) : (
                    <img
                      src={logoFalconBlock}
                      alt="photo_falcon"
                      className={classes.imagFalconBlock}
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          </Drawer>
        ) : (
          <Drawer
            open={open}
            onClose={toggleDrawerOpen}
            onClick={toggleDrawerOpen}
          >
            {menuItems}
          </Drawer>
        )
      }

      <main className={classes.content}>
        <div className={classes.spacer} />
        <FstSpinner>
          <div className={classes.body}>{content}</div>
        </FstSpinner>
      </main>

      <FstContainerGeneral />
    </div>
  );
};

export default withWidth()(AppBarClippedDrawer);
