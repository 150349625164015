import { useIntl } from "react-intl";
import { isLangCR, isLangAR } from "utils/common";

const useOutsiderTenderColumns = () => {
  const intl = useIntl();

  const tenderValues = [
    // tender
    {
      columnValue: "tenderNumber",
      columnName: `${intl.formatMessage({
        id: "cols.Id",
        defaultMessage: "Id",
      })}`,
      required: true,
    },
    {
      columnValue: "fileNumber",
      columnName: `${intl.formatMessage({
        id: "cols.Numenclatura",
        defaultMessage: "Nomenclatura",
      })}`,
      required: true,
    },
    {
      columnValue: "publishedDate",
      columnName: `${intl.formatMessage({
        id: "cols.Publicacion",
        defaultMessage: "Publicación",
      })}`,
      required: true,
      type: "date",
    },
    {
      columnValue: "tenderName",
      columnName: `${intl.formatMessage({
        id: "cols.Nombre",
        defaultMessage: "Nombre",
      })}`,
      required: true,
    },
    {
      columnValue: "tenderDescription",
      columnName: `${intl.formatMessage({
        id: "cols.Descripcion",
        defaultMessage: "Descripción",
      })}`,
      required: true,
    },
    {
      columnValue: "sourceName",
      columnName: `${intl.formatMessage({
        id: "cols.Portal",
        defaultMessage: "Portal",
      })}`,
      required: true,
    },
    {
      columnValue: "currency",
      columnName: `${intl.formatMessage({
        id: "cols.Moneda",
        defaultMessage: "Moneda",
      })}`,
      required: true,
      type: "select",
      options: [],
    },
    {
      columnValue: "estimatedValue",
      columnName: `${intl.formatMessage({
        id: "cols.Referencial",
        defaultMessage: "Valor Estimado",
      })} (Número)`,
      type: "number",
      required: true,
    },
    {
      columnValue: "tenderType",
      columnName: `${intl.formatMessage({
        id: "cols.Tipo",
        defaultMessage: "Tipo",
      })}`,
      required: true,
      type: "select",
      options: [],
    },
    {
      columnValue: "tenderStatus",
      columnName: `${intl.formatMessage({
        id: "cols.Estado",
        defaultMessage: "Estado",
      })}`,
      required: true,
      type: "select",
      options: [],
    },
    {
      columnValue: "clientUnitUniqueId",
      columnName: `ID único de ${intl.formatMessage({
        id: "cols.Entidad",
        defaultMessage: "Entidad",
      })} (CUIT)`, // (CUIT) es solo ARG, para otros paises luego ver
      required: true,
    },
    {
      columnValue: "clientUnitName",
      columnName: `${intl.formatMessage({
        id: "cols.Entidad",
        defaultMessage: "Entidad",
      })} (Entidad/Cliente)`, // (Entidad/Cliente) es solo ARG, para otros paises luego ver
      required: true,
    },
    //scrapedDate: automatic
    //creationDate: automatic
    //lastUpdate: automatic
    {
      columnValue: "endDate",
      columnName: `${intl.formatMessage({
        id: "cols.Cierre",
        defaultMessage: "Cierre",
      })}`,
      required: false,
      type: "date",
    },
    {
      columnValue: "awardDate",
      columnName: `Fecha de Adjudicación`,
      required: false,
      type: "date",
    },
    ...(isLangAR()
      ? [
          //tender
          {
            columnValue: "link",
            columnName: "Link",
            required: false,
          },
          {
            columnValue: "bidSecurity",
            columnName: "Garantía de oferta",
            required: false,
          },
          {
            columnValue: "faithfulObservance",
            columnName: "Garantía de cumplimiento",
            required: false,
          },
        ]
      : []),
  ];

  const tenderDetailValues = [
    //tender details
    {
      columnValue: "tenderDetailItemNumber",
      columnName: `${intl.formatMessage({
        id: "cols.Item",
        defaultMessage: "Item",
      })} (Número)`,
      type: "number",
      required: true,
    },
    {
      columnValue: "tenderDetailDescription",
      columnName: `${intl.formatMessage({
        id: "cols.Desc",
        defaultMessage: "Desc. Producto/Servicio",
      })}`,
      required: true,
    },
    {
      columnValue: "tenderDetailQty",
      columnName: `${intl.formatMessage({
        id: "cols.Cantidad",
        defaultMessage: "Cantidad",
      })} (Número)`,
      type: "number",
      required: true,
    },
    {
      columnValue: "tenderDetailUom",
      columnName: `${intl.formatMessage({
        id: "cols.Unidad",
        defaultMessage: "Unidad",
      })}`,
      required: true,
    },
    {
      columnValue: "tenderDetailEstimatedValue",
      columnName: `${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })} (Número)`,
      type: "number",
      required: true,
    },
    //creationDate: automatic
    //lastUpdate: automatic
    {
      columnValue: "tenderDetailCode",
      columnName: `${intl.formatMessage({
        id: "tabL.Codigo_Cubso",
        defaultMessage: "Código CUBSO",
      })}`,
      required: false,
    },
    {
      columnValue: "tenderDetailEstimatedValueCurrency",
      columnName: `Moneda del ${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })}`,
      required: false,
      type: "select",
      options: [],
    },
    ...(isLangAR()
      ? [
          //tender detail
          {
            columnValue: "tenderDetailObservation",
            columnName: `Observaciones`,
            required: false,
          },
          {
            columnValue: "tenderDetailSpecification",
            columnName: `Especificaciones`,
            required: false,
          },
          {
            columnValue: "tenderDetailConditioning",
            columnName: `Acondicionamiento`,
            required: false,
          },
        ]
      : []),
  ];

  // tender details award
  // bonification y pomotionalLaw son extension y de peru
  // they are mandatory if an award is present (check how todo it)
  const tenderDetailAwardValues = [
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedCompetitorUniqueId",
      columnName: `ID único Competidor (CUIT)`, // (CUIT) es solo ARG, para otros paises luego ver
      required: true,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedCompetitorName",
      columnName: `Competidor`,
      required: true,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedAmmount",
      columnName: `Monto (Número)`,
      type: "number",
      required: true,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedQty",
      columnName: `Cantidad adjudicada (Número)`,
      type: "number",
      required: true,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedCurrency",
      columnName: `Moneda`,
      required: true,
      type: "select",
      options: [],
    },
    {
      columnValue: "tenderDetailAwardedCompetitorDescription", // not mandatory
      columnName: `Descripción Competidor`,
      required: false,
    },
  ];
  //creationDate: automatic
  //lastUpdate: automatic

  //tender manage
  const tenderManageValues = [
    // EJECUTIVO  (debe existir en el sistema) --> Sellers
    {
      columnValue: "tenderManageSeller",
      columnName: "Ejecutivo",
      required: false,
      type: "select",
      options: [],
    },
    // SEGMENTO DE MERCADO (debe existir en el sistema) --> Categories
    {
      columnValue: "tenderManageCategory",
      columnName: "Segmento de Mercado",
      required: false,
      type: "select",
      options: [],
    },
    // ETIQUETAS  (debe existir en el sistema) --> Tags
    {
      columnValue: "tenderManageTag",
      columnName: "Etiqueta",
      required: false,
      type: "select",
      options: [],
    },
  ];

  const tenderManageDetailValues = [
    //tender manage detail
    {
      columnValue: "tenderManageDetailOffered",
      columnName: `Cotizado`,
      required: true,
      type: "select",
      options: [
        { id: true, name: "Si" },
        { id: false, name: "No" },
      ],
    },
    // FECHA DE COTIZACION
    {
      columnValue: "tenderManageDetailOfferedDate",
      columnName: `Fecha de Cotización`,
      required: false,
      type: "date",
    },
    // COMENTARIOS
    {
      columnValue: "tenderManageDetailComments",
      columnName: `Comentarios`,
      required: false,
    },
    // FECHA DE ENTREGA (only CR)
    ...(isLangCR()
      ? [
          {
            columnValue: "tenderManageDetailDeliveryDate",
            columnName: `Fecha de Entrega`,
            required: false,
            type: "date",
          },
        ]
      : []),
    // ITEM ALTERNATIVO --> no

    // PRODUCT ID (debe existir en el catalogo del cliente) (usamos code)
    {
      columnValue: "tenderManageDetailProductCode",
      columnName: `Código Producto`,
      required: false,
      type: "productAutoComplete",
    },
    {
      columnValue: "tenderManageDetailEstimatedTotal",
      columnName: `Estimado (Número)`,
      type: "number",
      required: false,
    },
  ];

  const minimumColumns = [
    // tender
    {
      columnValue: "tenderNumber",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Id",
        defaultMessage: "Id",
      })} (Texto)`,
      columnPosition: 0,
      modifiable: false,
    },
    {
      columnValue: "fileNumber",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Numenclatura",
        defaultMessage: "Nomenclatura",
      })} (Texto)`,
      columnPosition: 1,
      modifiable: false,
    },
    {
      columnValue: "publishedDate",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Publicacion",
        defaultMessage: "Publicación",
      })} (Fecha)`,
      columnPosition: 2,
      modifiable: false,
    },
    {
      columnValue: "tenderName",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Nombre",
        defaultMessage: "Nombre",
      })} (Texto)`,
      columnPosition: 3,
      modifiable: false,
    },
    {
      columnValue: "tenderDescription",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Descripcion",
        defaultMessage: "Descripción",
      })} (Texto)`,
      columnPosition: 4,
      modifiable: false,
    },
    {
      columnValue: "sourceName",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Portal",
        defaultMessage: "Portal",
      })} (Texto)`,
      columnPosition: 5,
      modifiable: false,
    },
    {
      columnValue: "currency",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Moneda",
        defaultMessage: "Moneda",
      })} (Texto, opciones provistas)`,
      columnPosition: 6,
      modifiable: false,
    },
    {
      columnValue: "tenderType",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Tipo",
        defaultMessage: "Tipo",
      })} (Texto, opciones provistas)`,
      columnPosition: 7,
      modifiable: false,
    },
    {
      columnValue: "tenderStatus",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Estado",
        defaultMessage: "Estado",
      })} (Texto, opciones provistas)`,
      columnPosition: 8,
      modifiable: false,
    },
    {
      columnValue: "clientUnitUniqueId",
      columnName: `Licitación: ID único de ${intl.formatMessage({
        id: "cols.Entidad",
        defaultMessage: "Entidad",
      })} (Texto/Número)`,
      columnPosition: 9,
      modifiable: false,
    },
    {
      columnValue: "clientUnitName",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Entidad",
        defaultMessage: "Entidad",
      })} (Texto)`,
      columnPosition: 10,
      modifiable: false,
    },
    {
      columnValue: "endDate",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Cierre",
        defaultMessage: "Cierre",
      })} (Fecha)`,
      columnPosition: 11,
      modifiable: true,
    },
    {
      columnValue: "awardDate",
      columnName: `Licitación: Fecha de Adjudicación (Fecha)`,
      columnPosition: 12,
      modifiable: true,
    },
    {
      columnValue: "link",
      columnName: "Licitación: Link (Texto)",
      columnPosition: 13,
      modifiable: true,
    },
    {
      columnValue: "bidSecurity",
      columnName: "Licitación: Garantía de oferta (Texto)",
      columnPosition: 14,
      modifiable: true,
    },
    {
      columnValue: "faithfulObservance",
      columnName: "Licitación: Garantía de cumplimiento (Texto)",
      columnPosition: 15,
      modifiable: true,
    },
    // Tender manage
    {
      columnValue: "tenderManageSeller",
      columnName: "Gestión: Ejecutivo (Texto)",
      columnPosition: 16,
      modifiable: true,
    },
    // SEGMENTO DE MERCADO (debe existir en el sistema) --> Categories
    {
      columnValue: "tenderManageCategory",
      columnName: "Gestión: Segmento de Mercado (Texto)",
      columnPosition: 17,
      modifiable: true,
    },
    // ETIQUETAS  (debe existir en el sistema) --> Tags
    {
      columnValue: "tenderManageTag",
      columnName: "Gestión: Etiqueta (Texto)",
      columnPosition: 18,
      modifiable: true,
    },

    //tender details
    {
      columnValue: "tenderDetailItemNumber",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "cols.Item",
        defaultMessage: "Item",
      })} (Número)`,
      columnPosition: 19,
      modifiable: false,
    },
    {
      columnValue: "tenderDetailDescription",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "cols.Desc",
        defaultMessage: "Desc. Producto/Servicio",
      })} (Texto)`,
      columnPosition: 20,
      modifiable: false,
    },
    {
      columnValue: "tenderDetailQty",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "cols.Cantidad",
        defaultMessage: "Cantidad",
      })} (Número)`,
      columnPosition: 21,
      modifiable: false,
    },
    {
      columnValue: "tenderDetailUom",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "cols.Unidad",
        defaultMessage: "Unidad",
      })} (Texto)`,
      columnPosition: 22,
      modifiable: false,
    },
    {
      columnValue: "tenderDetailEstimatedValue",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })} (Número)`,
      columnPosition: 23,
      modifiable: false,
    },
    {
      columnValue: "tenderDetailCode",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "tabL.Codigo_Cubso",
        defaultMessage: "Código CUBSO",
      })} (Texto)`,
      columnPosition: 24,
      modifiable: true,
    },
    {
      columnValue: "tenderDetailEstimatedValueCurrency",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Moneda del ${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })} (Texto, opciones provistas)`,
      columnPosition: 25,
      modifiable: true,
    },
    {
      columnValue: "tenderDetailObservation",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Observaciones (Texto)`,
      columnPosition: 26,
      modifiable: true,
    },
    {
      columnValue: "tenderDetailSpecification",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Especificaciones (Texto)`,
      columnPosition: 27,
      modifiable: true,
    },
    {
      columnValue: "tenderDetailConditioning",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Acondicionamiento (Texto)`,
      columnPosition: 28,
      modifiable: true,
    },

    //tender manage detail
    {
      columnValue: "tenderManageDetailOffered",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Cotizado (Si/No)`,
      columnPosition: 29,
      modifiable: false,
    },
    //manage detail
    // FECHA DE COTIZACION
    {
      columnValue: "tenderManageDetailOfferedDate",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Fecha de Cotización (Fecha)`,
      columnPosition: 30,
      modifiable: true,
    },
    // COMENTARIOS
    {
      columnValue: "tenderManageDetailComments",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Comentarios (Texto)`,
      columnPosition: 31,
      modifiable: true,
    },
    // PRODUCT ID (debe existir en el catalogo del cliente) (usamos code)
    {
      columnValue: "tenderManageDetailProductCode",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Código Producto (Texto)`,
      columnPosition: 32,
      modifiable: true,
    },
    {
      columnValue: "tenderManageDetailEstimatedTotal",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Estimado (Número)`,
      columnPosition: 33,
      modifiable: true,
    },

    //tender detail award
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedCompetitorUniqueId",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - ID único Competidor (CUIT) (Texto/Número)`,
      columnPosition: 34,
      modifiable: true,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedCompetitorName",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Competidor (Texto)`,
      columnPosition: 35,
      modifiable: true,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedAmmount",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Monto (Número)`,
      columnPosition: 36,
      modifiable: true,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedQty",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Cantidad adjudicada (Número)`,
      columnPosition: 37,
      modifiable: true,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedCurrency",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Moneda (Texto, opciones provistas)`,
      columnPosition: 38,
      modifiable: true,
    },
    {
      columnValue: "tenderDetailAwardedCompetitorDescription", // not mandatory
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Descripción Competidor (Texto)`,
      columnPosition: 39,
      modifiable: true,
    },
  ];

  const extraColumns = [
    ...(isLangAR()
      ? [
          //tender
          {
            columnValue: "link",
            columnName: "Licitación: Link (Texto)",
          },
          {
            columnValue: "bidSecurity",
            columnName: "Licitación: Garantía de oferta (Texto)",
          },
          {
            columnValue: "faithfulObservance",
            columnName: "Licitación: Garantía de cumplimiento (Texto)",
          },
          //tender detail
          {
            columnValue: "tenderDetailObservation",
            columnName: `Licitación: ${intl.formatMessage({
              id: "tab.ListaItems",
              defaultMessage: "Lista de Items",
            })} - Observaciones (Texto)`,
          },
          {
            columnValue: "tenderDetailSpecification",
            columnName: `Licitación: ${intl.formatMessage({
              id: "tab.ListaItems",
              defaultMessage: "Lista de Items",
            })} - Especificaciones (Texto)`,
          },
          {
            columnValue: "tenderDetailConditioning",
            columnName: `Licitación: ${intl.formatMessage({
              id: "tab.ListaItems",
              defaultMessage: "Lista de Items",
            })} - Acondicionamiento (Texto)`,
          },
        ]
      : []),
    //tender
    {
      columnValue: "endDate",
      columnName: `Licitación: ${intl.formatMessage({
        id: "cols.Cierre",
        defaultMessage: "Cierre",
      })} (Fecha)`,
    },
    {
      columnValue: "awardDate",
      columnName: `Licitación: Fecha de Adjudicación (Fecha)`,
    },
    //tender detail
    {
      columnValue: "tenderDetailCode",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - ${intl.formatMessage({
        id: "tabL.Codigo_Cubso",
        defaultMessage: "Código CUBSO",
      })} (Texto)`,
    },
    {
      columnValue: "tenderDetailEstimatedValueCurrency",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Moneda del ${intl.formatMessage({
        id: "tabL.Valor_Referencia",
        defaultMessage: "Valor Referencia/Estimado Total",
      })} (Texto, opciones provistas)`,
    },
    //tender detail award
    {
      // mandatory if an award is present (see how to do it)
      columnValue: "tenderDetailAwardedCompetitorUniqueId",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - ID único Competidor (CUIT) (Texto/Número)`,
    },
    {
      // mandatory if an award is present (see how to do it)
      columnValue: "tenderDetailAwardedCompetitorName",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Competidor (Texto)`,
    },
    {
      // mandatory if an award is present (see how to do it)
      columnValue: "tenderDetailAwardedAmmount",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Monto (Número)`,
    },
    {
      // mandatory if an award is present (see how to do it)
      columnValue: "tenderDetailAwardedQty",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Cantidad adjudicada (Número)`,
    },
    {
      // mandatory if an award is present
      columnValue: "tenderDetailAwardedCurrency",
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Moneda (Texto, opciones provistas)`,
    },
    {
      columnValue: "tenderDetailAwardedCompetitorDescription", // not mandatory
      columnName: `Licitación: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Adjudicación/Oferta - Descripción Competidor (Texto)`,
    },

    //manage
    // EJECUTIVO  (debe existir en el sistema) --> Sellers
    {
      columnValue: "tenderManageSeller",
      columnName: "Gestión: Ejecutivo (Texto)",
    },
    // SEGMENTO DE MERCADO (debe existir en el sistema) --> Categories
    {
      columnValue: "tenderManageCategory",
      columnName: "Gestión: Segmento de Mercado (Texto)",
    },
    // ETIQUETAS  (debe existir en el sistema) --> Tags
    {
      columnValue: "tenderManageTag",
      columnName: "Gestión: Etiqueta (Texto)",
    },

    //manage detail
    // FECHA DE COTIZACION
    {
      columnValue: "tenderManageDetailOfferedDate",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Fecha de Cotización (Fecha)`,
    },
    // COMENTARIOS
    {
      columnValue: "tenderManageDetailComments",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Comentarios (Texto)`,
    },
    // FECHA DE ENTREGA (only CR)
    ...(isLangCR()
      ? [
          {
            columnValue: "tenderManageDetailDeliveryDate",
            columnName: `Gestión: ${intl.formatMessage({
              id: "tab.ListaItems",
              defaultMessage: "Lista de Items",
            })} - Fecha de Entrega (Fecha)`,
          },
        ]
      : []),
    // ITEM ALTERNATIVO --> no

    // PRODUCT ID (debe existir en el catalogo del cliente) (usamos code)
    {
      columnValue: "tenderManageDetailProductCode",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Código Producto (Texto)`,
    },
    {
      columnValue: "tenderManageDetailEstimatedTotal",
      columnName: `Gestión: ${intl.formatMessage({
        id: "tab.ListaItems",
        defaultMessage: "Lista de Items",
      })} - Estimado (Número)`,
    },
  ];

  const getMinimumColumns = () => {
    return [...minimumColumns];
  };

  const getExtraColumns = () => {
    return [...extraColumns];
  };

  const getAllColumns = () => {
    let allColumns = [
      ...minimumColumns,
      // add 'columnPosition' to extraColumns
      ...extraColumns.map((column, index) => {
        return {
          ...column,
          columnPosition: minimumColumns.length + index,
          modifiable: true,
        };
      }),
    ];
    return allColumns;
  };

  const getTenderValues = (tenderTypes, tendersStatus, currencyList) => {
    // add 'tenderType' and 'tenderStatus' to tenderValues (type=select)
    let filledTenderValues = tenderValues.map((tender) => {
      if (tender.columnValue === "tenderType") {
        return { ...tender, options: tenderTypes };
      } else if (tender.columnValue === "tenderStatus") {
        return { ...tender, options: tendersStatus };
      } else if (tender.columnValue === "currency") {
        return { ...tender, options: currencyList };
      } else {
        return tender;
      }
    });

    return filledTenderValues;
  };

  const getTenderDetailValues = (currencyList) => {
    let filledTenderDetailValues = tenderDetailValues.map((tender) => {
      if (tender.columnValue === "tenderDetailEstimatedValueCurrency") {
        return { ...tender, options: currencyList };
      } else {
        return tender;
      }
    });

    return filledTenderDetailValues;
  };

  const getTenderDetailAwardValues = (currencyList) => {
    let filledTenderDetailAwardValues = tenderDetailAwardValues.map(
      (tender) => {
        if (tender.columnValue === "tenderDetailAwardedCurrency") {
          return { ...tender, options: currencyList };
        } else {
          return tender;
        }
      },
    );

    return filledTenderDetailAwardValues;
  };

  const getTenderManageValues = (sellers, tags, categories) => {
    // add 'tenderManageSeller', 'tenderManageTag' and 'tenderManageCategory' to tenderManageValues (type=select)
    let filledTenderManageValues = tenderManageValues.map((tender) => {
      if (tender.columnValue === "tenderManageSeller") {
        return { ...tender, options: sellers };
      } else if (tender.columnValue === "tenderManageTag") {
        return { ...tender, options: tags };
      } else if (tender.columnValue === "tenderManageCategory") {
        return { ...tender, options: categories };
      } else {
        return tender;
      }
    });

    return filledTenderManageValues;
  };

  const getTenderManageDetailValues = () => {
    return [...tenderManageDetailValues];
  };

  return {
    getMinimumColumns,
    getExtraColumns,
    getAllColumns,
    getTenderValues,
    getTenderDetailValues,
    getTenderDetailAwardValues,
    getTenderManageValues,
    getTenderManageDetailValues,
  };
};

export default useOutsiderTenderColumns;
