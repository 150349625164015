import { Tooltip } from "@material-ui/core";
import React from "react";
import TextClamp from "react-text-clamp";
import moment from "moment";

export const dateTimeFormat = "DD/MM/YYYY HH:mm";
export const dateTimeShortFormat = "DD/MM/YYYY";
export const IS_DEVELOPMENT = process.env.REACT_APP_IS_DEVELOPMENT;

export const formatDate = (strDate) => {
  return moment(strDate).format(dateTimeFormat);
};

export const formatShortDate = (strDate) => {
  return moment(strDate).format(dateTimeShortFormat);
};

export const getOrderAscTableValue = (orderDirection) => {
  return orderDirection === "asc";
};

export const translateCurrentPageForClient = (currentPage) => {
  // Page starts as 0 in material-table but starts as 1 in backend.
  const page = currentPage - 1;
  return page < 0 ? 0 : page;
};

export const translateCurrentPageForServer = (currentPage) => {
  // Page starts as 0 in material-table but starts as 1 in backend.
  return currentPage + 1;
};

export const translateCurrentPageForServerGridSyncFusion = (
  currentPage,
  sizePage,
) => {
  // Page starts as 0 in material-table but starts as 1 in backend.
  return currentPage / sizePage + 1;
};

export const isCurrentUrlProtocolValid = () => {
  const l = window.location.href;
  const isLocalhost = l.indexOf("localhost") > -1;
  const isStagingIp = l.indexOf("40.114.95.129") > -1;
  const isHttp = l.indexOf("http://") > -1;

  if (isHttp) {
    return isLocalhost || isStagingIp;
  }
  return true;
};

export const getMaterialTableCell = ({
  title,
  field,
  render: customRender,
  truncateLines,
  singleLineEllipsis = false,
  skipTooltip = false,
  makeTooltip,
  textAlign = "center",
  cellStyle: customClStyle,
  ...rest
}) => {
  if (!title || !field) {
    console.error("getMaterialTableCell missing mandatory data");
  }

  const cellStyle = !!customClStyle ? customClStyle : { textAlign: "center" };
  const headerStyle = !!customClStyle ? customClStyle : { textAlign: "center" };

  return {
    ...rest,
    title,
    field,
    cellStyle,
    headerStyle,
    render: (rowData) => {
      let v = !!customRender ? customRender(rowData) : rowData[field];
      const tooltipTitle =
        !!truncateLines || !customRender ? rowData[field] : v;
      if (!!truncateLines) {
        v = (
          <TextClamp
            fontSize={14}
            textAlign={textAlign}
            maxLines={truncateLines}
            rgbBgColor={"rgb(255,255,255)"}
          >
            <div>{v}</div>
          </TextClamp>
        );
      }

      let contentStyle = {};
      if (singleLineEllipsis) {
        contentStyle = {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        };
      }

      return !!tooltipTitle && !skipTooltip ? (
        <Tooltip
          title={!!makeTooltip ? makeTooltip(rowData) : tooltipTitle}
          placement="top"
        >
          <div style={contentStyle}>{v}</div>
        </Tooltip>
      ) : (
        <div style={contentStyle}>{v}</div>
      );
    },
  };
};

export const compareString = (value, compare) => {
  const newString = value
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase();
  let existing = [];
  compare.map((item) => {
    existing.push(newString.indexOf(item.toUpperCase()) === -1 ? false : true);
  });
  return existing.indexOf(true) === -1 ? false : true;
};

export const getDataJson = (key, data) => {
  const dataParse = JSON.parse(data);
  const value = dataParse[key] ? dataParse[key] : "";
  return value;
};

export const strcmpWithoutAccent = (a, b) => {
  return (
    a
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") ===
    b
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  );
};

export const getDataJsonComplex = (keys, data) => {
  const dataParse = JSON.parse(data);
  if (keys) {
    const arrayKey = keys.split(".");
    let value = dataParse[arrayKey[0]] ? dataParse[arrayKey[0]] : "";
    arrayKey.map((key, index) => {
      if (index >= 1) {
        value = value[key] ? value[key] : "";
      }
    });
    return value;
  }
  return dataParse;
};

export const getDataJsonBucle = (keys, data, types) => {
  const dataParse = JSON.parse(data);
  if (keys && types) {
    const ArrayKeys = keys.split(".");
    const ArrayTypes = types.split(".");
    let auxArray = dataParse[ArrayKeys[0]] ? dataParse[ArrayKeys[0]] : [];
    ArrayTypes.map((type, index) => {
      if (index >= 1) {
        auxArray = auxArray[ArrayKeys[index]] ? auxArray[ArrayKeys[index]] : "";
      }
      if (type === "J") {
        auxArray = JSON.parse(auxArray);
      }
    });
    return auxArray;
  }
  return dataParse;
};

export const formatCurrency = (value, fixed) => {
  return value ? value.toFixed(fixed) : value;
};

export const formatCurrencyLocale = (value, fixed, currency) => {
  const stringCurrency = currency ? `[${currency}]` : "";
  return value
    ? value.toLocaleString("de-DE", {
        minimumFractionDigits: fixed,
        maximumFractionDigits: fixed,
      }) + ` ${stringCurrency}`
    : null;
};

export const isAwardedContract = (modalityType) => {
  return modalityType === "Según demanda";
};

export const isLangCR = () => {
  return JSON.parse(localStorage.getItem("state")).user?.language === "es-CR";
};

export const isLangPE = () => {
  return JSON.parse(localStorage.getItem("state")).user?.language === "es-PE";
};

export const isLangAR = () => {
  return JSON.parse(localStorage.getItem("state")).user?.language === "es-AR";
};

export const isLangMX = () => {
  return JSON.parse(localStorage.getItem("state")).user?.language === "es-MX";
};

export const getLang = () => {
  return JSON.parse(localStorage.getItem("state")).user?.language;
};
