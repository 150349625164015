import React, { useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { setUserApplicationModules } from "../tender-information/services/usersService";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(3),
    },
  },
}));

const UserManagerInput = (props) => {
  const classes = useStyles();
  const listModuleApp = props.appModules;
  const listModuleUser = props.appUserModule;

  const actualizarData = async (app) => {
    const request = {
      userId: props.idUser,
      applicationModuleIds: app,
    };

    await setUserApplicationModules(request);
  };

  const handlerChange = (event, value) => {
    const getValue = value.map((item) => {
      return item.id;
    });
    actualizarData(getValue);
  };

  const getListDeault = (listApp, listUser) => {
    const listDefault = listApp.filter((items) => {
      if (listUser.some((item) => item.name === items.name)) {
        return items;
      }
    });
    return listDefault;
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className={classes.root + " div-usemanager"}>
      <Autocomplete
        multiple
        limitTags={3}
        id={"multiple-limit-tags" + props.idUser}
        options={listModuleApp}
        getOptionLabel={(option) => option.name}
        defaultValue={getListDeault(listModuleApp, listModuleUser)}
        onChange={handlerChange}
        renderInput={(params) => (
          <TextField {...params} variant="standard" placeholder="Modulos" />
        )}
      />
    </div>
  );
};

export default UserManagerInput;
