import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tenders from "./tender/Tenders";
import Grid from "@material-ui/core/Grid";
import * as TenderAction from "../../actions/manageTenderActions";
import { StylePage } from "../../style/contentStyle";
import * as SpinnerActions from "../../actions/spinnerActions";
import TenderGestionNavigation from "./TenderGestionNavigation";
import { ManageTenderPanel } from "../../types";
import Penalties from "../penalties/Penalties";
import LegalDocuments from "../legal-document/LegalDocuments";
import TenderCalendar from "./calendar/TenderCalendar";
import { withRouter } from "react-router-dom";
import {
  useAppInsightsContext,
  useTrackMetric,
} from "@microsoft/applicationinsights-react-js";

const ManageTender = (props) => {
  const classes = StylePage();
  const [moduleSelected, setModuleSelected] = useState(
    ManageTenderPanel.TENDER,
  );
  const [height, setHeight] = useState();
  const [heightTable, setHeightTable] = useState();
  const appInsights = useAppInsightsContext();
  const trackComponentMetric = useTrackMetric(appInsights, "GL: Time on page");
  useEffect(() => {
    trackComponentMetric();
    return () => {
      trackComponentMetric();
    };
  }, []);

  const selectedCard = {
    [ManageTenderPanel.TENDER]: () => (
      <Tenders height={heightTable} keepFilter={props.location.state} />
    ),
    [ManageTenderPanel.PENALTY]: () => <Penalties height={heightTable} />,
    [ManageTenderPanel.LEGAL_DOCUMENT]: () => (
      <LegalDocuments height={heightTable} />
    ),
    [ManageTenderPanel.CALENDAR]: () => <TenderCalendar height={height} />,
  };
  const handleResize = () => {
    const padding = 130;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
    setHeightTable(rest - 100);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showModule = (item) => {
    setModuleSelected(item.module);
  };

  return (
    <>
      {height && (
        <Grid container className={classes.content + " grid-dispay"}>
          <TenderGestionNavigation show={showModule} />
          <Grid item xs={12} sm={12} md={12}>
            {selectedCard[moduleSelected]()}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { app } = state;
  const { tendersStatus } = app;

  return {
    ...ownProps,
    tendersStatus,
  };
};

export default withRouter(
  connect(mapStateToProps, { ...TenderAction, ...SpinnerActions })(
    ManageTender,
  ),
);
