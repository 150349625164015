import Grid from "@material-ui/core/Grid";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isLangCR } from "utils/common";
import * as SpinnerActions from "../../actions/spinnerActions";
import { getTenderInformation } from "../../actions/tenderInformationActions";
import SecondaryMenu from "../../components/SubMenu/SecondaryMenu";
import useLangForm from "../../intl/utils/hooks/useLangForm";
import { StylePage } from "../../style/contentStyle";
import { TenderPanel } from "../../types";
import ContentSubCardTender from "./ContentSubCardTender";
import TenderInfo from "./TenderInfo";
import menuConfig from "./TenderMenuObject";

const loadingMenu = {
  etq_title: TenderPanel.PANTALLA_CARGA,
  href: "/cargando",
  idLang: "tab.Pantalla de Carga",
  isDefault: true,
  showActionPanel: false,
  title: TenderPanel.PANTALLA_CARGA,
};

const ContentTenderInformation = (props) => {
  const classes = StylePage();
  const GESTION = "gestion-informacion";
  const [menuList, setMenuList] = useState([]);
  const [menu, setMenu] = useState(null);
  const [tender, setTender] = useState(null);
  const route =
    props.match.path.split("/").indexOf(GESTION) > -1
      ? "/gestion"
      : "/licitaciones";
  const [isManaged, setIsManaged] = useState(
    props.match.path.split("/").indexOf(GESTION) > -1,
  );
  const filterForm = useLangForm();
  const [height, setHeight] = useState();

  const getMenu = (tenderInfo) => {
    let list = null;
    let menuDefault = null;
    if (isManaged) {
      list = menuConfig;
      //menuDefault = list.filter(item => item.isDefault)[0].items.filter(item => item.isDefault)[0];
      let newList = JSON.parse(JSON.stringify(getMapListMenu(list))); // Copy list to prevent filtering and then not being able to restore them
      // filter "Contratos" menu if tender status is not "Contrato"
      // and also "Ordenes de pedido" if modalityType is not "Según demanda", otherwise there wouldn't be data to show
      if (isLangCR() && tenderInfo) {
        if (tenderInfo.portalStatus !== "Contrato") {
          newList[0].items = newList[0].items.filter(
            (item) => item.etq_title !== "Contratos",
          );
        }
        if (
          tenderInfo.portalStatus !== "Contrato" ||
          tenderInfo.modalityType !== "Según demanda"
        ) {
          newList[0].items = newList[0].items.filter(
            (item) => item.etq_title !== "Ordenes de Pedido",
          );
        }
      }
      //
      setMenuList(newList);
      menuDefault = newList[0].items[0]; // TODO: Use filter by 'subheader' == 'Info del Portal' then access first elem
      setMenu(menuDefault);
    } else {
      list = menuConfig.filter((item) => item.subheader === TenderPanel.SEACE);
      //menuDefault = list[0].items.filter(item => item.isDefault)[0];
      const newList = getMapListMenu(list);
      setMenuList(newList);
      menuDefault = newList[0].items[0]; // TODO: same as above
      setMenu(menuDefault);
    }
  };

  const getMapListMenu = (list) => {
    list[0].items = filterForm.FormatFilterForm(
      "TenderGestion",
      "menuInfo",
      list[0].items,
    );
    list[1].items = filterForm.FormatFilterForm(
      "TenderGestion",
      "menuGestion",
      list[1].items,
    );
    return list;
  };

  const reloadPage = () => {
    //setMenu(null)
    props.history.push(props.location.pathname);
  };

  const reloadPageOnSameMenu = () => {
    clearMenu();
  };

  const handleResize = useCallback(() => {
    const paddingButtom = 48;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + paddingButtom);
    //restWidth()
    setHeight(rest);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      async function fetchData() {
        const { tenderNumber } = props.match.params;
        return getTender(tenderNumber);
      }
      fetchData()
        .then((response) => {
          getMenu(response);
        })
        .catch(console.error);
    });
    return () => {
      clearTimeout(timer);
    };
  }, [props.match]);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const getTender = async (number) => {
    const axiosResult = await getTenderInformation(number);
    setTender(axiosResult.data);
    return axiosResult.data;
  };

  const menuSelected = (value) => {
    setMenu(value);
  };

  const onChangeTender = (key, value) => {
    setTender({
      ...tender,
      [key]: value,
    });
  };

  const clearMenu = () => {
    let oldMenu = menu;
    props.SpinnerShow("Cargando...");
    setMenu(loadingMenu);
    setTimeout(() => {
      setMenu(oldMenu);
      props.SpinnerHide();
    }, 500);
  };

  return (
    <>
      {tender && menu && (
        <>
          <Grid container className={classes.content}>
            <Grid item xs={4} sm={4} md={3} lg={2}>
              <SecondaryMenu
                menuConfig={menuList}
                selectedMenu={menu}
                handleMenu={menuSelected}
                mHeight={height}
              />
            </Grid>
            <Grid item xs={8} sm={8} md={9} lg={10}>
              <TenderInfo tender={tender} />
              <ContentSubCardTender
                menu={menu}
                menuCompleteList={menuList}
                tender={tender}
                route={route}
                reloadPage={reloadPage}
                onChangeTender={onChangeTender}
                getTender={getTender}
                isManaged={isManaged}
                clearMenu={clearMenu}
                reloadPageOnSameMenu={reloadPageOnSameMenu}
              />
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  withRouter(ContentTenderInformation),
);
