import React from "react";
import Grid from "@material-ui/core/Grid";
import EditAssignments from "./EditAssignments";
import AttachFiles from "../../../components/files/AttachFiles";
import { TenderParameter } from "../services/tenderParameter";

const Assignments = (props) => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6}>
          <EditAssignments
            assignments={props.assignments}
            handleAssignments={props.handleAssignments}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <AttachFiles
            onChange={props.handleFiles}
            files={props.files}
            MaxFiles={TenderParameter.MaxFiles}
            MaxFileSize={TenderParameter.MaxFileSize10MB}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Assignments;
