export const HeaderTable = [
  {
    field: "name",
    headerText: "Nombre",
    width: 100,
    textAlign: "Left",
    visible: true,
    isPrimaryKey: false,
  },
];
