// @flow
import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {
  AccountCircle,
  Edit,
  FindInPage,
  Assessment,
  Shop,
  EventNote,
} from "@material-ui/icons";
import { DrawerTreeMenu } from "./AppBarDrawer/DrawerTreeMenu";
import AppBarClippedDrawer from "./AppBarDrawer/AppBarClippedDrawer";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import { logout } from "../utils/activeDirectory";
import { connect } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";
import AdministratorButton from "./AdministrationButton";
import SettingsButton from "./SettingsButton";
import * as PendingSaveActions from "../actions/pendingSaveActions";

const mapStateToProps = (state, ownProps) => {
  const { app } = state;
  const { user } = state;
  const { companyName, fullName, loginPending, roleId, language } = user;
  const userModules = user.applicationModules;
  const { alertPendingSaveReducer } = state;
  const { pendingActive } = alertPendingSaveReducer;
  const { appVersion, applicationModules } = app;

  const isAdmin = roleId === 1 || roleId === 99;
  return {
    ...ownProps,
    companyName,
    fullName,
    loginPending,
    roleId,
    isAdmin,
    userModules,
    applicationModules,
    language,
    pendingActive,
    appVersion,
  };
};

class TenderAppFrame extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      menuItems: [
        /*{
                    adminOnly: true,
                    label: "Licitaciones",
                    icon: <Ballot/>,
                    route: "/licitaciones",
                    children: []
                },{
                    adminOnly: true,
                    label: "Filtros",
                    icon: <SelectAll/>,
                    route: "/filtros",
                    children: []
                },*/ {
          name: "GL",
          label: "Gestion",
          icon: <Edit />,
          route: "/gestion",
          children: [],
          class: "item-tender",
        },
        {
          name: "SS",
          label: "Smart Search",
          icon: <FindInPage />,
          route: "/smart-search",
          children: [],
          class: "item-tender",
        },
        {
          //adminOnly: true,
          name: "AL",
          label: "Análisis Licitaciones",
          icon: <Assessment />,
          route: "/analisis",
          children: [],
          class: "item-tender",
        },
        {
          //adminOnly: true,
          name: "OC",
          label: "Orden de Compra",
          icon: <Shop />,
          route: "/purchase-order",
          children: [],
          class: "item-tender",
        },
        {
          //adminOnly: true,
          name: "PAC",
          label: "PAC",
          icon: <EventNote />,
          route: "/manage-pac",
          children: [],
          class: "item-tender",
        },

        /*,{
                    adminOnly: true,
                    label: "Destinatarios",
                    icon: <People/>,
                    route: "/destinatarios",
                    children:[]
                },{
                    adminOnly: true,
                    label: "Notificaciones",
                    icon: <Notifications/>,
                    route: "/notificaciones",
                    children:[]
                },
                {
                    adminOnly: true,
                    label: "Alertas",
                    icon: <Alarm/>,
                    route: "/alertas",
                    children:[]
                }*/
      ],
    };
  }

  handleMenu = (event) => this.setState({ anchorEl: event.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  handleLogout = () => logout();

  handleFilter(items, appModules) {
    const menu = items.filter((mi) =>
      appModules.some((item) => item.name === mi.name)
    );
    return menu;
  }

  render() {
    const {
      children,
      companyName,
      fullName,
      loginPending,
      isAdmin,
      userModules,
      pendingActive,
      language,
      appVersion,
      applicationModules,
    } = this.props;
    const { anchorEl, menuItems } = this.state;
    const userLabel = fullName.length > 0 ? `${fullName} - ${companyName}` : "";
    const filteredMenuItems = this.handleFilter(
      menuItems,
      isAdmin ? applicationModules : userModules
    );

    return (
      <AppBarClippedDrawer
        title={"Tenders"}
        appVersion={this.props.appVersion}
        menuItems={
          <DrawerTreeMenu
            activarAlert={this.props.OpenAlertPending}
            toRedirect={this.props.LinkRedirect}
            isToRouter={pendingActive}
            menuItems={filteredMenuItems}
          />
        }
        language={language}
        rightMenu={
          <React.Fragment>
            <Button
              variant="contained"
              color="secondary"
              style={{
                marginRight: "1rem",
                animation: "blinker 5s linear infinite",
              }}
              component="a"
              href="https://new.falcontenders.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ingresar a nueva versión
            </Button>
            <AdministratorButton />
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              color="inherit"
              onClick={this.handleMenu}
            >
              <AccountCircle style={{ marginRight: "0.2em" }} />
              {!!loginPending || userLabel.length === 0 ? (
                <CircularProgress
                  style={{ marginRight: "1em", marginLeft: "1em" }}
                  size={"1.5rem"}
                  color={"secondary"}
                />
              ) : (
                <div style={{ fontSize: "1rem" }}>{userLabel}</div>
              )}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleClose}>Perfil</MenuItem>
              <Divider />
              <MenuItem onClick={this.handleLogout}>Salir</MenuItem>
            </Menu>
            {isAdmin ? <SettingsButton isToRouter={pendingActive} /> : ""}
          </React.Fragment>
        }
        content={children}
      />
    );
  }
}

export default connect(mapStateToProps, { ...PendingSaveActions })(
  TenderAppFrame
);
