import { post } from "../../../config/axios.js";

export const listCategories = async (pagination) => {
  const request = {
    pageNumber: pagination.PageNumber,
    pageSize: pagination.pageSize,
    filter: pagination.search,
    orderBy: pagination.orderBy,
    orderAsc: pagination.OrderAsc,
  };
  return await post("/api/category/listcategories", request);
};

export const addOrUpdateCategory = async (request) => {
  return await post("/api/category/addorupdatecategory", request);
};

export const deleteCategory = async (request) => {
  return await post("/api/category/deletecategories", request);
};
