import axios from "axios";
import {
  newFulfilledAction,
  newPendingAction,
  newRejectedAction,
} from "../utils/asyncActionGenerator";

export const userActionTypes = {
  LOGIN: "LOGIN",
};

export function setupUserData() {
  return async (dispatch) => {
    dispatch(newPendingAction(userActionTypes.LOGIN));
    try {
      const axiosResult = await axios.post("/api/Login/Login");
      setLocalStore(axiosResult.data);
      const { status } = axiosResult;
      if (status !== 401) {
        return dispatch(newFulfilledAction(userActionTypes.LOGIN, axiosResult));
      } else {
        throw new Error(`/api/Login/Login returned status code ${status}`);
      }
    } catch (error) {
      return dispatch(
        newRejectedAction(
          userActionTypes.LOGIN,
          `Error getting user data. ${error}`
        )
      );
    }
  };
}

export function setLocalStore(result) {
  const settingClient = result.clientSettings;
  if (settingClient !== null) {
    Object.keys(settingClient).filter((key) => {
      const value = settingClient[key].map((item) => JSON.stringify(item));
      localStorage.setItem(key, value);
    });
  }
}
