import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
} from "@material-ui/core";
import React from "react";
import FstModal from "../../../../components/modal/FstModal";
import BalanceHistoryModalData from "./BalanceHistoryModalData";
import BalanceModalData from "./BalanceModalData";

export default function PurchaseOrderData(props) {
  const [balanceModal, setBalanceModal] = React.useState(false);
  const handleCloseBalanceModal = () => {
    setBalanceModal(false);
  };
  const handleOpenBalanceModal = () => {
    setBalanceModal(true);
  };

  const [balanceHistoryModal, setBalanceHistoryModal] = React.useState(false);
  const handleCloseBalanceHistoryModal = () => {
    setBalanceHistoryModal(false);
  };
  const handleOpenBalanceHistoryModal = () => {
    setBalanceHistoryModal(true);
  };

  return (
    <>
      <BalanceModalData
        open={balanceModal}
        handleOpenHistory={handleOpenBalanceHistoryModal}
        handleClose={handleCloseBalanceModal}
        handleSave={handleCloseBalanceModal}
        data={props.data}
      />
      <BalanceHistoryModalData
        open={balanceHistoryModal}
        handleClose={handleCloseBalanceHistoryModal}
        handleSave={handleCloseBalanceHistoryModal}
        data={props.data}
      />

      <Card>
        <CardContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3>{"Información general del procedimiento"}</h3>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              style={{ height: "50%" }}
              onClick={handleOpenBalanceModal}
            >
              {"Consultar Saldo"}
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableBody>
                {props.data.generalProcedureInfo?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontWeight: "bold" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell>{row.data}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        <CardContent>
          <h3>{"Información básica de la orden de pedido"}</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableBody>
                {props.data.purchaseOrderInfo?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontWeight: "bold" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell>{row.data}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        <CardContent>
          <h3>{"Información del bien, servicio u obra"}</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableHead>
                <TableRow>
                  {props.data.purchaseOrderGoodsHeader?.map((row, idx) => (
                    <TableCell
                      key={idx}
                      style={
                        idx === 1
                          ? { minWidth: "300px", maxWidth: "300px" }
                          : {}
                      }
                    >
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.purchaseOrderGoodsInfo?.map((row, idx) => (
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    key={idx * 10}
                  >
                    {row.map((row2, idx) => (
                      <TableCell
                        key={idx}
                        style={
                          idx === 1
                            ? { minWidth: "300px", maxWidth: "300px" }
                            : {}
                        }
                      >
                        {row2}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        <CardContent>
          <h3>{"Especies Fiscales"}</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableBody>
                {props.data.fiscalSpeciesInfo?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontWeight: "bold" }}
                    >
                      {row.name}
                    </TableCell>
                    <TableCell>{row.data}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        <CardContent>
          <h3>{"Archivo Adjunto"}</h3>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small">
              <TableBody></TableBody>
            </Table>
          </TableContainer>
        </CardContent>

        <CardContent>
          <h3>{"Información detallada de las aprobaciones"}</h3>
          <h4>{"Historial de Aprobación"}</h4>
          {props.data.approvalHistory?.map((approvalRow, idx) => (
            <TableContainer
              component={Paper}
              style={{ marginTop: "1%" }}
              key={idx}
            >
              <Table sx={{ minWidth: 650 }} size="small">
                <TableBody>
                  {approvalRow?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontWeight: "bold",
                          minWidth: "200px",
                          maxWidth: "200px",
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.data}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}

          <h4 style={{ marginTop: "4%" }}>
            {"Historial de la Solicitud de Aprobación"}
          </h4>
          {props.data.approvalApplicationsHistory?.map((approvalRow, idx) => (
            <TableContainer
              component={Paper}
              style={{ marginTop: "1%" }}
              key={idx}
            >
              <Table sx={{ minWidth: 650 }} size="small">
                <TableBody>
                  {approvalRow?.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{
                          fontWeight: "bold",
                          minWidth: "200px",
                          maxWidth: "200px",
                        }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.data}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </CardContent>
      </Card>
    </>
  );
}
