import React from "react";
import { Button } from "@material-ui/core";
import moment from "moment";
import { dateTimeFormat } from "../../../utils/common";

const HeaderCommon = [
  {
    id: "cols.Ejecutivos",
    field: "sellers",
    headerText: "Ejecutivos",
    width: "250",
    textAlign: "Left",
    allowSorting: false,
    template: (props) => (
      <div>
        <span>
          <strong>{props.sellers.join(" - ")}</strong>
        </span>
      </div>
    ),
    showInColumnChooser: false,
  },
  {
    id: "cols.Publicacion",
    field: "publishedDate",
    headerText: "Publicación",
    width: "150",
    textAlign: "Left",
    allowSorting: true,
    template: (props) => (
      <span>{moment(props.publishedDate).format(dateTimeFormat)}</span>
    ),
  },
  {
    id: "cols.Nomenclatura",
    field: "fileNumber",
    headerText: "Nomenclatura",
    width: "315",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Portal",
    field: "portal",
    headerText: "Portal",
    width: "100",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Convocatoria",
    field: "tenderNumber",
    headerText: "Convocatoria",
    width: "130",
    allowSorting: true,
    textAlign: "Center",
  },
  {
    id: "cols.Region",
    field: "regionName",
    headerText: "Región",
    width: "120",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Entidad",
    field: "clientUnitName",
    headerText: "Entidad",
    width: "400",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.ClientName", // Used exclusively in AR. "Servicio Administrativo Financiero"
    field: "clientName",
    headerText: "Client Name",
    width: "400",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Nombre",
    field: "tenderName",
    headerText: "Nombre",
    width: "400",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Modalidad",
    field: "modalityType",
    headerText: "Modalidad",
    width: "180",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Situacion",
    field: "manageInternalStatusDesc",
    headerText: "Situación",
    autoFitColumns: true,
    allowSorting: true,
    textAlign: "Center",
    clipMode: "Clip",
    template: (props) => (
      <div
        id="status"
        className="statustemp e-inactivecolor"
        style={{ backgroundColor: props.manageInsternalStatusColor }}
      >
        {" "}
        <span className="statustxt e-inactivecolor">
          {props.manageInternalStatusDesc}
        </span>
      </div>
    ),
  },
  {
    id: "cols.Etiquetas",
    field: "tags",
    headerText: "Etiquetas",
    autoFitColumns: true,
    textAlign: "Left",
    allowSorting: false,
    template: (props) => (
      <div>
        <Button size="small">{props.tags.join(" - ")}</Button>
      </div>
    ),
  },
  {
    id: "cols.OContratacion",
    field: "tenderDescription",
    headerText: "O.Contratación",
    width: "150",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Referencial",
    field: "estimatedValue",
    headerText: "Estimado/Referencial",
    width: "180",
    textAlign: "Right",
    allowSorting: true,
    template: (props) => (
      <span>
        {props.estimatedValue
          ? props.estimatedValue.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : null}
      </span>
    ),
  },
  {
    id: "cols.Moneda",
    field: "currency",
    headerText: "Moneda",
    width: "110",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Estado",
    field: "tenderStatusDesc",
    headerText: "Estado",
    width: "130",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Items",
    field: "detailsQty",
    headerText: "Items",
    width: "100",
    allowSorting: true,
    textAlign: "Center",
  },
  {
    id: "cols.Cierre",
    field: "endDate", // TODO: cambiar por fecha de cierre
    headerText: "Cierre",
    width: "150",
    textAlign: "Left",
    allowSorting: true,
    template: (props) =>
      props.endDate ? (
        <span>{moment(props.endDate).format(dateTimeFormat)}</span>
      ) : null,
  },
];

export const HeaderTableTenders = [
  ...HeaderCommon,
  {
    id: "cols.Finconsultas",
    field: "questionsReceptionEnd",
    headerText: "Fin consultas",
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.questionsReceptionEnd ? (
        <span>
          {moment(props.questionsReceptionEnd).format(dateTimeFormat)}
        </span>
      ) : null,
  },
  {
    id: "cols.BasesInt",
    field: "basesIntegratedEnd",
    headerText: "Bases int.",
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.basesIntegratedEnd ? (
        <span>{moment(props.basesIntegratedEnd).format(dateTimeFormat)}</span>
      ) : null,
  },
  {
    id: "cols.FinRegistro",
    field: "registryEnd",
    headerText: "Fin registro",
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.registryEnd ? (
        <span>{moment(props.registryEnd).format(dateTimeFormat)}</span>
      ) : null,
  },
  {
    id: "cols.FinPresProp",
    field: "offersReceptionEnd",
    headerText: "Fin pres. Prop.",
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.offersReceptionEnd ? (
        <span>{moment(props.offersReceptionEnd).format(dateTimeFormat)}</span>
      ) : null,
  },
  {
    id: "cols.BuenaPro",
    field: "awardEnd",
    headerText: "Buena Pro",
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.awardEnd ? (
        <span>{moment(props.awardEnd).format(dateTimeFormat)}</span>
      ) : null,
  },
  {
    id: "cols.Usuario",
    field: "userManage",
    headerText: "Usuario",
    width: "150",
    textAlign: "Left",
  },
  {
    id: "cols.Segmento",
    field: "categories",
    headerText: "Segmento",
    allowSorting: false,
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.categories.length ? (
        <span>{props.categories.join(" - ")}</span>
      ) : null,
  },
  {
    id: "cols.Gestionada",
    field: "dateCreationManage",
    headerText: "Gestionada",
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.dateCreationManage ? (
        <span>{moment(props.dateCreationManage).format(dateTimeFormat)}</span>
      ) : null,
  },
];

export const HeaderTableTendersDelete = [
  ...HeaderCommon,
  {
    field: "userManage",
    headerText: "Usuario",
    width: "130",
    textAlign: "Left",
  },
  {
    field: "dateCreationManage",
    headerText: "Gestionada",
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.dateCreationManage ? (
        <span>{moment(props.dateCreationManage).format(dateTimeFormat)}</span>
      ) : null,
  },
  // {
  //     field: 'deleteReason',
  //     headerText: 'Motivo',
  //     width: '100',
  //     textAlign: 'Left'
  // },
  {
    field: "deleteReasonDesc",
    headerText: "Motivo",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "deleteComment",
    headerText: "Comentario",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "deleteDate",
    headerText: "Eliminada",
    width: "150",
    textAlign: "Left",
    template: (props) =>
      props.deleteDate ? (
        <span>{moment(props.deleteDate).format(dateTimeFormat)}</span>
      ) : null,
  },
];

export const ColumnsDates = [
  {
    field: "publishedDate",
    format: dateTimeFormat,
  },
  {
    field: "questionsReceptionEnd",
    format: dateTimeFormat,
  },
  {
    field: "basesIntegratedEnd",
    format: dateTimeFormat,
  },
  {
    field: "registryEnd",
    format: dateTimeFormat,
  },
  {
    field: "offersReceptionEnd",
    format: dateTimeFormat,
  },
  {
    field: "awardEnd",
    format: dateTimeFormat,
  },
  {
    field: "dateCreationManage",
    format: dateTimeFormat,
  },
  {
    field: "deleteDate",
    format: dateTimeFormat,
  },
];
