import React, { useEffect, useState } from "react";
import { FstGrid } from "../../components/grid/FstGrid";

/* Props:
    - dataGrid: obj
    - uniqueValue: string
    - paginatedData: obj
    - dataLength: int
*/
export default function ABMGrid(props) {
  let initialData = JSON.parse(JSON.stringify(props.paginatedData));
  const [currentData, setCurrentData] = useState(initialData);

  useEffect(() => {
    setCurrentData(props.paginatedData);
  }, [props.paginatedData]);

  return (
    <FstGrid
      data={props.dataGrid}
      uniqueValue={props.uniqueValue}
      result={{ result: currentData, count: props.dataLength }}
      reloadDefault={true}
    />
  );
}
