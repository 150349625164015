import React, { useState, useEffect } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import DateFnsUtils from "@date-io/date-fns";
import { Controls } from "../../../utils/controls";
import { InputStyles } from "./style";
import PropTypes from "prop-types";

const FstDatePicker = (props) => {
  const { variant } = Controls.TextField;
  const classes = InputStyles();
  const [getInputValue, setInputValue] = useState("");
  const { name, label, value, inputValue, handleChange, handleError, format } =
    props;

  const handleChangeValue = (value) => {
    handleChange(name, value);
    handleError(name, value);
  };

  const verifyInput = () => {
    if (value !== null) {
      if (value.indexOf("_") === -1) {
        setInputValue(value);
      } else {
        setInputValue(inputValue);
      }
    }
  };

  useEffect(() => {
    verifyInput();
    return () => {};
  }, [value]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
      <KeyboardDatePicker
        disableToolbar
        clearable
        autoOk={true}
        inputVariant={variant}
        format={format}
        required
        invalidDateMessage="Fecha Invalida"
        maxDateMessage="La fecha desde no puede ser mayor a la hasta"
        minDateMessage="La fecha hasta no puede ser menor a la desde"
        autoComplete="off"
        id={name}
        label={label}
        readOnly={props.readOnly}
        className={classes.KeyboardDatePicker}
        InputProps={{ readOnly: props.readOnly }}
        inputValue={getInputValue}
        onChange={(event, newValue) => handleChangeValue(newValue)}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        minDate={props.minDate}
        maxDate={props.maxDate}
        error={props.error}
        helperText={props.helperText}
      />
    </MuiPickersUtilsProvider>
  );
};

FstDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  format: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

FstDatePicker.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default FstDatePicker;
