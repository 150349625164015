import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import EntityContainerPaging from "./EntityContainerPaging";
import TabsLabel from "./TabsLabel";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import BusinessIcon from "@material-ui/icons/Business";
import StarsIcon from "@material-ui/icons/Stars";
import ProductCriteria from "./ProductCriteria";
import { getCompetitors, getOrganisms } from "../services/AnalysisService";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    padding: 0,
  },
  rootPaper: {
    backgroundColor: "#3f51b5",
    color: "#fff",
  },
  withTab: {
    width: "36%",
    color: "#fff",
    maxWidth: "33%",
  },
  content: {
    height: "300px",
    padding: "10px 0px",
  },
}));

export default function AnalysisTabs(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.changeTabs(newValue);
  };

  const loadOptionsCompetitors = async (
    searchQuery,
    loadedOptions,
    { page }
  ) => {
    const request = {
      idOrder: 0,
      pageNumber: page,
      pageSize: 100,
      filter: searchQuery,
      orderAsc: true,
      orderBy: "",
      isSelected: props.ModuleId === 3 ? false : true,
    };

    const response = await getCompetitors(request);
    const responseJSON = await response.data.currentPageItems;

    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: response.data.currentPage,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
      },
    };
  };

  const loadOptionsOrganisms = async (searchQuery, loadedOptions, { page }) => {
    const request = {
      idOrder: 0,
      pageNumber: page,
      pageSize: 100,
      filter: searchQuery,
      orderAsc: true,
      orderBy: "",
      isSelected: false,
    };

    const response = await getOrganisms(request);
    const responseJSON = await response.data.currentPageItems;

    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: response.data.currentPage,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
      },
    };
  };

  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.rootPaper}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            className={classes.withTab}
            icon={
              <TabsLabel
                count={props.tabsCounts.products}
                icon={<InsertChartIcon />}
                name="Productos"
              />
            }
          ></Tab>
          <Tab
            icon={
              <TabsLabel
                count={props.tabsCounts.organisms}
                icon={<BusinessIcon />}
                name="Entidades"
              />
            }
            className={classes.withTab}
          />
          <Tab
            icon={
              <TabsLabel
                count={props.tabsCounts.competitors}
                icon={<StarsIcon />}
                name="Competidores"
              />
            }
            className={classes.withTab}
          />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0} className={classes.content}>
        <ProductCriteria
          listInclude={props.filter.IncludeKey}
          listExclude={props.filter.ExcludeKey}
          addElement={(key) => props.addElement(key)}
          handleList={(key, list) => props.onChangeList(key, list)}
        />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.content}>
        <EntityContainerPaging
          handleChange={props.handleChangeOrganims}
          loadOptionsAutocomplete={loadOptionsOrganisms}
          list={props.entitySelected}
        />
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.content}>
        <EntityContainerPaging
          handleChange={props.handleChangeCompetitors}
          loadOptionsAutocomplete={loadOptionsCompetitors}
          list={props.entitySelected}
        />
      </TabPanel>
    </div>
  );
}
