import React, { useEffect, useState } from "react";
import useGetFormConf from "../../intl/utils/hooks/useGetFormConf";
import GetSelectComponet from "./GetSelectComponet";
import useHandleDataByLang from "../../intl/utils/hooks/useHandleDataByLang";

const GeneralInfo = ({ data }) => {
  const getForm = useGetFormConf("ListCompGeneralInfo");
  const [getData, setData] = useState([]);
  const [runInit, setRunInit] = useState(false);
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);
  const minSpaceStyle = {
    padding: "4px",
    textAlign: "center",
    fontSize: "12px",
    paddingTop: "0.5rem",
    paddingRight: "0px",
    paddingBottom: "0.5rem",
    paddingLeft: "0px",
  };

  const initData = () => {
    const result = handelData.getDataByCountry("tab.General");
    setData(result);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      {runInit &&
        getForm
          .GetFormConf()
          .map((item, index) => (
            <GetSelectComponet
              key={index}
              item={item}
              data={[getData]}
              dataExt={getData}
            />
          ))}
    </>
  );
};

export default GeneralInfo;
