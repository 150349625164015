import FstModal from "../../components/modal/FstModal";
import React from "react";
import { DialogContentText, TextField } from "@material-ui/core";

export default function CompanyManagerUserSyncModal(props) {
  return (
    <>
      <FstModal
        open={props.open}
        title={props.title}
        handleClose={props.handleClose}
        handleSave={props.handleSave}
        widthSize="sm"
        classContainer={"fst-Modal-Container"}
      >
        <DialogContentText>
          Especifique la empresa que desea sincronizar sus usuarios.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="userSyncCompany"
          name="userSyncCompany"
          value={props.fieldValue}
          onChange={(e) => props.handleChange(e)}
          fullWidth
          label="Empresa"
        />
      </FstModal>
    </>
  );
}
