import { AnalysisReportsType } from "../types";

const INITIAL_STATE = {
  AnalysisFilterId: null,
  ReportName: null,
  refresh: null,
  loadReport: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AnalysisReportsType.REFRESH_REPORT:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
};
