import { makeStyles } from "@material-ui/core/styles";

export const InputStyles = makeStyles({
  root: {
    display: "block",
    padding: 12,
  },
  headerName: {
    fontSize: "0.9rem",
  },
  InputWidth: {
    width: "100%",
  },
  Autocomplete: {
    margin: 0,
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
  },
  KeyboardDatePicker: {
    width: "100%",
    marginTop: 0,
  },
  ListComments: {
    maxHeight: "150px",
    overflow: "auto",
    marginTop: "30px",
  },
  btnCenter: {
    textAlign: "center",
    "& .download-button-file-class": {
      marginLeft: 10,
    },
  },
});
