import { Card, CardContent, Divider } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DataManager, Query } from "@syncfusion/ej2-data";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AdminSnackbars from "views/utils-admin/AdminSnackbars";
import {
  addOrUpdateAlert,
  deleteAlert,
  generalDataAlerts,
  listAlerts,
} from "../../actions/alertActions";
import * as SpinnerActions from "../../actions/spinnerActions";
import AdminAccess from "../../components/AdminAccess";
import { StylePage } from "../../style/contentStyle";
import { dateTimeFormat } from "../../utils/common";
import AdminGrid from "../utils-admin/AdminGrid";
import AdminHeader from "../utils-admin/AdminHeader";

function AlertsAdmin(props) {
  const classes = StylePage();
  const [tableData, setTableData] = React.useState({
    data: [],
    page: 0,
    totalCount: 0,
  });

  const btnTypes = { REMOVE: "Remove" };
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterError, setOpenToasterError] = useState(false);
  const [itemToModify, setItemToModify] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [generalData, setGeneralData] = React.useState({ empty: true });

  const invertObjectKeyValue = (name, generalData) => {
    const obj = {};
    for (let key in generalData) {
      if (generalData.hasOwnProperty(key)) {
        obj[generalData[key]] = key;
      }
    }
    return obj;
  };

  const getLabelFromId = (name, lookup, id) => {
    let result = "Desconocido";
    for (let key in lookup) {
      if (lookup.hasOwnProperty(key)) {
        if (lookup[key] === id) {
          result = key;
          break;
        }
      }
    }
    return result;
  };

  const getDataForSelect = (data) => {
    return Object.entries(data).map(([key, value]) => {
      return { id: +key, name: value };
    });
  };

  const fetchAllData = async (pageInfo) => {
    props.SpinnerShow("Cargando...");
    const data = await generalDataAlerts();
    if (!!data.error) {
      setGeneralData({
        error: "Ocurrió un error al cargar los datos iniciales.",
      });
      return;
    }

    data.processed = {};
    data.mappedToSelect = {};
    let name = "alertTypes";
    data.processed[name] = invertObjectKeyValue(name, data[name]);
    data.mappedToSelect[name] = getDataForSelect(data.processed[name]);

    setGeneralData(data);

    await fetchTableData(pageInfo?.PageNumber ? pageInfo.PageNumber : 0);
    setTimeout(() => {
      props.SpinnerHide();
    }, 750);
  };

  const fetchTableData = async (page) => {
    return new Promise(async (resolve, reject) => {
      const alerts = await listAlerts();

      if (!!alerts) {
        const tableData = {
          page: page,
          data: alerts.data,
          totalCount: alerts.data.length,
        };
        setTableData(tableData);
        resolve(tableData);
      } else {
        reject("what?");
      }
    });
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const remove = (btn, item) => {
    if (btn.type === btnTypes.REMOVE) {
      setItemToModify(item);
      setDeleteConfirm(true);
    }
  };

  const actionCustomRow = (btn, item) => {
    remove(btn, item);
  };

  const showToaster = () => {
    props.SpinnerShow("Cargando...");
    setDeleteConfirm(false);
    setItemToModify(null);
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
      props.SpinnerHide();
    }, 2000);
  };

  const showToasterError = () => {
    setOpenToasterError(true);
    setTimeout(() => {
      setOpenToasterError(false);
    }, 4500);
  };

  const handleSave = async (item) => {
    if (!item.alertTypeId) {
      showToasterError();
      return;
    }
    showToaster();
    await addOrUpdateAlert(item, tableData);
    await fetchTableData(tableData.page);
  };

  const handleDelete = async (item) => {
    showToaster();
    await deleteAlert(item, tableData);
    await fetchTableData(tableData.page);
  };

  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
  const header = [
    {
      field: "alertTypeId",
      headerText: "Tipo",
      width: "50%",
      textAlign: "Left",
      allowSorting: true,
      showInColumnChooser: false,
      template: (rowData) => (
        <label>
          {getLabelFromId(
            "alertTypes",
            generalData["alertTypes"],
            rowData["alertTypeId"]
          )}
        </label>
      ),
      editType: "dropdownedit",
      editParams: {
        params: {
          actionComplete: () => false,
          allowFiltering: true,
          dataSource: new DataManager(
            generalData.mappedToSelect
              ? generalData.mappedToSelect["alertTypes"]
              : []
          ),
          fields: { text: "name", value: "id" },
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      field: "active",
      headerText: "Activo",
      width: "10%",
      textAlign: "Center",
      allowSorting: true,
      displayAsCheckBox: true,
      editType: "booleanedit",
      editParams: {
        params: {
          actionComplete: () => false,
          query: new Query(),
        },
      },
      allowEditing: true,
    },
    {
      field: "creation",
      headerText: "Creación",
      width: "20%",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>{moment(rowData["creation"]).format(dateTimeFormat)}</label>
      ),
      editTemplate: (rowData) => (
        <label>{moment(rowData["creation"]).format(dateTimeFormat)}</label>
      ),
    },
    {
      field: "lastUpdate",
      headerText: "Ultima actualización",
      width: "20%",
      textAlign: "Left",
      allowSorting: true,
      template: (rowData) => (
        <label>{moment(rowData["lastUpdate"]).format(dateTimeFormat)}</label>
      ),
      editTemplate: (rowData) => (
        <label>{moment(rowData["lastUpdate"]).format(dateTimeFormat)}</label>
      ),
    },
    {
      headerText: "Acciones",
      width: "160px",
      commands: [
        {
          type: "Edit",
          buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
        },
        {
          type: "Remove",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
        {
          type: "Save",
          buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
    },
  ];
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////

  return (
    <div className={classes.content}>
      <AdminAccess>
        {!generalData.empty ? (
          !generalData.error ? (
            <>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <AdminHeader headerTitle="Alertas" />
                    <Divider />
                    <CardContent>
                      <AdminGrid
                        tableData={tableData}
                        tableDataCount={tableData.totalCount}
                        reloadDefault={true}
                        header={header}
                        idGrid="AlertsAdmin"
                        fetchAllData={fetchAllData}
                        actionCustomRow={actionCustomRow}
                        handleSave={handleSave}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <AdminSnackbars
                openToaster={openToaster}
                openToasterError={openToasterError}
                modalTitle={`Eliminar alerta`}
                modalClose={() => {
                  setItemToModify(null);
                  setDeleteConfirm(false);
                }}
                deleteConfirm={deleteConfirm}
                modalMessage={`¿Está seguro que desea eliminar la alerta de tipo ${getLabelFromId(
                  "alertTypes",
                  generalData["alertTypes"],
                  itemToModify ? itemToModify["alertTypeId"] : ""
                )}?`}
                modalSuccess={() => handleDelete(itemToModify)}
              />
            </>
          ) : (
            `${generalData.error}`
          )
        ) : (
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justify="center"
            style={{ minHeight: "100vh" }}
          />
        )}
      </AdminAccess>
    </div>
  );
}

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(AlertsAdmin);
