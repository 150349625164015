import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;
export const GestionStyle = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 600,
  },

  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      minWidth: 60,
    },
    height: (props) => props.heightValue,
    position: "absolute",
    left: 12,
    zIndex: 600,
  },

  content: {
    flexGrow: 1,
    marginLeft: "65px",
    zIndex: 600,
  },
  items: {
    marginBottom: "5px",
  },
  active: {
    "& .MuiListItemIcon-root": {
      color: "#3f51b5",
    },
  },
  badgeCss: {
    backgroundColor: "#0288d1",
    color: "white",
  },
  selected: {
    backgroundColor: "#EFEFEF",
  },
}));

export const NavigationGestionStyle = makeStyles({
  root: {
    width: "100%",
    marginBottom: "5px",
    display: "inline-block !important",
    "& .MuiButtonBase-root": {
      minWidth: "90px",
    },
    "& .MuiBadge-badge": {
      fontSize: "0.70rem",
      width: "20px",
      minWidth: "none",
    },
    "& .MuiIconButton-root": {
      float: "right",
      minWidth: "50px",
    },
  },
  selected: {
    color: "none",
  },
});
