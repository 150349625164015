import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  Select,
  Input,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import * as TenderAction from "../../../actions/manageTenderActions";
import { connect } from "react-redux";
import { listDeleteReasons } from "views/delete-reasons/services/deleteReasonService";

const DeleteConfirmation = (props) => {
  const maxDeleteReasonChars = 500;
  const [deleteReason, setDeleteReason] = useState(-1);
  const [deleteReasonComment, setDeleteReasonComment] = useState("");
  const [remainingDeleteReasonCharacters, setRemainingDeleteReasonCharacters] =
    useState(maxDeleteReasonChars);
  const [deleteReasons, setDeleteReasons] = useState([]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleCloseDeleteConfirmation = (value) => {
    setDeleteReason(-1);
    setDeleteReasonComment("");
    props.handleCloseConfirmDelete(value);
  };

  useEffect(() => {
    async function getDeleteReasons() {
      const response = await listDeleteReasons();
      setDeleteReasons(response.data);
    }
    if (props.openConfirmDelete) getDeleteReasons();
  }, [props.openConfirmDelete]);

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={props.openConfirmDelete}
        onClose={props.handleCloseDeleteConfirmation}
        aria-labelledby="confirmar-eliminacion-popup"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirmar Eliminación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ha seleccionado eliminar la licitación "
            {props.rowDataToDelete.fileNumber}".
            <br />
            <br />
            Elija el motivo de eliminación: {`    `}
            <Select
              value={deleteReason}
              onChange={(e, obj) => setDeleteReason(obj.props.value)}
              input={<Input id="select" style={{ width: "250px" }} />}
            >
              {deleteReasons &&
                deleteReasons.map((r) => (
                  <MenuItem
                    key={r.deleteReasonId}
                    value={r.deleteReasonId}
                    name={r.description}
                  >
                    {r.description}
                  </MenuItem>
                ))}
            </Select>
            <br />
            Comentario:
            <br />
            <TextField
              id="delete-reason-textfield"
              multiline
              fullWidth
              value={deleteReasonComment}
              onChange={(e) => {
                const text = e.target.value;
                let remaining = maxDeleteReasonChars;
                if (!!text) {
                  if (text.length <= maxDeleteReasonChars) {
                    setDeleteReasonComment(text);
                    remaining = maxDeleteReasonChars - text.length;
                  } else {
                    setDeleteReasonComment(
                      text.substr(0, maxDeleteReasonChars),
                    );
                    remaining = 0;
                  }
                } else {
                  setDeleteReasonComment("");
                }

                (() => setRemainingDeleteReasonCharacters(remaining))();
              }}
              rows={7}
              style={{
                margin: theme.spacing(1),
              }}
              margin="normal"
            />
            <div
              style={{
                color:
                  (remainingDeleteReasonCharacters === 0 && "red") || "grey",
              }}
            >
              {`${remainingDeleteReasonCharacters} ${
                remainingDeleteReasonCharacters === 1
                  ? "carácter"
                  : "caracteres"
              } restantes.`}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleCloseDeleteConfirmation(false)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button
            color="primary"
            disabled={deleteReason === -1}
            onClick={async () => {
              props.rowDataToDelete.deleteComment = deleteReasonComment;
              props.rowDataToDelete.deleteReason = deleteReason;
              await props.removeTenderFromManagedList(props.rowDataToDelete);
              await props.getManagedTendersListDefault();
              handleCloseDeleteConfirmation(true);
            }}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return { ...ownProps };
};

export default connect(mapStateToProps, TenderAction)(DeleteConfirmation);
