import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import FstModal from "../../../components/modal/FstModal";
import AwardedForm from "./AwardedForm";
import { GetAwardedReport } from "../services/awardedService";
import { downloadBase64File } from "../../../utils/FileHandleService";
import * as SpinnerActions from "../../../actions/spinnerActions";
import {
  ConvertDateStartToISO,
  ConvertDateEndToISO,
  DateSubtractDay,
  formatDate,
} from "../../../utils/DateHelper";
import FstAlert from "../../../components/utils/FstAlert";

const GetAwardedReportModal = (props) => {
  const messageResult =
    "No se encontaron resultados para la busqueda realizada";
  const settingModal = { BtnNameSave: "Resultado" };
  const [isDisabledPrimaryModal, setIsDisabledPrimaryModal] = useState(false);
  const start = DateSubtractDay(7);
  const end = DateSubtractDay(0);
  const awaredDefault = {
    startDate: start,
    endDate: end,
    won: false,
    companyName: props.companyName,
  };
  const [awared, setAwared] = useState(awaredDefault);
  const [showMessageError, setShowMessageError] = useState(false);

  const handleClose = () => {
    props.handleOpen(false);
  };

  const SearchAwaredReport = async () => {
    setShowMessageError(false);
    props.SpinnerShow();

    const request = {
      startDate: ConvertDateStartToISO(awared.startDate, formatDate.es.SHORT),
      endDate: ConvertDateEndToISO(awared.endDate, formatDate.es.SHORT),
      won: awared.won,
      ReportTypeId: 1,
    };
    const resp = await GetAwardedReport(request);
    props.SpinnerHide();
    if (resp.data && resp.data.reportArray) {
      downloadBase64File(resp.data.reportName, resp.data.reportArray); // TODO VER NOMBRE DEL ARCHIVO
    } else {
      setShowMessageError(true);
    }
  };

  const onchange = (key, value) => {
    const aux = { ...awared };
    aux[key] = value;
    setAwared({
      ...aux,
    });
    const isDisabled = aux.startDate && aux.endDate;
    setIsDisabledPrimaryModal(!isDisabled);
  };

  useEffect(() => {
    setAwared(awaredDefault);
  }, [props.open]);

  return (
    <>
      <FstModal
        open={props.open}
        title="Adjudicaciones"
        handleClose={handleClose}
        handleSave={SearchAwaredReport}
        setting={settingModal}
        widthSize="sm"
        IsDisabledPrimary={isDisabledPrimaryModal}
      >
        <AwardedForm awared={awared} onChange={onchange} />
        <FstAlert show={showMessageError} message={messageResult} />
      </FstModal>
    </>
  );
};

const mapStateToProps = (state) => {
  return state.user;
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  GetAwardedReportModal
);
