import axios from "axios";

export const getStatusGroups = async () => {
  try {
    const axiosResult = await axios.post("/api/Manage/GetManageStatusGroups");

    if (!!axiosResult && !!axiosResult.data) {
      return axiosResult.data;
    } else {
      return { error: "Error fetching status groups data" };
    }
  } catch (error) {
    console.log({ error });
    return { error: "Error fetching tatus groups  data", msg: error };
  }
};
