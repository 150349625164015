import React, { useCallback, useEffect, useState } from "react";
import { FstGrid } from "../../components/grid/FstGrid";
import CompanyManagerButton from "./CompanyManagerButton";

const CompanyManagerGrid = (props) => {
  /// Height handling
  const [height, setHeight] = useState();

  const handleResize = useCallback(() => {
    const padding = 130;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  ///

  const Headers = [
    {
      field: "companyId",
      headerText: "ID",
      width: "2%",
      textAlign: "center",
      allowSorting: true,
      showInColumnChooser: false,
    },
    {
      field: "name",
      headerText: "Empresa",
      width: "15%",
      textAlign: "Left",
      allowSorting: true,
    },
    {
      field: "isEnabled",
      headerText: "Estado",
      width: "5%",
      textAlign: "center",
      type: "boolean",
      allowSorting: true,
      template: (props) => (
        <CompanyManagerButton
          handleClickActive={(event) => CompanyActive(props, event)}
          active={props.isEnabled}
        />
      ),
    },
  ];

  const CompanyActive = (arg, active) => {
    const param = {
      companyId: arg.companyId,
      name: arg.name,
      isEnabled: active,
    };
    props.handleActiveCompany(param);
  };

  const addToolBar = () => {
    let toolbar = "";
    if (props.isAdmin) {
      toolbar = {
        Add: props.executeCreate,
        //Delete: props.executeDelete,
        //rowDoubleClickAction:  props.executeEdit,
        ToolBar: {
          Add: true,
          AddTitle: "Nueva",
          //Delete: true,
          //DeleteTitle: 'Eliminar'
        },
      };
    }
    return toolbar;
  };

  const dataGrid = {
    Getdata: props.dataCompany,
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
    },
    allowSorting: true,
    allowPaging: true,
    take: 17,
    Header: Headers,
    height: height - 180,
    ...addToolBar(),
    idGrid: "CompanyManager",
  };

  return (
    <FstGrid
      data={dataGrid}
      uniqValue={props.uniqValue}
      result={{
        result: props.listData.rows,
        count: props.listData.totalRows,
      }}
      reloadDefault={true}
    />
  );
};

export default CompanyManagerGrid;
