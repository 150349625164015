import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const { roleId } = user;

  const isAdmin = roleId === 1 || roleId === 99;
  return {
    ...ownProps,
    isAdmin,
  };
};

class AdminAccess extends Component {
  render() {
    const { isAdmin, children } = this.props;
    return !!isAdmin ? children : <h3>Permiso Denegado</h3>;
  }
}

export default withRouter(connect(mapStateToProps)(AdminAccess));
