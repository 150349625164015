import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FstGrid } from "../../components/grid/FstGrid";
import { Headers, ColumnsDates } from "./ManagePacHeader";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./style";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Tooltip,
} from "@material-ui/core";
import * as SpinnerActions from "../../actions/spinnerActions";
import { FstButtonGroup } from "../../components/forms/buttons/FstButtonGroup";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { FormattedMessage, useIntl } from "react-intl";
import useIntlColumns from "../../intl/utils/format-elements/columnsIntl";
import {
  getFilteredResultPac,
  downloadExcelPac,
} from "./services/servicePac";
import {
  getSearchManageFilter,
  getSources,
} from "../smart-search/services/smartSearchService";
import DownloadExcel from "./utils/downloadExcel";
import { convertTimezoneToTimezone } from "utils/DateHelper";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ManagePacList = (props) => {
  const spinnerMessage = "Cargando...";
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [reloadDefault, setReloadDefault] = useState(false);
  const [stateRequest, setStateRequest] = useState(null);
  const [list, setList] = useState({ rows: [], totalRows: 0 });
  const [openModaltender, setOpenModaltender] = useState(false);
  const [openTenderInformation, setOpenTenderInformation] = useState(false);
  const [tenderNumber, setTenderNumber] = useState(null);
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [tenderNumbers, setTenderNumbers] = useState(false);
  let selectedRows = null;
  const classes = useStyles();
  const [paginado, setPaginado] = useState(null);
  const { getHeader } = useIntlColumns("ManagePAC", "app.columnsPAC", Headers);
  const [isDonwload, setIsDonwload] = useState(false);
  const selectionRows = (value) => {
    selectedRows = [...value];
  };
  const options = [
    { id: 0, description: "Todas", IsSummoned: null },
    { id: 1, description: "Convocadas", IsSummoned: true },
    { id: 2, description: "No Convocadas", IsSummoned: false },
  ];
  const monthOptions = [
    { id: 0, description: "Todos los meses" },
    { id: 1, description: "Enero" },
    { id: 2, description: "Febrero" },
    { id: 3, description: "Marzo" },
    { id: 4, description: "Abril" },
    { id: 5, description: "Mayo" },
    { id: 6, description: "Junio" },
    { id: 7, description: "Julio" },
    { id: 8, description: "Agosto" },
    { id: 9, description: "Septiembre" },
    { id: 10, description: "Octubre" },
    { id: 11, description: "Noviembre" },
    { id: 12, description: "Diciembre" },
  ];
  const yearOptions = [
    { id: 0, description: "Todos los años" , yearValue: 0},
    { id: 1, description: "Año actual", yearValue: moment().year() },
    { id: 2, description: "Año anterior", yearValue: moment().year() - 1 },
    { id: 3, description: "Año próximo", yearValue: moment().year() + 1 },
  ];
  const [monthSelected, setMonthSelected] = useState(0);
  const [yearSelected, setYearSelected] = useState(0);
  const [isState, setIsState] = useState(null);
  const [timeUpdate, setTimeUpdate] = useState({
    isDate: false,
    dateTime: moment().format("dd/MM/YYYY HH:mm"),
  });
  const intl = useIntl();

  const getData = async (pag, filter) => {
    let list = await props.listFilter
      .filter((item) => item.checked)
      .map((item) => item.searchFilterId);
    if (props.listFilter.length === 0) {
      list = await getFilters();
    }
    const request = {
      ScrapId: 0,
      Name: "",
      Description: "",
      Organism: "",
      Filters: list,
      PageNumber: pag.PageNumber,
      PageSize: pag.pageSize,
      SearchFilter: pag.search,
      OrderBy: pag.orderBy,
      OrderAsc: pag.OrderAsc,
      IsPaginated: true,
      Year: yearSelected,
      Month: monthSelected,
      IsSummoned: isState,
    };
    setPaginado(pag);
    setStateRequest(request);
    executeLoadData(request);
  };

  const executeLoadData = async (request) => {
    props.SpinnerShow(spinnerMessage);
    getTimeUpdate();
    const response = await getFilteredResultPac(request);
    setList({
      rows: response.data.currentPageItems,
      totalRows: response.data.totalItems,
    });
    props.SpinnerHide();
  };

  const getTimeUpdate = async () => {
    const getTime = {
      isDate: false,
      dateTime: moment().format("dd/MM/YYYY HH:mm"),
    };
    const resSource = await getSources();
    const portalName = intl.formatMessage({
      id: "portalNamePAC",
      defaultMessage: "PAC",
    });
    const getDateArray = resSource.data.filter(
      (item) => item.name === portalName
    );
    if (getDateArray.length > 0) {
      if (getDateArray[0].lastScrapedDate) {
        getTime.isDate = true;
        getTime.dateTime = moment(getDateArray[0].lastScrapedDate).format(
          "DD/MM/YYYY HH:mm"
        );
      }
    }
    let language = JSON.parse(localStorage.getItem("state")).user?.language;
    // if language is CR, change timezone from Chile to CR
    if (language === "es-CR") {
      getTime.dateTime = convertTimezoneToTimezone(
        getTime.dateTime,
        "DD/MM/YYYY HH:mm",
        "America/Santiago",
        "America/Costa_Rica"
      );
    }

    getTime.dateTime = `${getTime.dateTime}hs.`;
    setTimeUpdate(getTime);
  };

  const exportList = async (pag, filter) => {
    setPaginado(pag);
    setIsDonwload(false);
    const request = {
      ScrapId: 0,
      Name: "",
      Description: "",
      Organism: "",
      Filters: props.listFilter
        .filter((item) => item.checked)
        .map((item) => item.searchFilterId),
      PageNumber: pag.pageNumber,
      PageSize: pag.pageSize,
      SearchFilter: pag.search,
      Year: yearSelected,
      Month: monthSelected,
      IsSummoned: isState,
    };
    const response = await getFilteredResultPac(request);
    if (!!response && !!response.data) {
      return [...response.data.currentPageItems];
    }
  };

  const view = (row) => {
    if (row === undefined) return;
    setTenderNumber(row.tenderNumber);
    setOpenTenderInformation(true);
  };

  const ExportClick = async (pag) => {
    props.SpinnerShow("Descargado...");
    const request = {
      IsPaginated: false,
      ReportId: 3,
      ScrapId: 0,
      Name: "",
      Description: "",
      Organism: "",
      Filters: props.listFilter
        .filter((item) => item.checked)
        .map((item) => item.searchFilterId),
      PageNumber: pag.PageNumber,
      PageSize: pag.pageSize,
      SearchFilter: pag.search,
      Year: yearSelected,
      Month: monthSelected,
      IsSummoned: isState,
    };

    const result = await downloadExcelPac(request);
    props.SpinnerHide();
    exportToExcel(result.data);
  };

  const exportToExcel = (value) => {
    const anchor_href =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      value;
    const exportLinkElement = document.createElement("a");
    exportLinkElement.hidden = true;
    exportLinkElement.download = "SManagePAC - Detalles de licitaciones.xlsx";
    exportLinkElement.href = anchor_href;
    exportLinkElement.text = "downloading...";

    document.body.appendChild(exportLinkElement);
    exportLinkElement.click();
    exportLinkElement.remove();
  };

  const dataGrid = {
    allowSorting: true,
    allowPaging: true,
    take: 17,
    Getdata: getData,
    Header: getHeader,
    height: props.height - 183,
    exportFunc: exportList,
    rowDoubleClickAction: view,
    ExportClick: ExportClick,
    selection: {
      settingSelection: {
        type: "Multiple",
        mode: "Row",
      },
      selectionRows: selectionRows,
    },
    ToolBar: {
      Search: true,
      Export: false,
      ExportClick: true,
      Refresh: true,
    },
    ExportFileName: "ManagePAC - Detalles de licitaciones.xlsx",
    idGrid: "ManagePAC",
    styles: props.styles,
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setReloadDefault(true);
      setUniqueValue(moment().format());
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [props.listFilter, isState, yearSelected, monthSelected]);

  const rowDataBound = (arg) => {
    if (arg.data.userHighlightName) {
      arg.row.classList.add("bg-color-green");
    }

    if (arg.data.userManage) {
      arg.row.classList.remove("bg-color-green");
      arg.row.classList.add("bg-color-orange");
    }
  };

  const getFilters = async () => {
    const request = {
      orderBy: null,
      pageSize: 999,
      search: "",
      PageNumber: 1,
      OrderAsc: false,
    };
    const response = await getSearchManageFilter(request);
    const auxList = await response.data.currentPageItems.map((item) => {
      return item.searchFilterId;
    });

    return auxList;
  };

  return (
    <>
      <Card>
        <CardHeader
          className={classes.rootHeader}
          title={
            <>
              <Typography
                gutterBottom
                variant="h6"
                component="h6"
                className={classes.tipograghy}
              >
                <FormattedMessage
                  id="title.Resultados"
                  defaultMessage="Resultados"
                />
              </Typography>
              <FstButtonGroup
                clasNameGroup={classes.fstButtom}
                clasNamePrymary={classes.btnGrPrimary}
                btnGrIcon={classes.btnGrIcon}
                classNameBtn={classes.button}
                options={options}
                label="Todos"
                handleClick={(value) => {
                  if (value) setIsState(value.IsSummoned);
                }}
              />
              <FstButtonGroup
                clasNameGroup={classes.fstButtom}
                clasNamePrymary={classes.btnGrPrimary}
                btnGrIcon={classes.btnGrIcon}
                classNameBtn={classes.button}
                options={yearOptions}
                label="Año"
                handleClick={(value) => {
                  if (value) setYearSelected(value.yearValue);
                }}
              />
              <FstButtonGroup
                clasNameGroup={classes.fstButtom}
                clasNamePrymary={classes.btnGrPrimary}
                btnGrIcon={classes.btnGrIcon}
                classNameBtn={classes.button}
                options={monthOptions}
                label="Mes"
                handleClick={(value) => {
                  if (value) setMonthSelected(value.id);
                }}
              />
            </>
          }
        ></CardHeader>
        <Divider />
        <CardContent>
          {timeUpdate.isDate && (
            <div className={classes.ctnActializar}>
              {/*<Tooltip title="Actualizar">*/}
              <div className={classes.etqActializar}>
                <span style={{ display: "flex" }}>
                  Ultima actualización: {timeUpdate.dateTime}
                </span>
              </div>
              {/*</Tooltip>*/}
            </div>
          )}
          <FstGrid
            data={dataGrid}
            uniqValue={uniqValue}
            reloadDefault={reloadDefault}
            rowDataBound={rowDataBound}
            ColumnsDate={ColumnsDates}
            result={{
              result: list.rows,
              count: list.totalRows,
            }}
          />
        </CardContent>
      </Card>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity="success">{openToasterMessage}</Alert>
      </Snackbar>
      <DownloadExcel
        isDonwload={isDonwload}
        fileName={"ManagePAC - Detalles de licitaciones.xlsx"}
        getData={exportList}
        columns={getHeader}
        paging={{ ...paginado, ...list }}
        nameSheet={"ManagePAC"}
      />
    </>
  );
};
const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  withRouter(ManagePacList)
);
