import { post } from "../../../config/axios.js";

export const listCompanySettings = async (pagination) => {
  return await post("/api/companysetting/listcompanysetting", {});
};

export const addOrUpdateCompanySetting = async (request) => {
  return await post("/api/companysetting/addorupdatecompanysetting", request);
};

export const deleteCompanySetting = async (request) => {
  return await post("/api/companysetting/deletecompanysetting", request);
};
