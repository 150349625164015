import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PropTypes from "prop-types";
import { InputStyles } from "./style";
import { Controls } from "../../../utils/controls";
import TextField from "@material-ui/core/TextField";

const FstAutocomplete = (props) => {
  const { variant } = Controls.TextField;
  const classes = InputStyles();
  const [inputValue, setInputValue] = useState("");
  const { name, label, value, handleChange, list, getOptions } = props;

  const handleChangeValue = (value) => {
    handleChange(name, value);
  };

  const setValue = () => {
    setInputValue(value);
  };

  useEffect(() => {
    setValue();
    return () => {};
  }, [value]);

  return (
    <Autocomplete
      size={props.size}
      options={list}
      className={props.fstClass ? props.fstClass : classes.Autocomplete}
      style={props.fstStyle}
      id={name}
      openOnFocus
      blurOnSelect
      getOptionLabel={getOptions}
      value={inputValue}
      selectOnFocus
      onChange={(event, newValue) => handleChangeValue(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          style={props.fstStyle}
          required
          variant={variant}
        />
      )}
    />
  );
};

FstAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  list: PropTypes.array.isRequired,
  getOptions: PropTypes.func.isRequired,
  size: PropTypes.string,
  fstStyle: PropTypes.object,
  fstClass: PropTypes.string,
};

FstAutocomplete.defaultProps = {
  disabled: false,
  readOnly: false,
  list: [],
  size: "medium",
  fstStyle: {},
};

export default FstAutocomplete;
