import axios from "axios";
import {
  getOrderAscTableValue,
  translateCurrentPageForClient,
  translateCurrentPageForServer,
  translateCurrentPageForServerGridSyncFusion,
} from "../utils/common";

export const getAllTendersData = async (
  orderBy,
  orderDirection,
  page,
  pageSize,
  search,
) => {
  const defaultResponse = { totalCount: 0, page: 0, data: [], error: "" };

  try {
    const axiosResult = await axios.post("/api/Tender/GetTenders", {
      OrderBy: (!!orderBy && !!orderBy.field && orderBy.field) || "TenderId",
      PageSize: pageSize,
      Filter: search,
      PageNumber: translateCurrentPageForServer(page),
      OrderAsc: getOrderAscTableValue(orderDirection),
    });

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return {
        totalCount: data.totalItems,
        page: translateCurrentPageForClient(data.currentPage),
        data: data.currentPageItems,
        pageSize: pageSize,
      };
    } else {
      defaultResponse.error = "Error fetching tenders data";
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};

export const getManagedTendersList = async (
  orderBy,
  orderDirection,
  page,
  pageSize,
  search,
  statusId,
) => {
  const defaultResponse = { totalCount: 0, page: 0, data: [], error: "" };
  try {
    const axiosResult = await axios.post("/api/Manage/GetManageList", {
      OrderBy: orderBy || "dateCreationManage",
      PageSize: pageSize,
      Filter: search,
      PageNumber: translateCurrentPageForServerGridSyncFusion(page, pageSize),
      OrderAsc: getOrderAscTableValue(orderDirection),
      StatusId: !!statusId ? statusId : 0,
    });

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      setTimeout(() => {
        return {
          totalCount: data.totalItems,
          page: translateCurrentPageForClient(data.currentPage),
          data: data.currentPageItems,
          pageSize: data.pageSize,
        };
      }, 2000000);
    } else {
      defaultResponse.error = "Error fetching managed tenders data";
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};

export const addToManagedTenders = async (payload) => {
  try {
    const axiosResult = await axios.post("/api/Manage/ManageTender", payload);

    const error = "Error saving tenders";
    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      if (!!data && data.length > 0) {
        return data.map((d) => {
          return {
            TenderNumber: d.tenderNumber,
            Result: d.result,
          };
        });
      }
    }
    return { error };
  } catch (error) {
    console.log({ error });
    return { error };
  }
};

export const removeTenderFromManagedList = async (row, tableData) => {
  try {
    const axiosResult = await axios.post("/api/Manage/DeleteTenders", [
      {
        tenderNumber: row.tenderNumber,
        deleteComment: row.deleteComment,
        deleteReason: row.deleteReason,
      },
    ]);

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      const { errorOnDelete } = data;
      if (!!errorOnDelete) {
        console.log({ errorOnDelete });
        return tableData;
      } else {
        const { data: rows, totalCount, ...rest } = tableData;

        const index = rows.indexOf(row);
        rows.splice(index, 1);

        return { ...rest, rows, totalCount: totalCount - 1 };
      }
    } else {
      console.log("Error deleting tenders data");
      return tableData;
    }
  } catch (error) {
    console.log({ error });
    return tableData;
  }
};

export const getDeletedTendersList = async (
  orderBy,
  orderDirection,
  page,
  pageSize,
  search,
  statusId,
) => {
  const defaultResponse = { totalCount: 0, page: 0, data: [], error: "" };

  try {
    const axiosResult = await axios.post(
      "/api/DeletedTenders/GetDeletedTendersList",
      {
        OrderBy: orderBy || "dateCreationManage",
        PageSize: pageSize,
        Filter: search,
        PageNumber: translateCurrentPageForServerGridSyncFusion(page, pageSize),
        OrderAsc: getOrderAscTableValue(orderDirection),
        StatusId: !!statusId ? statusId : 0,
      },
    );

    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return {
        totalCount: data.totalItems,
        page: translateCurrentPageForClient(data.currentPage),
        data: data.currentPageItems,
        pageSize: data.pageSize,
      };
    } else {
      defaultResponse.error = "Error fetching managed tenders data";
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};
