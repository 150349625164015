import React from "react";
import { L10n, loadCldr } from "@syncfusion/ej2-base";
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  WorkWeek,
  Month,
  Inject,
  Resize,
  DragAndDrop,
  ExcelExport,
} from "@syncfusion/ej2-react-schedule";
import { applyCategoryColor } from "./helper";
import { GetLegalDocumentList } from "../services/tenderService";
import {
  GetDateTime,
  AddHourToDate,
  ConvertToDateLong,
  formatDate,
} from "../../../utils/DateHelper";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { formatShortDate, isLangPE, isLangCR } from "utils/common";
import { injectIntl } from "react-intl";

const numberingSystems = require("cldr-data/supplemental/numberingSystems.json");
const gregorian = require("cldr-data/main/es/ca-gregorian.json");
const numbers = require("cldr-data/main/es/numbers.json");
const timeZoneNames = require("cldr-data/main/es/timeZoneNames.json");
class TenderCalendar extends React.Component {
  ViewsENUM = {
    DAY: "Day",
    WEEK: "Week",
    MONTH: "MONTH",
  };

  scheduleObj;
  formatIso = "YYYY-MM-DDTHH:mm:ss";
  defaultPageSize = 1000;
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: GetDateTime(),
    };
  }

  getTenderList(start, end, PageSize) {
    const request = {
      OrderBy: "tenderNumber",
      PageSize: PageSize,
      Filter: "",
      PageNumber: 1,
      OrderAsc: false,
      StatusId: 0,
      StartDate: start,
      EndDate: end,
    };
    return GetLegalDocumentList(request);
  }

  async getDataToScheduler(selectedDate, PageSize) {
    const start = selectedDate
      .clone()
      .add(-1, "M")
      .startOf("month")
      .format(this.formatIso);
    const end = selectedDate
      .clone()
      .add(1, "M")
      .endOf("month")
      .format(this.formatIso);
    const res = await this.getTenderList(start, end, PageSize);
    const wrapper = res.data.currentPageItems.map((item) => {
      const parser = {
        ...item,
        Id: item.tenderId,
        Subject: item.fileNumber,
        StartTime: AddHourToDate(item.offersReceptionEnd, -1),
        EndTime: AddHourToDate(item.offersReceptionEnd, 0),
        CategoryColor: item.manageInsternalStatusColor
          ? item.manageInsternalStatusColor
          : "#3f51b5",
      };
      return parser;
    });

    //Flatten manageDetails field and add it to the wrapper if deliveryDate is not null, just like in the previous lines
    let wrapperWithManageDetails = [];
    wrapper.forEach((item) => {
      if (item.manageDetails?.length > 0) {
        item.manageDetails.forEach((detail) => {
          if (detail.deliveryDate) {
            const parser = {
              ...detail,
              ...item,
              Id: detail.tenderDetailId,
              Subject: `${item.fileNumber} - ${detail.code}`,
              ItemDescription: detail.description,
              ItemQuantity: detail.qty,
              ItemUOM: detail.uom,
              ItemCode: detail.code,
              ItemDeliveryDate: detail.deliveryDate,
              StartTime: AddHourToDate(detail.deliveryDate, -1),
              EndTime: AddHourToDate(detail.deliveryDate, 0),
              CategoryColor: "#f7b74f", // Arbitrary color: Light orange
            };
            wrapperWithManageDetails.push(parser);
          }
        });
      }
    });

    this.setState({
      ...this.state,
      result: res.data,
      dataSource: [...wrapper, ...wrapperWithManageDetails],
    });
  }

  componentDidMount() {
    this.getDataToScheduler(this.state.selectedDate, this.defaultPageSize);
  }

  onEventRendered(args) {
    if (this.scheduleObj) {
      applyCategoryColor(args, this.scheduleObj.currentView);
    }
  }

  content(props, formatMessage) {
    const jsUcfirst = (string) => {
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    };

    return (
      <div className="container-root-sheduler">
        {props.elementType === "cell" ? (
          <div className="e-cell-content e-template"></div>
        ) : (
          <div className="e-event-content e-template">
            <div className="e-subject-wrap">
              <div>
                <label>
                  <strong className="labelModal">
                    {formatMessage({
                      id: "cols.Convocatoria",
                      defaultMessage: "Convocatoria",
                    })}
                    :
                  </strong>{" "}
                  <span>{props.tenderNumber}</span>
                </label>
              </div>
              <div>
                <label>
                  <strong className="labelModal">
                    {formatMessage({
                      id: "cols.Nombre",
                      defaultMessage: "Nombre",
                    })}
                    :
                  </strong>{" "}
                  <span>{jsUcfirst(props.tenderName)}</span>
                </label>
              </div>
              <div>
                <label>
                  <strong className="labelModal">
                    {formatMessage({
                      id: "cols.Entidad",
                      defaultMessage: "Entidad",
                    })}
                    :
                  </strong>
                  <span>{jsUcfirst(props.clientUnitName)}</span>
                </label>
              </div>
              {(isLangPE() || isLangCR()) && (
                <div>
                  <label>
                    <strong className="labelModal">
                      {formatMessage({
                        id: "cols.FinPresProp",
                        defaultMessage: "Fin Pres. Prop.",
                      })}
                      :
                    </strong>
                    <span>
                      {props.offersReceptionEnd
                        ? ConvertToDateLong(props.offersReceptionEnd)
                        : "-"}
                    </span>
                  </label>
                </div>
              )}
              <div>
                <label>
                  <strong className="labelModal">
                    {formatMessage({
                      id: "cols.Estado",
                      defaultMessage: "Estado",
                    })}
                    :
                  </strong>
                  <span>{props.tenderStatusDesc}</span>
                </label>
              </div>
              <div>
                <label>
                  <strong className="labelModal">
                    {formatMessage({
                      id: "cols.Situacion",
                      defaultMessage: "Situación",
                    })}
                    :
                  </strong>
                  <span>
                    {props.manageInternalStatusDesc
                      ? props.manageInternalStatusDesc
                      : "Sin Asignar"}
                  </span>
                </label>
              </div>
              <div>
                <label>
                  <strong className="labelModal">
                    {formatMessage({
                      id: "cols.Portal",
                      defaultMessage: "Portal",
                    })}
                    :
                  </strong>
                  <span>{props.portal}</span>
                </label>
              </div>
              <div>
                <label>
                  <strong className="labelModal">Ejecutivos:</strong>
                  <span>
                    {props.sellers.length
                      ? props.sellers.toString()
                      : "Sin Asignar"}
                  </span>
                </label>
              </div>
              {isLangCR() && (
                <div>
                  <label>
                    <strong className="labelModal">
                      {formatMessage({
                        id: "cols.Finconsultas",
                        defaultMessage: "Fin consultas",
                      })}
                      :
                    </strong>
                    <span>
                      {props.questionsReceptionEnd
                        ? ConvertToDateLong(props.questionsReceptionEnd)
                        : "-"}
                    </span>
                  </label>
                </div>
              )}

              {props.tenderDetailId && (
                <>
                  <div>
                    <h3 style={{ marginBottom: 0 }}>
                      <strong className="labelModal">
                        {`Información del ${formatMessage({
                          id: "cols.Item",
                          defaultMessage: "Item",
                        })}`}
                      </strong>
                    </h3>
                  </div>
                  <div>
                    <label>
                      <strong className="labelModal">Descripción:</strong>
                      <span>
                        {props.ItemDescription ? props.ItemDescription : "-"}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <strong className="labelModal">
                        {formatMessage({
                          id: "tabL.Codigo_Cubso",
                          defaultMessage: "Código CUBSO",
                        })}
                        :
                      </strong>
                      <span>{props.ItemCode ? props.ItemCode : "-"}</span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <strong className="labelModal">Fecha de entrega:</strong>
                      <span>
                        {props.ItemDeliveryDate
                          ? formatShortDate(props.ItemDeliveryDate)
                          : "-"}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <strong className="labelModal">Cantidad:</strong>
                      <span>
                        {props.ItemQuantity ? `${props.ItemQuantity}` : "-"}
                      </span>
                    </label>
                  </div>
                  <div>
                    <label>
                      <strong className="labelModal">Unidad:</strong>
                      <span>{props.ItemUOM ? props.ItemUOM : "-"}</span>
                    </label>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  actionComplete(args) {
    const currentDate = moment(this.scheduleObj.selectedDate);
    if (currentDate.month() === this.state.selectedDate.month()) return;
    this.getDataToScheduler(currentDate, this.defaultPageSize);
    this.setState({
      ...this.state,
      selectedDate: currentDate,
    });
  }

  async onExportClick() {
    const { formatMessage } = this.props.intl;
    if (!this.scheduleObj) return;
    const total = this.scheduleObj.activeView.renderDates.length - 1;
    const start = moment(this.scheduleObj.activeView.renderDates[0]).format(
      this.formatIso,
    );
    const end = moment(this.scheduleObj.activeView.renderDates[total]).format(
      this.formatIso,
    );
    const rest = await this.getTenderList(start, end, 10000);
    const wrapper = rest.data.currentPageItems.map((item) => {
      // TODO: refactor later
      const parser = {
        [formatMessage({
          id: "cols.Ejecutivos",
          defaultMessage: "Ejecutivos",
        })]: item.sellers.toString(),
        [formatMessage({
          id: "cols.Publicacion",
          defaultMessage: "Publicación",
        })]: ConvertToDateLong(item.publishedDate),
        [formatMessage({
          id: "cols.Nomenclatura",
          defaultMessage: "Nomenclatura",
        })]: item.fileNumber,
        [formatMessage({
          id: "cols.Convocatoria",
          defaultMessage: "Convocatoria",
        })]: item.tenderNumber,
        [formatMessage({
          id: "cols.Entidad",
          defaultMessage: "Entidad",
        })]: item.clientUnitName,
        [formatMessage({
          id: "cols.Nombre",
          defaultMessage: "Nombre",
        })]: item.tenderName,
        [formatMessage({
          id: "cols.Situacion",
          defaultMessage: "Situación",
        })]: item.manageInternalStatusDesc,

        ...(isLangPE() || isLangCR()
          ? {
              [formatMessage({
                id: "cols.OContratacion",
                defaultMessage: "O.Contratación",
              })]: item.tenderDescription,
            }
          : {}),

        [formatMessage({
          id: "cols.Referencial",
          defaultMessage: "Estimado/Referencial",
        })]: item.estimatedValue,
        [formatMessage({
          id: "cols.Moneda",
          defaultMessage: "Moneda",
        })]: item.currency,
        [formatMessage({
          id: "cols.Estado",
          defaultMessage: "Estado",
        })]: item.tenderStatusDesc,
        [formatMessage({
          id: "cols.Items",
          defaultMessage: "Items",
        })]: item.detailsQty,
        [formatMessage({
          id: "cols.Finconsultas",
          defaultMessage: "Fin consultas",
        })]: ConvertToDateLong(item.questionsReceptionEnd),

        ...(isLangPE()
          ? {
              [formatMessage({
                id: "cols.BasesInt",
                defaultMessage: "Bases int.",
              })]: ConvertToDateLong(item.basesIntegratedEnd),
              [formatMessage({
                id: "cols.FinRegistro",
                defaultMessage: "Fin Registro",
              })]: ConvertToDateLong(item.registryEnd),
              [formatMessage({
                id: "cols.BuenaPro",
                defaultMessage: "Buena Pro",
              })]: ConvertToDateLong(item.awardEnd),
            }
          : {}),
        ...(isLangPE() || isLangCR()
          ? {
              [formatMessage({
                id: "cols.FinPresProp",
                defaultMessage: "Fin Pres. Prop.",
              })]: ConvertToDateLong(item.offersReceptionEnd),
            }
          : {}),

        [formatMessage({
          id: "cols.Usuario",
          defaultMessage: "Usuario",
        })]: item.userManage,
        [formatMessage({
          id: "cols.Segmento",
          defaultMessage: "Segmento",
        })]: item.categories.length ? item.categories[0] : "",
        [formatMessage({
          id: "cols.Gestionada",
          defaultMessage: "Gestionada",
        })]: ConvertToDateLong(item.dateCreationManage),
      };
      return parser;
    });

    let exportValues = {
      fields: [
        formatMessage({
          id: "cols.Ejecutivos",
          defaultMessage: "Ejecutivos",
        }),
        formatMessage({
          id: "cols.Publicacion",
          defaultMessage: "Publicación",
        }),
        formatMessage({
          id: "cols.Nomenclatura",
          defaultMessage: "Nomenclatura",
        }),
        formatMessage({
          id: "cols.Convocatoria",
          defaultMessage: "Convocatoria",
        }),
        formatMessage({
          id: "cols.Entidad",
          defaultMessage: "Entidad",
        }),
        formatMessage({
          id: "cols.Nombre",
          defaultMessage: "Nombre",
        }),
        formatMessage({
          id: "cols.Situacion",
          defaultMessage: "Situación",
        }),
        formatMessage({
          id: "cols.Referencial",
          defaultMessage: "Estimado/Referencial",
        }),

        ...(isLangPE() || isLangCR()
          ? [
              formatMessage({
                id: "cols.OContratacion",
                defaultMessage: "O.Contratación",
              }),
            ]
          : []),

        formatMessage({
          id: "cols.Moneda",
          defaultMessage: "Moneda",
        }),
        formatMessage({
          id: "cols.Estado",
          defaultMessage: "Estado",
        }),
        formatMessage({
          id: "cols.Items",
          defaultMessage: "Items",
        }),
        formatMessage({
          id: "cols.Finconsultas",
          defaultMessage: "Fin consultas",
        }),

        ...(isLangPE()
          ? [
              formatMessage({
                id: "cols.BasesInt",
                defaultMessage: "Bases int.",
              }),
              formatMessage({
                id: "cols.FinRegistro",
                defaultMessage: "Fin Registro",
              }),
              formatMessage({
                id: "cols.BuenaPro",
                defaultMessage: "Buena Pro",
              }),
            ]
          : []),
        ...(isLangPE() || isLangCR()
          ? [
              formatMessage({
                id: "cols.FinPresProp",
                defaultMessage: "Fin Pres. Prop.",
              }),
            ]
          : []),

        formatMessage({
          id: "cols.Usuario",
          defaultMessage: "Usuario",
        }),
        formatMessage({
          id: "cols.Segmento",
          defaultMessage: "Segmento",
        }),
        formatMessage({
          id: "cols.Gestionada",
          defaultMessage: "Gestionada",
        }),
      ],
      customData: wrapper,
    };

    this.scheduleObj.exportToExcel(exportValues);
  }

  onActionBegin(args) {
    if (args.requestType === "toolbarItemRendering") {
      let exportItem = {
        align: "Right",
        text: "Exportar",
        cssClass: "e-excel-export",
        click: this.onExportClick.bind(this),
      };
      args.items.push(exportItem);
    }
  }

  onPopupOpen(args) {
    if (args.type === "Editor") {
      args.cancel = true;
      this.props.history.push(
        "/gestion-informacion/" + encodeURIComponent(args.data.tenderNumber),
      );
    }
  }

  render() {
    loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
    L10n.load(require("@syncfusion/ej2-locale/src/es.json"));
    return (
      <>
        <ScheduleComponent
          width="100%"
          locale="es"
          popupOpen={this.onPopupOpen.bind(this)}
          height={this.props.height}
          dateFormat={formatDate.es.SHORT}
          ref={(schedule) => (this.scheduleObj = schedule)}
          eventSettings={{ dataSource: this.state.dataSource }}
          quickInfoTemplates={{
            content: (itemContent) => {
              const { formatMessage } = this.props.intl;
              return this.content(itemContent, formatMessage);
            },
          }}
          eventRendered={this.onEventRendered.bind(this)}
          actionBegin={this.onActionBegin.bind(this)}
          actionComplete={this.actionComplete.bind(this)}
          currentView="Month"
        >
          <ViewsDirective>
            <ViewDirective option="Day" />
            <ViewDirective option="Week" />
            <ViewDirective option="Month" />
          </ViewsDirective>

          <Inject
            services={[
              Day,
              Week,
              WorkWeek,
              Month,
              Resize,
              DragAndDrop,
              ExcelExport,
            ]}
          />
        </ScheduleComponent>
      </>
    );
  }
}

export default withRouter(injectIntl(TenderCalendar));
