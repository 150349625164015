import { useContext } from "react";
import { langContext } from "../../context/langContext";
import { useIntl } from "react-intl";

const useLangForm = () => {
  const langChange = useContext(langContext);
  const intl = useIntl();

  const FormatFilterForm = (id, type, data) => {
    const filter = langChange.configForm[id].Filter;

    const result = data.filter((item) => {
      if (filter[type].some((value) => value === item.idLang)) {
        const newTitle = intl.formatMessage({
          id: item.idLang,
          defaultMessage: item.title,
        });
        item.etq_title = newTitle;
        item.titlePanel = newTitle;
        return item;
      }
    });

    return result;
  };

  const FormatFilterFormObject = (id, type, data) => {
    const filter = langChange.configForm[id].Filter;

    const result = data.filter((item) => {
      if (filter[type].some((value) => value.id === item.idLang)) {
        const newTitle = intl.formatMessage({
          id: item.idLang,
          defaultMessage: item.title,
        });
        item.etq_title = newTitle;
        item.titlePanel = newTitle;
        return item;
      }
    });

    return result;
  };

  return {
    FormatFilterForm,
    FormatFilterFormObject,
  };
};

export default useLangForm;
