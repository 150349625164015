import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

/**
 * https://stackoverflow.com/a/14801798
 * List based on: http://en.wikipedia.org/wiki/Valid_characters_in_XML
 */
const NOT_SAFE_IN_XML_1_0 =
  /[^\x09\x0A\x0D\x20-\xFF\x85\xA0-\uD7FF\uE000-\uFDCF\uFDE0-\uFFFD]/gm;
export const sanitizeStringForXML = (value) => {
  "use strict";
  return typeof value === "string"
    ? String(value || "").replace(NOT_SAFE_IN_XML_1_0, "")
    : value;
};

export const flattenSheetDataFromJson = (data, header) => {
  if (!data || !data.length) return [header];
  // Flattens the data from JSON to a 2D array with header based order
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
};

export const getNewWorkbook = async () => {
  // Creates a new workbook with a single sheet
  const workbook = await XlsxPopulate.fromBlankAsync();
  workbook.sheet(0).name("Sheet1");
  return workbook;
};

export const saveNewWorkbook = async (workbook, fileName) => {
  // Saves the workbook as a new file
  const blob = await workbook.outputAsync();
  saveAs(blob, fileName);
};

export const sheetDataToJSON = (header, data) => {
  // Converts the sheet data to JSON
  return data.map(function (row) {
    return row.reduce(function (result, field, index) {
      if (field === undefined) field = null;
      result[header[index]] = field;
      return result;
    }, {});
  });
};

/* header: [ { headerText: "Header 1", field: "field1" }, ...] */
export const flattenSheetDataFromJsonFieldBased = (data, header) => {
  // flatten the data from JSON to a 2D array with header based order and based on the header fields
  if (!data || !data.length) return [header.map((item) => item.headerText)];
  let fields = header.map((item) => item.field);
  let sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header.map((item) => item.headerText));
  return sheetData;
};
