import TenderAppFrame from "components/TenderAppFrame";
import React from "react";

class ErrorHandlingPage extends React.Component {
  constructor(props) {}
  render() {
    return (
      <div style={{ textAlign: "center", marginTop: "10%" }}>
        <h2>
          Ha ocurrido un error. Por favor refresque el sitio. Si el problema
          persiste, contacte a soporte.
        </h2>
      </div>
    );
  }
}

export default ErrorHandlingPage;
