import { AlertPendingSave } from "../types";

export const PendingSaveShow = () => (dispatch) => {
  dispatch({
    type: AlertPendingSave.PENDING_ACTIVE,
  });
};

export const PendingSaveHide = () => (dispatch) => {
  dispatch({
    type: AlertPendingSave.PENDING_DEACTIVE,
  });
};

export const OpenAlertPending = () => (dispatch) => {
  dispatch({
    type: AlertPendingSave.ALERT_ACTIVE,
  });
};

export const CloseAlertPending = () => (dispatch) => {
  dispatch({
    type: AlertPendingSave.ALERT_DEACTIVE,
  });
};

export const LinkRedirect = (toRedirect) => (dispatch) => {
  dispatch({
    type: AlertPendingSave.ALERT_REDIRECT,
    toRedirect: toRedirect,
  });
};

export const FormShow = () => (dispatch) => {
  dispatch({
    type: AlertPendingSave.FORM_ACTIVE,
  });
};

export const FormHide = () => (dispatch) => {
  dispatch({
    type: AlertPendingSave.FORM_DEACTIVE,
  });
};
