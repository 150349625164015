import React from "react";
import MiniDrawer from "./GestionFilters";
import { FstGrid } from "../../../components/grid/FstGrid";
import Grid from "@material-ui/core/Grid";
import { ColumnsDates } from "./HeaderTableTenders";

const GridTenders = (props) => {
  return (
    <>
      {props.statusGroups && (
        <Grid container id="container-gestion-table">
          <Grid item xs={12}>
            <MiniDrawer
              heightValue={props.height + 132}
              filters={props.statusGroups}
              selectId={props.selectId}
              handleSelected={(item) => props.handleSelected(item)}
            >
              <FstGrid
                data={props.dataGrid}
                uniqValue={props.uniqValue}
                result={props.result}
                ColumnsDate={ColumnsDates}
                reloadDefault={props.reloadDefault}
              />
            </MiniDrawer>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default GridTenders;
