import React, { Fragment, Component } from "react";
import MenuSendTenders from "./MenuSendTender";

const getState = () => {
  const stateKey = "state";
  const { sendTenderReducer } = JSON.parse(localStorage.getItem(stateKey));
  const { sendTender } = sendTenderReducer;
  return sendTender;
};

class MasterSendTenders extends Component {
  state = {
    startIcon: false,
    title: "",
    open: false,
    filter: [],
  };

  componentDidMount() {
    this.initIcon(this.props.tenderId);
  }

  initIcon(id) {
    const send = getState();
    let title = "";
    const exist = JSON.stringify(send).indexOf(id) === -1 ? false : true;
    let filter = [];
    if (exist) {
      filter = send.filter((item) => id === item.tenderId);
    }
    this.setState({
      startIcon: exist,
      title: title,
      open: false,
      filter: filter,
    });
  }

  render() {
    const { startIcon, title, open, filter } = this.state;

    if (startIcon) {
      return (
        <MenuSendTenders
          tenderId={this.props.tenderId}
          tenderNumber={this.props.tenderNumber}
          getData={filter}
          {...this.props}
        />
      );
    }

    return <></>;
  }
}

export default MasterSendTenders;
