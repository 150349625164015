import React from "react";
import FstModal from "./FstModal";
import WarningTwoToneIcon from "@material-ui/icons/WarningTwoTone";
import { makeStyles } from "@material-ui/core/styles";

const ModalConfirmStyle = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    fontSize: "1.1em",
  },
  iconMargen: {
    marginRight: "10px",
  },
});

const FstModalConfirm = (props) => {
  const classes = ModalConfirmStyle();
  return (
    <FstModal
      open={props.open}
      title={props.title}
      handleClose={props.close}
      handleSave={props.success}
      widthSize="xs"
      IsDisabledPrimary={false}
      setting={{
        BtnNameSave:
          props.setting && props.setting.BtnNameSave
            ? props.setting.BtnNameSave
            : "Confirmar",
      }} //default Confirmar
      onlyOK={props.onlyOK ? props.onlyOK : false} //default ambos botones
      fullWidth={props.fullWidth ? props.fullWidth : false} //default false
    >
      <div className={classes.root}>
        <WarningTwoToneIcon color="secondary" className={classes.iconMargen} />
        <p>{props.message}</p>
      </div>
    </FstModal>
  );
};

export default FstModalConfirm;
