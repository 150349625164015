import React from "react";
import { formatDate } from "utils/common";

const handleList = (list) => {
  let aux = "";
  for (let index = 0; index < list.length; index++) {
    const element = list[index].join("|");
    if (aux.length > 0) {
      aux = ` ${aux}@${element}`;
    } else {
      aux = element;
    }
  }

  return aux;
};

export const Headers = [
  {
    field: "name",
    headerText: "Nombre",
    width: "200",
    textAlign: "Left",
    allowSorting: true,
    showInColumnChooser: false,
  },
  {
    field: "includeWords",
    headerText: "Incluir por item",
    width: "200",
    textAlign: "Left",
    customExcelTemplate: (items) => handleList(items.includeWords),
    template: (props) => <span>{handleList(props.includeWords)}</span>,
  },
  {
    field: "excludeWords",
    headerText: "Excluir por item",
    width: "200",
    textAlign: "Left",
    customExcelTemplate: (items) => handleList(items.excludeWords),
    template: (props) => <span>{handleList(props.excludeWords)}</span>,
  },
  {
    field: "includeWordsName",
    headerText: "Incluir por nombre",
    width: "200",
    textAlign: "Left",
    customExcelTemplate: (items) => handleList(items.includeWordsName),
    template: (props) => <span>{handleList(props.includeWordsName)}</span>,
  },
  {
    field: "excludeWordsName",
    headerText: "Excluir por nombre",
    width: "200",
    textAlign: "Left",
    customExcelTemplate: (items) => handleList(items.excludeWordsName),
    template: (props) => <span>{handleList(props.excludeWordsName)}</span>,
  },
  {
    field: "days",
    headerText: "Cantidad de días",
    width: "140",
    textAlign: "Center",
  },
  {
    field: "creation",
    headerText: "Fecha de creación",
    width: "170",
    template: (props) => <span>{formatDate(props.creation)}</span>,
  },
  {
    field: "lastUpdate",
    headerText: "Fecha de modificación",
    width: "170",
    template: (props) => <span>{formatDate(props.lastUpdate)}</span>,
  },
  {
    field: "createdBy",
    headerText: "Creado por",
    width: "140",
    textAlign: "Left",
  },
  {
    field: "updatedBy",
    headerText: "Modificado por",
    width: "140",
    textAlign: "Left",
  },
  {
    headerText: "Acciones",
    width: "110px",
    commands: [
      {
        type: "Edit",
        buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
      },
      {
        type: "Remove",
        buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
      },
    ],
  },
];
