import { post, get, put, Delete } from "../../../config/axios.js";

export const massivePostProduct = async (request) => {
  return await post("/api/product/massive", request);
};

export const postProduct = async (request) => {
  return await post("/api/product", request);
};

export const deleteProduct = async (id) => {
  return await Delete(`/api/product/${id}`);
};

export const putProduct = async (request) => {
  return await put("/api/product", request);
};

export const getProducts = async (request) => {
  /* given request is an object with the following structure:
    request = {
      OrderBy: pagination.orderBy,
      PageSize: pagination.pageSize,
      Filter: pagination.search,
      PageNumber: pagination.PageNumber,
      OrderAsc: pagination.OrderAsc,
    };
    add these as query params to the url if they are not null (using the same name as the object key)
    */
  let url = "/api/product?";
  for (const key in request) {
    if (request[key] != null && request[key] !== "") {
      url += `${key}=${request[key]}&`;
    }
  }

  // if last char is & or ? remove it
  if (url[url.length - 1] === "&" || url[url.length - 1] === "?") {
    url = url.slice(0, -1);
  }
  return await get(url);
};
