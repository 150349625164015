import React from "react";
import { FstGrid } from "components/grid/FstGrid";
import moment from "moment";
import { useEffect, useState, Fragment } from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import { AllDetailsHeader } from "./AllDetailsHeader";
import * as SpinnerActions from "../../../actions/spinnerActions";
import { addToManagedTenders } from "actions/tenderActions";
import {
  fillAllDetailsSheet,
  getAllDetailsData,
  getAllDetailsFormattedInitialData,
} from "./AllDetailsExcelService";
import { getNewWorkbook, saveNewWorkbook } from "utils/excelExportService";

let productUpdated = {
  productId: null,
  productCode: null,
  productDesc: null,
  productData: null,
  hasChanged: false,
};

const AllDetails = ({
  data, //manageDetails
  tender,
  onChangeManagedList,
  height,
  reloadPage,
  clearMenu,
  tagsList,
  settingsChangeActive,
  ...props
}) => {
  const [allData, setAllData] = useState([]);
  const intl = useIntl();

  const onChangeProduct = (e, args) => {
    args.productCode = e.productCode;
    args.productDesc = e.productDesc;
    args.productId = e.productId;
    args.productData = e.data;

    productUpdated.productId = e.productId;
    productUpdated.productCode = e.productCode;
    productUpdated.productDesc = e.productDesc;
    productUpdated.productData = e.data;
    productUpdated.hasChanged = true;
  };

  const header = AllDetailsHeader(
    intl,
    onChangeProduct,
    tender.modalityType,
    tagsList,
  );

  const [firstRender, setFirstRender] = useState(true);
  const [uniqueValue, setUniqueValue] = useState(moment().format());
  const [currentPagination, setCurrentPagination] = useState(null);
  const [currentData, setCurrentData] = useState([]);
  const [alternativeItemIdCounter, setAlternativeItemIdCounter] = useState(0);

  const actionsRows = [
    {
      text: "Crear ítem alternativo",
      target: ".e-content",
      id: "create-item",
      iconCss: "e-icons e-edit-6",
    },
    {
      text: "Eliminar ítem alternativo",
      target: ".e-content",
      id: "delete-item",
      iconCss: "e-icons e-edit-5",
    },
  ];

  const fetchData = async (pagination, newData = null) => {
    props.SpinnerShow("Cargando...");
    if (firstRender) {
      let result = initData();
      setFirstRender(false);
      setCurrentData(
        result.slice(
          pagination.pageSize * (pagination.PageNumber - 1),
          pagination.pageSize * pagination.PageNumber,
        ),
      );
      props.SpinnerHide();
    } else {
      setTimeout(() => {
        setCurrentData(
          newData ||
            allData.slice(
              pagination.pageSize * (pagination.PageNumber - 1),
              pagination.pageSize * pagination.PageNumber,
            ),
        );
        props.SpinnerHide();
      }, 450);
    }
    setCurrentPagination(pagination);
  };

  const initData = () => {
    let alternativeCounter = alternativeItemIdCounter;
    let result = data.map((item) => {
      if (item.productCode) {
        item.FullDescription = `(${item.productCode}) - ${item.productDesc}`;
      }
      if (!item.isPrimary) {
        item.alternativeItemId = alternativeCounter + 1;
        alternativeCounter++;
      }
      return item;
    });
    setAlternativeItemIdCounter(alternativeCounter);

    setAllData(result);
    return result;
  };

  const createAlternativeItem = async (data) => {
    props.SpinnerShow("Cargando...");
    // add new item with same data as selected item, removing the ManageDetailId and setting isPrimary to false
    let newManageDetail = {
      ...data,
      estimatedTotal: null,
      offered: false,
      productId: null,
      productCode: null,
      productDesc: null,
      productData: null,
      tagIds: [],
      comments: null,
      deliveryDate: null,
      FullDescription: null,
      manageDetailId: null,
      alternativeItemId: alternativeItemIdCounter + 1,
      isPrimary: false,
    };
    delete newManageDetail.manageDetailId;
    setAlternativeItemIdCounter(alternativeItemIdCounter + 1);

    const indexToInsert = allData.findIndex(
      (item) => item.tenderDetailId === data.tenderDetailId,
    );
    let allDataCopy = [...allData];
    allDataCopy.splice(indexToInsert + 1, 0, newManageDetail);
    onChangeManagedList(allDataCopy);
    setAllData(allDataCopy);
    setTimeout(() => {
      fetchData(currentPagination, allDataCopy);
      props.SpinnerHide();
    }, 300);
  };

  const deleteAlternativeItem = async (data) => {
    props.SpinnerShow("Cargando...");
    // remove item from list
    let filteredManageDetails = allData.filter((item) => {
      return item.alternativeItemId !== data.alternativeItemId;
    });
    onChangeManagedList(filteredManageDetails);
    setAllData(filteredManageDetails);
    setTimeout(() => {
      fetchData(currentPagination, filteredManageDetails);
    }, 300);
  };

  const contextMenuIFnc = async (value) => {
    const {
      rowInfo: { rowData },
      item: { id },
    } = value;

    if (id === "create-item") {
      createAlternativeItem(rowData);
    } else if (id === "delete-item") {
      deleteAlternativeItem(rowData);
    }
  };

  const actionBegin = (args) => {
    if (args.action === "edit" && args.requestType === "save") {
      if (productUpdated.hasChanged) {
        args.data.productId = productUpdated.productId;
        args.data.productDesc = productUpdated.productDesc;
        args.data.productCode = productUpdated.productCode;
        args.data.productData = productUpdated.productData;

        productUpdated.productCode = null;
        productUpdated.productDesc = null;
        productUpdated.productId = null;
        productUpdated.productData = null;
        productUpdated.hasChanged = false;
      }
      if (args.data.deliveryDate)
        args.data.deliveryDate = moment(args.data.deliveryDate, "DD/MM/YYYY")
          .toDate()
          .toISOString();
    }
  };

  const handleSave = async (data) => {
    const aux = [];

    for (let index = 0; index < allData.length; index++) {
      const element = allData[index];
      if (
        (data.manageDetailId &&
          element.manageDetailId &&
          data.manageDetailId === element.manageDetailId) ||
        (data.alternativeItemId &&
          element.alternativeItemId &&
          data.alternativeItemId === element.alternativeItemId)
      ) {
        aux.push(data);
      } else {
        aux.push(element);
      }
    }

    onChangeManagedList(aux);
    setAllData(aux);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (settingsChangeActive) {
      setTimeout(() => {
        // Used to update tags list
        clearMenu();
      }, 450);
    }
  }, [settingsChangeActive]);

  const extraSelectionFunc = (args, grid) => {
    // Used to enable or disable context menu items
    var contextMenuObj = grid.contextMenuModule.contextMenu;
    if (args.name === "rowSelected" && args.data.isPrimary) {
      contextMenuObj.enableItems(["create-item"], true, true);
      contextMenuObj.enableItems(["delete-item"], false, true);
    } else if (args.name === "rowSelected" && !args.data.isPrimary) {
      contextMenuObj.enableItems(["create-item"], false, true);
      contextMenuObj.enableItems(["delete-item"], true, true);
    }
  };

  const exportFunc = async (args) => {
    const columns = header.filter((item) => !item.hideToExport);
    let dataToExportFormatted = getAllDetailsFormattedInitialData(
      allData,
      tagsList,
    );

    const { exportHeader, exportData, exportItemsData, exportTenderData } =
      getAllDetailsData(intl, tender, columns, dataToExportFormatted);

    getNewWorkbook().then(async (workbook) => {
      fillAllDetailsSheet(
        workbook,
        intl,
        0, // sheetNumber
        exportHeader,
        exportData,
        exportItemsData,
        exportTenderData,
      );

      return await saveNewWorkbook(
        workbook,
        `${intl.formatMessage({
          id: "tab.ListaItems",
          defaultMessage: "Lista de Items",
        })} - ${tender.tenderNumber}.xlsx`,
      );
    });
  };

  const remove = (btn, item) => {
    if (btn.type === "REMOVE") {
    }
  };

  const actionCustomRow = (btn, item) => {
    remove(btn, item);
  };

  const dataGrid = {
    Getdata: fetchData,
    EditOptions: {
      allowEditing: true,
    },
    allowSorting: true,
    allowPaging: true,
    take: 17,
    Header: header,
    height: height - 180,
    idGrid: "AllDetails2", //change to AllDetailsNew
    Updated: handleSave,
    ToolBar: {
      ExportClick: true,
    },
    contextMenuIFnc: contextMenuIFnc,
    actionsRows: actionsRows,
    extraSelectionFunc: extraSelectionFunc,
    ActionCustomRow: actionCustomRow,
    ExportClick: exportFunc,
    actionBegin: actionBegin,
  };

  return (
    <Fragment>
      <div id="container-table-all">
        <FstGrid
          data={dataGrid}
          uniqValue={uniqueValue}
          result={{
            result: currentData,
            count: allData.length,
          }}
          reloadDefault={true}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(AllDetails);
