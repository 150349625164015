import moment from "moment";
import React, { useState } from "react";
import { FstGrid } from "../../../components/grid/FstGrid";
import FstModal from "../../../components/modal/FstModal";
import {
  deleteAnalysisFilter,
  GetSearchFilter,
} from "../services/AnalysisService";
import {
  ColumnsDates,
  ColumnsLengths,
  ColumnsSearchFilterTypes,
  HeaderTable,
} from "./searchFilterHeaderTable";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

function SearchFilter(props) {
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [list, setList] = useState({ rows: [], totalRows: 0 });
  const [currentPageRequest, setCurrentPageRequest] = useState(null);
  const [toaster, setToaster] = useState({ open: false, message: "" });
  const settingModal = { BtnNameClose: "cerrar" };

  const handleClose = () => {
    props.handleClose(false);
  };

  const getData = async (pag, filter) => {
    const moduleId = props.AnalysisFilterModuleId;
    setCurrentPageRequest(pag);
    const response = await GetSearchFilter(pag, filter, moduleId);
    setList({
      rows: response.data.currentPageItems,
      totalRows: response.data.totalItems,
    });
  };

  const selected = (item) => {
    props.selectFilter(item);
  };

  const create = () => {
    props.create();
  };

  const toasterError = () => {
    setToaster({
      open: true,
      message: "El filtro no puede ser eliminado porque está siendo utilizado.",
    });
    setTimeout(() => {
      setToaster({ open: false, message: "" });
    }, 7000);
  };

  const executeDelete = async (item) => {
    if (!item) return;
    deleteAnalysisFilter(item.analysisFilterId)
      .then((response) => {
        getData(currentPageRequest);
        props.refresh();
      })
      .catch((error) => {
        if (error.msgErrorKey === "ENTITY_BEING_USED") {
          toasterError();
        }
      });
  };

  const update = (item) => {
    if (!item) return;
    props.edit(item);
  };

  const executeExport = async (pag, filter) => {
    const moduleId = props.AnalysisFilterModuleId;
    const response = await GetSearchFilter(pag, filter, moduleId);
    if (!!response && !!response.data) {
      return response.data.currentPageItems;
    }
  };

  const dataGrid = {
    Getdata: getData,
    Header: HeaderTable,
    take: 12,
    allowSorting: true,
    allowPaging: true,
    rowDoubleClickAction: selected,
    Add: create,
    Delete: executeDelete,
    Edit: update,
    ExportFileName: "Filtros Visualización de Reportes.xlsx",
    exportFunc: executeExport,
    ToolBar: {
      Add: true,
      AddTitle: "Nuevo",
      Delete: true,
      DeleteTitle: "Eliminar",
      Edit: true,
      EditTitle: "Editar",
      Export: true,
      Search: true,
    },
    idGrid: "SearchFilter",
  };

  return (
    <FstModal
      open={props.open}
      title="Filtros de visualización de reportes"
      handleSave={handleClose}
      setting={settingModal}
      widthSize="lg"
      onlyOK={true}
      classContainer={"fst-Modal-Container fts-ajuste"}
    >
      <FstGrid
        data={dataGrid}
        uniqValue={uniqValue}
        ColumnsDate={ColumnsDates}
        ColumnsLengths={ColumnsLengths}
        ColumnsJoinWithComma={ColumnsSearchFilterTypes}
        result={{
          result: list.rows,
          count: list.totalRows,
        }}
        reloadDefault={true}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right", zIndex: 800 }}
        open={toaster.open}
      >
        <Alert severity="error">{toaster.message}</Alert>
      </Snackbar>
    </FstModal>
  );
}

export default SearchFilter;
