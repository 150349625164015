import { Spinner } from "../types";

const INITIAL_STATE = {
  active: false,
  message: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Spinner.ACTIVE:
      return {
        ...state,
        active: true,
        message: action.message,
      };
    case Spinner.DEACTIVE:
      return {
        ...state,
        active: false,
        message: "",
      };
    default:
      return state;
  }
};
