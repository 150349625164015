import { post } from "../../../config/axios.js";

export const getTags = async () => {
  const request = {
    Filter: "",
    OrderBy: "tagId",
    OrderAsc: true,
  };
  return await post("/api/Tag/ListTags", request);
};
