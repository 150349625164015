import React, { useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import TagModal from "../views/tag/TagModal";
import InternalStatusModal from "../views/internal-status/InternalStatusModal";
import BankModal from "../views/bank/BankModal";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupIcon from "@material-ui/icons/Group";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import FlagIcon from "@material-ui/icons/Flag";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ProductModal from "../views/product/ProductModal";
import ViewListIcon from "@material-ui/icons/ViewList";
import SellerModal from "../views/seller/SellerModal";
import CategoryModal from "../views/category/CategoryModal";
import BusinessIcon from "@material-ui/icons/Business";
import DeleteReasonModal from "../views/delete-reasons/DeleteReasonModal";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useIntl } from "react-intl";

const AdministrationButton = (props) => {
  const modals = {
    Seller: false,
    Tag: false,
    InternalStatus: false,
    Bank: false,
    Product: false,
    Category: false,
    DeleteReason: false,
  };
  const [modal, setModal] = useState(modals);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const intl = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalClose = () => {
    setAnchorEl(null);
    setModal(modals);
  };

  const handleModalOpen = (key) => {
    const aux = { ...modals };
    aux[key] = true;
    setAnchorEl(null);
    setModal(aux);
  };

  return (
    <>
      <Tooltip title="Administración">
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <SettingsIcon style={{ color: "#fff" }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleModalOpen("Seller")}>
          <ListItemIcon>
            <GroupIcon />
          </ListItemIcon>
          <ListItemText primary="Ejecutivos" />
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen("Bank")}>
          <ListItemIcon>
            <HomeWorkIcon />
          </ListItemIcon>
          <ListItemText
            primary={intl.formatMessage({
              id: "title.Banco.Administracion",
              defaultMessage: "Bancos",
            })}
          />
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen("InternalStatus")}>
          <ListItemIcon>
            <FlagIcon />
          </ListItemIcon>
          <ListItemText primary="Situaciones" />
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen("Tag")}>
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Etiquetas" />
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen("Product")}>
          <ListItemIcon>
            <ViewListIcon />
          </ListItemIcon>
          <ListItemText primary="Productos" />
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen("Category")}>
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText primary="Segmentos de Mercado" />
        </MenuItem>
        <MenuItem onClick={() => handleModalOpen("DeleteReason")}>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="Motivos de eliminación" />
        </MenuItem>
      </Menu>

      <SellerModal
        open={modal.Seller}
        handleOpenModal={() => handleModalClose()}
      />

      <TagModal open={modal.Tag} handleOpenModal={() => handleModalClose()} />
      <InternalStatusModal
        open={modal.InternalStatus}
        handleOpenModal={() => handleModalClose()}
      />
      <BankModal open={modal.Bank} handleOpenModal={() => handleModalClose()} />
      <ProductModal
        open={modal.Product}
        handleOpenModal={() => handleModalClose()}
        handleRefreshModal={() => {
          handleModalOpen("Product");
        }}
      />
      <CategoryModal
        open={modal.Category}
        handleOpenModal={() => handleModalClose()}
      />
      <DeleteReasonModal
        open={modal.DeleteReason}
        handleOpenModal={() => handleModalClose()}
      />
    </>
  );
};

export default AdministrationButton;
