import React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";
import moment from "moment";
import useLangOffProvider from "../../../../intl/utils/hooks/useLangOffProvider";
import { GetEmailData } from "../../../tender-information/services/sendTenderMailService";

const CustomMenu = (props) => {
  const lang = useLangOffProvider();

  const handleOutMenu = (event) => {
    props.handleOut(event);
  };

  const handleClick = async (event) => {
    const id = event.currentTarget.id;
    props.handleClick(props.tenderId);
    try {
      props.SpinnerShow("Cargando...");
      const dataEmail = await GetEmailData(id);
      const spanElement = document.getElementById("fecha" + id).textContent;
      dataEmail.data.NotificationId = parseInt(id);
      props.handleOpenSend(
        dataEmail.data,
        spanElement.replace("fecha:", "Ultimo envio:")
      );
    } catch (e) {
      props.handleClick();
      console.error("id de email no encontrado " + id);
    }
  };

  const getEtqTo = (recipients, seller) => {
    const size = recipients.length + seller.length;
    const etq = seller.length > 0 ? seller[0] : recipients[0];
    return etq + (size > 1 ? ", +" + (size - 1) : "");
  };

  return (
    <Card
      id={"menu-icon-email-" + props.tenderId}
      className={"menu-icon-email"}
      onMouseOut={handleOutMenu}
    >
      <CardHeader
        title={
          <div className={"item-cant"}>
            <span>Detalle enviados</span>
            <span className={"span-cant"}>{props.getData.length}</span>
          </div>
        }
      ></CardHeader>
      <CardContent>
        {props.getData.map((mi, idx) => (
          <div key={props.tenderId + "A" + idx}>
            <div key={props.tenderId + "B" + idx} className={"div-hr"}>
              <hr></hr>
            </div>
            <div
              id={mi.onDemandNotificationId}
              key={props.tenderId + "C" + idx}
              className={"item-detail"}
              onClick={handleClick}
            >
              <span>
                {props.etqTo}: {getEtqTo(mi.recipients, mi.sellerNames)}
              </span>
              <span id={"fecha" + mi.onDemandNotificationId}>
                {props.etqDate}:{" "}
                {moment(mi.sentDate).format("DD/MM/YYYY HH:mm")}
              </span>
              <span>
                {props.etqSend}: {mi.sentCount}
              </span>
            </div>
          </div>
        ))}
      </CardContent>
    </Card>
  );
};

export default CustomMenu;
