import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const FstModal = (props) => {
  const BtnNameCancel =
    props.setting && props.setting.BtnNameCancel
      ? props.setting.BtnNameCancel
      : "Cancelar"; //default Cancelar
  const BtnNameSave =
    props.setting && props.setting.BtnNameSave
      ? props.setting.BtnNameSave
      : "Guardar"; //default Guardar
  return (
    <Dialog
      className={props.classContainer}
      fullWidth={props.fullWidth ? props.fullWidth : false} //default no full width
      maxWidth={props.widthSize}
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
        {props.title}
      </DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        {props.onlyOK ? null : (
          <Button onClick={props.handleClose}>{BtnNameCancel}</Button>
        )}
        <Button
          autoFocus
          onClick={props.handleSave}
          color="primary"
          disabled={props.IsDisabledPrimary}
        >
          {BtnNameSave}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FstModal;
