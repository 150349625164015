import React, { useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
import ManageReportFilter from "./report-display-filter/ManageReportFilter";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import SearchFilter from "./search-filter/SearchFilter";
import {
  getAnalysisFilter,
  getAnalysisFilterById,
} from "./services/AnalysisService";
import FstAutocomplete from "../../components/forms/autocomplete/FstAutocomplete";
import * as AnalysisReportsActions from "../../actions/analysisReportsActions";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import moment from "moment";
import AddIcon from "@material-ui/icons/Add";
import {
  getReportCategories,
  downloadReport,
} from "./services/AnalysisService";
import { FstSplitButton } from "../../components/forms/buttons/FstSplitButton";
import * as SpinnerActions from "../../actions/spinnerActions";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    background: "#fff",
  },
  btnPosition: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
    float: "right",
    "& span": {
      "& span": {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    width: "300px",
  },
  btnGrPrimary: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  btnGrIcon: {
    paddingBottom: 1,
    paddingTop: 4,
  },
}));

const autoStyles = makeStyles((theme) => ({
  root: {
    display: "inline",
    marginTop: "5px",
  },
}));

function AnalysisActions(props) {
  const classes = useStyles();
  const styleFilter = { width: 300, marginTop: "5px" };
  const optionsExport = [{ id: 1, description: "Exportar PDF" }];
  const primaryExport = { id: 2, description: "Exportar Excel" };
  const classesAuto = autoStyles();
  const [open, setOpen] = useState(false);
  const [listFilter, setListFilter] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [filter, setFilter] = useState("");
  const [report, setReport] = useState("");
  const [analysisFilterEntity, setAnalysisFilterEntity] = useState({});
  const [reports, setReports] = useState([]);
  const appInsights = useAppInsightsContext();
  const [loadingReport, setLoadingReport] = useState(false);

  const addFilter = () => {
    setAnalysisFilterEntity({});
    setOpen(true);
  };

  useEffect(() => {
    props.SpinnerHide();
  }, []);

  const getAnalysisfilterCombo = async () => {
    const result = await getAnalysisFilter(props.AnalysisFilterModuleId);
    const { data } = result;
    setListFilter(data);
    if (data && data.length > 0) {
      const result = data.filter((item) => item.uniqueId === filter.uniqueId);
      const selected = result.length > 0 ? result[0] : data[0];
      setFilter(selected);
      props.RefreshReport("AnalysisFilterId", selected.uniqueId);
      handleRefresh();
      return selected.uniqueId;
    } else {
      props.RefreshReport("loadReport", false);
      setFilter("");
    }
  };

  const refreshListFilter = async () => {
    const result = await getAnalysisFilter(props.AnalysisFilterModuleId);
    const { data } = result;
    setListFilter(data);
  };

  const editAnalysisFilterNoCloseSearch = async () => {
    const result = await getAnalysisFilterById(filter.uniqueId);
    setAnalysisFilterEntity(result.data);
    setOpen(true);
  };

  const selectAnalysisFilter = async (item) => {
    setFilter({
      uniqueId: item.analysisFilterId,
      name: item.analysisFilterName,
    });
    setOpenSearch(false);
    props.RefreshReport("AnalysisFilterId", item.analysisFilterId);
    handleRefresh();
  };

  const createAnalysisFilter = () => {
    setOpenSearch(false);
    setOpen(true);
  };

  const editAnalysisFilter = async (item) => {
    setOpenSearch(false);
    const result = await getAnalysisFilterById(item.analysisFilterId);
    setAnalysisFilterEntity(result.data);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    if (value) {
      getAnalysisfilterCombo();
    }
  };

  const handleCloseWithSave = async (value) => {
    setOpen(false);
    await refreshListFilter();
    setFilter({
      uniqueId: value.analysisFilterId,
      name: value.Name,
    });
    props.RefreshReport("AnalysisFilterId", value.analysisFilterId);
    props.RefreshReport("loadReport", true);
    handleRefresh();
  };

  const refresh = async () => {
    await getAnalysisfilterCombo();
  };

  const trackExportAnalysis = useTrackEvent(
    appInsights,
    "AL: Export Analysis",
    {},
  );
  const exportFilterPDF = async (value) => {
    const request = {
      ReportId: report.id,
      ExportType: value.id,
      AnalysisFilterId: parseInt(filter.uniqueId),
    };
    trackExportAnalysis({ params: request });

    props.SpinnerShow("Exportando...");
    downloadReport(request).then((response) => {
      if (value.id === 1) {
        downloadPDF(response.data);
      } else {
        exportToExcel(response.data);
      }
      setLoadingReport(false);
    });
    setLoadingReport(true);
    setTimeout(() => {
      props.SpinnerHide();
    }, 2000);
  };

  const exportToExcel = (value) => {
    const filterName = filter ? filter.name : "default";
    const anchor_href =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      value;
    const exportLinkElement = document.createElement("a");
    exportLinkElement.hidden = true;
    exportLinkElement.download = `${report.fullName} - ${filterName}.xlsx`;
    exportLinkElement.href = anchor_href;
    exportLinkElement.text = "downloading...";

    document.body.appendChild(exportLinkElement);
    exportLinkElement.click();
    exportLinkElement.remove();
  };

  function downloadPDF(pdf) {
    const filterName = filter ? filter.name : "default";
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${report.fullName} - ${filterName}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  const getReports = async () => {
    let reportList = [];
    let fileName = "";
    const result = await getReportCategories(props.AnalysisFilterModuleId);
    for (let index = 0; index < result.data.length; index++) {
      const element = result.data[index];
      const res = element.reports.map((item) => {
        item.fullName = `${element.name} - ${item.name}`;
        return item;
      });
      reportList = reportList.concat(res);
    }
    if (reportList[0]) {
      handleChangeReport(reportList[0]);
      setReports(reportList);
      fileName = reportList[0].fileName;
    }
    return fileName;
  };

  useEffect(() => {
    async function initProcess() {
      props.RefreshReport("loadReport", false);
      const id = await getAnalysisfilterCombo();
      const name = await getReports();
      isCompleteReport(id, name);
    }
    const time = setTimeout(() => {
      initProcess();
    });

    return () => {
      clearTimeout(time);
    };
  }, []);

  const handleChangeFilter = (value) => {
    if (value) {
      setFilter(value);
      props.RefreshReport("AnalysisFilterId", value.uniqueId);
      handleRefresh();
    }
  };

  const handleChangeReport = (value) => {
    if (value) {
      props.RefreshReport("ReportName", value.fileName);
      setReport(value);
      handleRefresh();
    }
  };

  const handleOpenSearch = () => {
    setOpenSearch(true);
  };

  const handleRefresh = () => {
    props.RefreshReport("refresh", moment().format());
  };

  const handleClick = (value) => {
    exportFilterPDF(value);
  };

  const isCompleteReport = (id, name) => {
    if (id && name) {
      props.RefreshReport("loadReport", true);
    }
  };

  return (
    <>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            className={classes.container}
          >
            <div className={classes.root}>
              <FstAutocomplete
                size="small"
                label="Reportes"
                name="reports"
                list={reports}
                fstStyle={styleFilter}
                fstClass={classesAuto.root}
                getOptions={(option) => (option ? option.fullName : "")}
                value={report}
                handleChange={(key, value) => handleChangeReport(value)}
              />
              <FstAutocomplete
                size="small"
                label="Filtros"
                name="filter"
                list={listFilter}
                fstStyle={styleFilter}
                fstClass={classesAuto.root}
                getOptions={(option) => (option ? option.name : "")}
                value={filter}
                handleChange={(key, value) => handleChangeFilter(value)}
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<AddIcon />}
                onClick={addFilter}
              >
                Agregar
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<EditIcon />}
                onClick={editAnalysisFilterNoCloseSearch}
              >
                Editar
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<SearchIcon />}
                onClick={handleOpenSearch}
              >
                Buscar
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<RefreshIcon />}
                onClick={handleRefresh}
              >
                Refrescar
              </Button> */}
              <FstSplitButton
                clasNamePrymary={classes.btnGrPrimary}
                btnGrIcon={classes.btnGrIcon}
                classNameBtn={classes.button}
                options={optionsExport}
                primary={primaryExport}
                label="Exportar"
                handleClick={handleClick}
              />
            </div>
          </ListSubheader>
        }
      ></List>
      <ManageReportFilter
        open={open}
        analysisFilterEntity={analysisFilterEntity}
        handleClose={(value) => handleClose(value)}
        handleCloseWithSave={handleCloseWithSave}
        AnalysisFilterModuleId={props.AnalysisFilterModuleId}
      />
      <SearchFilter
        open={openSearch}
        create={() => createAnalysisFilter()}
        edit={(item) => editAnalysisFilter(item)}
        refresh={() => refresh()}
        selectFilter={(item) => selectAnalysisFilter(item)}
        handleClose={(value) => setOpenSearch(value)}
        AnalysisFilterModuleId={props.AnalysisFilterModuleId}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={loadingReport}
      >
        <Alert severity="info">
          Generando resultados... En unos momentos se descargará el reporte.
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (reducer) => {
  return reducer.analysisReportReducer;
};
export default connect(mapStateToProps, {
  ...AnalysisReportsActions,
  ...SpinnerActions,
})(AnalysisActions);
