import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  badge: {
    right: -20,
  },
  root: {
    textTransform: "capitalize",
    marginLeft: "5px",
  },
}));
function TabsLabel(props) {
  const classes = useStyles();
  return (
    <Badge
      color="secondary"
      classes={{ badge: classes.badge }}
      badgeContent={props.count}
    >
      {props.icon}
      <Typography classes={{ root: classes.root }}>{props.name}</Typography>
    </Badge>
  );
}

export default TabsLabel;
