import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FstGrid } from "../../components/grid/FstGrid";
import { Controls } from "../../utils/controls";
import { CommentStyles } from "./style/TicketStyle";
import * as SpinnerActions from "../../actions/spinnerActions";

export const ChecklistHeader = [
  {
    id: "admissibilityRequirements",
    field: "admissibilityRequirements",
    headerText: "Requisitos de admisibilidad",
    width: "34%",
    textAlign: "Left",
    allowEditing: false,
  },
  {
    id: "description",
    field: "description",
    headerText: "Descripción del documento",
    width: "34%",
    textAlign: "Left",
    allowEditing: true,
    // template: (rowData) => {
    //   return <p style={{whiteSpace: "pre-wrap"}}>{rowData.description}</p>;
    // },
  },
  {
    id: "status",
    field: "status",
    headerText: "Status",
    width: "12%",
    textAlign: "Left",
    allowEditing: true,
    editType: "booleanedit",
    textAlign: "Center",
    displayAsCheckBox: true,
  },
  {
    id: "manager",
    field: "manager",
    headerText: "Encargado",
    width: "20%",
    textAlign: "Left",
    allowEditing: true,
  },
  {
    id: "cols.Acciones",
    headerText: "Acciones",
    width: "110px",
    hideToExport: true,
    commands: [
      {
        type: "Edit",
        buttonOption: { cssClass: "e-flat", iconCss: "e-edit e-icons" },
      },
      {
        type: "Save",
        buttonOption: { cssClass: "e-flat", iconCss: "e-update e-icons" },
      },
      {
        type: "Cancel",
        buttonOption: {
          cssClass: "e-flat",
          iconCss: "e-cancel-icon e-icons",
        },
      },
    ],
  },
];

const getEvaluationFactors = (data) => {
  let evaluationFactors = "";
  if (data)
    data.forEach((item) => {
      evaluationFactors += `${item.Factor}: ${item.Percentage}% \n`;
    });
  return evaluationFactors;
};

// Base data needed for the first time the user enters the page, because the checklist data is empty and there is no data in backend
export const baseChecklistData = (data, tenderDetailExtension) => [
  {
    id: 1,
    admissibilityRequirements: "Garantia de participación",
    description: `${data.Participation}${
      data.ParticipationAmmount
        ? ` - Monto: ${data.ParticipationAmmount}${
            data.ParticipationIsPercentage ? "%" : ""
          }${
            data.ParticipationValidity
              ? ` - Vigencia: ${data.ParticipationValidity}`
              : ""
          }`
        : ""
    }`,
    status: false,
    manager: null,
  },
  {
    id: 2,
    admissibilityRequirements: "Vigencia de la oferta",
    description: `${data.OfferValidity ?? ""}`,
    status: false,
    manager: null,
  },
  {
    id: 3,
    admissibilityRequirements: "Plazo de entrega",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 4,
    admissibilityRequirements: "Precio",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 5,
    admissibilityRequirements: "Marca y modelo",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 6,
    admissibilityRequirements: "Garantía del producto",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 7,
    admissibilityRequirements: "Muestra",
    description: `${tenderDetailExtension.SampleRequest ?? ""}`,
    status: false,
    manager: null,
  },
  {
    id: 8,
    admissibilityRequirements: "Sistema de evaluación",
    description: `Contenido: ${
      data.EvaluationContent?.trim().length > 0
        ? `${data.EvaluationContent}`
        : "-"
    } | Factores de evaluación: ${
      getEvaluationFactors(data.EvaluationFactors).trim().length > 0
        ? getEvaluationFactors(data.EvaluationFactors)
        : "-"
    }`,
    status: false,
    manager: null,
  },
  {
    id: 9,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 10,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 11,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 12,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 13,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 14,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 15,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 16,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 17,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 18,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 19,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 20,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 21,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 22,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
  {
    id: 23,
    admissibilityRequirements: "Requisito específico",
    description: "",
    status: false,
    manager: null,
  },
];

const Checklist = (props) => {
  const classes = CommentStyles();
  const { variant } = Controls.TextField;
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [allData, setAllData] = useState([]);
  const [manageIds, setManageIds] = useState({
    ManageId: props.manageData.manageId,
    ManageExtensionId: null,
  });
  let data = JSON.parse(JSON.parse(props.data?.extension)?.Info);
  let tenderDetailExtension =
    props.data.tenderDetails.length > 0
      ? JSON.parse(props.data.tenderDetails[0].extension)
      : {};
  let baseData = baseChecklistData(data, tenderDetailExtension);

  const setData = () => {
    //filter to get manageExtensions that have the same manageId
    let manageExtensions = (props.manageData.manageExtensions || []).filter(
      (manageExtension) => {
        return (
          manageExtension.manageId === props.manageData.manageId ||
          manageExtension.ManageId === props.manageData.manageId
        );
      },
    );

    if (manageExtensions.length === 0) {
      setAllData(baseData);
      setTimeout(() => {
        props.onChangeChecklist([
          {
            ManageId: props.manageData.manageId,
            Data: JSON.stringify(baseData),
          },
        ]);
      }, 350);
    } else {
      let extensionData = manageExtensions.map((item) => {
        return {
          ...item,
          manageExtensionId: item.manageExtensionId || item.ManageExtensionId,
          manageId: item.manageId || item.ManageId,
          listData: JSON.parse(item.data || item.Data),
        };
      });
      setAllData(extensionData[extensionData.length - 1].listData);
      setManageIds({
        ...manageIds,
        ManageExtensionId:
          extensionData[extensionData.length - 1].manageExtensionId,
      });
    }
  };

  useEffect(() => {
    setData();
  }, []);

  const handleSave = (item) => {
    // modify the item in allData based on admissibilityRequirements field
    let newData = allData.map((allDataItem) => {
      // the first verification is for backwards compatibility as the id field was added later
      if (
        (!allDataItem.id &&
          !item.id &&
          allDataItem.admissibilityRequirements ===
            item.admissibilityRequirements) ||
        (allDataItem.id && item.id && allDataItem.id === item.id)
      )
        return item;
      else return allDataItem;
    });
    // Somehow .map adds these fields thats need to be removed
    newData.forEach((item) => {
      delete item.result;
      delete item.aggregates;
      delete item.count;
    });

    props.SpinnerShow("Cargando...");
    setTimeout(() => {
      setAllData(newData);

      let checklistExtensionData = [
        {
          ManageId: manageIds.ManageId,
          Data: JSON.stringify(newData),
          ...(manageIds.ManageExtensionId != null && {
            ManageExtensionId: manageIds.ManageExtensionId,
          }),
        },
      ];
      props.onChangeChecklist(checklistExtensionData);
      props.SpinnerHide();
    }, 550);
  };

  const exportExcel = (pag, filter) => {
    let dataToExport = allData.map((item) => {
      return {
        ...item,
        status: item.status ? "Si" : "No",
      };
    });
    return dataToExport;
  };

  const dataGrid = {
    Getdata: () => {},
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      allowEditing: true,
      allowAdding: true,
    },
    ToolBar: { Export: true },
    ExportFileName: `Checklist - ${props.data?.tenderNumber}.xlsx`,
    exportFunc: exportExcel,
    Header: ChecklistHeader,
    ActionCustomRow: (btn, item) => {},
    height: props.height - 120,
    allowSorting: true,
    allowPaging: false,
    AddDefault: true,
    Updated: handleSave,
    idGrid: "Checklist",
  };

  return (
    <>
      <div className={classes.content}>
        <FstGrid
          data={dataGrid}
          uniqValue={uniqValue}
          result={{
            result: allData,
            count: allData.length,
          }}
          reloadDefault={true}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { user, spinnerReducer } = state;
  const { fullName } = user;

  return {
    ...ownProps,
    fullName,
    spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(Checklist);
