import React, { useEffect, useState } from "react";
import { useStyles } from "./style";

const CompanyManagerButton = (props) => {
  const [activeCompany, setActiveCompany] = useState(props.active);
  const classes = useStyles();

  const handleActiveCompany = (event) => {
    const element = event.currentTarget.classList;
    setActiveCompany(!element.contains("company-active"));
    props.handleClickActive(!activeCompany);
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div className={classes.containerStatus}>
      <div
        id="status"
        onClick={handleActiveCompany}
        className={
          classes.gridButton +
          " statustemp " +
          (activeCompany ? "company-active" : "company-inactive")
        }
      >
        <span>{activeCompany ? "Activo" : "Inactivo"}</span>
      </div>
    </div>
  );
};

export default CompanyManagerButton;
