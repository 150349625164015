import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CustomMenu from "./CustomMenuSend";
import useLangOffProvider from "../../../../intl/utils/hooks/useLangOffProvider";
import { getSellers } from "../../../seller/services/sellersService";

const MenuSendTenders = (props) => {
  const lang = useLangOffProvider();
  const [notificationId, setNotificationId] = useState("");
  const [htmlMaxHeight, setHtmlMaxHeight] = useState("");

  const MessageToaster = {
    tenderSendByEmail: "La licitacion fue enviada por mail",
    saveManageTender: "Los cambios han sido confirmados",
  };

  const handleClick = (event) => {
    hideMenu(event);
  };

  const handleOver = (event) => {
    const listMenu = document.querySelectorAll(".container-menu-email");
    listMenu.forEach((item) => {
      item.style.visibility = "hidden";
    });
    const element = event.currentTarget;
    var rect = element.getBoundingClientRect();
    let elementRender = document.getElementById(
      "menu-detail-mail-" + element.id
    );
    elementRender.style.top = rect.top - 15 + "px";
    elementRender.style.left = rect.left + 15 + "px";
    elementRender.style.visibility = "visible";
    let elementCar = document.getElementById("menu-icon-email-" + element.id);
    elementCar.style.overflowY = "auto";
    elementCar.style.maxHeight =
      document.documentElement.clientHeight - rect.top + "px";
  };

  const createMenu = () => {
    if (!document.getElementById("menu-detail-mail-" + props.tenderId)) {
      let element = document.createElement("div");
      element.id = "menu-detail-mail-" + props.tenderId;
      element.style.visibility = "hidden";
      element.classList.add("container-menu-email");
      document.body.appendChild(element);
    }
    const etqTo = lang.OneElement("etq.to", "para");
    const etqDate = lang.OneElement("etq.date", "fecha");
    const etqSend = lang.OneElement("etq.send", "Envíos");
    ReactDOM.render(
      <CustomMenu
        tenderId={props.tenderId}
        getData={props.getData}
        etqTo={etqTo}
        etqDate={etqDate}
        etqSend={etqSend}
        handleOut={handleOut}
        handleOpenSend={handleOpenSend}
        SpinnerShow={(value) => props.SpinnerShow(value)}
        SpinnerHide={() => props.SpinnerHide()}
        handleClick={handleClick}
        htmlMaxHeight={htmlMaxHeight}
      />,
      document.getElementById("menu-detail-mail-" + props.tenderId)
    );
  };

  const handleOpenSend = async (event, fecha) => {
    const listSellers = await getSellers();
    let filter = [];
    listSellers.data.forEach((item) => {
      const value = event.sellerIds.find((ind) => ind === item.sellerId);
      if (value) {
        filter.push(item);
      }
    });
    setNotificationId(event.NotificationId);

    const sendEmail = {
      Subject: event.subject,
      NotificationRecipients: event.recipients,
      Message: "",
      Files: [],
      Sellers: filter,
      emailAdjunto:
        `<p  style="margin: 20px 0px 0px 15px;" >${fecha}  -  ${event.recipients}</p>\n` +
        event.body,
      active: true,
    };

    const tender = {
      tenderId: props.tenderId,
      tenderNumber: props.tenderNumber,
      onDemandNotificationId: event.NotificationId,
    };
    props.resendEmail(tender, sendEmail);
    props.SpinnerHide();
  };

  const handleOut = (event) => {
    const elementIcon = document.getElementById(props.tenderId);
    var rectIcon = elementIcon?.getBoundingClientRect();

    const elementMenu = document.getElementById(
      "menu-icon-email-" + props.tenderId
    );
    var rectMenu = elementMenu.getBoundingClientRect();

    if (rectIcon && event.clientX >= rectIcon.left && event.clientX <= rectMenu.left) {
      if (rectMenu.top >= event.clientY || rectIcon.bottom <= event.clientY) {
        hideMenu(props.tenderId);
      }
    } else if (
      event.clientX >= rectMenu.left &&
      event.clientX <= rectMenu.right
    ) {
      if (rectMenu.top >= event.clientY || rectMenu.bottom <= event.clientY) {
        hideMenu(props.tenderId);
      }
    } else {
      hideMenu(props.tenderId);
    }
  };

  const hideMenu = (id) => {
    let elementRender = document.getElementById("menu-detail-mail-" + id);
    elementRender.style.visibility = "hidden";
  };

  useEffect(() => {
    createMenu();
    setHtmlMaxHeight(document.documentElement.clientHeight);
  });

  return (
    <Fragment>
      <span
        id={props.tenderId}
        onMouseOver={handleOver}
        onMouseOut={handleOut}
        className={"material-icons icon-email"}
      >
        {" "}
        mark_email_read{" "}
      </span>
    </Fragment>
  );
};

export default MenuSendTenders;
