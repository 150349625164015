import { Button } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
import * as ModalAction from "../../../actions/modalAction";
import * as SpinnerActions from "../../../actions/spinnerActions";
import { useStoreDataClient } from "../../../utils/hooks/useStoreData";

/*
queryClient: "MODAL_DETAIL", etc
result.detail: "Partida", "Linea", etc
result.data: []
result.RequestNumber: comes from props
titleModal: "Detalle de la partida", "Detalle de la línea", etc
modalName: "MODAL_DETAIL", etc
*/

const ButtonModalDetail = (props) => {
  const queryClient = useStoreDataClient(props.queryClient);
  const intl = useIntl();

  const handleClick = () => {
    props.SpinnerShow("Guardando...");
    let result = {
      ...props.result,
      detail: props.detail,
      data: [props.data],
    };

    queryClient.setDataQuery(result);
    const setting = { titleModal: props.titleModal };
    props.OpenModal(props.modalName, setting);
    props.SpinnerHide();
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Button onClick={handleClick} style={{ color: "#1976d2" }} size="small">
        Detalle
      </Button>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return { ...state };
};
export default connect(mapStateToProps, { ...SpinnerActions, ...ModalAction })(
  ButtonModalDetail,
);
