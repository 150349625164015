import { pending, fulfilled, rejected } from "./asyncStatusGenerator";

export function newPendingAction(type, res = null) {
  let returnObj = {
    type: pending(type),
  };

  if (res !== null) {
    returnObj["payload"] = res;
  }

  return returnObj;
}

export function newFulfilledAction(type, res) {
  return {
    type: fulfilled(type),
    payload: res,
  };
}

export function newRejectedAction(type, err) {
  return {
    type: rejected(type),
    payload: err,
  };
}
