import React, { useState } from "react";
import { TenderCommentsHeaderTable } from "./TenderCommentsHeaderTable";
import { FstGrid } from "../../../components/grid/FstGrid";
import FstModal from "../../../components/modal/FstModal";
import { CommentStyles } from "../style/TicketStyle";
import { connect } from "react-redux";
import { Controls } from "../../../utils/controls";
import TextField from "@material-ui/core/TextField";

const TenderComments = (props) => {
  const classes = CommentStyles();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const { variant } = Controls.TextField;
  const CreatedComment = () => {
    setComment("");
    setOpen(true);
  };

  const handleClose = () => {
    setComment("");
    setOpen(false);
  };

  const handleSave = () => {
    const com = {
      comment: comment,
      updatedByUserName: props.fullName,
      manageId: props.data.manageId,
    };
    props.addComment(com);
    setOpen(false);
  };

  const dataGrid = {
    Getdata: () => {},
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
    },
    Header: TenderCommentsHeaderTable,
    height: props.height - 120,
    Add: CreatedComment,
    ToolBar: {
      Add: true,
      AddTitle: "Agregar",
    },
    idGrid: "TenderComments",
  };

  const onChangeComment = (comments) => {
    setComment(comments);
  };

  return (
    <>
      <FstModal
        open={open}
        title="Agregar Comentario"
        handleClose={handleClose}
        handleSave={handleSave}
        widthSize="md"
      >
        <TextField
          variant={variant}
          id="comments"
          label="Comentario"
          style={{ width: "500px" }}
          inputValue={comment}
          autoComplete="off"
          multiline
          rows="6"
          onChange={(event) => {
            const { value } = event.target;
            onChangeComment(value);
          }}
        />
        {/*<TextEditor value={comment} height="400" onChange={onChangeComment}/>*/}
      </FstModal>
      <div className={classes.content}>
        <FstGrid
          data={dataGrid}
          uniqValue={1}
          result={{
            result: props.data.manageComments,
            count: props.data.manageComments.length,
          }}
          reloadDefault={true}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { user } = state;
  const { fullName } = user;

  return {
    ...ownProps,
    fullName,
  };
};

export default connect(mapStateToProps)(TenderComments);
