import { post, get, put } from "../../../config/axios.js";

/// Competitors
export const getCompetitors = async (request) => {
  return await post("/api/analysisfilter/getcompetitorsbycompanyid", request);
};

export const getCompetitorsByUniqueId = async (request) => {
  return await post("/api/competitorcompany/getcompetitorsbyrucs", request);
};

export const putCompetitors = async (request) => {
  return await put("/api/competitorcompany", request);
};
///

export const getCompetitorsIdsByCompany = async () => {
  return await get("/api/competitorcompany/getcompetitoridsbycompany");
};

/// Organisms
export const getOrganisms = async (request) => {
  return await post("/api/analysisFilter/getClientUnitsByCompanyId", request);
};

export const getOrganismsByUniqueId = async (request) => {
  return await post("/api/clientunitcompany/getclientunitsbyrucs", request);
};

export const putOrganisms = async (request) => {
  return await put("/api/ClientUnitCompany", request);
};

///

export const getOrganismsIdsByCompany = async () => {
  return await get("/api/ClientUnitCompany/getClientUnitIdsByCompany");
};
