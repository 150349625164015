import React from "react";
import {
  dateTimeFormat,
  dateTimeShortFormat,
  formatDate,
  formatShortDate,
} from "../../../utils/common";

export const HeaderTable = [
  {
    field: "operationCount",
    headerText: "Op.",
    width: "80",
    textAlign: "Center",
    showInColumnChooser: false,
  },
  {
    field: "lastOperationDate",
    headerText: "Ultima Op.",
    width: "180px",
    textAlign: "Center",
    template: (props) =>
      props.lastOperationDate ? (
        <span>{formatDate(props.lastOperationDate)}</span>
      ) : null,
  },
  {
    field: "analysisFilterName",
    headerText: "Nombre",
    width: "180px",
    textAlign: "left",
  },
  {
    field: "types",
    headerText: "Tipos",
    width: "120px",
    textAlign: "Center",
    allowSorting: false,
    template: (props) => {
      if (props.types.length === 0) {
        return <span>{"Todos"}</span>;
      } else {
        const rest = props.types
          .map((item) => {
            return item.code;
          })
          .join(", ");
        return <span>{rest}</span>;
      }
    },
  },
  {
    field: "dateRangeName",
    headerText: "Periodo",
    width: "160px",
    textAlign: "Center",
    allowSorting: false,
  },
  {
    field: "startDate",
    headerText: "Desde",
    width: "150px",
    textAlign: "Center",
    template: (props) =>
      props.startDate ? <span>{formatShortDate(props.startDate)}</span> : null,
  },
  {
    field: "endDate",
    headerText: "Hasta",
    width: "150px",
    textAlign: "Center",
    template: (props) =>
      props.endDate ? <span>{formatShortDate(props.endDate)}</span> : null,
  },
  {
    field: "priceRangeFrom",
    headerText: "Desde $",
    width: "120px",
    textAlign: "Center",
  },
  {
    field: "priceRangeTo",
    headerText: "Hasta $",
    width: "120px",
    textAlign: "Center",
  },
  {
    field: "includeWords",
    headerText: "Incluir",
    width: "200px",
    textAlign: "Center",
  },
  {
    field: "excludeWords",
    headerText: "Excluir",
    width: "200px",
    textAlign: "Center",
  },
  {
    field: "organisms",
    headerText: "Entidades",
    width: "150px",
    textAlign: "Center",
    allowSorting: false,
    template: (props) => <span>{props.organisms.length}</span>,
  },
  {
    field: "competitors",
    headerText: "Competidores",
    width: "150px",
    textAlign: "Center",
    allowSorting: false,
    template: (props) => <span>{props.competitors.length}</span>,
  },
  {
    field: "creationDate",
    headerText: "Creación",
    width: "150px",
    textAlign: "Center",
    template: (props) =>
      props.creationDate ? <span>{formatDate(props.creationDate)}</span> : null,
  },
  {
    field: "createdByName",
    headerText: "Creado por",
    width: "150px",
    textAlign: "Center",
  },
  {
    field: "lastUpdateDate",
    headerText: "Modificación",
    width: "150px",
    textAlign: "Center",
    template: (props) =>
      props.lastUpdateDate ? (
        <span>{formatDate(props.lastUpdateDate)}</span>
      ) : null,
  },
  {
    field: "updatedByName",
    headerText: "Modificado por",
    width: "150px",
    textAlign: "Center",
  },
];

export const ColumnsDates = [
  {
    field: "lastOperationDate",
    format: dateTimeFormat,
  },
  {
    field: "startDate",
    format: dateTimeShortFormat,
  },
  {
    field: "endDate",
    format: dateTimeShortFormat,
  },
  {
    field: "creationDate",
    format: dateTimeShortFormat,
  },
  {
    field: "lastUpdateDate",
    format: dateTimeFormat,
  },
];

export const ColumnsLengths = [
  {
    field: "organisms",
  },
  {
    field: "competitors",
  },
];

export const ColumnsSearchFilterTypes = [
  {
    field: "types",
    defaultValue: "Todos",
    key: "code",
  },
];
