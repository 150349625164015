import * as XLSX from "xlsx";

const useDataFile = (key, init) => {
  const getDataFile = async (event) => {
    const _file = {
      FileName: event.target.files[0].name,
    };
    if (_file.FileName.indexOf("xls") !== -1) {
      return await getDataExel(event);
    } else if (_file.FileName.indexOf("txt") !== -1) {
      return await getDataTXT(event);
    } else {
      return null;
    }
  };

  const getDataExel = (event) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(event.target.files[0]);
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workBook = XLSX.read(data, { type: "array" });
        const sheetName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[sheetName];

        const valueArray = [];
        Object.keys(sheet).forEach((key) => {
          if (sheet[key]["v"]) {
            valueArray.push(sheet[key]["v"].toString());
          }
        });

        resolve(valueArray);
      };
    });
  };

  const getDataTXT = (event) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      let fileContent = "";
      reader.readAsText(event.target.files[0]);
      reader.onload = (e) => {
        fileContent = reader.result.trim().split(/\r?\n/);
        resolve(fileContent);
      };
    });
  };

  return {
    getDataFile,
  };
};

export default useDataFile;
