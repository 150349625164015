import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Chip from "@material-ui/core/Chip";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { CircularProgress, Select } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MenuItem from "@material-ui/core/MenuItem";
import { Delete, Add } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { getFilterById, upsertFilter } from "../../actions/filterActions";
import UnsavedChangesWarning from "../../components/UnsavedChangesWarning";
import { StylePage } from "../../style/contentStyle";

const FilterDetails = withRouter(({ history, match }) => {
  const classes = StylePage();
  const INCLUDE_TYPE = "include";
  const INCLUDE_BY_NAME_TYPE = "include_by_name";
  const EXCLUDE_TYPE = "exclude";

  const [validationError, setValidationError] = React.useState({
    name: null,
    days: null,
    filters: null,
  });
  const [isEditing, setIsEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [name, setName] = React.useState("");
  const [days, setDays] = React.useState(0);
  const [activeRowDisplayInput, setActiveRowDisplayInput] = React.useState({
    type: null,
    rowIdx: null,
    individualTableRowId: null,
    isEditingChipIdx: null,
  });
  const [tableRows, setTableRows] = React.useState({
    [INCLUDE_TYPE]: [
      /*[],[],...*/
    ],
    [INCLUDE_BY_NAME_TYPE]: [
      /*[],[],...*/
    ],
    [EXCLUDE_TYPE]: [
      /*[],[],...*/
    ],
  });
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [newCriteria, setNewCriteria] = React.useState("");

  useEffect(() => {
    async function setupInitialData() {
      const filterId = match.params.id;
      if (!!filterId && filterId !== "new") {
        setLoading(true);
        try {
          const result = await getFilterById(filterId);
          if (!!result.data) {
            const curFilter = result.data;
            const i = !!curFilter.includeWords.length
              ? [curFilter.includeWords]
              : [];
            const e = !!curFilter.excludeWords.length
              ? [curFilter.excludeWords]
              : [];
            const ibn = !!curFilter.includeWordsNames.length
              ? [curFilter.includeWordsNames]
              : [];

            setName(curFilter.name);
            setDays(curFilter.days);
            setTableRows({
              [INCLUDE_TYPE]: i,
              [EXCLUDE_TYPE]: e,
              [INCLUDE_BY_NAME_TYPE]: ibn,
            });
            /*setTableRows({
                            [INCLUDE_TYPE]: [["jeringa", "aguja"]],
                            [EXCLUDE_TYPE]: [["sensor"]],
                            [INCLUDE_BY_NAME_TYPE]: [["something"]]
                        })*/
          }
        } finally {
          setLoading(false);
        }
      }
    }

    setupInitialData().then();

    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [match.params.id]);

  function onCriteriaChange(e) {
    setNewCriteria(e.target.value);
  }

  function handleOnKeyDown(e) {
    if (e.key === "Enter") {
      setIsEditing(true);
      const editingChipIdx = activeRowDisplayInput.isEditingChipIdx;

      let dstArr =
        tableRows[activeRowDisplayInput.type][0][
          activeRowDisplayInput.individualTableRowId
        ];
      if (!dstArr) {
        dstArr = [];
      }

      if (editingChipIdx !== null) {
        dstArr.splice(editingChipIdx, 1);
        const { isEditingChipIdx, ...rest } = activeRowDisplayInput;
        setActiveRowDisplayInput({ ...rest, isEditingChipIdx: null });
      }

      dstArr.push(newCriteria);
      const tr = { ...tableRows };
      tr[activeRowDisplayInput.type][0][
        activeRowDisplayInput.individualTableRowId
      ] = dstArr;

      setTableRows({ ...tr });
      setNewCriteria("");
    }
  }

  function getFilterRowJsx(tableRowObj, rowIdx) {
    if (!tableRowObj) {
      return null;
    }

    let helperText, colorStyle;
    const curType = tableRowObj.type;
    switch (curType) {
      case INCLUDE_TYPE:
        colorStyle = { backgroundColor: "blue" };
        helperText = "incluir por item";
        break;
      case INCLUDE_BY_NAME_TYPE:
        colorStyle = { backgroundColor: "green" };
        helperText = "incluir por nombre";
        break;
      case EXCLUDE_TYPE:
      default:
        colorStyle = { backgroundColor: "red" };
        helperText = "excluir por item";
        break;
    }

    return (
      <div className={classes.content}>
        <TableRow key={rowIdx}>
          <TableCell style={{ padding: "0.5em" }}>
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              style={colorStyle}
              onClick={() => {
                setActiveRowDisplayInput({
                  type: curType,
                  rowIdx: rowIdx,
                  individualTableRowId: tableRowObj.individualTableRowId,
                  isEditingChipIdx: null,
                });
              }}
            >
              <Add />
            </Fab>{" "}
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              onClick={() => {
                tableRows[curType][0].splice(
                  tableRowObj.individualTableRowId,
                  1
                );
                setTableRows({ ...tableRows });
              }}
            >
              <Delete />
            </Fab>{" "}
            {rowIdx === activeRowDisplayInput.rowIdx && (
              <TextField
                id="standard-basic"
                label={`Inserte criterio a ${helperText}`}
                onChange={onCriteriaChange}
                onKeyDown={handleOnKeyDown}
                autoFocus={true}
                value={newCriteria}
                onBlur={() => {
                  if (activeRowDisplayInput.type !== null) {
                    setActiveRowDisplayInput({
                      type: null,
                      rowIdx: null,
                      individualTableRowId: null,
                      isEditingChipIdx: null,
                    });
                  }
                }}
              />
            )}
            {!!tableRowObj.data &&
              tableRowObj.data.map((curChip, idx) => {
                return (
                  <Chip
                    key={idx}
                    color={"primary"}
                    style={{ ...colorStyle, margin: "0.3em" }}
                    label={curChip}
                    onClick={() => {
                      setActiveRowDisplayInput({
                        type: curType,
                        rowIdx: rowIdx,
                        individualTableRowId: tableRowObj.individualTableRowId,
                        isEditingChipIdx: idx,
                      });
                      setNewCriteria(curChip);
                    }}
                    onDelete={() => {
                      tableRows[curType][0][
                        tableRowObj.individualTableRowId
                      ].splice(idx, 1);
                      setTableRows({ ...tableRows });
                    }}
                  />
                );
              })}
          </TableCell>
        </TableRow>
      </div>
    );
  }

  function getSortedFilters() {
    function processRow(type) {
      let result = [];
      tableRows[type].forEach((rowArr) => {
        if (!!rowArr && rowArr.length > 0) {
          rowArr.forEach((row, idx) => {
            result.push({ data: row, individualTableRowId: idx, type: type });
          });
        }
      });
      return result;
    }

    const includeByName = processRow(INCLUDE_BY_NAME_TYPE);
    const include = processRow(INCLUDE_TYPE);
    const exclude = processRow(EXCLUDE_TYPE);
    return includeByName.concat(include).concat(exclude);
  }

  async function onSave() {
    const errors = { name: null, days: null, filters: null };
    if (!name || name.length === 0) {
      errors.name = "El campo nombre no puede estar vacío";
    }
    if (!days) {
      errors.days = "El campo últimos días de publicación no puede estar vacío";
    }
    if (
      !tableRows[INCLUDE_TYPE][0] &&
      !tableRows[EXCLUDE_TYPE][0] &&
      !tableRows[INCLUDE_BY_NAME_TYPE][0]
    ) {
      errors.filters = "Debe incluir alguna palabra";
    }
    if (!!errors.name || !!errors.days || !!errors.filters) {
      setValidationError(errors);
      return;
    }

    const filterId = match.params.id === "new" ? null : match.params.id;
    setLoading(true);
    await upsertFilter(
      filterId,
      name,
      days,
      tableRows[INCLUDE_TYPE][0],
      tableRows[EXCLUDE_TYPE][0],
      tableRows[INCLUDE_BY_NAME_TYPE][0]
    );
    setLoading(false);
    setIsEditing(false);
    history.goBack();
  }

  function onAddRowClick(type, offset) {
    const t = type;
    tableRows[t][0] = !!tableRows[t][0]
      ? tableRows[t][0].filter((row) => row.length > 0)
      : [];
    tableRows[t][0].push([]);
    setTableRows({ ...tableRows });
    setActiveRowDisplayInput({
      type: t,
      rowIdx: offset + tableRows[t][0].length - 1,
      individualTableRowId: tableRows[t][0].length - 1,
      isEditingChipIdx: null,
    });
    const ve = validationError;
    ve.filters = null;
    setValidationError(ve);
  }

  const availableDaysCount = [];
  for (let i = 1; i <= 60; i++) {
    availableDaysCount.push(
      <MenuItem key={i} value={i}>
        {i}
      </MenuItem>
    );
  }
  return (
    <UnsavedChangesWarning isEditing={isEditing}>
      <h2>Filtros Smart Search</h2>
      {loading && (
        <CircularProgress
          style={{ marginRight: "1em", marginLeft: "1em" }}
          size={"1.5rem"}
          color={"secondary"}
        />
      )}
      {!loading && (
        <React.Fragment>
          <div>
            <TextField
              id="standard-basic"
              label={"Filter Name"}
              error={!!validationError.name}
              helperText={!!validationError.name ? validationError.name : ""}
              value={name}
              onChange={(e) => {
                const ve = validationError;
                ve.name = null;
                setValidationError(ve);
                setIsEditing(true);
                setName(e.currentTarget.value);
              }}
            />
            <br />
            <div style={{ marginTop: "1.5em" }}>
              Licitaciones publicadas en los últimos{" "}
              <Select
                onChange={(e) => {
                  const ve = validationError;
                  ve.days = null;
                  setValidationError(ve);
                  setIsEditing(true);
                  setDays(e.target.value);
                }}
                value={days}
              >
                {availableDaysCount}
              </Select>{" "}
              días.
            </div>
            {!!validationError.days && (
              <div style={{ color: "red" }}>{validationError.days}</div>
            )}

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Paper
                  style={{
                    padding: "0.5em",
                    marginTop: "1.5em",
                    display: "inline-block",
                  }}
                >
                  Agregar palabras <br />
                  <ButtonGroup>
                    <Button
                      style={{ marginTop: "20px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const offset = !!tableRows[INCLUDE_BY_NAME_TYPE][0]
                          ? tableRows[INCLUDE_BY_NAME_TYPE][0].length
                          : 0;
                        onAddRowClick(INCLUDE_TYPE, offset);
                      }}
                    >
                      Incluir
                    </Button>

                    <Button
                      style={{
                        marginTop: "20px",
                        backgroundColor: "green",
                        color: "white",
                      }}
                      variant="contained"
                      onClick={() => {
                        onAddRowClick(INCLUDE_BY_NAME_TYPE, 0);
                      }}
                    >
                      Incluir por nombre
                    </Button>

                    <Button
                      style={{
                        marginTop: "20px",
                        backgroundColor: "red",
                        color: "white",
                      }}
                      variant="contained"
                      onClick={() => {
                        const offsetA = !!tableRows[INCLUDE_BY_NAME_TYPE][0]
                          ? tableRows[INCLUDE_BY_NAME_TYPE][0].length
                          : 0;
                        const offsetB = !!tableRows[INCLUDE_TYPE][0]
                          ? tableRows[INCLUDE_TYPE][0].length
                          : 0;
                        onAddRowClick(EXCLUDE_TYPE, offsetA + offsetB);
                      }}
                    >
                      Excluir
                    </Button>
                  </ButtonGroup>
                </Paper>
              </Grid>
            </Grid>

            {!!validationError.filters && (
              <div style={{ color: "red" }}>{validationError.filters}</div>
            )}

            <div
              style={{
                minHeight: "100px",
                maxHeight: dimensions.height - 450 + "px",
                overflow: "auto",
              }}
            >
              <Table style={{ marginTop: "1em" }}>
                <TableBody>
                  {getSortedFilters().map((tableRowArr, rowIdx) =>
                    getFilterRowJsx(tableRowArr, rowIdx)
                  )}
                </TableBody>
              </Table>
            </div>
          </div>
          <ButtonGroup>
            <Button
              style={{ marginTop: "20px" }}
              variant="contained"
              color="secondary"
              onClick={() => history.goBack()}
            >
              Cancelar
            </Button>

            <Button
              style={{ marginTop: "20px" }}
              variant="contained"
              color="primary"
              onClick={onSave}
            >
              Guardar
            </Button>
          </ButtonGroup>
        </React.Fragment>
      )}
    </UnsavedChangesWarning>
  );
});

export default FilterDetails;
