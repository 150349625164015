import axios from "axios";
import { acquireToken, login } from "../utils/activeDirectory";
import { IS_DEVELOPMENT } from "../utils/common";
import { setLoadingMessage } from "../actions/appActions";

export const SERVER_HOSTNAME = process.env.REACT_APP_SERVER_HOSTNAME;
export const SERVER_PORT = process.env.REACT_APP_API_PORT;

let errorFuse = true;
export function axiosConfig(dispatcher) {
  axios.interceptors.request.use(
    async function (config) {
      if (IS_DEVELOPMENT) {
        config.baseURL = `${SERVER_HOSTNAME}:${SERVER_PORT}/`;
      } else {
        config.baseURL = `${window.location.hostname}:${SERVER_PORT}/`;
      }

      try {
        config.headers["Content-Type"] = "application/json";
        config.cors = true;

        const { error, token } = await acquireToken();

        //console.log("Axios Config", {error}, {token});
        if (!error && !!token) {
          config.headers.Authorization = `Bearer ${token.idToken.rawIdToken}`;
        } else {
          await login(async () => {
            config.headers["Content-Type"] = "application/json";
            const { token } = await acquireToken();
            config.headers.Authorization = `Bearer ${token.idToken.rawIdToken}`;
          });
        }
      } catch (e) {
        console.log("Something wrong happened to acquireToken ", { e });
      }
      return config;
    },
    function (error) {
      console.log("REJECTED request", { error });
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (errorFuse && error.response && error.response.status !== 400) {
        errorFuse = false;
        dispatcher(
          setLoadingMessage(
            "La cuenta seleccionada no está autorizada para usar Tenders. Se abrirá la pantalla de salida.",
          ),
        );
        return Promise.reject(error);
      }
      return Promise.reject(error.response);
    },
  );
}

export const post = async (url, request) => {
  try {
    const axiosResult = await axios.post(url, request);
    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return { data };
    }
  } catch (error) {
    const message = error && error.data ? error.data : error;
    throw message;
  }
};

export const get = async (url) => {
  try {
    const axiosResult = await axios.get(url);
    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return { data };
    }
  } catch (error) {
    // TODO: handle error
  }
};

export const put = async (url, request) => {
  try {
    const axiosResult = await axios.put(url, request);
    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return { data };
    }
  } catch (error) {
    const message = error && error.data ? error.data : error;
    throw message;
  }
};

export const Delete = async (url, request) => {
  try {
    const axiosResult = await axios.delete(url);
    if (!!axiosResult && !!axiosResult.data) {
      const data = axiosResult.data;
      return { data };
    }
  } catch (error) {
    const message = error && error.data ? error.data : error;
    throw message;
  }
};
