import React from "react";
import { HeaderStyles } from "./style/style";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Controls } from "../../utils/controls";

const LegalDocumentFormHeader = ({ tender }) => {
  const { variant } = Controls.TextField;
  const classes = HeaderStyles();
  return (
    <div className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2} md={2}>
          <TextField
            id="Convocatoria"
            label="Convocatoria"
            variant={variant}
            className={classes.InputWidth}
            value={tender.tenderNumber}
            autoComplete="nope"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <TextField
            id="Nomenclatura"
            label="Nomenclatura"
            variant={variant}
            autoComplete="nope"
            value={tender.fileNumber}
            className={classes.InputWidth}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            multiline
            id="Entidad"
            label="Entidad"
            variant={variant}
            value={tender.clientUnitName}
            className={classes.InputWidth}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            multiline
            id="Descripcion"
            label="Descripcion"
            variant={variant}
            value={tender.tenderName}
            className={classes.InputWidth}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LegalDocumentFormHeader;
