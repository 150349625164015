import moment from "moment";

const setDates = (StartDate, EndDate) => {
  return { StartDate, EndDate };
};

export const filterDefault = {
  Name: "",
  RangeFrom: "",
  RangeTo: "",
  range: "",
  StartDate: "",
  EndDate: "",
  Types: [],
  IncludeKey: [{ word: "", words: [] }],
  ExcludeKey: [{ word: "", words: [] }],
  allowDatePicker: true,
  Result: false,
  SourceIds: "",
};

export const MomentRange = {
  1: "days",
  2: "week",
  3: "month",
  4: "year",
};

export const FIJO = { key: "1", value: "Fijo" };
export const PERSONALIZADO = { key: "14", value: "Personalizado" };
export const RangeDefault = { key: "8", value: "Trimestre actual" };

export const procesTime = {
  //FIJO
  1: () => {},
  //ayer
  3: () => {
    const aux = moment().subtract(1, "days").format("L");
    return setDates(aux, aux);
  },
  //hoy
  2: () => {
    const aux = moment().format("L");
    return setDates(aux, aux);
  },
  //SEMANA_ACTUAL
  4: () => {
    const start = moment().startOf("week").format("L");
    const end = moment().format("L");
    return setDates(start, end);
  },
  //SEMANA_ANTERIOR
  5: () => {
    const start = moment().subtract(1, "week").startOf("week").format("L");
    const end = moment().subtract(1, "week").endOf("week").format("L");
    return setDates(start, end);
  },
  //MES_ACTUAL
  6: () => {
    const start = moment().startOf("month").format("L");
    const end = moment().format("L");
    return setDates(start, end);
  },
  // MES_ANTERIOR
  7: () => {
    const start = moment().subtract(1, "month").startOf("month").format("L");
    const end = moment().subtract(1, "month").endOf("month").format("L");
    return setDates(start, end);
  },
  //TRIMESTRE_ACTUAL
  8: () => {
    const start = moment()
      .quarter(moment().quarter())
      .startOf("quarter")
      .format("L");
    const end = moment().quarter(moment().quarter()).format("L");
    return setDates(start, end);
  },
  //TRIMESTRE_ANTERIOR
  9: () => {
    const start = moment()
      .quarter(moment().quarter() - 1)
      .startOf("quarter")
      .format("L");
    const end = moment()
      .quarter(moment().quarter() - 1)
      .endOf("quarter")
      .format("L");
    return setDates(start, end);
  },
  //SEMESTRE_ACTUAL
  10: () => {
    const currentMonth = moment().month();
    const semesterStart = currentMonth > 5 ? 6 : 0;
    const startDate = moment().month(semesterStart);
    const start = startDate.startOf("month").format("L");
    const end = moment().format("L");
    return setDates(start, end);
  },
  //SEMESTRE_ANTERIOR
  11: () => {
    const currentMonth = moment().month(-6);
    const currentYear = moment(currentMonth).year();
    const semesterStart = currentMonth > 5 ? 6 : 0;
    const semesterEnd = currentMonth > 5 ? 11 : 5;
    const startDate = moment().month(semesterStart).year(currentYear);
    const endDate = moment().month(semesterEnd).year(currentYear);
    const start = startDate.startOf("month").format("L");
    const end = endDate.endOf("month").format("L");
    return setDates(start, end);
  },
  //ANIO_ACTUAL
  12: () => {
    const start = moment().startOf("year").format("L");
    const end = moment().format("L");
    return setDates(start, end);
  },
  //ANIO_ANTERIOR
  13: () => {
    const start = moment()
      .startOf("year")
      .subtract(1, "year")
      .startOf("month")
      .format("L");
    const end = moment()
      .subtract(1, "year")
      .month(11)
      .endOf("month")
      .format("L");
    return setDates(start, end);
  },
  //PERSONALIZADO
  14: () => {},
};
