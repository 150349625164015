import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Controls } from "../../utils/controls";

const useStyles2 = makeStyles({
  table: {
    minWidth: 500,
  },
  btn: {
    padding: 7,
  },
});

const initialErrorsText = {
  required: true,
  error: false,
  message: "",
  focus: true,
};

const CriteriaWordListCard = (props) => {
  const [listAux, setListAux] = useState([]);
  const [errorInput, setErrorInput] = useState(initialErrorsText);

  const classes = useStyles2();
  const { variant } = Controls.TextField;

  const handleKeyPress = (ev, index) => {
    const { value } = ev.target;
    if (ev.key === "Enter" && value.toString().trim().length > 1) {
      const rowsAux = [...listAux];
      rowsAux[index].words.push(value);
      rowsAux[index].word = "";
      props.handleList(rowsAux);
    }
  };

  const handleBlur = (value, index) => {
    const rowsAux = [...listAux];
    if (value.trim().length > 0) {
      rowsAux[index].words.push(value);
      rowsAux[index].word = "";
      props.handleList(rowsAux);
    }

    validateInput(props.name, value);
  };

  const handleChange = (value, index) => {
    const rowsAux = [...listAux];
    rowsAux[index].word = value;
    setListAux(rowsAux);
    validateInput(props.name, value);
  };

  const deleteRow = (index) => {
    const rowsAux = [...listAux];
    rowsAux.splice(index, 1);
    props.handleList(rowsAux);
  };

  const deleteWord = (indexRow, indexWord) => {
    const rowsAux = [...listAux];
    rowsAux[indexRow].words.splice(indexWord, 1);
    if (rowsAux[indexRow].words.length === 0) {
      deleteRow(indexRow);
    } else {
      props.handleList(rowsAux);
    }
  };

  useEffect(() => {
    setListAux(props.list);
  }, [props.list]);

  const validateInput = async (key, value) => {
    /*Validar elemento*/
    let errorTxt = false;
    let defaultErrorTxt = false;
    defaultErrorTxt = value.trim() ? true : false;
    if (key === "includeWords") {
      errorTxt =
        defaultErrorTxt || listAux[0].words.length !== 0 ? false : true;
    }

    await setErrorInput((prevState) => ({
      ...prevState,
      errorTxt,
    }));
  };

  return (
    <TableContainer component={Paper} elevation={0}>
      <Table className={classes.table} aria-label="custom pagination table">
        <TableBody>
          {listAux.map((row, indexRow) => (
            <TableRow key={indexRow} style={{ padding: "0px 5px", height: 40 }}>
              <TableCell
                style={{ width: 200, padding: 0 }}
                className="size-input-table"
                scope="row"
              >
                <TextField
                  size="small"
                  variant={variant}
                  id={`name_${indexRow}`}
                  placeholder="Ingrese criterio *"
                  autoComplete="off"
                  className={
                    classes.InputWidth +
                    (errorInput.errorTxt ? " input-error" : "")
                  }
                  value={row.word}
                  onChange={(event) => {
                    const { value } = event.target;
                    handleChange(value, indexRow);
                  }}
                  onKeyPress={(ev) => handleKeyPress(ev, indexRow)}
                  onBlur={(event) => {
                    const { value } = event.target;
                    handleBlur(value, indexRow);
                  }}
                  error={errorInput.errorTxt}
                />
              </TableCell>
              <TableCell style={{ width: 380, padding: 0 }} align="right">
                {row.words.map((item, index) => (
                  <Chip
                    key={index}
                    label={item}
                    className="border-none"
                    size="small"
                    onDelete={() => deleteWord(indexRow, index)}
                    variant="outlined"
                  />
                ))}
              </TableCell>
              <TableCell style={{ width: 50, padding: 0 }} align="right">
                {row.words.length > 0 && (
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteRow(indexRow)}
                    className={`icon-button-xxs ${classes.btn}`}
                    style={{ fontSize: "1.4rem" }}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CriteriaWordListCard;
