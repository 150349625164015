import React from "react";
import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import * as SpinnerActions from "../../actions/spinnerActions";
import MuiAlert from "@material-ui/lab/Alert";
import { useIntl } from "react-intl";
import { useEffect } from "react";
import { isLangAR } from "utils/common";
import { TenderParameter } from "views/tender-information/services/tenderParameter";
import XlsxPopulate from "xlsx-populate";
import { flattenSheetDataFromJson } from "utils/excelExportService";
import { sheetDataToJSON } from "utils/excelExportService";
import { getSources } from "views/smart-search/services/smartSearchService";
import { getTypes } from "views/tender-analisys/services/AnalysisService";
import {
  getClientUnitsByUniqueIds,
  getScrapingStatus,
  manageOutsiderTenders,
} from "./outsiderTendersService";
import { getSellers } from "views/seller/services/sellersService";
import { getCategories } from "views/tender-information/services/categoriesService";
import { getTags } from "views/tender-information/services/tagsService";
import { isLangCR } from "utils/common";
import { isLangPE } from "utils/common";
import { isLangMX } from "utils/common";
import useOutsiderTenderColumns from "./useOutsiderTenderColumns";
import OutsiderTendersManualInsert from "./OutsiderTendersManualInsert";
import { useCallback } from "react";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const OutsiderTenders = (props) => {
  const history = useHistory();
  const intl = useIntl();
  const [openToaster, setOpenToaster] = useState(false);
  const [openToasterMessage, setOpenToasterMessage] = useState(null);
  const [openToasterSeverity, setOpenToasterSeverity] = useState("success");

  const manageModes = {
    NONE: 0,
    MANUAL: 1,
    FILE: 2,
  };
  const [manageMode, setManageMode] = useState(manageModes.NONE);

  const [sources, setSources] = useState([]);
  const [tenderTypes, setTenderTypes] = useState([]);
  const [tendersStatus, setTendersStatus] = useState([]);

  const [sellers, setSellers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);

  const fetchInitialData = async () => {
    props.SpinnerShow("Cargando...");
    const sourcesResponse = await getSources();
    setSources(sourcesResponse.data);

    const tenderTypesResponse = await getTypes();
    let tenderTypesMapped = tenderTypesResponse.data.map((item) => {
      return {
        id: item.entityTypeId,
        name: item.name,
      };
    });
    setTenderTypes(tenderTypesMapped);

    let tendersStatusResponse = await getScrapingStatus();
    let tendersStatusMapped = tendersStatusResponse.data.map((item, idx) => {
      return {
        id: idx + 1,
        name: item,
      };
    });
    setTendersStatus(tendersStatusMapped);

    //////
    const sellersResponse = await getSellers();
    setSellers(sellersResponse.data);

    const categoriesResponse = await getCategories();
    setCategories(categoriesResponse.data);

    const tagsResponse = await getTags();
    setTags(tagsResponse.data);
    props.SpinnerHide();
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const showToasterError = (message) => {
    setOpenToasterMessage(message);
    setOpenToasterSeverity("error");
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
      setTimeout(() => {
        setOpenToasterSeverity("success");
      }, 750);
    }, 8000);
  };

  const showToasterSuccess = (message) => {
    setOpenToasterMessage(message);
    setOpenToasterSeverity("success");
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
      setTimeout(() => {
        setOpenToasterSeverity("success");
      }, 750);
    }, 7000);
  };

  // -- Excel data utils -- //

  const excelDataAdd = [
    {
      columnValue: "addButtonColumnExcel",
      columnName: "Agregar columna",
      modifiable: false,
    },
  ];

  const columns = useOutsiderTenderColumns();
  const [excelDataOrganization, setExcelDataOrganization] = useState([
    ...columns.getMinimumColumns(),
    ...excelDataAdd,
  ]);
  const [duplicateExcelPositions, setDuplicateExcelPositions] = useState({
    value: false,
    message: "",
  });
  const [excelDataOrganizationHeader, setExcelDataOrganizationHeader] =
    useState(false);

  //function that returns excel columns available from a to bz with format {id: 0, name: A}
  const getExcelColumns = () => {
    let columns = [];
    for (let i = 0; i < 26; i++)
      columns.push({ id: i, name: String.fromCharCode(65 + i) });
    for (let i = 0; i < 26; i++)
      columns.push({ id: i + 26, name: `A${String.fromCharCode(65 + i)}` });
    for (let i = 0; i < 26; i++)
      columns.push({ id: i + 52, name: `B${String.fromCharCode(65 + i)}` });
    return columns;
  };

  const verifyDuplicatePositions = (data) => {
    let seen = new Set();
    var hasDuplicates = data.some(
      (currentObject) =>
        seen.size === seen.add(currentObject.columnPosition).size,
    );
    hasDuplicates |= data.some(
      (currentObject) => seen.size === seen.add(currentObject.columnValue).size,
    );
    if (hasDuplicates) {
      setDuplicateExcelPositions({
        value: true,
        message: "No debe haber columnas repetidas",
      });
    } else {
      setDuplicateExcelPositions({ value: false, message: "" });
    }
    return hasDuplicates;
  };

  const handleChange = (event, item, mustModifyPosition = false) => {
    //find and modify the item in the excelDataOrganization array
    let excelDataOrganizationCopy = [...excelDataOrganization];
    let itemIndex = excelDataOrganizationCopy.findIndex(
      (x) =>
        x.columnPosition === item.columnPosition &&
        x.columnName === item.columnName &&
        x.columnValue === item.columnValue,
    );

    if (mustModifyPosition) {
      // modify the position of the item
      excelDataOrganizationCopy[itemIndex].columnPosition = event.target.value;
    } else {
      let itemToApply = columns
        .getExtraColumns()
        .find((x) => x.columnValue === event.target.value);
      if (!itemToApply) return; // shouldn't happen
      // modify the name of the item
      // remove dots from the string
      excelDataOrganizationCopy[itemIndex].columnValue =
        itemToApply.columnValue;
      excelDataOrganizationCopy[itemIndex].columnName = itemToApply.columnName;
    }
    setExcelDataOrganization(excelDataOrganizationCopy);

    // if two items have the same columnPosition, then warn user
    verifyDuplicatePositions(excelDataOrganizationCopy);
  };

  const handleAddColumn = () => {
    // max columnPosition + 1 (so if the user adds a columns and sets the position to 5, then the next column will be 6, even if there is no position 4)
    let maxColumnPosition = Math.max.apply(
      Math,
      excelDataOrganization.slice(0, -1).map((o) => o.columnPosition),
    );
    // this shouldn't happen, but just in case
    if (maxColumnPosition === -Infinity || maxColumnPosition === "NaN")
      maxColumnPosition = excelDataOrganization.length - 1;

    setExcelDataOrganization([
      ...excelDataOrganization.slice(0, -1),
      {
        columnName: "",
        columnValue: "",
        columnPosition: maxColumnPosition + 1,
        modifiable: true,
      },
      ...excelDataAdd,
    ]);
  };

  const handleRemoveColumn = (event, item) => {
    let excelDataOrganizationCopy = [...excelDataOrganization];
    // to find the item all three properties must match
    let itemIndex = excelDataOrganizationCopy.findIndex(
      (x) =>
        x.columnPosition === item.columnPosition &&
        x.columnName === item.columnName &&
        x.columnValue === item.columnValue,
    );
    excelDataOrganizationCopy.splice(itemIndex, 1);
    setExcelDataOrganization(excelDataOrganizationCopy);

    // if two items have the same columnPosition, then warn user
    verifyDuplicatePositions(excelDataOrganizationCopy);
  };

  const handleChangeHeaderSwitch = (event) => {
    setExcelDataOrganizationHeader(event.target.checked);
  };

  const [file, setFile] = useState(null);
  const [messageErrorFile, setMessageErrorFile] = useState(null);

  const addFiles = () => {
    document.getElementById("upload-button-file").click();
  };

  const validateFileSize = (file) => {
    if (file.size > TenderParameter.MaxFileSize) {
      const message = `El file ${file.name} supera el tamaño maximo permitido de 10 MB!`;
      setMessageErrorFile(message);
      setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    }
    setMessageErrorFile(null);

    return true;
  };

  const [excelWorkbook, setExcelWorkbook] = useState(null);

  const handleFileUpload = (event) => {
    if (!validateFileSize(event.target.files[0])) return;

    let _file = {
      FileName: event.target.files[0].name,
    };
    const newFiles = event.target.files;
    const f = newFiles[0];
    const excelReader = new FileReader();
    excelReader.onload = async function (e) {
      _file.Content = e.target.result;
      setFile(_file);

      // Parse data
      const bstr = e.target.result;
      const workbook = await XlsxPopulate.fromDataAsync(bstr);
      setExcelWorkbook(workbook);
    };
    if (f instanceof Blob) {
      excelReader.readAsArrayBuffer(f);
    }
  };

  const deleteFile = () => {
    setFile(null);
    setExcelWorkbook({});
  };

  const excelDateToJSDate = (date) => {
    // https://learn.microsoft.com/en-us/office/dev/scripts/resources/samples/excel-samples#dates
    return new Date(Math.round((date - 25569) * 86400 * 1000));
  };

  const handleUpload = async () => {
    if (file.FileName.indexOf("xls") === -1) {
      showToasterError("El archivo no es válido");
      return;
    }
    props.SpinnerShow("Cargando archivo...");

    const sheet = excelWorkbook.sheet(0);
    const data = sheet.usedRange().value();

    // Headers will be ignored
    const headers = data[0];
    const headersWithContent = headers.filter((header) => header != null); // headers with content == headers withtout undefined values
    //

    // array of empty strings of the same length as the excel columns
    // this way we can use the excel specified by the user, with the columns in any order
    let header = Array.from(
      { length: excelDataOrganization.length - 1 },
      () => null,
    );
    excelDataOrganization.forEach((item) => {
      if (item.columnValue !== "addButtonColumnExcel")
        header[item.columnPosition] = item.columnValue;
    });

    const rows = excelDataOrganizationHeader ? data.slice(1) : data; // remove header if present
    const rowsWithContent = rows.filter((row) => row.join("") !== "");

    let parsedData = sheetDataToJSON(header, rowsWithContent);

    // check if any mandatory value is null
    const mandatoryColumns = excelDataOrganization.filter(
      (item) => item.columnValue !== "addButtonColumnExcel" && !item.modifiable,
    );
    let hasNullColumns = false;
    let nullColumn = "";
    mandatoryColumns.forEach((column) => {
      if (
        parsedData.some(
          (item) =>
            item[column.columnValue] === null ||
            item[column.columnValue] === undefined ||
            item[column.columnValue] === "",
        )
      ) {
        hasNullColumns = true;
        nullColumn = column.columnName;
      }
    });
    if (hasNullColumns) {
      showToasterError(
        `El archivo no se pudo cargar. Verifique que la columna ${nullColumn} no tenga valores vacíos.`,
      );
      props.SpinnerHide();
      return;
    }
    //

    // -- Parse yes/no values to boolean -- //
    const yesNoKeys = excelDataOrganization
      .filter(
        //TODO: (item) => item.columnType === "yesNo",
        (item) => item.columnName.toLowerCase().includes("si/no"),
      )
      .map((item) => item.columnValue);

    parsedData = parsedData.map((item) => {
      yesNoKeys.forEach((key) => {
        if (item[key].toLowerCase() === "si") item[key] = true;
        else if (item[key].toLowerCase() === "no") item[key] = false;
      });
      return item;
    });
    // --  -- //

    // -- Parse date values to date -- //
    const dateKeys = excelDataOrganization
      .filter(
        //TODO: (item) => item.columnType === "date")
        (item) => item.columnName.toLowerCase().includes("fecha"),
      )
      .map((item) => item.columnValue);

    try {
      parsedData = parsedData.map((item) => {
        dateKeys.forEach((key) => {
          if (item[key]) {
            // Only accepting dates with excel format
            item[key] = excelDateToJSDate(item[key]).toISOString();
          }
        });
        return item;
      });
    } catch (error) {
      showToasterError(
        "Ocurrió un error al cargar una columna de tipo Fecha. Verifique que los valores de la columna tengan formato de fecha.",
      );
      props.SpinnerHide();
      return;
    }
    // --  -- //
    // Check if any mandatory value is null
    const mandatoryItems = excelDataOrganization.filter((item) =>
      columns
        .getMinimumColumns()
        .find((column) => column.columnName === item.columnName),
    );
    let hasNullValue = false;
    parsedData.forEach((item, idx) => {
      if (hasNullValue) return;
      mandatoryItems.forEach((mandatory) => {
        if (
          item[mandatory.columnValue] === null ||
          item[mandatory.columnValue] === undefined ||
          item[mandatory.columnValue] === ""
        ) {
          showToasterError(
            `El archivo no se pudo cargar. Verifique que el campo ${
              mandatory.columnName
            } en la fila ${
              excelDataOrganizationHeader ? idx + 2 : idx + 1
            } no esté vacío.`,
          );
          props.SpinnerHide();
          hasNullValue = true;
          return;
        }
      });
    });
    if (hasNullValue) {
      props.SpinnerHide();
      return;
    }

    const request = buildDTOToSave(parsedData);
    if (!request) {
      props.SpinnerHide();
      return;
    }
    // return;
    const response = await manageOutsiderTenders(request);

    if (response) {
      showToasterSuccess("Licitaciones cargadas correctamente.");
      deleteFile();
      props.SpinnerHide();
    } else {
      showToasterError(
        "Ocurrió un error al cargar el archivo. Por favor intente nuevamente.",
      );
      props.SpinnerHide();
    }
  };

  const nullifyUndefinedValues = (obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (!!value && typeof value === "object") {
        if (Array.isArray(value)) {
          value.forEach((item) => {
            nullifyUndefinedValues(item);
          });
        } else nullifyUndefinedValues(value);
      } else if (value === undefined || value === "" || value === null) {
        obj[key] = null;
      }
    });
    return obj;
  };

  const fillTenderDetail = (item) => {
    return {
      ItemNumber: item.tenderDetailItemNumber,
      Code: item.tenderDetailCode,
      Description: item.tenderDetailDescription,
      Qty: item.tenderDetailQty,
      UOM: item.tenderDetailUom,
      EstimatedValue: item.tenderDetailEstimatedValue,
      EstimatedValueCurrency: item.tenderDetailEstimatedValueCurrency,
      Observations: item.tenderDetailObservation,
      Specifications: item.tenderDetailSpecification,
      Conditioning: item.tenderDetailConditioning,
      Awardeds:
        item.tenderDetailAwardedCompetitorUniqueId &&
        item.tenderDetailAwardedCompetitorName &&
        item.tenderDetailAwardedAmmount !== "" &&
        item.tenderDetailAwardedAmmount !== undefined &&
        item.tenderDetailAwardedAmmount !== null &&
        item.tenderDetailAwardedQty !== "" &&
        item.tenderDetailAwardedQty !== undefined &&
        item.tenderDetailAwardedQty !== null &&
        item.tenderDetailAwardedCurrency
          ? [
              {
                ammount: item.tenderDetailAwardedAmmount,
                qty: item.tenderDetailAwardedQty,
                description: item.tenderDetailAwardedCompetitorDescription,
                currrency: item.tenderDetailAwardedCurrency,
                Competitor: {
                  // BE looks if the competitor exists, if not, creates it
                  UniqueId: item.tenderDetailAwardedCompetitorUniqueId,
                  Name: item.tenderDetailAwardedCompetitorName,
                },
              },
            ]
          : [],
    };
  };

  const fillManageDetail = (item) => {
    return {
      Offered: item.tenderManageDetailOffered,
      Comments: item.tenderManageDetailComments,
      EstimatedTotal: item.tenderManageDetailEstimatedTotal,
      DeliveryDate: item.tenderManageDetailDeliveryDate,
      ProductCode: item.tenderManageDetailProductCode,
      OfferedDate: item.tenderManageDetailOfferedDate,
    };
  };

  const fillTenderDetailAwarded = (item) => {
    return {
      Ammount: item.tenderDetailAwardedAmmount,
      Qty: item.tenderDetailAwardedQty,
      Description: item.tenderDetailAwardedCompetitorDescription,
      Currrency: item.tenderDetailAwardedCurrency,
      Competitor: {
        // BE looks if the competitor exists, if not, creates it
        UniqueId: item.tenderDetailAwardedCompetitorUniqueId,
        Name: item.tenderDetailAwardedCompetitorName,
      },
    };
  };

  const getTenderType = (item, index) => {
    const type = tenderTypes.find((type) => type.name === item.tenderType);
    if (!type) {
      showToasterError(
        `El ${intl.formatMessage({
          id: "cols.Tipo",
          defaultMessage: "Tipo",
        })} de licitación '${item.tenderType}' no existe en la fila ${
          excelDataOrganizationHeader ? index + 2 : index + 1
        }`,
      );
      // + 2 because the first row is the header and the index starts in 0
      return null;
    }
    return type.name;
  };

  const getTenderStatus = (item, index) => {
    const status = tendersStatus.find(
      (status) => status.name === item.tenderStatus,
    );
    if (!status) {
      showToasterError(
        `La ${intl.formatMessage({
          id: "cols.Situacion",
          defaultMessage: "Situación",
        })} de licitación '${item.tenderStatus}' no existe en la fila ${
          excelDataOrganizationHeader ? index + 2 : index + 1
        }`,
      );
      // + 2 because the first row is the header and the index starts in 0
      return null;
    }
    return status.name;
  };

  const getTenderManageSeller = (item) => {
    const seller = sellers.find(
      (seller) =>
        (seller.firstName === item.tenderManageSeller.split(" ")[0].trim() &&
          seller.lastName === item.tenderManageSeller.split(" ")[1].trim()) ||
        (seller.firstName === item.tenderManageSeller.split(" ")[1].trim() &&
          seller.lastName === item.tenderManageSeller.split(" ")[0].trim()),
    );
    if (!seller) {
      const newSeller = {
        firstName: item.tenderManageSeller.split(" ")[0].trim(),
        lastName: item.tenderManageSeller.split(" ")[1].trim(),
        email: null,
      };
      return newSeller;
    }
    return seller;
  };

  const getTenderManageCategory = (item) => {
    const category = categories.find(
      (category) => category.name === item.tenderManageCategory,
    );
    if (!category) {
      const newCategory = {
        name: item.tenderManageCategory,
      };
      return newCategory;
    }
    return category;
  };

  const getTenderManageTag = (item) => {
    const tag = tags.find((tag) => tag.name === item.tenderManageTag);
    if (!tag) {
      const newTag = {
        name: item.tenderManageTag,
      };
      return newTag;
    }
    return tag;
  };

  const getSourceId = () => {
    if (isLangCR()) {
      return sources.find((source) => source.name === "ExtCR").id;
    }
    if (isLangPE()) {
      return sources.find((source) => source.name === "ExtPeru").id;
    }
    if (isLangAR()) {
      return sources.find((source) => source.name === "ExtArg").id;
    }
    if (isLangMX()) {
      return sources.find((source) => source.name === "ExtMX").id;
    }
  };

  const verifyAwardedFields = (item, index) => {
    if (
      item.tenderDetailAwardedCompetitorUniqueId ||
      item.tenderDetailAwardedCompetitorName ||
      item.tenderDetailAwardedAmmount ||
      item.tenderDetailAwardedQty ||
      item.tenderDetailAwardedCurrency
    ) {
      if (
        !item.tenderDetailAwardedCompetitorUniqueId ||
        !item.tenderDetailAwardedCompetitorName ||
        item.tenderDetailAwardedAmmount === "" ||
        item.tenderDetailAwardedAmmount === undefined ||
        item.tenderDetailAwardedAmmount === null ||
        item.tenderDetailAwardedQty === "" ||
        item.tenderDetailAwardedQty === undefined ||
        item.tenderDetailAwardedQty === null ||
        !item.tenderDetailAwardedCurrency
      ) {
        showToasterError(
          `Para Adjudicación/Oferta se deben completar ID único Competidor (CUIT), Competidor, Monto, Cantidad y Moneda
           en la fila ${excelDataOrganizationHeader ? index + 2 : index + 1}.`,
        );
        return false;
      }
    }
    return true;
  };

  const buildDTOToSave = (data) => {
    let result = [];
    let error = false;
    data.forEach((item, index) => {
      const tender = result.find(
        (resultItem) =>
          resultItem.TenderNumber === item.tenderNumber &&
          resultItem.ClientUnit?.UniqueId === item.clientUnitUniqueId,
      );
      // If the tender number is in results, means that this is another item(tenderDetail) of the same tender
      if (tender) {
        let tenderDetail = tender.Details.find(
          (tenderDetailItem) =>
            tenderDetailItem.ItemNumber === item.tenderDetailItemNumber,
        );
        // If the tenderDetail is in the tender, means that this is another awarded of the same tenderDetail
        if (tenderDetail) {
          if (!verifyAwardedFields(item, index)) {
            error = true;
            return;
          }
          let awarded = fillTenderDetailAwarded(item);
          // if all value are null, means that there is no awarded
          if (!Object.values(awarded).every((value) => value === null)) {
            tenderDetail.Awardeds.push(awarded);
          }
        } else {
          // If the tenderDetail is not in the tender, means that this is a new tenderDetail
          if (!verifyAwardedFields(item, index)) {
            error = true;
            return;
          }
          let detail = fillTenderDetail(item);
          let manageDetail = fillManageDetail(item);
          let DetailToAdd = {
            ...detail,
            ...manageDetail,
          };
          tender.Details.push(DetailToAdd);
        }
      } else {
        // If the tender number is not in results, means that this is a new tender
        if (!verifyAwardedFields(item, index)) {
          error = true;
          return;
        }
        let detail = fillTenderDetail(item);
        let manageDetail = fillManageDetail(item);
        let DetailToAdd = {
          ...detail,
          ...manageDetail,
        };

        let request = {
          // Extension solo va el source name, osea: (nuevo campo, que completen ellos)
          Extension: {
            Info: JSON.stringify({ SourceName: item.sourceName }),
          },
          // Infos: [], // no se sube
          Details: [DetailToAdd],
          // Stages: [], //se crea en el BE
          // Questions: [], // no se sube
          Sellers: item.tenderManageSeller ? [getTenderManageSeller(item)] : [], // buscar por name, y sino ponerlo como nuevo
          Categories: item.tenderManageCategory
            ? [getTenderManageCategory(item)]
            : [], // buscar por name, y sino ponerlo como nuevo
          Tags: item.tenderManageTag ? [getTenderManageTag(item)] : [], // buscar por name, y sino ponerlo como nuevo
          TenderNumber: item.tenderNumber, // si hay mismo tender number, se agregan los details nomas
          FileNumber: item.fileNumber,
          TenderType: getTenderType(item, index), // mandan la string (se da en excel opciones) --> despues se mapea (BE)
          Status: getTenderStatus(item, index), // mandan la string (se da en excel opciones) --> despues se mapea (BE)
          PublishedDate: item.publishedDate,
          EndDate: item.endDate,
          AwardDate: item.awardDate,
          TenderName: item.tenderName,
          Link: item.link,
          TenderDescription: item.tenderDescription,
          Currency: item.currency,
          EstimatedValue: item.estimatedValue,
          BidTypeLine: null, // probablemente no se use
          ScrapedDate: new Date().toISOString(), //automatic -> fecha de hoy (poner aca)
          SourceId: getSourceId(), // externo, completado x nosotros (extAR, etc)
          ClientUnit: {
            // BE looks if the clientUnit exists, if not, creates it
            UniqueId: item.clientUnitUniqueId,
            Name: item.clientUnitName,
            // Client: null // Maybe we need to add this for Peru or other later
          },
          BidSecurity: item.bidSecurity,
          FaithfulObservance: item.faithfulObservance,
        };

        if (!request.TenderType) {
          error = true;
          return;
        }
        if (!request.Status) {
          error = true;
          return;
        }
        result.push(request);
      }
    });
    if (error) {
      return null;
    }
    result = nullifyUndefinedValues(result);

    return result;
  };

  /// Height handling
  const [height, setHeight] = useState(window.innerHeight);
  const handleResize = useCallback(() => {
    const padding = 0;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  ///

  return (
    <div style={{ height: "100%" }}>
      <Card
        style={{
          padding: "1rem",
          margin: "1rem",
          display: "flex",
          alignItems: "center",
          height: "4rem",
        }}
      >
        <Tooltip title="Volver">
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            size="large"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
          </Button>
        </Tooltip>

        <Typography
          variant="h6"
          style={{
            marginLeft: "1rem",
            fontWeight: "bold",
          }}
        >
          Agregar licitaciones externas
        </Typography>
      </Card>

      <Card
        style={{
          padding: "1rem",
          margin: "1rem",
          height: `calc(${height}px - 7rem)`,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.7rem",
            height: "2rem",
          }}
        >
          {manageMode !== manageModes.NONE && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setManageMode(manageModes.NONE)}
            >
              <ArrowBackIcon />
            </Button>
          )}

          <Typography
            variant="h6"
            style={{
              marginLeft: manageMode === manageModes.NONE ? "0" : "1rem",
              fontSize: 16,
            }}
          >
            {manageMode === manageModes.NONE
              ? "Seleccione el modo de ingreso de licitaciones"
              : manageMode === manageModes.MANUAL
              ? "Ingrese manualmente las licitaciones"
              : "Importe las licitaciones desde un archivo"}
          </Typography>
        </div>
        {manageMode === manageModes.NONE && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
              gap: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{
                width: "20%",
              }}
              onClick={() => setManageMode(manageModes.MANUAL)}
            >
              Ingresar manualmente
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{
                width: "20%",
              }}
              onClick={() => setManageMode(manageModes.FILE)}
            >
              Importar desde archivo
            </Button>
          </div>
        )}

        {manageMode === manageModes.MANUAL && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                gap: "1rem",
              }}
            >
              <Typography
                style={{
                  marginLeft: "0",
                  fontSize: 14,
                }}
              >
                Ingrese los datos de la licitación a añadir. Los campos marcados
                con * son obligatorios.
              </Typography>
            </div>
            <OutsiderTendersManualInsert
              height={height}
              tenderTypes={tenderTypes}
              tendersStatus={tendersStatus}
              sellers={sellers.map((s) => ({
                ...s,
                id: s.sellerId,
                name: `${s.firstName} ${s.lastName}`,
              }))}
              categories={categories.map((c) => ({
                ...c,
                id: c.categoryId,
                name: c.name,
              }))}
              tags={tags.map((t) => ({
                ...t,
                id: t.tagId,
                name: t.name,
              }))}
              buildDTOToSave={buildDTOToSave}
              showToasterError={showToasterError}
              showToasterSuccess={showToasterSuccess}
              getSourceId={getSourceId}
              nullifyUndefinedValues={nullifyUndefinedValues}
            />
          </>
        )}

        {manageMode === manageModes.FILE && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "1rem",
                gap: "1rem",
              }}
            >
              <Typography
                style={{
                  marginLeft: "0",
                  fontSize: 14,
                }}
              >
                Seleccione las columnas del archivo Excel y sus respectivas
                posiciones.
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // marginTop: "1rem",
                gap: "1rem",
                width: 900,
                justifyContent: "space-between",
              }}
            >
              <Card
                style={{
                  marginTop: "2%",
                  marginBottom: "2%",
                  width: 400,
                  padding: "4px",
                }}
              >
                <Grid container spacing={2} justify="center">
                  <Grid item xs style={{ alignSelf: "center" }}>
                    <Typography
                      style={{ fontSize: "medium", marginLeft: "4%" }}
                      variant="h6"
                    >
                      Contiene encabezado
                    </Typography>
                  </Grid>
                  <Grid item xs style={{ textAlign: "center" }}>
                    <Switch
                      style={{ whiteSpace: "nowrap", width: "70%" }}
                      color="primary"
                      checked={excelDataOrganizationHeader}
                      name={"hasHeader"}
                      onChange={handleChangeHeaderSwitch}
                      size="medium"
                    />
                  </Grid>
                </Grid>
              </Card>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setExcelDataOrganization([
                    ...columns.getAllColumns(),
                    ...excelDataAdd,
                  ]);
                }}
              >
                Agregar todas las columnas disponibles
              </Button> */}
            </div>
            <div className="row">
              <Grid
                container
                spacing={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  overflowX: "auto",
                  overflowY: "hidden",
                  alignItems: "stretch",
                }}
              >
                {excelDataOrganization.map((item, index) => (
                  <Grid
                    item
                    xs={4}
                    key={index}
                    style={{
                      flexBasis: "15%",
                      flexGrow: 0,
                      flexShrink: 0,
                      alignItems: "center",
                      alignContent: "center",
                    }}
                  >
                    <Card style={{ alignItems: "center", height: "100%" }}>
                      {item.modifiable ? (
                        <Grid
                          container
                          style={{
                            alignItems: "center",
                            paddingBottom: "4%",
                            paddingTop: "8%",
                            justifyContent: "center",
                          }}
                          spacing={2}
                        >
                          <Tooltip title={item.columnName}>
                            <TextField
                              id="outlined-basic"
                              label="Nombre"
                              variant="outlined"
                              name="columnValue"
                              value={item.columnValue}
                              onChange={(event) => handleChange(event, item)}
                              size="small"
                              style={{ width: "75%" }}
                              select
                            >
                              {columns.getExtraColumns().map((option) => (
                                <MenuItem
                                  key={option.columnValue}
                                  value={option.columnValue}
                                >
                                  {option.columnName}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Tooltip>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          style={{
                            alignItems: "center",
                            paddingBottom: "10%",
                            paddingTop: "10%",
                            justifyContent: "center",
                            width: "auto",
                            marginLeft: "1rem",
                            marginRight: "1rem",
                          }}
                          spacing={2}
                        >
                          <Typography
                            style={{ fontSize: "medium", textAlign: "center" }}
                            variant="h6"
                          >
                            {item.columnName}
                            {item.columnValue !== "addButtonColumnExcel" && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </Typography>
                        </Grid>
                      )}

                      {item.columnValue === "addButtonColumnExcel" ? (
                        <Grid
                          container
                          style={{
                            alignItems: "center",
                            paddingBottom: "9%",
                            justifyContent: "center",
                          }}
                          spacing={2}
                        >
                          <IconButton onClick={handleAddColumn}>
                            <AddCircleIcon />
                          </IconButton>
                        </Grid>
                      ) : (
                        <Grid
                          container
                          style={{
                            alignItems: "center",
                            paddingBottom: "10%",
                            paddingTop: "5%",
                            justifyContent: "center",
                          }}
                          spacing={2}
                        >
                          <Typography
                            style={{ fontSize: "small", paddingRight: "5%" }}
                          >
                            Columna:
                          </Typography>
                          <TextField
                            style={{ width: "40%" }}
                            fullWidth
                            value={item.columnPosition}
                            variant="outlined"
                            size="small"
                            name={item.columnValue}
                            onChange={(event) =>
                              handleChange(event, item, true)
                            }
                            select
                          >
                            {getExcelColumns().map((excelColumns) => (
                              <MenuItem
                                key={excelColumns.id}
                                value={excelColumns.id}
                              >
                                {excelColumns.name}
                              </MenuItem>
                            ))}
                          </TextField>
                          {item.modifiable && (
                            <IconButton
                              onClick={(event) =>
                                handleRemoveColumn(event, item)
                              }
                              // style={{ marginBottom: "-2%", marginTop: "2%" }}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          )}
                        </Grid>
                      )}
                    </Card>
                  </Grid>
                ))}
              </Grid>
              {duplicateExcelPositions.value && (
                <Typography
                  style={{
                    color: "red",
                    paddingTop: "2%",
                    textAlign: "center",
                  }}
                  variant="h6"
                >
                  {duplicateExcelPositions.message}
                </Typography>
              )}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "3rem",
                gap: "1rem",
              }}
            >
              <input
                accept=".xls,.xlsx"
                style={{ display: "none" }}
                id="upload-button-file"
                type="file"
                hidden
                onInput={(e) => {
                  handleFileUpload(e);
                }}
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  addFiles();
                }}
                disabled={file !== null}
              >
                Adjuntar Archivo
              </Button>
              {messageErrorFile && (
                <Grid item xs={12} sm={12} md={12}>
                  <Alert severity="error">{messageErrorFile}</Alert>
                </Grid>
              )}
              <List dense={false} style={{ marginTop: "-0.35rem" }}>
                {!file ? (
                  <ListItem>
                    <ListItemText primary="Ningún archivo seleccionado" />
                  </ListItem>
                ) : (
                  <>
                    <ListItem>
                      <ListItemText primary={file.FileName} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          size="small"
                          aria-label="delete"
                          onClick={deleteFile}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </>
                )}
              </List>
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                handleUpload();
              }}
              disabled={file === null}
              style={{ marginTop: "1rem" }}
            >
              Enviar archivo
            </Button>
          </>
        )}
      </Card>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          zIndex: 800,
        }}
        open={openToaster}
      >
        <Alert severity={openToasterSeverity}>{openToasterMessage}</Alert>
      </Snackbar>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { app, spinnerReducer } = state;
  const { tendersStatus } = app;
  return {
    ...spinnerReducer,
    tendersStatus,
    ...ownProps,
  };
};

export default connect(mapStateToProps, {
  ...SpinnerActions,
})(OutsiderTenders);
