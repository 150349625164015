import React, { Component } from "react";
import { axiosConfig } from "../config/axios";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setupUserData } from "../actions/userActions";
import { setupInitialData } from "../actions/appActions";
import Loading from "../views/Loading";

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
  };
};

class AuthConfig extends Component {
  async componentDidMount() {
    await axiosConfig(this.props.dispatch);
    this.props.dispatch(setupUserData());
    this.props.dispatch(setupInitialData());
  }

  render() {
    const { children } = this.props;
    return <Loading checkFinishedLoading={true}>{children}</Loading>;
  }
}

export default withRouter(connect(mapStateToProps)(AuthConfig));
