import {
  ContextMenu,
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  GridComponent,
  Inject,
  Sort,
  Toolbar,
  Edit,
  ExcelExport,
  Resize,
  Page,
  Freeze,
  Selection,
  Reorder,
  ColumnMenu,
} from "@syncfusion/ej2-react-grids";
import React, { useState, useEffect } from "react";
import {
  getOrderAscTableValue,
  translateCurrentPageForServerGridSyncFusion,
} from "../../utils/common";
import moment from "moment";
import { updateSettingUser } from "../../utils/activeDirectory";
import {
  useStoreData,
  useStoreDataClient,
} from "../../utils/hooks/useStoreData";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";

const keyName = {
  ARROW_UP_DONW: "ARROW_UP_DONW",
};

export const FstGrid = (props) => {
  let grid = null;

  const [queryParams, setQueryParams] = useState(null);
  const editOptions = props.data.EditOptions;
  const header = props.data.Header;
  const gridHeight = props.data.height ? props.data.height : "100%";
  const gridWidth = props.data.width ? props.data.width : "100%";
  const contextMenuOptions = props.data.contextMenuOptions;
  const actionBegin = props.data.actionBegin;
  const allowSorting = props.data.allowSorting;
  const allowEditing = editOptions ? editOptions.allowEditing : null;
  const allowPaging = props.data.allowPaging;
  const take = props.data.take ? props.data.take : 10;
  const settingSelection = props.data.selection
    ? props.data.selection.settingSelection
    : null;
  const contextMenuItems = props.data.actionsRows ? props.data.actionsRows : [];
  let debounceTimer = null;
  const idGrid = props.data.idGrid ? props.data.idGrid : "";
  const [keyCode, setKeyCode] = useState("");
  const query = useStoreData("SELECT_ROWS");
  const queryClient = useStoreDataClient("SELECT_ROWS");
  const styles = props.data.styles;
  const appInsights = useAppInsightsContext();

  const contextMenuClick = (args) => {
    if (grid) {
      props.data.contextMenuIFnc(args);
    }
  };

  const paginationGrid = (state) => {
    let orderBy = null;
    let orderDirection = null;
    let search = "";
    if (state && (state.sorted || []).length) {
      orderBy = state.sorted[0].name;
      orderDirection =
        state.sorted[0].direction === "ascending" ? "asc" : "desc";
    } else if (state && state.useStorageSort) {
      let store = JSON.parse(localStorage.getItem(`grid${idGrid}`));
      if (
        store &&
        store.sortSettings &&
        store.sortSettings.columns &&
        store.sortSettings.columns.length > 0
      ) {
        orderBy = store.sortSettings.columns[0].field;
        orderDirection =
          store.sortSettings.columns[0].direction === "Ascending"
            ? "asc"
            : "desc";
      }
    }

    if (state && (state.search || []).length) {
      search = state.search[0].key;
    }
    const PageNumber = translateCurrentPageForServerGridSyncFusion(
      state.skip,
      state.take,
    );
    const OrderAsc = getOrderAscTableValue(orderDirection);
    const request = {
      orderBy: orderBy ? orderBy : orderBy,
      orderDirection,
      search,
      pageSize: take,
      PageNumber,
      OrderAsc,
    };
    setQueryParams(request);
    return request;
  };

  const toolbarOptionsFunc = () => {
    const toolBar = [];
    if (!props.data.ToolBar && !props.data.ToolBarUpdate) return;

    if (props.data.ToolBarUpdate) {
      return props.data.ToolBarUpdate;
    }

    if (props.data.ToolBar.Add) {
      toolBar.push({
        text: props.data.ToolBar.AddTitle,
        tooltipText: props.data.ToolBar.AddTitle,
        prefixIcon: "e-add",
        id: "add",
      });
    }
    if (props.data.ToolBar.Edit) {
      toolBar.push({
        text: props.data.ToolBar.EditTitle,
        tooltipText: props.data.ToolBar.EditTitle,
        prefixIcon: "e-edit",
        id: "edit",
      });
    }
    if (props.data.ToolBar.Delete) {
      toolBar.push({
        text: props.data.ToolBar.DeleteTitle,
        tooltipText: props.data.ToolBar.DeleteTitle,
        prefixIcon: "e-delete",
        id: "delete",
      });
    }
    if (props.data.ToolBar.Export) {
      toolBar.push({
        text: "Exportar",
        tooltipText: "Exportar a Excel",
        prefixIcon: "e-excelexport",
        id: "excelexport",
      });
    }

    if (props.data.ToolBar.UpdateGrid) {
      toolBar.push({
        text: props.data.ToolBar.UpdateGridTitle,
        tooltipText: "Update",
        id: "updateGrid",
      });
    }
    if (props.data.ToolBar.Search) {
      toolBar.push("Search");
    }
    if (props.data.ToolBar.Email) {
      toolBar.push({
        text: props.data.ToolBar.EmailTitle,
        tooltipText: props.data.ToolBar.EmailTitle,
        prefixIcon: "e-icons e-send-1",
        id: "email",
      });
    }
    if (props.data.ToolBar.ExportClick) {
      toolBar.push({
        text: "Exportar",
        tooltipText: "Exportar a Excel",
        prefixIcon: "e-export-excel",
        id: "exportClick",
      });
    }
    if (props.data.ToolBar.Refresh) {
      toolBar.push({
        text: "Actualizar",
        tooltipText: "Actualizar",
        prefixIcon: "e-refresh",
        id: "Refresh",
      });
    }
    if (props.data.ToolBar.AdvancedOptions) {
      toolBar.push({
        text: "Opciones Avanzadas",
        tooltipText: "Opciones Avanzadas",
        prefixIcon: "e-edit-3",
        id: "AdvancedOptions",
      });
    }
    if (props.data.ToolBar.EditView) {
      toolBar.push({
        text: "Vista",
        tooltipText: "Seleccionar Vista",
        prefixIcon: "e-edit-view",
        id: "EditView",
      });
    }

    return toolBar;
  };

  const rowSelection = (args) => {
    if (grid && props.data.selection) {
      const selectedrecords = grid.getSelectedRecords();
      props.data.selection.selectionRows(selectedrecords);
      if (selectedrecords.length > 0) {
        verifyDuplicate(selectedrecords);
      }
    }

    if (grid && keyCode === keyName.ARROW_UP_DONW && props.data.viewedArrow) {
      props.data.viewedArrow(args);
      setKeyCode("");
    }

    if (props.data.extraSelectionFunc) {
      props.data.extraSelectionFunc(args, grid);
    }
  };

  const verifyDuplicate = async (select) => {
    const data = queryClient.getDataQuery();
    const filter = select.filter((item) => {
      const stringData = JSON.stringify(data.data);
      const itemData = JSON.stringify(item);
      if (stringData.indexOf(itemData) === -1) {
        return item;
      }
    });

    if (filter.length > 0) {
      queryClient.updateDataQuery(...filter);
    }
  };

  const initialSelect = () => {
    if (!props.data.selection) return;
    const list = [];
    for (let i = 0; i < grid.dataSource.result.length; i++) {
      if (grid.dataSource.result[i][props.data.selection.selected]) {
        list.push(i);
      }
    }
    grid.selectRows(list);
  };

  const dataBound = () => {
    if (grid) {
      const fields = header
        .filter((item) => item.autoFitColumns)
        .map((item) => item.field);

      if (fields.length > 0) grid.autoFitColumns(fields);

      if (
        grid.element.querySelector(
          "#" + grid.element.getAttribute("id") + "_searchbar",
        ) !== null
      ) {
        //solo cuando hay search bar configurado
        grid.element
          .querySelector("#" + grid.element.getAttribute("id") + "_searchbar")
          .addEventListener("keyup", function (e) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
              search(e.target.value, e.target.id);
            }, 1000);
          });
      }

      initialSelect();
    }
  };

  const search = (value) => {
    if (grid) {
      if (grid.pageSettings) grid.pageSettings.currentPage = 1; //En las busquedas siempre debe empezar

      searchActive(grid, value);
      grid.element.ej2_instances[0].search(value);
      clearTimeout(debounceTimer);
    }
  };

  const searchActive = (grid, value) => {
    const element = document.getElementById(grid.element.getAttribute("id"));
    element.classList.remove("styleSearchActive");
    if (value !== "") {
      element.classList.add("styleSearchActive");
    }
  };

  let toolbarOptions = toolbarOptionsFunc();

  const exportExcel = async () => {
    const aux = { ...queryParams };
    aux.pageSize = props.result.count;
    aux.PageNumber = 1;
    const data = await props.data.exportFunc(aux, props.data.Filter);
    const excelExportProperties = {
      dataSource: data,
    };
    if (props.data.ExportFileName) {
      excelExportProperties.fileName = props.data.ExportFileName;
    }

    grid.excelExport(excelExportProperties);
  };

  const hideColumnsToExport = (value) => {
    const columns = header.filter((item) => item.hideToExport);
    for (let element of columns) {
      const indexOfColumn = header.findIndex(
        (i) => i.headerText === element.headerText,
      );
      grid.columns[indexOfColumn].visible = value;
    }
  };

  const trackRefreshGrid = useTrackEvent(
    appInsights,
    "FSTGrid: Refresh Grid",
    grid,
  );
  const trackExportGrid = useTrackEvent(
    appInsights,
    "FSTGrid: Export Grid",
    grid,
  );
  const trackExcelExportGrid = useTrackEvent(
    appInsights,
    "FSTGrid: Excel Export Grid",
    grid,
  );

  const toolbarClick = (args) => {
    if (grid && args.item.prefixIcon === "e-excelexport") {
      hideColumnsToExport(false);
      trackExcelExportGrid({ params: queryParams });
      exportExcel(queryParams);
    } else if (args.item.id === "add") {
      if (props.data.Add) {
        props.data.Add();
      }
      if (props.data.AddDefault) {
        grid.addRecord();
      }
    } else if (args.item.id === "delete") {
      const selectedrecords = grid.getSelectedRecords()[0];
      props.data.Delete(selectedrecords);
    } else if (args.item.id === "edit") {
      const selectedrecords = grid.getSelectedRecords()[0];
      props.data.Edit(selectedrecords);
    } else if (args.item.id === "email") {
      const selectedrecords = grid.getSelectedRecords()[0];
      props.data.Email(selectedrecords);
    } else if (args.item.id === "exportClick") {
      const aux = { ...queryParams };
      aux.pageSize = props.result.count;
      aux.PageNumber = 1;
      trackExportGrid({ params: aux });
      props.data.ExportClick(aux);
    } else if (args.item.id === "Refresh") {
      if (!queryParams) return;
      let params = {
        skip:
          queryParams.pageSize * queryParams.PageNumber - queryParams.pageSize,
        take: queryParams.pageSize,
      };
      if (queryParams.orderBy && queryParams.orderDirection) {
        params.sorted = [
          {
            name: queryParams.orderBy,
            direction:
              queryParams.orderDirection === "asc" ? "ascending" : "descending",
          },
        ];
      }
      if (queryParams.search) {
        params.search = [{ key: queryParams.search }];
      }
      setTimeout(() => {
        trackRefreshGrid({ params: params });
        getData(params);
      }, 750);
    } else if (args.item.id === "AdvancedOptions") {
      props.data.AdvancedOptions();
    } else if (args.item.id === "EditView") {
      props.data.EditView();
    }
  };

  const getData = (state) => {
    const pagination = paginationGrid(state);
    props.data.Getdata(pagination, props.data.Filter);
    if (grid && props.reloadDefault) {
      //grid.pageSettings.currentPage = 1;
    }
  };

  const setupInitialData = () => {
    grid.pageSettings.currentPage = 1;
    updateGridStore();
    if (props.filter) {
      grid.searchSettings.key = props.filter.pagination
        ? props.filter.pagination.search
        : "";
      props.data.Getdata(props.filter.pagination, props.filter.filter);
    } else {
      const state = { skip: 0, take: take, useStorageSort: true };
      grid.searchSettings.key = "";
      getData(state);
    }
  };

  const rowDataBound = (arg) => {
    if (typeof props.rowDataBound === "function") {
      props.rowDataBound(arg);
    }
  };

  useEffect(() => {
    if (!props.result || props.reloadDefault) {
      setupInitialData();
    }
    return () => {
      updateSettingUser();
    };
  }, [props.uniqValue]);

  const dataSourceChanged = (state) => {
    if (
      state.action === "edit" ||
      (props.data.AddDefault && state.action === "add")
    ) {
      props.data.Updated(state.data);
      state.endEdit();
    }
  };

  const recordDoubleClick = (item) => {
    if (props.data.rowDoubleClickAction) {
      props.data.rowDoubleClickAction(item.rowData);
    }
  };

  const commandClick = (args) => {
    if (grid) {
      props.data.ActionCustomRow(args.commandColumn, args.rowData);
    }
  };

  const excelExportComplete = () => {
    if (grid) {
      hideColumnsToExport(true);
    }
  };

  const excelQueryCellInfo = (args) => {
    if (props.ColumnsDate) {
      const result = props.ColumnsDate.filter(
        (item) => item.field === args.column.field,
      );
      if (result.length > 0) {
        var formattedDate = args.value
          ? moment(args.value).format(result[0].format)
          : "";
        args.value = formattedDate;
      }
    }

    if (props.ColumnsBooleans) {
      const bool = props.ColumnsBooleans.filter(
        (item) => item.field === args.column.field,
      );
      if (bool.length > 0) {
        args.value = args.value ? bool[0].POSITIVE : bool[0].NEGATIVE;
      }
    }

    if (props.ColumnsLengths) {
      const length = props.ColumnsLengths.filter(
        (item) => item.field === args.column.field,
      );
      if (length.length > 0) {
        args.value = args.value.length;
      }
    }

    if (props.ColumnsJoinWithComma) {
      const searchFilterType = props.ColumnsJoinWithComma.filter(
        (item) => item.field === args.column.field,
      );
      if (searchFilterType.length > 0) {
        if (args.value.length === 0) {
          args.value = props.ColumnsJoinWithComma[0].defaultValue;
        } else {
          const rest = args.value
            .map((item) => {
              return item[props.ColumnsJoinWithComma[0].key];
            })
            .join(", ");
          args.value = rest;
        }
      }
    }

    if (args.column.customExcelTemplate) {
      args.value = args.column.customExcelTemplate(args.data);
    }
  };

  const updateGridStore = () => {
    const idStore = "grid" + idGrid;
    let store = JSON.parse(localStorage.getItem(idStore));
    if (store !== null) {
      store.columns = store.columns.filter((existingItem) =>
        header.find((item) => item.field === existingItem.field),
      );

      // Iterate every header column and check if any of the values are different
      header.forEach((item, index) => {
        let storeIdx = store.columns.findIndex((i) => i.field === item.field);
        if (storeIdx !== -1) {
          Object.keys(item).forEach((key) => {
            if (
              !store.columns[storeIdx].hasOwnProperty(key) ||
              item[key] !== store.columns[storeIdx][key]
            ) {
              store.columns[storeIdx][key] = item[key];
            }
          });

          store.columns[storeIdx]["showInColumnChooser"] = item.hasOwnProperty(
            "showInColumnChooser",
          )
            ? item.showInColumnChooser
            : true;
          store.columns[storeIdx]["width"] = store.columns[
            storeIdx
          ].hasOwnProperty("width")
            ? store.columns[storeIdx].width
            : item.width;
          store.columns[storeIdx]["allowSorting"] = item.hasOwnProperty(
            "allowSorting",
          )
            ? item.allowSorting
            : true;

          let keysToRemoveIfNotInHeader = [
            "isPrimaryKey",
            "allowEditing",
            "autoFitColumns",
            "commands",
          ];
          keysToRemoveIfNotInHeader.forEach((keyToRemove) => {
            if (
              store.columns[storeIdx].hasOwnProperty(keyToRemove) &&
              !item.hasOwnProperty(keyToRemove)
            ) {
              delete store.columns[storeIdx][keyToRemove];
            }
          });
          store.columns[storeIdx]["index"] = index;
          store.columns[storeIdx]["uid"] = "grid-column" + index;
        } else {
          let itemsToPreventAdding = [
            "template",
            "editType",
            "editParams",
            "isPrimaryKey",
            "editTemplate",
            "type",
            "colExcel",
            "customExcelTemplate",
            "validationRules",
            "commands",
            "displayAsCheckBox",
          ];
          let newItem = {
            ...item,
            foreignKeyField: item.field,
            uid: `grid-column${index}`,
            index: index,
          };
          Object.keys(store.columns[0]).forEach((key) => {
            if (
              !newItem.hasOwnProperty(key) &&
              !itemsToPreventAdding.includes(key)
            ) {
              newItem[key] = store.columns[0][key];
            }
          });
          store.columns.splice(index, 0, newItem);
        }
      });
    }
    if (store !== null) {
      store.searchSettings.key = "";
      store.pageSettings.currentPage = 1;
      localStorage.setItem(idStore, JSON.stringify(store));
    }
  };

  const showItemMenu = (event) => {
    return event !== undefined ? event : true;
  };

  const recordClick = (arg) => {
    if (props.data.rowClickAction) {
      props.data.rowClickAction(arg);
    }
  };

  const keyPressed = (event) => {
    if (event.keyCode === 40 || event.keyCode === 38) {
      setKeyCode(keyName.ARROW_UP_DONW);
    } else {
      setKeyCode("");
    }
  };

  return (
    <>
      <GridComponent
        id={idGrid}
        locale="es-ES"
        height={gridHeight} //'100%'
        width={gridWidth}
        dataSource={props.result}
        ref={(g) => {
          if (!g) return;
          grid = g;
        }}
        dataStateChange={getData}
        allowReordering={true}
        allowResizing={true}
        editSettings={editOptions}
        allowSorting={allowSorting}
        allowPaging={allowPaging}
        pageSettings={{ pageSize: take }}
        allowEditing={allowEditing}
        allowSelection={true}
        toolbar={toolbarOptions}
        allowExcelExport={true}
        recordDoubleClick={recordDoubleClick}
        toolbarClick={toolbarClick}
        contextMenuItems={contextMenuOptions}
        rowSelected={rowSelection}
        rowDeselected={rowSelection}
        dataBound={dataBound}
        selectionSettings={settingSelection}
        dataSourceChanged={dataSourceChanged}
        commandClick={commandClick}
        excelExportComplete={excelExportComplete}
        excelQueryCellInfo={excelQueryCellInfo}
        contextMenuClick={contextMenuClick}
        contextMenuItems={contextMenuItems}
        rowDataBound={rowDataBound}
        enablePersistence={true}
        showColumnMenu={true}
        recordClick={recordClick}
        keyPressed={keyPressed}
        style={styles}
        actionBegin={actionBegin}
      >
        <ColumnsDirective>
          {header.map((row, index) => (
            <ColumnDirective
              key={index}
              field={row.field}
              type={row.type}
              headerText={row.headerText}
              width={row.width}
              textAlign={row.textAlign}
              format={row.format}
              visible={row.visible}
              allowEditing={row.allowEditing}
              allowSorting={row.allowSorting}
              editType={row.editType}
              displayAsCheckBox={row.displayAsCheckBox}
              edit={row.editParams}
              template={row.template}
              valueAccessor={row.valueAccessor}
              clipMode={row.clipMode ? row.clipMode : "EllipsisWithTooltip"} //por default, tooltip para todos los campos
              commands={row.commands}
              showInColumnChooser={showItemMenu(row.showInColumnChooser)}
              customExcelTemplate={row.customExcelTemplate}
              editTemplate={row.editTemplate}
              defaultValue={row.defaultValue}
            />
          ))}
        </ColumnsDirective>
        <Inject
          services={[
            Sort,
            CommandColumn,
            Toolbar,
            Edit,
            ExcelExport,
            Resize,
            Page,
            Freeze,
            ContextMenu,
            Selection,
            Reorder,
            ColumnMenu,
          ]}
        />
      </GridComponent>
    </>
  );
};
