import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ColumnsBooleans } from "views/penalties/PenaltiesHeaderTable";

class AuthRoute extends Component {
  async componentDidMount() {
    const isRedirect = await this.getAuthRoute(
      this.props.verify,
      this.props.location.pathname
    );
    if (!isRedirect) {
      this.props.history.push("/");
    }
  }

  getAuthRoute(props, pathName) {
    if (pathName === "/") return true;

    if (this.props.children.some((item) => item.props.path === pathName)) {
      const itemRoute = this.props.children.find(
        (item) => item.props.path === pathName
      );
      return this.verifyAuth(props, itemRoute);
    }

    const arrayPath = pathName.split("/");
    const newPath = "/" + arrayPath[1] + "/:";
    if (
      this.props.children.some(
        (item) => item.props.path.indexOf(newPath) !== -1
      )
    ) {
      const itemRoute = this.props.children.find(
        (item) => item.props.path.indexOf(newPath) !== -1
      );
      return this.verifyAuth(props, itemRoute);
    }

    return true;
  }

  verifyAuth(props, itemRoute) {
    const { isAdmin, isAdminGeneral, applicationModules, userModules } = props;

    //Verify is admin general
    if (itemRoute.props.isAdminGeneral) {
      return isAdminGeneral;
    }

    //Verify is admin
    if (itemRoute.props.isAdmin) {
      return isAdmin;
    }

    //Verify is Modules
    if (itemRoute.props.idModule) {
      const modules = isAdmin ? applicationModules : userModules;
      return modules.some((item) => item.name === itemRoute.props.idModule);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

const mapStateToProps = (state, ownProps) => {
  const { app } = state;
  const { user } = state;
  const { loginPending, roleId } = user;
  const userModules = user.applicationModules;
  const { applicationModules } = app;

  return {
    ...ownProps,
    loginPending,
    verify: {
      userModules,
      applicationModules,
      isAdmin: roleId === 1 || roleId === 99,
      isAdminGeneral: roleId === 99,
    },
  };
};

export default withRouter(connect(mapStateToProps)(AuthRoute));
