import {
  Card,
  CardContent,
  Divider,
  IconButton,
  Snackbar,
  Tooltip,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import DeleteIcon from "@material-ui/icons/Delete";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import FstModalConfirm from "components/modal/FstModalConfirm";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import DownloadExcel from "../../views/manage-pac/utils/downloadExcel";
import EntityAutocompletePaging from "../../views/tender-analisys/params/EntityAutocompletePaging";
import * as SpinnerActions from "../../actions/spinnerActions";
import AdminAccess from "../../components/AdminAccess";
import useDataFile from "../../utils/hooks/useDataFile";
import ImportFileIcon from "../../utils/custom-icons/ImportFile";
import ABMGrid from "./ABMGrid";
import ABMHeaderActions from "./ABMHeaderActions";
import {
  getOrganisms,
  getOrganismsByUniqueId,
  getOrganismsIdsByCompany,
  putOrganisms,
} from "./services/abmServices";
import { useStyles } from "./style";
import { Alert } from "@material-ui/lab";
import { TenderParameter } from "views/tender-information/services/tenderParameter";

function ABMOrganismsOC(props) {
  const classes = useStyles();
  const [uniqueValue, setUniqueValue] = useState(moment().format());
  const [isDataModified, setIsDataModified] = useState(false);

  const [paginatedData, setPaginatedData] = useState([]); // Paginated data to show in table
  const [filteredDataLength, setFilteredDataLength] = useState(0);
  const [currentPageRequest, setCurrentPageRequest] = useState(null);

  const [newAddedSelected, setNewAddedSelected] = useState([]);
  const [selectedOrganismsIds, setSelectedOrganismsIds] = useState([]);
  const [initialSelectedOrganismsIds, setInitialSelectedOrganismsIds] =
    useState([]);
  const [isInit, setIsInit] = useState(true);
  const dataFile = useDataFile();
  const [isDownload, setIsDownload] = useState(false);
  let timerMessageError = null;
  const [messageErrorFile, setMessageErrorFile] = useState(null);

  /// Height handling
  const [height, setHeight] = useState();
  const handleResize = useCallback(() => {
    const padding = 130;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  ///

  const handleSave = () => {
    props.SpinnerShow("Guardando...");
    setTimeout(() => {
      putOrganisms(selectedOrganismsIds); // Save data to backend
      setIsDataModified(false);
      props.SpinnerHide();
    }, 750);
  };

  const handleDelete = async (item) => {
    props.SpinnerShow("Guardando...");
    updateAllData(
      paginatedData.filter((x) => +x.id !== +item.id),
      selectedOrganismsIds.filter((x) => +x !== +item.id),
      newAddedSelected.filter((x) => +x.id !== +item.id),
    );
  };

  const handleDeleteAllSelected = () => {
    props.SpinnerShow("Cargando...");
    setTimeout(() => {
      setSelectedOrganismsIds([]);
      setNewAddedSelected([]);
      setIsDataModified(true);
      getOrganismsData({ useCurrent: true }, null, []);
      props.SpinnerHide();
    }, 500);
  };

  const reloadPage = () => {
    props.SpinnerShow("Cargando...");
    setTimeout(() => {
      setSelectedOrganismsIds(initialSelectedOrganismsIds);
      setNewAddedSelected([]);
      setIsDataModified(false);
      getOrganismsData({ useCurrent: true }, null);
      props.SpinnerHide();
    }, 350);
  };

  useEffect(() => {
    setFilteredDataLength(selectedOrganismsIds.length);
  }, [selectedOrganismsIds]);

  /////////////////////////////////////////////////////////////////////////
  const header = [
    {
      field: "uniqueId",
      headerText: "RUC",
      textAlign: "left",
      width: "15%",
      allowSorting: false,
    },
    {
      field: "name",
      headerText: "Nombre",
      textAlign: "left",
      width: "45%",
      allowSorting: true,
      showInColumnChooser: false,
    },
    {
      field: "department",
      headerText: "Departamento",
      textAlign: "left",
      width: "20%",
      allowSorting: false,
    },
    {
      field: "state",
      headerText: "Provincia",
      textAlign: "left",
      width: "20%",
      allowSorting: false,
    },
    {
      field: "district",
      headerText: "Distrito",
      textAlign: "left",
      width: "20%",
      allowSorting: false,
    },
    {
      headerText: "Acciones",
      width: "120px",
      commands: [
        {
          type: "Remove",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
      ],
    },
  ];
  /////////////////////////////////////////////////////////////////////////

  const setData = async (request, entities) => {
    if (entities) {
      setPaginatedData(entities);
      return;
    }
    let fetchedData = await getOrganisms(request);
    let parsedData = fetchedData.data.currentPageItems.map((x) => {
      return {
        id: +x.clientUnitId,
        name: x.name,
        uniqueId: x.uniqueId,
        state: x.state,
        district: x.district,
        department: x.department,
      };
    });
    setPaginatedData(parsedData);
  };

  const getOrganismsData = async (pag, filter, specificSelectedOrganisms) => {
    props.SpinnerShow("Cargando...");
    if (pag.useCurrent) pag = currentPageRequest;
    if (isInit) {
      // fetch all entities for autocomplete
      var dataByEntity = await getOrganismsIdsByCompany();
      setInitialSelectedOrganismsIds(
        JSON.parse(JSON.stringify(dataByEntity.data)),
      );
      setSelectedOrganismsIds(dataByEntity.data);
      //
      setIsInit(false);
    }

    setCurrentPageRequest(pag);
    await setData(
      { ...pag, filter: pag.search, isSelected: true, PageNumber: 1 },
      specificSelectedOrganisms ? specificSelectedOrganisms : null,
    );
    props.SpinnerHide();
    return;
  };

  const btnTypes = { REMOVE: "Remove" };
  const remove = (btn, item) => {
    if (btn.type === btnTypes.REMOVE) handleDelete(item);
  };
  const actionCustomRow = (btn, item) => {
    if (!item) return;
    remove(btn, item);
  };

  const dataGrid = {
    Getdata: getOrganismsData,
    Header: header,
    allowSorting: true,
    idGrid: "gridEntites",
    ToolBar: { Search: true },
    height: height - 220,
    ActionCustomRow: actionCustomRow,
    take: 999999,
  };

  const updateAllData = (
    newPaginatedData,
    newSelectedOrganismsIds,
    newNewAddedSelected,
  ) => {
    setPaginatedData(newPaginatedData);
    setSelectedOrganismsIds(newSelectedOrganismsIds);
    setNewAddedSelected(newNewAddedSelected);
    setIsDataModified(true);
    props.SpinnerHide();
  };

  const handleParentEntity = async (e) => {
    props.SpinnerShow("Cargando...");
    let organismToAdd = await getOrganismsByUniqueId([e.uniqueId]);
    if (
      organismToAdd.data.clientUnits.length === 0 ||
      organismToAdd.data.notFound.length > 0
    ) {
      props.SpinnerHide();
      return;
    }
    organismToAdd = organismToAdd.data.clientUnits[0];
    let newItem = {
      id: +organismToAdd.clientUnitId,
      name: organismToAdd.name.replaceAll('"', ""),
      uniqueId: organismToAdd.uniqueId,
      state: organismToAdd.state,
      district: organismToAdd.district,
      department: organismToAdd.department,
    };
    let newPaginatedData = [...paginatedData, newItem];
    updateAllData(
      newPaginatedData,
      selectedOrganismsIds.concat(newItem.id),
      newAddedSelected.concat(newItem),
    );
  };

  const disabledOption = (option) => {
    return selectedOrganismsIds.includes(+option.clientUnitId);
  };

  const validateFile = (file, data) => {
    if (file && file.size > TenderParameter.MaxFileSize10MB) {
      const message = `El archivo ${
        file ? file.name : ""
      } supera el tamaño maximo permitido de 10 MB!`;
      setMessageErrorFile(message);
      timerMessageError = setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    } else if (!data) {
      const message = `El archivo ${
        file ? file.name : ""
      } es de tipo inválido o no tiene datos!`;
      setMessageErrorFile(message);
      timerMessageError = setTimeout(() => {
        setMessageErrorFile(null);
      }, 10000);
      return false;
    }
    setMessageErrorFile(null);

    return true;
  };

  const [notFoundUniqueId, setNotFoundUniqueId] = useState(false);
  const [notFoundUniqueIdList, setNotFoundUniqueIdList] = useState([]);

  const importFile = async (event) => {
    props.SpinnerShow("Cargando...");
    const data = await dataFile.getDataFile(event);
    if (!validateFile(event.target?.files[0], data)) {
      props.SpinnerHide();
      return;
    }
    let organismToAdd = await getOrganismsByUniqueId(data);

    let filter = [];
    organismToAdd.data.clientUnits.map((items) => {
      const newItem = {
        id: +items.clientUnitId,
        name: items.name.replaceAll('"', ""),
        uniqueId: items.uniqueId,
        state: items.state,
        district: items.district,
        department: items.department,
      };
      filter.push(newItem);
    });
    let newPaginatedData = [...paginatedData, ...filter];

    if (organismToAdd.data.notFound.length > 0) {
      setNotFoundUniqueIdList(organismToAdd.data.notFound);
      setNotFoundUniqueId(true);
    }
    updateAllData(
      newPaginatedData,
      selectedOrganismsIds.concat(...filter.map((x) => x.id)),
      newAddedSelected.concat(...filter),
    );
  };

  const addFiles = () => {
    document.getElementById("import-file-xlsx").click();
  };

  const exportData = async () => {
    setIsDownload(false);
    return paginatedData.slice();
  };

  ///////////////////////////////////////////////////////////////////////////

  const loadOptionsOrganisms = async (searchQuery, loadedOptions, { page }) => {
    const request = {
      idOrder: 0,
      pageNumber: page,
      pageSize: 100,
      filter: searchQuery,
      orderAsc: true,
      orderBy: "",
      isSelected: false,
    };

    const response = await getOrganisms(request);
    const responseJSON = await response.data.currentPageItems;

    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: response.data.currentPage,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
      },
    };
  };

  ////////////////////////////////////////////////////////////////////////

  return (
    <AdminAccess>
      <div className={classes.content}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card>
              <ABMHeaderActions
                headerTitle="Administrar Entidades"
                isDataModified={isDataModified}
                handleSave={handleSave}
                reloadPage={reloadPage}
              />
              <Divider />
              <CardContent>
                <EntityAutocompletePaging
                  disabledOption={disabledOption}
                  handleParentEntity={handleParentEntity}
                  loadOptions={loadOptionsOrganisms}
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={{ display: "flex", marginBottom: "-35px" }}
                >
                  <div
                    style={{
                      marginTop: "10px",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <div style={{ float: "left" }}>
                      <h3>Entidades seleccionadas</h3>
                    </div>
                    <div style={{ float: "right" }}>
                      <Tooltip
                        id="download-excel-button"
                        title="Descargar Excel"
                      >
                        <IconButton
                          className={classes.buttonExcel}
                          onClick={() => setIsDownload(true)}
                          disabled={paginatedData.length === 0}
                        >
                          <InsertDriveFileIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        id="import-button"
                        title="Importar desde archivo"
                      >
                        <IconButton color="primary">
                          <ImportFileIcon
                            handleIconClick={() => {
                              addFiles();
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        id="remove-button"
                        title="Eliminar todos los seleccionados"
                      >
                        <IconButton
                          color="secondary"
                          onClick={handleDeleteAllSelected}
                          disabled={paginatedData.length === 0}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </div>
                </Grid>
              </CardContent>
              <CardContent>
                <ABMGrid
                  dataGrid={dataGrid}
                  uniqueValue={uniqueValue}
                  paginatedData={paginatedData}
                  dataLength={filteredDataLength}
                />
              </CardContent>
            </Card>
            <FstModalConfirm
              title="RUCs no encontrados"
              open={notFoundUniqueId}
              message={`Algunos RUCs no pudieron ser encontrados: ${notFoundUniqueIdList.join(
                ", ",
              )}`}
              success={() => {
                setNotFoundUniqueId(false);
                setNotFoundUniqueIdList([]);
              }}
              onlyOK={true}
              setting={{ BtnNameSave: "Continuar" }}
            />
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              open={messageErrorFile}
            >
              <Alert severity="error">{messageErrorFile}</Alert>
            </Snackbar>
          </Grid>
        </Grid>
        <input
          accept=".xlsx,.xls,.txt"
          style={{ display: "none" }}
          id="import-file-xlsx"
          type="file"
          hidden
          onInput={(e) => {
            importFile(e);
          }}
          onClick={(event) => {
            event.target.value = null;
          }}
        />
        <DownloadExcel
          isDonwload={isDownload}
          fileName={"Entidades Seleccionadas PAC"}
          getData={exportData}
          columns={header.filter((x) => x.headerText !== "Acciones")}
          paging={{ ...currentPageRequest, totalRows: filteredDataLength }}
          nameSheet={"Entidades"}
        />
      </div>
    </AdminAccess>
  );
}
const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  withRouter(ABMOrganismsOC),
);
