import { CardActions, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./style";

/* Props: 
      - headerTitle: str
*/
function AdminHeader(props) {
  const classes = useStyles();

  return (
    <>
      <CardActions className={classes.rootHeader}>
        <Typography
          gutterBottom
          variant="h6"
          component="h6"
          className={classes.headerTypography}
        >
          {props.headerTitle}
        </Typography>
      </CardActions>
    </>
  );
}

export default AdminHeader;
