import React, { useEffect, useState } from "react";
import FstModal from "../../components/modal/FstModal";
import SendTenderByEmailForm from "./SendTenderByEmailForm";
import { SendtenderMail } from "../tender-information/services/sendTenderMailService";
import { listCompanySettings } from "views/company-setting/services/CompanySettingService";
import moment from "moment";
import { dateTimeFormat } from "utils/common";

const isISODate = (date) => {
  return moment(date, moment.ISO_8601, true).isValid();
};
const formatISODate = (date) => {
  return moment(date, moment.ISO_8601, true).format(dateTimeFormat);
};

const SendTenderByEmailMaster = (props) => {
  const messageSendTender = "Enviando Licitación...";
  const [isDisabled, setIsDisabled] = useState(true);
  const [sendEmail, setSendEmail] = useState({
    Subject: "",
    NotificationRecipients: "",
    Message: "",
    Files: [],
    Sellers: [],
    active: true,
  });

  const [defaultSubject, setDefaultSubject] = useState("");
  const loadDefaultSubject = async () => {
    const response = await listCompanySettings();
    if (!response.data.subject) {
      setDefaultSubject("");
      return;
    }
    let subject = response.data.subject.split(",");
    subject = subject.map((item) => {
      if (isISODate(props.tender[item])) {
        return formatISODate(props.tender[item]);
      }

      return props.tender[item];
    });
    subject = subject.join(" | ");
    subject += "] ";
    let finalSubject = "[" + subject;
    setDefaultSubject(finalSubject);
  };
  useEffect(() => {
    if (props.open) loadDefaultSubject();
  }, [props.open]);

  const handleSave = async () => {
    props.SpinnerShow(messageSendTender);
    setIsDisabled(false);
    const request = {
      Subject: `${defaultSubject}${sendEmail.Subject}`,
      Title: "",
      Message: setMessageEmail(),
      NotificationRecipients: sendEmail.NotificationRecipients,
      SellerIds: sendEmail.Sellers.map((item) => item.sellerId),
      ChannelId: 1,
      NotificationTypeId: 1,
      TenderNumber: props.tender.tenderNumber,
      TenderIds: [props.tender.tenderId],
      Files: sendEmail.Files,
    };

    if (props.tender.onDemandNotificationId) {
      request.onDemandNotificationId = props.tender.onDemandNotificationId;
    }

    await SendtenderMail(request);
    setSendEmail({
      Subject: "",
      NotificationRecipients: "",
      Message: "",
      Files: [],
      Sellers: [],
      active: true,
    });
    props.SpinnerHide();
    props.handleClose(true);
  };

  const setMessageEmail = () => {
    if (props.SendEmail) {
      if (props.SendEmail.hasOwnProperty("emailAdjunto")) {
        return (
          sendEmail.Message +
          '<hr style="margin: 15px 0px 15px 0px;">\n' +
          props.SendEmail.emailAdjunto
        );
      }
    }

    return sendEmail.Message;
  };

  const handleClose = () => {
    props.SpinnerHide();
    setSendEmail({
      Subject: "",
      NotificationRecipients: "",
      Message: "",
      Files: [],
      Sellers: [],
      active: true,
    });
    props.handleClose(false);
  };

  const onChange = (key, value) => {
    if (key === "Files") {
      const filesList = sendEmail.Files;
      filesList.push(value);
      value = filesList;
    }

    setSendEmail({
      ...sendEmail,
      [key]: value,
      active: false,
    });
  };

  const deleteFile = (index) => {
    const files = sendEmail.Files;
    files.splice(index, 1);
    setSendEmail({
      ...sendEmail,
      Files: files,
    });
  };

  const initEditar = () => {
    if (sendEmail.active) {
      if (props.SendEmail) {
        if (Object.keys(props.SendEmail).length > 0 && !props.SendEmail.edit) {
          setSendEmail(props.SendEmail);
        }
      }
    }
  };

  useEffect(() => {
    if (props.edit) {
      initEditar();
    }
    const isDisables =
      sendEmail.Message.length > 0 &&
      (sendEmail.NotificationRecipients.length || sendEmail.Sellers.length > 0);
    setIsDisabled(!isDisables);
  });

  return (
    <>
      <FstModal
        open={props.open}
        title="Enviar licitación vía email"
        handleClose={handleClose}
        handleSave={handleSave}
        widthSize="xl"
        setting={{ BtnNameSave: "Enviar" }}
        spinnerMessage={messageSendTender}
        IsDisabledPrimary={isDisabled}
        classContainer={"fst-Modal-Container"}
      >
        <SendTenderByEmailForm
          SendEmail={sendEmail}
          deleteFile={deleteFile}
          onChange={onChange}
        />
      </FstModal>
    </>
  );
};

export default SendTenderByEmailMaster;
