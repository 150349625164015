import { post } from "../../../config/axios.js";

export const ManagePenalty = async (request) => {
  return await post("/api/Penalty/AddOrUpdatePenalty", request);
};

export const GetPenaltyList = async () => {
  return await post("/api/Penalty/ListPenalties");
};

export const GetPenaltyReasons = async () => {
  return await post("/api/PenaltyReason/ListPenaltyReasons");
};

export const GetPenaltiesList = async (request) => {
  return await post("/api/Penalty/GetPenalties", request);
};

export const DeletePenalties = async (request) => {
  return await post("/api/Penalty/DeletePenalties", request);
};
