import React from "react";
import FstModal from "../../../components/modal/FstModal";
import Tender from "./Tender";
const TenderInformation = (props) => {
  const { open, handleClose, tenderNumber } = props;

  return (
    <FstModal
      open={open}
      title="Info del Portal"
      handleClose={handleClose}
      handleSave={handleClose}
      setting={{ BtnNameSave: "Cerrar" }}
      onlyOK={true}
      widthSize="lg"
      fullWidth={true}
      classContainer={"fst-Modal-Container"}
    >
      <div>
        <Tender tenderNumber={tenderNumber} />
      </div>
    </FstModal>
  );
};

export default TenderInformation;
