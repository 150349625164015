import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import FstAutocompletePaging from "../../../components/forms/autocomplete/FstAutocompletePaging";
import FstAutocompletePagingMinCharacters from "../../../components/forms/autocomplete/FstAutocompletePagingMinCharacters";

function EntityAutocompletePaging(props) {
  const [entity, setEntity] = useState("");

  const handleEntity = (event, value) => {
    if (!value) return;
    props.handleParentEntity(value);
    setEntity(null);
  };

  useEffect(() => {
    setEntity("");
    return () => {};
  }, [entity]);

  return props.minCharacters ? (
    <FstAutocompletePagingMinCharacters
      name="entity"
      label="Seleccione"
      loadOptions={props.loadOptions}
      value={entity}
      disabledOption={props.disabledOption}
      optionsLabel={(option) =>
        option ? option.name + "    " + option.uniqueId : ""
      }
      renderOption={(option) => (
        <Typography noWrap>{option.name + "    " + option.uniqueId}</Typography>
      )}
      onChange={handleEntity}
      minCharacters={props.minCharacters}
    />
  ) : (
    <FstAutocompletePaging
      name="entity"
      label="Seleccione"
      loadOptions={props.loadOptions}
      value={entity}
      disabledOption={props.disabledOption}
      optionsLabel={(option) =>
        option ? option.name + "    " + option.uniqueId : ""
      }
      renderOption={(option) => (
        <Typography noWrap>{option.name + "    " + option.uniqueId}</Typography>
      )}
      onChange={handleEntity}
    />
  );
}

export default EntityAutocompletePaging;
