import React, { useEffect } from "react";
import { useLocation, matchPath } from "react-router";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListSubheader } from "@material-ui/core";
import NavItem from "./NavItem";
import useWindowSize from "../../views/manage-tenders/tender/useWindowsResize";

let heightWin = window.innerHeight - 50;
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "white",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    marginRight: 24,
  },
  navigation: {
    overflow: "auto",
    padding: theme.spacing(0, 1, 2, 2),
    flexGrow: 1,
    height: heightWin,
  },
  positionContent: {
    width: "100%",
    bottom: 0,
    float: "left",
  },
}));

function renderNavItems({
  items,
  subheader,
  key,
  actionClick,
  selectedMenu,
  ...rest
}) {
  return (
    <List key={key}>
      {subheader && (
        <ListSubheader
          disableSticky
          style={{
            textTransform: "uppercase",
            fontSize: "16px",
            fontWeight: "bold",
            paddingLeft: "0.25em",
          }}
        >
          {subheader}
        </ListSubheader>
      )}
      {/* eslint-disable-next-line react/prop-types */}
      {items.reduce(
        // eslint-disable-next-line no-use-before-define
        (acc, item) =>
          reduceChildRoutes({ acc, item, actionClick, selectedMenu, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  actionClick,
  selectedMenu,
  depth = 0,
}) {
  if (item.items) {
    const open = matchPath(pathname, { path: item.href, exact: false });
    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={item.href}
        label={item.label}
        open={Boolean(open)}
        title={item.etq_title}
        id={item.statusId}
        item={item}
        actionClick={actionClick}
        selectedMenu={selectedMenu}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          actionClick,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={item.title}
        label={item.label}
        title={item.etq_title}
        id={item.statusId}
        item={item}
        actionClick={actionClick}
        selectedMenu={selectedMenu}
      />
    );
  }
  return acc;
}

function SecondaryMenu({
  openMobile,
  onMobileClose,
  className,
  menuConfig,
  handleMenu,
  selectedMenu,
  mHeight,
  ...rest
}) {
  const headerTable = 65;
  const classes = useStyles();
  const location = useLocation();
  const [height] = useWindowSize();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname, onMobileClose, openMobile]);

  const content = (
    <div {...rest} className={clsx(classes.root, className)}>
      <nav className={classes.navigation}>
        {menuConfig.map((list) =>
          renderNavItems({
            items: list.items,
            subheader: list.subheader,
            pathname: location.pathname,
            key: list.subheader,
            actionClick: handleMenu,
            selectedMenu: selectedMenu,
          })
        )}
      </nav>
    </div>
  );

  return (
    <>
      <div
        className={classes.positionContent}
        style={{ height: height - 55, maxHeight: mHeight, overflow: "auto" }}
      >
        {content}
      </div>
    </>
  );
}

SecondaryMenu.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default SecondaryMenu;
