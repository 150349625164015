import React, { Fragment, useEffect, useState } from "react";
import { Button, Checkbox } from "@material-ui/core";
import { FstGrid } from "components/grid/FstGrid";
import FstModal from "components/modal/FstModal";
import useIntlColumns from "intl/utils/format-elements/columnsIntl";
import { FormattedForm } from "intl/utils/format-elements/formattedForm";
import moment from "moment";
import { connect } from "react-redux";
import AdminSnackbars from "views/utils-admin/AdminSnackbars";
import * as SpinnerActions from "../../actions/spinnerActions";
import useGetFormConf from "../../intl/utils/hooks/useGetFormConf";
import useHandleDataByLang from "../../intl/utils/hooks/useHandleDataByLang";
import GetSelectComponet from "./GetSelectComponet";
import { langContext } from "intl/context/langContext";
import { useContext } from "react";
import { isLangCR } from "utils/common";
import { addToManagedTenders } from "actions/tenderActions";
import { ManageTenderData } from "./services/tenderService";
import { getLang } from "utils/common";
import { isLangAR } from "utils/common";

const getFstGridIntlColumns = (id, langChange) => {
  const config = langChange.configForm[id];
  if (config.element.type !== "FstGrid") {
    return [];
  }

  return config.element.columns.map((item) => {
    if (item.template === "numeric") {
      return {
        ...item,
        template: (props) => {
          return (
            <span>
              {props[item.field] &&
                props[item.field].toLocaleString(getLang(), {})}
            </span>
          );
        },
      };
    }
    if (item.template === "numeric2") {
      return {
        ...item,
        template: (props) => {
          return (
            <span>
              {props[item.field] &&
                props[item.field].toLocaleString(getLang(), {
                  minimumFractionDigits: 2,
                })}
            </span>
          );
        },
      };
    }

    return item;
  });
};

const ItemList = ({ data, height, manageData, refreshPage, ...props }) => {
  const [detailAwardedsModalOpen, setDetailAwardedsModalOpen] = useState(false);
  const [detailAwardeds, setDetailAwardeds] = useState([]);
  const [deliveryDetailModalOpen, setDeliveryDetailModalOpen] = useState(false);
  const [deliveryDetail, setDeliveryDetail] = useState([]);
  const langChange = useContext(langContext);

  const InfoItemsListHeader = [
    ...(Object.keys(manageData).length > 0
      ? [
          {
            id: "tabL.Gestionado",
            field: "tenderDetailId",
            headerText: "Gestionado",
            width: "70",
            template: (props) => {
              return (
                <Checkbox
                  checked={Boolean(
                    manageData?.manageDetails?.find(
                      (item) => item.tenderDetailId === props.tenderDetailId,
                    ),
                  )}
                  size="small"
                  disabled
                  style={{ marginTop: -8, marginBottom: -8 }}
                />
              );
            },
          },
        ]
      : []),
    { id: "tabL.NroItem", field: "itemNumber", headerText: "#", width: "40" },
    {
      id: "tabL.Descripcion",
      field: "description",
      headerText: "Descripción",
      width: "150",
    },
    ...getFstGridIntlColumns("TabItemListFirst", langChange),
    ...(isLangAR()
      ? [
          {
            id: "tabL.DetalleEntrega",
            field: "extension",
            headerText: "Detalle de Entrega",
            width: "110",
            hideToExport: true,
            template: (props) => {
              if (!props.extension) return null;
              let extension = JSON.parse(props.extension);
              if (!extension.DeliveryDetail) return null;

              return (
                <>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    fullWidth
                    onClick={() => {
                      setDeliveryDetailModalOpen(true);
                      setDeliveryDetail(extension.DeliveryDetail);
                    }}
                    style={{ marginTop: -8, marginBottom: -8 }}
                  >
                    Detalle
                  </Button>
                </>
              );
            },
          },
        ]
      : []),
    ...(!isLangCR()
      ? [
          {
            id: "tabL.Adjudicaciones",
            field: "tenderDetailAwardeds",
            headerText: "Adjudicaciones/Ofertas",
            width: "100",
            hideToExport: true,
            template: (props) => {
              return (
                <>
                  {props.tenderDetailAwardeds?.length && (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      fullWidth
                      onClick={() => {
                        setDetailAwardedsModalOpen(true);
                        setDetailAwardeds(props.tenderDetailAwardeds);
                      }}
                      style={{ marginTop: -8, marginBottom: -8 }}
                    >
                      Ver
                    </Button>
                  )}
                </>
              );
            },
          },
        ]
      : []),
  ];

  // data == tenderData
  const getForm = useGetFormConf("ListCompItemList");
  const [allData, setAllData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [uniqValue, setUniqueValue] = useState(moment().format());
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [runInit, setRunInit] = useState(false);
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);
  const [firstRender, setFirstRender] = useState(true);
  const [currentPagination, setCurrentPagination] = useState(null);

  const fetchData = async (pagination) => {
    props.SpinnerShow("Cargando...");
    let result = handelData.getDataByCountry("tab.ListaItems");
    result = result.map((item) => {
      return {
        ...item,
        tenderExtension: data.extension,
      };
    });
    setAllData(result);
    if (firstRender) {
      setFirstRender(false);
      setCurrentData(
        result.slice(
          pagination.pageSize * (pagination.PageNumber - 1),
          pagination.pageSize * pagination.PageNumber,
        ),
      );
      props.SpinnerHide();
    } else {
      setTimeout(() => {
        setCurrentData(
          result.slice(
            pagination.pageSize * (pagination.PageNumber - 1),
            pagination.pageSize * pagination.PageNumber,
          ),
        );
        props.SpinnerHide();
      }, 450);
    }
    setCurrentPagination(pagination);
  };

  const remove = (btn, item) => {
    if (btn.type === "Remove") {
      setItemToDelete(item);
      setDeleteConfirm(true);
    }
  };

  const actionCustomRow = (btn, item) => {
    remove(btn, item);
  };

  const handleDelete = async (item) => {
    handleUnmanageItem(item);
  };

  const handleManageItem = async (item) => {
    if (
      !item ||
      manageData?.manageDetails?.find(
        (x) => x.tenderDetailId === item.tenderDetailId,
      )
    ) {
      //TODO: toaster error? (already managed)
      return;
    }
    props.SpinnerShow("Cargando...");
    await addToManagedTenders([
      {
        TenderNumber: data.tenderNumber,
        ItemNumbers: [item.itemNumber],
      },
    ]);
    setTimeout(() => {
      refreshPage();
    }, 300);
  };

  const handleUnmanageItem = async (item) => {
    if (
      !item ||
      !manageData?.manageDetails?.find(
        (x) => x.tenderDetailId === item.tenderDetailId,
      )
    ) {
      //TODO: toaster error? (already unmanaged)
      return;
    }
    props.SpinnerShow("Cargando...");
    let newManageDetails = manageData.manageDetails.filter(
      (detail) => detail.tenderDetailId !== item.tenderDetailId,
    );
    const manageDetails = newManageDetails.map((item) => {
      item.estimatedTotal = +item.estimatedTotal;
      return item;
    });

    const request = {
      TenderNumber: manageData.tenderNumber,
      ManageFiles: manageData.files ?? [],
      SellerIds: manageData.sellers.map((item) => item.sellerId),
      TagIds: manageData.tags.map((item) => item.tagId),
      CategoryIds: manageData.categories.map((item) => item.categoryId),
      LegalDocumentIds: manageData.legalDocuments.map(
        (item) => item.legalDocumentId,
      ),
      ManageComments: manageData.manageComments,
      ManageDetails: manageDetails.map((item) => {
        const obj = {
          manageId: item.manageId,
          manageDetailId: item.manageDetailId,
          tenderDetailId: item.tenderDetailId,
          itemNumber: item.itemNumber,
          companyId: item.companyId,
          estimatedTotal: item.estimatedTotal,
          offered: item.offered,
          comments: item.comments,
          createdByUserId: item.createdByUserId,
          creationDate: item.creationDate,
          searchFilterResult: item.searchFilterResult,
          productId: item.productId,
          deliveryDate: item.deliveryDate,
          tagIds: item.tagIds,
          isPrimary: item.isPrimary,
        };
        return obj;
      }),
      ManageInternalStatusId: manageData.manageInternalStatusId,
      ManageExtensions: manageData.manageExtensions,
    };
    await ManageTenderData(request);
    setTimeout(() => {
      refreshPage();
    }, 300);
  };

  const actionBegin = async (args) => {
    if (args.requestType === "beginEdit") {
      args.cancel = true;
    }
  };

  const dataGrid = {
    Getdata: fetchData,
    EditOptions: {
      allowDeleting: Object.keys(manageData).length > 0,
      showDeleteConfirmDialog: true,
      allowEditing: Object.keys(manageData).length > 0,
      allowAdding: Object.keys(manageData).length > 0,
    },
    Header: InfoItemsListHeader,
    ActionCustomRow: actionCustomRow,
    height: "100%",
    ToolBar: {
      Edit: Object.keys(manageData).length > 0,
      EditTitle: "Gestionar",
      Export: Object.keys(manageData).length > 0,
      Delete: Object.keys(manageData).length > 0,
      DeleteTitle: "Remover gestionado",
    },
    ExportFileName: `Lista de items.xlsx`,
    exportFunc: () =>
      allData.map((item) => {
        return {
          ...item,
          tenderDetailId: manageData?.manageDetails?.find(
            (x) => x.tenderDetailId === item.tenderDetailId,
          )
            ? "Si"
            : "No",
        };
      }),
    actionBegin: actionBegin,
    allowSorting: false,
    allowPaging: true,
    take: 15,
    Edit: handleManageItem,
    Delete: (item) => {
      if (
        !item ||
        !manageData?.manageDetails?.find(
          (x) => x.tenderDetailId === item.tenderDetailId,
        )
      ) {
        //TODO: toaster error? (already unmanaged)
        return;
      }
      setItemToDelete(item);
      setDeleteConfirm(true);
    },
    idGrid: "InfoItemsList",
  };

  const initData = () => {
    let result = handelData.getDataByCountry("tab.ListaItems");
    result = result.map((item) => {
      return {
        ...item,
        tenderExtension: data.extension,
      };
    });
    setAllData(result);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <Fragment>
      <FstGrid
        data={dataGrid}
        uniqValue={uniqValue}
        result={{
          result: currentData,
          count: allData.length,
        }}
        reloadDefault={true}
      />
      <AdminSnackbars
        openToaster={false}
        modalTitle={`Remover ítem gestionado`}
        modalClose={() => {
          setItemToDelete(null);
          setDeleteConfirm(false);
        }}
        deleteConfirm={deleteConfirm}
        modalMessage={`¿Está seguro que desea remover el ítem gestionado ${itemToDelete?.description}?`}
        modalSuccess={() => handleDelete(itemToDelete)}
      />
      <FstModal
        open={detailAwardedsModalOpen}
        title="Adjudicaciones / Ofertas"
        handleSave={() => {
          setDetailAwardedsModalOpen(false);
          setDetailAwardeds([]);
        }}
        widthSize="md"
        onlyOK
        setting={{ BtnNameSave: "Cerrar" }}
      >
        <FormattedForm id={"TabItemListLast"} data={detailAwardeds} />
      </FstModal>
      <FstModal
        open={deliveryDetailModalOpen}
        title="Detalle de Entrega"
        handleSave={() => {
          setDeliveryDetailModalOpen(false);
          setDeliveryDetail([]);
        }}
        widthSize="md"
        onlyOK
        setting={{ BtnNameSave: "Cerrar" }}
      >
        <FormattedForm
          id={"TabItemModalDeliveryDetail"}
          data={deliveryDetail}
        />
      </FstModal>
    </Fragment>
  );
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(ItemList);
