import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Grid } from "@material-ui/core";
import moment from "moment";
import { Card, CardContent } from "@material-ui/core";
import { Controls } from "../../utils/controls";
import { useIntl } from "react-intl";
import { FormattedForm } from "../../intl/utils/format-elements/formattedForm";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 20,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  padddingTopNone: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
  },
});

function TenderInfo({ tender }) {
  const classes = useStyles();
  const { variant } = Controls.TextField;
  const intl = useIntl();

  return (
    <Card className={classes.root} id="header-info">
      {tender && (
        <CardContent>
          <Grid container spacing={3}>
            <FormattedForm id={"TenderInform"} values={tender}>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  id="nomenclatura"
                  variant={variant}
                  label={intl.formatMessage({
                    id: "text.Nomenclatura",
                    defaultMessage: "Nomenclatura",
                  })}
                  size="small"
                  style={{ width: "100%" }}
                  value={tender.fileNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <TextField
                  id="publication-date"
                  label={intl.formatMessage({
                    id: "text.Fecha_Publicacion",
                    defaultMessage: "Fecha Publicación",
                  })}
                  size="small"
                  variant={variant}
                  style={{ width: "100%" }}
                  value={moment(tender.publishedDate).format("DD/MM/YYYY")}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="announcement"
                  label={intl.formatMessage({
                    id: "text.Convocatoria",
                    defaultMessage: "Convocatoria",
                  })}
                  style={{ width: "100%" }}
                  size="small"
                  variant={variant}
                  value={tender.tenderNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  id="type-of-sale"
                  label={intl.formatMessage({
                    id: "text.Tipo_Compra",
                    defaultMessage: "Tipo Compra",
                  })}
                  variant={variant}
                  size="small"
                  style={{ width: "100%" }}
                  value={tender.tenderTypeDesc}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                className={classes.padddingTopNone}
              >
                <TextField
                  id="entity"
                  label={intl.formatMessage({
                    id: "text.Entidad",
                    defaultMessage: "Entidad",
                  })}
                  variant={variant}
                  size="small"
                  style={{ width: "100%" }}
                  value={tender.clientUnitName}
                  InputProps={{
                    readOnly: true,
                  }}
                  multiline
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                className={classes.padddingTopNone}
              >
                <TextField
                  id="entity"
                  label={intl.formatMessage({
                    id: "text.Descripcion",
                    defaultMessage: "Descripción",
                  })}
                  size="small"
                  variant={variant}
                  style={{ width: "100%" }}
                  rowsMax="2"
                  value={tender.tenderName}
                  InputProps={{
                    readOnly: true,
                  }}
                  multiline
                />
              </Grid>
            </FormattedForm>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
}

export default TenderInfo;
