import useDownloadLang from "../../context/langDownload";

const useLangOffProvider = () => {
  const lang = useDownloadLang();
  const state = JSON.parse(localStorage.getItem("state"));
  const { language } = state.user;

  const OneElement = (id, valueDefauld) => {
    const langProperty = lang.getLangDetail("es-PE");
    let newValue = valueDefauld;
    try {
      const idfilter = langProperty.mensaje[id];
    } catch (e) {
      console.error("id de objecto no encontrado " + id);
    }
    return newValue;
  };

  return {
    OneElement,
  };
};

export default useLangOffProvider;
