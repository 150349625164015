import { AlertPendingSave } from "../types";

const INITIAL_STATE = {
  pendingActive: false,
  alertActive: false,
  formActive: false,
  toRedirect: "",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AlertPendingSave.PENDING_ACTIVE:
      return {
        ...state,
        pendingActive: true,
      };
    case AlertPendingSave.PENDING_DEACTIVE:
      return {
        ...state,
        pendingActive: false,
      };
    case AlertPendingSave.ALERT_ACTIVE:
      return {
        ...state,
        alertActive: true,
      };
    case AlertPendingSave.ALERT_DEACTIVE:
      return {
        ...state,
        alertActive: false,
      };
    case AlertPendingSave.ALERT_REDIRECT:
      return {
        ...state,
        toRedirect: action.toRedirect,
      };
    case AlertPendingSave.FORM_ACTIVE:
      return {
        ...state,
        formActive: true,
      };
    case AlertPendingSave.FORM_DEACTIVE:
      return {
        ...state,
        formActive: false,
      };
    default:
      return state;
  }
};
