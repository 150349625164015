import { makeStyles } from "@material-ui/core/styles";

export const HeaderStyles = makeStyles({
  root: {
    display: "block",
    padding: 12,
  },
  headerName: {
    fontSize: "0.9rem",
  },
  InputWidth: {
    width: "100%",
  },
  container: {
    marginBottom: 15,
  },
});

export const FormStyles = makeStyles({
  root: {
    display: "block",
    padding: 12,
  },
  headerName: {
    fontSize: "0.9rem",
  },
  InputWidth: {
    width: "100%",
  },
  Autocomplete: {
    margin: 0,
    "& .MuiFormControl-root": {
      marginTop: 0,
    },
  },
  KeyboardDatePicker: {
    width: "100%",
    marginTop: 0,
  },
  ListComments: {
    maxHeight: "150px",
    overflow: "auto",
    marginTop: "30px",
  },
});

export const FormTicketContentStyles = makeStyles({
  root: {
    paddingTop: "0px !important",
  },
  AutocompletePosition: {
    width: "100%",
    marginBottom: "10px",
  },
});

export const CommentStyles = makeStyles({
  content: {
    minHeight: "200px",
    maxHeight: "315px",
    //overflow: '',
  },
  contentModal: {
    height: 200,
    maxHeight: 400,
  },
});

export const ModalSendTenderEmail = makeStyles((theme) => ({
  content: {
    width: "950Px",
  },
  contentIconFile: {
    width: "auto",
    minWidth: 0,
  },
  contentTextEditor: {
    height: "270px",
  },
  chipRoot: {
    justifyContent: "center",
    float: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));
