import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { FstGrid } from "../../components/grid/FstGrid";

/* Props:
    - tableData: Array of objects, each object represents a row in the table
    - tableDataCount: int, number of rows in the table
    - reloadDefault: bool
    - fetchAllData: function. Fetches all base data
    - actionCustomRow: function
    - handleSave: function
    - header: Array of objects, each object represents a column in the table
    - idGrid: string, id of the grid
*/
function AdminGrid(props) {
  const [uniqValue, setUniqueValue] = useState(moment().format());

  /// Height handling
  const [height, setHeight] = useState();

  const handleResize = useCallback(() => {
    const padding = 130;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  ///

  const dataGrid = {
    Getdata: props.fetchAllData,
    EditOptions: {
      allowDeleting: true,
      showDeleteConfirmDialog: true,
      allowEditing: true,
      allowAdding: true,
    },
    ActionCustomRow: props.actionCustomRow,
    allowSorting: true,
    allowPaging: true,
    AddDefault: true,
    take: 17,
    Header: props.header,
    height: height - 180,
    ToolBar: { Add: true, AddTitle: "Agregar" },
    idGrid: props.idGrid,
    Updated: props.handleSave,
  };

  return (
    <FstGrid
      data={dataGrid}
      uniqValue={uniqValue}
      result={{
        result: props.tableData.data,
        count: props.tableDataCount,
      }}
      reloadDefault={true}
    />
  );
}

export default AdminGrid;
