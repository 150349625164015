import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  rootHeader: {
    padding: 10,
  },
  tipograghy: {
    margin: 0,
    fontWeight: 500,
    fontSize: "1.1rem",
  },
  content: {
    height: "inherit",
    padding: "24px",
    justifyContent: "center",
  },
  contentform: {
    maxWidth: "45%",
  },
  action: {
    marginTop: "auto",
    marginBottom: "auto",
    marginRight: "auto",
  },
  actionButton: {
    color: "#3f51b5",
  },
  gridButton: {
    width: "30%",
  },
  containerStatus: {
    justifyContent: "center",
    display: "flex",
  },
}));
