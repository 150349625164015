import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SendTenderByEmailMaster from "../../utils-send-email/SendTenderByEmailMaster";
import * as SpinnerActions from "../../../actions/spinnerActions";

const SendTenderByEmail = (props) => {
  return <SendTenderByEmailMaster {...props} />;
};

const mapStateToProps = (reducers) => {
  return {
    ...reducers.spinnerReducer,
  };
};

export default connect(mapStateToProps, { ...SpinnerActions })(
  SendTenderByEmail
);
