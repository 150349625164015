import React, { useEffect, useState } from "react";
import { Card, CardContent } from "@material-ui/core";
import { connect } from "react-redux";
import HeaderActions from "./TenderInfoHeaderAcctions";
import GeneralInfo from "./GeneralInfo";
import { TenderPanel } from "../../types";
import Schedule from "./Schedule";
import ItemList from "./ItemList";
import ProcedureDocument from "./ProcedureDocument";
import GeneralProcedureActions from "./GeneralProcedureActions";
import SupervisingJob from "./SupervisingJob";
import Assignments from "./assignments/Assignments";
import LegalDocumentsTender from "./legal-document-tender/LegalDocumentsTender";
import Divider from "@material-ui/core/Divider";
import { GetManageData, ManageTenderData } from "./services/tenderService";
import AllDetails from "./all-details/AllDetails";
import TenderComments from "./tender-comments/TenderComments";
import { ContentStyle } from "./style/ContentStyle";
import * as SpinnerActions from "../../actions/spinnerActions";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import FstModalConfirm from "../../components/modal/FstModalConfirm";
import { withRouter } from "react-router-dom";
import * as PendingSaveActions from "../../actions/pendingSaveActions";
import Requirement from "./Requirement";
import Warranty from "./Warranty";
import OffersInfo from "./OffersInfo";
import ContractConditions from "./ContractConditions";
import Delivery from "./Delivery";
import RelatedOfficials from "./RelatedOfficials";
import * as SettingsChangeActions from "actions/settingsChangeActions";
import Sanctions from "./Sanctions";
import AwardedContracts from "./AwardedContracts";
import AwardedPurchaseOrders from "./AwardedPurchaseOrders";
import Checklist, { baseChecklistData } from "./Checklist";
import { GetPenaltyReasons } from "views/penalties/service/penaltyService";
import { listTags } from "views/tag/services/tagService";
import { isLangCR } from "utils/common";

const saveManageData = async (
  manageData,
  spinnerShow,
  reloadPage,
  spinnerHide,
) => {
  spinnerShow("Inicializando datos por primera vez...");
  const request = {
    TenderNumber: manageData.tenderNumber,
    ManageFiles: manageData.files ?? [],
    SellerIds: manageData.sellers.map((item) => item.sellerId),
    TagIds: manageData.tags.map((item) => item.tagId),
    CategoryIds: manageData.categories.map((item) => item.categoryId),
    LegalDocumentIds: manageData.legalDocuments.map(
      (item) => item.legalDocumentId,
    ),
    ManageComments: manageData.manageComments,
    ManageDetails: manageData.manageDetails.map((item) => {
      const obj = {
        manageId: item.manageId,
        manageDetailId: item.manageDetailId,
        tenderDetailId: item.tenderDetailId,
        itemNumber: item.itemNumber,
        companyId: item.companyId,
        estimatedTotal: item.estimatedTotal,
        offered: item.offered,
        comments: item.comments,
        createdByUserId: item.createdByUserId,
        creationDate: item.creationDate,
        searchFilterResult: item.searchFilterResult,
        productId: item.productId,
        deliveryDate: item.deliveryDate,
        tagIds: item.tagIds,
        isPrimary: item.isPrimary,
      };
      return obj;
    }),
    ManageInternalStatusId: manageData.manageInternalStatusId,
    ManageExtensions: manageData.manageExtensions,
  };
  await ManageTenderData(request);
  setTimeout(() => {
    reloadPage();
    spinnerHide();
  }, 350);
};

const ContentSubCardTender = (props) => {
  const spinnerMessage = "Guardando cambios en licitacion...";
  const [tender, setTender] = useState({});
  const tenderRef = React.useRef(tender);
  tenderRef.current = tender;

  const [files, setFiles] = useState([]);
  const classes = ContentStyle();
  const [height, setHeight] = useState();
  const [assignments, setAssignments] = useState({
    internalStatus: null,
    tatagsgs: [],
    sellers: [],
    categories: [],
  });
  const [message, setMessage] = useState("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [penaltyReasonsList, setPenaltyReasonsList] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  const handleResize = () => {
    const info = document.getElementById("header-info").clientHeight;
    const header = document.getElementById("main-header").clientHeight;
    let rest = window.innerHeight - (header + info + 176);
    if (props.heightToSubtract) {
      rest = rest - parseInt(props.heightToSubtract);
    }
    setHeight(rest);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    props.LinkRedirect(props.route);
    if (!tender.tenderNumber) {
      GetManageDataTender();
    } else {
      createManageDetailsList(tender);
    }
    return () => {
      props.PendingSaveHide();
      props.CloseAlertPending();
      window.removeEventListener("resize", handleResize);
    };
  }, [props.menu.title]);

  const getTags = async () => {
    let res = await listTags({
      orderBy: "",
      pageSize: 1000,
      search: "",
      PageNumber: 1,
      OrderAsc: false,
    });
    setTagsList(res.data);
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    if (props.settingsChangeActive) {
      getTags();
      props.SettingsChangeDeactivate();
    }
  }, [props.settingsChangeActive]);

  const GetManageDataTender = async () => {
    if (!props.isManaged) {
      handleResize();
      return;
    }
    const rest = await GetManageData({
      tenderNumber: props.tender.tenderNumber,
    });
    rest.data.internalStatus = {
      manageInternalStatusId: rest.data.manageInternalStatusId,
      description: rest.data.manageInternalStatusDesc,
    };

    createManageDetailsList(rest.data);
    let status = null;
    if (rest.data.manageInternalStatusId) {
      status = {
        manageInternalStatusId: rest.data.manageInternalStatusId,
        description: rest.data.manageInternalStatusDesc,
      };
    }

    setAssignments({
      internalStatus: status,
      sellers: rest.data.sellers,
      tags: rest.data.tags,
      categories: rest.data.categories,
    });

    const _files = rest.data.manageFiles.map((item) => {
      item.FileName = item.fileName;
      item.Content = item.content;
      return item;
    });
    setFiles(_files);

    let penaltyReasons = await GetPenaltyReasons();
    let penaltyReasonsList = penaltyReasons.data.map((item) => {
      return { id: item.penaltyReasonId, name: item.description };
    });
    setPenaltyReasonsList(penaltyReasonsList);

    // Checklist
    //filter to get manageExtensions that have the same manageId
    let manageExtensions = (rest.data.manageExtensions || []).filter(
      (manageExtension) => {
        return (
          manageExtension.manageId === rest.data.manageId ||
          manageExtension.ManageId === rest.data.manageId
        );
      },
    );

    if (manageExtensions.length === 0 && isLangCR()) {
      // Currently used only in CR
      let data = JSON.parse(JSON.parse(props.tender?.extension)?.Info);
      let tenderDetailExtension =
        props.tender.tenderDetails.length > 0
          ? JSON.parse(props.tender.tenderDetails[0].extension)
          : {};
      let baseData = baseChecklistData(data, tenderDetailExtension);
      setTimeout(() => {
        saveManageData(
          {
            ...tenderRef.current,
            manageExtensions: [
              { ManageId: rest.data.manageId, Data: JSON.stringify(baseData) },
            ],
          },
          props.SpinnerShow,
          GetManageDataTender,
          props.SpinnerHide,
        );
      }, 350);
    }

    handleResize();
  };

  const iniSave = () => {
    props.SpinnerShow(spinnerMessage);
  };

  const responseAction = () => {
    props.SpinnerHide();
    setTender({
      ...tender,
      isChange: false,
    });
    props.PendingSaveHide();
  };

  const createManageDetailsList = (response) => {
    const listTenderDetails = [...props.tender.tenderDetails];

    // filter items that are in listTenderDetails but are not in response.manageDetails
    // (because only specific items can be managed)
    let tenderDetailsInManaged = listTenderDetails.filter((tenderDataItem) => {
      return response.manageDetails.find(
        (manageDataItem) =>
          manageDataItem.tenderDetailId === tenderDataItem.tenderDetailId,
      );
    });

    // for each item in response.manageDetails, find the corresponding item in listTenderDetails and add its properties
    let manageDetailsCompleteInfo = response.manageDetails.map(
      (manageDataItem) => {
        const tenderDataItem = listTenderDetails.find(
          (tenderDataItem) =>
            tenderDataItem.tenderDetailId === manageDataItem.tenderDetailId,
        );
        if (!tenderDataItem) {
          return;
        }
        return {
          ...manageDataItem,
          ...tenderDataItem,
          managed: true,
        };
      },
    );

    for (let i = 0; i < tenderDetailsInManaged.length; i++) {
      const item = tenderDetailsInManaged[i];
      let result;
      const index = response.manageDetails
        .map((x) => x.tenderDetailId)
        .indexOf(item.tenderDetailId);
      if (index > -1) {
        const obj = response.manageDetails.filter(
          (x) => x.tenderDetailId === item.tenderDetailId,
        )[0];
        result = {
          ...item,
          ...obj,
          managed: true,
        };
      } else {
        result = {
          ...item,
          estimatedTotal: 0,
          offered: false,
          managed: false,
        };
      }
      tenderDetailsInManaged[i] = result;
    }
    if (response.faithFulObservanceRequired) {
      response.ConfigLegalDocument = { text: "Requerida", id: 1 };
    }
    response.manageDetails = manageDetailsCompleteInfo;
    // props.onChangeTender("manageDetails", managed);
    setTender(response);
    handleResize();
  };

  const onChangeManagedList = (list) => {
    const tenderAux = tender;
    tenderAux.manageDetails = list;
    setTender({
      ...tenderAux,
      isChange: true,
    });
    props.PendingSaveShow();
  };

  const handleFiles = (files) => {
    setFiles(files);
    setTender({
      ...tender,
      isChange: true,
    });
    props.PendingSaveShow();
  };

  const handleAssignments = (property, value) => {
    setAssignments({
      ...assignments,
      [property]: value,
    });
    setTender({
      ...tender,
      isChange: true,
    });
    props.PendingSaveShow();
  };

  const updateLegalDocuments = (document) => {
    const tenderAux = tender;
    const index = findLegalDocument(document);
    if (index > -1) {
      tenderAux.legalDocuments[index] = document;
    } else {
      tenderAux.legalDocuments.push(document);
    }

    setTender({
      ...tenderAux,
      isChange: true,
    });
    props.PendingSaveShow();
  };

  const addComment = (comments) => {
    const tenderAux = tender;
    tenderAux.manageComments.push(comments);
    setTender({
      ...tenderAux,
      isChange: true,
    });
    props.PendingSaveShow();
  };

  const deleteLegalDocument = (document) => {
    const tenderAux = tender;
    const index = findLegalDocument(document);
    tenderAux.legalDocuments.splice(index, 1);
    setTender({
      ...tenderAux,
      isChange: true,
    });
    props.PendingSaveShow();
  };

  const findLegalDocument = (document) => {
    return tender.legalDocuments
      .map((item) => item.legalDocumentId)
      .indexOf(document.legalDocumentId);
  };

  const onChangeConfigLegalDocument = (item) => {
    const tenderAux = tender;
    tenderAux.ConfigLegalDocument = item;
    setTender({
      ...tenderAux,
      isChange: true,
    });
    props.PendingSaveShow();
  };

  const clearObject = () => {
    props.PendingSaveHide();
    setTender({ manageComments: [], legalDocuments: [] });
    props.reloadPage();
  };

  const refreshPageOnSameMenu = () => {
    props.PendingSaveHide();
    setTender({ manageComments: [], legalDocuments: [] });
    props.reloadPageOnSameMenu();
  };

  const executeSuccess = () => {
    props.CloseAlertPending();
  };

  const handleOpenModal = () => {
    if (!tender.isChange) {
      props.history.push(props.toRedirect);
    } else {
      props.OpenAlertPending();
      props.LinkRedirect(props.route);
    }
  };

  const handleClose = () => {
    setOpenConfirm(false);
    props.history.push(props.toRedirect);
  };

  const clearMenu = () => {
    setTender({ manageComments: [], legalDocuments: [] });
    props.clearMenu();
  };

  const onChangeChecklist = (checklistExtension) => {
    setTender((prev) => {
      const tenderAux = { ...prev };
      tenderAux.manageExtensions = checklistExtension;
      return {
        ...tenderAux,
        isChange: true,
      };
    });
    props.PendingSaveShow();
  };

  const onChangePenalties = (modifiedPenalties) => {
    const tenderAux = tender;
    if (!tenderAux.penalties) tenderAux.penalties = [];
    tenderAux.penalties = [...tenderAux.penalties, ...modifiedPenalties];
    setTender({
      ...tenderAux,
      isChange: true,
    });
    props.PendingSaveShow();
  };

  const selectedCard = {
    [TenderPanel.GENERAL]: () => <GeneralInfo data={props.tender} />,
    [TenderPanel.SCHEDULER]: () => <Schedule data={props.tender} />,
    [TenderPanel.ITEMS_LIST]: () => (
      <ItemList
        data={props.tender}
        height={height}
        manageData={tender}
        refreshPage={refreshPageOnSameMenu}
        onChangeManagedList={onChangeManagedList}
      />
    ),
    [TenderPanel.DOCUMENTOS]: () => <ProcedureDocument data={props.tender} />,
    [TenderPanel.ACCIONES]: () => (
      <GeneralProcedureActions data={props.tender} />
    ),
    [TenderPanel.OFICIOS]: () => <SupervisingJob data={props.tender} />,
    [TenderPanel.REQUISITOS]: () => <Requirement data={props.tender} />,
    [TenderPanel.GARANTIAS]: () => <Warranty data={props.tender} />,
    [TenderPanel.OFERTAS]: () => <OffersInfo data={props.tender} />,
    [TenderPanel.CONDICIONES]: () => <ContractConditions data={props.tender} />,
    [TenderPanel.ENTREGA]: () => <Delivery data={props.tender} />,
    [TenderPanel.FUNCIONARIOS]: () => <RelatedOfficials data={props.tender} />,
    [TenderPanel.ENTIDADES]: () => (
      <Assignments
        assignments={assignments}
        handleAssignments={handleAssignments}
        files={files}
        handleFiles={handleFiles}
      />
    ),
    [TenderPanel.ITEMS_ALL]: () => (
      <AllDetails
        data={tender.manageDetails}
        tender={props.tender}
        onChangeManagedList={onChangeManagedList}
        height={height}
        reloadPage={refreshPageOnSameMenu}
        clearMenu={clearMenu}
        tagsList={tagsList}
        settingsChangeActive={props.settingsChangeActive}
      />
    ),
    [TenderPanel.TICKET]: () => (
      <LegalDocumentsTender
        value={tender}
        DeleteLegalDocument={deleteLegalDocument}
        updateLegalDocument={updateLegalDocuments}
        onChangeConfigLegalDocument={onChangeConfigLegalDocument}
        height={height}
      />
    ),
    [TenderPanel.COMMENTS]: () => (
      <TenderComments data={tender} addComment={addComment} height={height} />
    ),
    [TenderPanel.SANCTIONS]: () => (
      <Sanctions
        data={tender}
        height={height}
        onChangePenalties={onChangePenalties}
        penaltyReasonsList={penaltyReasonsList}
      />
    ),
    [TenderPanel.CHECKLIST]: () => (
      <Checklist
        data={props.tender}
        height={height}
        manageData={tender}
        onChangeChecklist={onChangeChecklist}
      />
    ),
    [TenderPanel.CONTRATOS]: () => <AwardedContracts data={props.tender} />,
    [TenderPanel.ORDENES_PEDIDO]: () => (
      <AwardedPurchaseOrders data={props.tender} />
    ),
    [TenderPanel.PANTALLA_CARGA]: () => null,
  };
  return (
    <>
      {height && (
        <Card style={{ marginTop: "-1%" }}>
          {!props.hiddenFooter && (
            <>
              <HeaderActions
                menu={props.menu}
                menuCompleteList={props.menuCompleteList}
                tender={tender}
                tenderData={props.tender}
                iniSave={iniSave}
                files={files}
                reloadPage={refreshPageOnSameMenu}
                responseAction={responseAction}
                assignments={assignments}
                refreshData={GetManageDataTender}
              />
              <Divider />
            </>
          )}
          <CardContent className={classes.root} style={{ height: height }}>
            {selectedCard[props.menu.title]()}
          </CardContent>
          {!props.hiddenFooter && (
            <>
              <Divider />
              <CardActions style={{ float: "right" }}>
                <Button onClick={handleOpenModal}>Volver</Button>
              </CardActions>
            </>
          )}
        </Card>
      )}

      <FstModalConfirm
        title="Aviso"
        close={() => {
          handleClose();
        }}
        open={props.alertActive}
        setting={{ BtnNameSave: "Aceptar" }}
        message={
          "Tiene cambios pendientes sin guardar. Presione ACEPTAR para permanecer en esta vista y guardar, o CANCELAR para descartarlos."
        }
        success={executeSuccess}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { alertPendingSaveReducer } = state;
  const { pendingActive, alertActive, toRedirect } = alertPendingSaveReducer;
  return {
    ...state,
    pendingActive,
    alertActive,
    toRedirect,
    ...state.settingsChangeReducer,
  };
};

export default connect(mapStateToProps, {
  ...SpinnerActions,
  ...PendingSaveActions,
  ...SettingsChangeActions,
})(withRouter(ContentSubCardTender));
