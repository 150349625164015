import React, { useState, useEffect } from "react";
import ReactExport from "react-export-excel";
import { Button } from "@material-ui/core";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadExcel = (props) => {
  const [GroupChildrens, setGroupChildrens] = useState();
  const [dataSource, setDataSourse] = useState([]);

  const initDataDowload = async () => {
    const aux = { ...props.paging };
    aux.pageSize = props.paging.totalRows;
    aux.pageNumber = 1;
    const result = await props.getData(aux, "Donwload");
    setDataSourse(result);
    document.getElementById("btn-export-excel").click();
  };

  const getRow = (value) => {
    if (value.colExcel) {
      return value.colExcel;
    }
    return value.field;
  };

  useEffect(() => {
    if (props.isDonwload) {
      initDataDowload();
    }
    return () => {};
  }, [props.isDonwload]);

  return (
    <ExcelFile
      filename={props.fileName}
      element={
        <Button id={"btn-export-excel"} style={{ display: "none" }}>
          Export Data
        </Button>
      }
    >
      <ExcelSheet data={dataSource} name={props.nameSheet}>
        {props.columns.map((item, index) => (
          <ExcelColumn
            key={index}
            label={item.headerText}
            value={getRow(item)}
            style={{ width: "500" }}
          />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};

export default DownloadExcel;

/*<Button onClick={()=>this.handleClick()} variant="contained">Exportar a Excel</Button>
<ExcelFile 
    filename={fileName} 
    element={
        <Button variant="contained">Exportar a Excel</Button>
    }>
        <ExcelSheet data={dataSource} >
            {columns.map((item) =>
                <ExcelColumn label={item.headerText} value={item.field}/>
            )}
        </ExcelSheet>
</ExcelFile>*/
