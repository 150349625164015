import React, { useEffect, useState } from "react";
import useGetFormConf from "../../intl/utils/hooks/useGetFormConf";
import useHandleDataByLang from "../../intl/utils/hooks/useHandleDataByLang";
import GetSelectComponet from "./GetSelectComponet";

export default function ProcedureDocument({ data }) {
  const getForm = useGetFormConf("ListCompDocuments");
  const [getData, setData] = useState([]);
  const [runInit, setRunInit] = useState(false);
  const handleData = useHandleDataByLang("TenderContainer", "Tab", data);

  const initData = () => {
    let result = handleData.getDataByCountry("tab.Documentos");
    // Handle specific AR multiple document sections case
    if (JSON.parse(localStorage.getItem("state")).user?.language === "es-AR") {
      result = {
        tenderDocuments: result,
        tenderClauses: data.tenderClauses,
        tenderFolds: data.tenderFolds,
        tenderTechnicalFolds: data.tenderTechnicalFolds,
      };
    }
    setData(result);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <React.Fragment>
      {runInit &&
        getForm
          .GetFormConf()
          .map((item, index) => (
            <GetSelectComponet
              key={index}
              item={item}
              data={getData}
              dataExt={getData}
            />
          ))}
    </React.Fragment>
  );
}
