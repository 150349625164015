import React from "react";
import FstYesNO from "../../components/utils/FstYesNo";
import { formatShortDate, dateTimeShortFormat } from "../../utils/common";
import AttachFileIcon from "@material-ui/icons/AttachFile";

export const HeaderTable = (intl) => [
  {
    field: "number",
    headerText: "Numero",
    width: "85",
    textAlign: "Center",
    showInColumnChooser: false,
  },
  {
    field: "bankName",
    headerText: intl.formatMessage({
      id: "title.Banco.GridGarantia",
      defaultMessage: "Banco",
    }),
    width: "100",
    textAlign: "Left",
  },
  {
    field: "subject",
    headerText: "Motivo",
    width: "150",
    textAlign: "Left",
  },
  {
    field: "reference",
    headerText: "Referencia",
    width: "240",
    textAlign: "Left",
  },
  {
    field: "currencyDescription",
    headerText: "Moneda",
    width: "180",
    textAlign: "Center",
  },
  {
    field: "value",
    headerText: "Monto",
    width: "115",
    textAlign: "Right",
    template: (props) =>
      props.currencyId ? (
        <span>{`${props.currencySymbol} ${props.value.toFixed(2)}`}</span>
      ) : null,
  },
  {
    field: "statusDescription",
    headerText: "Estado",
    width: "105",
    textAlign: "Center",
  },
  {
    field: "endDate",
    headerText: "Vencimiento",
    width: "110",
    textAlign: "Center",
    template: (props) =>
      props.endDate ? <span>{formatShortDate(props.endDate)}</span> : null,
  },
  {
    field: "active",
    headerText: "Activa",
    width: "80",
    textAlign: "Center",
    template: (props) => <FstYesNO value={props.active} />,
  },
  {
    headerText: "Adjunto",
    width: "80",
    textAlign: "Center",
    hideToExport: true,
    template: (props) => (
      <div>
        {props.documentFiles[0] ? <AttachFileIcon fontSize="small" /> : null}
      </div>
    ),
  },
];

export const ColumnsDates = [
  {
    field: "endDate",
    format: dateTimeShortFormat,
  },
];

export const ColumnsBooleans = [
  {
    field: "active",
    POSITIVE: "Si",
    NEGATIVE: "No",
  },
];
