import { post, get } from "../../../config/axios.js";

export const GetSearchFilter = async (pagination, filter, moduleId) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
    AnalysisFilterModuleId: moduleId,
  };
  return await post("/api/analysisFilter/getList", request);
};

export const getCompetitorsOld = async () => {
  const request = {
    PageNumber: 1,
    PageSize: 999999,
    Filter: "",
    OrderBy: "",
  };
  return await post("/api/analysisFilter/getCompetitors", request);
};

export const getOrganismsOld = async () => {
  const request = {
    PageNumber: 1,
    PageSize: 999999,
    Filter: "",
    OrderBy: "",
  };
  return await post("/api/analysisFilter/getOrganisms", request);
};

export const getTypes = async (Id) => {
  if (Id === 4) {
    //Obtiene los tipos para OC
    return await get("api/purchaseOrder/getTypes");
  }
  //Obtiene los tipos para Licitaciones
  return await post("api/analysisFilter/getTypes");
};

export const saveOrUpdate = async (request) => {
  return await post("api/analysisFilter/addOrUpdateFilter", request);
};

export const getAnalysisFilter = async (moduleId) => {
  return await get(
    `/api/analysisFilter/getListIdName?analysisFilterModuleId=${moduleId}`
  );
};

export const getAnalysisFilterById = async (EntityId) => {
  return await post("/api/analysisFilter/getForm", { EntityId });
};

export const deleteAnalysisFilter = async (EntityId) => {
  return await post("/api/analysisFilter/deleteFilter", { EntityId });
};

export const getReport = async (url) => {
  return await get(url);
};

export const getDateRange = async () => {
  return await post("/api/analysisFilter/getDateRanges");
};

export const getReportCategories = async (moduleId) => {
  return await get(
    `api/reporting/getReportCategories?analysisFilterModuleId=${moduleId}`
  );
};

export const getDateRangeUnits = async () => {
  return await post("api/analysisFilter/getDateRangeUnits");
};

export const getTenderDetailDescription = async (include, exclude) => {
  let params = "";
  if (include.length > 0) {
    params = `include=${include}`;
  }

  if (include.length > 0 && exclude.length > 0) {
    params = `${params}&`;
  }

  if (exclude.length > 0) {
    params = params.concat(`exclude=${exclude}`);
  }

  return await get(`/api/analysisFilter/getTenderDetailDescription?${params}`);
};

export const exportFilter = async (request) => {
  return await post("/api/ReportViewer/PostReportAction", request);
};

export const downloadReport = async (request) => {
  return await post("/api/reportwriter/export", request);
};

export const getOrganismsAll = async () => {
  return await get("/api/ClientUnitCompany/getClientUnits");
};

/// Organisms
export const getOrganisms = async (request) => {
  return await post("/api/analysisFilter/getClientUnitsByCompanyId", request);
};

/// Competitors
export const getCompetitors = async (request) => {
  return await post("/api/analysisfilter/getcompetitorsbycompanyid", request);
};
