import React, { useState } from "react";
import {
  Card,
  Paper,
  List,
  ListItem,
  CardHeader,
  CardContent,
  Divider,
} from "@material-ui/core";
import { useStyles } from "../../smart-search/manage-smart-search/style";
import { getTenderDetailDescription } from "../services/AnalysisService";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from "@material-ui/icons/Refresh";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpSharpIcon from "@material-ui/icons/KeyboardArrowUpSharp";
import KeyboardArrowDownSharpIcon from "@material-ui/icons/KeyboardArrowDownSharp";
import FstSpinnerLocal from "../../../components/spinner/FstSpinnerLocal";

export const ProductFilteredList = ({
  listInclude,
  listExclude,
  displayProductsFiltered,
  handleDisplayProductsFiltered,
  btnActIncExt,
}) => {
  const classes = useStyles();
  const [productFiltered, setProductFiltered] = useState([]);
  const [displaySpinner, setDisplaySpinner] = useState(false);

  const convertASrrayToString = (list) => {
    let str = "";
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      const strJoined = element.words.join("|");
      str = str.length > 0 ? ` ${str}@${strJoined}` : strJoined;
    }
    return str;
  };

  const search = async () => {
    setDisplaySpinner(true);
    const include = convertASrrayToString(listInclude);
    const exclude = convertASrrayToString(listExclude);
    const result = await getTenderDetailDescription(include, exclude);
    handleDisplayProductsFiltered(true);
    setProductFiltered(result.data);
    setDisplaySpinner(false);
  };

  return (
    <Card>
      <CardHeader
        className={classes.rootHeader}
        title={
          <>
            <Button
              color="primary"
              size="small"
              onClick={search}
              disabled={btnActIncExt ? true : displaySpinner}
              className={classes.button}
              startIcon={<RefreshIcon />}
            >
              Actualizar
            </Button>
            <Typography
              gutterBottom
              variant="h6"
              component="h6"
              className={classes.tipograghy}
            >
              Previsualización de productos filtrados
            </Typography>
          </>
        }
        action={
          <IconButton
            aria-label="delete"
            className={classes.btnPrdFilteredArrow}
            onClick={() =>
              handleDisplayProductsFiltered(!displayProductsFiltered)
            }
          >
            {displayProductsFiltered ? (
              <KeyboardArrowUpSharpIcon />
            ) : (
              <KeyboardArrowDownSharpIcon />
            )}
          </IconButton>
        }
      ></CardHeader>
      <Divider className={classes.divider} />
      {displayProductsFiltered && (
        <CardContent className={classes.cardContentProductList}>
          <FstSpinnerLocal active={displaySpinner} message="Cargando...">
            <Paper style={{ height: 240, overflow: "auto" }}>
              <List className="py-0">
                {productFiltered.map((item, index) => (
                  <div key={index}>
                    <ListItem className="d-flex justify-content-between align-items-center">
                      {item}
                    </ListItem>
                    <Divider />
                  </div>
                ))}
                {productFiltered.length === 0 && (
                  <div>
                    <ListItem
                      className={`d-flex justify-content-between align-items-center ${classes.contentList}`}
                    >
                      No se encontraron resultados para la busqueda realizada
                    </ListItem>
                    <Divider />
                  </div>
                )}
              </List>
            </Paper>
          </FstSpinnerLocal>
        </CardContent>
      )}
    </Card>
  );
};
