import { L10n, setCulture } from "@syncfusion/ej2-base";

L10n.load({
  "es-ES": {
    grid: {
      EmptyDataSourceError:
        "Origen de datos no debe ser vacío durante carga inicial",
      EmptyRecord: "No hay registros",
      GroupDropArea: "Arrastre una cabecera de columna aqui para agruparla",
      Item: "Registro",
      Items: "Registros",
      UnGroup: "Desagrupar esta columna",
    },
    pager: {
      currentPageInfo: "{0} de {1} paginas",
      firstPageTooltip: "Ir a primera pagina",
      lastPageTooltip: "Ir a ultima pagina",
      nextPageTooltip: "proxima pagina",
      pagerDropDown: "Registros por pagina",
      previousPageTooltip: "pagina anterior",
      totalItemsInfo: "({0} registros)",
    },
    schedule: {
      day: "Dia",
      week: "Week",
      workWeek: "Work Week",
      month: "Month",
      agenda: "Agenda",
      weekAgenda: "Week Agenda",
      workWeekAgenda: "Work Week Agenda",
      monthAgenda: "Month Agenda",
      today: "Today",
      noEvents: "No events",
      emptyContainer: "There are no events scheduled on this day.",
      allDay: "All day",
      start: "Start",
      end: "End",
      more: "more",
      close: "Close",
      cancel: "Cancel",
      noTitle: "(No Title)",
      delete: "Delete",
      deleteEvent: "Delete Event",
      deleteMultipleEvent: "Delete Multiple Events",
      selectedItems: "Items selected",
      deleteSeries: "Delete Series",
      edit: "Edit",
      editSeries: "Edit Series",
      editEvent: "Edit Event",
      createEvent: "Create",
      subject: "Subject",
      addTitle: "Add title",
      moreDetails: "More Details",
      save: "Save",
      editContent: "Do you want to edit only this event or entire series?",
      deleteRecurrenceContent:
        "Do you want to delete only this event or entire series?",
      deleteContent: "Are you sure you want to delete this event?",
      deleteMultipleContent:
        "Are you sure you want to delete the selected events?",
      newEvent: "New Event",
      title: "Title",
      location: "Location",
      description: "Description",
      timezone: "Timezone",
      startTimezone: "Start Timezone",
      endTimezone: "End Timezone",
      repeat: "Repeat",
      saveButton: "Save",
      cancelButton: "Cancel",
      deleteButton: "Delete",
      recurrence: "Recurrence",
      wrongPattern: "The recurrence pattern is not valid.",
      seriesChangeAlert:
        "The changes made to specific instances of this series will be cancelled and those events will match the series again.",
      createError:
        "The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.",
      recurrenceDateValidation:
        "Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.",
      sameDayAlert:
        "Two occurrences of the same event cannot occur on the same day.",
      editRecurrence: "Edit Recurrence",
      repeats: "Repeats",
      alert: "Alert",
      startEndError: "The selected end date occurs before the start date.",
      invalidDateError: "The entered date value is invalid.",
      ok: "Ok",
      occurrence: "Occurrence",
      series: "Series",
      previous: "Previous",
      next: "Next",
      timelineDay: "Timeline Day",
      timelineWeek: "Timeline Week",
      timelineWorkWeek: "Timeline Work Week",
      timelineMonth: "Timeline Month",
    },
  },
});

setCulture("es-ES");

export const materialTable = {
  es: {
    header: {
      actions: "Acciones",
    },
    body: {
      editRow: {
        deleteText: "¿Seguro que desea eliminar la entidad seleccionada?",
        cancelTooltip: "Cancelar",
        saveTooltip: "Guardar",
      },
    },
    pagination: {
      labelRowsSelect: "filas",
      firstAriaLabel: "Primera página",
      firstTooltip: "Primera página",
      previousAriaLabel: "Página anterior",
      previousTooltip: "Página anterior",
      nextAriaLabel: "Página siguiente",
      nextTooltip: "Página siguiente",
      lastAriaLabel: "Última página",
      lastTooltip: "Última página",
      labelDisplayedRows: "{from}-{to} de {count}",
    },
    toolbar: {
      searchPlaceholder: "Buscar",
      searchTooltip: "Buscar",
    },
  },
};
