export const Countries = [
  { id: "AE", value: "Emiratos Árabes Unidos" },
  { id: "AL", value: "Albania" },
  { id: "AR", value: "Argentina" },
  { id: "AT", value: "Austria" },
  { id: "AU", value: "Australia" },
  { id: "BA", value: "Bosnia y Hercegovina" },
  { id: "BE", value: "Bélgica" },
  { id: "BE", value: "Bélgica" },
  { id: "BG", value: "Bulgaria" },
  { id: "BH", value: "Bahráin" },
  { id: "BO", value: "Bolivia" },
  { id: "BR", value: "Brasil" },
  { id: "BY", value: "Bielorrusia" },
  { id: "CA", value: "Canadá" },
  { id: "CA", value: "Canadá" },
  { id: "CH", value: "Suiza" },
  { id: "CH", value: "Suiza" },
  { id: "CH", value: "Suiza" },
  { id: "CL", value: "Chile" },
  { id: "CN", value: "China" },
  { id: "CO", value: "Colombia" },
  { id: "CR", value: "Costa Rica" },
  { id: "CS", value: "Serbia y Montenegro" },
  { id: "CY", value: "Chipre" },
  { id: "CZ", value: "Chequia" },
  { id: "DE", value: "Alemania" },
  { id: "DK", value: "Dinamarca" },
  { id: "DO", value: "República Dominicana" },
  { id: "DZ", value: "Argelia" },
  { id: "EC", value: "Ecuador" },
  { id: "EE", value: "Estonia" },
  { id: "EG", value: "Egipto" },
  { id: "ES", value: "España" },
  { id: "ES", value: "España" },
  { id: "FI", value: "Finlandia" },
  { id: "FR", value: "Francia" },
  { id: "GB", value: "Reino Unido" },
  { id: "GR", value: "Grecia" },
  { id: "GT", value: "Guatemala" },
  { id: "HK", value: "Hong Kong" },
  { id: "HN", value: "Honduras" },
  { id: "HR", value: "Croacia" },
  { id: "HU", value: "Hungría" },
  { id: "ID", value: "Indonesia" },
  { id: "IE", value: "Irlanda" },
  { id: "IE", value: "Irlanda" },
  { id: "IL", value: "Israel" },
  { id: "IN", value: "India" },
  { id: "IN", value: "India" },
  { id: "IQ", value: "Iraq" },
  { id: "IS", value: "Islandia" },
  { id: "IT", value: "Italia" },
  { id: "JO", value: "Jordania" },
  { id: "JP", value: "Japón" },
  { id: "JP-JP", value: "Japón,JP" },
  { id: "KR", value: "Corea del Sur" },
  { id: "KW", value: "Kuwait" },
  { id: "LB", value: "Líbano" },
  { id: "LT", value: "Lituania" },
  { id: "LU", value: "Luxemburgo" },
  { id: "LU", value: "Luxemburgo" },
  { id: "LV", value: "Letonia" },
  { id: "LY", value: "Libia" },
  { id: "MA", value: "Marruecos" },
  { id: "ME", value: "Montenegro" },
  { id: "MK", value: "Macedonia" },
  { id: "MT", value: "Malta" },
  { id: "MT", value: "Malta" },
  { id: "MX", value: "México" },
  { id: "MY", value: "Malasia" },
  { id: "NI", value: "Nicaragua" },
  { id: "NL", value: "Holanda" },
  { id: "NO", value: "Noruega" },
  { id: "NO-NY", value: "Noruega,Nynorsk" },
  { id: "NZ", value: "Nueva Zelanda" },
  { id: "OM", value: "Omán" },
  { id: "PA", value: "Panamá" },
  { id: "PE", value: "Perú" },
  { id: "PH", value: "Filipinas" },
  { id: "PL", value: "Polonia" },
  { id: "PR", value: "Puerto Rico" },
  { id: "PT", value: "Portugal" },
  { id: "PY", value: "Paraguay" },
  { id: "QA", value: "Qatar" },
  { id: "RO", value: "Rumania" },
  { id: "RS", value: "Serbia" },
  { id: "RU", value: "Rusia" },
  { id: "SA", value: "Arabia Saudita" },
  { id: "SD", value: "Sudán" },
  { id: "SE", value: "Suecia" },
  { id: "SG", value: "Singapur" },
  { id: "SG", value: "Singapur" },
  { id: "SI", value: "Eslovenia" },
  { id: "SK", value: "Eslovaquia" },
  { id: "SV", value: "El Salvador" },
  { id: "SY", value: "Siria" },
  { id: "TH", value: "Tailandia" },
  { id: "TH_TH", value: "Tailandia,TH" },
  { id: "TN", value: "Túnez" },
  { id: "TR", value: "Turquía" },
  { id: "TW", value: "Taiwán" },
  { id: "UA", value: "Ucrania" },
  { id: "US", value: "Estados Unidos" },
  { id: "US", value: "Estados Unidos" },
  { id: "UY", value: "Uruguay" },
  { id: "VE", value: "Venezuela" },
  { id: "VN", value: "Vietnam" },
  { id: "YE", value: "Yemen" },
  { id: "ZA", value: "Sudáfrica" },
];

export const Idiom = [
  { id: "ar", value: "Árabe" },
  { id: "be", value: "Bielorruso" },
  { id: "bg", value: "Búlgaro" },
  { id: "ca", value: "Catalán" },
  { id: "cs", value: "Checo" },
  { id: "da", value: "Danés" },
  { id: "de", value: "Alemán" },
  { id: "el", value: "Griego" },
  { id: "en", value: "Inglés" },
  { id: "es", value: "Español" },
  { id: "et", value: "Estonio" },
  { id: "fi", value: "Finés" },
  { id: "fr", value: "Francés" },
  { id: "ga", value: "Irlandés" },
  { id: "hi", value: "Hindú" },
  { id: "hr", value: "Croata" },
  { id: "hu", value: "Húngaro" },
  { id: "in", value: "Indonesio" },
  { id: "is", value: "Islandés" },
  { id: "it", value: "Italiano" },
  { id: "iw", value: "Hebreo" },
  { id: "ja", value: "Japonés" },
  { id: "ko", value: "Coreano" },
  { id: "lt", value: "Lituano" },
  { id: "lv", value: "Letón" },
  { id: "mk", value: "Macedonio" },
  { id: "ms", value: "Malayo" },
  { id: "mt", value: "Maltés" },
  { id: "nl", value: "Neerlandés" },
  { id: "no", value: "Noruego" },
  { id: "pl", value: "Polaco" },
  { id: "pt", value: "Portugués" },
  { id: "ro", value: "Rumano" },
  { id: "ru", value: "Ruso" },
  { id: "sk", value: "Eslovaco" },
  { id: "sl", value: "Eslovenio" },
  { id: "sq", value: "Albanés" },
  { id: "sr", value: "Serbio" },
  { id: "sv", value: "Sueco" },
  { id: "th", value: "Tailandés" },
  { id: "tr", value: "Turco" },
  { id: "uk", value: "Ucranio" },
  { id: "vi", value: "Vietnamita" },
  { id: "zh", value: "Chino" },
];
