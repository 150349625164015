const amountKeysToIsPercentage = {
  ParticipationAmmount: "ParticipationIsPercentage",
  ComplianceAmmount: "ComplianceIsPercentage",
  ColateralAmmount: "ColateralIsPercentage",
};
export default {
  StructureDataWarrantyCR: {
    state: {
      keys: [
        "Reception",
        "Participation",
        "ParticipationAmmount",
        "ParticipationValidity",
        "Compliance",
        "ComplianceAmmount",
        "ComplianceValidity",
        "Colateral",
        "ColateralAmmount",
        "ColateralValidity",
      ],
      structure: {
        title: "Recepción de garantías",
        description: "_Reception",
        data: [
          {
            type: "Garantía de participación",
            aplica: "_Participation",
            monto: "_ParticipationAmmount",
            vigencia: "_ParticipationValidity",
          },
          {
            type: "Garantía de cumplimiento",
            aplica: "_Compliance",
            monto: "_ComplianceAmmount",
            vigencia: "_ComplianceValidity",
          },
          {
            type: "Colateral",
            aplica: "_Colateral",
            monto: "_ColateralAmmount",
            vigencia: "_ColateralValidity",
          },
        ],
      },
    },
    render: (state) => {
      const { keys, structure, data } = state;
      let newData = JSON.stringify(structure);
      keys.map((key) => {
        if (!data.hasOwnProperty(key)) data[key] = "";
        // Handle IsPercentage
        if (
          data[key] &&
          amountKeysToIsPercentage.hasOwnProperty(key) &&
          data[amountKeysToIsPercentage[key]] === true
        )
          data[key] = `${data[key]} %`;

        const rex = new RegExp("_" + key + "\\b", "g");
        newData = newData.replaceAll(rex, data[key]).replaceAll(/0\b/g, "");
      });
      return JSON.parse(newData);
    },
  },
};
