import {
  Button,
  CardActions,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ReplayIcon from "@material-ui/icons/Replay";
import CloseIcon from "@material-ui/icons/Close";
import SaveSharpIcon from "@material-ui/icons/SaveSharp";
import { Alert } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Prompt, withRouter } from "react-router";
import * as PendingSaveActions from "../../actions/pendingSaveActions";
import FstModalConfirm from "../../components/modal/FstModalConfirm";
import { useStyles } from "./style";

/* Props: 
    - isDataModified: bool
    - areAllSelected: bool
    - handleSelectAll: func
    - handleSave: func
    - headerTitle: str
*/
function ABMHeaderActions(props) {
  const classes = useStyles();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [openToaster, setOpenToaster] = useState(false);
  const [confirmedLeavePage, setConfirmedLeavePage] = useState(false);

  const handleDiscard = () => {
    setConfirmOpen(true);
  };

  const discardChanges = () => {
    setConfirmOpen(false);
    props.reloadPage();
  };

  const showToaster = () => {
    setOpenToaster(true);
    setTimeout(() => {
      setOpenToaster(false);
    }, 3000);
  };

  const handleSave = () => {
    setConfirmOpen(false);
    props.handleSave();
    showToaster();
  };

  /// Leave page logic
  const handleLeavePage = (pathData) => {
    if (!confirmedLeavePage && props.isDataModified) {
      props.OpenAlertPending();
      props.LinkRedirect(pathData.pathname);
      return false;
    }
    return true;
  };

  const handleClose = () => {
    setConfirmedLeavePage(true);
    props.CloseAlertPending();
  };

  useEffect(() => {
    if (confirmedLeavePage) props.history.push(props.toRedirect);
  }, [confirmedLeavePage]);
  ///

  return (
    <>
      <CardActions className={classes.rootHeader}>
        <Typography
          gutterBottom
          variant="h6"
          component="h6"
          className={classes.headerTypography}
        >
          {props.headerTitle}
        </Typography>
        <span className={classes.buttonsPositions}>
          <Tooltip id="save-button" title="Guardar cambios">
            <span>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSave}
                disabled={!props.isDataModified}
                endIcon={<SaveSharpIcon />}
                style={{ marginRight: "10px" }}
              >
                Guardar
              </Button>
            </span>
          </Tooltip>
          <Tooltip id="close-button" title="Descartar cambios">
            <span>
              <Button
                color="secondary"
                variant="outlined"
                onClick={handleDiscard}
                disabled={!props.isDataModified}
                endIcon={<ReplayIcon />}
              >
                Descartar
              </Button>
            </span>
          </Tooltip>
        </span>
      </CardActions>
      <FstModalConfirm
        title="Descartar Cambios"
        close={() => {
          setConfirmOpen(false);
        }}
        open={confirmOpen}
        message={"¿Esta seguro que desea descartar los cambios?"}
        success={discardChanges}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={openToaster}
      >
        <Alert severity="success">Los cambios han sido confirmados</Alert>
      </Snackbar>
      <Prompt when={props.isDataModified} message={handleLeavePage} />
      <FstModalConfirm
        title="Aviso"
        close={handleClose}
        open={props.alertActive}
        setting={{ BtnNameSave: "Aceptar" }}
        message={
          "Tiene cambios pendientes sin guardar. Presione ACEPTAR para permanecer en esta vista y guardar, o CANCELAR para descartarlos."
        }
        success={() => props.CloseAlertPending()}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  const { alertPendingSaveReducer } = state;
  const { pendingActive, alertActive, toRedirect } = alertPendingSaveReducer;
  return {
    ...state,
    pendingActive,
    alertActive,
    toRedirect,
  };
};

export default connect(mapStateToProps, {
  ...PendingSaveActions,
})(withRouter(ABMHeaderActions));
