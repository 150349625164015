import React, { Node } from "react";
import MaterialTable from "material-table";
import { materialTable as localization } from "../../config/localization";
import { getAllTendersData } from "../../actions/tenderActions";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { dateTimeFormat, getMaterialTableCell } from "../../utils/common";
import { StylePage } from "../../style/contentStyle";

const ListAvailableTenders = withRouter(({ history }: { history: Node }) => {
  const classes = StylePage();
  let tableRef = React.createRef();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function getColumns() {
    const padding = { padding: "4px" };
    const centered = { textAlign: "center" };
    const maxSpaceStyle = {
      textAlign: "left",
      maxWidth: "30em",
      wordWrap: "break-word",
      paddingTop: "0.5rem",
      paddingRight: "0px",
      paddingBottom: "0.5rem",
      paddingLeft: "0px",
    };

    const result = [];
    result.push(
      getMaterialTableCell({
        title: "Publicación",
        field: "publishedDate",
        cellStyle: { ...padding },
        singleLineEllipsis: true,
        render: (rowData) =>
          moment(rowData["publishedDate"]).format(dateTimeFormat),
      })
    );
    result.push(
      getMaterialTableCell({ title: "Convocatoria", field: "tenderNumber" })
    );
    result.push(
      getMaterialTableCell({
        title: "Nomenclatura",
        field: "fileNumber",
        cellStyle: { maxWidth: "20em", ...padding, ...centered },
        singleLineEllipsis: true,
      })
    );
    result.push(
      getMaterialTableCell({
        title: "Nombre",
        field: "tenderName",
        rawTooltip: true,
        cellStyle: maxSpaceStyle,
        singleLineEllipsis: true,
        textAlign: "left",
      })
    );
    result.push(
      getMaterialTableCell({
        title: "O.Contratación",
        field: "tenderDescription",
        singleLineEllipsis: true,
        cellStyle: { ...padding, ...centered },
      })
    );
    result.push(
      getMaterialTableCell({
        title: "Estimado/Referencial",
        field: "estimatedValue",
      })
    );
    result.push(
      getMaterialTableCell({
        title: "Moneda",
        field: "currency",
        singleLineEllipsis: true,
        cellStyle: { ...padding, ...centered },
      })
    );
    result.push(
      getMaterialTableCell({
        title: "Estado",
        field: "tenderStatusDesc",
        singleLineEllipsis: true,
        cellStyle: { ...padding, ...centered },
      })
    );
    result.push(
      getMaterialTableCell({
        title: "Etapa",
        field: "tenderCurrentStage",
        singleLineEllipsis: true,
        cellStyle: {},
      })
    );
    return result;
  }

  return (
    <div className={classes.content}>
      <MaterialTable
        tableRef={tableRef}
        title="Licitaciones"
        columns={getColumns()}
        options={{
          debounceInterval: 400,
          pageSize: rowsPerPage,
          pageSizeOptions: [10, 20, 30],
          headerStyle: { backgroundColor: "#01579e", color: "#FFF" },
          exportButton: true,
          exportAllData: true,
          padding: "dense",
        }}
        onChangeRowsPerPage={(newPageSize) => setRowsPerPage(newPageSize)}
        onRowClick={(e, row) =>
          history.push("/informacion/" + row.tenderNumber)
        }
        data={(query) =>
          new Promise(async (resolve) => {
            const { orderBy, orderDirection, page, pageSize, search } = query;
            const data = await getAllTendersData(
              orderBy,
              orderDirection,
              page,
              pageSize,
              search
            );

            if (!!data) {
              resolve({
                data: data.data,
                page: data.page,
                totalCount: data.totalCount,
              });
            }
          })
        }
        localization={localization["es"]}
      />
    </div>
  );
});

export default ListAvailableTenders;
