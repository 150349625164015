import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import { connect } from "react-redux";
import { logout } from "../../../utils/activeDirectory";

export const SERVER_HOSTNAME = process.env.REACT_APP_SERVER_HOSTNAME;
export const SERVER_PORT = process.env.REACT_APP_API_PORT;

const TenderAnalysisReport = (props) => {
  const [url, setUrl] = useState();
  const [height, setHeight] = useState();

  const handleResize = () => {
    const padding = 100;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    setHeight(rest);
  };

  const handleUrl = async () => {
    if (isComplete()) {
      const aux = `${SERVER_HOSTNAME}:${SERVER_PORT}/api/Reporting?ReportName=${props.ReportName}&AnalysisFilterId=${props.AnalysisFilterId}`;
      setUrl(aux);
    } else {
      setUrl(null);
    }
  };

  useEffect(() => {
    setUrl(null);
    window.addEventListener("resize", handleResize);
    handleResize();
    handleUrl();

    return () => window.removeEventListener("resize", handleResize);
  }, [props.refresh]);

  const isComplete = () => {
    return props.AnalysisFilterId && props.ReportName;
  };

  const handleLoad = () => {
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === "attachEvent" ? "onmessage" : "message";

    eventer(messageEvent, function (e) {
      if (e.data.hasOwnProperty("message")) {
        const message = e.data.message;
        if (message.error.indexOf("Token expired") > -1) {
          logout();
        }
      }
    });
  };

  return (
    <div>
      {isComplete() && (
        <>
          <Iframe
            url={url}
            width="100%"
            height={height}
            id="myiFrame"
            key={props.refresh}
            className="iframe_class"
            display="initial"
            position="relative"
            name={props.auth}
            onLoad={handleLoad}
          />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (reducer) => {
  return reducer.analysisReportReducer;
};

export default connect(mapStateToProps, {})(TenderAnalysisReport);
