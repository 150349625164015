import React, { useEffect, useState } from "react";
import { FormattedForm } from "../../intl/utils/format-elements/formattedForm";
import useHandleDataByLang from "../../intl/utils/hooks/useHandleDataByLang";

export default function ContractConditions({ data }) {
  const [getData, setData] = useState([]);
  const [runInit, setRunInit] = useState(false);
  const handelData = useHandleDataByLang("TenderContainer", "Tab", data);

  const initData = () => {
    const result = handelData.getDataByCountry("tab.Condiciones");
    setData([result]);
    setRunInit(true);
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      initData();
    });
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <React.Fragment>
      {runInit && <FormattedForm id={"TabContractConditions"} data={getData} />}
    </React.Fragment>
  );
}
