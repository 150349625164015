import axios from "axios";

export const defaultTenderInformation = {
  tenderStages: [
    {
      tenderStageType: "",
      startDate: "",
      endDate: "",
    },
  ],
  tenderDetails: [
    {
      description: "",
      code: "",
      qty: "",
      reserve: "",
      package: "",
      missing: "Unmapped",
      estimatedValue: "",
      status: "",
      tenderDetailAwardeds: [
        {
          competitorName: "",
          missing: "Unmapped",
          pomotionalLaw: "",
          bonification: "",
          qty: "",
          ammount: "",
        },
      ],
    },
  ],
  tenderActivities: [
    {
      number: "",
      name: "",
      publishedDate: "",
      description: "",
      missing: "Unmapped",
    },
  ],
  tenderDocuments: [
    {
      number: "",
      stageName: "",
      name: "",
      link: "",
      publishedDate: "",
      missing: "Unmapped",
    },
  ],
  tenderQuestions: [],
  tenderSupervisions: [],
  fileNumber: "",
  callNumber: "",
  tenderTypeDesc: "",
  tenderNumber: "",
  missing: "Unmapped",
  clientUnitName: "",
  clientUnitUniqueId: "",
  tenderDescription: "",
  tenderName: "",
  publishedDate: "",
  estimatedValue: "",
};

export const getTenderInformation = async (tenderIdStr) => {
  const defaultResponse = defaultTenderInformation;
  const payload = { tenderNumber: decodeURIComponent(tenderIdStr) };
  try {
    const axiosResult = await axios.post("/api/Tender/GetTenderData", payload);
    if (!!axiosResult && !!axiosResult.data) {
      return {
        data: axiosResult.data,
      };
    } else {
      defaultResponse.error = `Error getting tender information ${payload}`;
      return defaultResponse;
    }
  } catch (error) {
    console.log({ error });
    defaultResponse.error = error;
    return defaultResponse;
  }
};
