import { post } from "../../../config/axios.js";

export const GetBankList = async (request) => {
  return await post("/api/Bank/ListBanks", request);
};

export const getSellers = async () => {
  const request = {
    Filter: "",
    OrderBy: "email",
    OrderAsc: false,
  };
  return await post("/api/Seller/ListSellers", request);
};

export const listBanks = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
  };
  return await post("/api/Bank/ListBanks", request);
};

export const addOrUpdateBank = async (request) => {
  return await post("/api/Bank/AddOrUpdateBank", request);
};

export const deleteBank = async (request) => {
  return await post("/api/Bank/DeleteBanks", request);
};
