import { Tooltip, Typography } from "@material-ui/core";
import FstAutocompletePaging from "components/forms/autocomplete/FstAutocompletePaging";
import React, { useEffect, useState } from "react";
import { getProducts } from "views/product/service/productService";

export default function ProductsAutoComplete(props) {
  const { onChangeProduct, args, ...rest } = props;

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const request = {
      idOrder: 0,
      pageNumber: page,
      pageSize: 100,
      filter: searchQuery,
      orderAsc: true,
      orderBy: "",
    };

    const response = await getProducts(request);
    const responseJSON = await response.data.currentPageItems;

    return {
      options: responseJSON,
      hasMore: responseJSON.length >= 1,
      additional: {
        page: response.data.currentPage,
        totalItems: response.data.totalItems,
        totalPages: response.data.totalPages,
      },
    };
  };

  const [productSelected, setProductSelected] = useState("");

  const handleProductSelected = (event, value) => {
    setProductSelected(value);
    if (!value) {
      value = {
        productCode: null,
        productDesc: null,
        productId: null,
        productData: null,
      };
    }
    onChangeProduct(value, args);
  };

  useEffect(() => {
    setProductSelected({
      productCode: args.productCode,
      productDesc: args.productDesc,
      productId: args.productId,
      productData: args.productData,
      createdBy: null,
      disposable: null,
      insertedDate: null,
      productBarCode: null,
      updatedBy: null,
      updatedDate: null,
    });
  }, []);

  return (
    <FstAutocompletePaging
      name="productSelected"
      label="Seleccione"
      loadOptions={loadOptions}
      value={productSelected}
      optionsLabel={(option) => {
        if (!option || (!option.productCode && !option.productDesc)) return "";
        return `(${option.productCode}) - ${option.productDesc}${
          option.data
            ? ` - ${Object.values(JSON.parse(option.data)).join(" - ")}`
            : ""
        }`;
      }}
      renderOption={(option) => {
        let renderString = `(${option.productCode}) - ${option.productDesc}${
          option.data
            ? ` - ${Object.values(JSON.parse(option.data)).join(" - ")}`
            : ""
        }`;
        return (
          <Tooltip title={renderString} placement="top">
            <Typography noWrap>{renderString}</Typography>
          </Tooltip>
        );
      }}
      onChange={handleProductSelected}
      textFieldSize="small"
      {...rest}
    />
  );
}
