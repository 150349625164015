import { ProductType } from "../types";

const INITIAL_STATE = {
  result: null,
  reloadDefault: false,
  error: null,
  count: 0,
  page: 0,
  pageSize: 0,
  product: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductType.ALL:
      return {
        ...state,
        result: action.payload,
        error: null,
        count: action.payload.result.count,
        page: action.page,
        pageSize: action.pageSize,
        reloadDefault: action.reloadDefault,
      };
    case ProductType.ERROR:
      return {
        ...state,
        result: null,
        error: action.error,
      };
    case ProductType.DEFAULT:
      return {
        ...state,
        reloadDefault: action.reloadDefault,
      };
    default:
      return state;
  }
};
