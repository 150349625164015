import { TenderPanel } from "../../types";
export default [
  {
    subheader: TenderPanel.SEACE,
    isDefault: true,
    items: [
      {
        etq_title: TenderPanel.GENERAL,
        title: TenderPanel.GENERAL,
        titlePanel: "Información General del Procedimiento",
        href: "/general",
        showActionPanel: false,
        isDefault: true,
        idLang: "tab.General",
        showExport: true,
        showExportAll: true,
        langForm: [
          "TabGeneralInfoFirst",
          "TabGeneralInfoSecond",
          "TabGeneralInfoLast",
        ],
      },
      {
        etq_title: TenderPanel.SCHEDULER,
        title: TenderPanel.SCHEDULER,
        titlePanel: "Cronograma",
        href: "/cronograma",
        showActionPanel: false,
        isDefault: true,
        idLang: "tab.Cronograma",
        showExport: true,
        showExportAll: true,
        langForm: ["TabSchedule"],
      },
      {
        etq_title: TenderPanel.ITEMS_LIST,
        title: TenderPanel.ITEMS_LIST,
        titlePanel: "Listado de Items",
        idLang: "tab.ListaItems",
      },
      {
        etq_title: TenderPanel.DOCUMENTOS,
        title: TenderPanel.DOCUMENTOS,
        titlePanel: "Documento del Procedimiento",
        idLang: "tab.Documentos",
      },
      {
        etq_title: TenderPanel.ACCIONES,
        title: TenderPanel.ACCIONES,
        titlePanel: "Acciones Generales del Procedimiento",
        idLang: "tab.Acciones",
      },
      {
        etq_title: TenderPanel.OFICIOS,
        title: TenderPanel.OFICIOS,
        titlePanel: "Oficios de Supervision",
        idLang: "tab.Oficios",
      },
      {
        etq_title: TenderPanel.REQUISITOS,
        title: TenderPanel.REQUISITOS,
        titlePanel: "Requisitos",
        idLang: "tab.Requisitos",
      },
      {
        etq_title: TenderPanel.GARANTIAS,
        title: TenderPanel.GARANTIAS,
        titlePanel: "Garantías",
        idLang: "tab.Garantias",
      },
      {
        etq_title: TenderPanel.OFERTAS,
        title: TenderPanel.OFERTAS,
        titlePanel: "Ofertas",
        idLang: "tab.Ofertas",
      },
      {
        etq_title: TenderPanel.CONDICIONES,
        title: TenderPanel.CONDICIONES,
        titlePanel: "Condiciones Contrato",
        idLang: "tab.Condiciones",
      },
      {
        etq_title: TenderPanel.ENTREGA,
        title: TenderPanel.ENTREGA,
        titlePanel: "Entrega",
        //showActionPanel: true,
        idLang: "tab.Entrega",
        showExport: true,
        showExportAll: true,
        langForm: ["TabDelivery"],
      },
      {
        etq_title: TenderPanel.FUNCIONARIOS,
        title: TenderPanel.FUNCIONARIOS,
        titlePanel: "Funcionarios relacionados",
        //showActionPanel: true,
        idLang: "tab.Funcionarios",
      },
      {
        etq_title: TenderPanel.CONTRATOS,
        title: TenderPanel.CONTRATOS,
        titlePanel: "Contratos",
        showActionPanel: true,
        idLang: "tab.Contratos",
      },
      {
        etq_title: TenderPanel.ORDENES_PEDIDO,
        title: TenderPanel.ORDENES_PEDIDO,
        titlePanel: "Ordenes de Pedido",
        showActionPanel: true,
        idLang: "tab.OrdenesPedido",
      },
    ],
  },
  {
    subheader: TenderPanel.GESTION,
    items: [
      {
        etq_title: TenderPanel.ENTIDADES,
        title: TenderPanel.ENTIDADES,
        titlePanel: "Asignaciones",
        href: "/entidades",
        showActionPanel: true,
        isDefault: true,
        idLang: "tab.Asignaciones",
      },
      {
        etq_title: TenderPanel.ITEMS_ALL,
        title: TenderPanel.ITEMS_ALL,
        titlePanel: "Gestión de items",
        showActionPanel: true,
        idLang: "tab.ListaItems",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.TICKET,
        title: TenderPanel.TICKET,
        titlePanel: "Cartas Fianza",
        showActionPanel: true,
        idLang: "tab.CartasFianza",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.COMMENTS,
        title: TenderPanel.COMMENTS,
        titlePanel: "Comentarios",
        showActionPanel: true,
        idLang: "tab.Comentarios",
      },
      {
        etq_title: TenderPanel.SANCTIONS,
        title: TenderPanel.SANCTIONS,
        titlePanel: "Sanciones",
        showActionPanel: true,
        idLang: "tab.Sanciones",
        showExportAll: true,
      },
      {
        etq_title: TenderPanel.CHECKLIST,
        title: TenderPanel.CHECKLIST,
        titlePanel: "Checklist",
        showActionPanel: true,
        idLang: "tab.Checklist",
        showExportAll: true,
      },
    ],
  },
];
