import React from "react";
import moment from "moment";
import { dateTimeFormat } from "../../../utils/common";

export const Headers = [
  // {
  //     field: 'highlightItemNumber',
  //     headerText: '',
  //     width: '70',
  //     textAlign: 'Center',
  //     allowSorting: true,
  //     template: (props) => {
  //         return (

  //             <Switch
  //                 size="small"
  //                 checked={true}
  //                 name="checkedB"
  //                 color="primary"
  //             />

  //         )}
  // },
  {
    id: "cols.Filtro",
    field: "searchFilterName",
    headerText: "Filtro",
    width: "150",
    textAlign: "Left",
    allowSorting: true,
    showInColumnChooser: false,
  },
  {
    id: "cols.Id",
    field: "tenderNumber",
    headerText: "Id",
    width: "100",
    textAlign: "Center",
    allowSorting: true,
  },
  {
    id: "cols.Numenclatura",
    field: "fileNumber",
    headerText: "Nomenclatura",
    width: "250",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Nombre",
    field: "tenderName",
    headerText: "Nombre",
    width: "200",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Modalidad",
    field: "modalityType",
    headerText: "Modalidad",
    width: "180",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Descripcion",
    field: "tenderDescription",
    headerText: "Descripción",
    width: "150",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Region",
    field: "regionName",
    headerText: "Región",
    width: "120",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Portal",
    field: "sourceName",
    headerText: "Portal",
    width: "100",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Entidad",
    field: "clientUnitName",
    headerText: "Entidad",
    width: "200",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.ClientName", // Used exclusively in AR. "Servicio Administrativo Financiero"
    field: "clientName",
    headerText: "Client Name",
    width: "400",
    allowSorting: true,
    textAlign: "Left",
  },
  {
    id: "cols.Publicacion",
    field: "publishedDate",
    headerText: "Publicación",
    width: "150",
    textAlign: "Center",
    allowSorting: true,
    template: (props) => (
      <span>{moment(props.publishedDate).format(dateTimeFormat)}</span>
    ),
  },
  {
    id: "cols.Cierre",
    field: "endDate",
    headerText: "Cierre",
    width: "150",
    textAlign: "Center",
    allowSorting: true,
    template: (props) =>
      props.endDate ? (
        <span>{moment(props.endDate).format(dateTimeFormat)}</span>
      ) : (
        ""
      ),
  },
  {
    id: "cols.Item",
    field: "itemNumber",
    headerText: "Item",
    width: "100",
    textAlign: "Center",
    allowSorting: true,
  },
  {
    id: "cols.Desc",
    field: "description",
    headerText: "Desc. Producto/Servicio",
    width: "300",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Observaciones",
    field: "observation",
    headerText: "Observaciones",
    width: "250",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Especificaciones",
    field: "specification",
    headerText: "Especificaciones",
    width: "250",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Acondicionamiento",
    field: "conditioning",
    headerText: "Acondicionamiento",
    width: "250",
    textAlign: "Left",
    allowSorting: true,
  },
  {
    id: "cols.Cantidad",
    field: "qty",
    headerText: "Cantidad",
    width: "120",
    textAlign: "Center",
    allowSorting: true,
  },
  {
    id: "cols.Unidad",
    field: "uom",
    headerText: "Unidad de medida",
    width: "160",
    textAlign: "Center",
    allowSorting: true,
  },
  {
    id: "cols.Moneda",
    field: "currency",
    headerText: "Moneda",
    width: "160",
    textAlign: "Center",
    allowSorting: true,
  },
  {
    id: "cols.GestionadoPor",
    field: "userManage",
    headerText: "Gestionado por",
    width: "150",
    textAlign: "Center",
    allowSorting: true,
  },
  {
    id: "cols.GestionadoEn",
    field: "dateCreationManage",
    headerText: "Gestionado en ",
    width: "150",
    textAlign: "Center",
    allowSorting: true,
    template: (props) =>
      props.dateCreationManage ? (
        <span>{moment(props.dateCreationManage).format(dateTimeFormat)}</span>
      ) : (
        ""
      ),
  },
  {
    id: "cols.DestacadoPor",
    field: "userHighlightName",
    headerText: "Destacado por",
    width: "150",
    textAlign: "Center",
    allowSorting: true,
  },
  {
    id: "cols.DestacadoEn",
    field: "highlightedDate",
    headerText: "Destacado en",
    width: "150",
    textAlign: "Center",
    allowSorting: true,
    template: (props) =>
      props.highlightedDate ? (
        <span>{moment(props.highlightedDate).format(dateTimeFormat)}</span>
      ) : (
        ""
      ),
  },
  {
    id: "cols.VistoPor",
    field: "userViewedName",
    headerText: "Visto por",
    width: "150",
    textAlign: "Center",
    allowSorting: true,
  },
  {
    id: "cols.VistoEn",
    field: "viewedDate",
    headerText: "Visto en",
    width: "150",
    textAlign: "Center",
    allowSorting: true,
    template: (props) =>
      props.viewedDate ? (
        <span>{moment(props.viewedDate).format(dateTimeFormat)}</span>
      ) : (
        ""
      ),
  },
];

export const ColumnsDates = [
  {
    field: "publishedDate",
    format: dateTimeFormat,
  },
  {
    field: "endDate",
    format: dateTimeFormat,
  },
];
