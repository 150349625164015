import { post, get } from "../../../config/axios.js";

export const SendtenderMail = async (request) => {
  return await post("/api/Notification/SendTenderMail", request);
};

export const SendtenderMailIds = async () => {
  return await get("/api/notification/getsenttenderids");
};

export const GetSendTendersList = async () => {
  return await get("/api/notification/getsenttenders");
};

export const GetEmailData = async (id) => {
  return await get(`/api/notification/getemaildata/${id}`);
};
