import { Tooltip, withWidth } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useAppInsightsContext, useTrackMetric } from "@microsoft/applicationinsights-react-js";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { StylePage } from "../../style/contentStyle";
import SmartSearchList from "../smart-search/smart-search-list/SmartSearchList";
import ManagePacFilter from "./ManagePacFilter";
import ManagePacList from "./ManagePacList";

const ManagePac = ({ width }) => {
  const classes = StylePage();
  const [listFilter, setListFilter] = useState([]);
  const [listFilterApplay, setListFilterApplay] = useState([]);
  const [updateList, setUpdateList] = useState(moment().format());
  const [panel, setPanel] = useState(10);
  const [panelFiltro, setPanelFiltro] = useState();
  const appInsights = useAppInsightsContext();
  const trackComponentMetric = useTrackMetric(appInsights, "PAC: Time on page");
  useEffect(() => {
    trackComponentMetric();
    return () => {
        trackComponentMetric();
    };
  }, []);

  const views = {
    FILTERS: "FILTERS",
    MANAGE: "MANAGE",
  };
  const [height, setHeight] = useState();
  const [view, setView] = useState("FILTERS");
  const [filterPanelOpenBelowmd, setFilterPanelOpenBelowmd] = useState(true);

  const handleResize = useCallback(() => {
    const padding = 130;
    const header = document.getElementById("main-header").clientHeight;
    const rest = window.innerHeight - (header + padding);
    restWidth();
    setHeight(rest);
  }, []);

  const handleUpdateList = () => {
    setUpdateList(moment().format());
  };

  const handleListFilter = (list, ss) => {
    const originLint = [...listFilter];
    for (let index = 0; index < list.length; index++) {
      const originIndex = originLint.findIndex(
        (item) => item.searchFilterId === list[index].searchFilterId
      );
      if (originIndex >= 0) {
        list[index].checked = originLint[originIndex].checked;
      }
    }
    const updateSSChecked =
      list.filter(
        (item) =>
          item.checked && ss && item.searchFilterId === ss.searchFilterId
      ).length > 0;
    if (!ss || updateSSChecked) {
      setListFilterApplay(list);
    }
    setListFilter(list);
  };

  const handleListAll = (list) => {
    setListFilter(list);
    setListFilterApplay(list);
  };

  const handleView = () => {
    setView(views.MANAGE);
  };

  const viewFiltersPac = () => {
    setView(views.FILTERS);
    restWidth();
  };

  const restWidth = () => {
    const width = window.screen.width;
    setPanel(width > 1600 ? 10 : 9);
    setPanelFiltro(width > 1600 ? 2 : 3);
  };

  useEffect(() => {
    if (!(width === "lg" || width === "xl")) {
      setFilterPanelOpenBelowmd(false);
    } else if (width === "lg" || width === "xl") {
      setFilterPanelOpenBelowmd(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  useEffect(() => {
    let timer = setTimeout(() => {
      window.addEventListener("resize", handleResize);
      handleResize();
    });
    return () => {
      clearTimeout(timer);
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const closeFilterPanel = () => {
    setFilterPanelOpenBelowmd(false);
  };

  /* Styles with the filter panel condition are required to show correctly
     the closed panel and a button to open it without rerendering the grid
     when the screen is resized. Also allows to place the button correctly
     and take advantage of the grid system to show the SS grid.
     When the screen is resized to a size smaller than md, the filter panel
     is closed and the button is placed in the grid. */
  const useFilterButtonConfig = makeStyles((theme) => ({
    gridButtonDisplay: { maxWidth: "50px" },
    gridFilterDisplay: { minWidth: "225px", maxWidth: "285px" },
    buttonDisplay: {
      maxWidth: "30px",
      display: "flex",
      alignItems: "start",
      justifyContent: "start",
    },
    displayNone: { display: "none" },
    emptyStyle: {},
  }));

  const filterButtonConfig = useFilterButtonConfig();

  return (
    <>
      {height && (
        <>
          <Grid container className={classes.content} spacing={2}>
            {view === views.FILTERS && (
              <Grid
                item
                xs
                className={
                  filterPanelOpenBelowmd === false
                    ? filterButtonConfig.gridButtonDisplay
                    : filterButtonConfig.gridFilterDisplay
                }
              >
                <div
                  className={
                    filterPanelOpenBelowmd === false
                      ? filterButtonConfig.buttonDisplay
                      : filterButtonConfig.displayNone
                  }
                >
                  <Tooltip title="Mostrar" placement="bottom">
                    <IconButton
                      color="inherit"
                      aria-label="open filterpanel"
                      edge="start"
                      onClick={() => setFilterPanelOpenBelowmd(true)}
                    >
                      {<FilterListIcon />}
                    </IconButton>
                  </Tooltip>
                </div>
                <div
                  className={
                    filterPanelOpenBelowmd === false
                      ? filterButtonConfig.displayNone
                      : filterButtonConfig.emptyStyle
                  }
                >
                  <ManagePacFilter
                    height={height}
                    list={listFilter}
                    updateList={updateList}
                    handleList={handleListFilter}
                    handleListAll={handleListAll}
                    handleView={handleView}
                    handleUpdateList={handleUpdateList}
                    max={360}
                    handlePanelHide={closeFilterPanel}
                  />
                </div>
              </Grid>
            )}
            <Grid item xs>
              {view === views.FILTERS ? (
                <ManagePacList
                  height={height}
                  listFilter={listFilterApplay}
                  styles={{ display: "grid" }}
                />
              ) : (
                <SmartSearchList
                  height={height}
                  handleUpdateList={handleUpdateList}
                  viewFilters={viewFiltersPac}
                />
              )}
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default withWidth()(ManagePac);
