import { post } from "../../../config/axios.js";

export const listDeleteReasons = async (pagination) => {
  return await post("/api/deletereason/ListDeleteReasons", {});
};

export const addOrUpdateDeleteReason = async (request) => {
  return await post("/api/deletereason/addorupdatedeletereason", request);
};

export const deleteDeleteReason = async (request) => {
  return await post("/api/deletereason/deletedeletereason", request);
};
