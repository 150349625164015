import React from "react";
import { Grid, Typography, TextField, MenuItem } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import FstInputNumeric from "components/forms/input-numeric/FstInputNumeric";
import ProductsAutoComplete from "views/tender-information/all-details/ProductsAutoComplete";

const OutsiderTendersFormComponent = ({
  outsiderValue,
  onChange,
  index,
  indexTopElem = null,
  indexAux = null,
}) => {
  const onChangeProductAutoComplete = (value) => {
    onChange(
      { target: { value: value.productCode } },
      index,
      indexTopElem,
      indexAux,
      outsiderValue,
      null,
    );
  };

  return (
    <Grid item xs={12} sm={6} key={index}>
      {outsiderValue === null ? (
        <div style={{ width: 300 }} />
      ) : (
        <Grid
          container
          spacing={4}
          direction="row"
          style={{
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid item style={{ width: 250 }}>
            <Typography style={{ fontSize: 14 }} variant="h6">
              {outsiderValue.columnName}
              {outsiderValue.required && (
                <span style={{ color: "red" }}> *</span>
              )}
              {(outsiderValue.columnValue === "tenderNumber" ||
                outsiderValue.columnValue === "clientUnitUniqueId" ||
                outsiderValue.columnValue === "clientUnitName") && (
                <span style={{ color: "red" }}>*</span>
              )}
            </Typography>
          </Grid>
          <Grid item style={{ minWidth: 300 }}>
            {outsiderValue.type === "select" && (
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                required={outsiderValue.required}
                value={outsiderValue.inputValue}
                select
                onChange={(e) =>
                  onChange(e, index, indexTopElem, indexAux, outsiderValue)
                }
              >
                {outsiderValue.options.map((option, idx) => (
                  <MenuItem key={idx} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
            {outsiderValue.type === "date" && (
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  fullWidth
                  InputProps={{ readOnly: true }}
                  inputVariant="outlined"
                  size="small"
                  required={outsiderValue.required}
                  invalidDateMessage="Fecha inválida"
                  format="DD/MM/yyyy HH:mm"
                  value={outsiderValue.inputValue || null}
                  onChange={(e, newValue) =>
                    onChange(
                      e,
                      index,
                      indexTopElem,
                      indexAux,
                      outsiderValue,
                      newValue,
                    )
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            )}
            {outsiderValue.type === "number" && (
              <FstInputNumeric
                name={outsiderValue.columnName}
                value={outsiderValue.inputValue}
                handleChange={(e, newValue) =>
                  onChange(
                    e,
                    index,
                    indexTopElem,
                    indexAux,
                    outsiderValue,
                    newValue,
                  )
                }
                required={outsiderValue.required}
                size="small"
                decimalCharacter=","
                decimalPlaces={2}
                digitGroupSeparator="."
                label=""
              />
            )}
            {outsiderValue.type === "productAutoComplete" && (
              <ProductsAutoComplete
                onChangeProduct={(value, args) =>
                  onChangeProductAutoComplete(value)
                }
                args={{
                  productCode: outsiderValue.inputValue,
                  productDesc: null,
                  productId: null,
                  productData: null,
                }}
                required={outsiderValue.required}
                size="small"
                label=""
              />
            )}
            {outsiderValue.type !== "select" &&
              outsiderValue.type !== "date" &&
              outsiderValue.type !== "number" &&
              outsiderValue.type !== "productAutoComplete" && (
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  required={outsiderValue.required}
                  value={outsiderValue.inputValue}
                  onChange={(e) =>
                    onChange(e, index, indexTopElem, indexAux, outsiderValue)
                  }
                />
              )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OutsiderTendersFormComponent;
