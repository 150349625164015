import { post } from "../../../config/axios.js";

export const listManageInternalStatus = async (pagination) => {
  const request = {
    OrderBy: pagination.orderBy,
    PageSize: pagination.pageSize,
    Filter: pagination.search,
    PageNumber: pagination.PageNumber,
    OrderAsc: pagination.OrderAsc,
  };
  return await post(
    "/api/ManageInternalStatus/ListManageInternalStatuses",
    request
  );
};

export const addOrUpdateManageInternalStatus = async (request) => {
  return await post(
    "/api/ManageInternalStatus/AddOrUpdateManageInternalStatus",
    request
  );
};

export const deleteManageInternalStatus = async (request) => {
  return await post(
    "/api/ManageInternalStatus/DeleteManageInternalStatuses",
    request
  );
};
